/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { ClickOutService } from '@app/shared/services/click-out.service';
import { CustomWidgetDirective } from '@app/widgets/custom-widget/custom-widget.directive';
import { CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { FILLER_MINI } from '@app/widgets/constants/widget-ids.constant';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { UrlService } from '@app/shared/services/url.service';
import { WIDGET_TOGGLE_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { WidgetsService } from '@app/widgets/services/widgets.service';
import { OfferBadgeData } from '@shared/interfaces/offer-badge-data.interface';

const imageParamsVariationIcon = { w: 64, h: 64 };
const BACK_BUTTON_TYPE = 2;
const RELOAD_ID = 1;

@Component({
  selector: 'app-widgets-mini-container',
  templateUrl: './widgets-mini-container.component.html',
  styleUrls: ['./widgets-mini-container.component.less'],
  animations: [WIDGET_TOGGLE_ANIMATION]
})

export class WidgetsMiniContainerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() widget: CustomWidgetModel;
  @Input() groupTabIndex: number;
  @Input() sectionIndex: number;

  @ViewChild(CustomWidgetDirective) widgetHost: CustomWidgetDirective;

  isExpandedWidget = false;
  isFiller = false;
  imageOption: {
    w: number;
    h: number;
  };
  backButtonPressed = false;
  widgetOffer: OfferBadgeData = null;

  constructor(
    private widgetsService: WidgetsService,
    private cdr: ChangeDetectorRef,
    private clickOutService: ClickOutService,
    private urlService: UrlService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
  ) { }

  get widgetSectionIndex(): number {
    return this.isExpandedWidget ? (this.sectionIndex + 1) : this.sectionIndex;
  }

  ngOnInit(): void {
    if ((this.router as any).navigationId === RELOAD_ID) {
      if (window.performance.navigation.type !== BACK_BUTTON_TYPE) {
        this.sessionStorageService.removeGroupOfKeys('_mini');
      }
    }

    this.isExpandedWidget = this.widget && this.widget.widgetId && this.sessionStorageService.hasKey(this.widget.widgetId);

    this.imageOption = imageParamsVariationIcon;
  }

  ngOnChanges(changes: { widget: SimpleChange }): void {
    if (changes.widget) {
      this.isFiller = this.widget.widgetId === FILLER_MINI;
      if (!this.isFiller) {
        this.widgetsService.render(this.widget, this.widgetHost, this.groupTabIndex);
      }
      if (this.widget && this.isWidgetValid(this.widget.widgetId)) {
        this.widgetOffer = {
          extraStatus: this.widget.extraStatus,
          endDate: this.widget.endDate,
          catCodeTagIds: this.widget.catCodeTagIds,
          class: this.widget.class
        };
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.isFiller) {
      this.widgetsService.render(this.widget, this.widgetHost, this.groupTabIndex);
    }
    this.widgetsService.widgetDisplayed(this.widget);
  }

  async onClick(): Promise<void> {
    if (this.isFiller) {
      this.widgetsService.widgetClickOut(this.widget, this.widget.destinationUrl);
      await this.handleClickOut(this.widget.destinationUrl, this.widget.isEbg);
    } else {
      this.isExpandedWidget = !this.isExpandedWidget;
      this.cdr.detectChanges();
    }
  }

  private async handleClickOut(destinationUrl = '', isEbg: boolean): Promise<void> {
    if (destinationUrl) {
      const isExternalUrl = this.urlService.isExternalUrl(destinationUrl);
      if (isExternalUrl) {
        await this.clickOutService.clickOut(destinationUrl, '', {
          data: { logo: this.widget.logoUrl, brand: this.urlService.getHostName(destinationUrl), guid: this.widget.id }
        }, !isEbg);
      } else {
        window.location.href = destinationUrl;
      }
    }
  }

  private isWidgetValid(widgetID: string): boolean {
    return ['rental_car_mini', 'taw_hotels_mini'].includes(widgetID);
  }
}
