import { ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, Directive } from '@angular/core';
import { HorizontalScrollAbstract } from './horizontal-scroll.abstract';
import { WindowHelper } from 'g3-common-ui';

// 4.1 === 100%, 4 === 97.5%, 100% - 97.5 = 2.5%, 2.5% === 0.025
const RIGHT_CLICK_OFFSET = 0.025;
const ARROW_POSITION_MOVE = 27;
const WIDE_DESKTOP_WIDTH = 1472;
const ITEM_PER_SLIDE_WIDE_DESKTOP_WIDTH = 5;
const ITEM_PER_SLIDE_DESKTOP_WIDTH = 4;
const ITEM_PER_SLIDE_XS = 2.1;
const ITEM_PER_SLIDE_S = 2.3;
const ITEM_PER_SLIDE_M = 4;

@Directive()
export abstract class HomepageHorizontalScrollAbstract extends HorizontalScrollAbstract {

  @Input() hovered = false;

  @Output() scrollEvent = new EventEmitter<string>();
  @Output() swipeEvent = new EventEmitter<string>();

  @ViewChild('slider') slider: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('rightArrow') rightArrow: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    super.onResize();
  }

  get widthOffset(): number {
    // 2 times this.offset for Full width
    return this.offset * 2;
  }

  get showRightControl(): boolean {
    if (!this.slider || !this.wrapper || !this.hovered) {
      return false;
    }

    // '1' it's hack because of rounding during the calculation
    return this.slider.nativeElement.scrollLeft + 1 < this.slider.nativeElement.scrollWidth - this.slider.nativeElement.clientWidth;
  }

  calculateWidth(): string {
    if (!this.slider || !this.templateInited) {
      return '100%';
    }

    if (this.cachedWidth) {
      return `${this.cachedWidth}px`;
    }

    const width = this.window.innerWidth;

    this.cachedWidth = width;

    return `${width}px`;
  }

  leftClick(): void {
    this.scroll(this.slider.nativeElement.scrollLeft - this.wrapper.nativeElement.clientWidth);
    this.scrolled = true;
    this.swipeEvent.emit('left');
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 750);
  }

  rightClick(): void {
    const wrapperWidth = this.wrapper.nativeElement.clientWidth;
    const windowWidth = this.window.innerWidth;

    if (windowWidth < WindowHelper.SIZE_L) {
      this.scroll(this.slider.nativeElement.scrollLeft + wrapperWidth - wrapperWidth * RIGHT_CLICK_OFFSET);
    } else {
      this.scroll(this.slider.nativeElement.scrollLeft + this.slider.nativeElement.clientWidth - this.widthOffset);
    }
    this.swipeEvent.emit('right');
    this.scrolled = true;
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 750);
  }

  setWrapperWidth(): void {
    const wrapperRightEdge = this.rightArrow.nativeElement.getBoundingClientRect().right;
    const pageWidth = this.window.document.body.clientWidth;

    if (pageWidth >= WindowHelper.SIZE_M) {
      this.offset = pageWidth - wrapperRightEdge + ARROW_POSITION_MOVE;
    } else {
      this.offset = pageWidth - wrapperRightEdge;
    }

    this.changeDetectorRef.detectChanges();
  }

  protected getItemWidth(): number {
    const windowWidth = this.window.innerWidth;
    const wrapperWidth = this.wrapper.nativeElement.clientWidth;
    let itemWidth;

    if (windowWidth >= WIDE_DESKTOP_WIDTH) {
      itemWidth = wrapperWidth / ITEM_PER_SLIDE_WIDE_DESKTOP_WIDTH;
    } else if (windowWidth >= WindowHelper.SIZE_L) {
      itemWidth = wrapperWidth / ITEM_PER_SLIDE_DESKTOP_WIDTH;
    } else if (windowWidth >= WindowHelper.SIZE_M) {
      itemWidth = wrapperWidth / ITEM_PER_SLIDE_M;
    } else if (windowWidth >= WindowHelper.SIZE_S) {
      itemWidth = wrapperWidth / ITEM_PER_SLIDE_S;
    } else {
      itemWidth = wrapperWidth / ITEM_PER_SLIDE_XS;
    }

    return itemWidth;
  }
}
