import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';
import { Params, ActivatedRoute } from '@angular/router';

@Injectable()
export class AdobeHomepageDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService,
    private activatedRoute: ActivatedRoute
  ) { }

  async setHomepageData(): Promise<void> {
    const queryParams: Params = this.activatedRoute.snapshot.queryParams;

    await this.adobeDataCollectorService.setPageData({
      name: `home page${queryParams.zone_id ? ': ' + queryParams.zone_id : ''}`,
      section: 'home page',
      type: 'g3 home page',
      hierarchy: this.adobeDataCollectorService.setHierarchy('Home Page')
    });
  }

}
