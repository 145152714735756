import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Imported Modules
import { SharedModule } from '@app/shared/shared.module';

// Imported External Libraries
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// Components
import { CustomWidgetHostComponent } from './custom-widget/custom-widget-host.component';

// Directives
import { CustomWidgetDirective } from './custom-widget/custom-widget.directive';

// Services
import { WidgetsService } from '@app/widgets/services/widgets.service';
import { RentalCarService } from './services/rental-car.service';
import { HotelRoomsService } from './services/hotel-rooms.service';
import { MultiWidgetService } from './services/multi-widget.service';
import { SearchWidgetService } from './services/search-widget.service';
import { TicketsService } from './services/tickets.service';
import { WidgetSuggestionService } from './services/widget-suggestion.service';

// Widgets
import { TrueCarComponent } from './custom/truecar/truecar.component';
import { HotelWidgetComponent } from './custom/hotel/hotel-widget.component';
import { HotelsSearchWidgetComponent } from './search/hotels/hotels-search.component';
import { RentalCarSearchComponent } from './search/rental-car/rental-car-search.component';
import { SearchWidgetsContainerComponent } from './search/search-container/search-container.component';

// Shared
import { ModalAlertComponent } from '@app/shared/components/modal-alert/modal-alert.component';
import { G3CommonUiModule } from 'g3-common-ui';
import { HotelsMiniComponent } from './mini/hotels-mini/hotels-mini.component';
import { WidgetsMiniContainerComponent } from './mini/widgets-mini-container/widgets-mini-container.component';
import { HotelsFormComponent } from './forms/hotels-form/hotels-form.component';
import { TruecarMiniComponent } from './mini/truecar-mini/truecar-mini.component';
import { TruecarFormComponent } from './forms/truecar-form/truecar-form.component';
import { InsuranceMiniComponent } from './mini/insurance-mini/insurance-mini.component';
import { InsuranceFormComponent } from './forms/insurance-form/insurance-form.component';
import { RentalCarMiniComponent } from './mini/rental-car-mini/rental-car-mini.component';
import { RentalCarFormComponent } from './forms/rental-car-form/rental-car-form.component';
import { RoomsAndGuestsComponent } from './common/rooms-and-guests/rooms-and-guests.component';
import { MultiSearchWidgetComponent } from './search/multi-search/multi-search.component';
import { TicketsSearchComponent } from './search/tickets-search/tickets-search.component';
import { TicketsSearchResultComponent } from './search/tickets-search-result/tickets-search-result.component';
import { TicketsFormComponent } from './forms/tickets-form/tickets-form.component';
import { TicketsMiniComponent } from './mini/tickets-mini/tickets-mini.component';
import { SessionStorageService } from './services/session-storage.service';
import { NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './services/datepicker-parser.service';

const widgetComponents = [
  TrueCarComponent,
  HotelWidgetComponent,
  HotelsMiniComponent,
  HotelsFormComponent,
  TruecarMiniComponent,
  TruecarFormComponent,
  InsuranceMiniComponent,
  InsuranceFormComponent,
  RentalCarMiniComponent,
  RentalCarFormComponent,
  WidgetsMiniContainerComponent,
  HotelsSearchWidgetComponent,
  RentalCarSearchComponent,
  RoomsAndGuestsComponent,
  MultiSearchWidgetComponent,
  TicketsSearchComponent,
  TicketsSearchResultComponent,
  TicketsMiniComponent,
  TicketsFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxSliderModule,
    G3CommonUiModule,
    NgbDatepickerModule
  ],
  declarations: [
    CustomWidgetHostComponent,
    CustomWidgetDirective,
    ...widgetComponents,
    SearchWidgetsContainerComponent
  ],
  exports: [
    ...widgetComponents,
    CustomWidgetHostComponent,
    SearchWidgetsContainerComponent
  ],
  providers: [
    WidgetsService,
    RentalCarService,
    HotelRoomsService,
    MultiWidgetService,
    SearchWidgetService,
    TicketsService,
    SessionStorageService,
    WidgetSuggestionService,
    NgbDateCustomParserFormatter,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class WidgetsModule { }
