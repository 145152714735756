import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Scrollable } from '@shared/components/horizontal-scroll/scrollable.interface';
import { WINDOW, WindowHelper } from 'g3-common-ui';

export const CLICK_EVENT_TYPE = 'click';
export const PREVENT_CLICK_DEFAULT_OFFSET = 5;

export const PAN_MIN_STEP = 2.5;
export const PAN_MAX_STEP = 40;
export const PAN_MIN_DELTA_TIME = 150;
export const PAN_VELOCITY = 8;
export const FOLLOW_FINGER_VELOCITY = 2;
const PADDING_RIGHT_XS = 11;

export enum PanDirection {
  LEFT = 'left',
  RIGHT = 'right'
}
export function isPanVelocity(velocity: number): boolean {
  return (velocity > 0 && velocity <= PAN_VELOCITY);
}

export function getVelocityMultiplier(velocity: number): number {
  // velocity will be in range 2 to 8
  // this fucntion returns 1.25 to 3.2 as a multiplier to distance
  // to speed up if user is moving finger fast, but not too fast
  return (velocity / 3 + 0.5);
}

export function isSwipeVelocity(velocity: number): boolean {
  return velocity > PAN_VELOCITY;
}

export function getPanStep(velocity: number, multiplier = 40): number {
  if (velocity < 0.1) {
    return PAN_MIN_STEP;
  }
  return Math.min(Math.round(velocity * multiplier), PAN_MAX_STEP);
}

export function easeInOutCubic(progress: number): number {
  return progress < 0.5 ? 4 * progress ** 3 : 1 - Math.pow(-2 * progress + 2, 3) / 2;
}


@Directive()
export abstract class HorizontalScrollAbstract implements OnInit, AfterViewInit, OnDestroy {

  public panDirection = PanDirection;
  public scrolled = false;
  public scrollState: Scrollable;

  protected cachedWidth: number;
  protected offset = 0;
  protected netDeltaX = 0;
  protected templateInited: boolean;
  protected initialWrapperWidth = 0;


  abstract hovered: boolean;
  abstract slider: ElementRef;
  abstract wrapper: ElementRef;
  abstract rightArrow: ElementRef;
  abstract scrollEvent: EventEmitter<string>;
  abstract swipeEvent: EventEmitter<string>;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    @Inject(WINDOW) protected window: WINDOW
  ) { }

  public ngAfterViewInit(): void {
    // FIXED ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    this.changeDetectorRef.detectChanges();
    this.slider.nativeElement.addEventListener(CLICK_EVENT_TYPE, this.preventChildClickHandler, true);
    this.initialWrapperWidth = this.wrapper.nativeElement.clientWidth;
    this.templateInited = true;
    setTimeout(() => {
      // Need delay, because after fist init could be slightly different width of wrapper element
      this.setWrapperWidth();
      this.setChildrenWidth();
    }, 0);

  }

  ngOnInit(): void {
    this.initState();
  }

  public ngOnDestroy(): void {
    this.slider.nativeElement.removeEventListener(CLICK_EVENT_TYPE, this.preventChildClickHandler, true);
  }

  public get hideOffset(): string {
    if (!this.wrapper) {
      return '';
    }

    return `${this.wrapper.nativeElement.clientWidth}px`;
  }

  public get ghostOffset(): string {
    if (this.window.innerWidth >= WindowHelper.SIZE_M) {
      return `${this.offset + 2}px`;
    } else if (this.window.innerWidth < WindowHelper.SIZE_S) {
      return this.window.innerWidth >= 540 ? `${(this.window.innerWidth - 500) / 2}px` : `${PADDING_RIGHT_XS}px`;
    } else {
      return `${(this.window.innerWidth - 700) / 2}px`;
    }
  }

  get showLeftControl(): boolean {
    if (!this.slider || !this.hovered) {
      return false;
    }

    return this.slider.nativeElement.scrollLeft > 0;
  }

  public onMouseDown(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    this.scrollState.offsetForPreventClick = e.clientX;
    this.scrollState.isDown = true;
    this.scrollState.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollState.scrollLeft = this.slider.nativeElement.scrollLeft;
  }

  public onMouseEnter(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.scrollState.isDown = false;
  }

  public onMouseLeave(): void {
    this.scrollState.startX = null;
  }

  public onMouseUp(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.scrollState.isDown = false;
  }

  public onMouseMove(e: MouseEvent): void {
    if (!this.scrollState.isDown || !this.scrollState.startX) {
      return;
    }

    e.preventDefault();
    this.scrolled = true;

    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const offset = x - this.scrollState.startX;

    this.slider.nativeElement.scrollLeft = this.scrollState.scrollLeft - offset;
    this.scrollEvent.emit(e.pageX > this.scrollState.startX ? 'right' : 'left');
  }

  pan(e: HammerInput, direction: PanDirection): void {
    e.preventDefault();
    if (e.isFirst) {
      // remember where we left from as scrollLeft
      this.scrollState.scrollLeft = this.slider.nativeElement.scrollLeft;
      // track where we end up
      this.netDeltaX = this.scrollState.scrollLeft;
    }
    this.scrolled = true;
    if (this.slider && this.wrapper) {
      const velocity = direction === PanDirection.LEFT ? -1 * e.velocityX : e.velocityX;
      if (isPanVelocity(velocity)) {
        if (e.isFinal) {
          this.scrollState.scrollLeft = this.slider.nativeElement.scrollLeft;
        } else if (velocity < FOLLOW_FINGER_VELOCITY) {
          this.netDeltaX = this.scrollState.scrollLeft - e.deltaX;
          this.slider.nativeElement.scrollLeft = this.netDeltaX;
        } else {
          // velocity will not be above PAN_VELOCITY
          this.netDeltaX = this.scrollState.scrollLeft - e.deltaX * getVelocityMultiplier(velocity);
          this.slider.nativeElement.scrollLeft = this.netDeltaX;
        }

      } else if (isSwipeVelocity(velocity)) {
        this.doSwipe(e, direction);
      }
    }
  }

  doSwipe(e: HammerInput, direction: PanDirection): void {
    e.preventDefault();
    if (this.slider && this.wrapper) {
      let offset = this.wrapper.nativeElement.clientWidth;
      if (direction === PanDirection.RIGHT) {
        offset *= -1;
      }
      const velocity = direction === PanDirection.LEFT ? -1 * e.velocityX : e.velocityX;
      if (isSwipeVelocity(velocity)) {
        this.swipeEvent.emit(direction);
        this.scroll(this.slider.nativeElement.scrollLeft + offset);
      }
    }
  }

  protected scroll(offset: number): void {
    if (!this.slider) {
      return;
    }

    this.smoothScrollTo(offset);
  }

  private smoothScrollTo(offset: number, duration: number = 750): void {
    const start = this.slider.nativeElement.scrollLeft;
    const change = offset - start;
    const startTime = performance.now();

    const animateScroll = (currentTime: number): void => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const ease = progress < 0.5 ? 4 * progress ** 3 : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      this.slider.nativeElement.scrollLeft = start + change * ease;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }

  protected onResize(): void {
    this.templateInited = false;
    this.scrollState.startX = 0;
    this.scrollState.scrollLeft = 0;
    this.scrollState.offsetForPreventClick = 0;
    this.cachedWidth = 0;
    this.offset = 0;

    setTimeout(() => {
      this.templateInited = true;
      this.initialWrapperWidth = this.slider.nativeElement.clientWidth;
      this.setWrapperWidth();
      this.setChildrenWidth();
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  private preventChildClickHandler = (e: MouseEvent): void => {
    if (Math.abs(e.clientX - this.scrollState.offsetForPreventClick) > PREVENT_CLICK_DEFAULT_OFFSET) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  private initState(): void {
    this.scrollState = {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      offsetForPreventClick: 0
    };
  }

  abstract setWrapperWidth(): void;
  abstract setChildrenWidth(): void;

}
