import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { OfferBadgeType } from '@shared/enums/offer-badge-type.enum';

const nonExtraStatusTypes = [OfferBadgeType.Exclusive, OfferBadgeType.Payroll, OfferBadgeType.Loyalty, OfferBadgeType.Client, OfferBadgeType.Category];

@Directive({
  selector: '[appBadge]'
})
export class BadgeDirective implements AfterViewInit {

  @Input() badgeType: OfferBadgeType = OfferBadgeType.None;
  @Input() title = '';
  @Input() withRightCorners = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {
    this.addBadgeClasses();
  }

  private addBadgeClasses(): void {
    const badgeElement = this.elementRef.nativeElement;

    if (!badgeElement) {
      return;
    }

    const badgeClasses: string[] = [];
    const isExtraStatus = !nonExtraStatusTypes.includes(this.badgeType);

    switch (this.badgeType) {
      case OfferBadgeType.New:
        badgeClasses.push('extra-status-new');
        break;
      case OfferBadgeType.ExtraPadding:
        badgeClasses.push('extra-status-expiring-soon');
        break;
      case OfferBadgeType.TimeLimited:
        badgeClasses.push('extra-status-limited');
        break;
      case OfferBadgeType.Expired:
        badgeClasses.push('extra-status-expired');
        break;
      case OfferBadgeType.Flash:
        badgeClasses.push('extra-status-flash');
        break;
      case OfferBadgeType.Tag:
        badgeClasses.push('extra-status-tag');
        break;
      case OfferBadgeType.TodayDeal:
        badgeClasses.push('today-deal-badge');
        break;
      case OfferBadgeType.Ads:
        badgeClasses.push('ads-badge');
        break;
      case OfferBadgeType.Loyalty:
        badgeClasses.push('loyalty-badge', 'extra-status-exclusive');
        break;
      case OfferBadgeType.Payroll:
      case OfferBadgeType.Exclusive:
      case OfferBadgeType.Client:
      case OfferBadgeType.Category:
        badgeClasses.push('extra-status-exclusive');
        break;
    }

    if (isExtraStatus) {
      badgeClasses.push('extra-status');
    }

    if (this.withRightCorners) {
      badgeClasses.push('extra-status-right-corners');
    }

    if (this.badgeType === OfferBadgeType.TodayDeal && this.title && this.title.length > 10) {
      badgeClasses.push('today-deal-badge-two-lines');
    }

    badgeClasses.forEach(badgeClass => {
      this.renderer.addClass(badgeElement, badgeClass);
    });
  }

}
