import {
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ConfigService, HeaderAnimationState } from '@app/shared/services/config.service';
import { NavDynamicItem } from '@core/layouts/logged-in/interfaces/nav-dynamic-section.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';

interface NavWithColumns extends NavDynamicItem {
  columns: NavDynamicItem[][];
}

@Component({
  selector: 'app-sub-navigation-menu',
  templateUrl: './sub-navigation-menu.component.html',
  styleUrls: ['./sub-navigation-menu.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION]
})
export class SubNavigationMenuComponent implements OnInit {

  @Input() primaryLevelItem: NavDynamicItem = {} as NavDynamicItem;
  @Input() secondLevelNavItems: NavDynamicItem[] = [] as NavDynamicItem[];
  @Input() showSubMenu = false;

  secondLevelNavItemsLimited: NavWithColumns[] = [] as NavWithColumns[];
  totalContainerColumnsCount = 0;
  subMenuWidth: string;

  readonly maxThirdLevelItemsCountInOneColumn = 9;

  private maxColumnsCount: number;
  private readonly landscapeMaxColumnsCount = 5;
  private readonly desktopMaxColumnsCount = 6;

  constructor(
    private navigationService: NavigationService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private configService: ConfigService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateColumns();
  }

  ngOnInit(): void {
    this.updateColumns();
  }

  updateColumns(): void {
    this.setColumnsMaxCount();
    this.secondLevelNavItemsLimited = this.getNavsWithColumns(this.secondLevelNavItems || []);
    this.updateTotalColumnsCount();
    this.updateSubmenuWidth();
  }

  getNavsWithColumns(navs: NavDynamicItem[]): NavWithColumns[] {
    const navsWithColumns = navs.map(nav => {
      const columns = [];

      // split items into columns
      if (nav.children && nav.children.length) {
        let itemsPerColumn = this.maxThirdLevelItemsCountInOneColumn;

        if (nav.children.length > this.maxThirdLevelItemsCountInOneColumn) {
          const maxItems = Math.min(nav.children.length, this.maxThirdLevelItemsCountInOneColumn * 2);
          itemsPerColumn = Math.ceil(maxItems / 2);
        }

        const firstColumn = nav.children.slice(0, itemsPerColumn);
        const secondColumn = nav.children.slice(itemsPerColumn, itemsPerColumn * 2);

        if (firstColumn.length) {
          columns.push(firstColumn);
        }

        if (secondColumn.length) {
          columns.push(secondColumn);
        }
      }

      return { ...nav, columns };
    });

    return this.limitColumnsCount(navsWithColumns);
  }

  onSubmenuClick(nav: NavDynamicItem, isViewAllLink = false): void {
    const isExternalUrl = this.navigationService.isExternalUrl(nav.url);
    const action = isExternalUrl ? 'nav-click-out' : 'nav-click-internal';
    this.configService.updateHeaderAnimationState(HeaderAnimationState.out);
    // eslint-disable-next-line typescriptESlintPlugin/no-explicit-any
    const data: any = {
      guid: nav.guid,
      id: nav.id,
      name: nav.name,
      ...(nav.parent && { parent: nav.parent }),
    };

    this.adobeAnalyticsEventsService.sendNavigationClick(nav);

    if (action === 'nav-click-internal') {
      data.url = nav.url;
    }
    this.navigationService.trackGAAnalytic(action, data, isViewAllLink);

    if (action === 'nav-click-out') {
      data.url = nav.url;
    }

    if (isExternalUrl) {
      data['click_out'] = nav.is_ebg ? 'internal' : 'external';
    }

    this.navigationService.trackInternalAnalytic(action, data, isViewAllLink);
    this.navigationService.handleClick(nav.url, nav.has_token);
  }

  private setColumnsMaxCount(): void {
    const isLandscape = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)').matches;
    this.maxColumnsCount = isLandscape ? this.landscapeMaxColumnsCount : this.desktopMaxColumnsCount;
  }

  private updateTotalColumnsCount(): void {
    this.totalContainerColumnsCount = Math.min(
      this.secondLevelNavItemsLimited.reduce((sum, nav) => sum + (nav.columns.length > 1 ? 2 : 1), 0),
      this.maxColumnsCount
    );
  }

  private limitColumnsCount(navs: NavWithColumns[]): NavWithColumns[] {
    let navsCount = 0;

    return navs.filter(nav => {
      if (navsCount === this.maxColumnsCount) {
        return false;
      }

      navsCount++;

      if (nav.columns.length > 1) {
        if (navsCount < this.maxColumnsCount) {
          navsCount++;
        } else {
          nav.columns = nav.columns.slice(0, 1);
        }
      }

      return true;
    });
  }

  private updateSubmenuWidth(): void {
    // width - (column width * total columns count) + right & left padding
    this.subMenuWidth = `calc((192px * ${this.totalContainerColumnsCount}) + 38px)`;
  }

}
