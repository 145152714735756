<div [ngClass]="zipFlag ? 'visible-types' : 'hidden-types'">
  <div class="{{ customClass }}">
    <div class="{{ customClass }}-controls"
         (appClickOutside)="closeSuggestionsPanel()"
         aria-label="Zip Code"
         role="combobox"
         aria-haspopup="listbox"
         [attr.aria-expanded]="shouldShowZipSuggestions"
         [attr.aria-owns]="zipCodeSuggestions">
      <div class="{{ customClass }}-controls__zip">
        <label class="{{ customClass }}-controls__zip--label"
               [id]="zipCodeLabel">City or Zip</label>
        <div class="input-wrapper">
          <input class="{{ customClass }}-controls__zip--input"
                 [class.zip-invalid]="zipCodeInvalid"
                 [appAutoTabIndex]="this.customClass === 'mobile-filters' ? groupTabIndex : groupTabIndex + 172"
                 #zipInput
                 type="text"
                 [(ngModel)]="zipCode"
                 aria-autocomplete="list"
                 [attr.aria-controls]="zipCodeSuggestions"
                 (keydown.enter)="onZipSelected(zipInput.value, 'enter', $event)"
                 (input)="zipCodeInvalid = false;"
                 (click)="onZipFocus(zipInput)"
                 (focus)="onZipFocus(zipInput)">
        </div>
        <div class="{{ customClass }}-controls__viewed drop-down-list"
             role="listbox"
             [id]="zipCodeSuggestions"
             [hidden]="!shouldShowZipSuggestions">
          <div>
            <div class="drop-down-list-title"
                 role="presentation">Last Viewed</div>
            <ul class="drop-down-list-list"
                role="presentation">
              <li [appAutoTabIndex]="this.customClass === 'mobile-filters' ? groupTabIndex + 1 : groupTabIndex + 173"
                  role="option"
                  *ngFor="let zip of zipSuggestions; let i = index"
                  (click)="onZipSelected(zip, 'select', $event)"
                  (focusout)="i === zipSuggestions.length - 1 ? closeSuggestionsPanel(): ''">{{ zip }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="{{ customClass }}-controls__types"
           *ngIf="showLocationTypes$ | async">
        <label class="{{ customClass }}-controls__types--label">Location Type</label>
        <div class="input-wrapper">
          <select [appAutoTabIndex]="this.customClass === 'mobile-filters' ? groupTabIndex + 2 : groupTabIndex + 174"
                  [attr.aria-label]="'location'"
                  [(ngModel)]="locationType"
                  class="{{ customClass }}-controls__types--select"
                  (change)="changeLocationType()">
            <option value="">All Types</option>
            <option *ngFor="let loc of locations$ | async | orderBy : 'count' : 1; trackBy:trackLocationByValue"
                    [value]="loc.value">
              {{ loc.displayName }} ({{ loc.count }})
            </option>
          </select>
        </div>
      </div>

    </div>

    <fieldset aria-label="show">
      <div [@opacityFadeInOut]
           class="{{ customClass }}-categories"
           [class.category-with-label]="!(locations$ | async)">
        <label class="{{ customClass }}-categories__label">Offer Category</label>
        <div class="input-wrapper">
          <select [appAutoTabIndex]="this.customClass === 'mobile-filters' ? groupTabIndex + 3 : groupTabIndex + 175"
                  [attr.aria-label]="'category'"
                  [(ngModel)]="category"
                  class="{{ customClass }}-categories__select"
                  (change)="changeCategory()">
            <option value="">All Categories</option>
            <option *ngFor="let cat of categories$ | async | orderBy : 'count' : 1; trackBy:trackCategoryByName"
                    [value]="cat.name">
              {{ cat.name }} ({{ cat.count }})
            </option>
          </select>
        </div>
      </div>
    </fieldset>

  </div>
</div>