import { UnsubscribeOption } from '../interfaces/subscription-options.interface';

export const DefaultOptions: UnsubscribeOption[] = [
  {
    header: 'Manage subscriptions.',
    subheader: 'Sign in to review and update your subscriptions. Make sure you are signed up for the email alerts you want to receive.',
    buttonTitle: 'Manage Subscriptions',
    buttonType: 'primary',
    buttonId: 'manage',
    formType: null,
    content: null
  },
  {
    header: 'No more email.',
    subheader: 'Unsubscribe me from all subscriptions.',
    buttonTitle: 'Unsubscribe from ALL',
    buttonType: 'secondary',
    buttonId: 'all',
    formType: null,
    content: null
  }
];

export const AdditionalOption: UnsubscribeOption = {
  header: 'Want less email?',
  subheader: '',
  buttonTitle: 'Update Now',
  buttonType: 'update',
  buttonId: 'family',
  formType: null,
  content: { subscription: [] }
};
