/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable prefer-const */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { OfferModel } from '@shared/models/offer.model';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import {
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsGAOfferModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutService } from '@shared/services/click-out.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ActionType, OffersAnalyticsService } from '@shared/services/analytics/offers-analytics.service';
import { Router } from '@angular/router';
import { CouponModalComponent } from '@shared/components/coupon-modal/coupon-modal.component';
import { OffersService } from '@shared/services/offers.service';
import { SearchService } from '@shared/services/search/search.service';
import { PhoneCtaService } from '@shared/services/phoneCta.service';
import { SOURCE_ID } from '@app/shared/constants/source-id.constants';
import { SingleUseCodeService } from '@app/shared/components/coupon-modal/single-use-code.service';
import { AdobeClickEvent, AdobeEventNameEnum, AdobeEventTypeEnum } from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';

@Injectable({
  providedIn: 'root'
})
export class DirectClickOutOffersService {

  constructor(
    protected adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    protected router: Router,
    protected modalService: NgbModal,
    protected clickOutService: ClickOutService,
    protected breadcrumbsService: BreadcrumbsService,
    protected analyticsService: AnalyticsService,
    protected offersAnalytics: OffersAnalyticsService,
    protected offersService: OffersService,
    protected searchService: SearchService,
    protected phoneCtaService: PhoneCtaService,
    protected singleUseCodeService: SingleUseCodeService
  ) { }

  getCouponModal(): NgbModalRef {
    return this.modalService.open(CouponModalComponent, {
      size: 'sm',
      centered: true,
      windowClass: 'custom-modal coupon-modal'
    });
  }

  disableHoverForOffer(offer: OfferModel) {
    return !(offer.hasDescriptionDetails() || offer.hasCouponCode() || offer.hasDestinationUrl());
  }

  isShowGetOfferButton(offer: OfferModel) {
    return offer.hasCouponCode() || offer.hasDestinationUrl() && !offer.isExpired() || !!offer.phone;
  }

  redirectToOfferDetailsFromOffer(offer: OfferModel, navigatedFromData?: Breadcrumb) {
    let eventData;
    if (!!navigatedFromData) {
      this.breadcrumbsService.setAdditionalItem(
        navigatedFromData.label,
        navigatedFromData.url,
        navigatedFromData.queryParams
      );
    }

    eventData = { offer: offer.guid };

    if (offer.brandOverride) {
      eventData['brandOverride'] = offer.brandOverride;
    }

    this.trackAnalyticsEvents('click', offer, eventData);
    void this.router.navigate([`/offers/${offer.guid}`]);
  }

  async handleClick(offer: OfferModel, source: 'card' | 'cta', navigatedFromData?: Breadcrumb, isPaylogixHomepageRedirect = false, adobeInfo?: AdobeClickEvent) {

    const eventData = this.offersAnalytics.getOfferEventData(offer);
    const sourceId = sessionStorage.getItem(SOURCE_ID) || '';

    const additionalEvents = this.getClickOutAdditionalEvents(offer, eventData);
    if (source === 'card' && offer.descriptionDetails) {
      if (adobeInfo) {
        adobeInfo.event_type = AdobeEventTypeEnum.CLICK;
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }
      this.redirectToOfferDetailsFromOffer(offer, navigatedFromData);
    } else if (this.isDisplayModal(offer)) {
      if (adobeInfo) {
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
        void this.adobeAnalyticsEventsService.sendOfferClick({
          event_name: adobeInfo.event_name,
          event_type: AdobeEventTypeEnum.REVEAL_CODE,
          properties: adobeInfo.properties
        });
      }
      this.trackAnalyticsEvents('click-reveal-code', offer, eventData);
      this.increaseViewCounter(offer);
      const modal = this.getCouponModal();
      modal.componentInstance.brandLogo = offer.vendorBrand && offer.vendorBrand.logoSquare;
      modal.componentInstance.offer = offer;
      modal.componentInstance.code = offer.code;
      modal.componentInstance.modalEvents.subscribe(async event => this.handleModalEvents(event, offer, adobeInfo));
      modal.componentInstance.phoneAction.subscribe(url => this.phoneCtaService.phoneCtaClick(url, offer));
      modal.componentInstance.phoneClick.subscribe(phone => this.phoneCtaService.phoneClick(phone, offer));
    } else if (offer.url && offer.url.startsWith('/offers?')) {

      this.trackAnalyticsEvents('click', offer, {
        offer: offer.guid
      });

      if (offer.urlType === 'dynamic') {
        if (adobeInfo) {
          adobeInfo.event_type = this.adobeAnalyticsEventsService.getClickOutValueForOffer(offer);
          adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
          void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
        }
        await this.clickOutService.clickOut('', 'offer_dynamic', { guid: offer.guid }, offer.destinationUrlNewTab, sourceId, offer.sourceBasedParams);
      } else {
        if (adobeInfo) {
          adobeInfo.event_type = AdobeEventTypeEnum.CLICK;
          adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
          void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
        }
        void this.router.navigateByUrl(offer.url);
      }

    } else if (offer.urlType === 'dynamic') {
      this.increaseViewCounter(offer);
      if (adobeInfo) {
        adobeInfo.event_type = this.adobeAnalyticsEventsService.getClickOutValueForOffer(offer);
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }

      const theOfferUrl = await this.clickOutService.clickOut('', 'offer_dynamic',
        {
          guid: offer.guid,
          analyticEvents: [
            ...this.offersAnalytics.getAnalyticsEvents('click-out', offer, eventData),
            ...additionalEvents
          ],
          data: {
            logo: offer.vendorBrand.logoSquare,
            brand: offer.vendorBrand.title,
            guid: offer.guid,
            externalData: offer.external_data
          }
        },
        offer.destinationUrlNewTab,
        sourceId,
        offer.sourceBasedParams
      );
      eventData['url'] = theOfferUrl;
    } else if (offer.url) {
      this.increaseViewCounter(offer);
      const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
      if (adobeInfo) {
        adobeInfo.event_type = this.adobeAnalyticsEventsService.getClickOutValueForOffer(offer);
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }

      await this.clickOutService.clickOut(offer.url, null,
        {
          analyticEvents: [
            ...this.offersAnalytics.getAnalyticsEvents('click-out', offer, eventData),
            ...additionalEvents
          ],
          data: { logo: offer.vendorBrand.logoSquare, brand: offer.vendorBrand.title, guid: offer.guid }
        },
        offer.destinationUrlNewTab,
        sourceId,
        offer.sourceBasedParams
      );
    } else if (offer.descriptionDetails) {
      if (adobeInfo) {
        adobeInfo.event_type = AdobeEventTypeEnum.CLICK;
        adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
        void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
      }
      this.redirectToOfferDetailsFromOffer(offer, navigatedFromData);
    } else {
      return false;
    }

    offer.views += 1;

    return false;
  }

  increaseViewCounter(offer: OfferModel) {
    if (offer && offer.guid) {
      this.trackAnalyticsEvents('offer-view', offer);
      void this.offersService.increaseOfferViewCount(offer.guid);
    }
  }

  async handleDynamicCategoryOfferClick(offer: OfferModel, categoryTitle) {
    const eventData = this.offersAnalytics.getOfferEventData(offer);
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('special-listings-clicks', eventData),
      new AnalyticsGAEventModel(categoryTitle, {
        category: 'speciallistingclicks',
        label: new AnalyticsGAOfferModel(offer.guid, offer.vendorBrand.title, offer.subtitle, false, offer.brandOverride).generateOfferLabel()
      })
    ]);
  }

  trackAnalyticsEvents(eventType: ActionType, offer: OfferModel = null, eventData?: any) {
    this.analyticsService.eventsTrack(this.offersAnalytics.getAnalyticsEvents(eventType, offer, eventData));
  }

  public async handleModalEvents(event, offer: OfferModel, adobeInfo?: AdobeClickEvent) {
    if (event && event.type) {
      const eventData = this.offersAnalytics.getOfferEventData(offer);
      eventData.offer_type = 'coupon-offer';
      switch (event.type) {
        case 'click-copy-code':
          if (adobeInfo) {
            adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
            void this.adobeAnalyticsEventsService.sendOfferClick({
              event_name: adobeInfo.event_name,
              event_type: AdobeEventTypeEnum.COPY_CODE,
              properties: adobeInfo.properties
            });
          }
          this.analyticsService.eventsTrack(this.offersAnalytics.getAnalyticsEvents('click-copy-code', offer, eventData));
          break;
        case 'cta-button-click':
          const analyticEvents = this.offersAnalytics.getAnalyticsEvents('cta-button-click', offer, eventData);
          const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
          if (adobeInfo) {
            adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(offer);
            void this.adobeAnalyticsEventsService.sendOfferClick({
              event_name: adobeInfo.event_name,
              event_type: this.adobeAnalyticsEventsService.getClickOutValueForOffer(offer),
              properties: adobeInfo.properties
            });
          }

          if (offer.urlType === 'dynamic') {
            await this.clickOutService.clickOut(
              '',
              'offer_dynamic',
              { analyticEvents, guid: offer.guid, data: { logo: offer.vendorBrand.logoSquare, brand: offer.vendorBrand.title, guid: offer.guid } },
              offer.destinationUrlNewTab,
              sourceId,
              offer.sourceBasedParams
            );
          } else {
            await this.clickOutService.clickOut(
              offer.url,
              null,
              { analyticEvents, data: { logo: offer.vendorBrand.logoSquare, brand: offer.vendorBrand.title, guid: offer.guid } },
              offer.destinationUrlNewTab,
              sourceId,
              offer.sourceBasedParams
            );
          }
          const params = this.searchService.getQueryParams();
          const excludedSpecials = ['New', 'Limited Time'];
          if (offer.is_in_dynamic_category && !excludedSpecials.includes(params.specials)) {
            await this.handleDynamicCategoryOfferClick(offer, params.specials);
          }
          break;
      }
    }
  }

  public isDisplayModal(offer: OfferModel): boolean {
    const hasCouponForModal = offer.hasCouponCode() && !this.isExternalUrlWithSucToken(offer);
    return !offer.isPaylogixOffer && (hasCouponForModal || offer.hasPhone());
  }

  protected getClickOutAdditionalEvents(offer: OfferModel, eventData?: any): AnalyticsEvent[] {
    const events: AnalyticsEvent[] = [];

    if (this.isExternalUrlWithSucToken(offer)) {
      events.push(...this.offersAnalytics.getAnalyticsEvents('click-reveal-code', offer, eventData));
    }

    return events;
  }

  protected isExternalUrlWithSucToken(offer: OfferModel): boolean {
    return offer.hasCouponCode() && this.singleUseCodeService.isExternalUrlWithSucToken(offer.url, offer.code);
  }

}
