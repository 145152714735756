<div class="container special-offers-container main-container {{wideContainerClass}}">
  <app-special-offers-item *ngFor="let item of specialOffers; let i = index; trackBy: SpecialOffersTrackBy"
                           [groupTabIndex]="groupTabIndex + 20 * i"
                           [ads]="item.items"
                           [itemName]="item.name"
                           [itemCategoryGuid]="item.category"
                           [itemTitle]="getSectionTitle(item)"
                           [itemIcon]="getSectionIconPath(item)"
                           [linkTitle]="getSectionLinkTitle(item)"
                           [linkUrl]="getLinkUrl(item)"
                           favoritedFromEventName="special-offers"
                           [isCustomCategory]=item.isCustomCategory
                           [isEnrollment]="isEnrollment"
                           [isStaggered]="isOffersStaggered(item)"
                           [disableShowAll]="disableShowAll"
                           [wideContainerClass]="wideContainerClass"
                           [shouldAlignItems]="shouldAlignItems"
                           [isAllowDisableHover]="isAllowDisableHover"
                           [wideContainerClassName]="wideContainerClassName"
                           [ctaIsEbgLink]="item.ctaIsEbgLink">
  </app-special-offers-item>
</div>
