/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
export function queryParams(params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
}

export const normalizeURIString = (value = '') => value.split('%').join('%25').split('%2520').join(' ');

export function appendUrlParams(url: string, params = ''): string {
  if (!url) {
    return url;
  }

  if (url.includes(params)) {
    return url;
  }

  const [beforeHash, hash] = url.split('#');
  let newUrl = beforeHash.endsWith('?') || beforeHash.endsWith('&') ? beforeHash.slice(0, -1) : beforeHash;
  const trimmedParams = params.startsWith('?') || params.startsWith('&') ? params.slice(1) : params;

  if (trimmedParams) {
    newUrl += `${newUrl.includes('?') ? '&' : '?'}${trimmedParams}`;
  }

  if (hash) {
    newUrl += '#' + hash;
  }

  return newUrl;
}

export function queryStringContainsProperty(queryString: string, property: string): boolean {
  if (!queryString || !property) {
    return false;
  }
  const uriQueryObject = new URLSearchParams(queryString);
  return uriQueryObject.has(property);
}
