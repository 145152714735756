<span class="coupon-code notranslate"
      [class.centered]="disableCopyButton"
      [class.longCode]="code && code.length > 12"
      [innerHTML]="code | tagFilter"
      #codeElement>
</span>
<button *ngIf="!disableCopyButton"
        class="btn coupon-copy-btn notranslate"
        role="button"
        aria-label="Copy"
        [innerHTML]="[buttonText]">
</button>
<button *ngIf="!disableCopyButton"
        class="btn coupon-copy-btn coupon-copy-btn-checkmark"
        [class.coupon-copy-btn-checkmark-active]="copyButtonClicked"
        [appAutoTabIndex]="groupTabIndex"
        (click)="copyToClipBoard(codeElement)"
        [innerHTML]="copiedTemplate">
</button>