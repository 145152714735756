import { Injectable } from '@angular/core';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class RetroactiveUpdateService {

  constructor(
    private readonly profileService: ProfileService
  ) { }

  public getIsRetroactiveEmailUpdate(): boolean {
    return this.profileService.getData()?.requireEmailUpdate;
  }
}
