/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import first from 'lodash/first';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { SearchService } from '@shared/services/search/search.service';
import { SearchSuggestedService } from '@shared/services/search/search-suggested.service';
import { TokensService } from '@shared/services/tokens.service';

interface SearchInterceptorRule {
  query: string;
  redirect: string;
  match_type: 'exact' | 'broad';
}

interface SearchInterceptorResponse {
  items: SearchInterceptorRule[];
  meta: {
    total: number;
    next: string;
    prev: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SearchInterceptorService {

  private isInitialized = false;
  private rules: SearchInterceptorRule[] = null;

  constructor(
    private http: HttpClient,
    private searchService: SearchService,
    private searchSuggestedService: SearchSuggestedService,
    private router: Router,
    private tokensService: TokensService,
  ) { }

  async initRules(): Promise<void> {
    this.isInitialized = true;

    try {
      const rules = [];
      const limit = 100;
      let offset = 0;
      let isIterationOver = false;

      while (!isIterationOver) {
        const data = await this.http
          .get<SearchInterceptorResponse>(`${environment.apiUrl}/api/search-interceptor/rules?limit=${limit}&offset=${offset}`)
          .toPromise();
        const isDataPresent = data && data.items && data.items.length;

        if (isDataPresent) {
          rules.push(...data.items);
          offset += limit;
        }

        if (!isDataPresent || !data.meta.next) {
          isIterationOver = true;
        }
      }

      this.rules = rules.filter(rule => !!rule.redirect);
    } catch (err) {
      this.rules = [];
      console.error(err);
    }
  }

  async intercept(queryParams: Params): Promise<boolean> {
    const queryKeys = Object.keys(queryParams).filter(key => queryParams.hasOwnProperty(key));
    const isQueryOnly = queryParams['q'] && queryKeys.length === 1;

    if (!isQueryOnly) {
      return false;
    }

    const query = queryParams['q'].trim();
    const matchedRule = await this.matchInterceptorRule(query);

    if (!matchedRule) {
      return false;
    }

    await this.handleRedirect(matchedRule.redirect, query, true);

    return true;
  }

  async matchInterceptorRule(query: string): Promise<any> {
    if (!this.isInitialized) {
      await this.initRules();
    }

    const exactRules = this.rules.filter(rule => rule.match_type === 'exact');
    let matchedRule = exactRules.find(i => i.query.toLowerCase() === query.toLowerCase());

    // IF no exact match => check for broad
    if (!matchedRule) {
      const broadRules = this.rules.filter(rule => rule.match_type === 'broad');
      const broadMatches = broadRules.filter(rule => query.toLowerCase().includes(rule.query.toLowerCase()));
      if (broadMatches.length) {
        matchedRule = first(this.getSortedBroadMatches(broadMatches, query));
      } else {
        // If sint_q rule shorter then query
        const matches = broadRules.filter(rule => query.toLowerCase().includes(rule.query.toLowerCase()));
        const matchesSorted = matches.sort((a, b) => {
          const queryPositionA = query.toLowerCase().indexOf(a.query.toLowerCase());
          const queryPositionB = query.toLowerCase().indexOf(b.query.toLowerCase());

          if (queryPositionA === queryPositionB) {
            return 0;
          }

          return queryPositionA < queryPositionB ? -1 : 1;
        });

        matchedRule = first(matchesSorted);
      }
    }

    return matchedRule;
  }

  async handleRedirect(url: string, title: string, intercepted: boolean): Promise<void> {
    const populatedUrl = (await this.tokensService.getPopulatedDynamicURLs([url]))[0];

    if (url && title) {
      void this.searchSuggestedService.addSuggested({ title, query: url, intercepted });
    }

    // track and save original search value
    if (url.startsWith('/offers')) {
      this.searchService.dontSaveUserSearch = true;
      this.searchService.trackSearch({ query: url });
    }

    if (populatedUrl.startsWith('/')) {
      void this.router.navigateByUrl(populatedUrl, { replaceUrl: true });
    } else {
      window.location.replace(populatedUrl);
    }
  }

  private getSortedBroadMatches(broadMatches: SearchInterceptorRule[], query: string): SearchInterceptorRule[] {
    // Exact match that has type "broad".
    const sortedExact = broadMatches.filter(item => item.query.toLowerCase() === query.toLowerCase());
    // Matches where the matching `sint_q` has the fewest characters including spaces to the left of it.
    const startWithQuery = broadMatches
      .filter(item => item.query.toLowerCase() !== query.toLowerCase()
        && query.toLowerCase().indexOf(item.query.toLowerCase()) === 0)
      .sort((a, b) => {
        if (a.query.toLowerCase() === b.query.toLowerCase()) {
          return 0;
        }

        return a.query.toLowerCase() < b.query.toLowerCase() ? -1 : 1;
      });

    // Alphabetical Order based on the first letter following any whitespace after the word/phrase users searched for.
    // If no additional letters are present consider this alphabetically first.
    const startWithAny = broadMatches
      .filter(item => item.query.toLowerCase() !== query.toLowerCase()
        && query.toLowerCase().indexOf(item.query.toLowerCase()) > 0)
      .sort((a, b) => {
        const restA = a.query.substring(a.query.toLowerCase().indexOf(query.toLowerCase()) + query.length, a.query.length);
        const restB = b.query.substring(b.query.toLowerCase().indexOf(query.toLowerCase()) + query.length, b.query.length);

        if (restA.toLowerCase() === restB.toLowerCase()) {
          return 0;
        }

        return restA.toLowerCase() < restB.toLowerCase() ? -1 : 1;
      });
    return [
      ...sortedExact,
      ...startWithQuery,
      ...startWithAny
    ];
  }
}
