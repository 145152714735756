import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ModalAlert {
  title?: string;
  text: string;
  buttonText?: string;
  isButton?: boolean;
}

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.less']
})
export class ModalAlertComponent {

  @Input()
  content: ModalAlert = {
    title: '',
    text: '',
    buttonText: 'Ok',
    isButton: true
  };

  constructor(
    public activeModal: NgbActiveModal
  ) { }

}
