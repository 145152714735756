<div class="coupon">
  <div class="coupon-intro"
       *ngIf="isShowOfferCouponIntro">
    <p *ngIf="offer && offer.phone && offer.phone_note"
       [innerHTML]="offer.phone_note"></p>
  </div>
  <div class="coupon-intro">
    <p *ngIf="ad && itemHasProperties(ad, ['phone', 'phone_note'])"
       [innerHTML]="ad.phone_note"></p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(offer, ['instruction_text']) && !itemHasProperties(offer, ['phone']) && code">
    <p>{{offer.instruction_text}}</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(ad, ['instruction_text']) && !itemHasProperties(ad, ['phone']) && code">
    <p>{{ad.instruction_text}}</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(offer, ['code_instructions', 'code']) && !itemHasProperties(offer, ['phone']) && !hasUrlWithSUCToken()">
    <p [innerHTML]="offer.code_instructions "></p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(ad, ['code_instructions', 'code']) && !itemHasProperties(ad, ['phone']) && !hasUrlWithSUCToken()">
    <p [innerHTML]="ad.code_instructions"></p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(offer, ['instruction_text']) && !itemHasProperties(offer, ['phone']) && !hasDestinationUrl && !code">
    <p>Code already has been used.</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(ad, ['instruction_text']) && !itemHasProperties(ad, ['phone']) && !hasDestinationUrl && !code">
    <p>Code already has been used.</p>
  </div>

  <div class="coupon-phone"
       *ngIf="offer && offer.phone">
    <a class="btn btn-primary coupon-phone-button"
       [href]="'tel:' + getPhone(offer)"
       *ngIf="!isShownPhoneAction(offer)"
       (click)="onPhoneClick(getPhone(offer))">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="12"
           height="12"
           viewBox="0 0 12 12">
        <path fill="#FFF"
              fill-rule="nonzero"
              d="M2.41333 5.19333C3.37333 7.08 4.92 8.62 6.80667 9.58667L8.27333 8.12C8.45333 7.94 8.72 7.88 8.95333 7.96C9.7 8.20667 10.5067 8.34 11.3333 8.34C11.7 8.34 12 8.64 12 9.00667V11.3333C12 11.7 11.7 12 11.3333 12C5.07333 12 0 6.92667 0 0.666667C0 0.3 0.3 0 0.666667 0H3C3.36667 0 3.66667 0.3 3.66667 0.666667C3.66667 1.5 3.8 2.3 4.04667 3.04667C4.12 3.28 4.06667 3.54 3.88 3.72667L2.41333 5.19333Z" />
      </svg>
      <span>{{offer.phone}}</span>
    </a>
    <a class="btn btn-primary coupon-phone-button"
       *ngIf="isShownPhoneAction(offer)"
       (click)="onPhoneAction(offer.phone_action)">
      <span>{{offer.phone}}</span>
      <img *ngIf="!isPhoneLinkInternal(offer.phone_action)"
           class="coupon-phone-button-img"
           src="/assets/icons/external-source-link.svg"
           alt="" />
    </a>
  </div>

  <div class="coupon-phone"
       *ngIf="ad && ad.phone">
    <a class="btn btn-primary coupon-phone-button"
       [href]="'tel:' + getPhone(ad)"
       *ngIf="!isShownPhoneAction(ad)"
       (click)="onPhoneClick(getPhone(ad))">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="12"
           height="12"
           viewBox="0 0 12 12">
        <path fill="#FFF"
              fill-rule="nonzero"
              d="M2.41333 5.19333C3.37333 7.08 4.92 8.62 6.80667 9.58667L8.27333 8.12C8.45333 7.94 8.72 7.88 8.95333 7.96C9.7 8.20667 10.5067 8.34 11.3333 8.34C11.7 8.34 12 8.64 12 9.00667V11.3333C12 11.7 11.7 12 11.3333 12C5.07333 12 0 6.92667 0 0.666667C0 0.3 0.3 0 0.666667 0H3C3.36667 0 3.66667 0.3 3.66667 0.666667C3.66667 1.5 3.8 2.3 4.04667 3.04667C4.12 3.28 4.06667 3.54 3.88 3.72667L2.41333 5.19333Z" />
      </svg>
      <span>{{ad.phone}}</span>
    </a>
    <a class="btn btn-primary coupon-phone-button"
       *ngIf="isShownPhoneAction(ad)"
       (click)="onPhoneAction(ad.phone_action)">
      <span>{{ad.phone}}</span>
      <img *ngIf="!isPhoneLinkInternal(ad.phone_action)"
           class="coupon-phone-button-img"
           src="/assets/icons/external-source-link.svg"
           alt="" />
    </a>
  </div>

  <div class="coupon-separator"
       [class.coupon-separator-large]="code"
       *ngIf="itemHasProperties(offer, ['phone']) && (hasDestinationUrl || code)">
    <span>or</span>
  </div>
  <div class="coupon-separator"
       [class.coupon-separator-large]="code"
       *ngIf="itemHasProperties(ad, ['phone']) && (hasDestinationUrl || code)">
    <span>or</span>
  </div>

  <div class="coupon-intro"
       *ngIf="itemHasProperties(offer, ['instruction_text', 'phone']) && code">
    <p>{{offer.instruction_text}}</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(offer, ['instruction_text', 'phone']) && hasDestinationUrl && !code">
    <p>Code already has been used.</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(offer, ['code_instructions', 'code', 'phone']) && !hasUrlWithSUCToken()">
    <p [innerHTML]="offer.code_instructions"></p>
  </div>
  <div class="coupon-intro"
       *ngIf="isShowCouponIntro(ad)">
    <p>{{ad.instruction_text}}</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(ad, ['instruction_text', 'phone']) && !code">
    <p>Code already has been used.</p>
  </div>
  <div class="coupon-intro"
       *ngIf="itemHasProperties(ad, ['code_instructions', 'code', 'phone']) && !hasUrlWithSUCToken()">
    <p [innerHTML]="ad.code_instructions"></p>
  </div>

  <app-coupon-copy-code class="coupon-content"
                        *ngIf="code && !hasUrlWithSUCToken()"
                        [code]="code"
                        [buttonText]="buttonText"
                        [disableCopyButton]="!!offer.instruction_text"
                        [groupTabIndex]="groupTabIndex + 1"
                        (codeCopied)="onCodeCopied()"></app-coupon-copy-code>

  <div class="coupon-cta"
       *ngIf="hasDestinationUrl">
    <a class="btn btn-primary coupon-offer-link"
       [class.coupon-offer-link_large]="!code"
       [class.coupon-phone-link]="offer?.phone_action || ad?.phone"
       (click)="ctaClick($event)"
       [attr.href]="offer.url"
       role="button"
       [appAutoTabIndex]="groupTabIndex + 2"
       aria-label="Get offer"
       [target]="destinationUrlTarget">
      <span class="coupon-cta__action">GO TO SITE</span>
      <svg *ngIf="offer.destinationUrlNewTab || todayDeal?.new_tab" 
           class="icon"
           xmlns="http://www.w3.org/2000/svg"
           width="15"
           height="18"
           viewBox="0 0 16 16">
        <path fill='#FFF'
              fill-rule="nonzero"
              d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
      </svg>
    </a>
  </div>

  <div class="coupon-notification"
       *ngIf="isShowNotification()">
    <p>You may be prompted by your browser to accept clipboard-related permissions.</p>
  </div>
</div>
