export const THREE_MINUTES = 180;
export const ONE_MINUTE = 60;
export const CODE_PATTERN = '[0-9]{6}';
export const PHONE_NUMBER_PATTERN = '^[0-9]{7,12}$';
export const PHONE_CODE_PATTERN = '^[0-9]{1,3}$';
export const EMAIL_PATTERN = '\\S+@\\S+\\.\\S+';
export const CONTACT_ERROR_LIST = {
  email: {
    required: 'Email is required',
    pattern: 'Email is not valid'
  },
  phone: {
    phone_code: {
      required: 'Phone code is required',
      pattern: 'Phone code is not valid'
    },
    phone_number: {
      required: 'Phone number is required',
      pattern: 'Phone number is not valid'
    }

  }
};
export const PASSCODE_ERROR_LIST = {
  required: 'Passcode field is required',
  pattern: 'Passcode should contain 6 digits'
};
export const EDIT_MODE_ERROR = 'Please send for and enter the obtained code';
