import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { Component, EventEmitter, Output } from '@angular/core';
import { SplitTestEventsService } from '@app/shared/services/split-tests/split-test-events.service';
import { UrlHelperService } from 'g3-common-ui';

@Component({
  selector: 'app-hotels-mini',
  templateUrl: './hotels-mini.component.html',
  styleUrls: ['./hotels-mini.component.less']
})

export class HotelsMiniComponent {

  @Output() clickOut = new EventEmitter<ClickOutModel>();

  model: CustomWidgetModel;

  constructor(
    private splitTestEventsService: SplitTestEventsService,
    private urlHelper: UrlHelperService
  ) {}

  hotelClickOut(data: ClickOutModel): void {
    const isInternalClickout = data.model.isEbg || !this.urlHelper.isExternalUrl(data.url);
    if (isInternalClickout) {
      this.splitTestEventsService.emitInternalClickoutEvent();
    } else {
      this.splitTestEventsService.emitExternalClickoutEvent();
    }

    this.clickOut.emit(data);
  }
}
