<ng-container *ngIf="!!widgetClass && !!model">
  <div class="{{ widgetClass }}">
    <form class="widget-form"
          ngNoForm
          novalidate
          id="searchForm">
      <div class="{{ widgetClass }}-zip">
        <label class="widget-label {{ widgetClass }}-zip__label">Zip code</label>
        <input [appAutoTabIndex]="groupTabIndex + 150"
               id="zipCode"
               maxlength="5"
               name="zipCode"
               aria-label="zip code"
               type="text"
               [placeholder]="'e.g. 33101'"
               class="widget-input {{ widgetClass }}-zip__input"
               [(ngModel)]="zipCode" />
      </div>

      <div class="{{ widgetClass }}-action">
        <button [appAutoTabIndex]="groupTabIndex"
                class="btn btn-primary {{ widgetClass }}-action__search"
                type="submit"
                (click)="onTriggerSearch($event)"
                value="Search">
          Get Quotes&nbsp;
          <svg *ngIf="isExternalUrl && !model.isEbg"
               xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               viewBox="0 0 16 16">
            <path fill="#FFF"
                  fill-rule="nonzero"
                  d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
          </svg>
        </button>
      </div>
    </form>
  </div>
</ng-container>