import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from '@app/shared/services/config.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ModalConfig } from '@app/subscription-center/services/subscription-center.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { RouterData } from '@app/unsubscribe/interfaces/router-data.interface';
import { Subject } from 'rxjs';
import { SubscriptionsService } from '@app/unsubscribe/services/subscriptions.service';
import { SuccessContent, UnsubscribeOption } from '@app/unsubscribe/interfaces/subscription-options.interface';
import { UnsubscribeActions } from '@app/unsubscribe/enums/unsubscribe-actions.enum';
import { UnsubscribeAnalyticsService } from '@app/unsubscribe/services/unsubscribe-analytics.service';
import { UnsubscribeConfirmationModalComponent } from '../unsubscribe-confirmation-modal/unsubscribe-confirmation-modal.component';
import { UnsubscribeFormType } from '@app/unsubscribe/enums/form-type.enum';
import { UnsubscribeHandlerService } from '@app/unsubscribe/services/unsubscribes-handler.service';

@Component({
  selector: 'app-unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION],
})
export class UnsubscribePageComponent implements OnInit, OnDestroy {

  path: string;
  title: string;
  successOption: string;
  successPrimaryHeader: string;
  successSecondaryHeader: string;
  formType: UnsubscribeFormType;
  unsubscribeOptions: UnsubscribeOption[] = [];
  routerData: RouterData;
  actionCancel = UnsubscribeActions.Cancel;
  actionSelect = UnsubscribeActions.Select;
  actionSuccess = UnsubscribeActions.Success;

  private destroyStream = new Subject<void>();

  constructor(
    activatedRoute: ActivatedRoute,
    private unsubscribeHandlerService: UnsubscribeHandlerService,
    private router: Router,
    private config: ConfigService,
    private subscriptionsService: SubscriptionsService,
    private unsubscribeAnalyticsService: UnsubscribeAnalyticsService,
    private modalService: NgbModal
  ) {
    const params: Params = activatedRoute.snapshot.params;
    const queryParams: Params = activatedRoute.snapshot.queryParams;
    this.routerData = unsubscribeHandlerService.getRouterData(params, queryParams);
  }

  public get actionManage(): string {
    return UnsubscribeActions.Manage;
  }

  public get actionReturn(): string {
    return UnsubscribeActions.Return;
  }

  public ngOnInit(): void {
    this.getHeaderData();
    this.getUnsubscribeData();
  }

  private getUnsubscribeData(): void {
    const path = this.routerData.path;
    const query = this.routerData.query;
    this.subscriptionsService.getSubscriptions(path.subdomain, path.userdata, query.sid, query.btag, query.ctag)
      .pipe(take(1)).subscribe(response => this.unsubscribeOptions = this.unsubscribeHandlerService.mapResponseData(response));
  }

  public cancelAction(action = '', trackAnalytics = true): void {
    if (action && trackAnalytics) {
      const data = this.unsubscribeAnalyticsService.getDataForAnalytics(this.routerData, action, false);
      this.unsubscribeAnalyticsService.trackAnalyticsEvents(action, 'Unsubscription', data);
    }

    if (action === this.actionManage) {
      this.router.navigateByUrl('/my-account/email-subscriptions');
    } else {
      this.router.navigateByUrl('home');
    }
  }

  public onSuccessHeaders(event: SuccessContent): void {
    setTimeout(() => {
      this.successPrimaryHeader = event && event.primary ? event.primary : '';
      this.successSecondaryHeader = event && event.secondary ? event.secondary : '';
    });
  }

  public optOutAction(): void {
    const modalRef = this.modalService.open(
      UnsubscribeConfirmationModalComponent,
      ModalConfig
    );
    modalRef.componentInstance.title = 'Opt-Out';
    modalRef.componentInstance.message = 'Are you sure you want to opt-out of all email communications?';
    modalRef.componentInstance.unsubscribeAction
      .pipe(
        takeUntil(this.destroyStream),
        filter(receivedEntry => !!receivedEntry)
      ).subscribe(() => this.onConfirmOptOut());
  }

  private onConfirmOptOut(): void {
    this.successPrimaryHeader = 'Opted out!';
    this.successSecondaryHeader = 'You are opted out of email communications. To be re-enabled please contact <span class="customer-service">Customer Service.</span>';

    void this.subscriptionsService.patchSubscriptions(
      this.routerData.path.subdomain,
      this.routerData.path.userdata,
      this.subscriptionsService.getRequestBody('', null, true)
    );
  }

  public ngOnDestroy(): void {
    this.destroyStream.next();
    this.destroyStream.complete();
  }

  private getHeaderData(): void {
    this.path = this.config.getOption<string>('logo', '');
    this.title = this.config.getOption<string>('name', '');
  }

}
