import {
  AnalyticsAdEventModel,
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { calculateDaysLeft } from '@shared/services/analytics/offers-analytics.service';
import { Injectable } from '@angular/core';
import { SearchResultsAdsModel } from '@shared/models/search-results-ads.model';
import { UrlHelperService } from 'g3-common-ui';
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/

export type ActionType =
  'click'
  | 'click-internal'
  | 'click-out'
  | 'cta-button-click'
  | 'click-out-phone'
  | 'click-out-phone-cta'
  | 'click-copy-code'
  | 'click-reveal-code'
  | 'view'
  | 'offer-view';

interface G3AdEvent {
  date_time: Date;
  response_id: string;
  ad_type?: string;
  ad_guid?: string;
  click_url?: string;
  type?: string;
  guid?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdsAnalyticsService {

  constructor(
    private urlHelper: UrlHelperService,
  ) { }

  getOfferEventData(item: SearchResultsAdsModel): any {
    const result = {
      url: item.destinationUrl,
      offer: item.contentGuid
    };
    if (item.extraStatus) {
      result['extraStatus'] = item.extraStatus;
    }
    if (item.endDate) {
      result['daysLeft'] = calculateDaysLeft(item.endDate);
    }
    if (item.brandOverride) {
      result['brandOverride'] = item.brandOverride;
    }
    return result;
  }

  getG3AdOfferEventData(item: SearchResultsAdsModel, withPrefix = true, isClickOut = false, clickOut = ''): any {
    let result: G3AdEvent = {
      date_time: new Date(),
      response_id: item.response_id
    };

    result = withPrefix ? {
      ...result,
      ad_type: item.type,
      ad_guid: item.guid,
      click_url: item.destinationUrl
    } : {
      ...result,
      type: item.type,
      guid: item.guid,
      url: item.destinationUrl
    };

    if (item.brandOverride) {
      result['brand_override'] = item.brandOverride;
    }
    if (isClickOut && clickOut) {
      result['click_out'] = clickOut;
    }
    return result;
  }

  getAnalyticsGALabel(item: SearchResultsAdsModel): string {
    let label = `${item.guid} | ${item.type} | ${item.campaign} | ${item.content_guid}`;

    if (item.brandOverride) {
      label += ` | ${item.brandOverride}`;
    }
    return label;
  }

  getAnalyticsContentGALabel(item: SearchResultsAdsModel): string {
    let label = `${item.contentGuid} | ${item.vendorBrand}`;

    if (item.headerShort) {
      label += ` | ${item.headerShort}`;
    }
    if (item.brandOverride) {
      label += ` | ${item.brandOverride}`;
    }
    return label;
  }

  getAnalyticsEvents(action: ActionType, item: SearchResultsAdsModel = null): AnalyticsEvent[] {
    const gaCategory = 'ads';
    const gaLabel = this.getAnalyticsGALabel(item);
    const eventData = this.getOfferEventData(item);
    const clickOut = item.isEbg || !this.urlHelper.isExternalUrl(item.destinationUrl) ? 'internal' : 'external';
    const clickOutSuffix = ` | ${clickOut}`;

    switch (action) {
      case 'view':
        return [
          new AnalyticsAdEventModel('g3-ad-results', { date_time: new Date(), ads: [({ response_id: item.response_id })] }),
          new AnalyticsInternalEventModel('g3-ad-results', {
            date_time: new Date(),
            ad_type: item.type,
            ads: [({ response_id: item.response_id, guid: item.guid })]
          }),
          new AnalyticsGAEventModel('g3-ad-results', {
            category: 'search-result',
            label: gaCategory
          })
        ];
      case 'click':
        return [
          new AnalyticsAdEventModel('g3-ad-click', { date_time: new Date(), response_id: item.response_id })
        ];
      case 'click-internal':
        return [
          new AnalyticsInternalEventModel('g3-ad-click-internal', this.getG3AdOfferEventData(item, false)),
          new AnalyticsGAEventModel('g3-ad-click-internal', {
            category: gaCategory,
            label: gaLabel,
            action: 'g3-ad-click-internal'
          }),
        ];
      case 'offer-view':
        const eventsViewContent = [];
        if (item.contentGuid) {
          eventsViewContent.push(new AnalyticsInternalEventModel('offer-view', eventData));
          eventsViewContent.push(
            new AnalyticsGAEventModel('OfferView', {
              category: 'OfferInteraction',
              label: this.getAnalyticsContentGALabel(item)
            }));
        }
        return eventsViewContent;
      case 'click-reveal-code':
        const eventsRevealCode = [
          new AnalyticsInternalEventModel('g3-ad-reveal-code', this.getG3AdOfferEventData(item, true)),
          new AnalyticsGAEventModel('g3-ad-reveal-code', {
            category: gaCategory,
            label: gaLabel,
            action: 'ad-reveal-code',
          })
        ];
        if (item.contentGuid) {
          eventsRevealCode.push(new AnalyticsInternalEventModel('click-reveal-code', eventData));
          eventsRevealCode.push(
            new AnalyticsGAEventModel('OfferRevealCodeClick', {
              category: 'OfferInteraction',
              label: this.getAnalyticsContentGALabel(item)
            }));
        }
        return eventsRevealCode;
      case 'click-copy-code':
        const eventsCopyCode = [
          new AnalyticsInternalEventModel('g3-ad-copy-code', this.getG3AdOfferEventData(item, true)),
          new AnalyticsGAEventModel('g3-ad-copy-code', {
            category: gaCategory,
            label: gaLabel,
            action: 'ad-copy-code',
          })
        ];
        if (item.contentGuid) {
          eventsCopyCode.push(new AnalyticsInternalEventModel('click-copy-code', eventData));
          eventsCopyCode.push(
            new AnalyticsGAEventModel('OfferCopyCodeClick', {
              category: 'OfferInteraction',
              label: this.getAnalyticsContentGALabel(item)
            }));
        }
        return eventsCopyCode;
      case 'click-out-phone':
        const phoneLabel = `${gaLabel} | offerPhone | ${clickOut}`;
        return [
          new AnalyticsInternalEventModel('click-out', { offer_phone: item.guid, url: item.destinationUrl, click_out: clickOut }),
          new AnalyticsGAEventModel('click-out', { category: 'OfferInteraction', label: phoneLabel })
        ];
      case 'click-out-phone-cta':
        const label = gaLabel + clickOutSuffix;
        eventData['click_out'] = clickOut;
        return [
          new AnalyticsInternalEventModel('click-out', eventData),
          new AnalyticsGAEventModel('click-out', { category: 'OfferInteraction', label })
        ];
      case 'click-out':
      case 'cta-button-click':
        const eventsClickOut = [
          new AnalyticsAdEventModel('g3-ad-click', { response_id: item.response_id }),
          new AnalyticsGAEventModel('g3-ad-click-out', {
            category: gaCategory,
            label: gaLabel + clickOutSuffix,
            action: 'ad-click-out',
          }),
          new AnalyticsInternalEventModel('g3-ad-click-out', this.getG3AdOfferEventData(item, false, true, clickOut)),
        ];
        // if there is connected offer
        if (item.contentGuid) {
          eventData['click_out'] = clickOut;
          eventsClickOut.push(new AnalyticsInternalEventModel('click-out', eventData));
          eventsClickOut.push(new AnalyticsGAEventModel('OfferLinkOutClick', {
            category: 'OfferInteraction',
            label: this.getAnalyticsContentGALabel(item) + clickOutSuffix
          }));
        }
        return eventsClickOut;
    }
  }
}
