import {
  EmailOption,
  SubscriptionOptions,
  SubscriptionsResponse
} from '../interfaces/subscription.interface';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { MarketingEmailTypeEnum } from '../enums/marketing-email.enum';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject } from 'rxjs';
import { KnownUserService } from '@shared/services/known-user.service';

export const ModalConfig: NgbModalOptions = {
  windowClass: 'confirmation-modal',
  backdrop: 'static'
};
export const GLOBAL_SUBSCRIBE = 'subscribe';
export const GLOBAL_UNSUBSCRIBE = 'unsubscribe';

interface SubscriptionUpdates {
  [key: string]: {
    title: string;
    items: {
      [key: string]: string | string[];
    }[];
  };
}

interface SubscriptionUpdatesStorage {
  [key: string]: {
    exclusions: string[];
    name: string;
    frequency: string;
    status: boolean;
  };
}

interface SendSubscriptionsUpdatesEmailProps {
  guid?: string;
  userGuid?: string;
  marketingEmail: string;
  subscriptionUpdates?: SubscriptionUpdates;
  firstName?: string;
  userId?: string;
}

@Injectable()
export class SubscriptionCenterService {
  private currentUserEmailStream: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private knownUserService: KnownUserService
  ) {}

  public getCurrentUserEmail(): Observable<string> {
    return this.currentUserEmailStream.asObservable();
  }

  public updateCurrentUserEmail(value: string): void {
    this.currentUserEmailStream.next(value);
  }

  getSubscriptionData(selectedEmail = ''): Observable<SubscriptionsResponse> {
    let query = '';
    if (this.knownUserService.knowUserGuid) {
      query += `&known_user_guid=${this.knownUserService.knowUserGuid}`;
    }

    if (this.knownUserService.knowUserChildGuid) {
      query += `&known_child_guid=${this.knownUserService.knowUserChildGuid}`;
    }

    return this.http.get<SubscriptionsResponse>(
      `${
        environment.apiUrl
      }/api/profile/subscriptions?email=${encodeURIComponent(
        selectedEmail
      )}${query}`
    );
  }

  async updateSubscriptions(
    id: string,
    body: SubscriptionOptions
  ): Promise<unknown> {
    if (this.knownUserService.knowUserGuid) {
      body.known_user_guid = this.knownUserService.knowUserGuid;
    }

    if (this.knownUserService.knowUserChildGuid) {
      body.known_child_guid = this.knownUserService.knowUserChildGuid;
    }

    return this.http
      .patch(`${environment.apiUrl}/api/profile/subscriptions/${id}`, body)
      .toPromise();
  }

  /**
   * Send subscription changes via email.
   * If a user GUID is not provided, attempt checking KnownUserService.
   * If subscription updates are not provided, attempt checking LocalStorage
   *
   * @param props
   * @returns
   */
  sendSubscriptionsUpdatesEmail({
    guid,
    firstName,
    userId,
    marketingEmail,
    subscriptionUpdates
  }: SendSubscriptionsUpdatesEmailProps): Observable<unknown> {
    let params = this.knownUserService.knowUserChildGuid ? '?isChild=true' : '';

    if (this.knownUserService.knowUserChildGuid) {
      guid = this.knownUserService.knowUserChildGuid;
    } else if (this.knownUserService.knowUserGuid) {
      guid = this.knownUserService.knowUserGuid;

      // * Corner case fix for "Known" users that are unconfirmed
      if (firstName?.toLowerCase()?.trim() === 'known' && !userId) {
        guid = this.knownUserService.knowUserGuid;
        params = '';
      }
    }

    subscriptionUpdates =
      subscriptionUpdates || this.parseChangedSubscriptions();

    if (!subscriptionUpdates || Object.values(subscriptionUpdates).length < 1) {
      return EMPTY;
    }


    return this.http.post(
      `${environment.apiUrl}/api/subscriptions/${guid}/send-email/${marketingEmail}${params}`,
      subscriptionUpdates
    );
  }

  /**
   * @param primaryEmail : matches @param marketingEmail === 'primary'
   * @param secondaryEmail : matches @param marketingEmail === 'secondary'
   * @param marketingEmail : target email (corresponds subscription)
   */
  checkSelectedValue(
    primaryEmail: string,
    secondaryEmail: string,
    marketingEmail: string
  ): EmailOption {
    let selectedValue: EmailOption;
    switch (marketingEmail) {
      case MarketingEmailTypeEnum.Primary:
        selectedValue = this.getSelectedValue(
          MarketingEmailTypeEnum.Primary,
          primaryEmail
        );
        break;
      default:
        selectedValue = this.getSelectedValue(
          MarketingEmailTypeEnum.Secondary,
          secondaryEmail
        );
    }

    return selectedValue;
  }

  getSelectedValue(value: string, text: string): EmailOption {
    return { value, text };
  }

  collectSubscriptionsChanges(
    key: string,
    value: string,
    subscriptionID: string,
    subscriptionStatus = null
  ): void {
    const existedItems = JSON.parse(
      sessionStorage.getItem('subscriptionUpdates')
    );
    if (existedItems && existedItems[subscriptionID]) {
      if (key === 'exclusions') {
        const existedExclusion = existedItems[subscriptionID].exclusions.find(
          i => i === value
        );
        if (!existedExclusion) {
          existedItems[subscriptionID]['exclusions'].push(value);
        }
      } else {
        existedItems[subscriptionID][key] = value;
        if (
          subscriptionStatus !== null &&
          existedItems[subscriptionID]['status'] !== subscriptionStatus
        ) {
          existedItems[subscriptionID]['status'] = subscriptionStatus;
        }
      }
      sessionStorage.setItem(
        'subscriptionUpdates',
        JSON.stringify(existedItems)
      );
    } else {
      sessionStorage.setItem(
        'subscriptionUpdates',
        JSON.stringify(
          this.getNewSubscriptionUpdate(
            key,
            value,
            subscriptionID,
            subscriptionStatus
          )
        )
      );
    }
  }

  getNewSubscriptionUpdate(
    key: string,
    value: string,
    subscriptionID: string,
    subscriptionStatus = null
  ): SubscriptionUpdatesStorage {
    const existedItems: SubscriptionUpdatesStorage = JSON.parse(
      sessionStorage.getItem('subscriptionUpdates')
    );
    return {
      ...existedItems,
      [subscriptionID]: {
        exclusions: key === 'exclusions' && value ? [value] : [],
        name: key === 'name' ? value : '',
        frequency: key === 'frequency' ? value : '',
        status: subscriptionStatus !== null ? subscriptionStatus : null
      }
    };
  }

  parseChangedSubscriptions(): SubscriptionUpdates {
    const subscriptionUpdates = {
      removedExclusions: {
        title: 'Subscription Brand/Category Exclusion Removed:',
        items: []
      },
      frequencyAdjustment: {
        title: 'Subscription Frequency Adjustments:',
        items: []
      },
      subscriptionsEnabled: {
        title: 'Subscriptions Enabled:',
        items: []
      },
      subscriptionsDisabled: {
        title: 'Subscriptions Disabled:',
        items: []
      }
    };

    const existedItems: SubscriptionUpdatesStorage = JSON.parse(
      sessionStorage.getItem('subscriptionUpdates')
    );
    if (!isEmpty(existedItems)) {
      Object.keys(existedItems).forEach(property => {
        if (!isEmpty(existedItems[property])) {
          subscriptionUpdates.removedExclusions.items = [
            ...subscriptionUpdates.removedExclusions.items,
            ...existedItems[property]['exclusions']
          ];

          if (existedItems[property]['frequency']) {
            subscriptionUpdates.frequencyAdjustment.items.push(
              existedItems[property]['frequency']
            );
          }

          if (
            existedItems[property]['name'] &&
            existedItems[property]['status']
          ) {
            subscriptionUpdates.subscriptionsEnabled.items.push(
              existedItems[property]['name']
            );
          } else {
            subscriptionUpdates.subscriptionsDisabled.items.push(
              existedItems[property]['name']
            );
          }
        }
      });

      Object.keys(subscriptionUpdates).forEach(
        property =>
          (subscriptionUpdates[property].items = subscriptionUpdates[
            property
          ].items.filter(i => !!i))
      );
      sessionStorage.removeItem('subscriptionUpdates');
      return subscriptionUpdates;
    } else {
      return null;
    }
  }
}
