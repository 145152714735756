import {
  Directive,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  Input,
  Injectable
} from '@angular/core';

@Injectable({ providedIn: 'root' })
@Directive({
  selector: '[appGooglePlaces]',
  exportAs: 'ngx-google-places'
})
export class NgxGooglePlacesDirective implements OnInit {
  @Input() public options: google.maps.places.AutocompleteOptions;

  @Output() public addressChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();

  public element: HTMLInputElement;
  public autocomplete: google.maps.places.Autocomplete;
  public eventListener: google.maps.MapsEventListener;
  public ngZone: NgZone;
  public place: google.maps.places.PlaceResult;

  public constructor(elRef: ElementRef, ngZone: NgZone) {
    this.element = elRef.nativeElement;
    this.ngZone = ngZone;
  }

  public ngOnInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.element);

    // ? Monitor place changes in the input
    if (this.autocomplete.addListener !== null) {
      this.eventListener = google.maps.event.addListener(this.autocomplete, 'place_changed', () => this.handleChangeEvent());
    }
  }

  public reset(): void {
    this.autocomplete?.setComponentRestrictions(this.options?.componentRestrictions || null);
    this.autocomplete?.setTypes(this.options?.types || []);
  }

  public handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete?.getPlace();

      if (this.place) {
        this.addressChange.emit(this.place);
      }
    });
  }
}
