/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { CcpaRequestFormData, ContactUsFormData, ContactUsResponse } from '@policies/policies-forms/contact-us.interface';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemMessageService } from 'g3-common-ui';
import { Observable } from 'rxjs';

export const CCPA_SUBMIT_MESSAGE = 'Your request has been submitted. We will contact you as soon as possible.';
export const CONTACT_SUBMIT_MESSAGE = 'Thanks for reaching out!';
export const NOMINATION_SUBMIT_MESSAGE = 'Nomination submitted. Thank you for the suggestion.';
export const PHONE_REGEX_PATTERN = '[- +()0-9]{8,16}';
@Injectable()
export class ContactUsService {

  constructor(
    private http: HttpClient,
    private systemMessageService: SystemMessageService
  ) { }

  onCcpaRequestFormSubmit(event: CcpaRequestFormData): void {
    this.sendCcpaRequestInfo(event).subscribe(() => {
      this.showSuccessSystemMessage(CCPA_SUBMIT_MESSAGE);
    }, error => {
      this.showErrorSystemMessage(error);
    });
  }

  onContactUsFormSubmit(event: ContactUsFormData, isNominateFormMode = false): void {
    const successMessageText = isNominateFormMode ? NOMINATION_SUBMIT_MESSAGE : CONTACT_SUBMIT_MESSAGE;

    this.sendContactUsInfo(event).subscribe(() => {
      this.showSuccessSystemMessage(successMessageText);
    }, error => {
      this.showErrorSystemMessage(error);
    });
  }

  sendCcpaRequestInfo(data: CcpaRequestFormData): Observable<ContactUsResponse> {
    return this.http.post<ContactUsResponse>(`${environment.apiUrl}/api/ccpa-request`, data);
  }

  sendContactUsInfo(data: ContactUsFormData): Observable<ContactUsResponse> {

    if (!data.subject) {
      data.subject = data.reason; // subject field is required in the old help modal form
    }

    return this.http.post<ContactUsResponse>(`${environment.apiUrl}/api/contact-us`, {
      ...data,
      contact_type: ['user'],
      url: window.location.href
    });
  }

  private showSuccessSystemMessage(text: string): void {
    this.systemMessageService.setMessage({
      type: 'success',
      text
    });
  }

  private showErrorSystemMessage(error: any): void {
    this.systemMessageService.setMessage({
      type: 'error',
      text: this.systemMessageService.getErrorFromException(error)
    });
  }

}
