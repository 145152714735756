/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
class GoogleCalendarEvent {
  private text: string;
  private startDate: string;
  private endDate: string;
  private startTime: string;
  private endTime: string;
  private details: string;
  private location: string;

  constructor({
    text,
    startDate,
    endDate,
    startTime,
    endTime,
    details,
    location,
  }) {
    this.text = text;
    this.startDate = startDate;
    this.endDate = endDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.details = details;
    this.location = location;
  }

  addEvent(): any {
    return `http://www.google.com/calendar/event?action=TEMPLATE&${this.getQuery()}`;
  }

  getQuery(): any {
    const query = [];

    if (this.text) {
      query.push(`text=${this.text}`);
    }

    if (this.startDate && !this.startTime && this.endDate && !this.endTime) {
      query.push(`dates=${this.startDate}/${this.endDate}`);
    }

    if (this.startDate && this.startTime && this.endDate && !this.endTime) {
      query.push(`dates=${this.startDate}T${this.startTime}/${this.endDate}`);
    }

    if (this.startDate && this.startTime && this.endDate && this.endTime) {
      query.push(`dates=${this.startDate}T${this.startTime}/${this.endDate}T${this.endTime}`);
    }

    if (this.startDate && this.startTime && !this.endDate && this.endTime) {
      query.push(`dates=${this.startDate}T${this.startTime}/${this.startDate}T${this.endTime}`);
    }

    if (this.details) {
      query.push(`details=${this.details}`);
    }

    if (this.location) {
      query.push(`location=${this.location}`);
    }

    return query.join('&');

  }
}

export default GoogleCalendarEvent;
