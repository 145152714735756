<div class="error-page info-page">
  <div class="logo">
    <div>
      <img src="/assets/beneplace-logo-wide.png"
           alt="Beneplace"
           loading="lazy"
           class="big-logo" />
    </div>
  </div>
  <div *ngIf="!loading">
    <h1 [innerHTML]="text"></h1>
    <a class="btn btn-primary btn-l"
       href={{moreInfoUrl}}>More info</a>
  </div>
</div>
