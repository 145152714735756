<div class="google-places-autocomplete__wrapper"
		 [class.wide-container]="wideFormat">
	<input appGooglePlaces
				 #placesRef="ngx-google-places"
				 class="google-places-autocomplete {{ classes }}"
				 [options]='settings'
				 [class.wide-view]="wideFormat"
				 [placeholder]="placeholder"
				 [value]="autocompleteOptions?.inputString || ''"
				 (input)="onDestinationChange($event)"
				 (addressChange)="handleAddressChange($event)" />
</div>