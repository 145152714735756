import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input
} from '@angular/core';
import { HomepageHorizontalScrollAbstract } from '@shared/components/horizontal-scroll/homepage-horizontal-scroll.abstract';
import { WINDOW } from 'g3-common-ui';

const GHOST_ITEM_CLASSNAME = 'ghost';
const DOUBLE_WIDE_CLASSNAME = 'tier-1';
// At the first card we removed left padding '9px' to align cards with header.
// Cards use box-sizing: content-box;
const LEFT_PADDING_WIDTH = 8;

@Component({
  selector: 'app-cards-horizontal-scroll',
  templateUrl: './cards-horizontal-scroll.component.html',
  styleUrls: ['./cards-horizontal-scroll.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsHorizontalScrollComponent extends HomepageHorizontalScrollAbstract {

  @Input() hideGhostEffect = false;
  @Input() isDesktop = true;
  @Input() hideArrows = false;
  @Input() isProductCards = false;

  cardsScrollHidden = true;

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Inject(WINDOW) protected window: WINDOW) {
    super(changeDetectorRef, window);
  }

  get getSliderWidth(): string {
    if (this.isDesktop && this.hideGhostEffect && this.wrapper && this.wrapper.nativeElement) {
      if (this.hovered || this.scrolled) {
        return this.calculateWidth();
      } else {
        return this.isProductCards
          ? `${this.wrapper.nativeElement.offsetWidth + 20 + parseInt(this.ghostOffset, 10)}px`
          : `${this.wrapper.nativeElement.offsetWidth + parseInt(this.ghostOffset, 10)}px`;
      }
    } else {
      return this.calculateWidth();
    }
  }

  rightOffset(hideOffset: string): string {
    return this.isProductCards ? `${parseInt(hideOffset, 10) + 20}px` : hideOffset;
  }

  setChildrenWidth(): void {
    const itemWidth = this.getItemWidth();
    const itemDoubleWidth = itemWidth * 2;
    let isFirstCard = true;

    this.slider.nativeElement.childNodes.forEach(item => {
      // skip 'comment' nodes and ghost element
      if (!item.style || !item.classList || item.classList.contains(GHOST_ITEM_CLASSNAME)) {
        return;
      }

      const targetNode = item.querySelector('.card-wrapper');
      const isPaylogix = targetNode?.classList.contains('paylogix');

      if (targetNode && !isPaylogix) {
        targetNode.classList.add('cards_scroll');
      }

      if (item.classList.contains(DOUBLE_WIDE_CLASSNAME)) {
        item.style.minWidth = `${isFirstCard ? itemDoubleWidth - LEFT_PADDING_WIDTH : itemDoubleWidth}px`;
        item.style.maxWidth = `${isFirstCard ? itemDoubleWidth - LEFT_PADDING_WIDTH : itemDoubleWidth}px`;
        isFirstCard = false;

        if (targetNode && !isPaylogix) {
          targetNode.classList.add('cards_scroll-double');
        }
      } else {
        item.style.minWidth = `${isFirstCard ? itemWidth - LEFT_PADDING_WIDTH : itemWidth}px`;
        item.style.maxWidth = `${isFirstCard ? itemWidth - LEFT_PADDING_WIDTH : itemWidth}px`;
        isFirstCard = false;
      }
      this.cardsScrollHidden = false;
    });
    this.changeDetectorRef.detectChanges();
  }

}
