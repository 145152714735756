import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import { Marker } from '@app/shared/interfaces/marker.interface';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';

@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION],
})

export class OfferPreviewComponent {
  @Input() marker: Marker;
  @Input() groupTabIndex: number;

  @Output() panelStatus = new EventEmitter<boolean>();
  @Output() cardGuid = new EventEmitter<string>();

  constructor(
    private directClickOutService: DirectClickOutService
  ) { }

  public getOfferCard(): void {
    setTimeout(() => {
      this.panelStatus.emit();
      this.cardGuid.emit(this.marker.guid);
    }, 100);
  }

  public getType(): string {
    if (this.marker.code) {
      return 'code';
    } else if (this.marker.url || this.marker.url_type === 'dynamic') {
      return 'external';
    }
    return 'none';
  }

  public buttonClick(): void {
    this.cardGuid.emit('default');
    if (this.marker.code) {
      const offersPanel: HTMLElement = document.querySelector('.list-view-panel.show-panel');
      if (offersPanel) {
        offersPanel.style.zIndex = '999';
      }
    }

    void this.directClickOutService.providerLocations.handleCtaClick(this.marker);
  }

  public getTitle(): string {
    return this.marker.ctaButtonTitle || 'GET OFFER';
  }

  get isVisible(): boolean {
    return !!(this.marker.code || this.marker.url || this.marker.url_type === 'dynamic');
  }
}
