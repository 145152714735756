/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CDNControl, MarketplaceFeaturesResponse } from 'g3-common-ui';
import { environment } from '@environments/environment';

import { PaylogixSubscription } from '../models/offer.model';

export interface ExperimentItem {
  guid: string;
  experiment_id: string;
  optimize_id: string;
  is_running: boolean;
  variation: number;
}

export interface EntryLink {
  label: string;
  url: string;
}

export interface MarketplaceColors {
  primary: string;
  secondary: string;
  accent: string;
}

export interface ControlsResponse {
  gtag_id: string;
  goto_url_path: string;
  experiments: ExperimentItem[];
  enrollment_buildup: EnrollmentBuildUpInfo;
  widgets: Widget[];
  features: string[];
  cdn: CDNControl[];
  cloud_image_type: string;
  dynamic_image_host: string;
  api_cdn_override: string;
  send_data_to_adobe_analytics: string;
  progressive_loading: string;
  use_post_decisions: string;
  mreg_active: boolean;
}

export interface VendorSettings {
  paylogix_sitename: string;
  paylogix_data?: [{
    default?: boolean;
    org_id?: string;
    sitename?: string;
  }];
  paylogix_verification_fields: string[];
  enable_beyond_enroll?: boolean;
}

export interface ConfigApiResponse {
  name: string;
  description: string;
  subdomain: string;
  prev_subdomain: string;
  moved_to: string;
  logo: string;
  favicon: string;
  owner: string;
  term_manager: string;
  term_members: string;
  colors: MarketplaceColors;
  color_theme: { [key: string]: string };
  is_allow_guest_access: boolean;
  is_retroactive: boolean;
  is_block_emails: boolean;
  is_allow_registration: boolean;
  is_require_passcode: boolean;
  is_allow_edit_email: boolean;
  is_require_password: boolean;
  auth_path: string;
  signout_title: string;
  id_title: string;
  is_display_user_id: boolean;
  welcome_header?: string;
  welcome_body?: string;
  disclaimer?: string;
  auth_bg_images?: any[];
  redirect_to?: string;
  features: MarketplaceFeaturesResponse;
  cdn: ConfigCdnResponse;
  client_offer: ClientOfferResponse;
  client_offer_pills: ClientOfferPillsResponse;
  client_category_pills: CategoryOfferPillsResponse;
  custom_links?: CustomLinkResponse[];
  is_allow_user_profile_birthday: boolean;
  is_allow_user_profile_gender: boolean;
  mark_promo_email?: boolean;
  is_sso: boolean;
  translate_language_codes: string[];
  countries_interstitial_string: string;
  entry_label: string;
  entry_links: EntryLink[];
  exclude_brands: string[];
  exclude_categories: string[];
  primary_top_level_domain: string;
  guid: string;
  allowed_countries: {
    country_code: string;
    country_name_en: string;
    country_icon_url: string;
  }[];
  sign_in_mode: string;
  wag3_admin_guid?: string;
  external_company_code?: string;
  salesforce_account_name?: string;
  session_disclaimer?: string;
  subscription_change_timeout: number;
  loyalty: boolean;
  ebghost: string;
  salesforce_corporate_data: {
    affiliate_id: string;
    affiliate_name: string;
    industry: string;
  };
  vendor_settings: VendorSettings;
  corporate_links?: CorporateLinks;
  user_otp_force_resend_debounce_mins?: number;
}

export interface CorporateLinks {
  funlife_history_url?: string;
  order_history_url?: string;
  funlife_transfer_points?: string;
}

export interface EnrollmentResponse {
  start_date: string;
  end_date: string;
  prior_notification: number;
  section_name: string;
  more_info: string;
  cta_primary_link: string;
  cta_primary_text: string;
  cta_secondary_link: string;
  cta_secondary_text: string;
  enrollment_off_cat: string;
  headline: string;
  products: Array<any>;
  paylogixSubscriptions: PaylogixSubscription[];
  help_info: string;
  cta_view_all_text: string;
  cta_view_all_link: string;
  cta_is_ebg: boolean;
  style: 'none' | 'staggered' | 'straight';
  full_background: 'illustrations' | 'shapes' | 'custom';
  section_icon: 'banner' | 'handshake' | 'star' | 'custom';
  section_icon_url: string;
  full_background_url: string;
  full_background_url_l: string;
  full_background_url_m: string;
  full_background_url_s: string;
  full_background_url_xs: string;
  countries: string[];
  date_visibility: boolean;
}

export interface EnrollmentInfo extends Omit<EnrollmentResponse, 'start_date' | 'end_date'> {
  start_date: Date;
  end_date: Date;
  isEnrollmentAvailable: boolean;
}

export interface EnrollmentBuildUpInfo {
  start_date: Date;
  end_date: Date;
  section_name: string;
  more_info: string;
  countries: string[];
}

export interface ConfigCdnResponse {
  api_host?: string;
  static_images_host?: string;
}

export interface CustomLinkResponse {
  caption: string;
  url: string;
  guid: string;
  location: string;
}

export interface ClientOfferResponse {
  description: string;
  list_disclaimer: string;
  list_name: string;
  short_name: string;
}

export interface ClientOfferPillsResponse  {
  pills_enabled: boolean;
  pill_icon: string;
  pill_text: string;
}

export interface CategoryOfferPillsResponse  {
  pills_enabled: boolean;
  pill_icon: string;
  pill_text: string;
  pill_category: number;
}

export interface Widget {
  name: string;
  img_url: string;
}

export interface ControlsObject {
  gtag_id: string;
  goto_url_path: string;
  experiments: ExperimentItem[];
  enrollment_buildup: EnrollmentBuildUpInfo;
  widgets: Widget[];
  features: string[];
  cdn: CDNControl[];
  cloud_image_type: string;
  dynamic_image_host: string;
  api_cdn_override: string;
  send_data_to_adobe_analytics: string;
  progressive_loading: string;
  use_post_decisions: string;
  strapi_version: string;
  mreg_active: boolean;
}

export interface UpgradePagesImage {
  external_name: string;
  image_path: string;
}
export interface UpgradePageImagesResponse {
  images: UpgradePagesImage[];
}

export enum HeaderAnimationState {
  in = 'in',
  out = 'out'
}

export enum cdnHostTypes {
  apiHost,
  staticImagesHost
}

@Injectable()
export class ConfigService {
  public controls: Partial<ControlsObject> = {};
  public headerAnimationState = new Subject<HeaderAnimationState>();
  public notificationBannerState = new Subject<boolean>();
  public isAdobeQAModeEnabled = false;
  private config: Partial<ConfigApiResponse> = {};

  constructor(private http: HttpClient, private injector: Injector) {}

  private get router(): Router {
    return this.injector.get(Router);
  }

  public getConfig(): ConfigApiResponse {
    return this.config as ConfigApiResponse;
  }

  public async onInit(): Promise<void> {
    await this.loadMarketplaceInfoThenRoute();
    await this.initControls();
  }

  public async loadMarketplaceInfoThenRoute(): Promise<any> {
    try {
      if (Object.keys(this.getConfig()).length < 1) {
        this.config = await this.getMarketplaceInfo();
      }
      const data = this.getConfig();
      const { features } = data;
      if ( features ) {
        this.isAdobeQAModeEnabled = features.adobe_qa_mode || false;
      }
      const currentHost = window.location.host.split('.')[0];
      const prevSubdomain = data.prev_subdomain;
      const subdomain = data.subdomain;

      if (prevSubdomain === currentHost) {
        window.location.href = this.replacePreviousHost(
          window.location.href,
          prevSubdomain,
          subdomain
        );
      }

      const topDomain = this.getMarketplaceTopDomain(data);
      const isCorrectTopDomain = window.location.host.endsWith(topDomain);

      if (!isCorrectTopDomain) {
        window.location.href = window.location.href.replace(
          window.location.host,
          `${currentHost}.${topDomain}`
        );
      }
    } catch (err) {
      console.error(err);

      if (
        err.status === 404 ||
        (err.error &&
          err.error.errors &&
          err.error.errors[0].field === 'sub_domain')
      ) {
        void this.router.navigate(['/no-site']);
      }
    }
  }

  public getOption<T>(key: string, defaultValue: T = null): T {
    if (this.config && key in this.config) {
      return this.config[key] as T;
    }

    return defaultValue;
  }

  public getUserIdFieldFromConfig(): string {
    return this.config.id_title;
  }

  public getUserIdDisplayFlagFromConfig(): boolean {
    return this.config.is_display_user_id;
  }

  public getCloudImageType(): string {
    return this.controls.cloud_image_type || '';
  }

  public getDynamicImageHost(): string {
    return this.controls.dynamic_image_host || '';
  }

  public sendViaAdobeSatelite(): boolean {
    return typeof this.controls.send_data_to_adobe_analytics === 'string'
      ? this.controls.send_data_to_adobe_analytics === 'true'
      : !!this.controls.send_data_to_adobe_analytics;
  }

  public isZonesProgressiveLoadingEnabled(): boolean {
    return this.controls.progressive_loading === 'true';
  }

  public isUsePostForSearch(): boolean {
    return this.controls.use_post_decisions === 'true';
  }

  public async getAuthInfo(): Promise<any> {
    try {
      const data = await this.http
        .get<ConfigApiResponse>(`${environment.apiUrl}/api/info?authInfo=true`)
        .toPromise();

      return {
        name: data.name || '',
        header: data.welcome_header || '',
        body: data.welcome_body || '',
        disclaimer: data.disclaimer || '',
        bgImages: data.auth_bg_images || [],
        redirect_to: data.redirect_to || ''
      };
    } catch (err) {
      return {
        name: '',
        header: '',
        body: '',
        disclaimer: '',
        redirect_to: ''
      };
    }
  }

  public async initControls(): Promise<boolean> {
    try {
      const config = this.getConfig();

      if (!config) {
        throw new Error('Missing configuration');
      }

      const url =
        this.controls && this.controls.cdn
          ? this.controls.cdn
          : environment.apiUrl;

      const controls = await this.http
        .get<ControlsResponse>(`${url}/api/controls/${config.subdomain}`)
        .toPromise();

      this.controls.gtag_id = controls.gtag_id;
      this.controls.goto_url_path = controls.goto_url_path;
      this.controls.experiments = controls.experiments;
      this.controls.enrollment_buildup = controls.enrollment_buildup;
      this.controls.widgets = controls.widgets;
      this.controls.features = controls.features;
      this.controls.cdn = controls.cdn;
      this.controls.cloud_image_type = controls.cloud_image_type;
      this.controls.dynamic_image_host = controls.dynamic_image_host;
      this.controls.api_cdn_override = controls.api_cdn_override;
      this.controls.send_data_to_adobe_analytics = String(controls.send_data_to_adobe_analytics);
      this.controls.progressive_loading = String(controls.progressive_loading);
      this.controls.use_post_decisions = String(controls.use_post_decisions);
      this.controls.mreg_active = controls.mreg_active;

      return true;
    } catch (err) {
      console.error(err);
    }
  }

  public getGtagId(): string {
    if (this.controls.gtag_id) {
      return this.controls.gtag_id;
    }

    return '';
  }

  public getGotoUrlPath(): string {
    if (this.controls.goto_url_path) {
      return this.controls.goto_url_path;
    }

    return '';
  }

  public getExperiments(): ExperimentItem[] {
    return this.controls.experiments;
  }

  public replacePreviousHost(url, prevSubdomain, newSubdomain): string {
    const regexp = new RegExp(`//${prevSubdomain}.`);
    return url.replace(regexp, '//' + newSubdomain + '.');
  }

  public getMarketplaceTopDomain(marketplace: {
    primary_top_level_domain?: string;
  }): string {
    if (marketplace.primary_top_level_domain) {
      return marketplace.primary_top_level_domain;
    }
    return environment.oauth.url.split('.').slice(1).join('.');
  }

  public async getUpgradePagesImages(pageType: string): Promise<UpgradePageImagesResponse> {
    return this.http
      .get<UpgradePageImagesResponse>(`${environment.apiUrl}/api/info/images/${pageType}`)
      .toPromise();
  }

  public getCustomLinks(location?: string): CustomLinkResponse[] {
    const customLinks = this.getOption<CustomLinkResponse[]>('custom_links', null) || [];

    customLinks.sort((a, b) => {
      if (a.caption === b.caption) {
        return 0;
      } else {
        return a.caption > b.caption ? 1 : -1;
      }
    });

    if (location) {
      return customLinks.filter(e => e.location === location);
    }

    return customLinks;
  }

  public getWidgetConfig(name: 'truecar' | 'hotel_discounts'): Widget {
    return this.controls.widgets.find(i => i.name === name);
  }

  public hasFeature(featureName: string): boolean {
    return !!this.controls.features.find(i => i === featureName);
  }

  public getFeature<T>(featureName: string, defaultValue: T): T {
    if (this.config.features && featureName in this.config.features) {
      return this.config.features[featureName] as T;
    }

    return defaultValue;
  }

  public getSubdomain(): string {
    return this.config.subdomain; // 0th item is api_host
  }

  public getExcludeBrands(): string[] {
    const excludeBrands = this.getOption('exclude_brands');
    return Array.isArray(excludeBrands) ? excludeBrands : [];
  }

  public getExcludeCategories(): number[] {
    const excludeCategories = this.getOption('exclude_categories');
    return Array.isArray(excludeCategories) ? excludeCategories : [];
  }

  public getCdn(cdnName: cdnHostTypes): string {
    if (!this.controls.cdn) {
      return '';
    }
    if (cdnName === cdnHostTypes.apiHost) {
      const apiHost = this.controls.cdn.find(item => item.name === 'api_host');
      const apiCdnOverride = this.controls.api_cdn_override;
      if (apiCdnOverride && apiCdnOverride !== '') {
        return this.formatCdnUrl(apiCdnOverride);
      }
      return apiHost ? apiHost.value : '';
    } else if (cdnName === cdnHostTypes.staticImagesHost) {
      const staticImagesHost = this.controls.cdn.find(
        item => item.name === 'static_images_host'
      );
      return staticImagesHost ? this.formatCdnUrl(staticImagesHost.value) : '';
    } else {
      return '';
    }
  }

  public getHeaderAnimationState(): Observable<HeaderAnimationState> {
    return this.headerAnimationState.asObservable();
  }

  public updateHeaderAnimationState(data: HeaderAnimationState): void {
    this.headerAnimationState.next(data);
  }

  public getNotificationBannerState(): Observable<boolean> {
    return this.notificationBannerState.asObservable();
  }

  public updateNotificationBannerState(data: boolean): void {
    this.notificationBannerState.next(data);
  }

  public isSubscriptionCenterEnabled(value: string): boolean {
    return value && value.includes('US') && this.config.mark_promo_email;
  }

  public async getMarketplaceInfo(): Promise<ConfigApiResponse> {
    return this.http
      .get<ConfigApiResponse>(`${environment.apiUrl}/api/info`)
      .toPromise();
  }

  public formatCdnUrl(url: string): string {
    if (url) {
      return url.startsWith('http') ? url : 'https://' + url;
    }
    return '';
  }

  public getIsPaylogixAllowed(): boolean {
    return this.config.vendor_settings?.paylogix_data.length && this.config.vendor_settings.enable_beyond_enroll;
  }
}
