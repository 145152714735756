import { OfferBadgeData } from '@shared/interfaces/offer-badge-data.interface';

interface OfferBadgePropertiesData {
  offerData?: OfferBadgeData;
  isShowExpired?: boolean;
  screenWidth?: number;
  offerClass?: string;
  isPaylogixOffer?: boolean;
  isEnrolledProduct?: boolean;
  userHasPaylogixId?: boolean;
  isSearch?: boolean;
}

export class OfferBadgePropertiesModel {

  public offerData: OfferBadgeData;
  public isShowExpired: boolean;
  public screenWidth: number;
  public offerClass: string;
  public isPaylogixOffer: boolean;
  public isEnrolledProduct: boolean;
  public userHasPaylogixId: boolean;
  public isSearch: boolean;

  constructor(data: OfferBadgePropertiesData = {}) {
    this.offerData = data.offerData !== undefined ? data.offerData : null;
    this.isShowExpired = data.isShowExpired !== undefined ? data.isShowExpired : true;
    this.screenWidth = data.screenWidth !== undefined ? data.screenWidth : 0;
    this.offerClass = data.offerClass !== undefined ? data.offerClass : '';
    this.isPaylogixOffer = data.isPaylogixOffer !== undefined ? data.isPaylogixOffer : false;
    this.isEnrolledProduct = data.isEnrolledProduct !== undefined ? data.isEnrolledProduct : false;
    this.userHasPaylogixId = data.userHasPaylogixId !== undefined ? data.userHasPaylogixId : false;
    this.isSearch = data.isSearch !== undefined ? data.isSearch : false;
  }

}
