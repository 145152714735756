/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import get from 'lodash/get';
import { SearchResultBadge } from './search-results-item.model';
import { SearchAdsItemResponse } from '@shared/services/ads/search-ads-items-response.interface';
import { OfferClass, OfferExternalData, OfferExternalSources, PaylogixSubscription, CODE_INSTRUCTIONS_DEFAULT} from '@shared/models/offer.model';
import { AccessSettingOption } from '@shared/interfaces/search-offer-item.interface';
import { KeyValue } from '@angular/common';
import { ImgixSettings } from 'g3-common-ui';

type classType = OfferClass.Exclusive | OfferClass.Coupon | OfferClass.Editorial | OfferClass.Client | '';

export class SearchResultsAdsModel {

  public guid: string;
  public headerLong: string;
  public headerShort: string;
  public vendorBrand: string;
  public descriptionSearch: string;
  public logoVendorSquare: string;
  public button: string;
  public terms: string;
  public destinationUrl: string;
  public destinationUrlNewTab: boolean;
  public urlType: string;
  public isEbg: boolean;
  public code: string;
  public isCouponHidden: boolean;
  public isAllowedDestinationUrl: boolean;
  public views: number;
  public endDate: Date;
  public extraStatus: string;
  public contentGuid: string;
  public isSaved: boolean;
  public descriptionDetails: string;
  public response_id: string;
  public campaign: string;
  public content_guid: string;
  public type: string;
  public phone: string;
  public phone_note: string;
  public phone_action: string;
  public image: string;
  public imageSquare: string;
  public instruction_text: string;
  public code_instructions: string;
  public class: classType;
  public catCodeTagIds: number[];
  public productSubHeading?: string;
  public brandOverride?: string;
  public descriptionDisclaimer?: string;
  public urlParams?: string;
  public access_setting: AccessSettingOption;
  public source_based_params: KeyValue<string, string>[];
  public paylogix_enrolled_header_short: string;
  public external_source: string;
  public internalUrlParams: string;
  public g3UrlParams: string;
  public external_data: OfferExternalData;
  public paylogixSubscription: PaylogixSubscription;
  public is_widget_iframe: boolean;
  public is_widget_disclaimer: boolean;
  public image_imgix_settings?: ImgixSettings;

  constructor(data: SearchAdsItemResponse) {
    this.guid = data.guid;
    this.headerLong = data.content_data ? data.content_data.getOption('off_header_long', '') : '';
    this.headerShort = data.content_data ? data.content_data.getOption('off_header_short', '') : '';
    this.vendorBrand = data.content_data && data.content_data.getOption('off_vendor_brand', '') || '';
    this.descriptionSearch = data.content_data ? data.content_data.getOption('off_description_search', '') : '';
    this.descriptionDisclaimer = data.content_data ? data.content_data.getOption('off_description_disclaimer', '') : '';
    this.logoVendorSquare = data.content_data ? data.content_data.getOption('off_logo_vendor_square', '') : '';
    this.button = data.content_data ? data.content_data.getOption('off_button', '') : '';
    this.terms = data.content_data ? data.content_data.getOption('off_terms', '') : '';
    this.urlType = data.content_data && data.content_data.url_type ? data.content_data.url_type : '';
    this.destinationUrl = data.content_data ? data.content_data.getOption('off_destination_url', '') : '';
    this.urlParams = data.append_url_params ? data.append_url_params : '';
    this.destinationUrlNewTab = data.content_data && data.content_data.data
      ? SearchResultsAdsModel.getDestinationUrlNewTab(data.content_data.data['off_destination_url_new_tab'], true)
      : true;
    this.code = data.content_data ? data.content_data.getOption('off_code', '') : '';
    this.isCouponHidden = data.content_data && data.content_data.data ? data.content_data.data['off_coupon_hide'] : false;
    this.descriptionDetails = data.content_data ? data.content_data.getOption('off_description_details', '') : '';
    this.phone = data.content_data ? data.content_data.getOption('off_phone', '') : '';
    this.phone_note = data.content_data ? data.content_data.getOption('off_phone_note', '') : '';
    this.phone_action = data.content_data ? data.content_data.getOption('off_phone_action', '') : '';
    this.instruction_text = data.content_data ? data.content_data.getOption('off_instruction_text', '') : '';
    this.code_instructions = data.content_data ? data.content_data.getOption('off_code_instructions', CODE_INSTRUCTIONS_DEFAULT) : CODE_INSTRUCTIONS_DEFAULT;
    this.isAllowedDestinationUrl = data.content_data ? !!data.content_data.is_allowed_destination_url : true;
    this.views = data.content_data ? (data.content_data.views || 0) : 0;
    this.endDate = data.content_data ? (data.content_data.end_date || undefined) : undefined;
    this.extraStatus = data.content_data ? (data.content_data.extra_status || '') : '';
    this.isSaved = data.content_data ? (data.content_data.is_saved || false) : false;
    this.contentGuid = data.content_guid || '';
    this.response_id = data.response_id || '';
    this.campaign = data.campaign || '';
    this.content_guid = data.content_guid || '';
    this.type = data.type || '';
    this.class = (data.content_data && data.content_data.class ? data.content_data.class : '') as classType;
    this.catCodeTagIds = data.content_data ? (data.content_data.cat_code_tag_ids || []) : [];
    this.isEbg = data.isEbg;
    this.productSubHeading = data.content_data ? data.content_data.getOption('off_product_sub_heading', '') : '';
    this.brandOverride = data.content_data ? data.content_data.getOption('off_brand_override', '') : '';
    this.access_setting = data.content_data ? data.content_data.access_setting : null;
    this.source_based_params = data.content_data ? data.content_data.source_based_params : [];
    this.image = this.getImage(data);
    this.imageSquare = data.content_data ? data.content_data.getOption('off_image_sq', '') : '';
    this.image_imgix_settings = data.image_imgix_settings || null;
    this.paylogix_enrolled_header_short = data.content_data && data.content_data.paylogix_enrolled_header_short || '';
    this.external_source = data.content_data ? data.content_data.getOption('off_external_source', '') : '';
    this.external_data = data.content_data ? data.content_data.getOption('off_external_data', null) : null;
    this.paylogixSubscription = get(data, 'content_data.paylogix_subscription', null);
    this.internalUrlParams = data.append_internal_url_params ? data.append_internal_url_params : '';
    this.g3UrlParams = data.append_g3_url_params || '';
    this.is_widget_iframe = data.content_data ? data.content_data.getOption('off_is_widget_iframe', false) : false;
    this.is_widget_disclaimer = data.content_data ? data.content_data.getOption('off_is_widget_disclaimer', false) : false;
  }

  static getDestinationUrlNewTab(value: string | boolean, defaultValue = true): boolean {
    if (typeof value === 'boolean') {
      return value;
    }

    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return defaultValue;
    }
  }

  public getBadges(offersLikeBudges: SearchResultBadge[]): SearchResultBadge[] {
    const result = offersLikeBudges || [] as SearchResultBadge[];
    result.push({
      title: 'AD',
      type: 'ads'
    });
    return result;
  }

  getCtaButtonTitle(): string {
    return this.button || 'GET OFFER';
  }

  hasCouponCode(): any {
    return !!this.code;
  }

  hasPhone(): any {
    return !!this.phone;
  }

  hasDestinationUrl(): any {
    return !!this.destinationUrl;
  }

  getImage(data: SearchAdsItemResponse): string {
    if (data.image_source && data.image_url) {
      return data.image_url;
    }

    return data.content_data ? data.content_data.getOption('off_image_01', '') : '';
  }

  get isPaylogixOffer(): boolean {
    return this.external_source === OfferExternalSources.Paylogix;
  }

}
