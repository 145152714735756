import { Ad } from '@app/shared/models/ad.model';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferModel } from '@app/shared/models/offer.model';
import { SearchResultsAdsModel } from '@app/shared/models/search-results-ads.model';

@Component({
  selector: 'app-ad-disclaimer-modal',
  templateUrl: './ad-disclaimer-modal.component.html',
  styleUrls: ['./ad-disclaimer-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdDisclaimerModalComponent implements AfterViewInit {

  @Input() ad: Ad | OfferModel | SearchResultsAdsModel;

  private defaultPosition = 0;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  get brandTitle(): string {
    if (this.ad instanceof OfferModel) {
      return this.ad.vendorBrand && this.ad.vendorBrand.title ? this.ad.vendorBrand.title : '';
    }

    if (this.ad instanceof Ad) {
      return this.ad.company;
    }

    if (this.ad instanceof SearchResultsAdsModel) {
      return this.ad.vendorBrand;
    }

    return '';
  }

  ngAfterViewInit(): void {
    this.defaultPosition = window.scrollY;
  }

  closeModal(): void {
    this.activeModal.close();
    window.scrollTo(0, this.defaultPosition); // Prevent auto-scrolling
  }

}
