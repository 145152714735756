import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Breakpoints } from '@app/shared/enums/breakpoints.enum';
import { ModalService } from '@shared/services/modal.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-notification',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.less']
})
export class ModalNotificationComponent implements OnInit, OnDestroy {

  @Input() headline = '';
  @Input() description = '';
  @Input() sampleUrl = '';
  @Input() set destinationUrl(value: string) {
    this.selectedOffersUrl = `/offers?s=${value}`;
  }

  get destinationUrl(): string {
    return this.selectedOffersUrl;
  }

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  public selectedOffersUrl = '';

  constructor(
    public modalService: ModalService
  ) { }

  public ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$
      .pipe(
        map(el => {
          const target = el.target as Window;
          return target.innerWidth;
        }),
        filter(val => val <= Breakpoints.Small)
      ).subscribe(() => {
        if (this.modalService.hasOpenModal()) {
          this.modalService.closeModal('close');
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }

}
