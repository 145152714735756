import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { RouterData } from '@app/unsubscribe/interfaces/router-data.interface';
import { SelectedUnsubscribe, SuccessContent, UnsubscribeOption } from '@app/unsubscribe/interfaces/subscription-options.interface';
import { SubscriptionsService } from '@app/unsubscribe/services/subscriptions.service';
import { UnsubscribeActions } from '@app/unsubscribe/enums/unsubscribe-actions.enum';
import { UnsubscribeAnalyticsService } from '@app/unsubscribe/services/unsubscribe-analytics.service';

export interface MappedSelectedItem {
  btag: string;
  ctag: string;
  sub_name: string;
}

@Component({
  selector: 'app-unsubscribe-option',
  templateUrl: './unsubscribe-option.component.html',
  styleUrls: ['./unsubscribe-option.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubscribeOptionComponent {

  @Input() unsubscribeOptions: UnsubscribeOption[];
  @Input() submitFormState = false;
  @Input() routerData: RouterData;

  @Output() successHeaders: EventEmitter<SuccessContent> = new EventEmitter<SuccessContent>();

  selectedUnsubscribes: SelectedUnsubscribe[] = [];
  isExistedSelectedValues = false;

  constructor(
    private router: Router,
    private subscriptionsService: SubscriptionsService,
    private unsubscribeAnalyticsService: UnsubscribeAnalyticsService
  ) { }

  onSuccessHeaders(event: SuccessContent): void {
    this.successHeaders.emit(event);
  }

  onSelectedValuesExist(event: boolean): void {
    this.isExistedSelectedValues = event;
  }

  async submitForm(buttonId: string): Promise<void> {
    if (buttonId === 'family' && this.isExistedSelectedValues) {
      this.submitFormState = true;
    }

    if (buttonId === 'manage') {
      void this.router.navigateByUrl('/my-account/email-subscriptions');
    }

    if (buttonId === 'all') {
      this.successHeaders.emit({
        primary: 'Unsubscribed!',
        secondary: 'You are now unsubscribed from all promotional emails.'
      });

      await this.subscriptionsService.patchSubscriptions(
        this.routerData.path.subdomain,
        this.routerData.path.userdata,
        this.subscriptionsService.getRequestBody('', true)
      );
    }

    if (this.selectedUnsubscribes.length) {
      this.selectedUnsubscribes.forEach(unsubscribe => {
        this.unsubscribeAnalyticsService.trackAnalyticsEvents(
          UnsubscribeActions.Success,
          'Unsubscription',
          this.unsubscribeAnalyticsService.getDataForAnalytics(this.routerData, unsubscribe.name)
        );
      });
    }
  }

  async onSelectedUnsubscribes(event: SelectedUnsubscribe[]): Promise<void> {
    this.selectedUnsubscribes = event;

    await this.subscriptionsService.patchSubscriptions(
      this.routerData.path.subdomain,
      this.routerData.path.userdata,
      this.subscriptionsService.getRequestBody(
        this.routerData.query.sid,
        false,
        false,
        this.mapSelectedItems(event).btag,
        this.mapSelectedItems(event).ctag,
        this.mapSelectedItems(event).sub_name,
        this.getOptionValue(event, 'sid') ? true : null
      )
    );

    event.forEach(unsubscribe => {
      this.unsubscribeAnalyticsService.trackAnalyticsEvents(
        UnsubscribeActions.Select,
        'Unsubscription',
        this.unsubscribeAnalyticsService.getDataForAnalytics(this.routerData, unsubscribe.name)
      );
    });
  }

  private mapSelectedItems(selectedItems: SelectedUnsubscribe[]): MappedSelectedItem {
    return {
      btag: this.getOptionValue(selectedItems, 'btag'),
      ctag: this.getOptionValue(selectedItems, 'ctag'),
      sub_name: this.getOptionValue(selectedItems, 'sub_name')
    };
  }

  private getOptionValue(selectedItems: SelectedUnsubscribe[], key: string): string {
    return selectedItems.filter(i => i.type === key).map(i => i.id).join(',');
  }
}
