/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Injectable } from '@angular/core';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');
      const dateObj: NgbDateStruct = { day: null as number, month: null as number, year: null as number };
      const dateLabels = Object.keys(dateObj);
      dateParts.forEach((datePart, idx) => dateObj[dateLabels[idx]] = parseInt(datePart, 10) || null as any);
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? `${this.padNumber(date.month)}/${this.padNumber(date.day)}/${date.year || ''}` : '';
  }

  toNgbDate(date: Date, daysToAdd = null): NgbDateStruct {
    if (daysToAdd) {
      date.setDate(date.getDate() + daysToAdd);
    }
    const result = date.toISOString().split('T')[0].split('-');
    return NgbDate.from({
      year: parseInt(result[0], 10),
      month: parseInt(result[1], 10),
      day: parseInt(result[2], 10)
    });
  }

  convertDateToFormat(date: string): string {
    const splittedDate = date.split('/');
    return `${splittedDate[2]}-${splittedDate[0]}-${splittedDate[1]}`;
  }

  toggleDatepicker(event: Event, datepicker: NgbInputDatepicker): void {
    event.preventDefault();
    event.stopPropagation();
    datepicker.toggle();
  }

  handleTimezoneDiff(stringDate: string): Date {
    const date = new Date(stringDate);
    date.setDate(date.getDate() + 1);
    return date;
  }

  private padNumber(value: number | null): string {
    if (!isNaN(value) && value !== null) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

}
