import { DEFAULT_ADULTS, MIN_ROOMS } from '../common/rooms-and-guests/room.constants';
import { Injectable } from '@angular/core';
import { RoomData, RoomsWrapper } from '../common/rooms-and-guests/room-data.interface';

@Injectable()
export class HotelRoomsService {

  getRoomsWrapperData(rooms: RoomData[]): RoomsWrapper {
    const roomFormData = { rooms: rooms.length };

    rooms.forEach((value, index) => {
      if (value.adults) {
        roomFormData[`room_occupants[${index}][adults]`] = value.adults;
      }

      if (value.children && value.children.length) {
        roomFormData[`room_occupants[${index}][children]`] = value.children.length;
        value.children.forEach((c, i) => roomFormData[`room_occupants[${index}][child_ages][${i}]`] = parseInt(c.age, 10));
      }
    });

    return roomFormData;
  }

  getDefaultRoomsWrapperData(): RoomsWrapper {
    return {
      'room_occupants[0][adults]': DEFAULT_ADULTS,
      rooms: MIN_ROOMS
    };
  }

  getRoomsAndGuestsTitle(rooms: RoomData[] = []): string {
    const roomsCount = rooms && rooms.length ? rooms.length : 1;
    const adultsCount = rooms && rooms.length ? rooms.map(room => room.adults).reduce((curr, acc) => curr + acc, 0) : 2;
    const kidsCount = rooms && rooms.length ? rooms.map(room => room.kids).reduce((curr, acc) => curr + acc, 0) : 0;
    const guestsCount = adultsCount + kidsCount;

    return `${roomsCount} room${this.getPlural(roomsCount)}, ${guestsCount} guest${this.getPlural(guestsCount)}`;
  }

  getPlural(count: number): string {
    return count > 1 ? 's' : '';
  }

}
