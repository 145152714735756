<ng-container *ngIf="widgets && widgets.length">
  <section *ngIf="zoneHeaderName"
           class="container main-container wide-container widgets-container">
    <app-zone-header [data]="data"
                     [groupTabIndex]="groupTabIndex"
                     (showAllClick)="showAllClickHandler($event)">
    </app-zone-header>
  </section>

  <div [attr.id]="anchorLink"
       class="container main-container mini-widgets {{ wideContainerClass }}"
       [class.no-header]="!zoneHeaderName">
    <div class="mini-widgets-wrapper wrapper-variation-icon">
      <ng-container *ngFor="let widget of widgets; trackBy: trackById; let i = index">
        <app-widgets-mini-container class="variation-icon"
                                    [sectionIndex]="1000 - sectionIndex * 10 - i - 1"
                                    [widget]="widget"
                                    [groupTabIndex]="groupTabIndex + i * 200">
        </app-widgets-mini-container>
      </ng-container>
    </div>
  </div>
</ng-container>
