import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Ad } from '@app/shared/models/ad.model';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.less']
})
export class CategoryItemComponent {

  @Input() public category: Partial<Ad> = { titleShort: null, imageSquare: null, adUrl: ''};

  @Output() public brokenImageUrl: EventEmitter<string> = new EventEmitter();

  public mouseJustLeft = false;

  public onMouseLeave(): void {
    this.mouseJustLeft = true;
  }

  public emitBrokenImageUrl(): void {
    this.brokenImageUrl.emit(this.category.titleShort);
  }

}
