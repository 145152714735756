/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '@shared/services/config.service';
import { HomepageAdsService } from './homepage-ads.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomepageDataLoaderService {

  private loadingSource = new BehaviorSubject<boolean>(true);
  loading$ = this.loadingSource.asObservable();

  isDataLoaded = false;
  isClosedFlyIn = false;

  constructor(
    private homepageAdsService: HomepageAdsService,
    private configService: ConfigService,
  ) { }

  async loadData(setFeaturedBrands: boolean) {
    this.loadingSource.next(true);
    const brands = setFeaturedBrands ? await this.homepageAdsService.getFeaturedBrands() : null;

    if (brands) {
      this.homepageAdsService.setNextBrands(brands);
    }
    this.loadingSource.next(false);

    // // set it to true, we don't need to update homepage data when we redirected to homepage from another page
    this.isDataLoaded = true;

  }

  public isEnabledFeaturedBrands(): boolean {
    return this.configService.hasFeature('ad_model_featured_brands') || false;
  }

}
