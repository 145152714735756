/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ValidCustomWidgetIdTypes } from '@app/widgets/constants/widget-ids.constant';
import { WidgetModel, WidgetType } from './widget.model';

export class CustomWidgetModel implements WidgetModel {
  readonly widgetType = WidgetType.Custom;

  public clickUrl: string;
  public impressionUrl: string;
  public data: any;
  public widgetId: ValidCustomWidgetIdTypes | any;
  public vendorId: number;
  public header: string;
  public logoUrl: string;
  public imageUrl: string;
  public id: string;
  public responseId: string;
  public destinationUrl: string;
  public iconUrl: string;
  public subHeader: string;
  public isEbg: boolean;
  public guid: string;
  public rentalCarHostUrl?: string;
  public catCodeTagIds?: number[];
  public extraStatus?: string;
  public endDate?: Date;
  public class?: string;
  public brandName?: string;

  constructor(data: any) {
    this.clickUrl = data.clickUrl;
    this.impressionUrl = data.impressionUrl;
    this.data = data.data;
    this.widgetId = data.widgetId;
    this.vendorId = data.vendorId;
    this.header = data.header;
    this.logoUrl = data.logoUrl;
    this.imageUrl = data.imageUrl;
    this.responseId = data.responseId;
    this.id = data.id;
    this.destinationUrl = data.destinationUrl;
    this.iconUrl = data.iconUrl;
    this.subHeader = data.subHeader;
    this.isEbg = data.isEbg;
    this.guid = data.guid;
    this.rentalCarHostUrl = data.rentalCarHostUrl ? data.rentalCarHostUrl : '';
    this.catCodeTagIds = data.catCodeTagIds ? data.catCodeTagIds : [];
    this.extraStatus = data.extraStatus ? data.extraStatus : '';
    this.endDate = data.endDate ? data.endDate : null;
    this.class = data.class ? data.class : '';
  }
}

export interface ClickOutModel {
  model: CustomWidgetModel;
  url: string;
}
