export class SUCHelper {
  /**
   * Checks if the offer url isexternal and contains SUC code inside
   *
   * @param url  Destination URL
   * @param code SUC Code
   * @returns Is URL suc token
   */
  static isExternalUrlWithSucToken(url: string, code: string): boolean {
    return url && (url.includes('http:') || url.includes('https:'))
      && this.containsSucToken(code)
      && this.containsSucToken(url, false, code)
      || false;
  }


  /**
   *
   * @param code Code
   * @param tokenOnly Use SUC Token pattern
   * @param specificToken SUC Token
   * @returns Does contain token code
   */
  static containsSucToken(code: string, tokenOnly = true, specificToken?: string): boolean {
    if (!code) {
      return false;
    }

    const sucPart = code.split('^')[0];
    let sucPattern = specificToken || '%suc-.+%';
    if (tokenOnly) {
      sucPattern = `^${sucPattern}$`;
    }
    return new RegExp(sucPattern).test(sucPart);
  }
}
