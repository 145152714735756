import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input
} from '@angular/core';
import { HomepageHorizontalScrollAbstract } from '@shared/components/horizontal-scroll/homepage-horizontal-scroll.abstract';
import { WINDOW } from 'g3-common-ui';

const GHOST_ITEM_CLASSNAME = 'ghost';
// At the first card we removed left padding '9px' to align cards with header.
// Cards use box-sizing: content-box;
const LEFT_PADDING_WIDTH = 8;

@Component({
  selector: 'app-homepage-horizontal-scroll',
  templateUrl: './homepage-horizontal-scroll.component.html',
  styleUrls: ['./homepage-horizontal-scroll.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageHorizontalScrollComponent extends HomepageHorizontalScrollAbstract {

  @Input() hideGhostEffect = false;
  @Input() isDesktop = true;
  @Input() hideArrows = false;
  @Input() isBrandSection = false;

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Inject(WINDOW) protected window: WINDOW) {
    super(changeDetectorRef, window);
  }

  get getSliderWidth(): string {
    if (this.isDesktop && this.hideGhostEffect && this.wrapper && this.wrapper.nativeElement) {
      return this.hovered || this.scrolled
        ? this.calculateWidth()
        : `${this.wrapper.nativeElement.offsetWidth + parseInt(this.ghostOffset, 10)}px`;
    } else {
      return this.calculateWidth();
    }
  }

  setChildrenWidth(): void {
    const itemWidth = this.getItemWidth();
    let isFirstCard = true;

    this.slider.nativeElement.childNodes.forEach(item => {
      // skip 'comment' nodes and ghost element
      if (!item.style || !item.classList || item.classList.contains(GHOST_ITEM_CLASSNAME)) {
        return;
      }

      if (this.isBrandSection) {
        isFirstCard = false;
        return;
      }

      item.style.minWidth = `${isFirstCard ? itemWidth - LEFT_PADDING_WIDTH : itemWidth}px`;
      item.style.maxWidth = `${isFirstCard ? itemWidth - LEFT_PADDING_WIDTH : itemWidth}px`;
      isFirstCard = false;
    });
  }
}
