<section *ngIf="hintLabelVisible"
         class="label-container">
  <div class="loyalty-badge-container loyalty-badge-container--large">
    <div data-qa="badge-loyalty-image"
         class="loyalty-image">
    </div>
  </div>

  <span
    class="label-description"
    aria-label="Hint label description"
    data-qa="label-description"
  >
    {{ hintLabel }}
  </span>

  <app-disclaimer-button
    [tooltipText]="hintLabel"
    aria-label="Hint label end icon"
    data-qa="label-end-icon"
    placement="top"
    class="ad-sub-heading-disclaimer">
  </app-disclaimer-button>
</section>
