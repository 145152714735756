/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AnalyticsService } from './analytics.service';
import { DirectClickOutAdCardsService } from '@shared/services/direct-click-out/direct-click-out-ad-cards.service';
import { DirectClickOutAdsService } from '@shared/services/direct-click-out/direct-click-out-ads.service';
import { DirectClickOutOffersService } from '@shared/services/direct-click-out/direct-click-out-offers.service';
import { DirectClickOutProviderLocationsService } from '@shared/services/direct-click-out/direct-click-out-provider-locations.service';
import { DirectClickOutTodayDealsService } from '@shared/services/direct-click-out/direct-click-out-today-deals.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DirectClickOutService {

  constructor(
    private analyticsService: AnalyticsService,
    public ads: DirectClickOutAdsService,
    public todayDeals: DirectClickOutTodayDealsService,
    public providerLocations: DirectClickOutProviderLocationsService,
    public offers: DirectClickOutOffersService,
    public adCards: DirectClickOutAdCardsService
  ) { }

  trackAnalyticsEvents(action: any, data: any, customGAEventAction = ''): void {
    try {
      this.analyticsService.eventsTrack(this.adCards.getGeneralAnalyticsEvents(action, data, customGAEventAction));
    } catch {
      console.error('error posting analytics event');
    }
  }

}
