/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class FilterStateService {

  storedDateSliderValues = {
    min: null,
    max: null,
    qAndCategory: '',
  };
  searchEvent = new EventEmitter();

  constructor() { }

  setCurrentValues(min: number, max: number, qAndCategory: string): void {
    this.storedDateSliderValues.min = min;
    this.storedDateSliderValues.max = max;
    this.storedDateSliderValues.qAndCategory = qAndCategory;
  }

  getCurrentValues(): any {
    return this.storedDateSliderValues;
  }

  emitSearchEvent(): void {
    this.searchEvent.next(true);
  }
}
