import { AutocompleteDropdownSettings } from '@app/shared/components/autocomplete-dropdown/autocomplete-data.interface';

export interface ValueText {
  value: string | number;
  text: string;
}

export const Times: ValueText[] = [
  { value: '00:00', text: 'Midnight' },
  { value: '00:30', text: '12:30am' },
  { value: '1:00', text: '1:00am' },
  { value: '1:30', text: '1:30am' },
  { value: '2:00', text: '2:00am' },
  { value: '2:30', text: '2:30am' },
  { value: '3:00', text: '3:00am' },
  { value: '3:30', text: '3:30am' },
  { value: '4:00', text: '4:00am' },
  { value: '4:30', text: '4:30am' },
  { value: '5:00', text: '5:00am' },
  { value: '5:30', text: '5:30am' },
  { value: '6:00', text: '6:00am' },
  { value: '6:30', text: '6:30am' },
  { value: '7:00', text: '7:00am' },
  { value: '7:30', text: '7:30am' },
  { value: '8:00', text: '8:00am' },
  { value: '8:30', text: '8:30am' },
  { value: '9:00', text: '9:00am' },
  { value: '9:30', text: '9:30am' },
  { value: '10:00', text: '10:00am' },
  { value: '10:30', text: '10:30am' },
  { value: '11:00', text: '11:00am' },
  { value: '11:30', text: '11:30am' },
  { value: '12:00', text: 'Noon' },
  { value: '12:30', text: '12:30pm' },
  { value: '13:00', text: '1:00pm' },
  { value: '13:30', text: '1:30pm' },
  { value: '14:00', text: '2:00pm' },
  { value: '14:30', text: '2:30pm' },
  { value: '15:00', text: '3:00pm' },
  { value: '15:30', text: '3:30pm' },
  { value: '16:00', text: '4:00pm' },
  { value: '16:30', text: '4:30pm' },
  { value: '17:00', text: '5:00pm' },
  { value: '17:30', text: '5:30pm' },
  { value: '18:00', text: '6:00pm' },
  { value: '18:30', text: '6:30pm' },
  { value: '19:00', text: '7:00pm' },
  { value: '19:30', text: '7:30pm' },
  { value: '20:00', text: '8:00pm' },
  { value: '20:30', text: '8:30pm' },
  { value: '21:00', text: '9:00pm' },
  { value: '21:30', text: '9:30pm' },
  { value: '22:00', text: '10:00pm' },
  { value: '22:30', text: '10:30pm' },
  { value: '23:00', text: '11:00pm' },
  { value: '23:30', text: '11:30pm' }
];

export const Ages: ValueText[] = [
  { value: '25', text: '25 or older' },
  { value: '24', text: '21 to 24' },
  { value: '20', text: '18 to 20' }
];

export const RentalCarDropdownSettings: AutocompleteDropdownSettings = {
  threshold: 3,
  itemsToShow: 3,
  placeholder: '',
  inputString: '',
  inputIconUrl: '/assets/icons/ic-location.svg',
  backdropClick: true
};

export const RentalCarDropOffDropdownSettings: AutocompleteDropdownSettings = {
  threshold: 3,
  itemsToShow: 3,
  placeholder: '',
  inputString: '',
  inputIconUrl: '/assets/icons/ic-location.svg',
  backdropClick: true
};

export const dropdownTitlesData = {
  airportsIcon: '/assets/icons/ic_plane.svg',
  citiesIcon: '/assets/icons/ic_city.svg',
  airportsTitle: 'Airports',
  citiesTitle: 'Cities, addresses',
};
