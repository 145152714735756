/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export enum WidgetType {
  // 100s are for markup / external loaded widgets
  Html = 100,

  // 200s are for metadata-driven + dynamically created widgets
  StandardForm = 200,

  // 300s are for custom, Angular-defined widgets
  Custom = 300
}

export interface WidgetModel {
  readonly widgetType: WidgetType;
  widgetId?: any;
  impressionUrl?: string;
  html?: string;
}
