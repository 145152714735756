/* eslint-disable typescriptESlintPlugin/no-misused-promises */
import { Component, Input, Output, EventEmitter, OnDestroy, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { ACCOUNT_CONFIRMATION_DROPDOWN } from '@app/shared/constants/scroll-id.constants';
import { ScrollToElementService } from '@app/shared/services/scroll-to-element.service';
import { AccountConfirmationService, TRANSITION_TIME } from '@shared/services/account-confirmation.service';
import { WindowHelper, WINDOW } from 'g3-common-ui';
import { ModalTodayDealService } from '../modal-pass-thru/modal-today-deal.service';
import { OfferModel } from '@app/shared/models/offer.model';
import { SearchResultsTodayDealModel } from '@app/shared/models/search-results-today-deal.model';
import { SearchResultsAdsModel } from '@app/shared/models/search-results-ads.model';
import { take } from 'rxjs';

@Component({
  selector: 'app-card-access-lock',
  templateUrl: './card-access-lock.component.html',
  styleUrls: ['./card-access-lock.component.less']
})
export class CardAccessLockComponent implements OnInit, OnDestroy {

  @Input() public isTier1 = false;
  @Input() public isSignedInAsUnconfirmedUser = false;
  @Input() public wideMode = false;
  @Input() public knowUserTypeFromStorage = '';
  @Input() public showConfirm = false;
  @Input() public showPassword = false;
  @Input() public offerGuid = '';
  @Input() public offer: OfferModel & SearchResultsTodayDealModel & SearchResultsAdsModel;

  @Output() public visibilityChange = new EventEmitter<boolean>();
  @Output() public accessGranted = new EventEmitter<boolean>();

  private scrollToModalTimeoutId: ReturnType<typeof setTimeout>;

  private isGuest = false;
  private confirmAccess = false;

  constructor(
    private authService: AuthService,
    private accountConfirmationService: AccountConfirmationService,
    private scrollToElementService: ScrollToElementService,
    private permissionService: PermissionService,
    private modalTodayDealService: ModalTodayDealService,
    @Inject(WINDOW) protected window: WINDOW
  ) { }

  public ngOnInit(): void {
    this.isGuest = this.permissionService.hasDefined('guest:access');
  }

  public async onSignInClick(): Promise<void> {
    if (this.showConfirm) {
      this.accountConfirmationService.checkAndOpenConfirmationDialog();

      if (this.window.innerWidth <= WindowHelper.SIZE_S) {
        this.scrollToConfirmationModal();
      }

      return;
    }

    if (this.showPassword) {
      this.accountConfirmationService.checkAndOpenPasswordDialog();
      return;
    }

    if (this.confirmAccess) {
      if (!this.modalTodayDealService.isAllowedToViewOffer(this.offerGuid)) {
        this.modalTodayDealService.openOfferOtpModal(this.offerGuid);

        this.modalTodayDealService.otpSuccess$
          .pipe(take(1))
          .subscribe(async (): Promise<void> => {
            await this.handleOfferNavigation();
          });

        return;
      }

      await this.handleOfferNavigation();
      return;
    }

    this.authService.redirectToSignInDirectly('/');
  }

  public scrollToConfirmationModal(): void {
    if (this.accountConfirmationService.isModalClosed) {
      this.scrollToModalTimeoutId = setTimeout(() => {
        this.scrollToElementService.scrollToElement(ACCOUNT_CONFIRMATION_DROPDOWN);
      }, TRANSITION_TIME + 50);
    } else {
      this.scrollToElementService.scrollToElement(ACCOUNT_CONFIRMATION_DROPDOWN);
    }
  }

  public getCardButtonTitle(): string {
    if (this.showPassword) {
      return 'Set Password';
    }

    if (this.showConfirm) {
      return 'Confirm Account';
    }

    if (this.isGuest) {
      return 'Sign In / Register';
    }

    this.confirmAccess = true;

    return 'Confirm Access';
  }

  public getCardContent(): string {
    if (this.showPassword) {
      return 'Unlock with setting your password';
    }

    if (this.showConfirm) {
      return 'Unlock with confirmation of account';
    }

    return 'Unlock Access To Exclusive Savings';
  }

  public ngOnDestroy(): void {
    clearTimeout(this.scrollToModalTimeoutId);
  }

  private async handleOfferNavigation(): Promise<void> {
    this.accessGranted.emit(true);
    return;
  }
}
