import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { NpsSurveyModalComponent } from './components/nps-survey-modal/nps-survey-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    NpsSurveyModalComponent
  ],
  exports: [
    NpsSurveyModalComponent
  ],
  providers: []
})

export class NpsSurveyModule { }
