<div *ngIf="isShow"
		 [appAutoTabIndex]="groupTabIndex"
		 [@slideInOutLeft]="animationState"
		 class="decisions-fly-in"
		 (click)="cardClick($event)">

	<div class="decisions-body">
		<div class="logo">
			<img *ngIf="image"
					 class="mobile"
					 loading="lazy"
					 [src]="image | imageUrl : previewImageUrlPipeOptions(flyInImageParamsXS)"
					 alt="logo" />

			<img *ngIf="image"
					 class="desktop"
					 loading="lazy"
					 [src]="image | imageUrl : previewImageUrlPipeOptions(flyInImageParamsDesktop)"
					 alt="logo" />
		</div>

		<div class="decisions-content">
			<div class="header"
					 [title]="header">
				<app-auto-resize-overflow-text [text]="header"
																			 textOverflowClassName="header-overflow"
																			 [maxLinesCount]="4">
				</app-auto-resize-overflow-text>
			</div>
		</div>
	</div>

	<div class="decisions-cta"
			 [appAutoTabIndex]="groupTabIndex">
		<app-ad-cta-button [options]="adCtaButtonOptions"
											 (click)="ctaClick($event)">
		</app-ad-cta-button>
	</div>
	<app-close-button (closed)="close($event)"></app-close-button>
</div>
