import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserModel } from './user.model';

@Injectable()
export class ProfileFeaturesGuardService {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  async canActivate(): Promise<boolean> {

    const accountInfo = await this.authService.getAccountInfo();
    const user = new UserModel(accountInfo);

    if (user.isProfileFeature) {
      void this.router.navigateByUrl('/');
      return false;
    }

    return true;
  }
}
