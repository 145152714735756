/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PolicyPage {
  slug: string;
  guid: string;
  title: string;
  content: string;
  sub_pages: PolicySubPage[];
  summary: string;
  datePublished: string;
  dateModified: string;
}

export interface PolicySubPage {
  name: string;
  content: string;
  options: {
    name: string;
    data: {
      title: string;
      key: string;
    }[];
  }[];
}

export interface PolicyConfigs {
  top_level_domain_visible_name: string;
  logo_url: string;
  logo_link_url: string;
  logo_inverse_url: string;
  secondary_color: string;
  phone_toll_free: string;
  phone_toll: string;
  copyright: string;
  primary_color: string;
}

export interface PolicyPages {
  items: PolicyPage[];
  meta: {
    configs: PolicyConfigs;
  };
}

interface PolicyPageResponse {
  slug: string;
  title: string;
  summary: string;
  content: string;
  sub_pages: PolicySubPage[];
  published_date: string;
  modified_date: string;
}

export interface PolicyPagesResponse {
  items: {
    slug: string;
    title: string;
    summary: string;
    content: string;
    sub_pages: PolicySubPage[];
    published_date: string;
    modified_date: string;
  }[];
  meta: {
    configs: PolicyConfigs;
    total: number;
    next: string;
    prev: string;
  };
}

@Injectable()
export class PoliciesService {

  constructor(private http: HttpClient) {
  }

  async getPolicies(orderBy: string = 'order_id'): Promise<PolicyPages> {
    const data = (await this.http.get<any>(`${environment.apiUrl}/api/policy-pages?versionStatus=active&orderBy=${orderBy}`).toPromise() as PolicyPagesResponse);
    return {
      items: data.items.map(item => ({
        slug: item.slug,
        title: item.title,
        guid: '',
        content: '',
        sub_pages: null,
        summary: item.summary,
        datePublished: item.published_date,
        dateModified: item.modified_date
      })),
      meta: {
        configs: {
          top_level_domain_visible_name: data.meta.configs.top_level_domain_visible_name,
          logo_url: data.meta.configs.logo_url,
          logo_link_url: data.meta.configs.logo_link_url,
          logo_inverse_url: data.meta.configs.logo_inverse_url,
          secondary_color: data.meta.configs.secondary_color,
          phone_toll_free: data.meta.configs.phone_toll_free,
          phone_toll: data.meta.configs.phone_toll,
          copyright: data.meta.configs.copyright,
          primary_color: data.meta.configs.primary_color
        }
      }
    };
  }

  async getPolicy(slug: string, guid?: string): Promise<PolicyPage> {

    const encodedSlug = encodeURIComponent(slug);

    let url = `${environment.apiUrl}/api/policy-pages/${encodedSlug}`;
    if (guid) {
      url += `?guid=${guid}&versionStatus=active`;
    }

    const data = await this.http.get<PolicyPageResponse>(url).toPromise();

    return {
      slug: data.slug,
      guid: '',
      title: data.title,
      content: data.content,
      sub_pages: data.sub_pages,
      summary: data.summary,
      datePublished: data.published_date,
      dateModified: data.modified_date
    };
  }

  getPolicyConfigs(): Observable<PolicyPagesResponse> {
    return this.http.get<PolicyPagesResponse>(`${environment.apiUrl}/api/policy-pages?onlyMeta=true`);
  }
}
