/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ICS } from 'ics-tsc';

class OutlookCalendarEvent {
  private filename: string;
  private dtStamp: string;
  private dtStart: string;
  private dtEnd: string;
  private summary: string;
  private description: string;
  private location: string;

  constructor({
    filename,
    dtStamp,
    dtStart,
    dtEnd,
    summary,
    description,
    location
  }) {
    this.filename = filename;
    this.dtStamp = dtStamp;
    this.dtStart = dtStart;
    this.dtEnd = dtEnd;
    this.summary = summary;
    this.description = description;
    this.location = location;
  }

  addEvent(): any {
    // location argument wasn't described in ics.d.ts file but it can get this param and properly set it
    const event = new (ICS as any)(this.filename, this.dtStamp, this.dtStart, this.dtEnd, this.summary, this.description, this.location);
    return event.exportIcs();
  }
}

export default OutlookCalendarEvent;
