/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { SearchAdContentDataInterface } from '@shared/services/ads/search-ads-items-response.interface';
import { AccessSettingOption } from '@shared/interfaces/search-offer-item.interface';
import { KeyValue } from '@angular/common';

export class SearchAdContentData implements SearchAdContentDataInterface {

  data: { [key: string]: any };
  is_allowed_destination_url: boolean;
  is_saved: boolean;
  views: number;
  extra_status: string;
  url_type?: string;
  has_destination_url?: boolean;
  class?: string;
  end_date: Date;
  cat_code_tag_ids?: number[];
  access_setting: AccessSettingOption;
  source_based_params: KeyValue<string, string>[];
  is_widget_iframe: boolean;
  is_widget_disclaimer: boolean;
  brand_name?: string;

  constructor(data?: { [key: string]: string }) {
    this.data = {};
    if (data) {
      Object.keys(data).forEach(key => this.setOption(key, data[key]));
    }
  }

  setOption(key: string, value: any): void {
    this.data[key] = value;
  }

  getOption(key: string, defaultValue = ''): string {
    return this.data && this.data[key] ? this.data[key] : defaultValue;
  }

}
