<div [attr.id]="anchorLink"
     class="special-offers-item-container"
     [class.wide-endpoint]="isWideContainer()"
     [class.staggered-short]="isStaggeredVariant(4)"
      [class.staggered-wide]="isStaggeredVariant(5)">
  <div class="special-offers-item-header"
      [class.show-more-flex-end]="isEnrollment">
    <h4 *ngIf="!isEnrollment">
      <img [src]="itemIcon"
           alt=""
           loading="lazy"
           *ngIf="itemIcon">{{itemTitle}}
    </h4>
    <a class="btn btn-text btn-underline"
       *ngIf="isEnrollment && linkTitle && linkUrl"
       (click)="handleEnrollmentViewAllClick($event)"
       [appAutoTabIndex]="groupTabIndex + 18">
      <span>{{linkTitle}}</span>
      <svg height="9"
           viewBox="0 0 9 9"
           width="9"
           xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c22=""
              d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z"
              fill="currentColor"
              fill-rule="nonzero"></path>
      </svg>
    </a>
    <a class="btn btn-text btn-underline show-more-full-text"
       *ngIf="!isEnrollment && !disableShowAll"
       (click)="handleShowAllClick($event)"
       [appAutoTabIndex]="groupTabIndex + 18">
      <span>{{fullText}}</span>
      <svg height="9"
           viewBox="0 0 9 9"
           width="9"
           xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c22=""
              d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z"
              fill="currentColor"
              fill-rule="nonzero"></path>
      </svg>
    </a>
    <a class="btn btn-text btn-underline show-more-short-text"
       *ngIf="!isEnrollment && !disableShowAll"
       (click)="handleShowAllClick($event)"
       [appAutoTabIndex]="groupTabIndex + 18">
      <span>{{shortTitle}}</span>
      <svg height="9"
           viewBox="0 0 9 9"
           width="9"
           xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c22=""
              d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z"
              fill="currentColor"
              fill-rule="nonzero"></path>
      </svg>
    </a>
  </div>
  <div class="special-offers-item-body mobile-design-1"
       [class.align-center]="alignItems">
    <ng-container *ngFor="let ad of getAds(); let i = index; trackBy: specialOfferTrackBy">
      <app-ad-item *ngIf="!isProductCard(ad)"
                   class="sel-ad-item home-page-item"
                   [zoneName]="zoneName"
                   [isAllowDisableHover]="isAllowDisableHover"
                   [ad]="ad"
                   (clicked)="onClickedCta(ad, i)"
                   (clickedCta)="onClickedAdCta($event, i)"
                   [favoriteFromEventName]="favoriteFromEventName"
                   [groupTabIndex]="groupTabIndex"
                   [isEnrollment]="isEnrollment">
      </app-ad-item>
    </ng-container>
  </div>
</div>
