import { ActivatedRoute, Params } from '@angular/router';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';
import { CategoryTagsService } from '../../category-tags.service';
import { Injectable } from '@angular/core';

interface AdobeAnalyticsPageInfo {
  name: string;
  section: string;
  type: string;
}

enum RootParamsDictionary {
  Query = 'q',
  Brand = 'b',
  Category = 'c',
  TW_Query = 'tw_q',
  TW_City_Zip = 'tw_city_zip',
  Nav = 'n',
  OffersGroup = 'ofg',
  TodaysDealsGroup = 'tdg',
  K = 'k'
}

const rootQueryParams = Object.values(RootParamsDictionary);
const SPECIFIC_CATEGORY_1 = 20500;
const SPECIFIC_CATEGORY_2 = 20599;

@Injectable()
export class AdobeSearchPageDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService,
    private activatedRoute: ActivatedRoute,
    private categoryTagsService: CategoryTagsService
  ) { }

  async setSearchPageData(): Promise<void> {
    const queryParams: Params = this.activatedRoute.snapshot.queryParams;
    await this.adobeDataCollectorService.setPageData({
      techstack: 'g3',
      ...await this.getPageDataByQuery(queryParams),
      hierarchy: this.adobeDataCollectorService.setHierarchy('Search Page'),
    });
  }

  private getRootQueryParamKeysWithDelimiter(queryParams: Params): string {
    const filterRootParams = rootQueryParams.filter(i => queryParams[i]);
    return filterRootParams.join('|');
  }

  private isSpecificCategories(сategory: string): boolean {
    const intCategory = parseInt(сategory, 10);
    return intCategory >= SPECIFIC_CATEGORY_1 && intCategory <= SPECIFIC_CATEGORY_2;
  }

  private isOnlySpecificRootParamter(queryParams: Params): boolean {
    return rootQueryParams.filter(i => queryParams[i]).length === Object.keys(queryParams).length;
  }

  private isSpecificCategoriesParam(queryParams: Params, isSingleQueryParameter: boolean): boolean {
    return queryParams[RootParamsDictionary.Category] && this.isSpecificCategories(queryParams[RootParamsDictionary.Category]);
  }

  private async getOfferCategoryByRootQuery(queryParams: Params): Promise<string> {
    if (queryParams[RootParamsDictionary.Nav]) {
      return queryParams[RootParamsDictionary.Nav];
    } else if (queryParams[RootParamsDictionary.Category]) {
      return this.getSearchHeader(queryParams);
    } else if (queryParams[RootParamsDictionary.Brand]) {
      return this.getFirstQueryParamterValue(queryParams[RootParamsDictionary.Brand]);
    } else {
      return queryParams[RootParamsDictionary.Nav]
        || this.getFirstQueryParamterValue(queryParams[RootParamsDictionary.Brand])
        || this.getFirstQueryParamterValue(queryParams[RootParamsDictionary.Category])
        || queryParams[RootParamsDictionary.Query]
        || queryParams[RootParamsDictionary.K]
        || queryParams[RootParamsDictionary.TW_Query]
        || queryParams[RootParamsDictionary.TW_City_Zip]
        || queryParams[RootParamsDictionary.OffersGroup]
        || queryParams[RootParamsDictionary.TodaysDealsGroup];
    }
  }

  private async getPageDataByQuery(queryParams: Params): Promise<AdobeAnalyticsPageInfo> {
    const isSingleQueryParameter = Object.keys(queryParams).length === 1;
    if (queryParams[RootParamsDictionary.Query] && isSingleQueryParameter && this.isOnlySpecificRootParamter(queryParams)) {
      return {
        name: `search results: ${queryParams[RootParamsDictionary.Query]}`,
        section: 'search',
        type: `g3 universal search: ${RootParamsDictionary.Query}`
      };
    } else if (this.isSpecificCategoriesParam(queryParams, isSingleQueryParameter)) {
      return {
        name: `seasonal category: ${await this.getSearchHeader(queryParams)}`,
        section: 'seasonal category',
        type: 'g3 seasonal'
      };
    } else {
      return {
        name: `offer category: ${await this.getOfferCategoryByRootQuery(queryParams) || 'not found'}`,
        section: 'offer category',
        type: `g3 universal search: ${this.getRootQueryParamKeysWithDelimiter(queryParams)}`
      };
    }
  }

  private async getSearchHeader(queryParams: Params): Promise<string> {
    const catCodeData = await this.categoryTagsService.getCategoryTagById(this.getFirstQueryParamterValue(queryParams[RootParamsDictionary.Category]));
    return catCodeData && catCodeData.name ? catCodeData.name : queryParams[RootParamsDictionary.Category];
  }

  private getFirstQueryParamterValue(value: string): string {
    if (value) {
      const splittedValue = value.split(',');
      return splittedValue.length ? splittedValue[0] : '';
    }

    return '';
  }

}
