<ng-container>
  <p class="email-confirmation__title">Please Confirm Your Email</p>
  <p class="email-confirmation__sub-title">Enter the code that we just sent to:</p>
  <form [formGroup]="confirmationForm"
        #form="ngForm"
        (ngSubmit)="onConfirmClick($event)">
    <div class="email-confirmation__field">
      <label class="email-confirmation__field__input-lable"
             for="email">
        Email
      </label>
      <a *ngIf="!(emailEditable$ | async)"
         class="email-confirmation__field__change-email-button"
         (click)='onChangeEmailClicked($event)'>
        Change
      </a>
      <input name="email"
             class="email-confirmation__field__input"
             formControlName="email"
             placeholder="Enter email address">
      <div *ngIf="email.errors?.required && form.submitted"
            class="email-confirmation__field__error">
        Please enter your email
      </div>
      <div *ngIf="email.errors?.email && form.submitted"
          class="email-confirmation__field__error">
        The email is not valid
        </div>
    </div>
    <div class="email-confirmation__field">
      <label class="email-confirmation__field__input-lable"
             for="passcode">
        One Time Passcode
      </label>
      <input name="passcode"
             class='email-confirmation__field__input'
             formControlName="passcode"
             placeholder="Enter one time passcode"
             maxlength="6"
             type="text">
      <div *ngIf="passcode.errors?.required && form.submitted && !passcodeErrorHidden"
           class="email-confirmation__field__error">
        Please enter one time passcode
      </div>
      <div *ngIf="passcode.errors?.pattern && form.submitted && !passcodeErrorHidden"
            class="email-confirmation__field__error">
        Passcode should contain 6 digits
      </div>
      <div *ngIf="passcode.errors?.invalid && form.submitted"
           class="email-confirmation__field__error">
        {{passcode.errors?.invalid}}
      </div>
    </div>
    <p
       class="email-confirmation__passcode-countdown">
      <span class="email-confirmation__passcode-countdown__lable">Didn't Receive Passcode?</span>
      <a class="email-confirmation__send-passcode-button"
         #sendPasscodeButton>RESEND</a>
    </p>
    <button class="btn btn-primary email-confirmation__submit-button"
            type="submit">
      Confirm
    </button>
  </form>
</ng-container>
