/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { Ad } from '@shared/models/ad.model';
import { AdsOffersService } from '@shared/services/ads/ads-offers.service';
import { DirectClickOutService } from '@shared/services/direct-click-out.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { SearchAdsItemResponse } from '@shared/services/ads/search-ads-items-response.interface';
import { SearchResultsAdsModel } from '@shared/models/search-results-ads.model';
import { SLIDE_IN_OUT_LEFT_ANIMATION } from '@shared/animations/slide-in-out-left.animation';
import {
  WINDOW,
  ImgixCropSettingsService,
  ImageUrlPipeOptions
} from 'g3-common-ui';
import { ModalService } from '@app/shared/services/modal.service';
import { AdobeAnalyticsEventsService } from '@shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeImpresssionsEvent
} from '@shared/services/adobe/adobe-analytics.interfaces';

const MOBILE_VIEWPORT_WIDTH = 768;
const MAX_HEADER_CHARACTERS_COUNT = 99;

@Component({
  selector: 'app-decisions-fly-in',
  templateUrl: './ad-fly-in.component.html',
  styleUrls: ['./ad-fly-in.component.less'],
  animations: SLIDE_IN_OUT_LEFT_ANIMATION,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdFlyInComponent implements OnInit, AfterViewInit {

  @Input() public data: SearchAdsItemResponse;
  @Input() public groupTabIndex: number;

  @Output() public cardClicked: EventEmitter<SearchAdsItemResponse> = new EventEmitter<SearchAdsItemResponse>();
  @Output() public ctaClicked: EventEmitter<SearchAdsItemResponse> = new EventEmitter<SearchAdsItemResponse>();
  @Output() public showed: EventEmitter<SearchAdsItemResponse> = new EventEmitter<SearchAdsItemResponse>();
  @Output() public closed: EventEmitter<SearchAdsItemResponse> = new EventEmitter<SearchAdsItemResponse>();

  public isShow = false;
  public animationState = false;
  public flyInImageParamsXS = { w: 96, h: 96 };
  public flyInImageParamsDesktop = { w: 188, h: 188 };

  constructor(
    @Inject(WINDOW) private window: Window,
    private adsOffersService: AdsOffersService,
    private profileService: ProfileService,
    private directClickOutService: DirectClickOutService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private readonly imgixCropSettingsService: ImgixCropSettingsService
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(e: any): void {
    this.checkIsUseAnimation(e.target);
  }

  public ngOnInit(): void {
    this.checkIsUseAnimation(this.window);
    if (this.modalService.hasOpenModal()) {
      this.modalService.activeModalSubject.pipe(
        filter(modal => !modal)
      ).subscribe(() => {
        this.showModal();
      });
    } else {
      this.showModal();
    }
  }

  public ngAfterViewInit(): void {
    if (this.data) {
      const adobeEvent: AdobeImpresssionsEvent = {
        event_name: AdobeEventNameEnum.FLY_IN,
        event_type: AdobeEventTypeEnum.IMPRESSION,
        properties: {
          impressions: [this.adobeAnalyticsEventsService.getOfferAdobeData(this.ad, 'standard', 'offer', undefined, undefined)]
        }
      };
      void this.adobeAnalyticsEventsService.sendOfferImpressions(adobeEvent);
      this.showed.emit(this.data);
    }
  }

  public get ad(): Ad {
    return this.data ? this.adsOffersService.getAdFromSearchAdsItem(this.data) : null;
  }

  public get image(): string {
    return this.data?.image_url
      || this.data?.content_data?.getOption('off_image_sq', '')
      || this.data?.content_data?.getOption('off_logo_vendor_square', '')
      || '';
  }


  public get header(): string {
    return this.data?.content_data?.getOption('off_header_short', '') || '';
  }

  public showModal(): void {
    this.isShow = true;
    this.cdr.detectChanges();
  }

  public previewImageUrlPipeOptions(dimensions: { w: number; h: number }): ImageUrlPipeOptions {
    if (this.data?.image_imgix_settings) {
      return this.imgixCropSettingsService.getImgixDisplaySettings(this.data?.image_imgix_settings, dimensions);
    }

    return dimensions;
  }

  public async cardClick(e: { stopPropagation: () => void }): Promise<void> {
    e.stopPropagation();
    if (!this.isMobileView(this.window)) {
      const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(this.ad, undefined, undefined, undefined, AdobeEventNameEnum.FLY_IN);
      delete adobeInfo.properties.offer.size;
      void this.directClickOutService.ads.handleClickAd(new SearchResultsAdsModel(this.data), 'card', undefined, undefined, adobeInfo);
      this.cardClicked.emit(this.data);
    }
  }

  public async ctaClick(e: { stopPropagation: () => void }): Promise<void> {
    e.stopPropagation();

    // * If offer has a widget we will navigate user to details page regardless of cta settings
    const clickType = this.data?.content_data?.is_widget_iframe
      ? 'card'
      : 'cta';

    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(this.ad, undefined, undefined, undefined, AdobeEventNameEnum.FLY_IN);
    delete adobeInfo.properties.offer.size;
    await this.directClickOutService.ads.handleClickAd(new SearchResultsAdsModel(this.data), clickType, undefined, undefined, adobeInfo);

    this.ctaClicked.emit(this.data);
    void this.close(e);
  }

  public async close(e: { stopPropagation: () => void }): Promise<void> {
    e.stopPropagation();
    this.isShow = false;
    if (this.animationState) {
      setTimeout(() => {
        this.closed.emit(this.data);
      }, 300);
    } else {
      this.closed.emit(this.data);
    }

    const expiredDate = { amount: 3, units: 'days' };
    await this.profileService.updateDismissedDecisions(this.data.guid, expiredDate).toPromise();
  }

  private checkIsUseAnimation(window: Window): void {
    this.animationState = !this.isMobileView(window);
  }

  private isMobileView(window: Window): boolean {
    return window.innerWidth < MOBILE_VIEWPORT_WIDTH;
  }
}
