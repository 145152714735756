import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ZIP_CODE_REG_EXP } from '../constants/form-submit-keys.constants';

export const ZipValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;

  if (!value) {
    return null;
  }

  const isValid = ZIP_CODE_REG_EXP.test(value);
  return isValid ? null : { pattern: { value } };
};
