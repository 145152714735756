import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-today-deal-tag',
  templateUrl: './today-deal-tag.component.html',
  styleUrls: ['./today-deal-tag.component.less']
})
export class TodayDealTagComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit(): void { }

}
