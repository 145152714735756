/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-search-bar-item',
  templateUrl: './search-bar-item.component.html',
  styleUrls: ['./search-bar-item.component.less']
})
export class SearchBarItemComponent implements OnInit {

  @Input() iconImage: string;
  @Input() iconClass: string;

  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  emitClick(event: any): void {
    this.click.emit(event);
  }

}
