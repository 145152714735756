/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface SuggestedItem {
  query: string;
  title: string;
  intercepted: boolean;
}

export interface SuggestedResponse {
  recent: SuggestedItem[];
}

@Injectable()
export class SearchSuggestedService {

  constructor(
    private http: HttpClient,
  ) { }

  async getSuggested(): Promise<SuggestedResponse> {
    return this.http.get<SuggestedResponse>(`${environment.apiUrl}/api/search/suggested`).toPromise();
  }

  async getSuggestedWidgets(): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/search/suggested-widgets`).toPromise();
  }

  async addSuggested(item: { query: string; title: string; intercepted: boolean }): Promise<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/search/suggested`, { ...item }).toPromise();
  }

  // addSuggestedWidgets(item: { query: string, title: string, intercepted: boolean }): Promise<void> {
  //   return this.http.post<void>(`${environment.apiUrl}/api/search/suggested-widgets`, { ...item }).toPromise();
  // }

  async clearAllSuggestions(): Promise<void> {
    return this.http.delete<void>(`${environment.apiUrl}/api/search/suggested`).toPromise();
  }

  async clearSuggestionItem(queryLabel: string): Promise<void> {
    return this.http.delete<void>(`${environment.apiUrl}/api/search/suggested/${encodeURIComponent(queryLabel)}`).toPromise();
  }

}
