/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { COMPANY_ID_PROP_NAME, DEFAULT_SEARCH_RADIUS_IN_MILES, TicketsService } from '@app/widgets/services/tickets.service';
import { ConfigService } from '@app/shared/services/config.service';
import { EbgProductSearchResponse } from '@app/widgets/interfaces/ebg-product-search.interface';
import { Location } from '@angular/common';
import { ModalAlertComponent } from '@shared/components/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { SearchWidgetsData } from '@offers/interfaces/search-widgets.interface';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TicketsQueryParams } from './tickets-query-params.interface';
import { TicketsState } from '@app/widgets/interfaces/widgets-state.interface';
import { WidgetSearchAbstract } from '../widget-search.abstract';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';

@Component({
  selector: 'app-tickets-search-widget',
  templateUrl: './tickets-search.component.html',
  styleUrls: ['./tickets-search.component.less', '../common-widget.style.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION]
})
export class TicketsSearchComponent extends WidgetSearchAbstract implements OnInit, AfterViewInit, OnDestroy {

  @Input() public customClass = '';
  @Input() public widget: SearchWidgetsData;
  @Input() public topIndent: boolean;

  @Output() public clicked = new EventEmitter<SearchWidgetsData>();
  @Output() public displayed = new EventEmitter<SearchWidgetsData>();

  public placeName = '';
  public ticketsEntity = '';
  public stateKey = '';
  public autocompleteOptions = { inputString: '' };
  public showMoreCount = 5;
  public ticketsProductSearchData: EbgProductSearchResponse[] = [];
  public isSubmitted = false;
  public ticketsInputData: Partial<TicketsQueryParams>;
  public widgetState: TicketsState;
  public useDefaultDestination = false;

  private destroyStream = new Subject<void>();

  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private readonly ticketsService: TicketsService,
    private readonly configService: ConfigService,
    private readonly location: Location,
    private readonly sessionStorageService: SessionStorageService,
    private readonly widgetSuggestionService: WidgetSuggestionService
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const target = event.target as Window;
    super.setImageOptions(target.innerWidth);
  }

  public async ngOnInit(): Promise<void> {
    const orderedParams = Object.keys(this.activatedRoute.snapshot.queryParams)
      .filter(el => el !== 'tw_city_zip' && el !== 'tw_q')
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = this.activatedRoute.snapshot.queryParams[key];

          return obj;
        }, {}
      );
    const queryItems = JSON.stringify(orderedParams);
    this.stateKey = `${this.widget.widget_id}_${queryItems}`;
    this.widgetState = this.sessionStorageService.getItem<TicketsState>(this.stateKey);

    this.handleRouterQueryParams();
    this.handleAnalytics();
    this.handleNavigationChanges();

    if (this.widgetState && !this.ticketsEntity && !this.placeName || this.widgetStateChange) {
      this.autocompleteOptions.inputString = this.widgetState.placeName;
      this.placeName = this.widgetState.placeName;
      this.ticketsEntity = this.widgetState.entity;

      this.useDefaultDestination = true;

      if (this.widgetState.isSubmitted) {
        this.ticketsSearchTrigger();
      }
    }

    super.ngOnInit();
  }

  public handleAddressChange(destination: string): void {
    this.placeName = destination;
    this.useDefaultDestination = false;
  }

  public getPlaceholder(ticketsEntity: string): string {
    return ticketsEntity
      ? `I am Looking for: ${ticketsEntity}`
      : 'I am Looking for: parks, artists, venues, events';
  }

  public updatePlaceName(value: string): void {
    this.placeName = value;
    this.useDefaultDestination = true;
  }

  public ticketsEntityChange(event: string): void {
    this.ticketsEntity = event;
  }

  // Only Edge hack.
  public inputClick(event: Event): void {
    const target = event.target as HTMLTextAreaElement;
    setTimeout(() => {
      if (!target.value) {
        this.placeName = '';
        this.cdr.detectChanges();
      }
    });
  }

  public async search(): Promise<void> {
    if (!this.placeName) {
      this.alertMessage('Please enter a destination.');
    } else if (!this.ticketsEntity) {
      this.alertMessage('Please enter parks, artists, venues, events.');
    } else {
      this.isSubmitted = false;
      this.setTicketsQueryParams();
      void this.requestEbgProductSearch();
      this.cdr.detectChanges();
      this.isSubmitted = true;

      this.clicked.emit(this.widget);

      this.widgetState = {
        placeName: this.placeName,
        entity: this.ticketsEntity,
        isSubmitted: this.isSubmitted
      };

      if (this.widgetState.placeName && this.widgetState.entity) {
        this.sessionStorageService.setItem<TicketsState>(this.stateKey, this.widgetState);
        void this.widgetSuggestionService.addSuggestedWidgets({
          data: this.widgetState,
          type: this.widget.widget_id,
          page_url: window.location.href,
          destination_url: window.location.href
        });
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyStream.next();
    this.destroyStream.complete();

    if (!this.widgetStateChange) {
      this.setWidgetState();

      return;
    }

    this.widgetState = {
      placeName: this.placeName,
      entity: this.ticketsEntity,
      isSubmitted: false
    };

    this.sessionStorageService.setItem<TicketsState>(this.stateKey, this.widgetState);
  }

  private ticketsSearchTrigger(): void {
    if (this.placeName && this.ticketsEntity) {
      this.isSubmitted = false;
      void this.requestEbgProductSearch();
      this.isSubmitted = true;
    }
  }

  private setTicketsQueryParams(): void {
    const ticketsPreset = this.getQueryParamsFromSnapshot();
    const url = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: { ...ticketsPreset, tw_city_zip: this.placeName, tw_q: this.ticketsEntity }
    }).toString();
    this.location.go(url);
  }

  private async requestEbgProductSearch(): Promise<void> {
    const wag3AdminGuid: string = this.configService.getOption(COMPANY_ID_PROP_NAME, '');
    const coordinates = await this.ticketsService.handleCoordinatesByPlaceName(this.placeName);

    this.ticketsService.requestEbgProductSearch({
      text: this.ticketsEntity,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      company_id: wag3AdminGuid,
      radius: DEFAULT_SEARCH_RADIUS_IN_MILES
    }).subscribe(data => {
      this.ticketsProductSearchData = data;
    });

    this.ticketsInputData = {
      tw_city_zip: this.placeName,
      tw_q: this.ticketsEntity
    };
  }

  private handleRouterQueryParams(): void {
    const ticketsPreset = this.getQueryParamsFromSnapshot();

    this.ticketsEntity = ticketsPreset.tw_q || this.ticketsEntity;
    this.placeName = ticketsPreset.tw_city_zip || this.placeName;
    this.autocompleteOptions.inputString = ticketsPreset.tw_city_zip || this.placeName;

    if (ticketsPreset.tw_count) {
      this.showMoreCount = ticketsPreset.tw_count;
    }

    if (ticketsPreset.tw_q && ticketsPreset.tw_city_zip && !this.widgetStateChange) {
      this.ticketsSearchTrigger();
    }
  }

  private getQueryParamsFromSnapshot(): Partial<TicketsQueryParams> {
    return this.activatedRoute.snapshot.queryParams;
  }

  private handleNavigationChanges(): void {
    this.router.events.pipe(takeUntil(this.destroyStream)).subscribe(event => {
      this.autocompleteOptions.inputString = this.placeName;

      if (event instanceof NavigationEnd) {
        this.handleRouterQueryParams();
      }
    });
  }

  private handleAnalytics(): void {
    const ticketsPreset = this.getQueryParamsFromSnapshot();

    if (ticketsPreset.tw_q && ticketsPreset.tw_city_zip) {
      this.ticketsService.ticketsResultAnalyticsOnDisplay(this.widget, ticketsPreset.tw_q, ticketsPreset.tw_city_zip);
    }
  }

  private alertMessage(message: string): void {
    this.modalService.open(ModalAlertComponent, { size: 'sm', centered: true }).componentInstance.content = { text: message };
  }

  private setWidgetState(): void {
    this.widgetState = {
      placeName: this.placeName,
      entity: this.ticketsEntity,
      isSubmitted: this.isSubmitted
    };
    this.sessionStorageService.setItem<TicketsState>(this.stateKey, this.widgetState);
  }

  private get widgetStateChange(): boolean {
    return this.widgetState && this.widgetState.placeName !== this.placeName
      || this.widgetState && this.widgetState.entity !== this.ticketsEntity;
  }

}
