/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdsOffersService } from '@shared/services/ads/ads-offers.service';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import {
  BehaviorSubject,
  forkJoin,
  lastValueFrom,
  Observable,
  of,
  Subject
} from 'rxjs';
import { ConfigService } from '../config.service';
import { DataScrollerEvent, DataScrollerService, PlatformService, WINDOW } from 'g3-common-ui';
import { environment } from '@environments/environment';
import { FilterEventModel } from '@offers/models/offers-filter.interfaces';
import { getAnalyticsGALabelFromObject } from '@app/zones/services/analytics/helpers';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KnownUserService } from '../known-user.service';
import { SearchAdsParams, SearchAdsService } from '@shared/services/ads/search-ads.service';
import { SearchAdsItemResponse, SearchAdsResponse } from '@shared/services/ads/search-ads-items-response.interface';
import { SearchOfferForAdsInterface } from '@offers/services/search-offer-item.interface';
import { SearchTodayDealsItemResponse, SearchTodayDealsResponse, SearchTodayDealsService } from '@offers/services/search-today-deals.service';
import { ProfileService } from '../profile/profile.service';
import { queryStringContainsProperty } from '@app/shared/helpers/query-string';
import { SplitTestEventsService } from '../split-tests/split-test-events.service';
import { BrandsResponse, FilterResponse, FilterResponseItem } from './search-interfaces';
import { TDD_CLASS_ID } from '@app/shared/constants/offer.constants';

export type AdminFeaturesPositionArrow = 'increase' | 'decrease';

export interface WidgetDates {
  [key: string]: {
    ['start']: Date;
    ['end']: Date;
  };
}

export interface Brand {
  title: string;
  tag_id?: string;
  logo: string;
  offer_guid: string;
  count: number;
}

export interface SubCategoriesResponseItem {
  id: string;
  title: string;
  count: number;
  offer_guid: string;
}

export interface CategoriesResponseItem {
  title: string;
  sub_categories: SubCategoriesResponseItem[];
}

export interface SearchResponse {
  items: any[];
  meta: any;
}

export interface SearchResults {
  offers: any;
  todayDeals: SearchTodayDealsResponse;
  ads: SearchAdsResponse;
  paylogixOffers?: any;
  paylogixAds: SearchAdsItemResponse[];
  isTakeoverRefresh: boolean;
}

export interface SearchTodayDealsInOfferItemResponse {
  guid: string;
  header_short: string;
  code: string;
  images: {
    is_search_image: boolean;
    url: string;
  }[];
  destination_url: string;
  destination_url_type: string;
  destination_url_new_tab: boolean;
  vendor_brand: {
    title: string;
    logo_square: string;
  };
  status: string;
  site_tags: string[];
  class: string;
  is_ebg: boolean;
  tdd_tag_text: string;
  tdd_orig_price: number;
  tdd_curr_price: number;
  tdd_offer_group: boolean;
  tdd_pinned: boolean;
  shopping_category: string;
  views: number;
}

export interface SearchTodayDealsInOfferResponse {
  items: SearchTodayDealsInOfferItemResponse[];
}

export interface SubCategoryItem {
  name: string;
  cat_code_tag_id: string;
  checked: boolean;
}

const TODAY_DEALS_MAX_ITEMS = 25;
const ADS_MAX_ITEMS = 3;
const DEFAULT_LIMIT = 10;

const specialOffersOptions = ['limited', 'new', 'expiring'];
const userSpecialOffersOptions = ['recent', 'favorites'];
const editorialModes = ['none', 'hero', 'all'] as const;
type EditorialMode = typeof editorialModes[number];

@Injectable()
export class SearchService {

  public query = '';
  public cat = '';
  public vendor = '';
  public vendorParent = '';

  private resultsSource = new BehaviorSubject<any>({});
  public results$ = this.resultsSource.asObservable();

  private filterPanelDataSource = new BehaviorSubject<any>([]);
  public filterPanelData$ = this.filterPanelDataSource.asObservable();

  private subCategoriesDataSource = new BehaviorSubject<SubCategoryItem[]>([]);
  public subCategoriesDataSource$ = this.subCategoriesDataSource.asObservable();

  // Map from query to true for quick retrieval
  private resultDeduplicationMap = new Map<string, boolean>();

  public limit = DEFAULT_LIMIT;
  public offset = 0;
  public orderBy = '';
  public orderByCat = '';
  public canSearchBss = false;
  public adminFeatures: string;

  public filter: FilterEventModel = {};

  public destination = '';
  public tourTypes = '';
  public eventTypes = '';

  public widgetTimeFrames: WidgetDates = {} as WidgetDates;


  public sports = '';
  public city = '';
  public areaTarget = '';
  public specials = '';
  public discount = '';
  public brandTagIds = '';
  public categoryCodeTagIds = '';
  public classes = '';
  public alternateSpecialsQueryParam = '';

  public notQuery = '';
  public notBrandTagIds = '';
  public notCategoryCodeTagIds = '';
  public notClasses = '';

  public notGuids = [];
  public offerGuids = '';
  public offerGuidsFeatured = '';
  public todayDealGuids = '';
  public adOffersGuids = [];
  public allClasses = '';
  public futureDate = '';
  public passThru = '';
  public showAllFilters = '';
  public navId = '';

  public hiddenSection = '';
  public keywordTypes = '';
  public keywords = '';
  public titleOverride = '';

  public dontSaveUserSearch = false;
  public useImageSelection = true;
  public isTakeoverAdmin = false;

  // v1 - means we user internal search functionality
  // v2 - mean we user elastic search functionality
  // for now it's a temporary solution to test search results of these 2 versions
  public searchVersion = '1';
  public editorialGuid = '';
  public heroEditorialGuid = '';
  public editorialMode: EditorialMode = 'none';
  public paylogixCurrentlyEnrolledMode: boolean;

  private editorialGuid$: Subject<string>;

  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    private dataScrollerService: DataScrollerService,
    private searchTodayDealsService: SearchTodayDealsService,
    private searchAdsService: SearchAdsService,
    private adsOffersService: AdsOffersService,
    private configService: ConfigService,
    private profileService: ProfileService,
    private knownUserService: KnownUserService,
    private platformService: PlatformService,
    @Inject(WINDOW) private window: WINDOW,
    private splitTestEventsService: SplitTestEventsService
  ) { }

  public async getItems(ignoreDuplication = true, isTakeoverRefresh = false) {
    this.limit = DEFAULT_LIMIT;

    if (isTakeoverRefresh) {
      this.limit = this.offset > this.limit
        ? this.offset
        : this.offset + this.limit;
      this.offset = 0;
    }

    let query = this.getQueryString();

    // Clear any currently active results
    this.resultsSource.next({} as SearchResults);

    const isNeedToAdHiddenSpecials = this.route.snapshot.queryParams.customCategoryGuid
      && !query.includes('hiddenSection=specials');

    if (isNeedToAdHiddenSpecials) {
      query += '&hiddenSection=specials';
    }

    if (this.navId) {
      query += `&n=${this.navId}`;
    }

    try {
      if (!ignoreDuplication && this.isDuplicateSearch(query)) {
        // Duplicate request: Results have already been shown
        return;
      }

      let offersQuery = query;

      let adResult = { items: [] };
      const searchAdsParams: SearchAdsParams = {
        type: 'text^search',
        keywords: this.searchAdsService.getKeywordsValue(this.getQueryParamsForAds()),
        keywordTypes: this.searchAdsService.getKeywordTypesValue(this.getQueryParamsForAds()),
        excludeKeywordTypes: this.searchAdsService.getKeywordTypesValue(this.getQueryExcludeParamsForAds(), false),
        excludeKeywords: this.searchAdsService.getKeywordsValue(this.getQueryExcludeParamsForAds()),
        specials: this.specials || this.alternateSpecialsQueryParam || (this.filter.specials || []).join(','),
        sites: this.window.location.host.split('.')[0],
        excludeBrands: this.configService.getExcludeBrands().join(','),
        excludeCategories: this.configService.getExcludeCategories().join(',')
      };

      this.canSearchBss = this.offset === 0
        && this.searchAdsService.isEnoughParamsForSearchAds(searchAdsParams);

      if (this.canSearchBss) {
        try {
          const searchAdsResponse = await this.searchAdsService.getAds(searchAdsParams, ADS_MAX_ITEMS).toPromise();
          adResult = await this.adsOffersService.getAdsWithOffersInfo(searchAdsResponse, true);
          this.adOffersGuids = adResult.items.map(item => item.content_guid);
        } catch (e) {
          console.error(e);
        }
      }

      const isAdsResultShown = !!this.adOffersGuids.length;

      // Exclude offers from the search if they are already present in the ads section
      if (isAdsResultShown) {
        const adOffersGuidsParam = `&notGuids=${this.adOffersGuids.join(',')}`;
        // if some notGuids are already set, add guids to the beginning of the list
        if (query.includes('&notGuids=')) {
          offersQuery = query.replace('&notGuids=', adOffersGuidsParam + ',');
        } else {
          offersQuery += adOffersGuidsParam;
        }
      }

      if (this.dontSaveUserSearch) {
        offersQuery += '&dontSaveUserSearch=1';
        this.dontSaveUserSearch = false;
      }

      if (this.useImageSelection) {
        offersQuery += '&useImageSelection=1';
        // this.useImageSelection = false;
      }

      let editorialQuery = this.modifyQueryLimit(offersQuery, 1) + '&editorial_search=true';

      const queryParams = this.getQueryParams();

      if (!editorialQuery.includes('&dontSaveUserSearch')) {
        editorialQuery += '&dontSaveUserSearch=1';
      }
      if (this.editorialGuid) {
        editorialQuery += `&editorial_guid=${this.editorialGuid}`;
      }
      let editorialItem;
      let paylogixOffers = [];
      let paylogixAds = [];

      if (this.editorialMode !== 'all') {
        offersQuery += '&skip_editorial=true';
      }

      if (this.offset === 0) {
        this.heroEditorialGuid = '';
        const canSearchEditorialHero = this.editorialMode !== 'none' || this.editorialGuid; // editorial guid overrides any editorial mode rules

        if (canSearchEditorialHero) {
          try {
            const editorialResponse = await this.makeSearchCall(editorialQuery).toPromise();
            if (editorialResponse?.items?.length) {
              editorialItem = editorialResponse.items[0];
              editorialItem.isEditorialHero = true;
              this.heroEditorialGuid = editorialItem.guid;
            }
          } catch (e) {
            console.error(e);
          }
        }

        if (this.editorialMode === 'hero' || this.editorialGuid) {
          this.editorialGuid$?.next(this.heroEditorialGuid);
          this.editorialGuid$?.complete();
        }
      }

      if (this.heroEditorialGuid) {
        const skipHeroParam = `&notGuids=${this.heroEditorialGuid}`;
        if (offersQuery.includes('&notGuids=')) {
          offersQuery = offersQuery.replace('&notGuids=', skipHeroParam + ',');
        } else {
          offersQuery += skipHeroParam;
        }
      }

      if (this.paylogixCurrentlyEnrolledMode) {
        if (this.offset === 0) {
          try {
            const paylogixQuery = this.modifyQueryLimit(offersQuery, 50) + '&paylogixEnrolled=true';
            const paylogixResult = await this.makeSearchCall(paylogixQuery).toPromise();
            paylogixAds = adResult.items.filter(ad => ad.content_data.paylogix_subscription);
            paylogixOffers = paylogixResult.items;
          } catch (err) {
            console.error(err);
          }
        }

        offersQuery += '&paylogixEnrolled=false';
      }

      forkJoin([
        this.makeSearchCall(offersQuery),
        // we only need to fetch today deals on first page
        // do not search for today deals if queryParams has class
        this.offset === 0 && !queryParams['k']
          ? this.getTodayDealItems(offersQuery, queryParams)
          : of({} as SearchTodayDealsResponse),
      ]).subscribe(async res => {

        if (res && res.length === 2) {

          const [searchOffersResponse, searchTodayDealsResponse] = res;

          this.markSearchForDeduplication(query);

          if (this.query !== '' && this.offset === 0) {
            void this.analyticsService.eventTrack(new AnalyticsInternalEventModel('search-phrase-result', {
              query: encodeURIComponent(this.query),
              results_total: (searchOffersResponse.meta && searchOffersResponse.meta.total) ? searchOffersResponse.meta.total : 0
            }));
          }

          this.splitTestEventsService.emitSearchResultViewEvent();

          if (searchOffersResponse?.items?.some(item => item.hasOwnProperty('position'))) {
            searchOffersResponse.items.sort((a, b) => a.position - b.position);
          }

          if (editorialItem) {
            searchOffersResponse.items.unshift(editorialItem);
          }

          if (this.heroEditorialGuid) {
            searchOffersResponse.meta.total++;
          }

          this.dataScrollerService.triggerScroll(DataScrollerEvent.SearchList);

          const resultsSourceData = {
            offers: searchOffersResponse,
            todayDeals: searchTodayDealsResponse,
            ads: adResult,
            paylogixOffers,
            paylogixAds,
            isTakeoverRefresh: false
          };

          if (isTakeoverRefresh) {
            resultsSourceData.isTakeoverRefresh = true;
          }

          this.resultsSource.next(resultsSourceData);
        }
      });

    } catch (err) {
      console.error(err);
      this.resultsSource.next({} as SearchResults);
    }
  }

  private async getTodayDealItems(offersQuery: string, queryParams: any): Promise<SearchTodayDealsResponse | Observable<SearchTodayDealsResponse>> {
    return this.getTodayDealItemsFromOffer(offersQuery);
  }

  private async getTodayDealItemsFromOffer(offersQuery: string): Promise<SearchTodayDealsResponse> {
    const todayDealsQuery = new URLSearchParams(offersQuery);
    todayDealsQuery.set('limit', `${TODAY_DEALS_MAX_ITEMS}`);
    todayDealsQuery.set('f_class', TDD_CLASS_ID);
    todayDealsQuery.set('is_tdd_search', 'true');

    const res: SearchTodayDealsInOfferResponse = await lastValueFrom(this.makeSearchCall('?' + todayDealsQuery.toString()));
    return this.convertOffersToTodayDeal(res);
  }

  private convertOffersToTodayDeal(searchOffersResponse: SearchTodayDealsInOfferResponse): SearchTodayDealsResponse {
    const searchTodayDealsResponse: SearchTodayDealsItemResponse[] = searchOffersResponse.items.map((tddOffer) => ({
      guid: tddOffer.guid,
      company_name: tddOffer.vendor_brand.title,
      company_brand_tag_logo: tddOffer.vendor_brand.logo_square,
      headline: tddOffer.header_short,
      image_url: tddOffer.images[0]?.url ?? '',
      tag_text: tddOffer.tdd_tag_text,
      views: tddOffer.views || 0,
      code: tddOffer.code,
      destination_url: tddOffer.destination_url,
      is_ebg: tddOffer.is_ebg,
      site_tags: tddOffer.site_tags,
      orig_price: tddOffer.tdd_orig_price,
      curr_price: tddOffer.tdd_curr_price,
      offer_group: tddOffer.tdd_offer_group,
      new_tab: tddOffer.destination_url_new_tab,
      destination_url_type: tddOffer.destination_url_type,
      shopping_category: tddOffer.shopping_category,
    }));

    return { items: searchTodayDealsResponse };
  }

  public makeSearchCall(query: string): Observable<SearchResponse> {
    return this.http.get<any>(`${environment.apiUrl}/api/search${query}`);
  }

  public isAllowedNaturalSearch(query: string, domain: string) {
    let userOnlySearch = query.includes('specials=viewed') || query.includes('specials=favorites') || query.includes('specials=recent');
    userOnlySearch = userOnlySearch || (query.includes('d=viewed') || query.includes('d=favorites') || query.includes('d=recent'));

    // following logic is because the default api-cache domain is https://savings.beneplace.com
    //  when it is set to this domain, or set to MARKETPLACE (or not set), we should NOT use natural-search
    //  and instead use search normal because search normal includes data from/about current user.
    // it is basically disableing the api-cache when set to these values.
    const hasSkipSearchDomain = ['https://savings.beneplace.com', 'MARKETPLACE'].includes(domain);
    return (!(userOnlySearch || hasSkipSearchDomain || domain === ''));
  }


  public async makeSearchNaturalCall(query: string, apiCacheDomain: string, marketplaceSubdomain: string, userCountry: string): Promise<SearchResponse> {
    if (this.isAllowedNaturalSearch(query, apiCacheDomain)) {
      const { isAllowSignOnNewAccount, isSignedInAsKnownUser } = this.profileService.getData();

      if (isAllowSignOnNewAccount && !isSignedInAsKnownUser) {
        query += '&authStatus=guest';
      } else if (isSignedInAsKnownUser && !isAllowSignOnNewAccount) {
        query += '&authStatus=known';
      }

      const data = await this.http.get<any>(`${apiCacheDomain}/api/search-natural/${marketplaceSubdomain}/${userCountry}${query}`).toPromise();
      return this.updateOffersDynamicData(data);
    }

    if (this.knownUserService.knowUserEmail && !queryStringContainsProperty(query, 'known_user_email')) {
      query += `&known_user_email=${this.knownUserService.knowUserEmail}`;
    }
    if (this.knownUserService.knowUserType && this.knownUserService.knownUserCountry && !queryStringContainsProperty(query, 'country')) {
      query += `&country=${this.knownUserService.knownUserCountry}`;
    }

    const siteTag = this.platformService.currentPlatform.SITE_TAG;
    query += `&siteTag=${siteTag}`;

    // use legacy search of not using a valid domain for cdn or user only search type
    return this.makeSearchCall(query).toPromise();
  }

  public async updateOffersDynamicData(data) {
    // get list of guids
    const offersForAds: SearchOfferForAdsInterface[] = await this.adsOffersService.getOffersByIds(data.items.map(i => i.guid), true, false);
    const offersForAdsAsMap: Map<string, SearchOfferForAdsInterface> = offersForAds.reduce((r, i) => {
      r.set(i.guid, i); return r;
    }, new Map());
    data.items = data.items.map(i => {
      if (offersForAdsAsMap.has(i.guid)) {
        const offerData = offersForAdsAsMap.get(i.guid);
        i.is_saved = offerData.is_saved;
        i.views = offerData.views;
        i.is_allowed_url = offerData.isAllowedDestinationUrl;
      }
      return i;
    });
    return data;
  }

  public async getFilterData() {

    try {

      const filterData = await this.getFilterPanelData();

      let subCategories = [];
      type UpdatedFilter = Partial<FilterResponseItem> & { defaultsChecked?: string[]; datetime?: Date };

      const items = filterData.items
        .map(item => {
          const newFilter: UpdatedFilter = this.filter[item.option]
            ? { ...item, defaultsChecked: this.filter[item.option] }
            : item;
          // this field is required to check changes when angular renders in ngFor
          newFilter.datetime = new Date();
          // we don't need to show items with 0 filter options
          return (newFilter.items && newFilter.items.length > 0) ? newFilter : null;
        })
        .map(item => {
          const canSearchEditorial = this.editorialMode !== 'none' || this.editorialGuid;
          if (item.option !== 'f_class' || canSearchEditorial) {
            return item;
          }

          item.items = item.items.filter(i => i.title !== 'Editorial');

          return item;
        })
        .filter(item => {
          if (!item) {
            return false;
          }
          // if there is c in root query params we need to copy this data into sub categories filter data
          if (item.option === 'f_subcat' && this.categoryCodeTagIds.length > 0) {
            const checkedItems = this.filter['f_cat'] || [];
            subCategories = item.items.map(i => this.convertFilterItemToSubCategoryItem(i, checkedItems));
            return false;
          }
          return true;
        })
        .map((item, _i, array) => {
          if (item.option === 'f_states') {
            const isOnlyCountry = array.find(filterOption => filterOption.option === 'f_countries')?.items.length === 1 || false;

            item.items = item.items.filter(i => this.filter['f_countries']?.includes(i.parentId) || isOnlyCountry || false);
            this.updateFilterAndQueryParams('f_states', item.items);
          }

          if (item.option === 'f_cities') {
            const isOnlyState = array.find(filterOption => filterOption.option === 'f_states')?.items.length === 1 || false;

            item.items = item.items.filter(i => this.filter['f_countries']?.includes(i.parentId)
              || this.filter['f_states']?.includes(i.parentId)
              || isOnlyState
              || false);
            this.updateFilterAndQueryParams('f_cities', item.items);
          }
          return item;
        })
        .filter(item => ['f_countries', 'f_states'].includes(item.option) ? item.items.length > 1 : item.items.length);

      this.subCategoriesDataSource.next(subCategories);
      this.filterPanelDataSource.next(items);
      this.setHiddenSection(filterData.meta.hiddenSection);

    } catch (e) {
      console.error(e);
    }

  }

  public updateFilterAndQueryParams(filterKey: string, itemKey): void {
    if (this.filter[filterKey]) {
      this.filter[filterKey] = this.filter[filterKey]?.filter(i => itemKey.some(item => item.id === i)) || [];

      const newQueryParams = { ...this.route.snapshot.queryParams, [filterKey]: this.filter[filterKey].join(',') };
      void this.router.navigate([],
        {
          relativeTo: this.route,
          queryParams: this.filterEmptyFields(newQueryParams)
        }
      );
    }
  }

  public filterEmptyFields(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== '')
    );
  }

  public convertFilterItemToSubCategoryItem(filterItem: any, checkedItems: string[]): SubCategoryItem {
    return {
      name: filterItem.title,
      cat_code_tag_id: filterItem.id,
      checked: checkedItems.includes(filterItem.id)
    };
  }

  public getFilterSectionNames(): string[] {
    return [
      'f_special',
      'f_cat',
      'f_brand',
      'f_countries',
      'f_cities',
      'f_type',
      'f_special',
      'f_class',
      'f_states'
    ];
  }

  public getQueryString(): string {
    let query = `?limit=${this.limit}`;

    if (this.offset > 0) {
      query += `&offset=${this.offset}`;
    }

    query += `&orderBy=${this.orderBy !== '' ? this.orderBy : 'vendor'}`;
    query += `&orderByCat=${this.orderByCat !== '' ? this.orderByCat : 'vendor'}`;

    if (this.query !== '') {
      query += (`&q=${encodeURIComponent(this.query)}`);
    }

    if (this.adminFeatures !== '') {
      query += (`&s=${encodeURIComponent(this.adminFeatures)}`);
    }

    if (this.notQuery !== '') {
      query += (`&nq=${encodeURIComponent(this.notQuery)}`);
    }

    if (this.notBrandTagIds !== '') {
      query += `&nb=${this.notBrandTagIds}`;
    }

    if (this.notCategoryCodeTagIds !== '') {
      query += `&nc=${this.notCategoryCodeTagIds}`;
    }

    if (this.notClasses !== '') {
      query += `&nk=${this.notClasses}`;
    }

    if (this.specials !== '') {
      query += `&specials=${this.specials}`;
    }

    if (this.alternateSpecialsQueryParam !== '') {
      query += `&d=${this.alternateSpecialsQueryParam}`;
    }

    if (this.brandTagIds !== '') {
      query += `&b=${this.brandTagIds}`;
    }

    if (this.categoryCodeTagIds) {
      query += `&c=${this.categoryCodeTagIds}`;
    }

    if (this.classes) {
      query += `&k=${this.classes}`;
    }

    if (this.allClasses === 'universal') {
      query += '&allClasses=true';
    }

    if (this.futureDate) {
      query += `&future-dt=${this.futureDate}`;
    }

    if (this.passThru === 'true') {
      query += '&pass-thru=true';
    }

    if (this.showAllFilters === 'true') {
      query += '&showAllFilters=true';
    }

    if (this.destination !== '') {
      query += `&destination=${this.destination}`;
    }

    if (this.tourTypes !== '') {
      query += `&tourTypes=${this.tourTypes}`;
    }

    if (this.eventTypes !== '') {
      query += `&eventTypes=${this.eventTypes}`;
    }

    if (this.sports !== '') {
      query += `&sports=${this.sports}`;
    }

    if (this.city !== '') {
      query += `&city=${this.city}`;
    }

    if (this.areaTarget !== '') {
      query += `&areaTarget=${this.areaTarget}`;
    }

    if (this.offerGuids !== '') {
      query += `&ofg=${this.offerGuids}`;
    }

    if (this.offerGuidsFeatured !== '') {
      query += `&ofg_featured=${this.offerGuidsFeatured}`;
    }

    if (this.titleOverride !== '') {
      query += `&t=${this.titleOverride}`;
    }

    const timeFrameKeys = Object.keys(this.widgetTimeFrames);

    if (timeFrameKeys.length && this.vendorParent) {
      const vendor = this.vendorParent;
      const dates = this.widgetTimeFrames[vendor];

      if (dates.start) {
        query += `&dateStart=${dates.start}`;
      }

      if (dates.end) {
        query += `&dateEnd=${dates.end}`;
      }
    }

    if (this.vendorParent) {
      query += `&vendorParent=${this.vendorParent}`;
    }

    if (this.cat) {
      query += `&cat=${this.cat}`;
    }

    if (this.vendor) {
      query += `&vendor=${this.vendor}`;
    }

    if (this.searchVersion === '2') {
      query += '&version=2';
    }

    if (this.notGuids.length) {
      query += `&notGuids=${this.notGuids.join(',')}`;
    }

    const siteTag = this.platformService.currentPlatform.SITE_TAG;
    query += `&siteTag=${siteTag}`;
    query += '&status=active';

    if (this.isTakeoverAdmin) {
      query += '&isTakeoverAdmin=true';
    }

    for (const option in this.filter) {
      if (this.filter[option].length > 0) {
        query += `&${option}=${this.filter[option].map(i => encodeURIComponent(i)).join(',')}`;
      }
    }

    if (!this.query && !this.hiddenSection) {
      this.hiddenSection = Object.keys(this.route.snapshot.queryParams).shift(); // then take first param
    }

    const specialsValue = this.route.snapshot.queryParams[this.hiddenSection];
    const isHiddenPartInUrl = !!specialsValue;

    // prevent hiding 'specials' block in the filter panel on a dynamic category page
    let isCustomCategoryPage = false;
    if (!this.isSpecialsValue(specialsValue)) {
      isCustomCategoryPage = true;
    }

    if (this.hiddenSection && isHiddenPartInUrl && !isCustomCategoryPage) {
      query += `&hiddenSection=${this.hiddenSection}`;
    }
    if (this.knownUserService.knowUserEmail && !queryStringContainsProperty(query, 'known_user_email')) {
      query += `&known_user_email=${this.knownUserService.knowUserEmail}`;
    }
    if (this.knownUserService.knowUserType && this.knownUserService.knownUserCountry && !queryStringContainsProperty(query, 'country')) {
      query += `&country=${this.knownUserService.knownUserCountry}`;
    }

    return query;
  }

  public getQueryParams(): any {
    const result = {};

    if (this.query !== '') {
      result['q'] = this.query;
    }

    if (this.orderBy !== '') {
      result['orderBy'] = this.orderBy;
    }

    if (this.orderByCat !== '') {
      result['orderByCat'] = this.orderByCat;
    }

    if (this.destination !== '') {
      result['destination'] = this.destination;
    }

    if (this.sports !== '') {
      result['sports'] = this.sports;
    }

    if (this.tourTypes !== '') {
      result['tourTypes'] = this.tourTypes;
    }

    if (this.eventTypes !== '') {
      result['eventTypes'] = this.eventTypes;
    }

    if (this.city !== '') {
      result['city'] = this.city;
    }

    if (this.areaTarget !== '') {
      result['areaTarget'] = this.areaTarget;
    }

    if (this.specials !== '') {
      result['specials'] = this.specials;
    }

    if (this.searchVersion === '2') {
      result['version'] = '2';
    }

    if (this.brandTagIds !== '') {
      result['b'] = this.brandTagIds;
    }

    if (this.categoryCodeTagIds !== '') {
      result['c'] = this.categoryCodeTagIds;
    }

    if (this.classes !== '') {
      result['k'] = this.classes;
    }

    if (this.navId !== '') {
      result['n'] = this.navId;
    }

    if (this.adminFeatures !== '') {
      result['s'] = this.adminFeatures;
      this.limit = 9999;
    }

    if (this.cat !== '') {
      result['cat'] = this.cat;
    }

    if (this.vendor !== '') {
      result['vendor'] = this.vendor;
    }

    if (this.todayDealGuids !== '') {
      result['tdg'] = this.todayDealGuids;
    }

    if (this.notQuery !== '') {
      result['nq'] = this.notQuery;
    }

    if (this.notBrandTagIds !== '') {
      result['nb'] = this.notBrandTagIds;
    }

    if (this.notCategoryCodeTagIds !== '') {
      result['nc'] = this.notCategoryCodeTagIds;
    }

    if (this.notClasses !== '') {
      result['nk'] = this.notClasses;
    }

    if (this.knownUserService.knowUserEmail) {
      result['known_user_email'] = this.knownUserService.knowUserEmail;
    }

    if (this.knownUserService.knowUserType && this.knownUserService.knownUserCountry) {
      result['country'] = this.knownUserService.knownUserCountry;
    }

    result['siteTag'] = this.platformService.currentPlatform.SITE_TAG;

    for (const option in this.filter) {
      if (this.filter[option].length > 0) {
        result[option] = `${this.filter[option].join(',')}`;
      }
    }

    return result;
  }

  public search(query: string, filter = {}, getItems = true, notGuids = []): any {

    this.resetSearchDeduplication();

    if (query) {
      query = this.sanitizeSearchQuery(query);
    }

    // Track search
    const trackData = {
      query
    };

    // Track first category?
    if (this.cat) {
      trackData['category'] = this.cat;
    }

    this.trackSearch(trackData);

    if (!query && !filter && !this.vendorParent) {
      return false;
    }

    const queryWords = query.trim().split(' ');
    const notIndex = queryWords.indexOf('NOT');

    if (notIndex !== -1) {
      this.query = queryWords.slice(0, notIndex).join(' ');
      this.notQuery = queryWords.slice(notIndex + 1).join(' ');
    } else {
      this.query = query.trim();
    }

    if (Object.keys(filter).length) {
      this.filter = filter;
    }

    this.offset = 0;
    this.notGuids = notGuids;

    if (getItems) {
      return this.getItems();
    }
  }

  public sanitizeSearchQuery(searchQuery: string = ''): string {
    return searchQuery ? searchQuery.replace(/['“”‘’ʼ’"®©\\\/]/g, '').replace(/[,;]/g, ' ').replace(/\s+/g, ' ').trim() : searchQuery;
  }

  public setOffset(value: number): any {
    this.offset = value;
    return this.getItems();
  }

  public async getCategories(): Promise<CategoriesResponseItem[]> {
    return this.http.get<CategoriesResponseItem[]>(`${environment.apiUrl}/api/search/categories`).toPromise();
  }

  public async getBrands(tags: string[] = []): Promise<BrandsResponse> {
    let query = '?';
    if (tags?.length) {
      query += `&tags=${tags.join(',')}`;
    }

    return this.http.get<BrandsResponse>(`${environment.apiUrl}/api/search/brands${query}`).toPromise();
  }

  public async setOrder(orderBy: string = '') {
    this.resetSearchDeduplication();

    // Start at the top on re-order
    this.offset = 0;

    this.orderBy = orderBy;
    return this.getItems();
  }

  public async setOrderCat(orderByCat: string = '') {
    this.resetSearchDeduplication();

    // Start at the top on re-order
    this.offset = 0;

    this.orderByCat = orderByCat;
    return this.getItems();
  }

  public async markOfferAsFavorite(guid, isSaved: boolean = true) {
    return isSaved
      ? this.http
        .put<any>(`${environment.apiUrl}/api/my/offers/${guid}/favorites`, {})
        .toPromise()
        .then(data => {
        })
        .catch(err => {
          console.error(err);
        })
      : this.http
        .delete<any>(`${environment.apiUrl}/api/my/offers/${guid}/favorites`)
        .toPromise()
        .then(data => {
        })
        .catch(err => {
          console.error(err);
        });
  }

  public setFilter(filter = {}, refreshFilters: boolean = false) {
    this.resetSearchDeduplication();

    // Track search

    // Every filter change = Start at the top of the results for new results
    this.offset = 0;

    for (const option in filter) {
      if (!filter[option].length) {
        delete this.filter[option];
      } else {
        this.filter[option] = filter[option];
      }
    }

    if (refreshFilters) {
      this.refreshFiltersAndItems();
    }

  }

  public refreshFiltersAndItems(ignoreDuplication = true, isTakeoverRefresh = false): void {
    this.editorialGuid$ = new Subject();
    void this.getFilterData();

    const trackData = {
      query: this.query,
      filter: this.filter
    };

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('search-filter', trackData),
      new AnalyticsGAEventModel('search-filter', {
        category: 'search',
        label: JSON.stringify(trackData)
      })
    ]);

    void this.getItems(ignoreDuplication, isTakeoverRefresh);
  }

  public async getFilterPanelData(): Promise<FilterResponse> {
    let query = this.getQueryString();
    query += `&editorial_mode=${this.editorialMode}`;

    if (this.offset === 0 && (this.editorialMode === 'hero' || this.editorialGuid)) {
      const editorialGuid = await lastValueFrom(this.editorialGuid$);

      if (editorialGuid) {
        query += `&editorial_guid=${editorialGuid}`;
      }
    }

    return lastValueFrom(
      this.http.get<FilterResponse>(`${environment.apiUrl}/api/search/filter${query}`)
    );
  }

  public setDestination(destination = '') {
    this.destination = destination;
  }

  public setTourType(tourTypes = '') {
    this.tourTypes = tourTypes;
  }

  public seteventType(eventTypes = '') {
    this.eventTypes = eventTypes;
  }

  public setSport(sports = '') {
    this.sports = sports;
  }

  public setDiscount(discount = '') {
    this.discount = discount;
  }

  public setCity(city = '') {
    this.city = city;
  }

  public setAreaTarget(areaTarget = '') {
    this.areaTarget = areaTarget;
  }

  public setVendorParent(vendorParent: string) {
    this.vendorParent = vendorParent;
  }

  public setSpecials(status: string) {
    this.specials = status;
  }

  public setSearchVersion(version: string) {
    const versions = ['1', '2'];

    if (!versions.includes(version)) {
      this.searchVersion = '1';
      return;
    }

    this.searchVersion = version;
  }

  public clearHiddenTerms() {
    this.destination = '';
    this.eventTypes = '';
    this.tourTypes = '';
    this.sports = '';
    this.city = '';
    this.vendorParent = '';
    this.cat = '';
    this.specials = '';
    this.vendor = '';
    this.widgetTimeFrames = {};
    this.filter = {};
  }

  public clearQuery() {
    this.query = '';
  }

  public setTimeframe(vendor: string = '', dateStart: Date = null, dateEnd: Date = null) {
    if (vendor) {
      this.widgetTimeFrames[vendor] = {
        start: dateStart,
        end: dateEnd,
      };
    } else {
      this.widgetTimeFrames = {};
    }
  }

  public resetSearchDeduplication() {
    // Clear results map so that we will not dedupe against a prior search
    this.resultDeduplicationMap.clear();
  }

  public isDuplicateSearch(query: string) {
    return this.resultDeduplicationMap.has(query);
  }

  public markSearchForDeduplication(query: string) {
    // Set the query in the map so we will not process this again
    this.resultDeduplicationMap.set(query, true);
  }

  public clearFilterPanelData() {
    this.filterPanelDataSource.next([]);
  }

  public clearSubCategoriesData() {
    this.subCategoriesDataSource.next([]);
  }

  public getFilterCurrentValue() {
    return this.filterPanelDataSource.getValue();
  }

  public setHiddenSection(hiddenSection: string) {
    this.hiddenSection = hiddenSection;
  }

  public resetSearch() {
    this.resultsSource.next({} as SearchResults);
  }

  public getQueryParamsForAds(): { key: string; value: string }[] {
    const checkedFilterItems = this.filter['f_cat'] || [];
    return [
      { key: 'q', value: this.query },
      { key: 'c', value: checkedFilterItems.length ? checkedFilterItems.join(',') : this.categoryCodeTagIds },
      { key: 'b', value: this.brandTagIds },
      { key: 'd', value: this.alternateSpecialsQueryParam },
      { key: 'k', value: this.classes },
      { key: 's', value: this.adminFeatures },
    ];
  }

  public getQueryExcludeParamsForAds(): { key: string; value: string }[] {
    return [
      { key: 'q', value: this.notQuery },
      { key: 'c', value: this.notCategoryCodeTagIds },
      { key: 'b', value: this.notBrandTagIds },
      { key: 'k', value: this.notClasses }
    ];
  }


  public trackSearch(trackData) {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('search', trackData),
      new AnalyticsGAEventModel('search', {
        category: 'search',
        label: JSON.stringify(trackData)
      })
    ]);
  }

  public trackAnalyticsEvents(action: string, data = {}): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel(
        action,
        data),
      new AnalyticsGAEventModel(action, {
        category: 'search-box',
        label: data ? getAnalyticsGALabelFromObject(data) : ''
      })
    ]);
  }

  public isSpecialsValue(specialsValue: string): boolean {
    return specialOffersOptions.indexOf(specialsValue) > -1 || userSpecialOffersOptions.indexOf(specialsValue) > -1;
  }

  public initOptionsFromParams(queryParams: Params): void {
    // Don't forget to update FILTER_PARAMS if you want to preserve param on filter change
    const {
      b = '',
      c = '',
      ieh_guid: editorialGuid = '',
      editorial = 'none',
      k = '',
      bp_beta: bpBeta = '',
      show_all_filters: showAllFilters = '',
      'future-dt': futureDate = '',
      'pass-thru': passThru = '',
      cat = '',
      vendor = '',
      d = '',
      n = '',
      specials = '',
      ofg = '',
      ofg_featured: ofgFeatured = '',
      tdg = '',
      nq = '',
      nc = '',
      nb = '',
      nk = '',
      s = '',
      paylogix = '',
      t: titleOverride = ''
    } = queryParams;

    this.brandTagIds = b;
    this.adminFeatures = s;
    this.categoryCodeTagIds = c;
    this.classes = k;
    this.allClasses = bpBeta;
    this.passThru = passThru;
    this.showAllFilters = showAllFilters;
    this.futureDate = futureDate;
    this.cat = cat;
    this.specials = specials;
    this.alternateSpecialsQueryParam = d;
    this.vendor = vendor;
    this.navId = n;
    this.offerGuids = ofg;
    this.offerGuidsFeatured = ofgFeatured;
    this.todayDealGuids = tdg;

    this.notQuery = nq;
    this.notCategoryCodeTagIds = nc;
    this.notBrandTagIds = nb;
    this.notClasses = nk;
    this.editorialGuid = editorialGuid;
    this.editorialMode = editorialModes.includes(editorial) ? editorial : 'none';
    this.paylogixCurrentlyEnrolledMode = paylogix === 'true';
    this.titleOverride = titleOverride;
  }

  public getNavigationFromData(): { label: string; url: string; queryParams: string } {
    return {
      label: 'Search Results',
      url: '/offers',
      queryParams: this.getQueryParams()
    };
  }

  public setIsTakeOverAdmin(isTakeover: boolean): void {
    this.isTakeoverAdmin = isTakeover;
  }

  private modifyQueryLimit(query, newLimit = 1): string {
    return query.replace(/\?limit=\d+/, `?limit=${newLimit}`);
  }
}
