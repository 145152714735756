<section [@opacityFadeInOut]
         *ngIf="multiWidgetUnits && multiWidgetUnits.length"
         [class.search-widget-top-indent]="topIndent"
         class="search-widget multi-widget">
  <app-tabset [tabsetData]="tabset"
              [defaultIndex]="defaultTab"
              (selectedTabId)="getSelectedTabId($event)"
              [customClass]="'multi'">
    <app-hint-label *ngIf="widgetOfferVisible && widgetLabelInHeader"
                    [description]="widgetLoyaltyDescription"
                    [offersData]="widgetOffers"
                    class="multi-widget-label multi-widget-label--inside-header">
    </app-hint-label>
  </app-tabset>

  <div class="multi-widget-unit-container">
    <div [@opacityFadeInOut]
         *ngIf="tabId === 'taw_hotels_search' && !isLoading">
      <app-hotels-search-widget class="search_widgets_container_item"
                                [widget]="widgetUnit"
                                [customClass]="'custom-multi custom-hotel'"
                                (displayed)="onDisplayed($event)"
                                (clicked)="onClicked($event)">
      </app-hotels-search-widget>
    </div>

    <div [@opacityFadeInOut]
         *ngIf="tabId === 'rental_car_search' && !isLoading">
      <app-rental-car-search-widget class="search_widgets_container_item"
                                    [widget]="widgetUnit"
                                    [customClass]="'custom-multi custom-rental'"
                                    (displayed)="onDisplayed($event)"
                                    (clicked)="onClicked($event)">
      </app-rental-car-search-widget>
    </div>

    <div [@opacityFadeInOut]
         *ngIf="tabId === 'tickets_search' && !isLoading">
      <app-tickets-search-widget class="search_widgets_container_item"
                                 [widget]="widgetUnit"
                                 [customClass]="'custom-multi'"
                                 (displayed)="onDisplayed($event)"
                                 (clicked)="onClicked($event)">
      </app-tickets-search-widget>
    </div>
  </div>
</section>
