<section class="tickets search-widget {{ customClass }}"
         [class.no-tickets-search-border]="isSubmitted"
         [class.search-widget-top-indent]="topIndent">
  <div class="search-widget_header">
    <img class="search-widget_header__logo"
         loading="lazy"
         [src]="widget.icon_url | imageUrl: imageOption">
    <div class="search-widget_header__container">
      <div class="search-widget_header__container__title">{{widget.header}}</div>
    </div>
  </div>
  <div class="tickets_search">
    <span class="tickets_search_mask widget_search_mask">Destination: </span>
    <app-google-places-autocomplete [normalizedDestination]="true"
                                    placeholder="City or ZIP"
                                    [wideFormat]="true"
                                    [autocompleteOptions]="autocompleteOptions"
                                    (destinationChange)="updatePlaceName($event)"
                                    (addressChange)="handleAddressChange($event)">
    </app-google-places-autocomplete>
  </div>

  <div class="widget_entity_container widget_entity_mask widget_search_mask"></div>
  <span class="widget_entity_mask widget_search_mask widget_search_place">I am looking for:</span>
  <input id="tickets-entity"
         placeholder="parks, artists, venues, events"
         aria-label="tickets-entity"
         type="text"
         class="tickets_entity-input"
         name="tickets-entity"
         [(ngModel)]="ticketsEntity"
         (keyup.enter)="search()"
         (ngModelChange)="ticketsEntityChange($event)" />
  <button class="tickets_button btn btn-primary mini-widget-action__search"
          type="submit"
          (click)="search()">
    <span class="tickets_button_text-xs">SEARCH</span>
    <img class="tickets_button_text"
         loading="lazy"
         src="/assets/icons/ic-search-white.svg">
  </button>
</section>

<app-tickets-search-result [@opacityFadeInOut]
                           *ngIf="isSubmitted"
                           [searchResult]="ticketsProductSearchData"
                           [customClass]="customClass"
                           [widget]="widget"
                           [ticketsInputData]="ticketsInputData"
                           [showMoreCountStringValue]="showMoreCount">
</app-tickets-search-result>
