/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ContactFormModalComponent } from 'g3-common-ui';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalAlertComponent } from '@shared/components/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '@shared/services/profile/profile.service';
import { take } from 'rxjs/operators';

const CONTACT_SUBMIT_TITLE = 'Thanks for reaching out!';
const CONTACT_SUBMIT_CONTENT = 'Thank you for sending your question or idea to us. A Customer Advocate will respond to you shortly.';
const NOMINATION_SUBMIT_TITLE = 'Thanks, we\'ve received your nomination.';
const NOMINATION_SUBMIT_CONTENT = `Nominations are prioritized based on a number of factors, and worked by the team in priority order.
  Because of high nomination volume, the team does not personally reply to nomination requests.`;
const NOMINATION_REASON = 'Nomination';

@Injectable()
export class ContactFormService {

  defaultAlertModalContent = {
    title: '',
    text: '',
    buttonText: 'Close',
    isButton: true
  };

  constructor(
    private analyticsService: AnalyticsService,
    private modalService: NgbModal,
    private http: HttpClient,
    private profileService: ProfileService
  ) {
  }

  openConfirmAlertModal(withContent = this.defaultAlertModalContent): void {

    const modalRef = this.modalService.open(ModalAlertComponent, {
      centered: true,
      windowClass: 'confirm-modal text-center contact-alert-modal'
    });

    modalRef.componentInstance.content = withContent;
  }

  openContactFormModal(defaults?: any): void {

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('contact-form-open', {}),
      new AnalyticsGAEventModel('contact-form-open', {})
    ]);

    const modalRef = this.modalService.open(ContactFormModalComponent, {
      centered: true,
      windowClass: 'form-modal'
    });
    const userData = this.profileService.getData();

    modalRef.componentInstance.userData = userData;
    modalRef.componentInstance.dismissModal
      .pipe(take(1))
      .subscribe(() => {
        modalRef.close();
      });

    modalRef.componentInstance.submit
      .pipe(take(1))
      .subscribe(async data => {
        this.trackContactModalAnalytic(data);
        modalRef.close();
        if (data.reason === NOMINATION_REASON) {
          this.defaultAlertModalContent['title'] = NOMINATION_SUBMIT_TITLE;
          this.defaultAlertModalContent['text'] = NOMINATION_SUBMIT_CONTENT;
        } else {
          this.defaultAlertModalContent['title'] = CONTACT_SUBMIT_TITLE;
          this.defaultAlertModalContent['text'] = CONTACT_SUBMIT_CONTENT;
        }

        this.openConfirmAlertModal();

        await this.http.post(`${environment.apiUrl}/api/contact-us`, {
          ...data,
          contact_type: ['user'],
          url: window.location.href
        }).toPromise();
      });

    if (defaults) {
      modalRef.componentInstance.setDefaults(defaults);
    }

  }

  private trackContactModalAnalytic(data: any): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('contact-form-submit', data),
      new AnalyticsGAEventModel('contact-form-submit', {
        category: '' +
          '',
        label: data['reason'] || null
      })
    ]);
  }

}
