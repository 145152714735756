<form ngNoForm
      novalidate
      class="unsubscribe-form">
  <div *ngIf="unsubscribeOptionContent.exclusions && unsubscribeOptionContent.exclusions.length"
       class="unsubscribe-form-exclusions">
    <label *ngFor="let exclusion of unsubscribeOptionContent.exclusions; let i = index"
           #checkboxTitle
           [ngClass]="getWideTitle(checkboxTitle)"
           class="unsubscribe-form-exclusions__checkbox">
      <input class="unsubscribe-form-exclusions__checkbox--input"
             [class.checked]="exclusion.value === true"
             type="checkbox"
             name="exclusion"
             tabindex="20 + i"
             (change)="getSelectedCheckbox(exclusion)"
             [(ngModel)]="exclusion.value" />
      <span [innerHTML]="getTitle(exclusion)"
            class="unsubscribe-form-exclusions__checkbox--title">
      </span>
    </label>
  </div>
  <div name="radioBasic"
       class="unsubscribe-form-radio">
    <label class="radio-label btn unsubscribe-form-radio__item"
           *ngFor="let subscription of unsubscribeOptionContent.subscription; let i = index"
           #radioTitle
           [ngClass]="getWideTitle(radioTitle)"
           [class.active]="radioValue === subscription.value">
      <input class="unsubscribe-form-radio__item--input"
             type="radio"
             tabindex="100 + i"
             name="subscription"
             [(ngModel)]="radioValue"
             [value]="subscription.value"
             (change)="getSelectedRadio()"/>
      <span [innerHTML]="getTitle(subscription)"
            class="unsubscribe-form-radio__item--title">
      </span>
    </label>
  </div>
</form>