<div class="mobile-show-hide-handler">
  <div class="show-filters"
       (click)="showFilterPanel()"
       [appAutoTabIndex]="groupTabIndex + 189">
    <div class="filter-icon">
      <img src="/assets/icons/ic-filters.svg"
           role="presentation"
           loading="lazy"/>
      <div [ngClass]="{ indicator: isFiltersSelected }"></div>
    </div>
    Filter Offers
  </div>
  <div class="show-list"
       *ngIf="!showPanel"
       (click)="showPanel = true; handleFooterWidth()"
       [appAutoTabIndex]="groupTabIndex + 190">
    <img src="/assets/icons/ic-list-small.svg"
         loading="lazy"
         role="presentation" />
    List View
  </div>
  <div class="show-list"
       *ngIf="showPanel"
       (click)="showPanel = false"
       [appAutoTabIndex]="groupTabIndex + 190">
    <img src="/assets/icons/ic-map.svg"
         role="presentation" />
    Map View
  </div>
</div>
<div class="show-hide-handler"
     (click)="showPanelStatus(showPanel); handleFooterWidth()"
     placement="right"
     [ngbTooltip]="showPanelTooltipContent"
     container="body"
     [closeDelay]="500"
     [appAutoTabIndex]="groupTabIndex + 191"
     [attr.aria-label]="showPanel ? 'Expand list view' : 'Collapse list view'"
     role="button"
     (mouseenter)="addHoverClass($event)"
     (mouseleave)="removeHoverClass($event)"
     (click)="showPanel = !showPanel"
     [ngClass]="{ 'show-panel': showPanel }">
  <svg *ngIf="showPanel"
       width="8"
       height="14"
       viewBox="0 0 8 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 7L8 0V14L0 7Z"
          fill="currentColor" />
  </svg>
  <svg *ngIf="!showPanel"
       width="8"
       height="14"
       viewBox="0 0 8 14"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 7L0 0V14L8 7Z"
          fill="currentColor" />
  </svg>
</div>
<div *ngIf="showPanel"
     [@widthInOut]
     class="list-view-panel"
     [ngClass]="{ 'show-panel': showPanel }">
  <div class="scrollable">
    <app-list-view-filters [groupTabIndex]="groupTabIndex + 20"
                           (zipSelected)="onZipSelected($event)"
                           [zipSuggestions]="zipSuggestions"
                           [zipCodeInvalid]="zipCodeInvalid"
                           [zipCode]="zipCode"
                           (trackAnalyticsEvent)="trackAnalyticsEvent($event)"
                           [customClass]="'filters'">
    </app-list-view-filters>
    <div class="list-view-items">
      <app-list-view-item [markerGuid]="markerGuid"
                          [expandCard]="expandCard"
                          *ngFor="let item of items"
                          [item]="item"
                          [markers]="items"
                          [groupTabIndex]="groupTabIndex + 190"
                          [activatedMarker]="activatedMarker">
      </app-list-view-item>
    </div>
  </div>
  <div class="list-view-pagination">
    <div class="content-container"
         [@widthInOut]>
      <div class="pagination-title"
           *ngIf="total">
        Showing results {{ offset + 1 }} - {{ lastIndex }}
      </div>
      <div class="pagination-buttons"
           *ngIf="total">
        <div class="btn btn-text btn-text-secondary prev"
             (click)="prev()"
             [appAutoTabIndex]="groupTabIndex + 196"
             aria-label="Previous page"
             role="button"
             [ngClass]="{ disabled: offset === 0 }">
          <svg width="16"
               height="16"
               viewBox="0 0 16 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M11 14L5 8L11 2"
                  stroke="currentColor"
                  stroke-width="2" />
          </svg>
        </div>
        <div class="btn btn-text btn-text-secondary next"
             (click)="next()"
             [appAutoTabIndex]="groupTabIndex + 196"
             aria-label="Next page"
             role="button"
             [ngClass]="{ disabled: offset + itemsPerPage > total }">
          <svg width="16"
               height="16"
               viewBox="0 0 16 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M5 14l6-6-6-6"
                  stroke="currentColor"
                  stroke-width="2" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="filters-panel"
     id="filter-control-panel">
  <div class="filters-panel-header">
    <a class="filters-panel-header__close"
       [appAutoTabIndex]="groupTabIndex + 190"
       (click)="closeFilterPanel()">
      <img class="close-button"
           src="/assets/icons/close.svg"
           alt="close" />
    </a>
    <p class="filters-panel-header__title">Filter Offers</p>
  </div>
  <app-list-view-filters class="list-view-panel"
                         [groupTabIndex]="groupTabIndex + 190"
                         (zipSelected)="onZipSelected($event)"
                         (filterSelected)="onFilterSelected($event)"
                         (trackAnalyticsEvent)="trackAnalyticsEvent($event)"
                         [zipSuggestions]="zipSuggestions"
                         [zipCodeInvalid]="zipCodeInvalid"
                         [zipCode]="zipCode"
                         [customClass]="'mobile-filters'">
  </app-list-view-filters>
  <div class="filters-panel-footer">
    <button class="btn btn-primary filters-panel-footer__button"
            [appAutoTabIndex]="groupTabIndex + 194"
            (click)="closeFilterPanel()">
      Show {{ total }} Offers
    </button>
  </div>
</div>
