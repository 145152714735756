import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {
  SelectedUnsubscribe,
  SuccessContent,
  UnsubscribeOptionContent,
  UnsubscribeOptionItem
} from '@app/unsubscribe/interfaces/subscription-options.interface';
import { UnsubscribeFormItem } from '@app/unsubscribe/interfaces/subscriptions-response';
import { UnsubscribeFormType } from '@app/unsubscribe/enums/form-type.enum';
import { UnsubscribeOptionsService } from '@app/unsubscribe/services/unsubscribe-options.service';

@Component({
  selector: 'app-unsubscribe-option-form',
  templateUrl: './unsubscribe-option-form.component.html',
  styleUrls: ['./unsubscribe-option-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubscribeOptionFormComponent implements OnChanges {

  @Input() unsubscribeOptionContent: UnsubscribeOptionContent;
  @Input() submitFormState: boolean;
  @Input() buttonId: string;
  @Input() formType: string;

  @Output() successHeaders: EventEmitter<SuccessContent> = new EventEmitter<SuccessContent>();
  @Output() selectedUnsubscribes: EventEmitter<SelectedUnsubscribe[]> = new EventEmitter<SelectedUnsubscribe[]>();
  @Output() isSelectedValuesExist: EventEmitter<boolean> = new EventEmitter<boolean>();

  radioValue = '';
  optionType: string;
  checkboxValues: UnsubscribeOptionItem[] = [];

  constructor(
    private unsubscribeOptionsService: UnsubscribeOptionsService
  ) { }

  ngOnChanges(): void {
    if (this.submitFormState && this.buttonId === UnsubscribeFormType.Family) {
      this.submitForm();
    }
  }

  getTitle(object: UnsubscribeFormItem): string {
    return this.unsubscribeOptionsService.getOptionTitle(object, this.formType);
  }

  getSelectedCheckbox(exclusion: UnsubscribeOptionItem): void {
    if (exclusion.value === true) {
      this.isSelectedValuesExist.emit(true);
      this.checkboxValues.push(exclusion);
    } else {
      this.isSelectedValuesExist.emit(false);
      this.checkboxValues = this.checkboxValues.filter(i => i.value === true);
    }
  }

  getSelectedRadio(): void {
    this.isSelectedValuesExist.emit(true);
  }

  getWideTitle(element: HTMLElement): void {
    if (element && element.offsetHeight > 30 && element.offsetHeight < 50) {
      element.classList.add('second-level-title');
    } else if (element && element.offsetHeight > 51) {
      element.classList.add('third-level-title');
    }
  }

  private submitForm(): void {
    const selectedUnsubscribes = this.getSelectedUnsubscribes();

    if (selectedUnsubscribes.length) {
      this.selectedUnsubscribes.emit(selectedUnsubscribes);
      this.successHeaders.emit({
        primary: this.getPrimaryHeader(),
        secondary: this.unsubscribeOptionsService.getSecondarySuccessHeader(selectedUnsubscribes, this.unsubscribeOptionContent)
      });
    }
  }

  private getSelectedUnsubscribes(): SelectedUnsubscribe[] {
    const radioObject: UnsubscribeOptionItem = this.unsubscribeOptionContent.subscription.find(i => i.value === this.radioValue);
    return this.unsubscribeOptionsService.getSelectedUnsubscribes(radioObject, this.checkboxValues);
  }

  private getPrimaryHeader(): string {
    const subscription = this.unsubscribeOptionContent.subscription;
    const radioOption: UnsubscribeOptionItem = subscription.find(i => i.value === this.radioValue);

    return radioOption && radioOption.key === 'sid'
      ? 'Successfully Unsubscribed!'
      : 'Subscription Updated!';
  }

}
