<div *ngIf="accountInfoLoading"
     class="page-spinner-wrapper">
  <app-spinner [size]="100"></app-spinner>
</div>
<div *ngIf="!accountInfoLoading"
     class="form-sign-in form-upgrade-version">
  <div class="left">
    <div class="content">
      <app-logo imgClass="big-logo"></app-logo>
      <div class="form-content">
        <h1>{{ this.pageTitle }}</h1>
        <div class="description">
          <p>{{ this.mainSectionContent }}</p>
          <p>{{ this.secondarySectionContent }}</p>
        </div>
        <div class="actions">

          <app-spinner *ngIf="loading"></app-spinner>
          <a class="btn btn-primary"
             routerLink="/"
             *ngIf="!loading">{{ this.updateVersionButtonText }}</a>

        </div>
      </div>
    </div>
    <app-auth-footer [classListContainer]="'hide-lt-1024'"
                     [classListRight]="'d-none'"
                     [footerText]="footerContent"
                     [hideDescriptionLink]="isInstantUpdate"
                     [marketplaceInfo]="marketplaceInfo"
                     [displayLink]="true">
    </app-auth-footer>
  </div>
  <div class="right">
    <app-disclaimer-carousel class="hide-lt-1024"
                             type="carousel"
                             *ngIf='images.length'
                             [images]="images">
    </app-disclaimer-carousel>
    <app-auth-footer [classListLeft]="'hide-gt-1024'"
                     [footerText]="footerContent"
                     [hideDescriptionLink]="isInstantUpdate"
                     [marketplaceInfo]="marketplaceInfo"
                     [showFooterLinks]="false"
                     [displayLink]="true">
    </app-auth-footer>
  </div>
</div>