<ng-container *ngIf="!!data">
  <section [attr.id]="anchorLink"
           class="container main-container {{ wideContainerClass }}"
           *ngIf="widgets?.length">
    <app-zone-header *ngIf="!!data.name"
                     [data]="data"
                     [groupTabIndex]="groupTabIndex"
                     (showAllClick)="showAllClickHandler($event)">
    </app-zone-header>

    <app-slide-carousel *ngIf="widgets && widgets.length > 2"
                        [carouselElements]="widgetElems"
                        (infiniteEnd)="this.widgets.push(this.widgets.shift());"
                        (infiniteStart)="this.widgets.unshift(this.widgets.pop());">
      <div #widgetElems
           class="widget-item carousel-item"
           *ngFor="let widget of widgets; trackBy: trackById; let i = index;">
        <div [ngSwitch]="widget.widgetType">
          <div *ngSwitchCase="widgetType.Html"
               [innerHTML]="widget.html | safe">
          </div>
          <div *ngSwitchCase="widgetType.Custom">
            <app-custom-widget-host [widget]="widget"
                                    [groupTabIndex]="groupTabIndex + i * 200"></app-custom-widget-host>
          </div>
        </div>
      </div>
    </app-slide-carousel>

    <div class="widgets-items row"
         *ngIf="widgets && widgets.length <= 2">
      <div class="widget-item col-lg-6"
           *ngFor="let widget of widgets; trackBy: trackById">
        <div [ngSwitch]="widget.widgetType">
          <div *ngSwitchCase="widgetType.Html"
               [innerHTML]="widget.html | safe">
          </div>
          <div *ngSwitchCase="widgetType.Custom">
            <app-custom-widget-host [widget]="widget"></app-custom-widget-host>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
