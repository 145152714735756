/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/no-misused-promises */
import { ActivatedRoute, Router } from '@angular/router';
import { AdsAnalyticsService } from '@shared/services/analytics/ads-analytics.service';
import { AdsOffersService } from '@shared/services/ads/ads-offers.service';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { AnchorService } from '@app/shared/services/anchor.service';
import {
  AutoTabIndexService,
  ComponentHtmlIdRegistryService,
  PlatformCountry
} from 'g3-common-ui';
import { ConfigService, EnrollmentInfo } from '@shared/services/config.service';
import {
  delay,
  filter,
  map,
  take,
  takeUntil,
  distinctUntilChanged,
  concatMap
} from 'rxjs/operators';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { HeaderOffset } from '@core/layouts/home/anchors.enum';
import { HomepageDataLoaderService } from '@app/shared/services/homepage-data-loader.service';
import { InfiniteScrollService } from '@app/shared/services/infinite-scroll.service';
import { OpenEnrollmentService } from '@shared/services/open-enrollment/open-enrollment.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { SearchAdsItemResponse, SearchAdsResponse } from '@shared/services/ads/search-ads-items-response.interface';
import { SearchAdsParams, SearchAdsService } from '@shared/services/ads/search-ads.service';
import { SearchResultsAdsModel } from '@shared/models/search-results-ads.model';
import { Subject, Subscription, from } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { ZoneSection, ZoneSectionElement, ZonesSearchResponse } from '@zones/interfaces/zones-search-response.interface';
import { ZonesService, allZonesToLoad } from '@zones/services/zones.service';
import { OfferBadgeService } from '@app/shared/services/offer-badge.service';
import { OfferBadgeSettingsModel } from '@app/shared/models/offer-badge-settings.model';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { AccessSettingOption } from '@app/shared/interfaces/search-offer-item.interface';
import { COUNTRY_LAST_SESSION_KEY } from '@shared/constants/profile.constants';
import { AdobeAnalyticsEventsService } from '@shared/services/adobe/units/adobe-analytics-events.service';
import { AdobeEventLocation } from '@shared/services/adobe/adobe-analytics.interfaces';

const FLY_IN_DISMISS_KEY = 'fly-in^homepage-closed';

const FLY_IN_MAX_LOAD_ORDER = 5;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  providers: [
    AutoTabIndexService,
    ComponentHtmlIdRegistryService,
    OfferBadgeService
  ]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  public subscriptions: Subscription[] = [];
  public enrollmentData: EnrollmentInfo;
  public adFlyIn: SearchAdsItemResponse;
  public isLoading = true;
  public pinnedZone: { section: string; data: ZoneSectionElement };

  public zonesToLoad: string[] = allZonesToLoad;
  public restZones: string[] = allZonesToLoad.slice(2, allZonesToLoad.length);
  public page = 'homepage';
  public wideContainerClassName = 'wide-container';
  public userCountry: string;
  public isNonPaylogixCountry = false;

  private adsViewInited = false;
  private isEnabledFeaturedBrands: boolean;
  private homepageZones: ZoneSection[];
  private displayedZones: Set<ZoneSection> = new Set<ZoneSection>();
  private destroyStream = new Subject<void>();
  private initNewZoneCounter = 0;
  private initNewZoneStream = new Subject<void>();

  public firstSection: ZoneSection;
  public secondSection: ZoneSection;
  public otherSections: ZoneSection[] = [];

  constructor(
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    public homePageDataLoaderService: HomepageDataLoaderService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private openEnrollmentService: OpenEnrollmentService,
    private searchAdsService: SearchAdsService,
    private adsOffersService: AdsOffersService,
    private zonesService: ZonesService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private adsAnalyticsService: AdsAnalyticsService,
    private anchorService: AnchorService,
    private infiniteScrollService: InfiniteScrollService,
    private offerBadgeService: OfferBadgeService,
    private permissionService: PermissionService
  ) {
    this.infiniteScrollService.updateInfiniteScrollState(false);
  }

  @HostListener('window:scroll', ['$event'])
  public scrollHandler(): void {
    this.anchorService.setScrollPosition(window.pageYOffset);
  }

  public get isAdsViewInited(): boolean {
    return this.adsViewInited;
  }

  public get wideContainerClass(): string {
    return 'wide-container';
  }

  public get isDataLoaded(): boolean {
    return this.homePageDataLoaderService.isDataLoaded;
  }

  public async ngOnInit(): Promise<void> {
    this.subscribeOnOpenEnrollment();
    this.redirectToHomeRedirectUrl();
    this.isEnabledFeaturedBrands = this.homePageDataLoaderService.isEnabledFeaturedBrands();

    this.profileService.profileData$.pipe(takeUntil(this.destroyStream)).subscribe(async data => {
      if (!data || !data.countryLast) {
        return;
      }

      this.userCountry = data.countryLast;
      this.isNonPaylogixCountry = data.countryLast !== 'US';
      await this.getAllZonesData();
      this.subscribeOnFlyInAd();

      this.initNewZoneStream.pipe(
        takeUntil(this.destroyStream),
        concatMap(() => from(this.initNewZoneContent()))
      ).subscribe();

      await this.initNewZoneContent();

      if (!this.isDataLoaded) {
        void this.callDataLoader();
      }
      this.isLoading = false;
    });

    this.offerBadgeService.setBadgeSettings(new OfferBadgeSettingsModel({
      darkPillsCount: 1,
      lightPillsCount: 1,
      sortingReversed: true
    }));
  }

  public ngAfterViewInit(): void {
    setTimeout(() => window.scrollTo({ top: this.anchorService.scrollPosition, behavior: 'smooth' }), 500);

    this.subscriptions.push(this.route.fragment.subscribe((anchor) => {
      if (anchor) {
        this.viewportScroller.scrollToAnchor(anchor);
        this.viewportScroller.setOffset(HeaderOffset);
      }
    }));
  }

  public ngOnDestroy(): void {
    this.adobeAnalyticsEventsService.emitPageEvents(AdobeEventLocation.Homepage);
    this.destroyStream.next();
    this.destroyStream.complete();

    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }

    this.infiniteScrollService.updateInfiniteScrollState(true);
  }

  public isShowAdFlyIn(): boolean {
    const isDismissedPerSession = sessionStorage.getItem(FLY_IN_DISMISS_KEY);
    return this.adFlyIn && !this.homePageDataLoaderService.isClosedFlyIn && !isDismissedPerSession;
  }

  public onShowedAdFlyIn(ad: SearchAdsItemResponse): void {
    this.analyticsService.eventsTrack(this.adsAnalyticsService.getAnalyticsEvents('view', new SearchResultsAdsModel(ad)));
  }

  public onClickedAdFlyIn(): void {
    this.homePageDataLoaderService.isClosedFlyIn = true;
  }

  public onCloseAdFlyIn(): void {
    sessionStorage.setItem(FLY_IN_DISMISS_KEY, 'true');
    this.homePageDataLoaderService.isClosedFlyIn = true;
  }

  public async callDataLoader(): Promise<void> {
    void this.homePageDataLoaderService.loadData(this.isEnabledFeaturedBrands);
    this.adsViewInited = true;
  }

  public async loadMore(event: Event): Promise<void> {
    event.stopPropagation();

    if (this.initNewZoneCounter >= this.homepageZones.length || this.configService.isZonesProgressiveLoadingEnabled()) {
      return;
    }

    this.initNewZoneStream.next();
  }

  public handleDisplayedSection(event: ZoneSection): void {
    this.zonesService.debug(event, 'Rendered');

    this.displayedZones.add(event);
    const allowProgressiveLoading = this.configService.isZonesProgressiveLoadingEnabled();
    this.isLoading = false;

    if (this.displayedZones.size === this.homepageZones.length) {
      setTimeout(() => this.infiniteScrollService.updateInfiniteScrollState(true));
      setTimeout(() => this.adobeAnalyticsEventsService.emitPageEvents(AdobeEventLocation.Homepage), 1000); // give zone-section some time to render
      return;
    }
    this.infiniteScrollService.updateInfiniteScrollState(false);

    // * If enabled display all zones ignoring scroll
    if (allowProgressiveLoading) {
      this.initNewZoneStream.next();
    }
  }

  public trackBySection(index: number, item: ZoneSection): string {
    return item.section;
  }

  private getAds(searchAdsParams: SearchAdsParams, excludeGuids: string[], loadNext = 0): void {
    const isKnown = this.permissionService.hasDefined('known:access');
    const isGuest = this.permissionService.hasDefined('guest:access');

    this.subscriptions.push(
      this.searchAdsService.getAds(searchAdsParams, 1, excludeGuids.toString())
        .pipe(take(1), delay(!loadNext ? 2000 : 0))
        .subscribe(async (data: SearchAdsResponse) => {
          const adResult = await this.adsOffersService.getAds(data);

          const promptForAuthItems = adResult.items.filter(item => (isKnown || isGuest) && item.content_data.access_setting === AccessSettingOption.PromptForAuth);
          const items = adResult.items.filter(item => promptForAuthItems.indexOf(item) === -1);
          if (items.length) {
            this.adFlyIn = items[0];
          } else if ((!adResult.items.length || promptForAuthItems.length) && loadNext < FLY_IN_MAX_LOAD_ORDER) {
            const hiddenItemsGuids = [...data.items, ...promptForAuthItems].map(item => item.guid);
            this.getAds(searchAdsParams, [...excludeGuids, ...hiddenItemsGuids], ++loadNext);
          }
        })
    );
  }

  private subscribeOnFlyInAd(): void {
    if (!this.configService.getFeature('advertising_modals', true)) {
      return;
    }
    if (!this.homePageDataLoaderService.isClosedFlyIn) {
      const searchAdsParams: SearchAdsParams = {
        type: 'fly-in^homepage',
        sites: this.configService.getOption('subdomain'),
        excludeBrands: this.configService.getExcludeBrands().join(','),
        excludeCategories: this.configService.getExcludeCategories().join(',')
      };

      this.profileService.profileData$
        .pipe(
          take(2),
          filter(i => !!i.dismissedDecisions),
          map(i => i.dismissedDecisions))
        .subscribe(item => {
          const mappedGuids = item.map(ad => ad.guid);
          this.getAds(searchAdsParams, mappedGuids);
        });
    }
  }

  private redirectToHomeRedirectUrl(): void {
    const countries = this.configService.getOption<PlatformCountry[]>('allowed_countries', []);
    const countryOptions = this.configService.getOption<PlatformCountry[]>('country_options', []);

    if (countryOptions && countryOptions.length && countryOptions.length > 0) {
      countryOptions.forEach(value => {
        const country = countries.find(i => i.country_code === value.country_code);
        if (country) {
          const index = countries.indexOf(country);
          if (value.country_url) {
            countries[index]['country_url'] = value.country_url;
          }
          if (value.home_redirect_url) {
            countries[index]['home_redirect_url'] = value.home_redirect_url;
          }
        }
      });
    }

    this.subscriptions.push(
      this.profileService.profileData$.subscribe(profileData => {
        if (profileData && profileData.countryLast) {
          const currentUserCountry: Partial<PlatformCountry> = countries.find(c => c.country_code === profileData.countryLast);
          let countryUrl = '/home';
          if (currentUserCountry.home_redirect_url) {
            countryUrl = currentUserCountry.home_redirect_url;
          }

          if (countryUrl && countryUrl !== '/home') {
            void this.router.navigateByUrl(countryUrl);
          }
        }
      }, (err) => {
        console.error(err);
      })
    );
  }

  private async getAllZonesData(): Promise<void> {
    const zones = await this.getZonesData(this.page, this.zonesToLoad);

    this.handlePinnedSection(zones);
    this.homepageZones = !isEmpty(zones.items) ? zones.items : [];
    this.firstSection = this.homepageZones.find(i => i.section === '1');
    setTimeout(() => {
      this.secondSection = this.homepageZones.find(i => i.section === '2');
    }, 500);
    setTimeout(() => {
      this.otherSections = this.homepageZones.filter(i => this.restZones.includes(i.section));
    }, 1000);
  }

  private handlePinnedSection(zones: ZonesSearchResponse): void {
    const pinnedZoneElementId: string = this.route.snapshot.queryParams.zone_id || '';
    if (zones?.items?.length) {
      if (!isEmpty(zones.items) && pinnedZoneElementId) {
        zones.items.forEach((value, index) => {
          const zoneSection = zones.items[index].elements.find(i => i.guid === pinnedZoneElementId);
          if (zoneSection && this.zonesService.isAllowedToPin(zoneSection.type) && isEmpty(this.pinnedZone)) {
            this.pinnedZone = { section: (index + 1).toString(), data: zoneSection };
          }
        });
      }
    }
  }

  private async getZonesData(page: string, sections: string[]): Promise<ZonesSearchResponse> {
    const countryLast = localStorage.getItem(COUNTRY_LAST_SESSION_KEY);
    const queryString = this.zonesService.getQueryString(page, sections, countryLast);
    return this.zonesService.getZonesData(this.page, queryString);
  }

  private async initNewZoneContent(initialLoad = true): Promise<void> {
    this.isLoading = true;

    this.initNewZoneCounter += 1;

    const zone = this.homepageZones[this.initNewZoneCounter - 1];
    await this.zonesService.loadZoneSection(this.page, { items: [zone] });

    this.subscriptions.push(this.zonesService.getZonesSectionsForPage(this.page)
      .pipe(
        take(1)
      )
      .subscribe(async (data: ZoneSection[]) => {
        if (data.length > this.initNewZoneCounter) {
          this.initNewZoneCounter = data.length;
        }

        const lastZone = last(data);

        if (initialLoad && lastZone?.elements.every(item => item.ads.length < item.min_units)) {
          await this.initNewZoneContent(false);
        }
      }));
  }

  private subscribeOnOpenEnrollment(): void {
    this.subscriptions.push(
      this.openEnrollmentService.getEnrollmentInfo().pipe(distinctUntilChanged()).subscribe((data: EnrollmentInfo) => {
        this.enrollmentData = data;

        const isEnrollmentProducts = this.enrollmentData
          && this.enrollmentData.products
          && this.enrollmentData.style !== 'none';

        if (isEnrollmentProducts) {
          this.enrollmentData = {
            ...this.enrollmentData,
            products: this.enrollmentData.products.map(product => this.adsOffersService.getAdFromSearchOfferItem(product))
          };

          return;
        }

        // * Clear products if style 'none'
        if (this.enrollmentData) {
          this.enrollmentData.products = [];
        }
      })
    );
  }
}
