import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';

enum EligibleMyProfileRoutes {
  Dashboard = '/my-account/dashboard',
  Details = '/my-account/details',
  Security = '/my-account/security',
  EmailSubscriptions = '/my-account/email-subscriptions',
  Favorites = '/my-account/favorites',
  Viewed = '/my-account/viewed'
}

@Injectable()
export class AdobeUserProfileDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService
  ) { }

  async setMyProfileData(path: string): Promise<void> {
    await this.adobeDataCollectorService.setPageData({
      section: 'account',
      name: `account: ${this.getPageNameBySubRoute(path)}`,
      type: 'g3 account',
      hierarchy: this.adobeDataCollectorService.setHierarchy('User Profile Page'),
    });
  }

  private getPageNameBySubRoute(path: string): string {
    switch (path) {
      case EligibleMyProfileRoutes.Dashboard:
        return 'Dashboard';
      case EligibleMyProfileRoutes.Details:
        return 'Details';
      case EligibleMyProfileRoutes.Security:
        return 'Password & Security';
      case EligibleMyProfileRoutes.EmailSubscriptions:
        return 'Email Subscriptions';
      case EligibleMyProfileRoutes.Favorites:
        return 'Favorites';
      case EligibleMyProfileRoutes.Viewed:
        return 'Viewed';
    }
  }

}
