<div *ngIf="openEnrollment && (isEnrollmentContent || hasProducts)"
     class="full-width-container"
     [class.oe-extended]="extendedByOECards && !isIllustrations()"
     [class.illustrations]="isIllustrations()"
     [class.empty-products]="!hasProducts"
     [style.background-image]="getTakeoverBackground()">
  <div class="top-part">
    <div class="container main-container relative-container {{wideContainerClass}}">
      <div class="controls">
        <h4 class="blurred"
            *ngIf="openEnrollment?.section_name">
          <img class="oe_header_logo"
               [src]="getSectionIconImageSrc() | imageUrl : { w: 40, h: 40 }"
               alt=""
               loading="lazy">
          {{openEnrollment.section_name}}
        </h4>
        <div *ngIf="!openEnrollment?.section_name && openEnrollment?.help_info"
             class="placeholder">
        </div>
        <svg [appAutoTabIndex]="groupTabIndex"
             *ngIf="openEnrollment?.help_info"
             (click)="showInfoModal()"
             xmlns="http://www.w3.org/2000/svg"
             width="32"
             height="32"
             viewBox="0 0 24 24">

          <path fill="#7b7b7b"
                fill-rule="nonzero"
                d="M13.5 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-.36 4.77c-1.19.1-4.44 2.69-4.44 2.69-.2.15-.14.14.02.42.16.27.14.29.33.16.2-.13.53-.34 1.08-.68 2.12-1.36.34 1.78-.57 7.07-.36 2.62 2 1.27 2.61.87.6-.39 2.21-1.5 2.37-1.61.22-.15.06-.27-.11-.52-.12-.17-.24-.05-.24-.05-.65.43-1.84 1.33-2 .76-.19-.57 1.03-4.48 1.7-7.17.11-.64.41-2.04-.75-1.94z" />
        </svg>
      </div>

      <div class="card blurred"
           *ngIf="openEnrollment && isEnrollmentContent">
        <p *ngIf="openEnrollment.date_visibility">
          {{ getFormattedDateRange(openEnrollment.start_date, openEnrollment.end_date )}}</p>
        <h3 class="{{headlineFontClass}}">{{ openEnrollment.headline }}</h3>
        <div *ngIf="openEnrollment.cta_primary_text">
          <button class="btn btn-primary"
                  (click)="clickOnPrimaryLink($event)"
                  [appAutoTabIndex]="groupTabIndex">{{ openEnrollment.cta_primary_text }}
          </button>
        </div>
        <ng-container *ngIf="openEnrollment.paylogixSubscriptions?.length && !isNonPaylogixCountry">
          <div class="paylogix-deduction-summary">Current Deduction Summary</div>
          <div class="paylogix-current-monthly-total">
            Current Monthly Total:
            <span class="paylogix-current-monthly-total-value">${{paylogixSubscriptionSumDeductions}}</span>
          </div>
        </ng-container>
        <div *ngIf="openEnrollment.cta_secondary_text">
          <a class="cta-secondary-link"
             (click)="clickOnSecondaryLink($event)"
             [appAutoTabIndex]="groupTabIndex">{{ openEnrollment.cta_secondary_text }}
          </a>
        </div>
        <div *ngIf="isPaylogixButton"
             (click)="clickOnPaylogixButton($event)">
          <app-ad-paylogix-button [appAutoTabIndex]="groupTabIndex"
                                  [options]="paylogixButtonOptions"
                                  class="complete-info-button">
          </app-ad-paylogix-button>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-part"
       [style.background-image]="isIllustrations() && 'url(/assets/takeover_illustrations_bottom_bgr.png)'">
    <div class="main-container {{wideContainerClass}}">
      <app-special-offers *ngIf="hasProducts"
                          [groupTabIndex]="980"
                          [specialOffers]="specialOffersAsAds"
                          [isEnrollment]="true"
                          [shouldAlignItems]="true"
                          [wideContainerClass]="wideContainerClass"
                          [isAllowDisableHover]="false"
                          [wideContainerClassName]="wideContainerClassName">
      </app-special-offers>
    </div>
  </div>
</div>