<div class="brand-item card-wrapper"
     (mouseleave)="onMouseLeave()"
     [class.hover-out]="mouseJustLeft"
     [class.row-mode]="rowMode">
  <img class="brand-item-logo"
       [src]="brand.logo"
       [alt]="brand.title"
       loading="lazy"
       (error)="emitBrokenImageUrl()">
</div>
