import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface CategoryTagData {
  name: string;
  cat_code_tag_id: number;
}

@Injectable()
export class CategoryTagsService {

  constructor(
    private http: HttpClient
  ) { }

  async getCategoryTagById(catCodeTagId: string): Promise<CategoryTagData> {
    return this.http.get<CategoryTagData>(`${environment.apiUrl}/api/category-tags/${catCodeTagId}`).toPromise();
  }
}
