import { Injectable } from '@angular/core';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { KnownUserService } from './known-user.service';

@Injectable({
  providedIn: 'root'
})
export class KnownUserPasswordService {

  private _isPasswordDialogOpen$ = new BehaviorSubject<boolean>(false);

  get isPasswordDialogOpen(): Observable<boolean> {
    return this._isPasswordDialogOpen$.asObservable().pipe(distinct());
  }

  constructor(private permissionService: PermissionService, private knownUserService: KnownUserService ) { }


  isUserKnownAndUnconfirmed(): boolean {
    return this.permissionService.hasDefined('known:access') &&
      ['known_not_set_confirmed', 'known_not_set_unconfirmed'].includes(this.knownUserService.knowUserType);
  }

  checkAndOpenPasswordDialog(): boolean {
    if (this.isUserKnownAndUnconfirmed() && !this.knownUserService.isKnownSkipPassword) {
      this.openPasswordDialog();
      return true;
    }

    return false;
  }

  openPasswordDialog(): void {
    this._isPasswordDialogOpen$.next(true);
  }

  closePasswordDialog(): void {
    this._isPasswordDialogOpen$.next(false);
  }
}
