/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export class VendorModel {

  public guid: string;
  public title: string;
  public logoSquare: string;
  public logoWide: string;

  constructor(data: any) {

    if (data.guid) {
      this.guid = data.guid;
    }
    if (data.title) {
      this.title = data.title;
    }
    if (data.logo_square) {
      this.logoSquare = data.logo_square;
    }
    if (data.logo_wide) {
      this.logoWide = data.logo_wide;
    }

  }

}
