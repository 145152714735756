<div [@opacityFadeInOut]
     *ngIf="tabsetData && tabsetData.length"
     class="{{ customClass }}-tabset tabset">
  <div *ngFor="let tab of tabsetData; let index = index"
       class="{{ customClass }}-tabset-container tabset-container"
       #tabsetContainer>
    <div (click)="onTabClick(tab)"
         [appAutoTabIndex]="groupTabIndex"
         class="{{ customClass }}-tabset-container-item tabset-container-item">
      <img *ngIf="tab.image"
           class="{{ customClass }}-tabset-container-item__image tabset-container-item__image"
           loading="lazy"
           [src]="tab.image | imageUrl: { w: 48, h: 48 }">
      <h4 [ngClass]="{'tabset-container-item__active-title': isActiveTab(index), 'tabset-container-item__title-with-image': tab.image}"
          class="{{ customClass }}-tabset-container-item__title tabset-container-item__title">{{ tab.title }}
      </h4>
    </div>
    <div [@widthInOut]
         *ngIf="isActiveTab(index)"
         class="tabset-container-active-item"></div>
  </div>
  
  <ng-content></ng-content>
</div>
