import { AnalyticsEvent, AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { getAnalyticsGALabelFromObject } from '@zones/services/analytics/helpers';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService } from '@shared/services/url.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

type ActionType = 'displayed' | 'click-all';

export interface ZoneHeader {
  guid: string;
  name: string;
  icon: string;
  url: string;
  urlText: string;
}

@Injectable({
  providedIn: 'root'
})
export class ZoneHeaderService {

  constructor(private router: Router, private urlService: UrlService) { }

  getZoneHeaderFromZoneSection(data: ZoneSectionElement): ZoneHeader {
    return {
      guid: data.guid,
      name: data.name,
      icon: data.icon_bw || '',
      url: data.view_all_url || '',
      urlText: data.view_all_anchor_text || ''
    };
  }

  getAnalyticsEvents(action: ActionType, zoneHeader: ZoneHeader): AnalyticsEvent[] {
    let data;
    const category = 'zone-element-header';
    switch (action) {
      case 'displayed':
        data = { guid: zoneHeader.guid, name: zoneHeader.name };
        return [
          new AnalyticsInternalEventModel(`${category}-displayed`, data)
          /** * REM per story #9788
      new AnalyticsGAEventModel(`${category}-displayed`, {
            category: category,
            label: getAnalyticsGALabelFromObject(data)
          })
           */
        ];
      case 'click-all':
        data = { guid: zoneHeader.guid, name: zoneHeader.name, url: zoneHeader.url };
        return [
          new AnalyticsInternalEventModel(`${category}-click-all`, data),
          new AnalyticsGAEventModel(`${category}-click-all`, {
            category,
            label: getAnalyticsGALabelFromObject(data)
          })
        ];
    }
    return [];
  }

  headerClick(url: string, isEbg: boolean): void {
    if (this.urlService.isExternalUrl(url)) {
      window.open(url, isEbg ? '_self' : '_blank');
    } else {
      void this.router.navigateByUrl(url);
    }
  }
}
