import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FILLER_MINI,
  INSURANCE_MINI,
  RENTAL_CAR_MINI,
  TAW_HOTELS_MINI,
  TICKETS_MINI,
  TRUECAR_MINI
} from '@app/widgets/constants/widget-ids.constant';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { Subscription } from 'rxjs';
import { WidgetsAdContent } from '@app/zones/interfaces/widgets.interface';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import { ZoneWidgetsService } from '@app/zones/services/zone-widgets.service';
import { OfferBadgeService } from '@shared/services/offer-badge.service';
import { OfferBadgeSettingsModel } from '@shared/models/offer-badge-settings.model';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventLocation,
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeWidgetImpresssionsEvent
} from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { Ad } from '@app/shared/models/ad.model';

@Component({
  selector: 'app-widgets-mini',
  templateUrl: './widgets-mini.component.html',
  styleUrls: ['./widgets-mini.component.less'],
  providers: [OfferBadgeService]
})

export class WidgetsMiniComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: ZoneSectionElement;
  @Input() groupTabIndex: number;
  @Input() wideContainerClass = false;
  @Input() sectionIndex: number;

  widgets: WidgetsAdContent[] = [];
  anchorLink = '';

  private subscriptions: Subscription[] = [];

  constructor(
    private zoneWidgetsService: ZoneWidgetsService,
    private homepageAdsService: HomepageAdsService,
    private zoneHeaderService: ZoneHeaderService,
    private offerBadgeService: OfferBadgeService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) {}

  get zoneHeaderName(): string {
    return this.data && this.data.name ? this.data.name : '';
  }

  ngOnInit(): void {
    const allowedWidgetIds = [
      TAW_HOTELS_MINI,
      TRUECAR_MINI,
      INSURANCE_MINI,
      RENTAL_CAR_MINI,
      FILLER_MINI,
      TICKETS_MINI
    ];
    this.widgets = this.zoneWidgetsService.getWidgetsAdContent(this.data, allowedWidgetIds);

    if (this.data && this.data.name) {
      const sectionName = `s_${this.data.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_widgets';
    }

    this.offerBadgeService.setBadgeSettings(new OfferBadgeSettingsModel({
      lightPillsCount: 1,
      loyaltyPillsOnly: true
    }));
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.data?.ads?.length) {
      const impressions = await Promise.all(this.data.ads?.map(
        async (ad: Ad, index) => this.adobeAnalyticsEventsService.getWidgetAdobeData(ad, `offer${index + 1}`, {
          size: 'mini',
          zone: this.data.name
        })
      ));
      const adobeEventImpressionsData: AdobeWidgetImpresssionsEvent = {
        event_name: AdobeEventNameEnum.WIDGET,
        event_type: AdobeEventTypeEnum.IMPRESSION,
        properties: { impressions }
      };
      this.adobeAnalyticsEventsService.sendWidgetImpressions(adobeEventImpressionsData, AdobeEventLocation.Homepage);
    }
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.data.is_ebg);
  }

  public trackById(index: number, item: WidgetsAdContent): string {
    return item.id;
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}
