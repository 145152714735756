import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ZipLocation {
  zip: string;
  country: string;
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeographyService {

  private defaultCountry = 'US';

  constructor(
    private http: HttpClient,
  ) { }

  async getLocationByZip(zip: string, country: string): Promise<ZipLocation> {

    if (!zip) {
      throw new Error('Please provide zip for searching');
    }

    const query = `?zip=${zip}&country=${country || this.defaultCountry}`;
    return this.getLocations(query);
  }

  async getLocationByLatLong(lat: number, lng: number): Promise<ZipLocation> {

    if (!lat || !lng) {
      throw new Error('Please provide lat and lng for searching');
    }

    const query = `?lat=${lat}&lng=${lng}`;
    return this.getLocations(query);
  }

  private async getLocations(query: string): Promise<ZipLocation> {
    return this.http.get(`${environment.apiUrl}/api/geography${query}`).toPromise() as Promise<ZipLocation>;
  }

}
