import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { RequestThrottleTime } from '@app/shared/enums/request-throttle-time.enum';
import { IRequestThrottleClick } from '@app/shared/interfaces/request-throttle-click.interface';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

const EMAIL_CONTROL_KEY = 'email';
const PASSCODE_CONTROL_KEY = 'passcode';

@Component({
  selector: 'app-email-confirmation-step',
  templateUrl: './email-confirmation-step.component.html',
  styleUrls: ['./email-confirmation-step.component.less']
})
export class EmailConfirmationStepComponent implements IRequestThrottleClick, OnDestroy, OnInit {

  @ViewChild('sendPasscodeButton', { static: true }) sendPasscodeButton: ElementRef;
  @ViewChild('form', { read: FormGroupDirective, static: true }) form: FormGroupDirective;

  emailEditable$: Observable<boolean>;

  emailSubscription: Subscription;
  passcodeSubscription: Subscription;
  emailEditableSubscription: Subscription;
  formEmailSubscription: Subscription;
  formPasscodeSubscription: Subscription;
  errorMessageSubscription: Subscription;
  codeTimerSubscription: Subscription;

  enableEmailSend = false;
  throttleTime = RequestThrottleTime.OTP;

  confirmationForm = new UntypedFormGroup({
    [EMAIL_CONTROL_KEY]: new UntypedFormControl('', [Validators.required, Validators.email]),
    [PASSCODE_CONTROL_KEY]: new UntypedFormControl('', [Validators.required,  Validators.pattern('[0-9]{6}')]),
  }, {
    updateOn: 'submit',
  });

  get email(): AbstractControl {
    return this.confirmationForm.get(EMAIL_CONTROL_KEY);
  }

  get passcode(): AbstractControl {
    return this.confirmationForm.get(PASSCODE_CONTROL_KEY);
  }

  passcodeErrorHidden = false;

  private destroyStream = new Subject<void>();

  constructor(private accountConfirmationService: AccountConfirmationService) {
    this.codeTimerSubscription = this.accountConfirmationService.codeTimer$.subscribe((timer) => {
      this.enableEmailSend = !timer;
    });

    this.emailEditable$ = this.accountConfirmationService.emailEditable$;

    this.emailEditableSubscription = this.emailEditable$.subscribe(value => {
      if (value) {
        this.confirmationForm.get(EMAIL_CONTROL_KEY).enable();
      } else {
        this.confirmationForm.get(EMAIL_CONTROL_KEY).disable();
      }
    });

    this.emailSubscription = accountConfirmationService.email$.subscribe((email) => {
      this.confirmationForm.controls[EMAIL_CONTROL_KEY].setValue(email);
    });

    this.passcodeSubscription = accountConfirmationService.passcode$.subscribe((value) => {
      this.confirmationForm.get(PASSCODE_CONTROL_KEY).setValue(value);
    });

    this.formEmailSubscription = this.confirmationForm.get(EMAIL_CONTROL_KEY).valueChanges.subscribe(value => {
      this.accountConfirmationService.setEmail(value);
    });

    this.formPasscodeSubscription = this.confirmationForm.get(PASSCODE_CONTROL_KEY).valueChanges.subscribe(value => {
      this.accountConfirmationService.setPasscode(value);
    });

    this.errorMessageSubscription = accountConfirmationService.errorMessage$.subscribe(value => {
      this.confirmationForm.get(PASSCODE_CONTROL_KEY).setErrors({invalid: value});
    });
  }

  onChangeEmailClicked(event: Event): void {
    event.stopPropagation();
    this.accountConfirmationService.navigateToChangeEmailStep();
  }

  onSendPassCodeClick(event: Event): void {
    event.stopPropagation();
    this.form.onSubmit(null);
    this.passcodeErrorHidden = true;
    if (!this.enableEmailSend) {
      return;
    }
    if (!this.confirmationForm.get(EMAIL_CONTROL_KEY).invalid) {
      void this.accountConfirmationService.sendPascode(true);
    }
  }

  public onConfirmClick(event: Event): void {
    if (!event) {
      return;
    }

    this.passcodeErrorHidden = false;
    event.stopPropagation();
    this.confirmationForm.updateValueAndValidity();
    if (this.confirmationForm.valid) {
      void this.accountConfirmationService.confirmEmail();
    }
  }

  registerClickEvent(): void {
    fromEvent(this.sendPasscodeButton.nativeElement, 'click').pipe(
      throttleTime(RequestThrottleTime.OTP),
      takeUntil(this.destroyStream)
    ).subscribe((event: Event) => this.onSendPassCodeClick(event));
  }

  ngOnInit(): void {
    this.registerClickEvent();
  }

  ngOnDestroy(): void {
    this.emailSubscription.unsubscribe();
    this.passcodeSubscription.unsubscribe();
    this.emailEditableSubscription.unsubscribe();
    this.formEmailSubscription.unsubscribe();
    this.formPasscodeSubscription.unsubscribe();
    this.errorMessageSubscription.unsubscribe();
    this.codeTimerSubscription.unsubscribe();
    this.destroyStream.next();
    this.destroyStream.complete();
  }
}
