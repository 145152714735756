export enum OfferBadgeType {
  None = '',
  Expired = 'grayBackground',
  New = 'iconFire',
  TimeLimited = 'iconClock',
  Exclusive = 'exclusive',
  Payroll = 'payroll',
  Loyalty = 'loyalty',
  TodayDeal = 'todayDeal',
  ExtraPadding = 'extraPadding',
  Tag = 'iconTagText',
  Flash = 'iconFlash',
  Ads = 'ads',
  Enrolled = 'enrolled',
  Client = 'client',
  Category = 'category',
}
