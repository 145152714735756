<div class="search-bar"
     [class.active]="details"
     (appClickOutside)="hideDetails()"
     role="search">
  <form>
    <input [appAutoTabIndex]="groupTabIndex"
           #searchInput
           class="touch-friendly search-input"
           type="text"
           aria-label="Search offers menu pop-up combo box.
        You are currently on a menu pop-up
        combo box. Type text or use tabulation
        key to access and browse suggestions.
        Confirm your choice with enter key, or
        escape key to close suggestions box."
           autocomplete="off"
           [formControl]="searchField"
           placeholder="{{ placeholder }}"
           (focus)="onInputFocus()"
           (focusout)="onComponentFocusout()"
           (keyup.enter)="search($event, '', ''); searchInput.blur();"
           (keyup.esc)="hideDetailsAndClear()"
           [appFocusControl]="'skipToSearch'" />

    <a class="btn-search"
       role="button"
       aria-label="search"
       (click)="search($event, '', '');"
       [appAutoTabIndex]="groupTabIndex">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20">
        <path fill="currentColor"
              fill-rule="nonzero"
              d="M14.5 12h-.79l-.28-.27A6.471 6.471 0 0 0 15 7.5 6.5 6.5 0 1 0 8.5 14c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L19.49 17l-4.99-5zm-6 0C6.01 12 4 9.99 4 7.5S6.01 3 8.5 3 13 5.01 13 7.5 10.99 12 8.5 12z" />
      </svg>
    </a>
    <app-spinner size="20"
                 *ngIf="loading"
                 role="presentation"></app-spinner>
  </form>

  <ng-container *ngIf="results.recent && results.recent.length && results.recent.length > 0">
    <div class="divider"
         *ngIf="details"></div>

    <div class="details"
         (click)="hideDetails()"
         role="region"
         aria-label="search popup">
      <div class="details-items">

        <div class="search-bar-block search-bar-searches"
             *ngIf="results.recent.length">
          <div class="recent-container">
            <h5 class="title"
                [id]="idRegistryService.get('recent-searches-label')">Recent</h5>
            <button (click)="clearAll()"
                    class="btn btn-text btn-underline">
              <span>Clear All</span>
            </button>
          </div>

          <ul class="results"
              [attr.aria-labelledby]="idRegistryService.get('recent-searches-label')"
              role="list">
            <li *ngFor="let item of results.recent"
                [appAutoTabIndex]="groupTabIndex"
                (keyup.enter)="search($event, item.q, item?.title)"
                role="listitem">
              <app-search-bar-item (click)="search($event, item.q, item?.title)"
                                   iconClass="ico-find"
                                   role="presentation">
                <div (click)="trackRecentAnalytics(item.title, item?.q)"
                     class="recent-item-container">
                  <span class="searches-item"
                        role="link">{{ item?.title }}</span>
                  <svg (click)="clearItem(item.q, item?.title, $event)"
                       width="25"
                       height="25"
                       viewBox="0 0 11 10"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4"
                          d="M10.1296 1.00714L9.12249 0L5.12964 3.99286L1.13678 0L0.129639 1.00714L4.1225 5L0.129639 8.99286L1.13678 10L5.12964 6.00714L9.12249 10L10.1296 8.99286L6.13678 5L10.1296 1.00714Z"
                          fill="#353535" />
                  </svg>
                </div>
              </app-search-bar-item>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>

</div>