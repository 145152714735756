import { Injectable } from '@angular/core';
import { Badge } from '@shared/interfaces/badge.interface';
import { SearchResultBadge } from '@shared/models/search-results-item.model';
import { OfferBadgeType } from '@shared/enums/offer-badge-type.enum';
import { ConfigService } from './config.service';

type BadgesUnionType = Badge[] | SearchResultBadge[];

@Injectable({
  providedIn: 'root'
})
export class PillsService {

  constructor(
    private readonly configService: ConfigService
  ) {
  }

  getPills(pills: BadgesUnionType, searchPageList = false): BadgesUnionType {
    const filteredPills = pills.filter(i => i);

    if (filteredPills && filteredPills.length === 0) {
      return filteredPills;
    }

    const hasLoyaltyBadge = !!filteredPills.find(item => item && item.type.toLowerCase() === OfferBadgeType.Loyalty);
    const displayLoyaltyBadge = hasLoyaltyBadge && this.configService.getOption(OfferBadgeType.Loyalty);

    if (displayLoyaltyBadge && !searchPageList) {
      return filteredPills.filter(item => item && item.type !== OfferBadgeType.Exclusive && item.type !== OfferBadgeType.Payroll);
    } else if (hasLoyaltyBadge && !searchPageList) {
      return filteredPills.filter(item => item && item.type !== OfferBadgeType.Loyalty);
    }

    return filteredPills;
  }

}
