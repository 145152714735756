/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export class OfferBadgeSettingsModel {

  public darkPillsCount: number;
  public lightPillsCount: number;
  public loyaltyPillsOnly: boolean;
  public totalPillsCount: number;
  public sortingReversed: boolean;
  public reverseSortOnSmallBreakpoint: boolean;

  constructor(data: any = {}) {
    this.darkPillsCount = data.darkPillsCount !== undefined ? data.darkPillsCount : null;
    this.lightPillsCount = data.lightPillsCount !== undefined ? data.lightPillsCount : null;
    this.loyaltyPillsOnly = data.loyaltyPillsOnly !== undefined ? data.loyaltyPillsOnly : false;
    this.totalPillsCount = data.totalPillsCount !== undefined ? data.totalPillsCount : null;
    this.sortingReversed = data.sortingReversed !== undefined ? data.sortingReversed : false;
    this.reverseSortOnSmallBreakpoint = data.reverseSortOnSmallBreakpoint !== undefined ? data.reverseSortOnSmallBreakpoint : false;
  }

}
