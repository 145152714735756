import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';

@Injectable()
export class AdobeCategoriespageDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService,
  ) { }

  async setCategoriesPageData(): Promise<void> {
    await this.adobeDataCollectorService.setPageData({
      name: 'offer category: all categories',
      section: 'offer category',
      type: 'g3 categories',
      hierarchy: this.adobeDataCollectorService.setHierarchy('Categories Page'),
    });
  }
}
