/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface SuggestedItem {
  query: string;
  title: string;
  intercepted: boolean;
}

export interface SuggestedResponse {
  recent: SuggestedItem[];
}

@Injectable()
export class WidgetSuggestionService {

  constructor(
    private http: HttpClient,
  ) { }

  async addSuggestedWidgets(item: any): Promise<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/search/suggested-widgets`, item).toPromise();
  }

}
