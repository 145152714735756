<section class="cards-scroll container main-container wide-container category-item-container"
         [attr.id]="anchorLink"
         [class.merge-to-cta]="isCtaMergedToCards"
         [class.no-header]="!data.name"
         [class.pinned-zone]="isPinnedZone"
         [class.wide-endpoint]="isWideContainer()"
         *ngIf="!!data && data.ads && isEnoughAds"
         (mouseenter)="hovered = true"
         (mouseleave)="hovered = false">
  <app-zone-header *ngIf="!!data.name"
                   [data]="data"
                   [groupTabIndex]="groupTabIndex"
                   (showAllClick)="showAllClickHandler()"></app-zone-header>
  <div class="cards-scroll_body">
    <app-cards-horizontal-scroll [isDesktop]="isDesktop"
                                 [hideGhostEffect]="hideGhostEffect"
                                 [hideArrows]="hideArrows"
                                 [hovered]="hovered"
                                 [isProductCards]="isProductCards"
                                 (scrollEvent)="onScrollEvent($event)">
      <app-open-enrollment-ad class="open-enrollment-ad col-12 col-md-3 sel-ad-item tier-1"
                              [sectionName]="getEnrollmentInfo().section_name"
                              [startDate]="getEnrollmentInfo().start_date"
                              [endDate]="getEnrollmentInfo().end_date"
                              [moreInfo]="getEnrollmentInfo().more_info"
                              [class.wide-endpoint]="isWideContainer()"
                              [groupTabIndex]="1000"
                              role="presentation"
                              *ngIf="isEnrollment">
      </app-open-enrollment-ad>
      <ng-container *ngFor="let ad of ads; let i = index; trackBy: addItemTrackBy">
        <app-ad-item *ngIf="isProductCard(ad)"
                     [groupTabIndex]="(i + 1) % 2 === 0 && i === 0 ? groupTabIndex + 10 : groupTabIndex"
                     class="sel-ad-item"
                     parentType="cards_scroll"
                     [zoneName]="this.data.name"
                     [ad]="ad"
                     [class.tier-1]="isDoubleWideCard(i)"
                     [isTier1]="isDoubleWideCard(i)"
                     (displayed)="onDisplayedAd($event)"
                     (clicked)="handleAdClick(ad, i)"
                     (clickedCta)="onClickedAdCta($event, i)"></app-ad-item>
      </ng-container>
    </app-cards-horizontal-scroll>
  </div>
</section>
