import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

export interface Option {
  value: string;
  text: string;
}

export interface InputSelectedValue {
  name: string;
  value: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less'],
})
export class SelectComponent implements OnInit {

  @Input() options: Option[] = [];
  @Input() selectValue: string = null;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() iconUrl: string;
  @Input() className: string;
  @Input() isDisabled = false;
  @Input() groupTabIndex: number;
  @Input() showArrow = true;

  @Output() selectValueChange = new EventEmitter<InputSelectedValue>();
  @Output() mousedown = new EventEmitter();
  @Output() keydown = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() mouseleave = new EventEmitter();
  @Output() change = new EventEmitter();

  @ViewChild('select') select;

  activeSelect = false;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void { }

  selectClick(): void {
    this.activeSelect = true;
    this.changeSvgState(true);
  }

  get isValueNull(): boolean {
    return !this.selectValue && this.selectValue !== 'null'; // it can have value = 'null'
  }

  onChange(): void {
    this.selectValueChange.emit({
      name: this.name,
      value: this.selectValue,
    });
    this.change.emit();
  }

  changeSvgState(addActive = false): void {
    const svg = document.querySelector(`#${this.name}-arrow`);

    if (!svg) {
      return;
    }

    if (addActive && !svg.classList.contains('active')) {
      svg.classList.add('active');
      svg.querySelector('path').setAttribute('opacity', '1');
    } else {
      svg.classList.remove('active');
      svg.querySelector('path').setAttribute('opacity', '.5');

      // Needed to remove focus on select after selecting the same value
      this.select.nativeElement.blur();
    }
  }

  trackOptionByValue(option: Option): string {
    return option.value;
  }
}
