<div class="modal-header">
  <div class="modal-expired-icon">
    <img alt="Expired icon"
         role="presentation"
         aria-hidden="true"
         loading="lazy"
         src="/assets/icons/ic-ghost.svg">
  </div>
  <div class="title">Offer Unavailable</div>

  <button type="button"
          role="button"
          class="close"
          aria-label="Close"
          ngbAutofocus
          (click)="closeModal()">
    <span aria-hidden="true"
          class="close-icon">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="info-text">It's likely that another similar offer is available. Please browse this page and use search
    features to find offers available now.</div>
</div>

<div class="modal-footer">
  <button type="button"
          role="button"
          class="btn btn-primary"
          (click)="closeModal()">Ok, great</button>
</div>
