<div class="carousel-nav {{ carouselClass }}"
     *ngIf="showNav">
  <div class="indicator-container"
       *ngIf="enableIndicator">
    <div class="indicator"
         *ngFor="let i of arrayOf(indicatorSize)"
         [ngClass]="{'selected':( i+1 === indicatorPosition)}"></div>
  </div>
  <button class="btn btn-square prev"
          (click)="prev(true)"
          [style.top]="checkWidth() ? buttonOffsetTop + 'px':'auto'"
          [disabled]="transitionState">
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 9 9">
      <path class="theme-fill-primary"
            fill-rule="nonzero"
            d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z" />
    </svg>
  </button>
  <button class="btn btn-square next"
          (click)="next(true)"
          [style.top]="checkWidth() ? buttonOffsetTop + 'px':'auto'"
          [disabled]="transitionState">
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 9 9">
      <path class="theme-fill-primary"
            fill-rule="nonzero"
            d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z" />
    </svg>
  </button>


  <div class="wrap-container {{ carouselClass }}"
       [class.overflow-visible]="overflowVisible"
       [style.minHeight]="wrapperHeight + 'px'">
    <div #carouselContainer
         class="carousel-items"
         (swipeleft)="next(true, true)"
         (swiperight)="prev(true, true)"
         (window:resize)="getCarouselDimensions($event)"
         [class.transition]="transitionState"
         [style.left]="containerOffsetLeft + 'px'"
         [style.transform]="'translateX(' + containerTranslateX + 'px)'">
      <ng-content></ng-content>
    </div>
  </div>
</div>