import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { ModalService } from '@shared/services/modal.service';

interface SessionDisclaimer {
  marketplace: string;
  content: string;
}

const SESSION_DISCLAIMER_DISMISS_KEY = 'session-disclaimer-shown-time';

@Component({
  selector: 'app-homepage-disclaimer',
  templateUrl: './homepage-disclaimer.component.html',
  styleUrls: ['./homepage-disclaimer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageDisclaimerComponent implements AfterViewInit {

  @Input() disclaimer: SessionDisclaimer;
  @ViewChild('disclaimerContent', { static: true }) modalContent: ElementRef<HTMLElement>;
  @ViewChild('disclaimerBottom', { static: true }) modalBottom: ElementRef<HTMLElement>;


  constructor(
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) { }

  ngAfterViewInit(): void {
    this.setScrollableClass();
  }

  getMarketplaceName(): string {
    return this.disclaimer && this.disclaimer.marketplace || '';
  }

  getDisclaimerContent(): string {
    return this.disclaimer && this.disclaimer.content || '';
  }

  closeModal(): void {
    this.modalService.closeModal();
    this.setDisclaimerShownTime();
  }

  private setDisclaimerShownTime(): void {
    this.localStorageService.setItem<number>(SESSION_DISCLAIMER_DISMISS_KEY, Date.now());
  }

  private setScrollableClass(): void {
    const { scrollHeight, clientHeight } = this.modalContent.nativeElement;

    if (scrollHeight > clientHeight) {
      this.modalBottom.nativeElement.classList.add('scrollable');
    } else {
      this.modalBottom.nativeElement.classList.remove('scrollable');
    }
  }

}
