import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KnownUserType } from 'g3-common-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { COUNTRY_LAST_SESSION_KEY } from '@shared/constants/profile.constants';
import { environment } from '@environments/environment';

export const knownUserTypes = [
  'known_set_confirmed',
  'known_not_set_confirmed',
  'known_set_unconfirmed',
  'known_not_set_unconfirmed',
  'known_child'
];
const KNOWN_USER_TYPE_KEY = 'known_user_type';
const KNOWN_USER_GUID_KEY = 'known_user_guid';
const KNOWN_USER_CHILD_GUID_KEY = 'known_child_user_guid';
const KNOWN_USER_CHILD_DLK_KEY = 'known_child_dlk';
const KNOWN_USER_EMAIL_KEY = 'known_user_email';
const KNOWN_SKIP_PASSWORD = 'known_skip_password';
const KNOWN_REMEMBER_ME = 'known_remember_me';
const KNOWN_SKIP_CONFIRM = 'known_skip_confirm';
const KNOWN_USER_EMAIL_CONTACT_KEY = 'known_user_email_contact';

export const KNOWN_QUERY_PARAMS = {
  KNOWN_USER_TYPE_KEY,
  KNOWN_USER_GUID_KEY,
  KNOWN_USER_CHILD_GUID_KEY,
  KNOWN_USER_CHILD_DLK_KEY,
  KNOWN_USER_EMAIL_KEY,
  KNOWN_SKIP_PASSWORD,
  KNOWN_SKIP_CONFIRM,
  KNOWN_USER_EMAIL_CONTACT_KEY
} as const;

@Injectable()
export class KnownUserService {
  public knownSkipPassword = false;
  public knownSkipConfirm = false;
  public knownRememberMe = true;

  private knownUserType: KnownUserType;
  private knownUserGuid: string;
  private knownUserChildGuid: string;
  private knownUserChildDlk: string;
  private knownUserEmail: string;
  private knownUserEmailContact: string;

  private knownUserType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public constructor(
    private readonly http: HttpClient
  ) {
    this.setKnownUserType(localStorage.getItem(KNOWN_USER_TYPE_KEY) as KnownUserType);
    this.setKnownUserGuid(localStorage.getItem(KNOWN_USER_GUID_KEY));
    this.setKnownUserEmail(localStorage.getItem(KNOWN_USER_EMAIL_KEY));
    this.setKnownSkipPassword((localStorage.getItem(KNOWN_SKIP_PASSWORD) === 'true'));
    this.setKnownSkipConfirm((localStorage.getItem(KNOWN_SKIP_CONFIRM) === 'true'));
    this.setKnownRememberMe((localStorage.getItem(KNOWN_REMEMBER_ME) === 'false' ? false : true));
    this.setKnownUserEmailContact(localStorage.getItem(KNOWN_USER_EMAIL_CONTACT_KEY));
    this.setKnownChildGuid(localStorage.getItem(KNOWN_USER_CHILD_GUID_KEY));
    this.setKnownChildDlk(localStorage.getItem(KNOWN_USER_CHILD_DLK_KEY));
  }

  public clearAll(): void {
    localStorage.removeItem(KNOWN_USER_TYPE_KEY);
    localStorage.removeItem(KNOWN_USER_GUID_KEY);
    localStorage.removeItem(KNOWN_USER_EMAIL_KEY);
    localStorage.removeItem(KNOWN_USER_EMAIL_CONTACT_KEY);
    localStorage.removeItem(KNOWN_USER_CHILD_GUID_KEY);
    localStorage.removeItem(KNOWN_USER_CHILD_DLK_KEY);

    this.knownUserGuid = '';
    this.knownUserChildGuid = '';
    this.knownUserEmail = '';
    this.knownUserType = null;
  }

  public clearSkipPassword(): void {
    this.knownSkipPassword = false;
    localStorage.removeItem(KNOWN_SKIP_PASSWORD);
  }

  public clearRememberMe(): void {
    this.knownRememberMe = true;
    localStorage.removeItem(KNOWN_REMEMBER_ME);
  }

  public clearSkipConfirme(): void {
    this.knownSkipConfirm = false;
    localStorage.removeItem(KNOWN_SKIP_CONFIRM);
  }

  // Make sure to use this method to set known user type to local storage
  public setKnownUserType(type: KnownUserType): void {
    if (!knownUserTypes.includes(type)) {
      return;
    }

    localStorage.setItem(KNOWN_USER_TYPE_KEY, type);
    this.knownUserType = type;
    this.knownUserType$.next(type);
  }

  public setKnownUserGuid(guid: string): void {
    if (!guid) {
      return;
    }

    localStorage.setItem(KNOWN_USER_GUID_KEY, guid);
    this.setKnownUserCookie(guid);
    this.knownUserGuid = guid;
  }

  public setKnownUserEmail(email: string = ''): void {
    if (!email) {
      return;
    }
    const lowerCaseEmail = email.toLowerCase();
    localStorage.setItem(KNOWN_USER_EMAIL_KEY, lowerCaseEmail);
    this.knownUserEmail = lowerCaseEmail;
  }

  public setKnownUserEmailContact(emailContact: string = ''): void {
    if (!emailContact) {
      return;
    }
    const lowerCaseEmail = emailContact.toLowerCase();
    localStorage.setItem(KNOWN_USER_EMAIL_CONTACT_KEY, lowerCaseEmail);
    this.knownUserEmailContact = lowerCaseEmail;
  }

  public setKnownChildGuid(guid: string): void {
    if (!guid) {
      return;
    }

    localStorage.setItem(KNOWN_USER_CHILD_GUID_KEY, guid);
    this.knownUserChildGuid = guid;
  }

  public setKnownChildDlk(dlk: string): void {
    if (!dlk) {
      return;
    }

    localStorage.setItem(KNOWN_USER_CHILD_DLK_KEY, dlk);
    this.knownUserChildDlk = dlk;
  }

  public isKnownUser(): boolean {
    return Boolean(this.knownUserType);
  }

  public setKnownSkipPassword(value: boolean): void {
    this.knownSkipPassword = value;
    localStorage.setItem(KNOWN_SKIP_PASSWORD, value.toString());
  }

  public setKnownRememberMe(value: boolean): void {
    this.knownRememberMe = value;
    localStorage.setItem(KNOWN_REMEMBER_ME, value.toString());
  }

  public setKnownSkipConfirm(value: boolean): void {
    this.knownSkipConfirm = value;
    localStorage.setItem(KNOWN_SKIP_CONFIRM, value.toString());
  }

  public getIsConfirmInfoCheck(type: KnownUserType): boolean {
    return type.includes('unconfirmed') && !type.includes('not_set') || this.isKnownSkipPassword;
  }

  public getIsNotSetUnconfirmed(): boolean {
    return this.knownUserType?.includes('not_set') && !this.knownUserType?.includes('unconfirmed');
  }

  public async getBlockedDLKValue(dlkValue: string): Promise<boolean> {
    const guid = dlkValue.split('~')[0];
    const response = await this.http.get<{ is_blocked_dlk: boolean }>(`${environment.apiUrl}/api/known/dlk-compare?guid=${guid}`).toPromise();
    return response.is_blocked_dlk;
  }

  public setAllKnownData(allData: { email: string; contact: string; type: KnownUserType; guid: string; childGuid: string; childDLK: string }): void {
    const { email = '', contact = '', type = null, guid = '', childGuid = '', childDLK = '' } = allData;

    if (email) {
      this.setKnownUserEmail(email);
    }
    if (contact) {
      this.setKnownUserEmailContact(contact);
    }
    if (type) {
      this.setKnownUserType(type);
    }
    if (guid) {
      this.setKnownUserGuid(guid);
    }
    if (childGuid) {
      this.setKnownChildGuid(childGuid);
    }
    if (childDLK) {
      this.setKnownChildDlk(childDLK);
    }

  }

  public get isKnownSkipPassword(): boolean {
    return this.knownSkipPassword;
  }

  public get isKnownSkipConfirm(): boolean {
    return this.knownSkipConfirm;
  }

  public get knowUserType(): KnownUserType {
    if (this.knownUserType) {
      return this.knownUserType;
    }
  }

  public get knowUserGuid(): string {
    if (this.knownUserGuid) {
      return this.knownUserGuid;
    }
  }

  public get knowUserChildGuid(): string {
    if (this.knownUserChildGuid) {
      return this.knownUserChildGuid;
    }
  }

  public get knowUserChildDlk(): string {
    if (this.knownUserChildDlk) {
      return this.knownUserChildDlk;
    }
  }

  public get knowUserEmail(): string {
    if (this.knownUserEmail) {
      return this.knownUserEmail;
    }
  }

  public get knowUserEmailContact(): string {
    if (this.knownUserEmailContact) {
      return this.knownUserEmailContact;
    }
  }

  public get knowUserTypeFromStorage(): KnownUserType {
    return localStorage.getItem((KNOWN_USER_TYPE_KEY)) as KnownUserType;
  }

  public get _knowUserType$(): Observable<string> {
    return this.knownUserType$.asObservable();
  }

  public get knownUserCountry(): string {
    return localStorage.getItem(COUNTRY_LAST_SESSION_KEY);
  }

  public get knownUserQueryParams(): string {
    const query = [];

    if (this.knowUserType) {
      if (this.knowUserGuid) {
        query.push(`userGuid=${this.knowUserGuid}`);
      }

      if (this.knowUserEmail) {
        query.push(`knownEmail=${encodeURIComponent(this.knowUserEmail)}`);
      }

      if (this.knownUserEmailContact) {
        query.push(`knownEmailContact=${encodeURIComponent(this.knowUserEmailContact)}`);
      }

      if (this.knownUserChildGuid) {
        query.push(`knownUserChildGuid=${encodeURIComponent(this.knownUserChildGuid)}`);
      }
    }

    return query.join('&');
  }

  private setKnownUserCookie(knownUserGuid: string): void {
    const fullCookie: Array<string> = document.cookie.split(';');
    const fullCookieLength: number = fullCookie.length;
    const cookieName = `${KNOWN_USER_GUID_KEY}=`;
    let cookieSegment: string;
    let foundCookieValue = '';

    for (let i = 0; i < fullCookieLength; i += 1) {
      cookieSegment = fullCookie[i].replace(/^\s+/g, '');
      if (cookieSegment.indexOf(cookieName) === 0) {
        foundCookieValue = cookieSegment.substring(cookieName.length, cookieSegment.length);
        cookieSegment = cookieName + knownUserGuid;
        fullCookie[i] = cookieSegment;
        document.cookie = fullCookie.join(';');
        break;
      }
    }
  }
}
