<div [attr.id]="anchorLink"
     class="cta-container"
     *ngIf="ctaBlock">
  <div class="cta-decision cta-decision__small"
       [appAutoTabIndex]="groupTabIndex + 3"
       (click)="handleCtaClick()">

    <div class="cta-decision__background">
      <div class="background-image"
           [class.background-image-mask]="ctaElementService.isImageSvg(backgroundUrl) && !isMSOrFirefoxBrowser"
           [ngStyle]="backgroundMaskStyles">
      </div>
    </div>

    <div class="cta-decision__text-container">

      <h3 class="cta-decision__header">{{ ctaBlock.header }}</h3>
      <p class="cta-decision__text">{{ ctaBlock.body }}</p>
      <button class="btn btn-primary"
              *ngIf="ctaButtonText"
              [appAutoTabIndex]="groupTabIndex + 4">
        {{ ctaButtonText }}
      </button>

    </div>
    <div class="foreground-image"
         [class.foreground-image-mask]="ctaElementService.isImageSvg(ctaBlock?.image_url) && !isMSOrFirefoxBrowser"
         [ngStyle]="foregroundMaskStyles"></div>
  </div>

  <div class="cta-decision cta-decision__large"
       [appAutoTabIndex]="groupTabIndex + 3"
       (click)="handleCtaClick()"
       [class.wide-endpoint]="!!wideContainerClass">
    <div class="background-image"
         [class.background-image-mask]="ctaElementService.isImageSvg(backgroundUrl) && !isMSOrFirefoxBrowser"
         [ngStyle]="backgroundMaskStyles"></div>

    <div class="cta-decision__text-container">

      <h3 class="cta-decision__header">{{ctaBlock.header}}</h3>
      <p class="cta-decision__text" [innerHTML]="(ctaBlock.body || '') | safe"></p>
      <button class="btn btn-primary"
              *ngIf="ctaButtonText"
              [appAutoTabIndex]="groupTabIndex + 4">
        {{ ctaButtonText }}
      </button>

    </div>

    <div *ngIf="ctaBlock?.image_url"
         class="foreground-image"
         [class.foreground-image-mask]="ctaElementService.isImageSvg(ctaBlock?.image_url) && !ctaElementService.isMSOrFirefoxBrowser()"
         [ngStyle]="foregroundMaskStyles">
    </div>

  </div>

</div>
