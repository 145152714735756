import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { G3CommonUiModule } from 'g3-common-ui';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

// Services
import { SubscriptionCenterAnalyticsService } from './services/analytics/subscription-center-analytics.service';

// Components
import { SubscriptionCardComponent } from './components/subscription-card/subscription-card.component';
import { SubscriptionCenterService } from './services/subscription-center.service';
import { SubscriptionEmailComponent } from './components/subscription-email/subscription-email.component';
import { SubscriptionPageComponent } from './components/subscription-page/subscription-page.component';
import { SubscriptionPreviewModalComponent } from './components/subscription-preview-modal/subscription-preview-modal.component';
import { SubscriptionConfirmationModalComponent } from './components/subscription-confirmation-modal/subscription-confirmation-modal.component';
import { ExclusionsComponent } from './components/exclusions/exclusions.component';
import { SubscriptionSubNamesComponent } from './components/subscription-sub-names/subscription-sub-names.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    G3CommonUiModule,
    SharedModule
  ],
  declarations: [
    SubscriptionPageComponent,
    SubscriptionCardComponent,
    SubscriptionEmailComponent,
    ExclusionsComponent,
    SubscriptionPreviewModalComponent,
    SubscriptionConfirmationModalComponent,
    SubscriptionSubNamesComponent
  ],
  exports: [
    SubscriptionPageComponent,
    SubscriptionEmailComponent
  ],
  providers: [
    SubscriptionCenterService,
    SubscriptionCenterAnalyticsService
  ]
})
export class SubscriptionCenterModule { }
