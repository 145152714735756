/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CtaElementService, CtaOptions } from '@app/zones/services/cta-element.service';
import { WINDOW, ImageUrlPipe } from 'g3-common-ui';

@Component({
  selector: 'app-cta-block',
  templateUrl: './cta-block.component.html',
  styleUrls: ['./cta-block.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CtaBlockComponent implements OnInit {

  @Input() public ctaBlock: CtaOptions;
  @Input() public groupTabIndex: number;
  @Input() public wideContainerClass = '';
  @Input() public anchorLink = '';

  @Output() public handleCta = new EventEmitter<void>();

  private readonly BACKGROUND_SIZES = {
    XS: 767,
    S: 1023,
    M: 1279,
    L: 1471
  };

  public backgroundMaskStyles: any;
  public isMSOrFirefoxBrowser = false;

  public get ctaButtonText(): string {
    return this.ctaBlock?.button_txt || (this.ctaBlock?.button_url && 'GET OFFER');
  }

  constructor(
    private ctaElementService: CtaElementService,
    private imageUrlPipe: ImageUrlPipe,
    @Inject(WINDOW) private readonly window: WINDOW
  ) { }

  public ngOnInit(): void {
    this.isMSOrFirefoxBrowser = this.ctaElementService.isMSOrFirefoxBrowser();
    this.updateBackgroundMaskStyles();
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.updateBackgroundMaskStyles();
  }

  public updateBackgroundMaskStyles(): any {
    let bgUrl = '';
    if (this.ctaBlock) {
      if (this.window.innerWidth <= this.BACKGROUND_SIZES.XS) {
        bgUrl = this.ctaBlock.background_url_xs;
      }
      if (!bgUrl && this.window.innerWidth <= this.BACKGROUND_SIZES.S) {
        bgUrl = this.ctaBlock.background_url_s;
      }
      if (!bgUrl && this.window.innerWidth <= this.BACKGROUND_SIZES.M) {
        bgUrl = this.ctaBlock.background_url_m;
      }
      if (!bgUrl && this.window.innerWidth <= this.BACKGROUND_SIZES.L) {
        bgUrl = this.ctaBlock.background_url_l;
      }
      if (!bgUrl) {
        bgUrl = this.ctaBlock.background_url_xl;
      }
    }

    if (!bgUrl) {
      return;
    }

    const imgixUrl = this.imageUrlPipe.transform(bgUrl, {}, false);

    this.backgroundMaskStyles = !this.ctaElementService.isMSOrFirefoxBrowser() && this.ctaElementService.isImageSvg(bgUrl)
      ? this.ctaElementService.imageMask(imgixUrl, 'cover')
      : this.ctaElementService.backgroundMask(imgixUrl, 'cover');
  }

  public get foregroundMaskStyles(): any {
    const imageUrl = this.ctaBlock && this.ctaBlock.image_url ? this.ctaBlock.image_url : '';
    return !this.ctaElementService.isMSOrFirefoxBrowser() && this.ctaElementService.isImageSvg(imageUrl || '')
      ? this.ctaElementService.imageMask(imageUrl, 'contain')
      : this.ctaElementService.backgroundMask(imageUrl, 'contain');
  }

  public handleCtaClick(): void {
    this.handleCta.emit();
  }

}
