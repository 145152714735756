import { Component, Input, OnInit } from '@angular/core';
import { PlatformCountry } from 'g3-common-ui';
import { ModalService } from '@app/shared/services/modal.service';

@Component({
  selector: 'app-modal-offer-invalid-location',
  templateUrl: './modal-offer-invalid-location.component.html',
  styleUrls: ['./modal-offer-invalid-location.component.less']
})
export class ModalOfferInvalidLocationComponent implements OnInit {
  @Input() availableCountries: PlatformCountry[] = [];
  @Input() onSwitchLocation: (c: PlatformCountry) => void;

  modalBodyText = '';
  countriesOptions: { title: string; value: string }[] = [];
  selectedCountryCode = '';
  errored = false;

  constructor(
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.modalBodyText = this.getModalBodyText();
    this.countriesOptions = this.availableCountries.map(c => ({
      value: c.country_code,
      title: c.country_name_en
    }));
  }

  switchCountry(): void {
    if (this.availableCountries.length > 1 && !this.selectedCountryCode) {
      this.errored = true;
      return;
    }
    const country = this.selectedCountryCode ?
      this.availableCountries.find(c => c.country_code === this.selectedCountryCode) :
      this.availableCountries[0];
    this.onSwitchLocation(country);
    this.modalService.closeModal('close');
  }

  dismiss(): void {
    this.modalService.closeModal('dismiss');
  }

  selectOption(code: string): void {
    this.selectedCountryCode = this.countriesOptions.find(c => c.value === code)?.value;
    this.errored = false;
  }

  private getModalBodyText(): string {
    if (this.availableCountries.length === 1) {
      return `This offer is only available in ${this.availableCountries[0].country_name_en}.`;
    }
    return 'This offer is unavailable in your current location. Please select an alternate location.';
  }

}
