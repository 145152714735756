/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { SearchResultsAdsModel } from '../../models/search-results-ads.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Breadcrumb, BreadcrumbsService } from '../breadcrumbs.service';
import { Router } from '@angular/router';
import { CouponModalComponent } from '../../components/coupon-modal/coupon-modal.component';
import { ClickOutService } from '@shared/services/click-out.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { AdsAnalyticsService } from '@shared/services/analytics/ads-analytics.service';
import { PhoneCtaService } from '@shared/services/phoneCta.service';
import { AdsOffersService } from '../ads/ads-offers.service';
import { SOURCE_ID } from '@app/shared/constants/source-id.constants';
import { SingleUseCodeService } from '@app/shared/components/coupon-modal/single-use-code.service';
import { AnalyticsEvent } from '@app/shared/models/analytics.event.model';
import { AdobeClickEvent, AdobeEventTypeEnum } from '@shared/services/adobe/adobe-analytics.interfaces';
import { AdobeAnalyticsEventsService } from '@shared/services/adobe/units/adobe-analytics-events.service';

const adClickEvents = {
  CARD: 'card',
  TAKEOVER: 'takeover',
  URL_NOT_ALLOWED: 'destinationUrlNotAllowed',
  HAS_COUPON_CODE: 'hasCouponCode',
  INTERNAL_URL: 'internalDstURL',
  EXTERNAL_URL: 'externalDstURL',
  AD_HAS_DSC_DETAILS: 'adHasDscDetails',
  CLICK_INTERNAL: 'clickInternal',
  REVEAL_CODE: 'revealCode',
  COPY_CODE: 'copyCode',
  CLICK_OUT: 'clickOut'
} as const;

@Injectable({
  providedIn: 'root'
})
export class DirectClickOutAdsService {

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private clickOutService: ClickOutService,
    private analyticsService: AnalyticsService,
    private breadcrumbsService: BreadcrumbsService,
    private adsAnalytics: AdsAnalyticsService,
    private phoneCtaService: PhoneCtaService,
    private adsOffersSevice: AdsOffersService,
    private singleUseCodeService: SingleUseCodeService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) { }

  async handleClickAd(ad: SearchResultsAdsModel, source: 'card' | 'cta' | 'takeover', onClick?: () => void, navigatedFromData?: Breadcrumb, adobeData?: AdobeClickEvent) {
    if ((source === adClickEvents.CARD || source === adClickEvents.TAKEOVER) && ad.descriptionDetails) {
      this.goToOfferDetailPage(ad, navigatedFromData, adobeData);
    } else if (this.isDisplayModal(ad)) {
      this.openModal(ad, adobeData);
    } else if (ad.destinationUrl?.startsWith('/')) {
      await this.goToInternalUrl(ad, adobeData);
    } else if (ad.destinationUrl) {
      await this.goToExternalUrl(ad, adobeData);
    } else if (ad.descriptionDetails) {
      this.redirectToOfferDetailsFromSearchResultsAd(ad, navigatedFromData);
    }

    if (onClick) {
      onClick();
    }
  }

  goToOfferDetailPage(ad: SearchResultsAdsModel, navigatedFromData?: Breadcrumb, adobeData?: AdobeClickEvent) {
    this.analyticsService.eventsTrack(this.adsAnalytics.getAnalyticsEvents('click', ad));
    if (adobeData) {
      adobeData.event_type = AdobeEventTypeEnum.CLICK;
      adobeData.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
      void this.adobeAnalyticsEventsService.sendOfferClick(adobeData);
    }

    this.redirectToOfferDetailsFromSearchResultsAd(ad, navigatedFromData);
  }

  openModal(ad: SearchResultsAdsModel, adobeData?: AdobeClickEvent) {
    if (adobeData) {
      adobeData.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
      void this.adobeAnalyticsEventsService.sendOfferClick({
        event_name: adobeData.event_name,
        event_type: AdobeEventTypeEnum.REVEAL_CODE,
        properties: adobeData.properties
      });
    }
    this.analyticsService.eventsTrack(this.adsAnalytics.getAnalyticsEvents('click-reveal-code', ad));
    this.increaseViewCounter(ad);
    ad.views += 1;
    const modal = this.getCouponModal();

    modal.componentInstance.brandLogo = ad.logoVendorSquare;
    modal.componentInstance.ad = ad;
    modal.componentInstance.code = ad.code;
    modal.componentInstance.modalEvents.subscribe(async event => this.handleModalEvent(event, ad, adobeData));
    modal.componentInstance.phoneAction.subscribe(url => this.phoneCtaService.phoneCtaClick(url, ad, true));
    modal.componentInstance.phoneClick.subscribe(phone => this.phoneCtaService.phoneClick(phone, ad));
  }

  async handleModalEvent(event, ad: SearchResultsAdsModel, adobeData?: AdobeClickEvent) {
    const type = ad.urlType === 'dynamic' ? 'offer_dynamic' : '';

    if (event && event.type) {
      switch (event.type) {
        case 'click-copy-code':
          if (adobeData) {
            adobeData.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
            void this.adobeAnalyticsEventsService.sendOfferClick({
              event_name: adobeData.event_name,
              event_type: AdobeEventTypeEnum.COPY_CODE,
              properties: adobeData.properties
            });
          }
          this.analyticsService.eventsTrack(this.adsAnalytics.getAnalyticsEvents('click-copy-code', ad));
          break;
        case 'cta-button-click':
          if (adobeData) {
            adobeData.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
            void this.adobeAnalyticsEventsService.sendOfferClick(adobeData);
          }
          const sourceId = sessionStorage.getItem(SOURCE_ID);
          await this.clickOutService.clickOut(
            ad.destinationUrl,
            type,
            {
              guid: ad.contentGuid,
              analyticEvents: this.adsAnalytics.getAnalyticsEvents('click-out', ad),
              data: {
                logo: ad.logoVendorSquare,
                brand: ad.vendorBrand,
                guid: ad.contentGuid,
                urlParams: this.getClickOutParams(ad)
              }
            },
            ad.destinationUrlNewTab,
            sourceId,
            ad.source_based_params
          );
          break;
      }
    }
  }

  async goToInternalUrl(ad: SearchResultsAdsModel, adobeData?: AdobeClickEvent) {
    if (adobeData) {
      adobeData.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
      void this.adobeAnalyticsEventsService.sendOfferClick(adobeData);
    }
    this.analyticsService.eventsTrack(this.adsAnalytics.getAnalyticsEvents('click-internal', ad));
    const urlParams = ad.g3UrlParams || '';
    if (ad.urlType === 'dynamic') {
      await this.clickOutService.clickOut(
        ad.destinationUrl,
        'offer_dynamic',
        {
          guid: ad.contentGuid,
          data: {
            logo: '',
            brand: '',
            guid: ad.contentGuid,
            urlParams,
          }
        },
        ad.destinationUrlNewTab
      );
    } else {
      let url = ad.destinationUrl;
      if (!!urlParams) {
        url += `${ad.destinationUrl.includes('?') ? '&' : '?'}${urlParams}`;
      }
      void this.router.navigateByUrl(url);
    }
  }

  async goToExternalUrl(ad: SearchResultsAdsModel, adobeData?: AdobeClickEvent) {
    if (adobeData) {
      adobeData.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(ad);
      void this.adobeAnalyticsEventsService.sendOfferClick(adobeData);
    }
    const type = ad.urlType === 'dynamic' ? 'offer_dynamic' : '';
    const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
    const additionalEvents = this.getAdAdditionalEvents(ad);

    await this.clickOutService.clickOut(
      ad.destinationUrl,
      type,
      {
        guid: ad.contentGuid,
        analyticEvents: [
          ...this.adsAnalytics.getAnalyticsEvents('click-out', ad),
          ...additionalEvents
        ],
        data: {
          logo: ad.logoVendorSquare,
          brand: ad.vendorBrand,
          guid: ad.contentGuid,
          urlParams: this.getClickOutParams(ad)
        },
      },
      ad.destinationUrlNewTab,
      sourceId,
      ad.source_based_params
    );

    this.increaseViewCounter(ad);
  }

  redirectToOfferDetailsFromSearchResultsAd(ad: SearchResultsAdsModel, navigatedFromData?: Breadcrumb) {
    if (!!navigatedFromData) {
      this.breadcrumbsService.setAdditionalItem(
        navigatedFromData.label,
        navigatedFromData.url,
        navigatedFromData.queryParams
      );
    }
    this.analyticsService.eventsTrack(this.adsAnalytics.getAnalyticsEvents('click-internal', ad));

    let queryParams = null;
    if (ad.destinationUrl?.startsWith('/')) {
      if (ad.g3UrlParams) {
        this.adsOffersSevice.appendG3Url = ad.g3UrlParams;
      }
    } else if (ad.isEbg || this.clickOutService.isInternalClickOut(ad.destinationUrl)) {
      if (ad.internalUrlParams) {
        this.adsOffersSevice.appendInternalUrl = ad.internalUrlParams;
      }
    } else if (ad.urlParams) {
      this.adsOffersSevice.appendUrl = ad.urlParams;
    }

    if (ad.g3UrlParams) {
      queryParams = ad.g3UrlParams.split('&').reduce((acc, el) => {
        const param = el.split('=');
        acc[param[0]] = param[1];
        return acc;
      }, {});
    }

    void this.router.navigate([`/offers/${ad.contentGuid}`], { queryParams });
  }

  increaseViewCounter(ad: SearchResultsAdsModel) {
    if (ad) {
      this.analyticsService.eventsTrack(this.adsAnalytics.getAnalyticsEvents('offer-view', ad));
    }
  }

  isShowGetAdButton(ad: SearchResultsAdsModel): boolean {
    return ad.hasCouponCode() || ad.hasDestinationUrl();
  }

  isDisplayModal(ad: SearchResultsAdsModel): boolean {
    const hasCouponForModal = ad.hasCouponCode() && !this.isAdExternalUrlWithSucToken(ad);
    return hasCouponForModal || ad.hasPhone();
  }

  getCouponModal(): NgbModalRef {
    return this.modalService.open(CouponModalComponent, {
      size: 'sm',
      centered: true,
      windowClass: 'custom-modal coupon-modal'
    });
  }

  protected getAdAdditionalEvents(ad: SearchResultsAdsModel): AnalyticsEvent[] {
    const events: AnalyticsEvent[] = [];

    if (this.isAdExternalUrlWithSucToken(ad)) {
      events.push(...this.adsAnalytics.getAnalyticsEvents('click-reveal-code', ad));
    }

    return events;
  }

  protected isAdExternalUrlWithSucToken(ad: SearchResultsAdsModel): boolean {
    return ad.hasCouponCode() && this.singleUseCodeService.isExternalUrlWithSucToken(ad.destinationUrl, ad.code);
  }

  protected getClickOutParams(ad: SearchResultsAdsModel): string {
    if (ad.destinationUrl?.startsWith('/')) {
      return ad.g3UrlParams;
    }
    return ad.isEbg || this.clickOutService.isInternalClickOut(ad.destinationUrl)
      ? ad?.internalUrlParams
      : ad?.urlParams;
  }

}
