<div class="modal-body-container">
  <h3 class="modal-body-container__title">{{ title }}</h3>
  <button type="button"
          class="modal-body-container__close"
          aria-label="Close"
          (click)="onCancel()">
    <svg width="16"
         height="14"
         viewBox="0 0 16 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M15.174 1.41L13.7193 0L7.95221 5.59L2.18513 0L0.730469 1.41L6.49754 7L0.730469 12.59L2.18513 14L7.95221 8.41L13.7193 14L15.174 12.59L9.40687 7L15.174 1.41Z"
            fill="#353535" />
    </svg>
  </button>
  <p class="modal-body-container__subtitle">{{ message }}</p>
  <button (click)="onUnsubscribeAction()"
          class="modal-body-container__unsubscribe btn btn-primary">
    {{ confirmButtonTitle }}
  </button>
  <button (click)="onCancel()"
          class="modal-body-container__cancel btn btn-primary-outline">
    Cancel
  </button>
</div>