/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Category } from '@app/shared/interfaces/category.interface';
import { ClientOfferResponse, ConfigService } from '@app/shared/services/config.service';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ComponentHtmlIdRegistryService, WINDOW } from 'g3-common-ui';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import {
  filter,
  map,
  take,
  tap
} from 'rxjs/operators';
import { Marker } from '@app/shared/interfaces/marker.interface';
import { Observable } from 'rxjs';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { ProviderLocationsService } from '../../services/provider-locations.service';
import { ProviderLocationType } from '@app/shared/interfaces/provider-location.type';

@Component({
  selector: 'app-list-view-filters',
  templateUrl: './list-view-filters.component.html',
  styleUrls: ['./list-view-filters.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION]
})

export class ListViewFiltersComponent implements OnInit {

  @Input() groupTabIndex: number;
  @Input() customClass = '';
  @Input() zipSuggestions: string[];
  @Input() zipCode: string;
  @Input() zipCodeInvalid: boolean;

  @Output() zipSelected = new EventEmitter<string>();
  @Output() filterSelected = new EventEmitter<boolean>();
  @Output() trackAnalyticsEvent = new EventEmitter<{ eventType: string; eventData: any }>();

  public zipCodeSuggestions: string;
  public zipCodeLabel: string;
  public locationType: ProviderLocationType = '';
  public mapInstance: any = {} as any;
  public shouldShowZipSuggestions = false;
  public isPanelVisible = false;
  public country = 'US';
  public category = '';
  public searchButtonLeftBinding = '';
  public shouldShowSearchButton = false;
  public zipFlag = false;
  public pageFlag: boolean;
  public markers$: Observable<Marker[]>;
  public categories$: Observable<Category[]>;
  public showLocationTypes$: Observable<boolean>;
  public locations$: Observable<{
    count: number;
    displayName: string;
    value: ProviderLocationType;
  }[]>;

  constructor(
    private idRegistry: ComponentHtmlIdRegistryService,
    private providerLocationsService: ProviderLocationsService,
    private configService: ConfigService,
    private profileService: ProfileService,
    private directClickOutService: DirectClickOutService,
    @Inject(WINDOW) private window: WINDOW,
  ) {
    this.zipCodeSuggestions = this.idRegistry.get('zip-code-suggestions');
    this.zipCodeLabel = this.idRegistry.get('zip-code-label');
  }

  async ngOnInit(): Promise<void> {
    this.pageFlag = this.customClass === 'provider-filters';
    const profile = await this.profileService.profileData$.pipe(
      filter(data => Object.keys(data).length > 0),
      take(1)
    ).toPromise();

    if (profile && profile.countryLast) {
      this.country = profile.countryLast;
    }

    const zips = await this.providerLocationsService.getLastZipLocations();
    if (zips) {
      this.zipSuggestions = zips;
      this.zipCode = this.zipSuggestions[0] || '78701';
    }

    this.initShowFilters();
  }

  onZipFocus(inputRef: HTMLInputElement): void {
    inputRef.select();
    this.shouldShowZipSuggestions = true;
    this.zipSelected.emit(this.zipCode);
  }

  closeSuggestionsPanel(): void {
    this.shouldShowZipSuggestions = false;
  }

  onZipSelected(zip = '', type: string, e: KeyboardEvent): void {
    (e.target as HTMLInputElement).blur();
    this.closeSuggestionsPanel();
    this.zipSelected.emit(zip);
    if (type === 'enter') {
      this.directClickOutService.providerLocations.trackAnalyticsEvents('provider-location-zip-enter', null, zip);
    } else if (type === 'select') {
      this.directClickOutService.providerLocations.trackAnalyticsEvents('provider-location-zip-select', null, zip);
    }
  }

  trackLocationByValue(index: number, location: { value: string }): string {
    return location.value;
  }

  trackCategoryByName(index: number, location: Category): string {
    return location.name;
  }

  changeCategory(): void {
    if (this.category || this.locationType) {
      this.filterSelected.emit(true);
    } else {
      this.filterSelected.emit(false);
    }
    this.providerLocationsService.filterBy(this.category, this.locationType);
    this.directClickOutService.providerLocations.trackAnalyticsEvents(
      'provider-location-category-select',
      null,
      this.category || 'All Categories'
    );
  }

  changeLocationType(): void {
    if (this.category || this.locationType) {
      this.filterSelected.emit(true);
    } else {
      this.filterSelected.emit(false);
    }
    this.providerLocationsService.filterBy(this.category, this.locationType);
  }

  private initShowFilters(): void {
    this.categories$ = this.providerLocationsService.markers$.pipe(
      map(markers => markers.meta.categories),
      tap(categories => {
        // hack for async update category select field
        // fixes https://github.com/beneplace/g3/pull/1192#issuecomment-459369758
        if (!categories.map(c => c.name).includes(this.category)) {
          this.window.requestAnimationFrame(() => this.category = '');
        }
      })
    );

    this.locations$ = this.providerLocationsService.markers$.pipe(
      map(markers => {
        const locations = markers.meta.locationTypes;
        const res = locations.map(({ name, count }) => ({ count, displayName: this.getLocationTypeDisplayName(name), value: name }));

        // If there are no provider locations with list_subdomain = the current marketplace, then don't show the new type select list
        // return res.map(l => l.value).includes('client') ? res : [];
        return res;
      }),
      tap(locations => {
        if (!locations.map(l => l.value).includes(this.locationType)) {
          this.window.requestAnimationFrame(() => this.locationType = '');
        }
      })
    );

    this.showLocationTypes$ = this.providerLocationsService.markers$.pipe(
      map(markers => markers.meta.marketplaceHasClientLocations)
    );

    this.showLocationTypes$.pipe(take(2)).subscribe(item => {
      if (item) {
        this.zipFlag = true;
      }
    });
  }

  private getLocationTypeDisplayName(name: ProviderLocationType): string {
    return name === 'client'
      ? this.configService.getOption<ClientOfferResponse>('client_offer').short_name
      : 'Beneplace Network';
  }
}
