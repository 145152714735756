import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';
import { PoliciesService } from '@policies/services/policies.service';
import { ActivatedRoute, Params } from '@angular/router';

enum SubTabs {
  Nominate = 'nominations',
  Ccpa = 'ccpa'
}

enum SubTabTitles {
  Contact = 'Contact',
  Nominate = 'Nominate',
  Ccpa = 'CCPA'
}

const SUPPORT = 'Support';

@Injectable()
export class AdobePoliciesDataService {
  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService,
    private policiesService: PoliciesService,
    private activatedRoute: ActivatedRoute
  ) { }

  async setPoliciesData(path: string): Promise<void> {
    const tabTitle = await this.getTabTitle(path);

    await this.adobeDataCollectorService.setPageData({
      name: `misc${tabTitle ? ': ' + tabTitle : ''}`,
      section: 'misc',
      type: 'g3 misc',
      hierarchy: this.adobeDataCollectorService.setHierarchy('Policies Page'),
    });
  }

  private async getTabTitle(path: string): Promise<string | void> {
    return this.policiesService.getPolicies()
      .then(policies => {
        const tab = policies.items.find(el => el.slug === path);
        return tab
          ? tab.title === SUPPORT
            ? `${tab.title}: ${this.getSubTabTitle()}`
            : tab.title
          : '';
      })
      .catch(err => console.error('Error fetching policies', err));
  }

  private getSubTabTitle(): string {
    const params: Params = this.activatedRoute.snapshot.queryParams;

    switch (params.tab) {
      case SubTabs.Nominate: {
        return SubTabTitles.Nominate;
      }
      case SubTabs.Ccpa: {
        return SubTabTitles.Ccpa;
      }
      default: return SubTabTitles.Contact;
    }
  }
}
