import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tickets-mini',
  templateUrl: './tickets-mini.component.html',
  styleUrls: ['./tickets-mini.component.less']
})

export class TicketsMiniComponent {

  @Output() clickOut = new EventEmitter<ClickOutModel>();

  model: CustomWidgetModel;

  async onButtonClick(data: { url: string }): Promise<void> {
    this.clickOut.emit({ model: this.model, url: data.url });
  }
}
