/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AuthService } from './auth.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthService
  ) { }

  isBeneplaceApiUrl(url: string): boolean {
    return /^\/api/.test(url);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBeneplaceApiUrl(request.url)) {
      const accessToken = this.auth.getAccessToken();
      const shortToken = this.auth.getShortToken() || '';
      const shouldUseBearerToken = !!accessToken;
      if (shouldUseBearerToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
            'short-token': shortToken
          }
        });
      }
    }

    return next.handle(request);
  }
}
