<div class="horizontal-scroll-wrapper"
     #wrapper>
     <div class="arrow left_arrow"
          [class.hide-arrow]="!showLeftControl"
          [class.small-content]="isBrandSection"
          (click)="leftClick()">
          <div class="inner_arrow theme-background-color-secondary">
               <svg width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 12.524L7.52403 14L0.524029 7L7.52403 -1.29033e-07L9 1.47597L3.47597 7L9 12.524Z"
                          fill="white" />
               </svg>
          </div>
     </div>
     <div class="arrow right_arrow"
          [class.hide-arrow]="!showRightControl || hideArrows"
          [class.small-content]="isBrandSection"
          (click)="rightClick()"
          #rightArrow>
          <div class="inner_arrow theme-background-color-secondary">
               <svg width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.47597L1.47597 0L8.47597 7L1.47597 14L0 12.524L5.52403 7L0 1.47597Z"
                          fill="white" />
               </svg>
          </div>
     </div>
     <div class="hide-right-part"
          [class.hide-pinned-right-part]="hideGhostEffect"
          [style.width]="ghostOffset"
          [style.left]="hideOffset"
          [class.hide-right-part-hovered]="hovered || scrolled"></div>
     <div class="hide-left-part"
          [class.hide-pinned-right-part]="hideGhostEffect"
          [style.width]="ghostOffset"
          [style.right]="hideOffset"></div>

     <div class="horizontal-scroll"
          [style.width]="getSliderWidth"
          [style.right]="ghostOffset"
          #slider
          (mousedown)="onMouseDown($event)"
          (mouseenter)="onMouseEnter($event)"
          (mouseleave)="onMouseLeave()"
          (mouseup)="onMouseUp($event)"
          (mousemove)="onMouseMove($event)"
          (swipeleft)="pan($event, panDirection.LEFT)"
          (swiperight)="pan($event, panDirection.RIGHT)"
          (panleft)="pan($event, panDirection.LEFT)"
          (panright)="pan($event, panDirection.RIGHT)">
          <div class="ghost"
               [style.minWidth]="ghostOffset"></div>
          <ng-content class="horizontal-scroll-items"></ng-content>
          <div class="ghost"
               [style.minWidth]="ghostOffset"></div>
     </div>
</div>