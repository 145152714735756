/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CtaElementService, CtaOptions } from '@app/zones/services/cta-element.service';
import { ImageUrlPipe } from 'g3-common-ui';

@Component({
  selector: 'app-cta-product',
  templateUrl: './cta-product.component.html',
  styleUrls: ['./cta-product.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CtaProductComponent {

  @Input() ctaProduct: CtaOptions;
  @Input() groupTabIndex: number;
  @Input() wideContainerClass = '';
  @Input() anchorLink = '';

  @Output() handleCta = new EventEmitter<void>();

  constructor(
    private ctaElementService: CtaElementService,
    private imageUrlPipe: ImageUrlPipe
  ) { }

  public get foregroundMaskStyles(): any {
    const imageUrl = this.ctaProduct && this.ctaProduct.image_url ? this.ctaProduct.image_url : '';
    const imgixUrl = this.imageUrlPipe.transform(imageUrl, { w: 508, h: 496 }, false);
    return !this.ctaElementService.isMSOrFirefoxBrowser() && this.ctaElementService.isImageSvg(imageUrl || '')
      ? this.ctaElementService.imageMask(imgixUrl, 'cover')
      : this.ctaElementService.backgroundMask(imgixUrl, 'cover');
  }

  public handleCtaClick(): void {
    this.handleCta.emit();
  }
}
