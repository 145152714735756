import { Component, Input, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, Inject, ChangeDetectionStrategy } from '@angular/core';
import { WINDOW } from 'g3-common-ui';

@Component({
  selector: 'app-auto-resize-overflow-text',
  templateUrl: './auto-resize-overflow-text.component.html',
  styleUrls: ['./auto-resize-overflow-text.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoResizeOverflowTextComponent implements AfterViewInit {
  @Input() text: string;
  @Input() textOverflowClassName: string;
  @Input() maxLinesCount = 4;
  @ViewChild('textContainer') textContainer: ElementRef<HTMLDivElement>;

  textOverflowStyles: Partial<CSSStyleDeclaration>;
  classNames = ['overflow-hidden'];

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(WINDOW) private window: WINDOW
  ) {}

  private get textContainerLineHeight(): number {
    return Number.parseInt(this.window.getComputedStyle(this.textContainer.nativeElement).lineHeight, 10);
  }

  private get textContainerHeight(): number {
    return this.textContainer.nativeElement.clientHeight;
  }

  ngAfterViewInit(): void {
    const textLinesCount = this.textContainerHeight / this.textContainerLineHeight;

    if (textLinesCount >= this.maxLinesCount) {
      this.textOverflowStyles = { webkitLineClamp: this.maxLinesCount.toString() };
      this.classNames = this.classNames.concat(this.textOverflowClassName);
      this.cdr.detectChanges();
    }
  }
}
