import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { environment } from '@environments/environment';
import get from 'lodash/get';
import { OtpCode, OtpData } from '../interfaces/otp.interface';
import { ConfigService } from './config.service';
import { OTP_TIMEOUT } from '../constants/one-time-password.constants';

export type ContactTypes = 'email' | 'phone';
interface IErrorResponse {
  error: {
    errors: {
      message: {
        message: string;
        name: string;
      }[];
    };
  };
}

export interface IUserAuthResponse {
  id: string;
  is_confirmed: boolean;
  email: string;
  email_contact: string;
  first_name: string;
  last_name: string;
  require_pass: boolean;
  require_passcode: boolean;
  status: string;
  country_last: string;
  mfa_setting: string;
  mfa_uri: string;
  pre_mfa_success_token: string;
  external_authentication: unknown;
  known_user_type: string;
  known_user_guid: string;
  known_child_guid: string;
  access_token: string;
  short_token: string;
  scopes: [string];
}

const DEFAULT_ERROR = 'Something went wrong!';

@Injectable({
  providedIn: 'root'
})
export class OneTimePasswordService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  /**
   *
   * @returns OTP Code timeout in seconds that was set up in system config
   */
  public getOtpCodeTimeout(date?: string): number {
    const otpCodeTimeout = this.configService.getOption('user_otp_force_resend_debounce_mins', OTP_TIMEOUT) * 60;

    if (date) {
      try {
        return Math.floor((Date.parse(date) + otpCodeTimeout * 1000 - Date.now()) / 1000);
      } catch (error) {
        console.error(error);
      }
    }

    return otpCodeTimeout;
  }

  public async createOtpCode(otpData: OtpData): Promise<OtpCode> {
    const { userGuid, type, contact, activateAccount, forceResend, uideh, reason, isChild }: OtpData = otpData;
    const body: OtpData = {
      userGuid,
      type,
      contact,
      activateAccount,
      forceResend,
      isChild,
    };

    if (uideh) {
      body.uideh = uideh;
    }
    if (reason) {
      body.reason = reason;
    }

    return this.http.post<OtpCode>(`${environment.apiUrl}/api/onetime-code`, body).toPromise();
  }

  public async checkOtpCode(userGuid: string, code: string, email = ''): Promise<unknown> {
    const body: { userGuid: string; code: string; email?: string } = {
      userGuid,
      code
    };

    if (email) {
      body.email = email;
    }

    return this.http.patch(`${environment.apiUrl}/api/onetime-code`, body).toPromise();
  }

  public async authenticateUserByLoginAndOTP(userGuid: string, otpValue: string, email = '', rememberMe = true): Promise<IUserAuthResponse> {
    return this.http.post<IUserAuthResponse>(`${environment.apiUrl}/auth/otp?subdomain=${this.configService.getSubdomain()}`, {
      userGuid,
      otpValue,
      email,
      rememberMe
    }).toPromise();
  }

  public getErrorMessage(res: IErrorResponse): string {
    return get(res, 'error.errors[0].message', DEFAULT_ERROR);
  }

}
