import { ChangeDetectionStrategy, Component, HostListener, Inject, Input, OnChanges } from '@angular/core';

import { WINDOW } from 'g3-common-ui';

import { OfferBadgeService } from '@shared/services/offer-badge.service';
import { PillsService } from '@app/shared/services/pills.service';

import { Badge } from '@shared/interfaces/badge.interface';
import { OfferBadgeData } from '@shared/interfaces/offer-badge-data.interface';
import { OfferBadgePropertiesModel } from '@shared/models/offer-badge-properties.model';

@Component({
  selector: 'app-hint-label',
  templateUrl: './hint-label.component.html',
  styleUrls: ['./hint-label.component.less', '../../../../styles/partials/badges.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintLabelComponent implements OnChanges {

  @Input() description: string;
  @Input() offersData: OfferBadgeData[];

  badges: Badge[] = [];
  hintLabel: string;

  constructor(
    @Inject(WINDOW) private window: WINDOW,
    private offerBadgeService: OfferBadgeService,
    private pillsService: PillsService
  ) { }

  get hintLabelVisible(): boolean {
    return !!(this.description && this.description.length) || !!(this.badges && this.badges.length);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateData();
  }

  ngOnChanges(): void {
    this.updateData();
  }

  updateData(): void {
    if (this.description && this.description.length) {
      this.hintLabel = this.description;
      return;
    }

    const badges = this.offersData.reduce((offerBadges: Badge[], offerData: OfferBadgeData) => {
      const offerProperties = new OfferBadgePropertiesModel({
        offerData,
        screenWidth: this.window.innerWidth
      });
      const pills = this.pillsService.getPills(
        this.offerBadgeService.getBadgesForOffer(offerProperties)
      ) as Badge[];

      offerBadges.push(...pills);
      return offerBadges;
    }, []);

    this.badges = badges;
    this.updateLabel(badges);
  }

  updateLabel(badges: Badge[]): void {
    if (this.offersData && this.offersData.length && badges && badges.length) {
      this.hintLabel = this.offersData.reduce((hintLabel, offerData, index, array) => {
        const newLabel = hintLabel + `${badges[index].title} on ${offerData.title}`;

        if (index < array.length - 1) {
          return `${newLabel} and `;
        }

        return newLabel;
      }, '');
    }
  }

}
