import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ALLOWED_CHANGE_EMAILS, ALLOWED_VIEW_OFFERS } from '@app/shared/constants/one-time-password.constants';
import { EmailSession } from '@app/shared/interfaces/otp.interface';
import { environment } from '@environments/environment';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class OTPService {

  public constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) { }

  public async saveEmailKeySession(data: EmailSession): Promise<EmailSession> {
    return this.http.post<EmailSession>(`${environment.apiUrl}/api/otp`, data).toPromise();
  }

  public async removeUserOtpSession(userGuid: string): Promise<unknown> {
    this.clearOtpSession();
    return this.http.delete<unknown>(`${environment.apiUrl}/api/otp/${userGuid}`).toPromise();
  }

  public clearOtpSession(): void {
    this.localStorageService.removeItem(ALLOWED_CHANGE_EMAILS);
    this.localStorageService.removeItem(ALLOWED_VIEW_OFFERS);
  }
}
