import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { ClickOutService } from '@app/shared/services/click-out.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { UrlService } from '@app/shared/services/url.service';
import { W_INSURANCE } from '@app/widgets/constants/widget-headers.constant';

@Component({
  selector: 'app-insurance-mini',
  templateUrl: './insurance-mini.component.html',
  styleUrls: ['./insurance-mini.component.less']
})

export class InsuranceMiniComponent {

  @Output() clickOut = new EventEmitter<ClickOutModel>();

  model: CustomWidgetModel;

  constructor(
    private clickOutService: ClickOutService,
    private urlService: UrlService
  ) { }

  async search(event: string): Promise<void> {
    const destinationUrl = this.checkExistedZipCodeParam(this.model.destinationUrl, event);
    this.clickOut.emit({ model: this.model, url: destinationUrl });
    await this.handleClickOut(destinationUrl);
  }

  private async handleClickOut(destinationUrl = ''): Promise<void> {
    const isExternalUrl = this.urlService.isExternalUrl(destinationUrl);
    if (isExternalUrl) {
      await this.clickOutService.clickOut(destinationUrl, '', {
        data: { logo: this.model.logoUrl, brand: W_INSURANCE, guid: this.model.id }
      }, !this.model.isEbg);
    } else {
      window.location.href = destinationUrl;
    }
  }

  private checkExistedZipCodeParam(url: string, zipcode: string): string {
    if (url.includes('?')) {
      if (url.toLowerCase().includes('%zipcode%')) {
        return url.replace(new RegExp('%zipcode%', 'gi'), zipcode);
      } else {
        return `${url}&zipcode=${zipcode}`;
      }
    } else {
      return `${url}?zipcode=${zipcode}`;
    }
  }

}
