/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { Marker } from '../interfaces/marker.interface';

@Injectable({
  providedIn: 'root'
})
export class MapMarkerPreviewService {

  constructor() { }

  /**
   * Handle marker size with/without image
   *
   * @param marker
   * @param largePinHeight
   * @param largePinWidth
   * @param smallPinHeight
   * @param smallPinWidth
   */
  public handleMarkerSize(
    marker: Marker,
    largePinHeight: string,
    largePinWidth: string,
    smallPinHeight: string,
    smallPinWidth: string
  ) {
    const locationMarker: HTMLElement = document.querySelector(`.premium-marker.c${marker.guid}`);
    const defaultMarker: HTMLElement = document.querySelector(`.default-marker.c${marker.guid}`);
    if (locationMarker) {
      locationMarker.style.width = largePinWidth;
      locationMarker.style.height = largePinHeight;
    }
    if (defaultMarker) {
      defaultMarker.style.width = smallPinWidth;
      defaultMarker.style.height = smallPinHeight;
    }
  }

  /**
   *
   * @param activatedMarker : marker which was clicked
   * @param markers
   */
  public deactivateMarker(activatedMarker: Marker, markers: Marker[]) {
    if (activatedMarker && activatedMarker.activated) {
      this.handleMarkerSize(activatedMarker, '38px', '38px', '10px', '10px');
      markers.find(m => m.activated).activated = false;

      if (activatedMarker.label) {
        const activateMarker = document.querySelector(`.location-marker.c${activatedMarker.guid}`);
        activateMarker.classList.remove('highlight-marker');
      }
      if (!activatedMarker.label) {
        const activateMarker = document.querySelector(`.default-marker.c${activatedMarker.guid}`);
        activateMarker.classList.remove('preview-default-icon');
      }
    }
  }

  /**
   *
   * @param guid : marker guid as HTML class
   * @param action : true: activated marker / false: inactive marker
   */
  public highlightCard(guid: string, action: boolean): void {
    const activatedCard = document.querySelector<HTMLElement>(`.c${guid}`);

    if (activatedCard) {
      if (action) {
        activatedCard.classList.add('highlighted-card');
      } else {
        activatedCard.classList.remove('highlighted-card');
      }
    }

  }

  public removeHighlightedState(markerGuid: string): void {
    const activatedCard = document.querySelector<HTMLElement>('.highlighted-card');

    if (activatedCard) {
      const activatedCardGuid = Object.values(activatedCard.classList).find(item => item === `c${markerGuid}`);
      if (!activatedCardGuid) {
        activatedCard.classList.remove('highlighted-card');
      }
    }

  }

}
