<div #subMenuContainer
     *ngIf="showSubMenu"
     class="dynamic-submenu"
     [@opacityFadeInOut]
     [class.has-destination-url]="primaryLevelItem.url"
     [ngClass]="'columns-count-' + totalContainerColumnsCount"
     [ngStyle]="{ 'width': subMenuWidth }">

  <ul *ngFor="let secondLevelNavItem of secondLevelNavItemsLimited; trackBy: trackBySecondLevelNavGuid"
      class="submenu dynamic-submenu-column"
      [class.submenu-expanded]="showSubMenu"
      [class.expanded-column]="secondLevelNavItem.columns.length > 1"
      role="menu">

    <li class="second-level-items">
      <a role="link"
         (click)="onSubmenuClick(secondLevelNavItem)"
         [appAutoTabIndex]="501">
        <img class="dynamic-submenu-icon"
             loading="lazy"
             [src]="secondLevelNavItem.icon_bw  | imageUrl : { w: 20, h: 20 }"
             alt=""
             role="presentation" />
        {{ secondLevelNavItem.name | truncateCompleteWords : 40 : true }}
      </a>
    </li>

    <div class="third-level-columns">

      <div *ngFor="let column of secondLevelNavItem.columns; trackBy: trackByColumnIndex"
           class="third-level-column">
        <li *ngFor="let thirdLevelNavItem of column; trackBy: trackByColumnGuid"
            class="third-level-items">
          <a role="link"
             (click)="onSubmenuClick(thirdLevelNavItem)"
             [appAutoTabIndex]="501">
            {{ thirdLevelNavItem.name | truncateCompleteWords : 40 : true }}
          </a>
        </li>
      </div>

    </div>

  </ul>

  <div *ngIf="primaryLevelItem.url"
       class="show-more-full-text">
    <a class="btn btn-text btn-underline"
       (click)="onSubmenuClick(primaryLevelItem, true)"
       [appAutoTabIndex]="501">
      <span>VIEW ALL {{ primaryLevelItem.name }}</span>
      <svg height="9"
           viewBox="0 0 9 9"
           width="9"
           xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c22=""
              d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z"
              fill="currentColor"
              fill-rule="nonzero"></path>
      </svg>
    </a>
  </div>
</div>
