// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.conf.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { MAP_STYLE } from '@environments/provider-location-map-styles.config';

export const environment = {
  production: false,
  apiUrl: '',
  authUrl: '',
  wsUrl: '',
  gtmId: 'GTM-PVZKWQ5', // staging and test GTM ID GTM-PVZKWQ5 -> Harlans Acct
  oauth: {
    url: 'https://auth.ssldev.beneplace.com',
    clientId: '678gnurhf9G51lUqb3Efnn'
  },
  mapApiKey: 'AIzaSyA3hDANcnc89dvQcH_GXeWeofQxtAHr5ho',
  providerLocationMapStyles: MAP_STYLE,
  providerLoctionMapKey: 'pk.eyJ1IjoiYmVuZXBsYWNlZGV2IiwiYSI6ImNqdXR5YnlpMjA0MXo0M3Jyc3NpbWQwaTIifQ.8hFEZwCJtTiXUPfhHZtW5w',
  adobeAnalytics: {
    scriptSource: '//assets.adobedtm.com/a281455e4dfe/86f9b29df5eb/launch-a0e5cece2585.min.js'
  }
};

