<div class="auth-footer {{ classListContainer }}">
  <div class="auth-footer-left {{ classListLeft }}">

    <div class="text"
         *ngIf="!isWorkingAdvantage">{{ this.footerText || 'This site is maintained by Beneplace, LLC.' }}</div>
    <div class="link"
         *ngIf="displayLink">
      <ng-template #popContent>
        <p>The {{ marketplaceInfo.name }} site is an exclusive benefit of {{ marketplaceInfo.owner }} {{
          marketplaceInfo.termMembers }}; an authorized account is required. In addition, many of the new features only
          work if you share details about yourself to personalize your experience.</p>
        <p>Please note: If you've already signed up for the old site, you still need to sign up using our new sign up
          process. We're very sorry about the inconvenience. But the good news is that once you sign up again, you can
          access both the old and new sites with the same account.</p>
      </ng-template>
      <div *ngIf="!hideDescriptionLink"
           class="signup-link">
        <div class="sep">|</div>
        <a href="#"
           class="btn btn-text-primary"
           (click)="$event.preventDefault();"
           [ngbPopover]="popContent"
           triggers="click:blur">Why do I need to sign up?</a>
      </div>
    </div>
  </div>
  <div *ngIf="showFooterLinks"
       class="auth-footer-right {{ classListRight }}">
    <a class="auth-footer-nav"
       (click)="onClick(nav.url)"
       *ngFor="let nav of navigation">{{nav.name}}</a>
  </div>
</div>