/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '@app/shared/services/config.service';
import { Injectable } from '@angular/core';
import { SearchService } from '@app/shared/services/search/search.service';
import { Observable ,  BehaviorSubject } from 'rxjs';

export interface Breadcrumb {
  label: string;
  url: string;
  queryParams: any;
}

@Injectable()
export class BreadcrumbsService {
  public breadcrumbs$: Observable<Breadcrumb[]>;

  private url: string;
  private titleSource = new BehaviorSubject<any>({});
  label$ = this.titleSource.asObservable();

  private additionalItem = new BehaviorSubject<any>({});
  additionalItem$ = this.additionalItem.asObservable();

  constructor(
    private configService: ConfigService,
    private searchService: SearchService,
  ) { }

  setTitle(label = {}) {
    const breadcrumbTitle = {
      label,
      url: this.url ? this.url : '',
      queryParams: this.searchService.getQueryParams(),
    };

    this.titleSource.next(breadcrumbTitle);
  }

  removeTitle() {
    this.titleSource.next({});
  }

  setAdditionalItem(label: string, url = '', queryParams = {}) {
    this.additionalItem.next({
      label, url, queryParams,
    });
  }

  removeAdditionalItem() {
    this.additionalItem.next({});
  }

  setUrl(url) {
    this.url = url;
  }

  get marketplaceName() {
    return this.configService.getOption<string>('name', '');
  }

  cleanUpPath(path: string): string {
    return path.split(/[\/-]+/g)
      .map(p => p.charAt(0).toUpperCase() + p.slice(1))
      .join(' ');
  }

  buildBreadcrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<Breadcrumb> = []
  ): Array<Breadcrumb> {

    if (route && route.snapshot) {
      const { routeConfig, params } = route.snapshot;
      const isParamsExists = params
        ? Object.keys(params).length
        : 0;

      if (routeConfig && !isParamsExists) {
        const { path, data } = routeConfig;

        const label = data
          ? data['breadcrumb']
          : '';

        if ((path === 'home' || path !== 'home') && !breadcrumbs.length) {
          const homeLabel = this.cleanUpPath(path);
          breadcrumbs.push({
            label: this.marketplaceName,
            url: path,
            queryParams: this.searchService.getQueryParams()
          });
        }

        if (label) {
          breadcrumbs.push({
            label: data['breadcrumb'],
            url: `/${path}`,
            queryParams: this.searchService.getQueryParams()
          });
        }

      }

      if (route.snapshot.firstChild) {
        return this.buildBreadcrumb(route.firstChild, url, breadcrumbs);
      }

      return breadcrumbs;
    }
  }

}
