import { Injectable } from '@angular/core';
import { SelectedUnsubscribe, UnsubscribeOptionContent, UnsubscribeOptionItem } from '../interfaces/subscription-options.interface';
import { UnsubscribeFormItem } from '../interfaces/subscriptions-response';

@Injectable()
export class UnsubscribeOptionsService {

  getSecondarySuccessHeader(selectedOptions: SelectedUnsubscribe[], options: UnsubscribeOptionContent): string {
    if (selectedOptions && selectedOptions.length) {
      const subscription = this.findSelectedOptions(selectedOptions, 'sid');
      if (subscription.length === selectedOptions.length) {
        return `We will no longer send you emails from the ${subscription.find(i => i.name).name} email series.`;
      }

      const subscriptionData = options.subscription.find(i => i.key === 'sid');
      const subName = this.findSelectedOptions(selectedOptions, 'sub_name');
      if (subName.length === selectedOptions.length && Object.keys(subscriptionData).length) {
        return `We will now send your ${subscriptionData.name} email series content ${subName.find(i => i.name).name}.`;
      }

      const brands = this.findSelectedOptions(selectedOptions, 'btag');
      if (brands.length === selectedOptions.length) {
        return `We will no longer send you emails that are completely focused on ${brands.map(i => i.name).join(', ')}.`;
      }

      const categories = this.findSelectedOptions(selectedOptions, 'ctag');
      if (categories.length === selectedOptions.length) {
        return `We will no longer send you emails that are completely focused on ${categories.map(i => i.name).join(', ')}.`;
      }

      return 'Your subscription preferences are updated. It may take a few days for these changes to be applied across our systems.';
    } else {
      return '';
    }
  }

  findSelectedOptions(selectedOptions: SelectedUnsubscribe[], key: string): SelectedUnsubscribe[] {
    return selectedOptions && selectedOptions.length ? selectedOptions.filter(i => i.type === key) : [];
  }

  getSelectedUnsubscribes(radio: UnsubscribeOptionItem, checkboxes: UnsubscribeOptionItem[]): SelectedUnsubscribe[] {
    const selectedRadioValue = radio && Object.keys(radio).length
      ? { name: radio.name, type: radio.key, id: radio.id }
      : null;

    if (checkboxes.length) {
      return selectedRadioValue ? [...this.getSelectedExclusions(checkboxes), selectedRadioValue] : this.getSelectedExclusions(checkboxes);
    } else {
      return selectedRadioValue ? [selectedRadioValue] : [];
    }
  }

  getSelectedExclusions(checkboxes: UnsubscribeOptionItem[]): SelectedUnsubscribe[] {
    return checkboxes.map(exclusion => ({
      name: exclusion.name,
      type: exclusion.key,
      id: exclusion.id
    }));
  }

  getOptionTitle(object: UnsubscribeFormItem, formType: string): string {
    if (object.key && object.key === 'ctag') {
      return `Don't send me emails from the <strong>${object.name} category</strong>`;
    }
    if (object.key && object.key === 'btag') {
      return `Don't send me emails about <strong>${object.name}</strong>`;
    }

    return object.key && object.key === 'sid' ? `Unsubscribe from the <strong>${object.name}</strong> email series` : object.name;
  }

}
