import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-modal-expired',
  templateUrl: './modal-expired-offer.component.html',
  styleUrls: ['./modal-expired-offer.component.less']
})
export class ModalExpiredOfferComponent {

  public constructor(
    public modalService: ModalService,
    private readonly router: Router,
  ) { }

  public async closeModal(): Promise<void> {
    this.modalService.closeModal();
  }
}
