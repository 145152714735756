import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/shared/services/config.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { OTPService } from '@app/shared/services/otp/otp.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.less']
})
export class SignOutComponent implements OnInit {

  loading = false;
  message: string;
  signOutUrl: SafeResourceUrl;

  constructor(
    public router: Router,
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private readonly profileService: ProfileService,
    private readonly otpService: OTPService
  ) { }

  public async ngOnInit(): Promise<void> {
    void this.otpService.removeUserOtpSession(this.profileService.getOption('guid'));

    const isAuthenticated = await this.authService.isAuthenticated();
    if (!isAuthenticated) {

      this.loading = true;
      this.authService.redirectToAuthorize('/');
      return;

    }

    this.signOutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.authService.getAuthorizeSignOutUrl());

    this.loading = true;

    try {

      this.authService.setUnauthenticated();

      const { sign_out_url: signOutUrl } = await this.authService.signOut();

      const signout_title = this.configService.getOption<string>('signout_title', '');
      this.message = signout_title || 'Thanks for visiting your discount marketplace.';

      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel('auth', {
          action: 'sign-out'
        }),
        new AnalyticsGAEventModel('sign-out', { category: 'sign-out-success' })
      ]);

      this.analyticsService.setUsername('');
      sessionStorage.clear();

      if (signOutUrl) {
        window.location.href = signOutUrl;
        return;
      }

      this.loading = false;

    } catch (err) {
      this.authService.setUnauthenticated();
      this.loading = false;

      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel('auth', {
          action: 'sign-out-failure'
        }),
        new AnalyticsGAEventModel('sign-out', { category: 'sign-out-failure' })
      ]);
      this.analyticsService.setUsername('');
      console.error(err);
      const signout_title = this.configService.getOption<string>('signout_title', '');
      this.message = signout_title || 'Thanks for visiting your discount marketplace.';
    }
  }

  signIn(): void {
    this.authService.redirectToAuthorize('/');
  }

}
