import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbNavModule, NgbTooltipModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  G3CommonUiModule,
  AutoTabIndexService,
} from 'g3-common-ui';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '@environments/environment';
import { NgxGooglePlacesDirective } from './directives/google-places.directive';

// Components
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { SelectSortByComponent } from '@app/shared/components/select-sort-by/select-sort-by.component';
import { RouterOutletWrapperComponent } from '@app/shared/components/router-outlet-wrapper/router-outlet-wrapper.component';
import { ModalConfirmComponent } from '@app/shared/components/modal-confirm/modal-confirm.component';
import { ConfirmOptionsComponent } from '@app/shared/components/modal-confirm/confirm-options/confirm-options.component';
import { ModalAlertComponent } from '@app/shared/components/modal-alert/modal-alert.component';
import { CardComponent } from '@app/shared/components/card/card.component';
import { BadgeComponent } from '@app/shared/components/badge/badge.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { OfferBadgeComponent } from './components/offer-badge/offer-badge.component';
import { DateSelectorComponent } from '@app/shared/components/date-selector/date-selector.component';
import { CouponComponent } from '@app/shared/components/coupon/coupon.component';
import { CouponModalComponent } from '@app/shared/components/coupon-modal/coupon-modal.component';
import { AvatarComponent } from '@app/shared/components/avatar/avatar.component';
import { ErrorPageComponent } from '@app/shared/components/error-page/error-page.component';
import { SelectComponent } from '@app/shared/components/select/select.component';
import { RateComponent } from '@app/shared/components/rate/rate.component';
import { RatePopupComponent } from '@app/shared/components/rate-popup/rate-popup.component';
import { SlideCarouselComponent } from './components/slide-carousel/slide-carousel.component';
import { ModalPassThruComponent } from './components/modal-pass-thru/modal-pass-thru.component';
import { CouponCopyCodeComponent } from '@shared/components/coupon/coupon-copy-code.component';
import { SaveButtonComponent } from '@app/shared/components/save-button/save-button.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { LogoComponent } from '@shared/logo/logo.component';
import { NominateComponent } from '@shared/components/nominate/nominate.component';
import { AdItemComponent } from './components/ad-item/ad-item.component';
import { OffersListComponent } from './components/offers-list/offers-list.component';
import { ShareButtonComponent } from './components/share-button/share-button.component';
import { RemoveButtonComponent } from './components/remove-button/remove-button.component';
import { OfferPreviewComponent } from './components/offer-preview/offer-preview.component';
import { TodayDealItemComponent } from '@app//shared/components/today-deal-item/today-deal-item.component';
import { TodayDealTagComponent } from '@app//shared/components/today-deal-tag/today-deal-tag.component';
import { NonEncapsulatedInnerHtmlComponent } from './components/non-encapsulated-inner-html/non-encapsulated-inner-html.component';
import { CommentNodeComponent } from './components/comment-node/comment-node.component';
import { OtpFormComponent } from './components/otp-form/otp-form.component';
import { OfferOtpModalComponent } from './components/offer-otp-modal/offer-otp-modal.component';
import { VaultOtpModalComponent } from './components/vault-otp-modal/vault-otp-modal.component';
import { CardAccessLockComponent } from './components/card-access-lock/card-access-lock.component';
import { CardOverlayComponent } from './components/card-overlay/card-overlay.component';
import { HintLabelComponent } from '@shared/components/hint-label/hint-label.component';
import { CloseButtonComponent } from '@shared/components/close-button/close-button.component';
import { EmptySearchResultComponent } from './components/empty-search-result/empty-search-result.component';
// TODO OpenEnrollmentAdComponent moved to shared because we need to connect it component in HomePageModule and ZonesModule
// When we remove outdated components from HomepageModule. It's better to review components here. Some of them better to move for the ZonesModule
import { OpenEnrollmentAdComponent } from './components/open-enrollment-ad/open-enrollment-ad.component';
import { AdDisclaimerModalComponent } from './components/ad-disclaimer-modal/ad-disclaimer-modal.component';
import { PhoneCtaService } from './services/phoneCta.service';
import { AdPaylogixButtonComponent } from './components/ad-paylogix-button/ad-paylogix-button.component';
import { PaylogixProfileStepComponent } from './components/account-confirmation-dropdown-steps/paylogix-profile-step/paylogix-profile-step.component';

// Directives
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { InputPlaceholderDirective } from '@app/shared/directives/input-placeholder.directive';
import { TogglePasswordVisibilityDirective } from '@app/shared/directives/toggle-password-visibility.directive';
import { TrackActiveSectionDirective } from '@app/shared/directives/track-active-section.directive';
import { HasPermissionDirective } from '@app/core/auth/directives/has-permission.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { AutoFocusElementDirective } from '@app/shared/directives/auto-focus.directive';
import { ProfileFeaturesDirective } from './directives/profile-features.directive';
import { BadgeDirective } from '@shared/directives/badge.directive';
import { TextOverflowDirective } from '@shared/directives/text-overflow.directive';

// Pipes
import { FullUrlPipe } from '@app/shared/pipes/full-url.pipe';
import { SafePipe } from '@app/shared/pipes/safe.pipe';
import { FormatDatePipe } from '@app/shared/pipes/format-date.pipe';
import { DecodeURIComponentPipe } from '@app/shared/pipes/decode-uri-component.pipe';
import { SelectHeaderPipe } from './pipes/select-header.pipe';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { TruncateStringPipe } from '@shared/pipes/truncate-string.pipe';
import { SafeExceptJavascriptPipe } from './pipes/safe-except-javascript.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PaylogixPipe } from './pipes/paylogix.pipe';

// Services
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { HomepageAdsService } from './services/homepage-ads.service';
import { DirectClickOutService } from './services/direct-click-out.service';
import { ClickOutService } from './services/click-out.service';
import { UrlService } from '@app/shared/services/url.service';
import { BrandItemComponent } from './components/brand-item/brand-item.component';
import { TodayDealsService } from './services/today-deals.services';
import { SearchService } from './services/search/search.service';
import { SearchInterceptorService } from './services/search/search-interceptor.service';
import { SearchSuggestedService } from './services/search/search-suggested.service';
import { FilterStateService } from './services/search/filter-state.service';
import { SearchBarItemComponent } from './components/search-bar-item/search-bar-item.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ProviderLocationsService } from './services/provider-locations.service';
import { ProviderLocationsComponent } from './components/provider-locations/provider-locations.component';
import { ProviderLocationsPanelComponent } from './components/provider-locations-panel/provider-locations-panel.component';
import { SpecialOffersComponent } from './components/special-offers/special-offers.component';
import { SpecialOffersItemComponent } from './components/special-offers-item/special-offers-item.component';
import { ListViewFiltersComponent } from './components/list-view-filters/list-view-filters.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { ListViewItemComponent } from './components/list-view-item/list-view-item.component';
import { ShareInfoService } from '@app/shared/services/share-info.service';
import { SubNavigationMenuComponent } from './components/sub-navigation-menu/sub-navigation-menu.component';
import { OffersListAdminLayoutItemComponent } from './components/offers-list/offers-list-admin/offers-list-admin-layout-item.component';
import { MapMarkerPreviewService } from './services/map-marker-preview.service';
import { OfferInlineEditFormComponent } from './components/offers-list/offers-list-admin/offer-inline-edit-form.component';
import { AdFlyInComponent } from './components/ad-fly-in/ad-fly-in.component';
import { AdCtaButtonComponent } from './components/ad-cta-button/ad-cta-button.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HomepageHorizontalScrollComponent } from '@app/homepage/components/homepage-horizontal-scroll/homepage-horizontal-scroll.component';
import { TodayDealsAnalyticsService } from '@shared/services/analytics/today-deals-analytics.service';
import { OffersAnalyticsService } from '@shared/services/analytics/offers-analytics.service';
import { DirectClickOutTodayDealsService } from '@shared/services/direct-click-out/direct-click-out-today-deals.service';
import { DirectClickOutProviderLocationsService } from '@shared/services/direct-click-out/direct-click-out-provider-locations.service';
import { DirectClickOutOffersService } from '@shared/services/direct-click-out/direct-click-out-offers.service';
import { DirectClickOutAdCardsService } from '@shared/services/direct-click-out/direct-click-out-ad-cards.service';
import { AdsAnalyticsService } from '@shared/services/analytics/ads-analytics.service';
import { ModalExpiredOfferComponent } from './components/modal-expired-offer/modal-expired-offer.component';
import { TabIndexService } from '@shared/services/tabIndex.service';
import { InfiniteDirective } from './directives/infinite-scroll.directive';
import { AnchorService } from './services/anchor.service';
import { AutocompleteDropdownComponent } from './components/autocomplete-dropdown/autocomplete-dropdown.component';
import { TabsetComponent } from './components/tabset/tabset.component';
import { CategoryTagsService } from './services/category-tags.service';
import { SelectOffersService } from './services/select-offers.service';
import { LocalStorageService } from './services/local-storage.service';
import { OneTimePasswordService } from './services/one-time-password.service';
import { ModalNotificationComponent } from './components/modal-notification/modal-notification.component';
import { AccountConfirmationDropdownComponent } from './components/account-confirmation-dropdown/account-confirmation-dropdown.component';
import { EmailConfirmationStepComponent } from './components/account-confirmation-dropdown-steps/email-confirmation-step/email-confirmation-step.component';
import { FinalConfirmationStepComponent } from './components/account-confirmation-dropdown-steps/final-confirmation-step/final-confirmation-step.component';
import { ChangeEmailConfirmationStepComponent } from './components/account-confirmation-dropdown-steps/change-email-confirmation-step/change-email-confirmation-step.component';
import { ProfilePromptZipStepComponent } from './components/account-confirmation-dropdown-steps/profile-prompt-zip-step/profile-prompt-zip-step.component';
import { ProfilePromptPhoneStepComponent } from './components/account-confirmation-dropdown-steps/profile-prompt-phone-step/profile-prompt-phone-step.component';
import { MregService } from './services/mreg/mreg.service';
import { UserContactsService } from './services/user-contacts/user-contacts.service';
import { OfferBadgeService } from '@shared/services/offer-badge.service';
import { OTPService } from './services/otp/otp.service';
import { SamlService } from './services/saml.service';
import { OfferItemComponent } from './components/offer-item/offer-item.component';
import { OfferEditorialHeroComponent } from './components/offer-editorial-hero/offer-editorial-hero.component';
import { GooglePlacesAutocompleteComponent } from './components/google-places-autocomplete/google-places-autocomplete.component';
import { AutoResizeOverflowTextComponent } from './components/auto-resize-overflow-text/auto-resize-overflow-text.component';
import { CreatePasswordModalComponent } from './components/create-password-modal/create-password-modal.component';
import { ModalOfferInvalidLocationComponent } from './components/modal-offer-invalid-location/modal-offer-invalid-location.component';
import { CategoryItemComponent } from './components/category-item/category-item.component';
import { BrandItemNameComponent } from './components/brand-item-name/brand-item-name.component';
import { FeedbackService } from './services/feedback.service';
import { ScrollSpyContentDirective } from './directives/scroll-spy-content.directive';
import { RedirectGuard } from './guards/redirect.guard';
import { PositionArrowButtonsComponent } from './components/position-arrow-buttons/position-arrow-buttons.component';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbNavModule,
    NgbTooltipModule,
    G3CommonUiModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.providerLoctionMapKey,
    }),
  ],
  declarations: [
    // Components
    AutoResizeOverflowTextComponent,
    RouterOutletWrapperComponent,
    SpinnerComponent,
    SelectSortByComponent,
    ModalConfirmComponent,
    ConfirmOptionsComponent,
    ModalAlertComponent,
    CardComponent,
    DateSelectorComponent,
    CouponComponent,
    CouponModalComponent,
    SaveButtonComponent,
    AvatarComponent,
    ErrorPageComponent,
    SelectComponent,
    RateComponent,
    RatePopupComponent,
    BadgeComponent,
    ActionMenuComponent,
    SearchInputComponent,
    LogoComponent,
    NominateComponent,
    OfferBadgeComponent,
    SlideCarouselComponent,
    ModalExpiredOfferComponent,
    ModalPassThruComponent,
    CouponCopyCodeComponent,
    AdItemComponent,
    OffersListComponent,
    BrandItemComponent,
    BrandItemNameComponent,
    SearchBarComponent,
    SearchBarItemComponent,
    ProviderLocationsComponent,
    ProviderLocationsPanelComponent,
    SpecialOffersComponent,
    SpecialOffersItemComponent,
    ListViewFiltersComponent,
    ListViewComponent,
    ListViewItemComponent,
    ShareButtonComponent,
    RemoveButtonComponent,
    OfferPreviewComponent,
    SubNavigationMenuComponent,
    OffersListAdminLayoutItemComponent,
    TodayDealItemComponent,
    TodayDealTagComponent,
    HomepageHorizontalScrollComponent,
    OpenEnrollmentAdComponent,
    AutocompleteDropdownComponent,
    TabsetComponent,
    AdDisclaimerModalComponent,
    NonEncapsulatedInnerHtmlComponent,
    CommentNodeComponent,
    VaultOtpModalComponent,
    OfferOtpModalComponent,
    ModalNotificationComponent,
    CardAccessLockComponent,
    CardOverlayComponent,
    AccountConfirmationDropdownComponent,
    EmailConfirmationStepComponent,
    ChangeEmailConfirmationStepComponent,
    PaylogixProfileStepComponent,
    ProfilePromptZipStepComponent,
    ProfilePromptPhoneStepComponent,
    FinalConfirmationStepComponent,
    OtpFormComponent,
    HintLabelComponent,
    CloseButtonComponent,
    AdPaylogixButtonComponent,
    GooglePlacesAutocompleteComponent,
    CreatePasswordModalComponent,
    ModalOfferInvalidLocationComponent,
    CategoryItemComponent,
    EmptySearchResultComponent,

    // Directives
    TogglePasswordVisibilityDirective,
    TrackActiveSectionDirective,
    FocusDirective,
    InputPlaceholderDirective,
    HasPermissionDirective,
    DebounceClickDirective,
    AutoFocusElementDirective,
    ProfileFeaturesDirective,
    InfiniteDirective,
    BadgeDirective,
    TextOverflowDirective,
    NgxGooglePlacesDirective,

    // Pipes
    FullUrlPipe,
    SafePipe,
    FormatDatePipe,
    DecodeURIComponentPipe,
    SafeExceptJavascriptPipe,
    SelectHeaderPipe,
    OrderByPipe,
    TruncatePipe,
    TruncateStringPipe,
    AdFlyInComponent,
    AdCtaButtonComponent,
    OfferInlineEditFormComponent,
    PaylogixPipe,
    OfferEditorialHeroComponent,
    OfferItemComponent,
    ScrollSpyContentDirective,
    PositionArrowButtonsComponent,
  ],
  providers: [
    MapMarkerPreviewService,
    TodayDealsAnalyticsService,
    OffersAnalyticsService,
    AdsAnalyticsService,
    DirectClickOutTodayDealsService,
    DirectClickOutProviderLocationsService,
    DirectClickOutOffersService,
    DirectClickOutAdCardsService,
    TabIndexService,
    FormatDatePipe,
    AnchorService,
    PhoneCtaService,
    NgbActiveModal,
    LocalStorageService,
    OneTimePasswordService,
    MregService,
    UserContactsService,
    OfferBadgeService,
    OTPService,
    SamlService,
    RedirectGuard,
    FeedbackService
  ],
  exports: [
    // Components
    AutoResizeOverflowTextComponent,
    RouterOutletWrapperComponent,
    SpinnerComponent,
    SelectSortByComponent,
    ModalConfirmComponent,
    ConfirmOptionsComponent,
    ModalAlertComponent,
    CardComponent,
    DateSelectorComponent,
    CouponComponent,
    CouponModalComponent,
    SaveButtonComponent,
    AvatarComponent,
    ErrorPageComponent,
    SelectComponent,
    RateComponent,
    RatePopupComponent,
    BadgeComponent,
    ActionMenuComponent,
    SearchInputComponent,
    LogoComponent,
    NominateComponent,
    OfferBadgeComponent,
    SlideCarouselComponent,
    CouponCopyCodeComponent,
    AdItemComponent,
    OffersListComponent,
    BrandItemComponent,
    BrandItemNameComponent,
    SearchBarComponent,
    SearchBarItemComponent,
    ProviderLocationsComponent,
    ProviderLocationsPanelComponent,
    SpecialOffersComponent,
    SpecialOffersItemComponent,
    ShareButtonComponent,
    RemoveButtonComponent,
    OfferPreviewComponent,
    SubNavigationMenuComponent,
    AdFlyInComponent,
    AdCtaButtonComponent,
    TodayDealItemComponent,
    TodayDealTagComponent,
    HomepageHorizontalScrollComponent,
    OpenEnrollmentAdComponent,
    AutocompleteDropdownComponent,
    TabsetComponent,
    NonEncapsulatedInnerHtmlComponent,
    CommentNodeComponent,
    VaultOtpModalComponent,
    OfferOtpModalComponent,
    CardAccessLockComponent,
    CardOverlayComponent,
    AccountConfirmationDropdownComponent,
    EmailConfirmationStepComponent,
    ChangeEmailConfirmationStepComponent,
    PaylogixProfileStepComponent,
    FinalConfirmationStepComponent,
    OtpFormComponent,
    HintLabelComponent,
    CloseButtonComponent,
    AdPaylogixButtonComponent,
    GooglePlacesAutocompleteComponent,
    CreatePasswordModalComponent,
    CategoryItemComponent,
    EmptySearchResultComponent,

    // Directives
    TogglePasswordVisibilityDirective,
    TrackActiveSectionDirective,
    FocusDirective,
    InputPlaceholderDirective,
    HasPermissionDirective,
    DebounceClickDirective,
    AutoFocusElementDirective,
    ProfileFeaturesDirective,
    InfiniteDirective,
    BadgeDirective,
    TextOverflowDirective,
    ScrollSpyContentDirective,
    NgxGooglePlacesDirective,

    // Pipes
    SafePipe,
    FullUrlPipe,
    FormatDatePipe,
    DecodeURIComponentPipe,
    SafeExceptJavascriptPipe,
    SelectHeaderPipe,
    OrderByPipe,
    FormatDatePipe,
    TruncatePipe,
    TruncateStringPipe,
    PaylogixPipe
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      // list of services that should have only 1 singleton instance in app per all ng modules
      providers: [
        ProfileService,
        HomepageAdsService,
        DirectClickOutService,
        ClickOutService,
        UrlService,
        TodayDealsService,
        SearchService,
        SearchSuggestedService,
        SearchInterceptorService,
        FilterStateService,
        ProviderLocationsService,
        ShareInfoService,
        AutoTabIndexService,
        AnchorService,
        CategoryTagsService,
        SelectOffersService,
        LocalStorageService,
        OneTimePasswordService,
        MregService,
        UserContactsService,
        CurrencyPipe,
        PaylogixPipe,
        {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: CustomHammerConfig
        }
      ]
    };
  }
}
