import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { AutocompleteSettings } from '@app/widgets/interfaces/autocomplete-settings.interface';
import {
  ClickOutModel,
  CustomWidgetModel
} from '@app/shared/models/custom-widget.model';
import { ConfigService } from '@shared/services/config.service';
import { CustomWidgetComponent } from '../../custom-widget/custom-widget.component';
import { defaultRoom } from '../../common/rooms-and-guests/room.constants';
import { HotelRoomsService } from '@app/widgets/services/hotel-rooms.service';
import { ModalAlertComponent } from '@app/shared/components/modal-alert/modal-alert.component';
import { NgbDateCustomParserFormatter } from '@app/widgets/services/datepicker-parser.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import {
  RoomData,
  RoomsEvent
} from '../../common/rooms-and-guests/room-data.interface';
import { SessionStorageService } from '@widgets/services/session-storage.service';
import {
  TAW_HOTELS_LARGE,
  TAW_HOTELS_MINI
} from '@app/widgets/constants/widget-ids.constant';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  Output,
  EventEmitter
} from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

interface RectDimensions {
  top: number;
  left: number;
  height: number;
  width: number;
}

interface HotelWidgetPayload {
  placeName?: string;
  placeLat?: string;
  placeLng?: string;
  checkIn?: string;
  checkOut?: string;
  roomsData?: RoomData[];
}

@Component({
  selector: 'app-hotels-form',
  templateUrl: './hotels-form.component.html',
  styleUrls: [
    './hotels-form.component.less',
    '../../search/common-widget.style.less'
  ],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION]
})
export class HotelsFormComponent implements CustomWidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() widgetClass: string;
  @Input() model: CustomWidgetModel;
  @Output() clickOut = new EventEmitter<ClickOutModel>();

  groupTabIndex: number;
  placeName = '';
  placeLat = '';
  placeLng = '';
  observer: MutationObserver;
  MAX_MINI_WIDGET_ZINDEX = 1000;
  showGuestsAndRoomsData = false;
  roomsAndGuestsTitle = '';
  roomsData: RoomData[] = [defaultRoom];
  roomFormData = {};
  autocompleteSettings: AutocompleteSettings = {
    inputPlaceholderText: 'e.g. Miami',
    showSearchButton: false,
    currentLocIconUrl: '/assets/icons/current-location.png',
    locationIconUrl: '/assets/icons/location.png',
    showRecentSearch: false
  };
  autocompleteOptions: { inputString: string };
  url = '';

  minDate: NgbDateStruct;
  checkIn: NgbDateStruct;
  checkOut: NgbDateStruct;
  checkInDate: string;
  checkOutDate: string;

  // * Have a reference to improve performance
  autoCompleteMenuContainer: HTMLElement;

  constructor(
    public datepickerParser: NgbDateCustomParserFormatter,
    public configService: ConfigService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private hotelRoomsService: HotelRoomsService,
    private sessionStorageService: SessionStorageService,
    private widgetSuggestionService: WidgetSuggestionService
  ) {
    this.minDate = datepickerParser.toNgbDate(new Date());
    this.checkIn = datepickerParser.toNgbDate(new Date());
    this.checkOut = datepickerParser.toNgbDate(new Date(), 2);
  }

  // Following code updates autocomplete position when user resizes window
  // In case autocomplete panel is open
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const ddMenuCollection = document.getElementsByClassName(
      'custom-autocomplete__dropdown'
    );

    if (ddMenuCollection.length < 1) {
      return;
    }
    this.positionAutocompleteMenu(
      ddMenuCollection[0] as HTMLElement,
      this.autoCompleteMenuContainer
    );
  }

  ngOnInit(): void {
    this.roomsAndGuestsTitle = this.hotelRoomsService.getRoomsAndGuestsTitle();
    this.initDefaultValues();
    this.handleStoredData();
  }

  ngAfterViewInit(): void {
    this.autoCompleteMenuContainer = document.getElementById('search_places');
  }

  setElementDimensions(
    el: Element,
    dimensions: Pick<RectDimensions, 'width' | 'left' | 'top'>
  ): void {
    this.renderer.setStyle(el, 'width', dimensions.width + 'px');
    this.renderer.setStyle(el, 'left', dimensions.left + 'px');
    this.renderer.setStyle(el, 'top', dimensions.top + 'px');
    this.renderer.setStyle(el, 'position', 'absolute');
  }

  getElementDimensions(el: Element): RectDimensions {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
      height: rect.height,
      width: rect.width
    };
  }

  initDefaultValues(): void {
    if (!this.model) {
      return;
    }

    this.autocompleteSettings.inputPlaceholderText = 'e.g. Miami';

    if (this.model.destinationUrl) {
      this.url = this.model.destinationUrl;
    }
  }

  autoCompleteCallback(event: { data: unknown }): void {
    const { data } = event;
    this.placeName = get(data, 'formatted_address');
    this.placeLat = get(data, 'geometry.location.lat');
    this.placeLng = get(data, 'geometry.location.lng');
  }

  search(): boolean {
    this.checkInDate = this.datepickerParser.convertDateToFormat(
      this.datepickerParser.format(this.checkIn)
    );
    this.checkOutDate = this.datepickerParser.convertDateToFormat(
      this.datepickerParser.format(this.checkOut)
    );
    if (isEmpty(this.roomFormData)) {
      this.roomFormData = this.hotelRoomsService.getDefaultRoomsWrapperData();
    }
    if (!this.placeName && !this.placeLat && !this.placeLng) {
      this.alertMessage('Please enter a travel destination.');
      return false;
    }
    if (!this.checkIn || !this.checkOut) {
      this.alertMessage('Please enter both a check-in and check-out date.');
      return false;
    }
    this.dispatchWidgetData();
    this.clickOut.emit({ model: this.model, url: this.url });
    return true;
  }

  handleRoomsAndGuests(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.showGuestsAndRoomsData = !this.showGuestsAndRoomsData;
  }

  handleRoomsAndGuestsData(roomsEvent: RoomsEvent): void {
    if (this.model.widgetId === roomsEvent.widgetId) {
      this.roomsData = roomsEvent.roomsData;
      this.roomsAndGuestsTitle = this.hotelRoomsService.getRoomsAndGuestsTitle(
        roomsEvent.roomsData
      );
      this.roomFormData = this.hotelRoomsService.getRoomsWrapperData(
        roomsEvent.roomsData
      );
    }
  }

  dismissRoomPopover(): void {
    this.showGuestsAndRoomsData = false;
  }

  onDateSelection(date: NgbDateStruct, target: string): void {
    if (target === 'checkIn') {
      this.checkIn = date;
      this.checkOut = this.datepickerParser.toNgbDate(
        new Date(this.datepickerParser.format(date)),
        3
      );
      return;
    }
    this.checkOut = date;
  }

  public handleAddressChange(value: Address): void {
    if (!value) {
      return;
    }

    const geoLocation = get(value, 'geometry.location');

    this.placeName = get(value, 'address_components')
      .filter(el => !el.types.includes('administrative_area_level_2'))
      .map(el => el.short_name)
      .join(', ');

    this.placeLat = geoLocation.lat().toString();
    this.placeLng = geoLocation.lng().toString();
  }


  public ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private positionAutocompleteMenu(
    el: HTMLElement,
    containerEl: HTMLElement
  ): void {
    if (!el || !containerEl) {
      return;
    }
    const refDimensions = this.getElementDimensions(containerEl);
    refDimensions.top += refDimensions.height;
    this.setElementDimensions(el, refDimensions);
  }

  private dispatchWidgetData(): void {
    this.sessionStorageService.removeGroupOfKeys('_mini');
    const widgetType: string =
      this.model && this.model.widgetId === TAW_HOTELS_MINI
        ? TAW_HOTELS_MINI
        : TAW_HOTELS_LARGE;
    const data = {
      placeName: this.placeName,
      placeLat: this.placeLat,
      placeLng: this.placeLng,
      checkIn: new Date(this.datepickerParser.format(this.checkIn)),
      checkOut: new Date(this.datepickerParser.format(this.checkOut)),
      roomsData: this.roomsData
    };

    this.sessionStorageService.setItem(widgetType, data);
    void this.widgetSuggestionService.addSuggestedWidgets({
      data,
      type: widgetType,
      page_url: window.location.href,
      destination_url: this.url
    });
  }

  private alertMessage(message: string): void {
    const modal = this.modalService.open(ModalAlertComponent, {
      size: 'sm',
      centered: true
    });
    modal.componentInstance.content = {
      text: message
    };
  }

  private handleStoredData(): void {
    const widgetType: string =
      this.model && this.model.widgetId === TAW_HOTELS_MINI
        ? TAW_HOTELS_MINI
        : TAW_HOTELS_LARGE;
    const hotelWidgetData =
      this.sessionStorageService.getItem<HotelWidgetPayload>(widgetType);

    if (!hotelWidgetData) {
      return;
    }

    if (hotelWidgetData.placeName) {
      this.placeName = hotelWidgetData.placeName;
      this.autocompleteSettings.inputString = hotelWidgetData.placeName;
    }

    if (hotelWidgetData.placeLat) {
      this.placeLat = hotelWidgetData.placeLat;
    }

    if (hotelWidgetData.placeLng) {
      this.placeLng = hotelWidgetData.placeLng;
    }

    if (hotelWidgetData.checkIn) {
      this.checkIn = this.datepickerParser.toNgbDate(
        this.datepickerParser.handleTimezoneDiff(hotelWidgetData.checkIn)
      );
    }

    if (hotelWidgetData.checkOut) {
      this.checkOut = this.datepickerParser.toNgbDate(
        this.datepickerParser.handleTimezoneDiff(hotelWidgetData.checkOut)
      );
    }

    if (hotelWidgetData.roomsData) {
      this.roomsData = hotelWidgetData.roomsData;
      this.roomsAndGuestsTitle = this.hotelRoomsService.getRoomsAndGuestsTitle(
        hotelWidgetData.roomsData
      );
      this.roomFormData = this.hotelRoomsService.getRoomsWrapperData(
        hotelWidgetData.roomsData
      );
    }
  }
}
