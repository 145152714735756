<div class="stylized-select {{className}}">
  <select id="{{name + 'Id'}}"
          [appAutoTabIndex]="groupTabIndex"
          [disabled]="isDisabled"
          class="{{name}}-select"
          #select
          [name]="name"
          [(ngModel)]="selectValue"
          (change)="onChange()"
          (appClickOutside)="changeSvgState()"
          (mousedown)="mousedown.emit()"
          (keydown)="keydown.emit()"
          (blur)="blur.emit()"
          (mouseleave)="mouseleave.emit()"
          placeholder
          [ngClass]="isValueNull ? 'color-transparent' : 'color-solid'"
          (click)="selectClick()"
          [attr.aria-label]="placeholder">
    <option class="_option placeholder-option"
            [value]=null
            disabled
            selected
            hidden
            *ngIf="placeholder"
            [attr.aria-label]="placeholder">{{placeholder}}</option>
    <option class="_option"
            *ngFor="let option of options; trackBy: trackOptionByValue"
            [value]="option.value"
            [attr.aria-label]="option.text">{{option.text}}</option>
  </select>
  <svg class="select-arrow"
       *ngIf="showArrow"
       id="{{name + '-arrow'}}"
       [style.active]="activeSelect"
       xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20">
    <path fill="#353535"
          fill-rule="nonzero"
          d="M14.59 6.59L10 11.17 5.41 6.59 4 8l6 6 6-6z"
          opacity=".5" />
  </svg>
</div>
