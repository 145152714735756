import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CarMake, newCarMake, usedCarMake } from './makes';
import { CustomWidgetModel } from '@shared/models/custom-widget.model';
import { ModalAlertComponent } from '@shared/components/modal-alert/modal-alert.component';
import { SessionStorageService } from '@widgets/services/session-storage.service';
import { TRUECAR_LARGE, TRUECAR_MINI } from '@app/widgets/constants/widget-ids.constant';
import { UrlService } from '@app/shared/services/url.service';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';
import { TokensService } from '@shared/services/tokens.service';
import { InputSelectedValue } from '@app/shared/components/select/select.component';


const USED_CAR_REFERENCE_DEFAULT_ID = 'ZBEN000025877';
const USED_CAR_REFERENCE_WA_ID = 'ZWOR000025936';
const NEW_CAR_REFERENCE_DEFAULT_ID = 'ZBEN000025876';
const NEW_CAR_REFERENCE_WA_ID = 'ZWOR000025935';

interface WidgetSessionData {
  newMakeId: string;
  zipCodeNew: string;
  usedMakeId: string;
  zipCodeUsed: string;
}

@Component({
  selector: 'app-truecar-form',
  templateUrl: './truecar-form.component.html',
  styleUrls: ['./truecar-form.component.less']
})
export class TruecarFormComponent implements OnInit {

  @Input() public widgetClass: string;
  @Input() public model: CustomWidgetModel;

  @Output() public buttonClick: EventEmitter<{ url: string; type: string }> = new EventEmitter<{ url: string; type: string }>();

  public groupTabIndex: number;

  // New Car Properties
  public newMakeId: string = null;
  public zipCodeNew: string;

  // Used Car Properties
  public usedMakeId: string = null;
  public zipCodeUsed: string;
  public readonly referrerId = 28898457;

  private dynamicUrlData = '';

  constructor(
    private modalService: NgbModal,
    private urlService: UrlService,
    private sessionStorageService: SessionStorageService,
    private widgetSuggestionService: WidgetSuggestionService,
    private tokensService: TokensService
  ) { }

  public get newCarMake(): CarMake[] {
    return newCarMake;
  }

  public get usedCarMake(): CarMake[] {
    return usedCarMake;
  }

  public get newCarReferenceId(): string {
    return this.urlService.isWorkingAdvantage(window.location.hostname)
      ? NEW_CAR_REFERENCE_WA_ID
      : NEW_CAR_REFERENCE_DEFAULT_ID;
  }

  public get usedCarReferenceId(): string {
    return this.urlService.isWorkingAdvantage(window.location.hostname)
      ? USED_CAR_REFERENCE_WA_ID
      : USED_CAR_REFERENCE_DEFAULT_ID;
  }

  public ngOnInit(): void {
    this.handleStoredData();
    void this.populateDynamicUrlData();
  }

  public getWidgetSize(): string {
    return this.model && this.model.widgetId && this.model.widgetId.includes('mini') ? 'mini' : 'large';
  }

  public getPartnerName(): string {
    return this.urlService.isWorkingAdvantage(window.location.hostname) ? 'wa' : 'beneplace';
  }

  public findNewCar(): void {
    if (!this.newMakeId) {
      this.alertMessage('Please select a new car make/model.');
      return;
    }

    if (!this.zipCodeNew) {
      this.alertMessage('Please enter a Zip code.');
      return;
    }

    if (this.zipCodeNew.length !== 5) {
      this.alertMessage('Please enter a valid Zip code.');
      return;
    }

    const zipCode = this.sanitizeZipCode(this.zipCodeNew);
    const newCarPath = `overview/${this.newMakeId}/`;
    const newCarQuery = `postalCode=${zipCode}&referrer_id=${this.newCarReferenceId}&utm_source=homepage&`
      + `utm_medium=site-link&utm_campaign=widget&utm_content=new&utm_term=${this.getWidgetSize()}&${this.dynamicUrlData}`;
    const fullUrl = `https://${this.getPartnerName()}.truecar.com/${newCarPath}?${newCarQuery}`;

    this.dispatchWidgetData(fullUrl);
    this.buttonClick.emit({ url: fullUrl, type: 'new-car' });
  }

  public findUsedCar(): void {
    if (!this.usedMakeId) {
      this.alertMessage('Please select a used car make/model.');
      return;
    }

    if (!this.zipCodeUsed) {
      this.alertMessage('Please enter both a search range and Zip code.');
      return;
    }

    if (this.zipCodeUsed.length !== 5) {
      this.alertMessage('Please enter a valid Zip code.');
      return;
    }

    const zipCode = this.sanitizeZipCode(this.zipCodeUsed);
    const usedCarPath = `used-cars-for-sale/listings/${this.usedMakeId}/location-${zipCode}`;
    const usedCarQuery = `referrer_id=${this.usedCarReferenceId}&utm_source=homepage&utm_medium=site-link&utm_campaign=widget&`
      + `utm_content=used&utm_term=${this.getWidgetSize()}&${this.dynamicUrlData}`;
    const fullUrl = `http://${this.getPartnerName()}.truecar.com/${usedCarPath}?${usedCarQuery}`;

    this.dispatchWidgetData(fullUrl);
    this.buttonClick.emit({ url: fullUrl, type: 'used-car' });
  }

  public selectChange({ name, value }: InputSelectedValue): void {
    if (name === 'new-make') {
      this.newMakeId = value;
    }

    if (name === 'used-make') {
      this.usedMakeId = value;
    }
  }

  public getZipCodePlaceholder(): string {
    return this.widgetClass === 'mini-widget' ? 'e.g. 33101' : 'Zip Code';
  }

  public sanitizeZipCode(zipCode: string): string {
    const defaultZipCode = '90404';
    if (!zipCode || zipCode === '') {
      return defaultZipCode;
    }

    return zipCode;
  }

  private dispatchWidgetData(destinationUrl: string): void {
    this.sessionStorageService.removeGroupOfKeys('_mini');
    const widgetType: string = this.model && this.model.widgetId === TRUECAR_MINI ? TRUECAR_MINI : TRUECAR_LARGE;
    const data: WidgetSessionData = {
      newMakeId: this.newMakeId,
      zipCodeNew: this.zipCodeNew,
      usedMakeId: this.usedMakeId,
      zipCodeUsed: this.zipCodeUsed
    };
    this.sessionStorageService.setItem(widgetType, data);
    void this.widgetSuggestionService.addSuggestedWidgets({
      data,
      type: widgetType,
      page_url: window.location.href,
      destination_url: destinationUrl
    });
  }

  private handleStoredData(): void {
    const widgetType: string = this.model && this.model.widgetId === TRUECAR_MINI ? TRUECAR_MINI : TRUECAR_LARGE;
    const parsedTruecarWidgetData = this.sessionStorageService.getItem<WidgetSessionData>(widgetType);

    if (parsedTruecarWidgetData) {
      if (parsedTruecarWidgetData.newMakeId) {
        this.newMakeId = parsedTruecarWidgetData.newMakeId;
      }

      if (parsedTruecarWidgetData.zipCodeNew) {
        this.zipCodeNew = parsedTruecarWidgetData.zipCodeNew;
      }

      if (parsedTruecarWidgetData.usedMakeId) {
        this.usedMakeId = parsedTruecarWidgetData.usedMakeId;
      }

      if (parsedTruecarWidgetData.newMakeId) {
        this.zipCodeUsed = parsedTruecarWidgetData.zipCodeUsed;
      }
    }
  }

  private async populateDynamicUrlData(): Promise<void> {
    const [dynamicData] = await this.tokensService.getPopulatedDynamicURLs(['u1=%UIDHASH%']);
    this.dynamicUrlData = dynamicData;
  }

  private alertMessage(message): void {
    const modal = this.modalService.open(ModalAlertComponent, { size: 'sm', centered: true });
    modal.componentInstance.content = {
      text: message
    };
  }

}
