/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { UntypedFormGroup } from '@angular/forms';

export function getFormFirstError(form: UntypedFormGroup): any {
  const fields = Object.keys(form.controls);
  for (const i in fields) {
    if (fields[i]) {
      const control = form.get(fields[i]);
      if (!control.valid && control.errors) {
        return {
          field: fields[i],
          validator: Object.keys(control.errors)[0]
        };
      }
      if (control['controls']) {
        const deepControls = Object.keys(control['controls']);
        let error;

        deepControls.forEach(key => {
          const value = control.get(key);

          if (!value.valid && value.errors) {
            error = {
              field: key,
              validator: Object.keys(value.errors)[0]
            };
          }
        });
        return error;
      }
    }
  }
}

export function getAllFormErrors(form: UntypedFormGroup): { field: string; validator: string }[] {
  const fields = Object.keys(form.controls);
  return fields.map((field: string) => {

    const control = form.get(field);
    if (!control.valid && control.errors) {
      return {
        field,
        validator: Object.keys(control.errors)[0]
      };
    }

  }).filter(el => el);
}

export function getAllFormErrorsObject(form: UntypedFormGroup, errorMessages: any): any {
  const errors = {};

  for (const key in form.controls) {
    if (form.controls.hasOwnProperty(key)) {
      const control = form.get(key);
      if (!control.valid && control.errors) {
        errors[key] = errorMessages[key][Object.keys(control.errors)[0]];
      }
    }
  }

  return errors;
}
