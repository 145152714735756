import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {

  @Input() size = '36';
  @Input() isHomePage = false;

  protected spinnerWrapHeight: number =  Number(this.size) * Math.sqrt(2);

  constructor() { }

  ngOnInit(): void { }

}
