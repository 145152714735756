import { AnalyticsEvent, AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { Injectable } from '@angular/core';
import { PreviewDisplayedEventData, SendToEventData, UnsubscribeModalEventData } from '@app/subscription-center/interfaces/subscription.interface';

// Please add more types. For example: type ActionType = 'subctr-preview-viewed' | 'something-else';
type ActionType =
  'subctr-preview-viewed' |
  'subscribe-center-send-to-changed' |
  'subscribe-center-unsubscribe-modal-show' |
  'subscribe-center-unsubscribe-modal-confirm' |
  'subscribe-center-unsubscribe-modal-cancel';
// Please add more data. For example: type EventData = PreviewDisplayedEventData | SomeOtherInterface
type EventData = PreviewDisplayedEventData | SendToEventData | UnsubscribeModalEventData;

@Injectable()
export class SubscriptionCenterAnalyticsService {

  getAnalyticsEvents(action: ActionType, data: EventData): AnalyticsEvent[] {
    switch (action) {
      case 'subctr-preview-viewed':
        return [
          new AnalyticsInternalEventModel(action, data),
          new AnalyticsGAEventModel(action, {
            category: 'subctr-preview',
            label: data['subctr-preview']
          })
        ];
      case 'subscribe-center-send-to-changed':
        return [
          new AnalyticsInternalEventModel(action, data),
          new AnalyticsGAEventModel(action, {
            category: 'subscribe-center-send-to',
            label: data['subscribe-center-send-to']
          })
        ];
      case 'subscribe-center-unsubscribe-modal-show':
      case 'subscribe-center-unsubscribe-modal-cancel':
      case 'subscribe-center-unsubscribe-modal-confirm':
        const modalData = data as UnsubscribeModalEventData;
        return [
          new AnalyticsInternalEventModel(action, data),
          new AnalyticsGAEventModel(action, {
            category: 'subscribe-center-unsubscribe-modal',
            label: `${modalData.subctr_name} | ${modalData.subctr_id}`
          })
        ];
    }

    return [];
  }
}
