import { ClickOutModel, CustomWidgetModel } from '@shared/models/custom-widget.model';
import { ClickOutService } from '@shared/services/click-out.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { UrlService } from '@app/shared/services/url.service';
import { W_TRUE_CAR } from '@app/widgets/constants/widget-headers.constant';

@Component({
  selector: 'app-truecar-mini',
  templateUrl: './truecar-mini.component.html',
  styleUrls: ['./truecar-mini.component.less']
})
export class TruecarMiniComponent {

  @Output() clickOut = new EventEmitter<ClickOutModel>();
  model: CustomWidgetModel;

  constructor(
    private clickOutService: ClickOutService,
    private urlService: UrlService
  ) { }

  async onButtonClick(data: { url: string; type: string }): Promise<void> {
    this.clickOut.emit({ model: this.model, url: data.url });
    await this.handleClickOut(data.url);
  }

  private async handleClickOut(destinationUrl = ''): Promise<void> {
    const isExternalUrl = this.urlService.isExternalUrl(destinationUrl);
    if (isExternalUrl) {
      await this.clickOutService.clickOut(destinationUrl, '', {
        data: { logo: this.model.logoUrl, brand: W_TRUE_CAR, guid: this.model.id }
      }, true);
    } else {
      window.location.href = destinationUrl;
    }
  }
}
