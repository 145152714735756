/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionResponse } from '../interfaces/subscriptions-response';
import { UnsubscribeRequestBody } from '../interfaces/subscriptions-request.interface';

interface IUserData {
  guid: string;
  emailHash: string;
}

@Injectable()
export class SubscriptionsService {

  constructor(
    private http: HttpClient
  ) { }

  public async patchSubscriptions(subdomain: string, userData: string, body: UnsubscribeRequestBody | { [key: string]: string }): Promise<any> {
    const { guid, emailHash }: IUserData = this.splitUserData(userData);

    if (emailHash) {
      body['email_hash'] = emailHash;
    }

    return this.http.patch<any>(`${environment.apiUrl}/api/subscriptions/${subdomain}/${guid}`, body).toPromise();
  }

  public getSubscriptions(subdomain: string, userData: string, sid: string, btags?: string, ctags?: string): Observable<SubscriptionResponse[]> {
    const { guid, emailHash }: IUserData = this.splitUserData(userData);
    return this.http.get<any>(`${environment.apiUrl}/api/subscriptions/${subdomain}/${guid}${this.generateQuery({ sid, btags, ctags, emailHash })}`);
  }

  public getRequestBody(sid = '', global = null, optedOut = null, btag = '', ctag = '', sub_name = '', is_subscribed = null): UnsubscribeRequestBody | { [key: string]: string } {
    const requestBody = {};

    if (sid) {
      requestBody['sid'] = sid;
    }

    if (global) {
      requestBody['user_is_global_subscribed'] = false;
    }

    if (optedOut) {
      requestBody['euser_opt_out'] = true;
    }

    if (btag) {
      requestBody['btag'] = btag;
    }

    if (ctag) {
      requestBody['ctag'] = ctag;
    }

    if (sub_name) {
      requestBody['sub_name'] = sub_name;
    }

    if (is_subscribed) {
      requestBody['is_subscribed'] = false;
    }

    return requestBody;
  }

  private generateQuery({ sid = '', btags = '', ctags = '', emailHash = '' }): string {
    let query = '?';

    if (sid) {
      query += `&sid=${sid}`;
    }

    if (btags) {
      query += `&btag=${btags}`;
    }

    if (ctags) {
      query += `&ctag=${ctags}`;
    }

    if (emailHash) {
      query += `&emailHash=${emailHash}`;
    }

    return query;
  }

  private splitUserData(userData: string): IUserData {
    const user = userData.split('~');
    return {
      guid: user[0] || '',
      emailHash: user[1] || ''
    };
  }

}
