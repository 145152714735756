import { CommonModule } from '@angular/common';
import { G3CommonUiModule } from 'g3-common-ui';
import { NgModule } from '@angular/core';

// Custom Modules
import { SharedModule } from '@shared/shared.module';
import { WidgetsModule } from '@widgets/widgets.module';

// Components
import { CardsFixedComponent } from './components/cards-fixed/cards-fixed.component';
import { CardsHorizontalScrollComponent } from './components/cards-scroll/cards-horizontal-scroll/cards-horizontal-scroll.component';
import { CardsScrollComponent } from './components/cards-scroll/cards-scroll.component';
import { CtaBlockComponent } from './components/cta-element/cta-block/cta-block.component';
import { CtaElementComponent } from './components/cta-element/cta-element.component';
import { CtaProductComponent } from './components/cta-element/cta-product/cta-product.component';
import { NotiBlockComponent } from './components/noti-block/noti-block.component';
import { PinnedZoneComponent } from './components/pinned-zone/pinned-zone.component';
import { TddScrollComponent } from './components/tdd-scroll/tdd-scroll.component';
import { CtaElementService } from './services/cta-element.service';
import { WidgetsLargeComponent } from './components/widgets-large/widgets-large.component';
import { WidgetsMiniComponent } from './components/widgets-mini/widgets-mini.component';
import { ZoneHeaderComponent } from './components/zone-header/zone-header.component';
import { ZoneSectionComponent } from './components/zone-section/zone-section.component';
import { CardsProductComponent } from './components/cards-product/cards-product.component';

// Services
import { ZoneHeaderService } from '@zones/services/zone-header.service';

import { ZoneWidgetsService } from './services/zone-widgets.service';
import { ZonesSectionElementsAnalyticsService } from './services/analytics/zones-section-elements-analytics.service';
import { TopCategoriesComponent } from './components/top-categories/top-categories.component';
import { TopBrandsComponent } from './components/top-brands/top-brands.component';
import { TopBrandsNamesComponent } from './components/top-brands-names/top-brands-names.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    CardsFixedComponent,
    NotiBlockComponent,
    ZoneSectionComponent,
    ZoneHeaderComponent,
    TddScrollComponent,
    CardsScrollComponent,
    CardsHorizontalScrollComponent,
    CtaBlockComponent,
    TddScrollComponent,
    WidgetsLargeComponent,
    WidgetsMiniComponent,
    PinnedZoneComponent,
    CtaElementComponent,
    CtaProductComponent,
    CardsProductComponent,
    TopCategoriesComponent,
    TopBrandsComponent,
    TopBrandsNamesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    G3CommonUiModule,
    WidgetsModule,
    NgbModule
  ],
  exports: [
    CardsFixedComponent,
    ZoneSectionComponent,
    TddScrollComponent,
    ZoneSectionComponent,
    CardsScrollComponent,
    CardsHorizontalScrollComponent,
    PinnedZoneComponent
  ],
  providers: [
    ZonesSectionElementsAnalyticsService,
    CtaElementService,
    ZoneHeaderService,
    ZoneWidgetsService,
  ]
})
export class ZonesModule { }
