import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnchorService {

  anchorLinks: string[] = [];
  scrollPosition: number;

  constructor() { }

  getAnchorLink(value: string): string {
    if (value) {
      const anchorName = value ? value.replace('\'', '') : '';
      const anchorLink = anchorName ? anchorName.split(' ').join('_').toLowerCase() : '';
      return this.findAnchorDuplicate(anchorLink);
    } else {
      return '';
    }
  }

  findAnchorDuplicate(value: string): string {
    const findDuplicate = this.anchorLinks.filter(i => i === value);
    if (!(findDuplicate && findDuplicate.length >= 1)) {
      this.anchorLinks.push(value);
      return value;
    }
  }

  setScrollPosition(scrollPosition: number): void {
    if (scrollPosition > 0) {
      this.scrollPosition = scrollPosition;
    }
  }

}
