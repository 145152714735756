import { Injectable } from '@angular/core';

const MAX_ITEMS_IN_ZONE_ELEMENT = 99;
const AUTO_TAB_PER_CARD_ITEM = 4; // card, heart and 2 buttons
const ELEMENT_HEADER_EVENTS = 1;

@Injectable({
  providedIn: 'root'
})
export class TabIndexService {
  getTabIndexForZone(initialIndex: number, orderInSection: number): number {
    const elementOffset = orderInSection * MAX_ITEMS_IN_ZONE_ELEMENT * AUTO_TAB_PER_CARD_ITEM + ELEMENT_HEADER_EVENTS;
    return initialIndex + elementOffset;
  }
}
