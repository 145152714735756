import {
  animate,
  style,
  transition,
  trigger
} from '@angular/animations';

export const SLIDE_IN_OUT_LEFT_ANIMATION = [
  trigger('slideInOutLeft', [
    transition('* => true', [
      style({
        transform: 'translateX(-100%)',
        opacity: 0.7,
      }),
      animate('300ms ease-out', style({
        transform: 'translateX(0%)',
        opacity: 1,
      }))
    ]),
    transition('true => *', [
      animate('300ms ease-in', style({
        transform: 'translateX(-100%)',
        opacity: 0.7
      }))
    ])
  ])
];
