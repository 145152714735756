<ng-container *ngIf="!!data && todayDeals.length">
  <section class="tdd-section container main-container wide-container category-item-container scrollable_container"
           [attr.id]="anchorLink"
           [class.wide-endpoint]="isWideContainer()"
           *ngIf="showSection"
           (mouseenter)="hovered = true"
           (mouseleave)="hovered = false">
    <app-zone-header *ngIf="!!data.name"
                     [data]="data"
                     [groupTabIndex]="groupTabIndex"
                     (showAllClick)="showAllClickHandler($event)">
    </app-zone-header>

    <app-homepage-horizontal-scroll *ngIf="todayDeals.length"
                                    [hideGhostEffect]="hideGhostEffect"
                                    [hideArrows]="hideArrows"
                                    [isDesktop]="isDesktop"
                                    [hovered]="hovered"
                                    (scrollEvent)="onScrollEvent($event)"
                                    (swipeEvent)="onSwipeEvent($event)">
      <app-today-deal-item *ngFor="let todayDealItem of todayDeals; trackBy: trackById"
                           [todayDeal]="todayDealItem"
                           [appAutoTabIndex]="groupTabIndex"
                           [usedFromSearch]="false"
                           class="{{ wideContainerClass }}"
                           (click)="handleClickTodayDeal($event, todayDealItem); (false)"></app-today-deal-item>
    </app-homepage-horizontal-scroll>
  </section>
</ng-container>
