import { Injectable } from '@angular/core';
import { MultiWidgetTabsetUnits } from '../search/multi-search/multi-search-units.enum';
import { RENTAL_CAR_SEARCH, TAW_HOTELS_SEARCH, TICKETS_SEARCH } from '../constants/widget-ids.constant';
import { SearchWidgetsData } from '@app/offers/interfaces/search-widgets.interface';
import { Tabset } from '@app/shared/components/tabset/tabset.interface';
import { TokensService } from '@app/shared/services/tokens.service';

const WIDGET_ITEM_KEYWORD = '_search';
const WIDGET_DELIMITER = ';';
const KEY_VALUE_REGEX = /:(.+)/;

@Injectable()
export class MultiWidgetService {

  constructor(
    private tokensService: TokensService
  ) { }

  getTabsetTitleByWidgetId(widgetId: string): string {
    let tabTitle = '';
    switch (widgetId) {
      case TAW_HOTELS_SEARCH:
        tabTitle = MultiWidgetTabsetUnits.Hotel;
        break;
      case RENTAL_CAR_SEARCH:
        tabTitle = MultiWidgetTabsetUnits.RentalCar;
        break;
      case TICKETS_SEARCH:
        tabTitle = MultiWidgetTabsetUnits.Tickets;
        break;
    }

    return tabTitle;
  }

  getMultiWidgetTabset(multiWidgetUnits: Partial<SearchWidgetsData>[]): Tabset[] {
    return multiWidgetUnits && multiWidgetUnits.length ? multiWidgetUnits
      .filter(unit => !!this.getTabsetTitleByWidgetId(unit.widget_id))
      .map(unit => ({
        id: unit.widget_id,
        title: this.getTabsetTitleByWidgetId(unit.widget_id),
        image: unit.icon_url,
        cat_code_tag_ids: unit.cat_code_tag_ids
      })) : [];
  }

  getMultiWidgetUnitsFromString(widget: SearchWidgetsData): Partial<SearchWidgetsData>[] {
    return Object.keys(widget).filter(item => item.includes(WIDGET_ITEM_KEYWORD)).map(widgetItem => {
      const widgetItemData: Partial<SearchWidgetsData> = this.getMappedWidgetItem(widget[widgetItem]);
      return { widget_id: widgetItem, destination_url: widget.destination_url, ...widgetItemData };
    });
  }

  async getMultiWidgetUnits(widget: SearchWidgetsData): Promise<Partial<SearchWidgetsData>[]> {
    const units = this.getMultiWidgetUnitsFromString(widget);

    const unitsIsEbg = units.some(item => (
      item && item.destination_url && item.destination_url.toLowerCase().includes('%ebghost%')
    ));
    const handledUrls = await this.handleTokens(units);

    return units.map((value, index) => ({
      ...value,
      destination_url: handledUrls[index],
      responseId: widget.responseId,
      isEbg: unitsIsEbg,
      cat_code_tag_ids: widget.cat_code_tag_ids
    }));
  }

  private async handleTokens(units: Partial<SearchWidgetsData>[]): Promise<string[]> {
    const destinationUrls = units.map(unit => unit.destination_url);
    return this.tokensService.getPopulatedDynamicURLs(destinationUrls);
  }

  private getMappedWidgetItem(widgetItemString: string): Partial<SearchWidgetsData> {
    const widgetItemDataStrings = widgetItemString.split(WIDGET_DELIMITER);
    const widgetData = {} as Partial<SearchWidgetsData>;
    widgetItemDataStrings.forEach(stringItem => {
      const splittedKeyValueString = stringItem.split(KEY_VALUE_REGEX);
      widgetData[splittedKeyValueString[0]] = splittedKeyValueString[1];
    });

    return widgetData;
  }

}
