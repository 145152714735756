<ng-container *ngIf="!!widgetClass && !!model">
  <div class="{{ widgetClass }}">
    <form class="widget-form"
          id="searchForm">
      <div class="{{ widgetClass }}-autocomplete">
        <label class="widget-label {{ widgetClass }}-autocomplete__label">Destination: </label>
        <app-google-places-autocomplete placeholder="City or ZIP"
                                        [normalizedDestination]="true"
                                        [autocompleteOptions]="autocompleteOptions"
                                        (destinationChange)="updatePlaceName($event)"
                                        (addressChange)="handleAddressChange($event)">
        </app-google-places-autocomplete>
      </div>
      <div class="{{ widgetClass }}-autocomplete looking-for__container">
        <label class="widget-label {{ widgetClass }}-autocomplete__label">I am Looking for</label>
        <div class="looking-for__wrapper">
          <input id="tickets-entity"
                 placeholder="Parks, artists, venues, and events"
                 aria-label="tickets-entity"
                 type="text"
                 class="widget-input looking-for-input"
                 name="tickets-entity"
                 [(ngModel)]="ticketsEntity"
                 (keyup.enter)="search($event);"
                 (ngModelChange)="onTicketsEntityChange($event)" />
        </div>
      </div>

      <div class="{{ widgetClass }}-action">
        <button [appAutoTabIndex]="groupTabIndex"
                class="btn btn-primary {{ widgetClass }}-action__search"
                type="submit"
                (click)="search($event)"
                value="Search">
          Search
        </button>
      </div>
    </form>
  </div>
</ng-container>
