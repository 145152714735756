<div *ngIf="!type"
     [attr.id]="anchorLink"
     class="wide-container container main-container relative-container title-container">
  <div class="provider-locations-header">
    <h4 class="locations-header-title">
      <img class="heading-icon"
           src="/assets/icons/icn-map.svg"
           loading="lazy"
           alt="">
      {{ title }}
      <mark class="locations-count">({{ (markers$ | async).length }})</mark>
    </h4>
    <app-list-view-filters [groupTabIndex]="groupTabIndex"
                           (zipSelected)="onZipSelected($event)"
                           (trackAnalyticsEvent)="trackAnalyticsEvent($event)"
                           [zipSuggestions]="zipSuggestions"
                           [zipCodeInvalid]="zipCodeInvalid"
                           [zipCode]="zipCode"
                           [customClass]="customClass">
    </app-list-view-filters>
  </div>
</div>
<div [ngClass]="type ? 'full-page-container' : 'container main-container relative-container ' + wideContainerClass">
  <div class="close-map"></div>
  <app-list-view *ngIf="type"
                 [groupTabIndex]="groupTabIndex"
                 (zipSelected)="onZipSelected($event)"
                 (trackAnalyticsEvent)="trackAnalyticsEvent($event)"
                 [selectedLocation]="selectedLocation"
                 [isDesktop]="isDesktop"
                 [zipSuggestions]="zipSuggestions"
                 [zipCodeInvalid]="zipCodeInvalid"
                 [expandCard]="expandCard"
                 (activateMarker)="activateMarker($event)"
                 [markerGuid]="markerGuid"
                 [activatedMarker]="activatedMarker"
                 (panelState)="panelState($event)"
                 [zipCode]="zipCode">
  </app-list-view>
  <ng-container *ngIf="!mapLoading">
    <div class="disable-mobile-container">
      <div class="tablet-flex-container map-container map-container-with-focus">
        <mgl-map [style]="'mapbox://styles/mapbox/light-v9'"
                 [zoom]="[mapConfig.zoom]"
                 [minZoom]="mapConfig.minZoom"
                 [maxZoom]="mapConfig.maxZoom"
                 [center]="[mapConfig.longitude, mapConfig.latitude]"
                 (load)="handleMapReady($event)"
                 (moveEnd)="handleBoundsChange($event)">

          <mgl-marker *ngFor="let marker of (markers$ | async)"
                      [lngLat]="[marker.lng, marker.lat]">

            <app-offer-preview *ngIf="marker.showPreviewMarker"
                               [@opacityFadeInOut]
                               (mouseleave)="hidePreviewMarker(marker)"
                               (panelStatus)="handleMarkerClick($event, marker)"
                               (cardGuid)="setMarkerGuid($event)"
                               [marker]="marker">
            </app-offer-preview>

            <div [@opacityFadeInOut]
                 [ngClass]="'c' + marker.guid"
                 (mouseenter)="showPreviewMarker($event, marker)"
                 (mouseleave)="dropMarkerSize(marker)"
                 [attr.aria-label]="marker.name"
                 [appAutoTabIndex]="groupTabIndex + 213"
                 [class.preview-icon]="marker.showPreviewMarker"
                 class="premium-marker location-marker"
                 *ngIf="marker.label"
                 [style.backgroundImage]="'url(' + marker.label + ')'"
                 (click)="handleMarkerClick($event, marker)">
            </div>
            <div class="default-marker location-marker"
                 *ngIf="!marker.label"
                 [ngClass]="'c' + marker.guid"
                 [class.preview-default-icon]="marker.showPreviewMarker"
                 (mouseenter)="showPreviewMarker($event, marker)"
                 (mouseleave)="dropMarkerSize(marker)"
                 (click)="handleMarkerClick($event, marker)"></div>
          </mgl-marker>

          <mgl-control position="top-right"
                       *ngIf="type">
            <div class="map-additional-controls">
              <button [appAutoTabIndex]="groupTabIndex + 207"
                      aria-label="Close the map"
                      (click)="closeMap()"
                      class="gm-style app-ngl-map-close-top"
                      placement="left"
                      container="body"
                      [closeDelay]="500"
                      (mouseenter)="addHoverClass($event)"
                      (mouseleave)="removeHoverClass($event)"
                      ngbTooltip="Close Map">
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="27"
                     height="27"
                     viewBox="0 0 24 24">
                  <g fill="none"
                     fill-rule="evenodd">
                    <path [@opacityFadeInOut]
                          fill="currentColor"
                          fill-rule="nonzero"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    <path d="M0 0h24v24H0z" />
                  </g>
                </svg>
              </button>
            </div>
          </mgl-control>
          <mgl-control mglNavigation
                       [showCompass]="false"
                       position="bottom-right"
                       class="app-ngl-zoom-buttons-container"></mgl-control>
          <mgl-control position="bottom-right">
            <div class="map-additional-controls">
              <button (mouseenter)="addHoverClass($event)"
                      (mouseleave)="removeHoverClass($event)"
                      [appAutoTabIndex]="groupTabIndex + 208"
                      aria-label="close map"
                      (click)="closeMap()"
                      class="gm-style app-ngl-map-close-bottom">
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24">
                  <g fill="none"
                     fill-rule="evenodd">
                    <path fill="currentColor"
                          fill-rule="nonzero"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    <path d="M0 0h24v24H0z" />
                  </g>
                </svg>
              </button>
              <button (mouseenter)="addHoverClass($event)"
                      (mouseleave)="removeHoverClass($event)"
                      [appAutoTabIndex]="groupTabIndex + 209"
                      aria-label="Reload this map"
                      id="app-ngl-map-reset-button"
                      class="gm-style refresh-button"
                      (click)="trackAnalyticsEvent('provider-location-map-refresh-click', {},  null, false); onMapReset()">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24">
                  <g fill="none"
                     fill-rule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path fill="currentColor"
                          fill-rule="nonzero"
                          d="M16.942 7.058a6.968 6.968 0 0
                          0-5.676-2.02c-3.215.323-5.86 2.93-6.219 6.14A6.988 6.988 0 0 0 11.993 19a6.99 6.99 0 0 0
                          6.315-3.99.876.876 0 0 0-.788-1.259.852.852 0 0 0-.771.464 5.252 5.252 0 0
                          1-5.956 2.895c-1.945-.428-3.512-2.012-3.924-3.954a5.253 5.253 0 0 1 5.124-6.404c1.454 0 2.75.604 3.696
                          1.557L14.367 9.63c-.552.552-.167 1.496.613 1.496h3.144a.878.878 0 0 0
                          .876-.874V7.11c0-.779-.946-1.172-1.498-.621l-.56.568z" />
                  </g>
                </svg>
              </button>
              <button (mouseenter)="addHoverClass($event)"
                      (mouseleave)="removeHoverClass($event)"
                      [appAutoTabIndex]="groupTabIndex + 210"
                      aria-label="Find my location"
                      id="app-ngl-map-setup-current-position-button"
                      class="gm-style current-position"
                      (click)="trackAnalyticsEvent('provider-location-current-location-click', {}, null, true); setMapUserCurrentPosition()">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24">
                  <g fill="none"
                     fill-rule="evenodd">
                    <path d="M4 4h16v16H4z" />
                    <path fill="currentColor"
                          fill-rule="nonzero"
                          d="M12 9.333a2.666 2.666 0 1 0 0 5.334 2.666 2.666 0 1 0
                            0-5.334zm5.96 2a5.996 5.996 0 0 0-5.293-5.293v-.707a.667.667 0 0 0-1.333 0v.707a5.996 5.996 0 0 0-5.294
                            5.293h-.707a.667.667 0 0 0 0 1.333h.707a5.996 5.996 0 0 0 5.293 5.294v.707a.667.667 0 1 0 1.333
                            0v-.707a5.996 5.996 0 0 0 5.294-5.293h.707a.667.667 0 1 0 0-1.333h-.707zM12 16.667A4.663 4.663 0 0 1
                            7.333 12 4.663 4.663 0 0 1 12 7.333 4.663 4.663 0 0 1 16.667 12 4.663 4.663 0 0 1 12 16.667z" />
                  </g>
                </svg>
              </button>
            </div>
          </mgl-control>
          <mgl-control position="top-left">
            <button [appAutoTabIndex]="groupTabIndex + 201"
                    [hidden]="!shouldShowSearchButton"
                    class="btn btn-primary btn-search-this-location"
                    (click)="searchThisLocation()"
                    [style.left]="searchButtonLeftBinding"
                    style="margin-top:32px;border:none;font-size:13px;padding:12px 24px;font-family:'Lato',sans-serif;position:absolute;width:216px;"
                    id="btn-search-this-location">
              Search this location
            </button>
          </mgl-control>
          <mgl-geojson-source id="mapcircle">
            <mgl-feature [geometry]="circleLayerSourceGeometry">
            </mgl-feature>
          </mgl-geojson-source>
          <mgl-layer id="geomarker"
                     type="circle"
                     source="mapcircle"
                     [paint]="circleLayerStyles"></mgl-layer>
        </mgl-map>
        <app-provider-locations-panel [groupTabIndex]="groupTabIndex + 214"
                                      [@slideUpDownPanel]
                                      class="provider-locations-mobile-panel"
                                      *ngIf="isPanelVisible && isResponsive"
                                      [data]="selectedLocation"
                                      [locationDisclaimer]="locationDisclaimer"
                                      (panelClose)="closePanelAndResetData()"></app-provider-locations-panel>
      </div>
    </div>
  </ng-container>
</div>
