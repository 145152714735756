import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ButtonSize } from '@app/shared/enums/button-size.enum';
import { IAdPaylogixButtonOptions } from '@app/shared/interfaces/ad-paylogix-button-options.interface';

@Component({
  selector: 'app-ad-paylogix-button',
  templateUrl: './ad-paylogix-button.component.html',
  styleUrls: ['./ad-paylogix-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdPaylogixButtonComponent {

  @Input() options: IAdPaylogixButtonOptions = {
    title: 'Complete Info',
    size: ButtonSize.Medium
  };

  @Input() public isSearchView = false;

  constructor() { }

  get buttonSize(): string {
    return this.options.size === ButtonSize.Medium ? 'paylogix-button--medium' : 'paylogix-button--large';
  }

}
