import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rental-car-mini',
  templateUrl: './rental-car-mini.component.html',
  styleUrls: ['./rental-car-mini.component.less']
})

export class RentalCarMiniComponent {

  @Output() clickOut = new EventEmitter<ClickOutModel>();

  model: CustomWidgetModel;

  rentalCarClickOut(data: ClickOutModel): void {
    this.clickOut.emit(data);
  }

}
