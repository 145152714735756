<ng-container>
  <p class='email-confirmation__title'>
    <img class="email-confirmation__image"
         [src]="modalContent?.icon || 'assets/images/hands-up.png'"
         alt="" />{{ modalContent?.title }}
  </p>
  <p class='email-confirmation__note'
     [innerHTML]="modalContent?.content | safeExceptJavascript"></p>
  <button class='btn btn-primary email-confirmation__button'
          (click)="onCloseClick($event)">
    {{ modalContent?.buttonTitle }}
  </button>

  <p *ngIf="isShowLink" class='email-confirmation__link'
     (click)="onLinkClick()">
    <span>{{modalContent.link_txt}}</span>
  </p>
</ng-container>
