<div class="modal-container">
  <div class="close-modal">
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()">
    </button>
  </div>

  <h3 class="modal-title">Please enter the code that we just sent to:</h3>

  <app-otp-form [type]="type"
                [contact]="contact"
                [sendImmediately]="sendImmediately"
                [isContactEditable]="isContactEditable"
                (onValidCode)="onFormCodeValid($event)">
  </app-otp-form>

</div>
