import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorConverterService {

  getRGBAColor(hex: string, alpha: number): string {
    const r: number = parseInt(hex.slice(1, 3), 16);
    const g: number = parseInt(hex.slice(3, 5), 16);
    const b: number = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
