import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ZoneHeader, ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

@Component({
  selector: 'app-zone-header',
  templateUrl: './zone-header.component.html',
  styleUrls: ['./zone-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneHeaderComponent implements OnInit, AfterViewInit {

  @Input() data: ZoneSectionElement;
  @Input() groupTabIndex: number;

  @Output() showAllClick = new EventEmitter<string>();

  zoneHeader: ZoneHeader;

  constructor(
    private zoneHeaderService: ZoneHeaderService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.zoneHeader = this.zoneHeaderService.getZoneHeaderFromZoneSection(this.data);
    }
  }

  ngAfterViewInit(): void {
    if (this.zoneHeader) {
      this.analyticsService.eventsTrack(this.zoneHeaderService.getAnalyticsEvents('displayed', this.zoneHeader));
    }
  }

  handleShowAllClick($event: { preventDefault: () => void }): void {
    $event.preventDefault();
    if (this.zoneHeader) {
      this.analyticsService.eventsTrack(this.zoneHeaderService.getAnalyticsEvents('click-all', this.zoneHeader));
    }
    this.showAllClick.emit(this.zoneHeader.url);
  }

  getUrlText(): string {
    return this.zoneHeader && this.zoneHeader.urlText || `All ${this.zoneHeader.name} offers`;
  }
}
