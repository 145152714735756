/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { WINDOW } from 'g3-common-ui';

@Component({
  selector: 'app-coupon-copy-code',
  templateUrl: './coupon-copy-code.component.html',
  styleUrls: ['./coupon-copy-code.component.less']
})
export class CouponCopyCodeComponent implements OnInit {

  @Input() public code = '';
  @Input() public buttonText = 'copy';
  @Input() public groupTabIndex: number;
  @Input() public disableCopyButton = false;
  @Input() public useMobileAdaptedView = true;

  @Output() public codeCopied = new EventEmitter<void>();

  @HostBinding('class.use-mobile-adapted-view')
  public copyButtonClicked: boolean;

  public copiedTemplate = '<img src="/assets/icons/icn-copy-code.svg" alt="">';

  private timeoutId = null;
  private initCopyButtonText = '';

  constructor(@Inject(WINDOW) private window: WINDOW) {
  }

  public ngOnInit(): void {
    this.initCopyButtonText = this.buttonText;
  }

  public copyToClipBoard(inputElement: HTMLElement): void {
    if (this.timeoutId) {
      this.window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    this.window.getSelection().removeAllRanges();

    const emailLink = inputElement;
    const range = this.window.document.createRange();

    range.selectNode(emailLink);
    this.window.getSelection().addRange(range);

    try {
      this.window.document.execCommand('copy');
      this.copyButtonClicked = true;
    } catch (err) {
      console.warn('Oops, unable to copy');
    }

    this.timeoutId = this.window.setTimeout(() => {
      this.window.getSelection().removeRange(range);
      this.copyButtonClicked = false;
      this.buttonText = this.initCopyButtonText;
    }, 1800);

    this.codeCopied.emit();
  }
}
