<span
  appBadge
  [badgeType]="badgeType"
  [title]="title"
  [withRightCorners]="withRightCorners"
  [style.z-index]="badgeIndex"
  aria-label="new offer"
  data-qa="badge-container"
  [ngClass]="badgeSpecificClass"
>
  <span
    class="triangle-left"
    [class.triangle-left-two-lines]="isTwoLine"
    *ngIf="isType(offerBadgeType.TodayDeal)"
  ></span>
  <img *ngIf="iconUrl"
       [src]="iconUrl"
       alt=""
       data-qa="client-offer-badge-icon">
  {{ getTitle() }}
  <div *ngIf="isType(offerBadgeType.Loyalty)" class="loyalty-badge-container loyalty-badge-container--default">
    <div data-qa="badge-loyalty-image" class="loyalty-image"></div>
  </div>
</span>
