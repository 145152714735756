export const ENROLLED_TITLES = {
  NOT_ENROLLED_MD: 'Enroll now',
  NOT_ENROLLED_XS: 'Enroll',
  ENROLLED_MD: 'Manage',
  ENROLLED_XS: 'Manage'
};

export const ENROLLED_TITLES_DETAILS_PAGE = {
  NOT_ENROLLED: 'Get Offer',
  ENROLLED: 'Manage'
};
