import { Brand } from '@app/shared/services/search/search.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-brand-item',
  templateUrl: './brand-item.component.html',
  styleUrls: ['./brand-item.component.less']
})
export class BrandItemComponent implements OnInit {

  @Input() brand: Brand = { title: null, logo: null, count: null, offer_guid: null };
  @Input() rowMode = false;

  @Output() brokenImageUrl: EventEmitter<string> = new EventEmitter();

  mouseJustLeft = false;

  ngOnInit(): void {
    if (this.brand.title === '2920 Sleep') {
      this.brand.logo = 'asdf/asdf';
    }
  }

  onMouseLeave(): void {
    this.mouseJustLeft = true;
  }

  emitBrokenImageUrl(): void {
    this.brokenImageUrl.emit(this.brand.title);
  }

}
