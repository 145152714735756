<section class="pinned-zone"
         [style.background]="backgroundColor | safe: 'style'"
         [class.tdd-scroll]="zoneElement && zoneElement.type && zoneElement.type === zoneElementTypes.TddScroll"
         *ngIf="activatedRoute.snapshot.queryParams.zone_id && zoneElement && zoneElement.type">
  <div class="pinned-zone-container">
    <app-comment [contents]="'zone_name: ' + zoneElement.name + ', zone_id=' + zoneElement.guid"></app-comment>
    <app-tdd-scroll *ngIf="zoneElement.type === zoneElementTypes.TddScroll"
                    [hideGhostEffect]="hideGhostEffect"
                    [isDesktop]="isDesktop"
                    [isPinnedZone]="isPinnedZone"
                    [data]="zoneElement"
                    [groupTabIndex]="1000"
                    [wideContainerClass]="wideContainerClass"
                    [wideContainerClassName]="wideContainerClassName">
    </app-tdd-scroll>
    <app-cards-scroll *ngIf="zoneElement.type === zoneElementTypes.CardsScroll || zoneElement.type === zoneElementTypes.CardsFixed"
                      [hideGhostEffect]="hideGhostEffect"
                      [isPinnedZone]="isPinnedZone"
                      [isDesktop]="isDesktop"
                      [data]="zoneElement"
                      [groupTabIndex]="1000"
                      [wideContainerClass]="wideContainerClass"
                      [wideContainerClassName]="wideContainerClassName"
                      (displayed)="onDisplayedElement($event)"
                      (initialized)="onInitElement($event)">
    </app-cards-scroll>
    <app-cards-product *ngIf="zoneElement.type === zoneElementTypes.CardsProduct"
                       [hideGhostEffect]="hideGhostEffect"
                       [isPinnedZone]="isPinnedZone"
                       [isDesktop]="isDesktop"
                       [data]="zoneElement"
                       [groupTabIndex]="1000"
                       [wideContainerClass]="wideContainerClass"
                       [wideContainerClassName]="wideContainerClassName"
                       (displayed)="onDisplayedElement($event)"
                       (initialized)="onInitElement($event)">
    </app-cards-product>
  </div>
</section>
