import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable()
export class UserContactsService {

  public constructor(private readonly http: HttpClient) { }

  public async setUserContactSignUpEvent(mreg: string, userGuid = '', email = ''): Promise<void> {
    const data: { mreg: string; userGuid?: string; email?: string } = { mreg };

    if (userGuid && email) {
      data.userGuid = userGuid;
      data.email = email;
    }

    return this.http.patch<void>(`${environment.apiUrl}/api/user/mreg-sign-up-event`, data).toPromise();
  }
}
