/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
import { Injectable } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { OffersService } from '@shared/services/offers.service';
import { SearchOfferForAdsInterface } from '@offers/services/search-offer-item.interface';
import { Takeover } from '@offers/ad-takeover/ad-takeover.component';
import { Ad, AdSource } from '@shared/models/ad.model';
import { SearchResultsAdsModel } from '@shared/models/search-results-ads.model';
import { TodayDeal } from '@shared/models/today-deal.model';
import { SearchOfferItem, AccessSettingOption } from '@shared/interfaces/search-offer-item.interface';
import { ConfigService } from '@shared/services/config.service';
import { TokensService } from '@shared/services/tokens.service';
import { PermissionService } from '@app/core/auth/services/permission.service';
import {
  SearchAdsItemResponse,
  SearchAdsResponse
} from '@shared/services/ads/search-ads-items-response.interface';
import { CONTENT_SOURCE_OFF_INHERIT, SearchAdsService } from '@shared/services/ads/search-ads.service';
import { CODE_INSTRUCTIONS_DEFAULT, OfferModel } from '@shared/models/offer.model';
import { PlatformService } from 'g3-common-ui';

const isAdWithOffer = ad => ad.content_source === CONTENT_SOURCE_OFF_INHERIT && ad.content_guid;

const getContentDataOption = (item, option, defaultValue) => item.content_data && typeof item.content_data.getOption === 'function' ? item.content_data.getOption(option, defaultValue) : defaultValue;
const getContentDataProperty = (item, option, defaultValue) => item.content_data && item.content_data.hasOwnProperty(option) ? item.content_data[option] : defaultValue;

const hasDestinationUrl = (item: Partial<SearchAdsItemResponse>): boolean => !!getContentDataOption(item, 'off_destination_url', '') || getContentDataProperty(item, 'has_destination_url', false);

@Injectable({
  providedIn: 'root'
})
export class AdsOffersService {

  private _appendUrl = '';
  private _appendInternalUrl = '';
  private _appendG3Url = '';

  constructor(
    private offersService: OffersService,
    private configService: ConfigService,
    private searchAdsService: SearchAdsService,
    private tokensService: TokensService,
    private permissionService: PermissionService,
    private platformService: PlatformService
  ) { }

  get appendUrl(): string {
    return this._appendUrl;
  }

  set appendUrl(url: string) {
    this._appendUrl = url;
  }

  get appendInternalUrl(): string {
    return this._appendInternalUrl;
  }

  set appendInternalUrl(url: string) {
    this._appendInternalUrl = url;
  }

  get appendG3Url(): string {
    return this._appendG3Url;
  }

  set appendG3Url(url: string) {
    this._appendG3Url = url;
  }

  removeAppendUrl(): void {
    this.appendUrl = '';
    this._appendG3Url = '';
    this._appendInternalUrl = '';
  }

  getOfferGUIDsFromAds(adsResponse: SearchAdsResponse): string[] {
    return adsResponse.items
      .filter(i => isAdWithOffer(i))
      .map(i => i.content_guid);
  }

  async getAds(searchAdsResponse: SearchAdsResponse, useImageSelection = false): Promise<SearchAdsResponse> {
    // get ads with offers info
    const adsWithOffersInfo = await this.getAdsWithOffersInfo(searchAdsResponse, useImageSelection);

    // get ads that don't have connection with offers
    const adsWithoutOffers = searchAdsResponse.items.filter(ad => !isAdWithOffer(ad));

    const combinedAdsItems = [...adsWithOffersInfo.items, ...adsWithoutOffers];

    // we need to return result in the same order
    const items = searchAdsResponse.items
      .map(item => combinedAdsItems.find(j => j.response_id === item.response_id))
      .filter(i => !!i)
      .map(i => this.searchAdsService.overrideContentDataByAdContent(i));

    return { items };
  }

  async getOffersByIds(guids, isDynamicData = false, useImageSelection = false) {
    return this.offersService.getOffersByIds({
      ids: guids,
      isDynamicData,
      onlyAvailable: true,
      useImageSelection,
    });
  }

  async getAdsWithOffersInfo(searchAdsResponse: SearchAdsResponse, useImageSelection = false): Promise<SearchAdsResponse> {

    let offersForAdsAsMap: Map<string, SearchOfferForAdsInterface>;

    const inheritedAds = this.getOfferGUIDsFromAds(searchAdsResponse);

    if (inheritedAds.length > 0) {
      const isKnown = this.permissionService.hasDefined('known:access');
      const isGuest = this.permissionService.hasDefined('guest:access');

      const offersForAds: SearchOfferForAdsInterface[] = await this.getOffersByIds(inheritedAds, false, useImageSelection);

      offersForAdsAsMap = offersForAds
        .filter(offer => {
          // BSS site_tags filter is overrided by offer's site_tags
          const isOfferValid = this.isOfferValidForPlatformSiteTag(offer.site_tags);
          const isRestrictedForKnownUser = isKnown && offer.access_setting === AccessSettingOption.HiddenToGuestKnown;

          const isRestrictedForGuestUser = isGuest && [
            AccessSettingOption.HiddenToGuest,
            AccessSettingOption.HiddenToGuestKnown].indexOf(offer.access_setting) !== -1;
          const isRestrictedByAuthMode = isGuest
            && this.configService.getConfig().sign_in_mode === 'unseeded_pass_autoguest'
            && offer.access_setting === AccessSettingOption.PromptForAuth;

          return !isRestrictedForKnownUser && !isRestrictedForGuestUser && !isRestrictedByAuthMode && isOfferValid;
        })
        .reduce((r, i) => {
          r.set(i.guid, i); return r;
        }, new Map());
    }

    const items = searchAdsResponse.items
      // override by offer's data
      .map(ad => isAdWithOffer(ad) && offersForAdsAsMap.has(ad.content_guid)
        ? this.overrideContentDataByOfferData(ad, offersForAdsAsMap.get(ad.content_guid))
        : null)
      .filter((i: SearchAdsItemResponse) => !!i)
      // override by ad's data
      .map((i: SearchAdsItemResponse) => this.searchAdsService.overrideContentDataByAdContent(i));

    return { items };
  }

  // TODO Migrate return object to Partial<SearchAdsItemResponse>
  public overrideContentDataByOfferData(ad: Partial<SearchAdsItemResponse>, offer?: Partial<SearchOfferForAdsInterface>): SearchAdsItemResponse {

    if (!offer) {
      ad.content_data.is_allowed_destination_url = true;
      ad.content_data.is_saved = false;
      return ad as SearchAdsItemResponse;
    }

    const adUrlOption = this.searchAdsService.getContentBySubdomain(ad, this.configService.getOption('subdomain'))
      .find(i => i.key === 'off_destination_url');

    if (!adUrlOption || !adUrlOption.value) {
      ad.isEbg = this.tokensService.isTokenPresent(offer.destinationUrl, 'ebghost');
    }

    // these offer's options can be changed via user
    [
      { option: 'off_header_short', key: 'title_short', },
      { option: 'off_header_long', key: 'title' },
      { option: 'off_vendor_brand', key: 'vendor' },
      { option: 'off_description_search', key: 'description_search' },
      { option: 'off_logo_vendor_square', key: 'vendor_image' },
      { option: 'off_button', key: 'ctaButtonTitle' },
      { option: 'off_terms', key: 'terms' },
      { option: 'off_destination_url', key: 'destinationUrl' },
      { option: 'off_code', key: 'code' },
      { option: 'off_phone', key: 'phone' },
      { option: 'off_phone_note', key: 'phone_note' },
      { option: 'off_phone_action', key: 'phone_action' },
      { option: 'off_description_details', key: 'descriptionDetails' },
      { option: 'off_image_sq', key: 'image_square' },
      { option: 'off_instruction_text', key: 'instruction_text' },
      { option: 'off_code_instructions', key: 'code_instructions' },
      { option: 'off_image_01', key: 'image' },
      { option: 'off_brand_override', key: 'brand_override' },
      { option: 'off_product_sub_heading', key: 'product_sub_heading' },
      { option: 'off_description_disclaimer', key: 'description_disclaimer' },
      { option: 'off_external_source', key: 'external_source' },
      { option: 'off_external_data', key: 'external_data' },
      { option: 'off_is_widget_iframe', key: 'is_widget_iframe' },
      { option: 'off_is_widget_disclaimer', key: 'is_widget_disclaimer' },
      { option: 'off_widget_code', key: 'widget_code' },
      { option: 'off_widget_disclaimer', key: 'external_data' }
    ].forEach(item => {
      if (offer[item.key]) {
        ad.content_data.setOption(item.option, offer[item.key]);
      }
    });

    ad.content_data.setOption('off_destination_url_new_tab', !isNil(offer.destination_url_new_tab) ? offer.destination_url_new_tab : true);
    ad.content_data.setOption('off_coupon_hide', offer.is_coupon_hidden || false);

    // these options are generated automatically
    ad.content_data.is_allowed_destination_url = offer.isAllowedDestinationUrl !== undefined ? offer.isAllowedDestinationUrl : true;
    ad.content_data.is_saved = offer.is_saved || false;
    ad.content_data.views = offer.views || 0;
    ad.content_data.extra_status = offer.extra_status || '';
    ad.content_data.url_type = offer.urlType || '';
    ad.content_data.has_destination_url = offer.hasDestinationUrl;
    ad.content_data.class = offer.class;
    ad.content_data.end_date = offer.end_date ? new Date(offer.end_date) : undefined;
    ad.content_data.cat_code_tag_ids = offer.cat_code_tag_ids || [];
    ad.content_data.access_setting = offer.access_setting;
    ad.content_data.source_based_params = offer.source_based_params || [];
    ad.content_data.paylogix_enrolled_header_short = offer.paylogix_enrolled_header_short || '';
    ad.content_data.paylogix_subscription = offer.paylogixSubscription || undefined;
    ad.content_data.is_widget_iframe = offer.is_widget_iframe || false;
    ad.content_data.is_widget_disclaimer = offer.is_widget_disclaimer || false;
    ad.external_data = offer.external_data || null;

    return ad as SearchAdsItemResponse;
  }

  getCatCodeTagIdsFromSearchAd(searchAd: Partial<SearchAdsItemResponse>): number[] {
    const catCodeTagIds = searchAd ? getContentDataProperty(searchAd, 'cat_code_tag_ids', []) : [];

    if (searchAd && searchAd.cat_code_tag_ids) {
      catCodeTagIds.push(...searchAd.cat_code_tag_ids);
    }

    // filter catCodeTagIds array from duplicates
    return catCodeTagIds.filter((value, i, arr) => arr.indexOf(value) === i);
  }

  getTakeoverFromSearchAd(searchAd: Partial<SearchAdsItemResponse>): Takeover {
    if (!searchAd.content_data) {
      return null;
    }

    const takeOver = {} as Takeover;
    if (searchAd.content_data.getOption('image_850', '')) {
      takeOver.srcLarge = searchAd.content_data.getOption('image_850', '');
    }
    if (searchAd.content_data.getOption('image_720', '')) {
      takeOver.srcMedium = searchAd.content_data.getOption('image_720', '');
    }
    if (searchAd.content_data.getOption('image_500', '')) {
      takeOver.srcSmall = searchAd.content_data.getOption('image_500', '');
    }
    if (searchAd.content_data.getOption('alt_text', '')) {
      takeOver.altText = searchAd.content_data.getOption('alt_text', '');
    }

    return isEmpty(takeOver) ? null : takeOver;
  }

  // content_data is from overrideContentDataByOfferData method
  getAdFromSearchAdsItem(item: Partial<SearchAdsItemResponse>, priorityMultiplier = 1): Ad {
    let priority = item && item.priority || 1;
    if (isNaN(priority)) {
      priority = 1;
    }

    return new Ad({
      guid: item && item.guid || '',
      contentGuid: item && item.content_guid || '',
      id: item && item.response_id || '',
      endDate: item && item.content_data && item.content_data.end_date ? item.content_data.end_date : null,
      isGeneratedAd: true,
      is_saved: getContentDataProperty(item, 'is_saved', false),
      title: getContentDataOption(item, 'off_header_long', ''),
      titleShort: getContentDataOption(item, 'off_header_short', ''),
      descriptionDetails: getContentDataOption(item, 'off_description_details', ''),
      destinationUrl: getContentDataOption(item, 'off_destination_url', ''),
      vendorImage: getContentDataOption(item, 'off_logo_vendor_square', ''),
      company: getContentDataOption(item, 'off_vendor_brand', ''),
      dbImageUrl: item.image_url ||  getContentDataOption(item, 'off_image_01', ''),
      imageSource: item.image_source || '',
      code: getContentDataOption(item, 'off_code', ''),
      instruction_text: getContentDataOption(item, 'off_instruction_text', ''),
      code_instructions: getContentDataOption(item, 'off_code_instructions', CODE_INSTRUCTIONS_DEFAULT),
      ctaButtonTitle: getContentDataOption(item, 'off_button', ''),
      phone: getContentDataOption(item, 'off_phone', ''),
      phone_note: getContentDataOption(item, 'off_phone_note', ''),
      phone_action: getContentDataOption(item, 'off_phone_action', ''),
      imageSquare: getContentDataOption(item, 'off_image_sq', ''),
      views: getContentDataProperty(item, 'views', 0),
      isAllowedDestinationUrl: getContentDataProperty(item, 'is_allowed_destination_url', true),
      isEbg: item.isEbg,
      hasDestinationUrl: hasDestinationUrl(item),
      urlType: getContentDataProperty(item, 'url_type', 'static'),
      extraStatus: getContentDataProperty(item, 'extra_status', ''),
      class: getContentDataProperty(item, 'class', ''),
      priority: priority * priorityMultiplier || 1,
      source: AdSource.BSS,
      content: this.searchAdsService.getContentBySubdomain(item, this.configService.getOption('subdomain')),
      responseId: item.response_id,
      catCodeTagIds: this.getCatCodeTagIdsFromSearchAd(item),
      destination_url_new_tab: item && item.content_data && item.content_data.data
        ? SearchResultsAdsModel.getDestinationUrlNewTab(item.content_data.data['off_destination_url_new_tab'], true)
        : true,
      brandOverride: item.content_data ? item.content_data.getOption('off_brand_override', '') : '',
      brand_name: item.brand_name || '',
      productSubHeading: item.content_data ? item.content_data.getOption('off_product_sub_heading', '') : '',
      headerShort: getContentDataOption(item, 'off_header_short', ''),
      descriptionDisclaimer: getContentDataOption(item, 'off_description_disclaimer', ''),
      append_url_params: item.append_url_params || '',
      isAuthRequired: this.permissionService.hasAnyDefined(['guest:access', 'known:access']) && item.content_data.access_setting === AccessSettingOption.PromptForAuth,
      source_based_params: item.source_based_params ? item.source_based_params : item.content_data && item.content_data.source_based_params || [],
      campaign: item.campaign || '',
      external_source: getContentDataOption(item, 'off_external_source', ''),
      external_data: item.external_data || getContentDataOption(item, 'off_external_data', ''),
      paylogix_enrolled_header_short: item.content_data && item.content_data.paylogix_enrolled_header_short || '',
      is_widget_iframe: item.content_data && item.content_data.is_widget_iframe || '',
      is_widget_disclaimer: item.content_data && item.content_data.is_widget_disclaimer || '',
      paylogixSubscription: getContentDataProperty(item, 'paylogix_subscription', null),
      append_internal_url_params: item.append_internal_url_params || '',
      append_g3_url_params: item.append_g3_url_params || '',
    });
  }

  getAdFromSearchResultsAdsItem(item: SearchResultsAdsModel, priorityMultiplier = 1): Ad {

    return new Ad({
      guid: item && item.guid || '',
      contentGuid: item && item.content_guid || '',
      id: item && item.response_id || '',
      endDate: item && item.endDate || null,
      isGeneratedAd: true,
      is_saved: item.isSaved,
      title: item.headerLong,
      titleShort: item.headerShort,
      descriptionDetails: item.descriptionDetails,
      destinationUrl: item.destinationUrl,
      vendorImage: item.logoVendorSquare,
      company: item.vendorBrand,
      dbImageUrl: item.image,
      code: item.code,
      instruction_text: item.instruction_text,
      code_instructions: item.code_instructions,
      ctaButtonTitle: item.button,
      phone: item.phone,
      phone_note: item.phone_note,
      phone_action: item.phone_action,
      imageSquare: item.imageSquare,
      views: item.views,
      isAllowedDestinationUrl: item.isAllowedDestinationUrl,
      isEbg: item.isEbg,
      hasDestinationUrl: item.hasDestinationUrl,
      urlType: item.urlType,
      extraStatus: item.extraStatus,
      class: item.class,
      priority: 1,
      source: AdSource.BSS,
      content: this.searchAdsService.getContentBySubdomain(item, this.configService.getOption('subdomain')),
      responseId: item.response_id,
      catCodeTagIds: this.getCatCodeTagIdsFromSearchAd(item),
      destination_url_new_tab: item.destinationUrlNewTab,
      brandOverride: item.brandOverride,
      productSubHeading: item.productSubHeading,
      headerShort: item.headerShort,
      descriptionDisclaimer: item.descriptionDisclaimer,
      append_url_params: item.urlParams || '',
      isAuthRequired: this.permissionService.hasAnyDefined(['guest:access', 'known:access']) && item.access_setting === AccessSettingOption.PromptForAuth,
      source_based_params: item.source_based_params,
      campaign: item.campaign || '',
      external_source: getContentDataOption(item, 'off_external_source', ''),
      external_data: getContentDataOption(item, 'off_external_data', ''),
      paylogix_enrolled_header_short: item.paylogix_enrolled_header_short,
      is_widget_iframe: item.is_widget_iframe,
      is_widget_disclaimer: item.is_widget_disclaimer,
      append_internal_url_params: item.internalUrlParams || '',
      append_g3_url_params: item.g3UrlParams || '',
    });
  }

  getAdFromSearchOfferItem(item: SearchOfferItem, priorityMultiplier = 1): Ad {
    return new Ad({
      adUrl: '',
      brandTagDisplay: item.brand_tag_display || '',
      company: item.vendor_brand && item.vendor_brand.title ? item.vendor_brand.title : '',
      dbImageUrl: item.images && item.images[0] ? item.images[0].url : '',
      endDate: item.end_date,
      guid: item.guid,
      id: item.guid,
      isGeneratedAd: true,
      is_saved: item.is_saved,
      title: item.header_long,
      titleShort: item.header_short,
      vendorImage: item.vendor_brand && item.vendor_brand.logo_square ? item.vendor_brand.logo_square : '',
      views: item.views,
      class: item.class,
      descriptionDetails: item.description_details,
      code: item.code,
      instruction_text: item.instruction_text,
      code_instructions: item.code_instructions,
      destinationUrl: item.destination_url,
      isAllowedDestinationUrl: item.is_allowed_url,
      hasDestinationUrl: item.has_destination_url,
      destination_url_new_tab: item.destination_url_new_tab,
      ctaButtonTitle: item.cta_button_title,
      urlType: item.destination_url_type,
      phone: item.phone,
      phone_note: item.phone_note,
      phone_action: item.phone_action,
      extraStatus: item.extra_status,
      priority: (item.priority || 0) * priorityMultiplier,
      source: AdSource.Natural,
      contentGuid: item.guid,
      catCodeTagIds: item.cat_code_tag_ids || [],
      isEbg: this.tokensService.isTokenPresent(item.destination_url, 'ebghost'),
      productSubHeading: item.product_sub_heading || '',
      brandOverride: item.brand_override || '',
      customProductSubHeadings: item.custom_product_sub_headings || null,
      vendorBrand: item.vendor_brand || null,
      descriptionDisclaimer: item.description_disclaimer || '',
      headerShort: item.header_short || '',
      append_url_params: item.append_url_params || '',
      isAuthRequired: this.permissionService.hasAnyDefined(['guest:access', 'known:access']) && item.access_setting === AccessSettingOption.PromptForAuth,
      access_setting: item.access_setting || '',
      source_based_params: item.source_based_params || [],
      external_data: item.external_data || null,
      external_source: item.external_source || '',
      paylogixSubscription: item.paylogixSubscription,
      paylogix_enrolled_header_short: item.paylogix_enrolled_header_short || '',
      append_internal_url_params: item.append_internal_url_params || '',
      append_g3_url_params: item.append_g3_url_params || '',
      is_widget_iframe: item.is_widget_iframe || false,
      is_widget_disclaimer: item.is_widget_disclaimer || false,
      widget_code: item.widget_code || '',
      widget_disclaimer: item.widget_disclaimer || '',
    });
  }

  getAdFromSearchOffer(item: OfferModel): Ad {
    return new Ad({
      adUrl: '',
      company: item.vendorBrand && item.vendorBrand.title ? item.vendorBrand.title : '',
      dbImageUrl: item.images && item.images[0] ? item.images[0].url : '',
      endDate: item.endDate,
      guid: item.guid,
      id: item.guid,
      isGeneratedAd: true,
      is_saved: item.is_saved,
      title: item.title,
      titleShort: item.subtitle,
      vendorImage: item.vendorBrand && item.vendorBrand.logoSquare ? item.vendorBrand.logoSquare : '',
      views: item.views,
      class: item.class,
      descriptionDetails: item.descriptionDetails,
      code: item.code,
      instruction_text: item.instruction_text,
      code_instructions: item.code_instructions,
      destinationUrl: item.destinationUrl,
      isAllowedDestinationUrl: item.is_allowed_url,
      hasDestinationUrl: item.hasDestinationUrl,
      destination_url_new_tab: item.destinationUrlNewTab,
      ctaButtonTitle: item.ctaButtonTitle,
      urlType: item.urlType,
      phone: item.phone,
      phone_note: item.phone_note,
      phone_action: item.phone_action,
      extraStatus: item.extraStatus,
      priority: 0,
      source: AdSource.Natural,
      contentGuid: item.guid,
      catCodeTagIds: item.catCodeTagIds || [],
      isEbg: this.tokensService.isTokenPresent(item.destinationUrl || item.url, 'ebghost'),
      productSubHeading: item.productSubHeading || '',
      brandOverride: item.brandOverride || '',
      customProductSubHeadings: item.customProductSubHeadings || null,
      vendorBrand: item.vendorBrand || null,
      descriptionDisclaimer: item.descriptionDisclaimer || '',
      headerShort: '',
      append_url_params: '',
      isAuthRequired: this.permissionService.hasAnyDefined(['guest:access', 'known:access']) && item.access_setting === AccessSettingOption.PromptForAuth,
      access_setting: item.access_setting || '',
      source_based_params: item.sourceBasedParams || [],
      external_data: item.external_data || null,
      external_source: item.external_source || '',
      paylogixSubscription: item.paylogixSubscription,
      paylogix_enrolled_header_short: item.paylogixEnrolledHeaderShort || '',
      append_g3_url_params: '',
      append_internal_url_params: ''
    });
  }

  getAdFromOfferTddItem(item: SearchOfferItem, priorityMultiplier = 1): Ad {
    return new Ad({
      guid: item?.guid || '',
      id: item?.guid || '',
      company: item?.vendor_brand.title || '',
      dbImageUrl: item?.images?.length && item.images[0].url || '',
      titleShort: item?.header_short || '',
      destinationUrl: item?.destination_url || '',
      code: item?.code || '',
      offerGroup: item?.tdd_offer_group || false,
      currentPrice: item?.tdd_curr_price || '',
      originalPrice: item?.tdd_orig_price || '',
      isPinned: item?.tdd_pinned || false,
      isEbg: item?.is_ebg || false,
      isNewTab : item?.destination_url_new_tab || false,
      tagText: item?.tdd_tag_text || '',
      priority: priorityMultiplier,
      source: AdSource.TDD,
      shopping_category: item?.shopping_category || '',
    });
  }

  getAdFromTddItem(item: TodayDeal, priorityMultiplier = 1): Ad {
    return new Ad({
      guid: item && item.guid || '',
      id: item && item.guid || '',
      company: item && item.company_name || '',
      vendorImage: item.company_brand_tag_logo || '',
      dbImageUrl: item && item.image_url || '',
      titleShort: item && item.headline || '',
      destinationUrl: item && item.url,
      views: item && item.views_total || '',
      code: item && item.coupon_code || '',
      offerGroup: item && item.offer_group || false,
      currentPrice: item && item.curr_price || '',
      originalPrice: item && item.orig_price || '',
      isPinned: item && item.hasOwnProperty('pinned') ? item.pinned : false,
      isEbg: item && item.hasOwnProperty('is_ebg') ? item.is_ebg : false,
      isNewTab : item && item.new_tab || false,
      tagText: item && item.tag_text || '',
      priority: priorityMultiplier,
      source: AdSource.TDD,
      shopping_category: item?.shopping_category,
    });
  }

  private isOfferValidForPlatformSiteTag(siteTags: string[]): boolean {
    const { SITE_TAG } = this.platformService.currentPlatform;
    return siteTags.some(siteTag => siteTag === SITE_TAG || siteTag === '*');
  }
}
