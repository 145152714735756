<ng-container *ngIf="topBrands?.length && zoneElement">
    <section class="top-brands-names scrollable_container container main-container wide-container"
      [attr.id]="anchorLink"
      [class.wide-endpoint]="isWideContainer()"
      (mouseenter)="hovered = true"
      (mouseleave)="hovered = false">
      <app-zone-header
        [data]="zoneElement"
        [groupTabIndex]="groupTabIndex"
        (showAllClick)="showAllClickHandler($event)">
      </app-zone-header>

      <app-homepage-horizontal-scroll [hideGhostEffect]="hideGhostEffect"
        [hideArrows]="hideArrows"
        [isDesktop]="isDesktop"
        [isBrandSection]="true"
        [hovered]="hovered">
        <app-brand-item-name [appAutoTabIndex]="2000"
          class="row-mode app-brand-item-name notranslate"
          *ngFor="let brand of topBrands; index as i;"
          [brand]="brand"
          (click)="onBrandClick(brand, $event)">
        </app-brand-item-name>
      </app-homepage-horizontal-scroll>
    </section>
  </ng-container>
