<ng-container *ngIf="displayModal">
  <div class="close-button-container">
    <button type="button" class="close" aria-label="Close" (click)="modalService.closeModal('dismiss')">
      <span aria-hidden="true" class="close-icon">&times;</span>
    </button>
  </div>

  <div class="modal-header">
    <div class="modal-header-small">{{configService.getOption('name')}}</div>

    <h1 class="offer-header">{{item?.header}}</h1>
  </div>

  <div class="modal-body">
    <div class="pass-thru-container" [class.code]="item?.code">
      <div class="pass-thru-image-wrapper">
        <app-logo></app-logo>
      </div>

      <img class="pass-thru-arrows" loading="lazy" src="/assets/pass-thru-arrows.gif" alt="" />

      <div class="pass-thru-image-wrapper">
        <div class="pass-thru-image-container" [style.background-image]="'url(' + item?.vendorLogoUrl + ')' | safe: 'style'">
          <p *ngIf="!item?.vendorLogoUrl" [title]="item?.brand" class="pass-thru-brand-name notranslate">{{ item?.brand }}</p>
        </div>
      </div>

    </div>

    <app-coupon-copy-code *ngIf="item?.code" [useMobileAdaptedView]="false" [code]="item.code" buttonText="COPY"
      (codeCopied)="trackEvent('pass-thru-copy-code');">
    </app-coupon-copy-code>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="goToSite()">
      GO TO SITE
      <img src="/assets/icons/external-source-link.svg" loading="lazy" alt="" />
    </button>

    <div class="disclaimer-text" *ngIf="item?.code">
      You may be prompted by your browser to accept clipboard-related permissions.
    </div>
  </div>

</ng-container>
