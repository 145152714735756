<ng-container>
  <div class="card-overlay__content_wrapper">
    <ng-content></ng-content>
  </div>
  <button type="button"
          class="card-overlay__close-button"
          aria-label="Close"
          (click)="onCloseClick($event)">
    <span aria-hidden="true"
          class="card-overlay__close-button_icon">&times;</span>
  </button>
</ng-container>
