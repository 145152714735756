export const illustrationsBg = (color: string): string => `
  <svg width="1920" height="438" viewBox="0 0 1920 438" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1814.25 348.04C1815.68 337.582 1800.93 328.984 1789.84 328.085C1784.55 327.662 1779.06 328.47 1774.05 326.725C1763.2 322.94 1758.86 308.914
      1748.27 304.448C1740.36 301.111 1731.25 304.076 1723.57 307.938C1715.9 311.801 1708.31 316.664 1699.73 317.216C1692.27 317.691 1684.16 314.932 1677.64 318.576C1672.78
      321.297 1670.24 326.892 1665.75 330.203C1661.36 333.437 1655.65 334.117 1650.21 334.412C1644.77 334.707 1639.13 334.758 1634.18 337.03C1629.23 339.301 1625.13 344.537
      1626.16 349.888L1814.25 348.04Z" fill="#EBEBEB" fill-opacity="0.6"/>
    <path d="M186.868 150.508C187.906 142.96 177.254 136.754 169.252 136.106C165.436 135.8 161.472 136.383 157.86 135.124C150.024 132.392 146.893 122.268 139.252
      119.045C133.538 116.637 126.962 118.776 121.423 121.564C115.884 124.352 110.41 127.862 104.214 128.261C98.8327 128.603 92.9791 126.612 88.274 129.242C84.7637
      131.206 82.9298 135.244 79.6881 137.634C76.5205 139.968 72.3989 140.459 68.4718 140.672C64.5447 140.885 60.4786 140.922 56.9035 142.561C53.3284 144.201 50.3738
      147.98 51.1147 151.842L186.868 150.508Z" fill="#EBEBEB" fill-opacity="0.5"/>
    <g opacity="0.8">
      <path d="M1209.46 111.718C1210.45 104.589 1200.37 98.7104 1192.81 98.1109C1189.2 97.8277 1185.45 98.3773 1182.03 97.1782C1174.62 94.5968 1171.68 85.0371 1164.45
        81.9893C1159.05 79.7076 1152.82 81.7395 1147.59 84.3709C1142.36 87.0023 1137.18 90.3166 1131.32 90.6996C1126.23 91.0327 1120.71 89.1341 1116.25 91.6323C1112.93
        93.4809 1111.19 97.3115 1108.14 99.5598C1105.14 101.758 1101.24 102.241 1097.53 102.441C1093.82 102.641 1089.97 102.674 1086.59 104.223C1083.21 105.772 1080.41
        109.353 1081.11 113L1209.46 111.718Z" fill="#F5F5F5"/>
    </g>
    <path d="M772.92 317.043C774.105 308.447 761.954 301.358 752.835 300.635C748.477 300.293 743.958 300.956 739.841 299.51C730.903 296.397 727.349 284.868 718.632
      281.193C712.125 278.441 704.613 280.892 698.307 284.065C692 287.238 685.754 291.235 678.684 291.697C672.539 292.099 665.891 289.809 660.508 292.822C656.511
      295.051 654.402 299.671 650.727 302.382C647.112 305.033 642.412 305.616 637.933 305.857C633.455 306.098 628.815 306.138 624.738 308.006C620.661 309.873 617.287
      314.191 618.13 318.59L772.92 317.043Z" fill="#EBEBEB" fill-opacity="0.5"/>
    <path d="M233.836 400.198C234.546 395.047 227.264 390.799 221.8 390.365C219.189 390.161 216.481 390.558 214.013 389.691C208.657 387.826 206.527 380.917 201.304
      378.715C197.404 377.066 192.903 378.534 189.124 380.436C185.345 382.338 181.602 384.733 177.365 385.01C173.682 385.25 169.699 383.878 166.473 385.683C164.078
      387.019 162.814 389.788 160.612 391.412C158.445 393.001 155.629 393.35 152.945 393.495C150.261 393.639 147.481 393.663 145.038 394.782C142.595 395.902 140.573
      398.489 141.078 401.125L233.836 400.198Z" fill="#F5F5F5" fill-opacity="0.8"/>
    <path d="M1876.85 104.073C1877.3 100.807 1872.69 98.1137 1869.22 97.839C1867.57 97.7093 1865.85 97.9611 1864.29 97.4117C1860.89 96.2291 1859.54 91.8495 1856.23
      90.4532C1853.76 89.4079 1850.9 90.3388 1848.51 91.5443C1846.11 92.7498 1843.74 94.2682 1841.05 94.4437C1838.72 94.5963 1836.19 93.7265 1834.15 94.871C1832.63
      95.7179 1831.83 97.4728 1830.43 98.5028C1829.06 99.51 1827.27 99.7312 1825.57 99.8228C1823.87 99.9144 1822.11 99.9296 1820.56 100.639C1819.01 101.349 1817.73
      102.989 1818.05 104.66L1876.85 104.073Z" fill="#F5F5F5"/>
    <path d="M1726.3 151.756C1724.45 156.38 1721.49 163.855 1720.14 167.289C1719.74 168.304 1720.23 169.448 1721.24 169.848L1733.88 174.881C1734.89 175.285 1736.04
      174.791 1736.44 173.776L1742.57 158.387C1743.47 156.137 1742.37 153.587 1740.12 152.69L1741.02 150.427L1732.75 147.132L1731.9 149.273L1731.86 149.26C1729.64
      148.456 1727.18 149.556 1726.3 151.756Z" fill="#F5F5F5"/>
    <path d="M1732.64 147.419L1732.22 148.451L1740.38 152.038L1740.91 150.714L1732.64 147.419Z" fill="#E0E0E0"/>
    <path d="M1740.82 151.445L1732.23 148.024C1731.78 147.849 1731.57 147.342 1731.74 146.897L1732.56 144.846C1732.83 144.154 1733.62 143.822 1734.31 144.096L1742.02
      147.167C1742.71 147.44 1743.04 148.222 1742.77 148.913L1741.95 150.965C1741.77 151.409 1741.27 151.625 1740.82 151.445Z" fill="#EBEBEB"/>
    <path d="M1732.72 148.24C1732.66 148.218 1732.98 147.279 1733.43 146.153C1733.88 145.021 1734.29 144.128 1734.35 144.15C1734.41 144.173 1734.1 145.107 1733.65
    146.238C1733.19 147.365 1732.78 148.263 1732.72 148.24Z" fill="#E0E0E0"/>
    <path d="M1733.81 148.671C1733.75 148.649 1734.07 147.715 1734.52 146.584C1734.97 145.452 1735.38 144.559 1735.44 144.581C1735.5 144.604 1735.19 145.538 1734.74
      146.669C1734.28 147.8 1733.87 148.698 1733.81 148.671Z" fill="#E0E0E0"/>
    <path d="M1734.9 149.107C1734.85 149.084 1735.16 148.146 1735.61 147.019C1736.06 145.892 1736.47 144.994 1736.53 145.017C1736.59 145.039 1736.28 145.973 1735.83
      147.104C1735.37 148.236 1734.96 149.129 1734.9 149.107Z" fill="#E0E0E0"/>
    <path d="M1735.99 149.542C1735.93 149.52 1736.25 148.581 1736.7 147.455C1737.14 146.323 1737.56 145.43 1737.62 145.452C1737.68 145.475 1737.36 146.409 1736.92
      147.54C1736.47 148.671 1736.05 149.565 1735.99 149.542Z" fill="#E0E0E0"/>
    <path d="M1737.08 149.978C1737.02 149.955 1737.34 149.017 1737.79 147.89C1738.23 146.759 1738.65 145.865 1738.71 145.888C1738.77 145.91 1738.45 146.844 1738.01
      147.975C1737.56 149.102 1737.14 150 1737.08 149.978Z" fill="#E0E0E0"/>
    <path d="M1738.17 150.409C1738.11 150.386 1738.43 149.448 1738.88 148.321C1739.33 147.19 1739.74 146.296 1739.8 146.319C1739.86 146.341 1739.55 147.275 1739.1
      148.406C1738.64 149.538 1738.23 150.436 1738.17 150.409Z" fill="#E0E0E0"/>
    <path d="M1739.26 150.844C1739.21 150.822 1739.52 149.883 1739.97 148.757C1740.42 147.625 1740.83 146.732 1740.89 146.754C1740.95 146.777 1740.64 147.711 1740.19
      148.842C1739.74 149.973 1739.32 150.867 1739.26 150.844Z" fill="#E0E0E0"/>
    <path d="M1740.35 151.278C1740.3 151.256 1740.61 150.322 1741.06 149.191C1741.51 148.059 1741.92 147.166 1741.98 147.188C1742.04 147.211 1741.73 148.145 1741.28
      149.276C1740.83 150.407 1740.41 151.301 1740.35 151.278Z" fill="#E0E0E0"/>
    <path d="M1735.83 167.221L1725.22 162.992C1724.88 162.857 1724.71 162.467 1724.84 162.125L1727.47 155.522C1727.61 155.18 1728 155.014 1728.34 155.149L1738.96
      159.378C1739.3 159.513 1739.47 159.903 1739.33 160.244L1736.7 166.848C1736.57 167.189 1736.17 167.356 1735.83 167.221Z" fill="#EBEBEB"/>
    <path d="M1735.87 166.611C1735.87 166.606 1735.89 166.561 1735.92 166.476C1735.95 166.377 1736 166.256 1736.05 166.108C1736.18 165.771 1736.36 165.304 1736.58
      164.725C1737.04 163.522 1737.7 161.83 1738.5 159.773L1738.55 159.895C1735.69 158.763 1732.08 157.331 1728.16 155.778C1728.12 155.791 1728.44 155.652 1728.31
      155.715V155.72L1728.3 155.729L1728.3 155.742L1728.29 155.769L1728.26 155.827L1728.22 155.94L1728.13 156.164L1727.95 156.613C1727.83 156.914 1727.71 157.21
      1727.59 157.502C1727.36 158.09 1727.13 158.669 1726.9 159.239C1726.45 160.375 1726.01 161.47 1725.59 162.512L1725.53 162.373C1728.55 163.598 1731.12 164.644
      1732.96 165.39C1733.87 165.762 1734.58 166.059 1735.1 166.274C1735.34 166.373 1735.53 166.454 1735.68 166.516C1735.8 166.575 1735.87 166.606 1735.87 166.611C1735.88
      166.615 1735.81 166.597 1735.68 166.548C1735.54 166.494 1735.35 166.422 1735.12 166.337C1734.61 166.139 1733.88 165.861 1732.99 165.515C1731.12 164.788 1728.51 163.764
      1725.44 162.57L1725.35 162.53L1725.39 162.431C1725.8 161.39 1726.23 160.29 1726.68 159.154C1726.91 158.584 1727.14 158.005 1727.37 157.417C1727.49 157.12 1727.61 156.824
      1727.73 156.528L1727.91 156.079L1728 155.854L1728.04 155.742L1728.07 155.684L1728.08 155.657V155.639L1728.08 155.63V155.625C1727.95 155.684 1728.27 155.544 1728.24
      155.558C1732.15 157.125 1735.76 158.575 1738.62 159.72L1738.7 159.755L1738.67 159.841C1737.82 161.906 1737.13 163.603 1736.63 164.811C1736.39 165.376 1736.21 165.83
      1736.07 166.157C1736.01 166.297 1735.96 166.409 1735.92 166.503C1735.89 166.579 1735.87 166.615 1735.87 166.611Z" fill="#E0E0E0"/>
    <path d="M1736.1 161.369C1736.08 161.427 1734.47 160.848 1732.52 160.072C1730.58 159.295 1729.01 158.617 1729.04 158.559C1729.06 158.5 1730.66 159.079 1732.61
      159.856C1734.56 160.633 1736.12 161.311 1736.1 161.369Z" fill="#E0E0E0"/>
    <path d="M1735.62 162.57C1735.6 162.628 1734 162.049 1732.05 161.273C1730.1 160.496 1728.54 159.818 1728.56 159.76C1728.58 159.701 1730.19 160.28 1732.13 161.057C1734.08
      161.829 1735.65 162.507 1735.62 162.57Z" fill="#E0E0E0"/>
    <path d="M1735.16 163.721C1735.14 163.779 1733.53 163.2 1731.59 162.423C1729.64 161.647 1728.08 160.969 1728.1 160.91C1728.12 160.852 1729.72 161.431 1731.67
      162.208C1733.62 162.984 1735.19 163.662 1735.16 163.721Z" fill="#E0E0E0"/>
    <path d="M1739.68 152.428C1739.65 152.486 1737.91 151.849 1735.78 151C1733.65 150.152 1731.95 149.416 1731.97 149.357C1731.99 149.299 1733.74 149.936 1735.86
      150.785C1737.99 151.633 1739.7 152.37 1739.68 152.428Z" fill="#E0E0E0"/>
    <path d="M1721.18 166.668C1721.16 166.664 1721.25 166.399 1721.43 165.923C1721.62 165.416 1721.88 164.742 1722.19 163.925C1722.84 162.242 1723.76 159.925 1724.79
      157.371C1725.3 156.096 1725.79 154.879 1726.24 153.77C1726.68 152.666 1727.21 151.678 1727.88 151.041C1728.55 150.399 1729.28 150.125 1729.79 150.067C1730.04
      150.031 1730.24 150.053 1730.37 150.067C1730.51 150.089 1730.58 150.107 1730.57 150.116C1730.57 150.143 1730.29 150.075 1729.8 150.161C1729.32 150.246 1728.62
      150.533 1728 151.162C1727.36 151.786 1726.87 152.751 1726.44 153.856C1725.99 154.969 1725.51 156.186 1725 157.461C1723.97 160.015 1723.03 162.322 1722.33
      163.993C1721.99 164.796 1721.71 165.461 1721.5 165.963C1721.31 166.421 1721.19 166.673 1721.18 166.668Z" fill="white"/>
    <path d="M1735.37 174.794C1735.36 174.808 1735.18 174.66 1734.87 174.39C1734.72 174.256 1734.53 174.09 1734.3 173.91C1734.07 173.731 1733.83 173.511 1733.53 173.3C1732.94
      172.855 1732.22 172.37 1731.38 171.881C1730.54 171.405 1729.59 170.925 1728.56 170.507C1727.52 170.099 1726.5 169.793 1725.57 169.564C1724.62 169.349 1723.77 169.201
      1723.04 169.124C1722.67 169.071 1722.35 169.057 1722.06 169.035C1721.76 169.008 1721.52 168.999 1721.31 168.99C1720.9 168.972 1720.67 168.954 1720.67 168.94C1720.67
      168.922 1720.89 168.909 1721.31 168.9C1721.51 168.891 1721.76 168.886 1722.06 168.904C1722.35 168.918 1722.68 168.918 1723.05 168.963C1723.78 169.021 1724.65 169.151
      1725.61 169.358C1726.56 169.578 1727.59 169.883 1728.64 170.296C1729.69 170.718 1730.65 171.207 1731.49 171.701C1732.32 172.209 1733.05 172.711 1733.62 173.174C1733.92
      173.394 1734.16 173.623 1734.38 173.816C1734.61 174.004 1734.79 174.179 1734.93 174.328C1735.23 174.615 1735.38 174.781 1735.37 174.794Z" fill="white"/>
    <path d="M676.767 128.469L648.931 141.868C648.499 142.076 647.982 141.893 647.777 141.462L629.086 102.642C628.878 102.211 629.061 101.693 629.492 101.488L657.329
      88.0861C657.76 87.8776 658.278 88.061 658.483 88.4924L677.17 127.315C677.378 127.743 677.199 128.261 676.767 128.469Z" fill="#E0E0E0"/>
    <path d="M656.884 89.3622L630.768 101.932L648.86 139.523L674.977 126.953L656.884 89.3622Z" fill="#FAFAFA"/>
    <path d="M649.409 93.6584C648.345 92.8352 646.907 92.6842 645.696 93.2702L644.894 91.6812C644.308 90.466 642.852 89.9555 641.637 90.5415C640.422 91.1275 639.911
      92.5835 640.497 93.7986L641.299 95.4667L640.742 95.7471C639.555 96.3403 638.8 97.5483 638.782 98.8748L638.771 99.7017L650.143 94.23L649.409 93.6584ZM643.341 93.9173C642.686
      94.23 641.903 93.9568 641.586 93.3025C641.274 92.6482 641.547 91.8645 642.201 91.5481C642.855 91.2354 643.639 91.5086 643.956 92.1629C644.268 92.8208 643.991 93.6045 643.341
      93.9173Z" fill="#EBEBEB"/>
    <path d="M643.01 102.6C643.032 102.646 641.464 103.448 639.509 104.39C637.553 105.332 635.95 106.058 635.928 106.011C635.906 105.965 637.474 105.163 639.43 104.221C641.382
      103.276 642.985 102.553 643.01 102.6Z" fill="#E0E0E0"/>
    <path d="M639.914 105.559C639.936 105.605 639.191 106.012 638.25 106.465C637.308 106.918 636.524 107.248 636.502 107.202C636.481 107.155 637.225 106.749 638.167 106.296C639.109
      105.843 639.893 105.512 639.914 105.559Z" fill="#E0E0E0"/>
    <path d="M658.152 100.878C658.174 100.924 653.781 103.085 648.338 105.706C642.895 108.327 638.466 110.412 638.444 110.365C638.423 110.318 642.816 108.158 648.259 105.537C653.702
      102.916 658.131 100.831 658.152 100.878Z" fill="#E0E0E0"/>
    <path d="M659.058 102.758C659.079 102.805 654.686 104.965 649.243 107.586C643.8 110.207 639.371 112.292 639.35 112.245C639.328 112.199 643.721 110.038 649.164 107.417C654.603
      104.796 659.036 102.711 659.058 102.758Z" fill="#E0E0E0"/>
    <path d="M659.964 104.638C659.986 104.684 655.592 106.845 650.149 109.466C644.707 112.087 640.278 114.172 640.256 114.125C640.234 114.078 644.628 111.918 650.07 109.297C655.51
      106.676 659.939 104.591 659.964 104.638Z" fill="#E0E0E0"/>
    <path d="M660.866 106.515C660.888 106.561 656.495 108.722 651.052 111.343C645.609 113.964 641.18 116.049 641.158 116.002C641.137 115.955 645.53 113.795 650.973 111.174C656.416
      108.557 660.845 106.468 660.866 106.515Z" fill="#E0E0E0"/>
    <path d="M661.773 108.395C661.794 108.442 657.401 110.603 651.958 113.223C646.515 115.844 642.086 117.929 642.065 117.882C642.043 117.836 646.436 115.675 651.879 113.054C657.318
      110.434 661.751 108.348 661.773 108.395Z" fill="#E0E0E0"/>
    <path d="M662.675 110.275C662.696 110.321 658.303 112.482 652.86 115.103C647.418 117.724 642.988 119.809 642.967 119.762C642.945 119.715 647.338 117.555 652.781 114.934C658.224
      112.313 662.653 110.228 662.675 110.275Z" fill="#E0E0E0"/>
    <path d="M653.637 116.71C653.644 116.72 653.511 116.8 653.267 116.929C652.986 117.073 652.637 117.253 652.224 117.465C651.34 117.91 650.114 118.511 648.751 119.165C647.389
      119.819 646.156 120.402 645.257 120.819C644.833 121.009 644.477 121.171 644.189 121.301C643.934 121.412 643.79 121.466 643.786 121.455C643.779 121.444 643.912 121.365
      644.157 121.236C644.437 121.092 644.786 120.912 645.199 120.7C646.084 120.254 647.31 119.654 648.672 119C650.031 118.345 651.268 117.763 652.166 117.346C652.591 117.155
      652.947 116.994 653.234 116.864C653.489 116.756 653.633 116.699 653.637 116.71Z" fill="#E0E0E0"/>
    <path d="M656.746 95.7763C656.767 95.823 656.081 96.2005 655.207 96.6211C654.334 97.0417 653.611 97.3437 653.589 97.297C653.568 97.2502 654.254 96.8727 655.128 96.4521C655.998
      96.0315 656.724 95.7295 656.746 95.7763Z" fill="#E0E0E0"/>
    <path d="M651.131 122.961C651.153 123.008 650.028 123.598 648.611 124.277C647.198 124.957 646.034 125.471 646.012 125.424C645.99 125.377 647.116 124.788 648.532 124.108C649.945
      123.429 651.106 122.915 651.131 122.961Z" fill="#E0E0E0"/>
    <path d="M652.123 124.587C652.145 124.633 650.998 125.23 649.564 125.924C648.126 126.614 646.943 127.139 646.921 127.092C646.9 127.046 648.046 126.449 649.484 125.755C650.915
      125.065 652.098 124.54 652.123 124.587Z" fill="#E0E0E0"/>
    <path d="M665.853 115.559C665.874 115.606 665.339 115.912 664.656 116.239C663.973 116.566 663.401 116.796 663.38 116.749C663.358 116.702 663.894 116.397 664.577 116.07C665.256
      115.743 665.828 115.516 665.853 115.559Z" fill="#E0E0E0"/>
    <path d="M666.676 117.056C666.698 117.102 666.162 117.408 665.479 117.735C664.796 118.062 664.224 118.292 664.203 118.246C664.181 118.199 664.717 117.893 665.4 117.566C666.083
      117.239 666.655 117.009 666.676 117.056Z" fill="#E0E0E0"/>
    <path d="M650.595 127.527C650.617 127.574 650.103 127.869 649.441 128.185C648.78 128.502 648.23 128.721 648.205 128.674C648.183 128.627 648.697 128.333 649.359 128.016C650.02
      127.7 650.574 127.481 650.595 127.527Z" fill="#E0E0E0"/>
    <path d="M667.624 118.806C667.646 118.853 667.265 119.083 666.769 119.321C666.272 119.558 665.855 119.712 665.834 119.666C665.812 119.619 666.193 119.389 666.689
      119.152C667.182 118.914 667.599 118.76 667.624 118.806Z" fill="#E0E0E0"/>
    <path d="M649.994 132.802C649.991 132.798 650.027 132.773 650.095 132.726C650.174 132.672 650.275 132.608 650.397 132.525C650.681 132.342 651.073 132.094 651.558
      131.781C652.583 131.137 654.017 130.239 655.732 129.16L655.815 129.11L655.851 129.2C656.038 129.681 656.074 130.249 655.897 130.785C655.732 131.317 655.322 131.82
      654.747 132.029C654.186 132.237 653.496 132.212 652.967 131.828C652.432 131.45 652.213 130.717 652.371 130.077C652.432 129.836 652.547 129.609 652.716 129.423C652.755
      129.376 652.802 129.333 652.849 129.293C652.896 129.254 652.942 129.214 653.011 129.182C653.075 129.149 653.183 129.139 653.262 129.189C653.338 129.236 653.374 129.3
      653.406 129.354C653.629 129.771 653.748 130.231 653.773 130.684C653.798 131.137 653.726 131.59 653.568 132C653.413 132.406 653.169 132.795 652.795 133.025C652.417
      133.266 651.921 133.219 651.58 132.996C651.231 132.78 650.983 132.453 650.861 132.094C650.627 131.367 650.775 130.663 650.872 130.066L650.893 129.929L650.987
      130.034C651.414 130.515 651.616 131.087 651.673 131.583C651.734 132.086 651.659 132.521 651.555 132.859C651.447 133.197 651.299 133.431 651.192 133.582C651.138
      133.657 651.087 133.708 651.059 133.744C651.026 133.78 651.012 133.798 651.008 133.794C650.983 133.78 651.285 133.503 651.483 132.838C651.576 132.507 651.641
      132.083 651.569 131.598C651.504 131.119 651.303 130.573 650.89 130.124L651.005 130.091C650.911 130.692 650.785 131.378 651.008 132.047C651.123 132.37 651.357
      132.669 651.666 132.859C651.975 133.05 652.385 133.086 652.705 132.881C653.029 132.68 653.259 132.324 653.399 131.939C653.546 131.554 653.611 131.127 653.589
      130.699C653.568 130.271 653.453 129.836 653.244 129.448C653.219 129.401 653.19 129.365 653.169 129.354C653.147 129.343 653.129 129.34 653.09 129.358C653.018
      129.397 652.921 129.476 652.856 129.552C652.712 129.71 652.608 129.911 652.554 130.124C652.41 130.699 652.608 131.349 653.075 131.68C653.539 132.018 654.172
      132.05 654.682 131.86C655.2 131.673 655.574 131.224 655.732 130.731C655.897 130.239 655.872 129.707 655.7 129.261L655.818 129.3C654.089 130.354 652.644 131.234
      651.609 131.864C651.109 132.162 650.713 132.399 650.422 132.572C650.293 132.644 650.192 132.705 650.106 132.752C650.038 132.784 649.998 132.805 649.994 132.802Z" fill="#E0E0E0"/>
    <path d="M668.663 123.738C668.671 123.742 668.66 123.86 668.602 124.076C668.574 124.184 668.527 124.317 668.441 124.457C668.351 124.597 668.203 124.737 667.988
      124.802C667.883 124.831 667.758 124.834 667.639 124.791C667.52 124.748 667.42 124.658 667.34 124.558C667.186 124.349 667.078 124.115 666.938 123.871C666.668
      123.375 666.366 122.828 666.043 122.239L666.208 122.199C666.179 123.023 666.147 123.925 666.115 124.867C666.107 125.09 666.097 125.313 666.089 125.532L666.075
      125.902L665.913 125.568C665.421 124.558 664.964 123.616 664.562 122.793L664.713 122.778C664.432 123.792 664.375 124.673 664.371 125.287C664.371 125.902 664.414
      126.251 664.393 126.255C664.385 126.255 664.364 126.168 664.342 126.006C664.321 125.841 664.292 125.6 664.281 125.291C664.256 124.673 664.292 123.778 664.562
      122.739L664.615 122.53L664.713 122.721C665.119 123.54 665.586 124.479 666.086 125.485L665.91 125.521C665.917 125.302 665.928 125.083 665.935 124.856C665.971
      123.914 666.007 123.015 666.043 122.189L666.057 121.865L666.208 122.149C666.521 122.742 666.812 123.296 667.078 123.792C667.211 124.036 667.319 124.284 667.452
      124.468C667.585 124.658 667.79 124.741 667.973 124.687C668.354 124.586 668.487 124.241 668.559 124.047C668.62 123.849 668.653 123.734 668.663 123.738Z" fill="#E0E0E0"/>
    <path d="M668.625 122.39C668.653 122.433 667.169 123.494 665.31 124.756C663.451 126.021 661.92 127.01 661.891 126.967C661.862 126.923 663.343 125.863 665.206
      124.601C667.064 123.339 668.596 122.347 668.625 122.39Z" fill="#E0E0E0"/>
    <path d="M670.447 122.707C670.48 122.747 669.394 123.735 668.017 124.918C666.64 126.101 665.501 127.028 665.465 126.989C665.432 126.949 666.518 125.961 667.895
      124.778C669.272 123.595 670.415 122.667 670.447 122.707Z" fill="#E0E0E0"/>
    <path d="M1214.95 361.424L1191.99 352.739C1190.4 352.139 1189.6 350.364 1190.2 348.778L1202.22 316.987C1202.82 315.398 1204.6 314.599 1206.18 315.2L1220.75
      320.708L1227.63 336.594L1218.92 359.636C1218.31 361.225 1216.54 362.024 1214.95 361.424Z" fill="#F5F5F5"/>
    <path d="M1220.75 320.707L1217.37 329.655C1216.81 331.13 1217.55 332.781 1219.03 333.337L1227.63 336.593L1220.75 320.707Z" fill="#EBEBEB"/>
    <path d="M1217.19 330.485C1217.19 330.485 1216.25 332.614 1218.45 334.259C1218.54 334.323 1218.62 334.376 1218.71 334.43C1219.76 335.055 1226.66 339.167 1226.72
      338.997C1226.79 338.814 1227.63 336.593 1227.63 336.593L1219.03 333.34C1219.03 333.34 1216.91 332.456 1217.19 330.485Z" fill="#E0E0E0"/>
    <path d="M1215.58 328.562C1215.53 328.676 1213.12 327.87 1210.19 326.762C1207.25 325.653 1204.91 324.658 1204.95 324.545C1205 324.431 1207.41 325.236 1210.35
      326.345C1213.28 327.453 1215.62 328.448 1215.58 328.562Z" fill="#E0E0E0"/>
    <path d="M1220.53 336.218C1220.48 336.332 1216.53 334.945 1211.7 333.12C1206.87 331.291 1203 329.718 1203.04 329.601C1203.08 329.488 1207.03 330.874 1211.86
      332.7C1216.69 334.528 1220.57 336.101 1220.53 336.218Z" fill="#E0E0E0"/>
    <path d="M1220.51 341.037C1220.46 341.151 1216.16 339.632 1210.9 337.642C1205.63 335.649 1201.4 333.944 1201.44 333.83C1201.49 333.717 1205.79 335.236 1211.05
      337.228C1216.31 339.215 1220.55 340.921 1220.51 341.037Z" fill="#E0E0E0"/>
    <path d="M1219 345.023C1218.96 345.137 1214.65 343.618 1209.39 341.628C1204.12 339.635 1199.89 337.927 1199.93 337.813C1199.98 337.699 1204.28 339.218 1209.54
      341.211C1214.81 343.201 1219.04 344.906 1219 345.023Z" fill="#E0E0E0"/>
    <path d="M1217.49 349.009C1217.45 349.122 1213.14 347.603 1207.88 345.613C1202.61 343.621 1198.38 341.915 1198.43 341.801C1198.47 341.688 1202.77 343.207 1208.04
      345.2C1213.3 347.186 1217.53 348.892 1217.49 349.009Z" fill="#E0E0E0"/>
    <path d="M1215.98 352.994C1215.94 353.108 1211.64 351.589 1206.37 349.599C1201.11 347.606 1196.88 345.901 1196.92 345.787C1196.96 345.674 1201.27 347.193 1206.53
      349.186C1211.8 351.172 1216.03 352.878 1215.98 352.994Z" fill="#E0E0E0"/>
    <path d="M1335.06 233.576L1333.18 234.492L1324.73 229.145L1321.87 224.326C1321.87 224.326 1318.83 225.51 1322.92 231.655C1327.01 237.797 1325.68 236.371 1325.68
      236.371C1325.68 236.371 1319.54 239.402 1319.43 240.34C1319.36 241.028 1323.86 248.973 1326.33 253.279C1327.58 255.445 1329.78 256.943 1331.64 256.69C1331.9
      256.658 1332.15 256.587 1332.37 256.48C1333.91 255.733 1340.81 250.298 1340.81 250.298L1344.05 248.718L1335.07 233.574L1335.06 233.576Z" fill="#F5F5F5"/>
    <path d="M1341.41 227.957L1349.48 244.381L1346.43 245.909L1337.44 230.355L1341.41 227.957Z" fill="#F5F5F5"/>
    <path d="M478.237 221.569L476.793 220.564L477.404 213.267L479.872 209.918C479.872 209.918 477.76 208.206 474.789 212.773C471.821 217.344 472.359 216.029 472.359
      216.029C472.359 216.029 467.608 212.75 466.861 212.954C466.314 213.104 462.16 218.483 459.92 221.418C458.794 222.896 458.557 224.831 459.496 226.016C459.626
      226.184 459.78 226.334 459.948 226.451C461.128 227.273 467.991 230.362 467.991 230.362L470.485 232.099L478.24 221.572L478.237 221.569Z" fill="#F5F5F5"/>
    <path d="M483.905 224.235L474.844 234.835L472.441 233.15L480.474 222.171L483.905 224.235Z" fill="#F5F5F5"/>
    <path d="M1613.47 235.721C1613.42 235.721 1613.35 235.074 1613.26 233.859C1613.23 233.252 1613.17 232.499 1613.01 231.622C1612.88 230.744 1612.75 229.722 1612.42
      228.612C1611.93 226.365 1610.91 223.694 1609.3 220.868C1607.67 218.051 1605.31 215.1 1602.1 212.573C1598.89 210.075 1594.83 207.915 1590.12 206.989C1585.45 206.092
      1580.14 206.121 1575.07 207.924C1572.51 208.744 1570.03 210.027 1567.76 211.657C1566.58 212.428 1565.54 213.403 1564.46 214.338C1563.47 215.38 1562.44 216.392
      1561.58 217.588C1557.95 222.17 1555.83 228.13 1555.62 234.313C1555.53 240.505 1557.36 246.552 1560.75 251.316C1561.56 252.541 1562.53 253.612 1563.47 254.702C1564.51
      255.686 1565.49 256.718 1566.63 257.537C1568.83 259.273 1571.24 260.672 1573.76 261.617C1578.74 263.671 1584.04 263.961 1588.75 263.286C1593.51 262.582 1597.67 260.633
      1600.99 258.29C1604.33 255.927 1606.83 253.091 1608.58 250.352C1610.33 247.603 1611.48 244.999 1612.08 242.771C1612.46 241.681 1612.63 240.669 1612.82 239.791C1613.03
      238.923 1613.12 238.17 1613.17 237.563C1613.31 236.367 1613.42 235.721 1613.47 235.721C1613.53 235.721 1613.53 236.367 1613.49 237.601C1613.48 238.219 1613.44 238.981
      1613.28 239.878C1613.15 240.775 1613.02 241.816 1612.68 242.945C1612.16 245.24 1611.07 247.96 1609.35 250.834C1607.6 253.699 1605.08 256.689 1601.66 259.216C1598.24
      261.714 1593.93 263.816 1588.97 264.607C1584.04 265.369 1578.48 265.118 1573.22 263.006C1570.56 262.022 1568.02 260.575 1565.68 258.753C1564.48 257.885 1563.43 256.814
      1562.33 255.772C1561.33 254.634 1560.3 253.506 1559.45 252.213C1555.86 247.208 1553.91 240.813 1554 234.274C1554.22 227.744 1556.48 221.446 1560.31 216.614C1561.22
      215.37 1562.31 214.299 1563.36 213.2C1564.51 212.216 1565.61 211.194 1566.85 210.393C1569.27 208.686 1571.88 207.365 1574.58 206.516C1579.95 204.655 1585.5 204.674
      1590.39 205.677C1595.31 206.709 1599.52 209.024 1602.81 211.686C1606.1 214.367 1608.48 217.482 1610.08 220.424C1611.67 223.385 1612.62 226.153 1613.03 228.468C1613.32
      229.616 1613.4 230.657 1613.48 231.564C1613.6 232.461 1613.61 233.223 1613.58 233.85C1613.57 235.084 1613.53 235.721 1613.47 235.721Z" fill="#EBEBEB"/>
    <path d="M1607.6 217.394C1607.67 217.481 1607.14 217.944 1606.11 218.706C1605.55 219.111 1604.93 219.555 1604.22 220.056C1603.85 220.317 1603.46 220.606 1603.02 220.876C1602.55
      221.117 1602.07 221.368 1601.57 221.628C1597.65 223.895 1591.42 226.21 1584.33 225.968C1583.45 225.93 1582.57 225.891 1581.71 225.853C1580.86 225.718 1580.02 225.592 1579.2
      225.457C1578.8 225.39 1578.38 225.332 1577.99 225.255C1577.59 225.149 1577.21 225.043 1576.82 224.946C1576.05 224.734 1575.29 224.551 1574.57 224.319C1571.69 223.316 1569.2
      222.149 1567.24 220.943C1563.31 218.571 1561.4 216.401 1561.54 216.266C1561.7 216.082 1563.84 217.896 1567.8 219.95C1569.77 220.992 1572.22 222.014 1575.01 222.901C1575.71
      223.104 1576.45 223.258 1577.19 223.451C1577.56 223.548 1577.93 223.634 1578.32 223.731C1578.7 223.789 1579.09 223.847 1579.48 223.904C1580.27 224.02 1581.07 224.136 1581.88
      224.261C1582.7 224.29 1583.53 224.329 1584.36 224.358C1591.12 224.589 1597.1 222.564 1601.07 220.606C1601.58 220.384 1602.06 220.172 1602.54 219.969C1602.98 219.738 1603.38
      219.487 1603.77 219.275C1604.51 218.87 1605.18 218.503 1605.78 218.175C1606.89 217.587 1607.54 217.298 1607.6 217.394Z" fill="#EBEBEB"/>
    <path d="M1560.67 253.061C1560.6 252.974 1561.13 252.511 1562.15 251.749C1562.71 251.344 1563.34 250.9 1564.04 250.399C1564.42 250.138 1564.81 249.849 1565.25 249.579C1565.71
      249.338 1566.19 249.087 1566.7 248.827C1570.62 246.56 1576.84 244.245 1583.94 244.487C1584.82 244.525 1585.7 244.564 1586.55 244.602C1587.4 244.737 1588.24 244.863 1589.06
      244.998C1589.47 245.065 1589.88 245.123 1590.28 245.2C1590.67 245.306 1591.06 245.412 1591.44 245.509C1592.22 245.721 1592.98 245.904 1593.7 246.136C1596.58 247.139 1599.06
      248.306 1601.02 249.512C1604.96 251.884 1606.87 254.054 1606.72 254.189C1606.57 254.373 1604.43 252.559 1600.46 250.505C1598.49 249.463 1596.05 248.441 1593.26 247.554C1592.55
      247.351 1591.82 247.197 1591.08 247.004C1590.71 246.907 1590.34 246.821 1589.95 246.724C1589.56 246.666 1589.18 246.608 1588.78 246.551C1588 246.435 1587.2 246.319 1586.39
      246.194C1585.57 246.165 1584.74 246.126 1583.9 246.097C1577.15 245.866 1571.17 247.891 1567.2 249.849C1566.69 250.071 1566.2 250.283 1565.73 250.486C1565.29 250.717 1564.88
      250.968 1564.5 251.18C1563.75 251.585 1563.09 251.952 1562.49 252.28C1561.39 252.868 1560.74 253.148 1560.67 253.061Z" fill="#EBEBEB"/>
    <path d="M1613.45 236.404C1613.44 236.847 1600.3 236.896 1584.1 236.5C1567.9 236.105 1554.78 235.429 1554.79 234.986C1554.8 234.542 1567.94 234.494 1584.14 234.889C1600.35
      235.275 1613.46 235.96 1613.45 236.404Z" fill="#EBEBEB"/>
    <path d="M1582.77 264.307C1582.33 264.297 1582.28 251.161 1582.68 234.967C1583.07 218.773 1583.75 205.656 1584.19 205.666C1584.63 205.676 1584.68 218.812 1584.29 235.006C1583.9
      251.199 1583.22 264.326 1582.77 264.307Z" fill="#EBEBEB"/>
    <path d="M1582.77 264.269C1582.67 264.442 1579.43 262.243 1575.7 257.286C1573.86 254.807 1571.92 251.624 1570.46 247.766C1569 243.918 1568.08 239.395 1568.19 234.621C1568.28
      229.837 1569.47 225.361 1571.15 221.61C1572.83 217.848 1574.92 214.771 1576.88 212.379C1580.84 207.605 1584.09 205.493 1584.2 205.657C1584.36 205.84 1581.4 208.242 1577.78
      213.084C1575.98 215.504 1574.05 218.552 1572.51 222.198C1570.98 225.834 1569.88 230.107 1569.8 234.649C1569.71 239.202 1570.53 243.532 1571.86 247.255C1573.19 250.988
      1574.95 254.132 1576.63 256.63C1580.03 261.626 1582.94 264.095 1582.77 264.269Z" fill="#EBEBEB"/>
    <path d="M1584.86 205.676C1584.96 205.502 1588.2 207.701 1591.93 212.659C1593.77 215.137 1595.71 218.32 1597.17 222.178C1598.63 226.026 1599.55 230.55 1599.44 235.324C1599.35
      240.108 1598.16 244.583 1596.48 248.335C1594.8 252.096 1592.71 255.173 1590.75 257.565C1586.8 262.339 1583.55 264.451 1583.43 264.287C1583.28 264.104 1586.23 261.703 1589.85
      256.861C1591.65 254.44 1593.59 251.392 1595.12 247.746C1596.65 244.11 1597.75 239.838 1597.83 235.295C1597.93 230.743 1597.1 226.412 1595.78 222.689C1594.44 218.957 1592.68
      215.812 1591 213.314C1587.61 208.318 1584.69 205.859 1584.86 205.676Z" fill="#EBEBEB"/>
    <g clip-path="url(#clip0)">
      <path d="M1417.93 180L1418.72 181.42C1419.6 183 1420.93 184.31 1422.53 185.17L1423.96 185.94L1422.54 186.73C1420.95 187.6 1419.65 188.93 1418.79 190.53L1418.02 191.96L1417.23
        190.54C1416.35 188.95 1415.03 187.65 1413.43 186.79L1412 186.02L1413.42 185.23C1415 184.35 1416.31 183.03 1417.17 181.43L1417.93 180Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip1)">
      <path d="M1137.92 254L1139.25 256.377C1140.72 259.021 1142.95 261.213 1145.62 262.653L1148.02 263.941L1145.64 265.264C1142.98 266.72 1140.8 268.946 1139.36 271.623L1138.08
        274.017L1136.75 271.64C1135.28 268.979 1133.07 266.803 1130.39 265.364L1128 264.075L1130.38 262.753C1133.02 261.28 1135.21 259.071 1136.65 256.393L1137.92 254Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip2)">
      <path d="M1539.92 294L1541.25 296.377C1542.72 299.021 1544.95 301.213 1547.62 302.653L1550.02 303.941L1547.64 305.264C1544.98 306.72 1542.8 308.946 1541.36 311.623L1540.08
        314.017L1538.75 311.64C1537.28 308.979 1535.07 306.803 1532.39 305.364L1530 304.075L1532.38 302.753C1535.02 301.28 1537.21 299.071 1538.65 296.393L1539.92 294Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip3)">
      <path d="M815.925 132L817.247 134.377C818.72 137.021 820.946 139.213 823.623 140.653L826.017 141.941L823.64 143.264C820.979 144.72 818.803 146.946 817.364 149.623L816.075
        152.017L814.753 149.64C813.28 146.979 811.071 144.803 808.393 143.364L806 142.075L808.377 140.753C811.021 139.28 813.213 137.071 814.653 134.393L815.925 132Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip4)">
      <path d="M1682.45 216L1683.31 217.545C1684.27 219.264 1685.71 220.689 1687.46 221.624L1689.01 222.462L1687.47 223.321C1685.74 224.268 1684.32 225.715 1683.39 227.455L1682.55
        229.011L1681.69 227.466C1680.73 225.736 1679.3 224.322 1677.56 223.387L1676 222.549L1677.54 221.69C1679.26 220.732 1680.69 219.296 1681.62 217.556L1682.45 216Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip5)">
      <path d="M1084.45 36L1085.31 37.5448C1086.27 39.2636 1087.71 40.6887 1089.46 41.6243L1091.01 42.4619L1089.47 43.3213C1087.74 44.2678 1086.32 45.7146 1085.39 47.4552L1084.55
        49.0109L1083.69 47.4661C1082.73 45.7364 1081.3 44.3222 1079.56 43.3866L1078 42.549L1079.54 41.6895C1081.26 40.7322 1082.69 39.2962 1083.62 37.5556L1084.45 36Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip6)">
      <path d="M208.451 248L209.31 249.545C210.268 251.264 211.715 252.689 213.455 253.624L215.011 254.462L213.466 255.321C211.736 256.268 210.322 257.715 209.387 259.455L208.549
        261.011L207.69 259.466C206.732 257.736 205.296 256.322 203.556 255.387L202 254.549L203.545 253.69C205.264 252.732 206.689 251.296 207.624 249.556L208.451 248Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip7)">
      <path d="M106.474 336L106.936 337.069C107.452 338.259 108.231 339.246 109.168 339.894L110.006 340.474L109.174 341.069C108.243 341.724 107.481 342.726 106.977 343.931L106.526
        345.008L106.064 343.938C105.548 342.741 104.775 341.762 103.838 341.114L103 340.534L103.832 339.939C104.757 339.276 105.525 338.282 106.028 337.077L106.474 336Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip8)">
      <path d="M599.361 214L601.807 218.397C604.531 223.289 608.649 227.345 613.603 230.008L618.031 232.392L613.634 234.838C608.711 237.531 604.686 241.649 602.023 246.603L599.639
        251.031L597.193 246.634C594.469 241.711 590.382 237.686 585.428 235.023L581 232.639L585.397 230.193C590.289 227.469 594.345 223.382 597.008 218.428L599.361 214Z" fill="#E0E0E0"/>
    </g>
      <path d="M1706.45 424L1707.31 425.545C1708.27 427.264 1709.71 428.689 1711.46 429.624L1713.01 430.462L1711.47 431.321C1709.74 432.268 1708.32 433.715 1707.39 435.455L1706.55
        437.011L1705.69 435.466C1704.73 433.736 1703.3 432.322 1701.56 431.387L1700 430.549L1701.54 429.69C1703.26 428.732 1704.69 427.296 1705.62 425.556L1706.45 424Z" fill="#E0E0E0"/>
    <g clip-path="url(#clip9)">
      <path d="M1320.45 331L1321.31 332.545C1322.27 334.264 1323.71 335.689 1325.46 336.624L1327.01 337.462L1325.47 338.321C1323.74 339.268 1322.32 340.715 1321.39 342.455L1320.55
        344.011L1319.69 342.466C1318.73 340.736 1317.3 339.322 1315.56 338.387L1314 337.549L1315.54 336.69C1317.26 335.732 1318.69 334.296 1319.62 332.556L1320.45 331Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip10)">
      <path d="M363.413 314L364.934 316.733C366.628 319.774 369.187 322.295 372.267 323.951L375.019 325.433L372.286 326.953C369.226 328.628 366.724 331.187 365.069 334.267L363.587
        337.019L362.066 334.286C360.372 331.226 357.832 328.724 354.752 327.069L352 325.587L354.733 324.066C357.774 322.372 360.295 319.832 361.951 316.752L363.413 314Z" fill="#E0E0E0"/>
    </g>
    <g clip-path="url(#clip11)">
      <path d="M1549.36 107L1551.81 111.397C1554.53 116.289 1558.65 120.345 1563.6 123.008L1568.03 125.392L1563.63 127.838C1558.71 130.531 1554.69 134.649 1552.02 139.603L1549.64
        144.031L1547.19 139.634C1544.47 134.711 1540.38 130.686 1535.43 128.023L1531 125.639L1535.4 123.193C1540.29 120.469 1544.34 116.382 1547.01 111.428L1549.36 107Z" fill="#E0E0E0"/>
    </g>
    <path d="M1425.26 315H1421.13V298.856C1421.13 295.056 1416.81 293 1411.52 293C1406.22 293 1401.91 295.056 1401.91 298.856V314.998H1397.77V298.856C1397.77 293.421 1403.94 289
      1411.52 289C1419.09 289 1425.26 293.421 1425.26 298.856V315H1425.26Z" fill="${color}" fill-opacity="0.5"/>
    <g opacity="0.4">
    <g opacity="0.4">
      <path opacity="0.4" d="M1425.26 315H1421.13V298.856C1421.13 295.056 1416.81 293 1411.52 293C1406.22 293 1401.91 295.056 1401.91 298.856V314.998H1397.77V298.856C1397.77 293.421
        1403.94 289 1411.52 289C1419.09 289 1425.26 293.421 1425.26 298.856V315H1425.26Z" fill="black"/>
    </g>
    </g>
    <path d="M1428.11 339.003H1394.92C1393.86 339.003 1393 338.143 1393 337.08V317.163C1393 316.1 1393.86 315.24 1394.92 315.24H1428.12C1429.18 315.24 1430.04 316.1 1430.04
      317.163V337.08C1430.04 338.143 1429.18 339.003 1428.11 339.003Z" fill="${color}" fill-opacity="0.5"/>
    <g opacity="0.5">
      <path opacity="0.5" d="M1414.61 324.021C1414.61 322.31 1413.23 320.924 1411.52 320.924C1409.81 320.924 1408.42 322.31 1408.42 324.021C1408.42 325.437 1409.38 326.615
        1410.67 326.985V331.614H1412.36V326.985C1413.66 326.618 1414.61 325.437 1414.61 324.021Z" fill="black"/>
    </g>
    <path d="M554.818 300.382C553.728 291.622 546.318 283.462 537.498 283.452C532.288 283.452 527.328 286.172 523.768 289.982C522.208 291.642 521.148 292.862 520.288 294.112C519.698
      292.722 518.888 291.322 517.688 289.382C514.948 284.952 510.628 281.302 505.508 280.282C496.868 278.542 487.998 285.092 485.208 293.462C482.418 301.832 484.738 311.232
      489.328 318.772C493.918 326.312 501.808 336.162 516.358 341.052C526.388 339.762 539.818 330.872 545.798 324.382C551.778 317.892 555.908 309.132 554.818 300.382Z"
      fill="${color}" fill-opacity="0.5"/>
    <path d="M551.638 316.102C551.638 316.112 551.538 316.112 551.348 316.102C551.118 316.092 550.838 316.072 550.498 316.052C549.708 315.982 548.608 315.902 547.228
      315.782C544.348 315.522 540.258 315.142 535.228 314.682L535.448 314.552C533.998 318.322 532.308 322.742 530.448 327.592L530.148 328.382L529.948 327.562C528.428
      321.232 526.718 314.152 524.918 306.682C524.368 304.372 523.818 302.092 523.288 299.872L523.798 299.972C520.538 304.452 517.448 308.702 514.638 312.562L514.548
      312.682L514.398 312.662C505.768 311.762 498.488 311.002 493.308 310.462C490.778 310.182 488.768 309.952 487.338 309.792C486.678 309.712 486.158 309.642 485.758
      309.592C485.398 309.542 485.218 309.512 485.218 309.492C485.218 309.482 485.408 309.482 485.768 309.502C486.168 309.532 486.698 309.572 487.358 309.622C488.798
      309.752 490.808 309.922 493.338 310.142C498.518 310.632 505.808 311.322 514.448 312.132L514.208 312.242C517.008 308.362 520.078 304.112 523.328 299.612L523.688
      299.112L523.828 299.712C524.358 301.932 524.908 304.212 525.458 306.522C527.238 313.992 528.928 321.082 530.438 327.412L529.938 327.382C531.828 322.552 533.558
      318.142 535.038 314.382L535.098 314.232L535.258 314.252C540.278 314.792 544.358 315.232 547.238 315.532C548.618 315.692 549.698 315.822 550.498 315.912C550.838
      315.962 551.118 315.992 551.348 316.022C551.548 316.072 551.638 316.092 551.638 316.102Z" fill="#FAFAFA"/>
    <path d="M320.587 167.799C317.588 158.199 307.386 152.84 297.798 155.827C288.211 158.814 282.839 169.028 285.826 178.616C287.989 185.561 293.939 190.281 300.699
      191.228C301.338 193.293 302.063 195.616 302.063 195.616L317.293 190.871C317.293 190.871 316.617 188.388 315.966 186.176C320.661 181.554 322.677 174.523 320.587
      167.799Z" fill="${color}" fill-opacity="0.5"/>
    <path d="M311.762 192.518C311.762 192.518 311.737 192.444 311.713 192.297C311.688 192.137 311.639 191.928 311.59 191.645C311.491 191.08 311.332 190.232 311.159
      189.138C310.987 188.044 310.803 186.704 310.68 185.143C310.569 183.582 310.508 181.787 310.754 179.82C310.877 178.837 311.049 177.817 311.368 176.772C311.54
      176.256 311.786 175.727 312.229 175.334C312.659 174.941 313.286 174.732 313.901 174.879C314.196 174.953 314.491 175.125 314.663 175.395C314.847 175.654 314.921
      175.998 314.872 176.305C314.773 176.944 314.38 177.485 313.888 177.878C312.868 178.64 311.516 178.948 310.201 178.751C308.885 178.554 307.632 177.768 306.845
      176.612C306.464 176.035 306.144 175.371 306.12 174.646C306.071 173.92 306.538 173.109 307.324 172.949C307.705 172.863 308.136 173.011 308.394 173.281C308.664
      173.552 308.836 173.92 308.873 174.314C308.935 175.088 308.578 175.801 308.185 176.416C307.374 177.657 306.034 178.628 304.497 178.849C302.973 179.071 301.363
      178.518 300.355 177.387C299.839 176.846 299.519 176.084 299.58 175.322C299.617 174.941 299.777 174.547 300.109 174.314C300.416 174.068 300.847 174.006 301.215
      174.08C301.584 174.154 301.928 174.351 302.186 174.609C302.444 174.879 302.629 175.199 302.764 175.531C303.022 176.194 303.145 176.907 303.121 177.596C303.084
      178.985 302.518 180.324 301.584 181.271C300.65 182.217 299.396 182.783 298.142 182.881C297.528 182.93 296.864 182.881 296.323 182.537C295.795 182.193 295.463
      181.504 295.709 180.89C295.831 180.595 296.09 180.374 296.372 180.263C296.655 180.152 296.962 180.115 297.257 180.165C297.847 180.263 298.314 180.644 298.695
      181.025C299.421 181.836 300.06 182.635 300.687 183.397C303.17 186.47 305.05 189.039 306.341 190.834C306.98 191.731 307.472 192.432 307.804 192.911C307.964 193.145
      308.086 193.329 308.173 193.464C308.259 193.587 308.295 193.661 308.295 193.661C308.295 193.661 308.246 193.6 308.16 193.489C308.062 193.366 307.939 193.182 307.767
      192.948C307.423 192.469 306.919 191.78 306.28 190.895C304.977 189.113 303.071 186.556 300.576 183.508C299.949 182.746 299.298 181.935 298.585 181.148C298.216 180.779
      297.774 180.423 297.245 180.349C296.729 180.263 296.114 180.46 295.905 180.964C295.696 181.455 295.991 182.082 296.458 182.377C296.938 182.684 297.565 182.734 298.155
      182.684C299.359 182.586 300.564 182.033 301.461 181.123C302.358 180.214 302.912 178.923 302.936 177.583C302.961 176.92 302.838 176.231 302.592 175.604C302.346 174.99
      301.879 174.4 301.203 174.289C300.883 174.228 300.527 174.277 300.281 174.486C300.023 174.682 299.875 175.002 299.851 175.334C299.802 176.022 300.084 176.723 300.564
      177.227C301.523 178.296 303.059 178.825 304.51 178.616C305.972 178.407 307.263 177.473 308.037 176.28C308.418 175.678 308.738 175.002 308.689 174.314C308.664 173.982
      308.517 173.65 308.283 173.429C308.05 173.195 307.718 173.072 307.41 173.146C307.091 173.207 306.82 173.429 306.636 173.699C306.452 173.97 306.378 174.301 306.378
      174.633C306.402 175.297 306.697 175.936 307.066 176.489C307.816 177.596 309.021 178.345 310.274 178.542C311.528 178.739 312.843 178.431 313.814 177.719C314.282 177.35
      314.65 176.834 314.724 176.268C314.822 175.703 314.441 175.15 313.901 175.039C313.372 174.916 312.782 175.088 312.401 175.457C311.995 175.826 311.762 176.317 311.59
      176.821C311.27 177.841 311.086 178.862 310.963 179.833C310.717 181.787 310.754 183.569 310.864 185.131C310.975 186.692 311.159 188.031 311.307 189.113C311.479
      190.195 311.602 191.043 311.7 191.621C311.749 191.903 311.774 192.112 311.799 192.272C311.762 192.444 311.762 192.518 311.762 192.518Z" fill="#263238" fill-opacity="0.3"/>
    <path d="M316.998 194.054L303.92 198.123C303.379 198.295 302.801 197.988 302.629 197.447C302.457 196.906 302.764 196.328 303.305 196.156L316.384 192.088C316.924
      191.916 317.502 192.223 317.674 192.764C317.846 193.317 317.539 193.895 316.998 194.054Z" fill="#263238" fill-opacity="0.3"/>
    <path d="M317.268 194.915L304.19 198.983C303.649 199.156 303.342 199.733 303.514 200.274C303.686 200.815 304.263 201.122 304.804 200.95L306.242 200.508L309.279
    203.544C309.623 203.888 310.139 204.011 310.606 203.876L315.277 202.425C315.744 202.278 316.101 201.872 316.187 201.393L316.912 197.201L317.883 196.894C318.424
    196.722 318.731 196.144 318.559 195.603C318.387 195.05 317.809 194.743 317.268 194.915Z" fill="#263238" fill-opacity="0.3"/>
    <g opacity="0.2">
      <g opacity="0.2">
        <g opacity="0.2">
          <g opacity="0.2">
            <path opacity="0.2" d="M288.592 165.612C289.428 164.173 290.411 163.03 291.357 162.219C292.292 161.395 293.152 160.879 293.791 160.609C294.43 160.338 294.824 160.277
              294.897 160.412C295.07 160.707 293.902 161.58 292.488 163.325C291.087 165.034 289.501 167.75 289.059 171.045C288.629 174.327 289.44 177.375 290.349 179.391C291.247
              181.431 292.156 182.587 291.911 182.832C291.8 182.931 291.443 182.783 290.89 182.353C290.349 181.923 289.649 181.198 288.96 180.153C288.272 179.12 287.608 177.756
              287.19 176.146C286.76 174.523 286.6 172.667 286.846 170.737" fill="white"/>
          </g>
        </g>
      </g>
    </g>
    <g opacity="0.2">
      <g opacity="0.2">
        <g opacity="0.2">
          <g opacity="0.2">
            <path opacity="0.2" d="M299.31 159.858C298.204 160.19 297.135 160.19 296.84 159.65C296.705 159.367 296.815 158.998 297.135 158.629C297.454 158.261 297.995 157.916
              298.659 157.72C299.323 157.523 299.962 157.511 300.429 157.634C300.896 157.757 301.203 158.002 301.24 158.31" fill="white"/>
          </g>
        </g>
      </g>
    </g>
    <path d="M316.027 201.762C316.027 201.799 315.646 201.75 315.044 201.762C314.442 201.774 313.606 201.872 312.733 202.167C311.86 202.462 311.147 202.905 310.656 203.261C310.176
      203.618 309.906 203.888 309.881 203.864C309.869 203.851 310.09 203.544 310.557 203.139C311.024 202.733 311.762 202.266 312.659 201.958C313.557 201.651 314.417 201.577 315.044
      201.602C315.671 201.627 316.04 201.737 316.027 201.762Z" fill="#455A64"/>
    <path d="M316.948 197.214C316.961 197.275 314.613 198.062 311.7 198.959C308.787 199.869 306.402 200.557 306.39 200.496C306.377 200.434 308.725 199.647 311.638 198.75C314.551
      197.853 316.924 197.164 316.948 197.214Z" fill="#455A64"/>
    <path d="M316.605 199.021C316.617 199.046 316.138 199.255 315.363 199.55C314.577 199.845 313.495 200.238 312.278 200.631C311.061 201.025 309.955 201.357 309.144
      201.578C308.332 201.799 307.828 201.91 307.828 201.885C307.816 201.861 308.308 201.688 309.107 201.43C309.967 201.148 311.037 200.803 312.217 200.422C313.397
      200.029 314.454 199.685 315.314 199.402C316.101 199.144 316.605 198.997 316.605 199.021Z" fill="#455A64"/>
    <path d="M497.068 91.3757H492.857V110.253H497.068V91.3757Z" fill="${color}" fill-opacity="0.5"/>
    <path d="M485.524 98.7138V102.924H504.402V98.7138H485.524Z" fill="${color}" fill-opacity="0.5"/>
    <path d="M510.643 100.861C510.637 100.861 510.631 100.772 510.612 100.606C510.599 100.421 510.58 100.172 510.561 99.8596C510.548 99.6937 510.535 99.5087 510.522
      99.3109C510.503 99.1068 510.458 98.8899 510.42 98.6538C510.344 98.1817 510.273 97.6331 510.088 97.0334C509.808 95.8212 509.24 94.3858 508.34 92.8738C507.434 91.3618
      506.133 89.786 504.359 88.4463C502.592 87.1193 500.359 85.9964 497.788 85.5371C495.23 85.0969 492.346 85.1798 489.603 86.2261C488.901 86.4494 488.244 86.7939
      487.574 87.1193C486.936 87.5084 486.26 87.8657 485.667 88.3442C485.029 88.7844 484.474 89.3267 483.893 89.8498C483.37 90.4303 482.821 90.9981 482.368 91.6553C481.443
      92.944 480.633 94.373 480.142 95.9424C479.632 97.5055 479.364 99.1642 479.338 100.855C479.357 102.545 479.632 104.204 480.142 105.767C480.633 107.343 481.443 108.772
      482.368 110.061C482.821 110.718 483.37 111.286 483.893 111.866C484.474 112.389 485.029 112.932 485.66 113.372C486.254 113.857 486.93 114.214 487.568 114.603C488.244
      114.929 488.901 115.273 489.597 115.496C492.346 116.543 495.23 116.619 497.782 116.185C500.353 115.732 502.586 114.603 504.353 113.276C506.127 111.936 507.428 110.361
      508.334 108.849C509.234 107.33 509.801 105.901 510.082 104.689C510.267 104.089 510.337 103.541 510.414 103.069C510.452 102.833 510.497 102.616 510.516 102.411C510.529
      102.214 510.541 102.029 510.554 101.863C510.58 101.55 510.592 101.301 510.605 101.116C510.624 100.944 510.637 100.861 510.643 100.861C510.65 100.861 510.65 100.951
      510.65 101.116C510.643 101.301 510.637 101.55 510.631 101.869C510.624 102.035 510.618 102.22 510.612 102.424C510.592 102.628 510.554 102.845 510.522 103.088C510.458
      103.566 510.395 104.121 510.216 104.727C509.954 105.952 509.393 107.407 508.5 108.951C507.6 110.488 506.292 112.096 504.5 113.468C502.713 114.826 500.455 115.981
      497.839 116.46C495.243 116.919 492.302 116.849 489.495 115.79C488.78 115.56 488.11 115.216 487.421 114.884C486.764 114.488 486.081 114.125 485.469 113.633C484.825
      113.193 484.257 112.638 483.664 112.102C483.128 111.509 482.566 110.935 482.101 110.259C481.15 108.944 480.327 107.483 479.823 105.876C479.306 104.281 479.026 102.584
      479 100.855C479.026 99.1323 479.3 97.4353 479.823 95.8404C480.327 94.2327 481.15 92.7717 482.101 91.4639C482.566 90.794 483.128 90.2134 483.664 89.6265C484.257 89.0906
      484.825 88.542 485.469 88.0954C486.075 87.6041 486.764 87.2405 487.421 86.8449C488.11 86.5132 488.78 86.1623 489.495 85.939C492.302 84.88 495.236 84.8098 497.833
      85.2692C500.449 85.7413 502.713 86.9024 504.493 88.2612C506.286 89.6329 507.594 91.2406 508.493 92.7781C509.387 94.322 509.948 95.7766 510.21 97.0015C510.382 97.6075
      510.446 98.1626 510.516 98.6411C510.548 98.8835 510.586 99.1004 510.605 99.3045C510.612 99.5087 510.618 99.6937 510.624 99.8596C510.631 100.179 510.643 100.427 510.643
      100.612C510.65 100.772 510.65 100.861 510.643 100.861Z" fill="${color}" fill-opacity="0.5"/>
    <g clip-path="url(#clip12)">
      <path d="M815.96 40.8676L804.869 47.0452L804.056 56.3297L797.003 50.4953L787.51 54.8847L760 29L815.96 40.8676Z" fill="${color}" fill-opacity="0.5"/>
      <g opacity="0.5">
        <g opacity="0.5">
          <path opacity="0.5" d="M804.869 47.0454L760.84 29.1808L799.631 49.2762L804.056 56.3299L804.869 47.0454Z" fill="black"/>
        </g>
      </g>
      <g opacity="0.8">
        <g opacity="0.8">
          <path opacity="0.8" d="M799.631 49.2762L797.003 50.4955L804.057 56.33L799.631 49.2762Z" fill="black" fill-opacity="0.5"/>
        </g>
      </g>
    </g>
    <path d="M1317.88 122.938C1317.88 122.938 1284.07 116.139 1285.02 61.2434C1285.03 60.4609 1285.67 59.831 1286.45 59.8186C1291.16 59.7352 1306.73 58.8711 1317.01
      51.2877C1317.53 50.9026 1318.24 50.9044 1318.76 51.2912C1329.03 58.9545 1344.61 59.815 1349.32 59.8949C1350.1 59.9073 1350.74 60.539 1350.75 61.3197C1351.71
      116.79 1317.88 122.938 1317.88 122.938Z" fill="${color}" fill-opacity="0.5"/>
    <path d="M1350.75 61.3212C1350.73 60.5388 1350.1 59.9089 1349.32 59.8964C1347.78 59.8698 1345.08 59.7598 1341.78 59.3571C1340.86 68.5445 1337.95 81.6035 1329.4
      95.5248C1322.34 107.038 1311.68 111.827 1302.02 113.598C1310.04 121.362 1317.89 122.94 1317.89 122.94C1317.89 122.94 1351.71 116.79 1350.75 61.3212Z"
      fill="black" fill-opacity="0.05"/>
    <path d="M1335.23 80.9628H1300.54V92.9731H1335.23V80.9628Z" fill="white"/>
    <path d="M1323.89 104.313V69.6234H1311.88V104.313H1323.89Z" fill="white"/>
    <defs>
      <clipPath id="clip0">
        <rect width="11.95" height="11.95" fill="white" transform="translate(1412 180)"/>
      </clipPath>
      <clipPath id="clip1">
        <rect width="20" height="20" fill="white" transform="translate(1128 254)"/>
      </clipPath>
      <clipPath id="clip2">
        <rect width="20" height="20" fill="white" transform="translate(1530 294)"/>
      </clipPath>
      <clipPath id="clip3">
        <rect width="20" height="20" fill="white" transform="translate(806 132)"/>
      </clipPath>
      <clipPath id="clip4">
        <rect width="13" height="13" fill="white" transform="translate(1676 216)"/>
      </clipPath>
      <clipPath id="clip5">
        <rect width="13" height="13" fill="white" transform="translate(1078 36)"/>
      </clipPath>
      <clipPath id="clip6">
        <rect width="13" height="13" fill="white" transform="translate(202 248)"/>
      </clipPath>
      <clipPath id="clip7">
        <rect width="7" height="9" fill="white" transform="translate(103 336)"/>
      </clipPath>
      <clipPath id="clip8">
        <rect width="37" height="37" fill="white" transform="translate(581 214)"/>
      </clipPath>
      <clipPath id="clip9">
        <rect width="13" height="13" fill="white" transform="translate(1314 331)"/>
      </clipPath>
      <clipPath id="clip10">
        <rect width="23" height="23" fill="white" transform="translate(352 314)"/>
      </clipPath>
      <clipPath id="clip11">
        <rect width="37" height="37" fill="white" transform="translate(1531 107)"/>
      </clipPath>
      <clipPath id="clip12">
        <rect width="55.96" height="27.3297" fill="white" transform="translate(760 29)"/>
      </clipPath>
    </defs>
  </svg>
`;

export const shapesBg = (color: string): string => `
  <svg width="1920" height="597" viewBox="0 0 1920 597" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path opacity="0.2" d="M1778.63 782.226C1765.84 745.509 1769.36 705.19 1776.12 666.91C1782.88 628.613 1792.76 590.486 1792.25 551.595C1791.4 488.094
        1760.97 425.528 1711.28 385.991C1661.58 346.455 1593.55 330.881 1531.7 345.266C1492.58 354.369 1455.81 374.715 1415.68 376.362C1374.48 378.06 1335.34
        359.854 1294.78 352.416C1198.49 334.736 1095.28 383.189 1041.39 464.929C987.504 546.669 982.392 656.313 1021.83 745.916C1061.26 835.519 1142.1 904.25
        1234.69 936.094C1327.27 967.92 1430.31 964.235 1523.19 933.275C1568.74 918.092 1612.09 896.608 1658.27 883.514C1704.45 870.42 1755.47 866.327 1799.37
        885.756L1863.12 852.078C1824.27 851.025 1791.44 818.927 1778.63 782.226Z" fill="url(#paint0_linear)"/>
      <path opacity="0.2" d="M588.616 903.226C575.81 866.509 579.343 826.19 586.102 787.91C592.861 749.613 602.746 711.486 602.236 672.595C601.387 609.094 570.953
        546.528 521.26 506.992C471.568 467.455 403.533 451.881 341.68 466.266C302.568 475.369 265.799 495.715 225.668 497.362C184.467 499.06 145.321 480.854 104.765
        473.416C8.47008 455.736 -94.7367 504.189 -148.624 585.929C-202.495 667.669 -207.607 777.313 -168.172 866.916C-128.737 956.519 -47.8971 1025.25 44.6952 1057.09C137.271
        1088.92 240.308 1085.23 333.189 1054.27C378.737 1039.09 422.096 1017.61 468.273 1004.51C514.467 991.42 565.468 987.327 609.369 1006.76L673.124 973.078C634.249 972.042
        601.421 939.944 588.616 903.226Z" fill="url(#paint1_linear)"/>
      <path opacity="0.2" d="M2075.61 -145.757C2062.81 -182.475 2066.34 -222.793 2073.1 -261.073C2079.86 -299.37 2089.74 -337.497 2089.23 -376.389C2088.39 -439.889 2057.95
        -502.455 2008.26 -541.992C1958.57 -581.546 1890.53 -597.119 1828.68 -582.734C1789.57 -573.631 1752.8 -553.286 1712.67 -551.638C1671.47 -549.94 1632.32 -568.146
        1591.76 -575.584C1495.49 -593.264 1392.26 -544.811 1338.39 -463.071C1284.5 -381.331 1279.39 -271.687 1318.83 -182.067C1358.26 -92.4637 1439.1 -23.7328 1531.69
        8.11066C1624.27 39.9372 1727.31 36.2518 1820.19 5.29144C1865.74 -9.89154 1909.09 -31.3752 1955.27 -44.4692C2001.47 -57.5634 2052.47 -61.6563 2096.37 -42.2275L2160.12
        -75.9052C2121.25 -76.9582 2088.42 -109.056 2075.61 -145.757Z" fill="url(#paint2_linear)"/>
      <path opacity="0.2" d="M937.06 -159.073C929.842 -116.275 912.503 -75.1926 885.856 -40.8866C833.276 26.7744 746.101 66.0226 660.591 60.5031C651.505 59.9256 642.351
        58.8898 633.163 57.6499C585.135 51.2133 536.189 39.478 493.103 59.535C456.521 76.5692 433.135 113.117 400.12 136.299C356.932 166.631 298.798 171.59 248.562
        155.405C198.343 139.22 155.783 103.691 124.687 61.0465C113.936 46.3051 104.426 30.6806 95 15.0051V-159.073H937.06Z" fill="url(#paint3_linear)"/>
      <path d="M1247 -188C1236.35 -178.099 1224.02 -170.083 1210.28 -164.784C1185.47 -155.189 1157.5 -153.338 1134.98 -139.191C1105.31 -120.56 1091.52 -84.7427 1084.05
        -50.5216C1076.59 -16.2835 1073.19 19.6869 1056.33 50.3925C1021.38 114.096 934.475 136.005 865.574 112.908C819.702 97.5208 781.388 66.5095 750.496 28.722C735.007
        9.76874 721.387 -10.8659 709.634 -32.0949C683.073 -80.0724 663.593 -132.907 627.164 -173.887C622.782 -178.83 618.027 -183.551 613 -188H1247Z" fill="${color}" fill-opacity="0.15"/>
      <path d="M1931.96 334.695V766.407H1310.68C1312.77 748.201 1316.59 730.199 1322.33 712.673C1341.71 653.35 1383.74 601.399 1437.04 569.606C1465.2 552.793 1496.51 541.601
        1529.29 537.95C1571.97 533.177 1620.08 538.935 1652.98 511.337C1672.76 494.727 1682.38 469.372 1694.71 446.665C1729.83 382.061 1795.98 332.606 1869.33 327.409C1890.37
        325.915 1911.7 328.547 1931.96 334.695Z" fill="${color}" fill-opacity="0.15"/>
      <path d="M1486.44 708.128H661.172C663.651 684.861 669.511 661.866 678.019 640.094C697.924 589.28 737.732 540.844 791.756 533.134C815.057 529.805 837.95 534.322 860.113
        542.542C891.871 554.312 927.451 544.716 949.241 518.783C964.627 500.492 984.413 485.852 1006.64 477.055C1047.81 460.734 1096.48 464.725 1134.44 487.5C1145.7 494.259
        1156.03 502.53 1166 511.327C1194.36 536.326 1219.85 565.571 1255.57 576.441C1292.71 587.718 1333.3 576.39 1371.36 583.998C1427.15 595.156 1468.14 646.055 1484.35
        700.588C1485.09 703.084 1485.79 705.598 1486.44 708.128Z" fill="url(#paint4_linear)"/>
      <path d="M483.37 118.296C490.422 87.1165 470.863 56.1243 439.683 49.0727C408.504 42.0212 377.512 61.5804 370.46 92.7596C363.409 123.939 382.968 154.931 414.147
        161.982C445.326 169.034 476.319 149.475 483.37 118.296Z" fill="${color}" fill-opacity="0.15"/>
      <path d="M1415.18 313.767C1423.4 306.494 1424.16 293.936 1416.89 285.718C1409.62 277.499 1397.06 276.732 1388.84 284.005C1380.62 291.277 1379.86 303.835 1387.13
        312.054C1394.4 320.272 1406.96 321.039 1415.18 313.767Z" fill="${color}" fill-opacity="0.15"/>
      <path d="M562.755 532.389C562.755 537.874 558.306 542.324 552.82 542.324C547.334 542.324 542.885 537.874 542.885 532.389C542.885 526.903 547.334 522.454 552.82 522.454C558.306
        522.454 562.755 526.903 562.755 532.389Z" fill="${color}" fill-opacity="0.15"/>
      <path d="M1506.31 221.694C1506.31 227.18 1501.86 231.63 1496.37 231.63C1490.89 231.63 1486.44 227.18 1486.44 221.694C1486.44 216.209 1490.89 211.759 1496.37
        211.759C1501.86 211.759 1506.31 216.209 1506.31 221.694Z" fill="${color}" fill-opacity="0.15"/>
      <path d="M392.823 -155.769C463.405 -155.854 533.376 -123.348 578.84 -69.342C618.258 -22.5024 638.57 37.3124 672.383 88.3299C706.197 139.365 763.311 184.115
        823.5 172.906C864.82 165.213 897.665 132.249 917.094 94.9873C936.523 57.7262 944.947 15.8966 955.34 -24.8291C965.751 -65.5548 978.964 -106.892 1005.88 -139.177C1040.02
        -180.089 1095.43 -202.167 1148.35 -195.951" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M416.464 -158.352C482.63 -158.436 548.219 -127.952 590.847 -77.3419C627.803 -33.4403 646.841 22.6382 678.531 70.4628C710.239 118.305 763.77 160.253 820.188
        149.74C858.927 142.523 889.717 111.63 907.923 76.6956C926.146 41.7612 934.026 2.54708 943.775 -35.6312C953.523 -73.8094 965.921 -112.565 991.158 -142.812C1023.15
        -181.16 1075.11 -201.862 1124.71 -196.02" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M440.104 -160.934C501.855 -161.002 563.079 -132.572 602.836 -85.3254C637.329 -44.362 655.095 7.98021 684.679 52.612C714.264 97.2608 764.228 136.407
        816.875 126.591C853.033 119.866 881.752 91.028 898.752 58.4203C915.753 25.8126 923.105 -10.7692 932.208 -46.3999C941.311 -82.0306 952.877 -118.188 976.432
        -146.431C1006.29 -182.214 1054.78 -201.541 1101.07 -196.09" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M463.745 -163.499C521.08 -163.567 577.923 -137.158 614.845 -93.3078C646.858 -55.2655 663.365 -6.67657 690.827 34.7625C718.289 76.2014 764.687 112.562
        813.565 103.459C847.123 97.2095 873.804 70.4441 889.581 40.163C905.359 9.89905 912.203 -24.0844 920.644 -57.1506C929.101 -90.2339 939.835 -123.81 961.692
        -150.032C989.409 -183.268 1034.43 -201.202 1077.42 -196.141" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M487.386 -166.079C540.305 -166.146 592.766 -141.776 626.835 -101.288C656.385 -66.1836 671.602 -21.331 696.958 16.9152C722.314 55.1613 765.129 88.7201
        810.236 80.3134C841.213 74.5391 865.839 49.8456 880.394 21.8912C894.965 -6.04608 901.266 -37.3971 909.061 -67.9328C916.857 -98.4686 926.775 -129.463 946.951
        -153.647C972.544 -184.318 1014.09 -200.877 1053.76 -196.207" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1170.93 837.588C1159.24 794.96 1150.73 748.511 1167.05 707.446C1188.45 653.643 1247.81 623.498 1305.3 616.603C1362.78 609.69 1420.68 621.103 1478.42
        625.502C1536.17 629.901 1598 626.096 1645.61 593.098C1711.6 547.345 1733.27 458.184 1792.83 404.296C1842.49 359.375 1917.45 344.549 1980.48 367.222" stroke="#D8D8D8"
        stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1214.66 837.586C1203.6 797.268 1195.55 753.315 1210.99 714.475C1231.23 663.576 1287.38 635.061 1341.76 628.54C1396.16 622.001 1450.91 632.802 1505.53
        636.963C1560.15 641.124 1618.65 637.524 1663.67 606.309C1726.12 563.018 1746.6 478.68 1802.95 427.714C1849.93 385.222 1920.85 371.193 1980.46 392.643"
        stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1258.39 837.586C1247.98 799.561 1240.37 758.139 1254.94 721.506C1274.03 673.512 1326.97 646.627 1378.26 640.479C1429.55 634.314 1481.17 644.504 1532.68
        648.41C1584.19 652.334 1639.36 648.937 1681.8 619.505C1740.68 578.694 1759.99 499.162 1813.11 451.1C1857.4 411.02 1924.27 397.807 1980.48 418.034"
        stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1302.12 837.589C1292.34 801.873 1285.19 762.948 1298.88 728.54C1316.81 683.449 1366.54 658.195 1414.72 652.421C1462.9 646.63 1511.4 656.191 1559.79
        659.877C1608.17 663.562 1659.99 660.369 1699.87 632.721C1755.18 594.373 1773.32 519.664 1823.23 474.522C1864.84 436.871 1927.66 424.456 1980.46 443.46"
        stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1345.85 837.589C1336.7 804.166 1330.02 767.754 1342.81 735.571C1359.57 693.385 1406.11 669.761 1451.18 664.36C1496.25 658.943 1541.63 667.893 1586.89
        671.341C1632.15 674.788 1680.64 671.799 1717.95 645.934C1769.7 610.065 1786.68 540.162 1833.37 497.925C1872.3 462.702 1931.08 451.086 1980.46 468.867"
        stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M0.851662 104C-7.67389 133.686 7.50905 165.784 29.5193 187.455C51.5295 209.125 79.7046 223.154 105.604 240.001C131.503 256.848 156.536 278.332 166.336
        307.628C183.761 359.749 148.181 412.805 131.215 465.079C105.536 544.17 125.542 636.388 181.689 697.732" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M-19.2909 110.13C-27.8165 139.817 -12.6335 171.915 9.37671 193.586C31.3869 215.256 59.562 229.284 85.4614 246.132C111.361 262.979 136.394 284.463 146.193
        313.759C163.618 365.88 128.038 418.936 111.072 471.21C85.3935 550.301 105.4 642.519 161.546 703.862" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M-39.4325 116.263C-47.9581 145.95 -32.7751 178.048 -10.7649 199.719C11.2453 221.389 39.4204 235.417 65.3198 252.264C91.2191 269.112 116.252 290.596 126.052
        319.892C143.476 372.013 107.897 425.068 90.9304 477.343C65.2518 556.433 85.258 648.652 141.404 709.995" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M-59.5917 122.392C-68.1173 152.078 -52.9343 184.176 -30.9241 205.847C-8.91387 227.518 19.2612 241.546 45.1606 258.393C71.0599 275.24 96.0931 296.724 105.892
        326.02C123.317 378.141 87.7374 431.197 70.7712 483.471C45.0926 562.562 65.0988 654.78 121.245 716.124" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1543.72 -388C1548.04 -357.414 1574.97 -334.283 1603.91 -323.464C1632.85 -312.646 1664.29 -311.304 1694.81 -306.481C1725.32 -301.641 1756.93 -292.215 1777.82
        -269.458C1814.98 -228.953 1804.09 -166.013 1809.88 -111.361C1818.66 -28.6703 1874.5 47.3974 1950.76 80.5485" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1527.81 -374.192C1532.12 -343.605 1559.06 -320.474 1588 -309.656C1616.94 -298.837 1648.37 -297.496 1678.89 -292.672C1709.41 -287.832 1741.02 -278.407 1761.91
        -255.649C1799.06 -215.144 1788.18 -152.204 1793.97 -97.5524C1802.75 -14.8613 1858.59 61.2063 1934.85 94.3575" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1511.91 -360.387C1516.23 -329.8 1543.16 -306.669 1572.1 -295.851C1601.04 -285.032 1632.48 -283.691 1663 -278.867C1693.51 -274.027 1725.12 -264.602 1746.01
        -241.844C1783.17 -201.339 1772.28 -138.4 1778.07 -83.7473C1786.85 -1.05621 1842.69 75.0115 1918.95 108.162" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
      <path d="M1496 -346.578C1500.31 -315.991 1527.25 -292.86 1556.19 -282.042C1585.13 -271.224 1616.56 -269.882 1647.08 -265.059C1677.6 -260.219 1709.21 -250.793 1730.1
        -228.035C1767.26 -187.531 1756.37 -124.591 1762.16 -69.9391C1770.94 12.752 1826.78 88.8197 1903.04 121.971" stroke="#D8D8D8" stroke-width="1.69832" stroke-miterlimit="10"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="996.017" y1="649.129" x2="1863.14" y2="649.129" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D1D5DE"/>
        <stop offset="1" stop-color="#DBDBDB"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="-194.003" y1="770.144" x2="673.119" y2="770.144" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D1D5DE"/>
        <stop offset="1" stop-color="#DBDBDB"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="1293" y1="-278.854" x2="2160.12" y2="-278.854" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D1D5DE"/>
        <stop offset="1" stop-color="#DBDBDB"/>
      </linearGradient>
      <linearGradient id="paint3_linear" x1="95.0027" y1="2.69284" x2="937.063" y2="2.69284" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D1D5DE"/>
        <stop offset="1" stop-color="#DBDBDB"/>
      </linearGradient>
      <linearGradient id="paint4_linear" x1="661.175" y1="587.616" x2="1486.44" y2="587.616" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D1D5DE"/>
        <stop offset="1" stop-color="#DBDBDB"/>
      </linearGradient>
      <clipPath id="clip0">
        <rect width="1920" height="597" fill="white"/>
      </clipPath>
    </defs>
  </svg>
`;
