import { AuthService } from '@core/auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ConfigService } from '@shared/services/config.service';

@Component({
  selector: 'app-profile-deleted',
  templateUrl: './profile-deleted.component.html',
  styleUrls: ['./profile-deleted.component.less']
})
export class ProfileDeletedComponent implements OnInit {

  signOutUrl: SafeResourceUrl;
  logoPath: string;
  marketplaceName: string;

  constructor(
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private config: ConfigService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.logoPath = this.config.getOption<string>('logo', '');
    this.marketplaceName = this.config.getOption<string>('name', '');
    this.signOutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.authService.getAuthorizeSignOutUrl());
    try {
      await this.authService.signOut();
    } catch (err) {
      console.error(err);
    }
  }

  onCancelDelete(): void {
    this.authService.redirectToAuthorize('/home');
  }

}
