<header class="header">
  <div class="header-primary">
    <div class="container main-container">
      <div [@opacityFadeInOut]
           class="logo"
           *ngIf="path">
        <div>
          <a tabindex="10"
             (click)="cancelAction(actionReturn)">
            <img src="{{ path }}"
                 alt="{{ title }}" />
          </a>
        </div>
      </div>
    </div>
  </div>
</header>

<section [@opacityFadeInOut]
         class="unsubscribe"
         *ngIf="!successPrimaryHeader && !successSecondaryHeader">
  <div class="unsubscribe-img">
    <img class="unsubscribe-img__item"
         src="/assets/images/unsubscribe.png"
         alt="" />
  </div>

  <div class="unsubscribe-title">
    <h1 class="unsubscribe-title__primary">
      Let’s make the update that is best for you.
    </h1>
    <h4 class="unsubscribe-title__secondary">
      Our goal is to help you spend less and make your money go farther, but we
      realize that some offers may not be right for you.
    </h4>
  </div>

  <app-unsubscribe-option *ngIf="unsubscribeOptions.length"
                          [@opacityFadeInOut]
                          [routerData]="routerData"
                          (successHeaders)="onSuccessHeaders($event)"
                          [unsubscribeOptions]="unsubscribeOptions">
  </app-unsubscribe-option>

</section>

<app-unsubscribe-success-page *ngIf="successPrimaryHeader && successSecondaryHeader"
                              [@opacityFadeInOut]
                              [successPrimaryHeader]="successPrimaryHeader"
                              [successSecondaryHeader]="successSecondaryHeader">
</app-unsubscribe-success-page>

<div class="unsubscribe-footer">
  <span *ngIf="!successPrimaryHeader && !successSecondaryHeader"
        class="unsubscribe-footer__content opt-out">
    <a (click)="optOutAction()">Opt-Out of all marketing email communications</a>
  </span>
  <p class="unsubscribe-footer__content">
    Manage email preferences at <a tabindex="300"
       (click)="cancelAction(actionManage)">Email Subscription Center</a>
  </p>
  <p class="unsubscribe-footer__content">
    <span *ngIf="successPrimaryHeader && successSecondaryHeader">
      or visit
    </span>
    <span *ngIf="!successPrimaryHeader && !successSecondaryHeader">Visit </span><a *ngIf="path"
       (click)="cancelAction(actionReturn)">{{ title }}</a>.
  </p>
</div>