<div class="zone-section-wrapper"
     [ngClass]="getMarginClasses()">
  <div #zoneSection></div>
  <ng-container *ngFor="let zoneElement of elements; trackBy: trackByZoneGuid; let i = index">
    <app-comment [contents]="'zone_name: ' + zoneElement.name + ', zone_id=' + zoneElement.guid"></app-comment>
    <app-noti-block [groupTabIndex]="getTabIndexForZone(i)"
                    *ngIf="zoneElement.type === zoneElementTypes.NotiBlock || zoneElement.type === zoneElementTypes.NotiStrip"
                    [data]="zoneElement">
    </app-noti-block>
    <app-widgets-mini *ngIf="zoneElement.type === zoneElementTypes.WidgetsMini"
                      [groupTabIndex]="getTabIndexForZone(i)"
                      [data]="zoneElement"
                      [sectionIndex]="i"
                      [wideContainerClass]="wideContainerClass">
    </app-widgets-mini>
    <app-widgets-large *ngIf="zoneElement.type === zoneElementTypes.WidgetsLarge"
                       [groupTabIndex]="getTabIndexForZone(i)"
                       [data]="zoneElement"
                       [wideContainerClass]="wideContainerClass">
    </app-widgets-large>
    <app-tdd-scroll *ngIf="isCardsSection(zoneElement, zoneElementTypes.TddScroll)"
                    [data]="zoneElement"
                    [groupTabIndex]="getTabIndexForZone(i)"
                    [wideContainerClass]="wideContainerClass"
                    [wideContainerClassName]="wideContainerClassName"
                    class="container main-container wide-container">
    </app-tdd-scroll>
    <app-cards-fixed *ngIf="isCardsSection(zoneElement, zoneElementTypes.CardsFixed)"
                     [data]="zoneElement"
                     [groupTabIndex]="getTabIndexForZone(i)"
                     (initialized)="onInitElement($event)"
                     (displayed)="onDisplayedElement($event)"
                     [wideContainerClass]="wideContainerClass"
                     [wideContainerClassName]="wideContainerClassName"
                     [userCountry]="userCountry"
                     class="container main-container wide-container">
    </app-cards-fixed>
    <app-cta-element *ngIf="zoneElement.type === zoneElementTypes.CtaBlock || zoneElement.type === zoneElementTypes.CtaProduct"
                     [groupTabIndex]="getTabIndexForZone(i)"
                     [zoneElement]="zoneElement"
                     [wideContainerClass]="wideContainerClass"
                     class="container main-container wide-container"
                     [class.cta-product]="zoneElement.type === zoneElementTypes.CtaProduct">
    </app-cta-element>
    <app-cards-product *ngIf="isCardsSection(zoneElement, zoneElementTypes.CardsProduct)"
                       [data]="zoneElement"
                       [groupTabIndex]="getTabIndexForZone(i)"
                       [wideContainerClass]="wideContainerClass"
                       [wideContainerClassName]="wideContainerClassName"
                       [userCountry]="userCountry"
                       (displayed)="onDisplayedElement($event)"
                       (initialized)="onInitElement($event)"
                       #cardsProductElement
                       [cardsProductElement]="cardsProductElement"
                       class="container main-container wide-container">
    </app-cards-product>
    <app-cards-scroll *ngIf="isCardsSection(zoneElement, zoneElementTypes.CardsScroll)"
                      [data]="zoneElement"
                      [groupTabIndex]="getTabIndexForZone(i)"
                      [wideContainerClass]="wideContainerClass"
                      [wideContainerClassName]="wideContainerClassName"
                      [userCountry]="userCountry"
                      (displayed)="onDisplayedElement($event)"
                      (initialized)="onInitElement($event)"
                      class="container main-container wide-container">
    </app-cards-scroll>
    <app-top-brands *ngIf="zoneElement.type === zoneElementTypes.TopBrands"
                    [zoneElement]="zoneElement"
                    [groupTabIndex]="getTabIndexForZone(i)"
                    [wideContainerClass]="wideContainerClass"
                    [wideContainerClassName]="wideContainerClassName"
                    (displayed)="onDisplayedElement($event)"
                    (initialized)="onInitElement($event)"
                    class="container main-container wide-container">
    </app-top-brands>
    <app-top-categories *ngIf="zoneElement.type === zoneElementTypes.TopCategories"
                    [zoneElement]="zoneElement"
                    [groupTabIndex]="getTabIndexForZone(i)"
                    [wideContainerClass]="wideContainerClass"
                    [wideContainerClassName]="wideContainerClassName"
                    (displayed)="onDisplayedElement($event)"
                    (initialized)="onInitElement($event)"
                    class="container main-container wide-container">
    </app-top-categories>
    <app-top-brands-names *ngIf="zoneElement.type === zoneElementTypes.TopBrandsNames"
                          [zoneElement]="zoneElement"
                          [groupTabIndex]="getTabIndexForZone(i)"
                          [wideContainerClass]="wideContainerClass"
                          [wideContainerClassName]="wideContainerClassName"
                          (displayed)="onDisplayedElement($event)"
                          (initialized)="onInitElement($event)"
                          class="container main-container wide-container">
    </app-top-brands-names>
  </ng-container>
</div>
