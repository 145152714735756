/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export class MarketplaceInfoModel {

  public name: string;
  public owner: string;
  public termManager: string;
  public termMembers: string;

  constructor(data: any) {
    if (data.name) {
      this.name = data.name;
    }
    if (data.owner) {
      this.owner = data.owner;
    }
    if (data.term_manager) {
      this.termManager = data.term_manager;
    }
    if (data.term_members) {
      this.termMembers = data.term_members;
    }
  }

}
