<div [attr.id]="anchorLink"
     class="container main-container {{wideContainerClass}}"
     *ngIf="brandsTier1?.length"
     role="region"
     [attr.aria-labelledby]="idRegistryService.get('popular-brands-list-title')">

  <h4 class="header">
    <div class="header-left">
      <img src="/assets/icons/icn-popular.svg"
           loading="lazy"
           alt="">
      <span [id]="idRegistryService.get('popular-brands-list-title')">{{ title }}</span>
    </div>

    <a class="btn btn-text btn-underline btn-browse-more"
       [routerLink]="['/browse-offers/brands']"
       [appAutoTabIndex]="groupTabIndex + 50">
      <span>All brands</span>
      <svg class="icon-browse-more"
           xmlns="http://www.w3.org/2000/svg"
           width="9"
           height="9"
           viewBox="0 0 9 9">
        <path class="theme-fill-primary"
              fill="#353535"
              fill-rule="nonzero"
              d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z" />
      </svg>
    </a>
  </h4>

  <div role="presentation"
       class="row brands-container"
       [class.wide-endpoint]="isWideContainer()">
    <app-adzerk-brand-item [appAutoTabIndex]="groupTabIndex"
                           class="col-xs-custom col-4 col-md-3 col-lg-2"
                           [brand]="brand"
                           *ngFor="let brand of getBrands()"
                           (brandClick)="clickHandler($event, brand)"
                           (brokenImageUrl)="brokenImageUrl($event, brand.guid)">
    </app-adzerk-brand-item>
  </div>
</div>
