/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { EbgProductSearchBody, EbgProductSearchResponse } from '../interfaces/ebg-product-search.interface';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchWidgetsData } from '@app/offers/interfaces/search-widgets.interface';
import { PermissionService } from '@core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';

export const COMPANY_ID_PROP_NAME = 'wag3_admin_guid';
export const DEFAULT_SEARCH_RADIUS_IN_MILES = '100';

export interface Coordinates {
  latitude: string;
  longitude: string;
}

@Injectable()
export class TicketsService {

  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
  ) { }

  requestEbgProductSearch(data: EbgProductSearchBody): Observable<EbgProductSearchResponse[]> {
    return this.http.get<EbgProductSearchResponse[]>(`${environment.apiUrl}/api/ebg/search/products${this.getQuery(data)}`);
  }

  getQuery(data: EbgProductSearchBody): string {
    let query = '?';

    Object.keys(data).forEach(key => {
      if (data[key]) {
        query += `&${key}=${data[key]}`;
      }
    });

    if (this.permissionService.hasDefined('known:access')) {
      query += `&known_user_email=${this.knownUserService.knowUserEmail}`;
    }

    return query;
  }

  async handleCoordinatesByPlaceName(placeName: string): Promise<Coordinates> {
    const geocoder = new google.maps.Geocoder();
    const coordinates: Coordinates = {
      latitude: '',
      longitude: ''
    };

    if (!placeName) {
      return coordinates;
    }

    await geocoder.geocode({ address: placeName }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (!results || !results.length) {
          return coordinates;
        }

        const placeLocation = results[0].geometry.location;

        coordinates.latitude = placeLocation.lat().toString();
        coordinates.longitude = placeLocation.lng().toString();
      }
    });

    return coordinates;
  }

  ticketsResultAnalyticsOnDisplay(widget: SearchWidgetsData, tw_q: string, tw_city_zip: string): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('tickets-search-result-displayed', {
        'widget-type': widget.widget_id,
        ad_id: widget.adId
      }),
      new AnalyticsGAEventModel('tickets-search-result-displayed', {
        category: 'widget-search',
        label: `${widget.widget_id} | ${widget.adId} | ${tw_q} | ${tw_city_zip}`
      }),
    ]);

    this.http.post(`${environment.apiUrl}/decisions/impressions`,
      { impressions: [{ response_id: widget.responseId }] }).toPromise().catch(() => { });
  }

  ticketsResultAnalyticsOnClick(widget: SearchWidgetsData, itemUrl = ''): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('tickets-search-result-click', {
        'widget-type': widget.widget_id,
        ad_id: widget.adId
      }),
      new AnalyticsGAEventModel('tickets-search-result-click', {
        category: 'widget-search',
        label: `${widget.widget_id} | ${widget.adId} | ${itemUrl}`
      }),
    ]);

    this.http.post(`${environment.apiUrl}/decisions/clicks`,
      { response_id: widget.responseId }).toPromise().catch(() => { });
  }

}
