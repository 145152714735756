<div class="autocomplete {{ autocompleteDropdownSettings.customClass || '' }}">
  <form class="autocomplete-form"
        [formGroup]="autocompleteForm">
    <label class="autocomplete-form__label">
      <ng-container *ngIf="autocompleteLabel">
        {{ autocompleteLabel }}
      </ng-container>

      <img *ngIf="autocompleteDropdownSettings.inputIconUrl"
           class="autocomplete-form__label--icon"
           loading="lazy"
           [src]="autocompleteDropdownSettings.inputIconUrl">

      <input #input
             type="text"
             [placeholder]="autocompletePlaceholder"
             formControlName="autocompleteInput"
             (click)="inputSelect($event)"
             [class.input-icon]="autocompleteDropdownSettings.inputIconUrl"
             class="autocomplete-form__label--input" />

      <button [@opacityFadeInOut]
              *ngIf="isCloseDropdownButtonVisible"
              (click)="closeDropdown()"
              class="autocomplete-form__label--close">
        <svg width="12"
             height="12"
             viewBox="0 0 12 12"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.8938 0.636162C10.5423 0.284691 9.9725 0.284691 9.62103 0.636162L5.73691 4.52027L1.90847 0.691826C1.55699 0.340355 0.987146 0.340355 0.635674 0.691826C0.284202 1.0433 0.284202 1.61315 0.635674 1.96462L4.46412 5.79307L0.71148 9.54571C0.360008 9.89718 0.360009 10.467 0.71148 10.8185C1.06295 11.17 1.6328 11.17 1.98427 10.8185L5.73691 7.06586L9.54522 10.8742C9.89669 11.2256 10.4665 11.2256 10.818 10.8742C11.1695 10.5227 11.1695 9.95284 10.818 9.60137L7.00971 5.79307L10.8938 1.90895C11.2453 1.55748 11.2453 0.987634 10.8938 0.636162Z"
                fill="#353535"
                fill-opacity="0.45" />
        </svg>
      </button>
    </label>
  </form>

  <div [@opacityFadeInOut]
       *ngIf="showDropdown"
       (click)="getBackdropClick($event)"
       [class.no-results]="!isResultsFound"
       class="autocomplete-dropdown">

    <ng-container *ngIf="!isResultsFound">
      <span class="autocomplete-dropdown__section--no-results">No results found</span>
    </ng-container>

    <ng-container *ngIf="isResultsFound">
      <section class="autocomplete-dropdown__section"
               *ngFor="let section of sections">

        <ng-container *ngIf="sections.length && section.data.length">

          <!-- Title -->
          <div *ngIf="section.title"
               class="autocomplete-dropdown__section--title">
            <div class="title-container">
              <img *ngIf="section.iconUrl"
                   class="title-container-icon"
                   loading="lazy"
                   [src]="section.iconUrl">
              <span class="title-container-text">{{ section.title }}</span>
            </div>
            <span class="autocomplete-dropdown__section--title--count">{{ section.data.length }} results</span>
          </div>

          <!-- List of data -->
          <div [@opacityFadeInOut]
               *ngFor="let item of getSectionData(section)"
               (click)="selectItem($event, item)"
               [class.short]="item.title.length <= 20"
               class="autocomplete-dropdown__section--data">
            <span class="autocomplete-dropdown__section--data--item">
              {{ item.title | truncateCompleteWords: 34 }}
            </span>
          </div>

          <!-- Show More Action -->
          <ng-container
                        *ngIf="autocompleteDropdownSettings.itemsToShow && section.data.length > this.autocompleteDropdownSettings.itemsToShow">
            <button (click)="onShowMore($event, section)"
                    class="btn btn-text btn-show-more">
              <span class="link">{{ section.buttonTitle }}</span>
            </button>
          </ng-container>

        </ng-container>
      </section>
    </ng-container>
  </div>
</div>
