<div class="{{ widgetClass }}-rooms rooms-container rooms-container-by-variation">
  <button (click)="dismiss($event)"
          class="{{ widgetClass }}-rooms-container-dismiss rooms-container-dismiss">
    <svg width="14"
         height="14"
         viewBox="0 0 14 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
            fill="#353535" />
    </svg>
  </button>
  <div [@opacityFadeInOut]
       *ngFor="let room of roomsDataClone">

    <h4 class="{{ widgetClass }}-rooms-container-title rooms-container-title">Room {{ room.roomId.toString() }}:</h4>
    <div class="{{ widgetClass }}-rooms-item rooms-container-item">
      <div class="{{ widgetClass }}-rooms-item__control rooms-container-item__control">
        <span class="room-unit">Adults</span>
        <div class="room-unit-setup">
          <button (click)="handleCount($event, room, false, true)"
                  class="room-unit-setup__decrease btn-unit">
            <svg class="svg-mask"
                 viewBox="0 0 10 2"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="nonzero"
                    d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2H1C0.447716 2 0 1.55228 0 1Z"
                    fill="currentColor" />

            </svg>
          </button>
          <div [@opacityFadeInOut]
               class="room-unit-setup__count">{{ room.adults }}</div>
          <button (click)="handleCount($event, room, true, true)"
                  class="room-unit-setup__increase btn-unit">
            <svg class="svg-mask"
                 viewBox="0 0 10 10"
                 fill="none"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="nonzero"
                    d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447716 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447716 6 1 6H4V9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1Z"
                    fill="currentColor" />
            </svg>
          </button>
        </div>
      </div>

      <div class="{{ widgetClass }}-rooms-item__control rooms-container-item__control">
        <span class="room-unit">Kids</span>
        <div class="room-unit-setup">
          <button (click)="handleCount($event, room, false, false)"
                  class="room-unit-setup__decrease btn-unit">
            <svg class="svg-mask"
                 viewBox="0 0 10 2"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="nonzero"
                    d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2H1C0.447716 2 0 1.55228 0 1Z"
                    fill="currentColor" />
            </svg>
          </button>
          <div [@opacityFadeInOut]
               class="room-unit-setup__count">{{ room.kids }}</div>
          <button (click)="handleCount($event, room, true, false)"
                  class="room-unit-setup__increase btn-unit">
            <svg class="svg-mask"
                 viewBox="0 0 10 10"
                 version="1.1"
                 fill="transparent"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="nonzero"
                    d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447716 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447716 6 1 6H4V9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1Z"
                    fill="currentColor" />
            </svg>
          </button>
        </div>
      </div>

      <div [@opacityFadeInOut]
           *ngFor="let child of room.children; let i = index">
        <div class="{{ widgetClass }}-rooms-item__control rooms-container-item__control">
          <span class="room-unit">{{ child.fieldName }}</span>
          <app-select [selectValue]="child.age"
                      className="kids"
                      (selectValueChange)="selectChange($event, room, child.id, i)"
                      [options]="childAges"
                      [name]="child.id + i"
                      [placeholder]="child.fieldName">
          </app-select>
        </div>
      </div>
    </div>

  </div>

  <div class="rooms-container-item__actions">
    <div [@opacityFadeInOut]
         *ngIf="showRemoveButton"
         [class.only-remove-button]="!showAddButton"
         class="{{ widgetClass }}-remove-room cta-button remove-room">
      <a (click)="handleRoom($event, false)"
         class="btn btn-text">
        <span class="action-btn">Remove Room</span>
      </a>
    </div>
    <div [@opacityFadeInOut]
         *ngIf="showAddButton"
         class="cta-button">
      <a (click)="handleRoom($event, true)"
         class="btn btn-text">
        <span class="action-btn">Add Room</span>
      </a>
    </div>
  </div>
</div>