/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { AnalyticsEvent, AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { Injectable } from '@angular/core';
import { TodayDeal } from '@shared/models/today-deal.model';
import { UrlHelperService } from 'g3-common-ui';

type ActionType = 'tdd-click' | 'tdd-view' | 'tdd-reveal-code' | 'tdd-copy-code' | 'tdd-click-out';
const MAX_GA_LABEL_LENGTH = 500;

@Injectable({
  providedIn: 'root'
})
export class TodayDealsAnalyticsService {

  constructor(
    private urlHelper: UrlHelperService,
  ) { }

  getAnalyticsGALabel(todayDeal: TodayDeal) {
    let label = `${todayDeal.guid} | ${todayDeal.company_name} | ${todayDeal.headline}`;
    if (label.length > MAX_GA_LABEL_LENGTH) {
      label = label.slice(0, MAX_GA_LABEL_LENGTH);
    }
    return label;
  }

  getAnalyticsEvents(action: ActionType, todayDeal: TodayDeal, eventData?: any): AnalyticsEvent[] {
    const category = 'today-deals';
    const label = this.getAnalyticsGALabel(todayDeal);
    const clickOut = todayDeal.is_ebg || !this.urlHelper.isExternalUrl(todayDeal.url) ? 'internal' : 'external';
    const clickOutSuffix = ` | ${clickOut}`;
    const clickOutLabel = label.slice(0, MAX_GA_LABEL_LENGTH - clickOutSuffix.length) + clickOutSuffix;

    switch (action) {
      case 'tdd-click':
        return [
          new AnalyticsInternalEventModel('tdd-click', { tdd_guid: todayDeal.guid, url: todayDeal.url }),
          new AnalyticsGAEventModel('tdd-click', { action: 'tdd-click', category, label })
        ];
      case 'tdd-view':
        return [
          new AnalyticsInternalEventModel('tdd-view', { tdd_guid: todayDeal.guid }),
          new AnalyticsGAEventModel('tdd-view', { action: 'tdd-view', category, label })
        ];
      case 'tdd-reveal-code':
        return [
          new AnalyticsInternalEventModel('tdd-reveal-code', { tdd_guid: todayDeal.guid }),
          new AnalyticsGAEventModel('tdd-reveal-code', { action: 'tdd-reveal-code', category, label })
        ];
      case 'tdd-copy-code':
        return [
          new AnalyticsInternalEventModel('tdd-copy-code', { tdd_guid: todayDeal.guid }),
          new AnalyticsGAEventModel('tdd-copy-code', { action: 'tdd-copy-code', category, label })
        ];
      case 'tdd-click-out':
        return [
          new AnalyticsInternalEventModel('tdd-click-out', { tdd_guid: todayDeal.guid, url: todayDeal.url, click_out: clickOut }),
          new AnalyticsGAEventModel('tdd-click-out', { action: 'tdd-click-out', category, label: clickOutLabel })
        ];
    }
  }
}
