import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Services
import { SubscriptionsService } from './services/subscriptions.service';
import { UnsubscribeHandlerService } from './services/unsubscribes-handler.service';
import { UnsubscribeAnalyticsService } from './services/unsubscribe-analytics.service';
import { UnsubscribeOptionsService } from './services/unsubscribe-options.service';

// Components
import { UnsubscribeOptionComponent } from './components/unsubscribe-option/unsubscribe-option.component';
import { UnsubscribeOptionFormComponent } from './components/unsubscribe-option-form/unsubscribe-option-form.component';
import { UnsubscribePageComponent } from './components/unsubscribe-page/unsubscribe-page.component';
import { UnsubscribeSuccessPageComponent } from './components/unsubscribe-success-page/unsubscribe-success-page.component';
import { UnsubscribeConfirmationModalComponent } from './components/unsubscribe-confirmation-modal/unsubscribe-confirmation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    UnsubscribePageComponent,
    UnsubscribeOptionComponent,
    UnsubscribeOptionFormComponent,
    UnsubscribeSuccessPageComponent,
    UnsubscribeConfirmationModalComponent
  ],
  exports: [
    UnsubscribePageComponent
  ],
  providers: [
    SubscriptionsService,
    UnsubscribeHandlerService,
    UnsubscribeAnalyticsService,
    UnsubscribeOptionsService
  ]
})
export class UnsubscribeModule { }
