/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export class TodayDeal {
  public id: number;
  public cat_code?: number[];
  public keywords?: string[];
  public stop_keywords?: string[];
  public area_countries?: string[];
  public pinned?: boolean;
  public status?: string;
  public created_date?: Date;
  public updated_date?: Date;
  public company_name?: string;
  public company_brand_tag_logo?: string;
  public headline?: string;
  public tag_text?: string;
  public coupon_code?: string;
  public orig_price?: number;
  public curr_price?: number;
  public offer_group?: boolean;
  public url?: string;
  public is_ebg: boolean;
  public image_url?: string;
  public time_zone?: string;
  public start_date?: Date;
  public end_date?: Date;
  public guid?: string;
  public index?: number;
  public viewed?: boolean;
  public views_total: number;
  public new_tab?: boolean;
  public shopping_category?: string;
  public url_type?: string;
  public constructor(data: any = {}) {
    Object.assign(this, data);

    if (data.destinationUrl) {
      this.url = data.destinationUrl;
    }

    if (data.title_short) {
      this.headline = data.title_short;
    }

    if (data.image) {
      this.image_url = data.image;
    }

    if (data.vendor) {
      this.company_name = data.vendor;
    }

    if (data.vendor_image) {
      this.company_brand_tag_logo = data.vendor_image;
    }

    if (data.hasOwnProperty('destination_url_new_tab')) {
      this.new_tab = data.destination_url_new_tab;
    }

    if (data.code) {
      this.coupon_code = data.code;
    }

    if (data.title_short) {
      this.headline = data.title_short;
    }

    if (data.urlType) {
      this.url_type = data.urlType;
    }
  }

  public hasCouponCode(): boolean {
    return !!this.coupon_code;
  }
}
