import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { OPACITY_FADE_IN_OUT_ANIMATION, WIDTH_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { WindowHelper } from 'g3-common-ui';
import { Tabset } from './tabset.interface';

const DEFAULT_WIDTH = 100;

@Component({
  selector: 'app-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION, WIDTH_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsetComponent implements OnInit, AfterViewInit {

  @Input() customClass = '';
  @Input() tabsetData: Tabset[] = [];
  @Input() defaultIndex = 0;
  @Input() groupTabIndex = 100;

  @Output() selectedTabId: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('tabsetContainer') tabsetContainer: QueryList<ElementRef>;

  activeTabIndex: number;
  isMobile: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const target = event.target as Window;
    this.isMobile = target.innerWidth < WindowHelper.SIZE_M;
    this.calculateMobileTabWidth();
  }

  ngOnInit(): void {
    this.initTabset();
  }

  ngAfterViewInit(): void {
    this.calculateMobileTabWidth();
  }

  isActiveTab(index: number): boolean {
    return index === this.activeTabIndex;
  }

  onTabClick(selectedTab: Tabset): void {
    this.activeTabIndex = this.tabsetData.indexOf(selectedTab);
    this.selectedTabId.emit(selectedTab.id);
  }

  private initTabset(): void {
    this.activeTabIndex = this.defaultIndex;
    this.isMobile = window.innerWidth < WindowHelper.SIZE_M;
    this.selectedTabId.emit(this.tabsetData[this.defaultIndex].id);
  }

  private calculateMobileTabWidth(): void {
    if (this.tabsetContainer && this.tabsetContainer.length) {
      this.tabsetContainer.forEach((element: ElementRef) =>
        element.nativeElement.style.width = this.isMobile ? `${DEFAULT_WIDTH / this.tabsetData.length}%` : 'auto');
    }
  }

}
