import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ColorConverterService } from '@app/shared/services/color-converter.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ConfigService } from '@app/shared/services/config.service';
import { WindowHelper } from 'g3-common-ui';
import { ZoneElementTypes, ZonesService } from '@app/zones/services/zones.service';
import { ZoneSectionElement } from '@app/zones/interfaces/zones-search-response.interface';
import { ZonesSectionElementsAnalyticsService } from '@app/zones/services/analytics/zones-section-elements-analytics.service';

const DEFAULT_PINNED_COLOR = '#757575';

@Component({
  selector: 'app-pinned-zone',
  templateUrl: './pinned-zone.component.html',
  styleUrls: ['./pinned-zone.component.less']
})

export class PinnedZoneComponent implements OnInit {

  zoneElementTypes = ZoneElementTypes;
  zoneElement: ZoneSectionElement = null;
  hideGhostEffect = true;
  isDesktop = true;
  isPinnedZone = true;
  extendedByOECards = false;
  backgroundColor: string;


  constructor(
    public activatedRoute: ActivatedRoute,
    private zonesService: ZonesService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private colorConverterService: ColorConverterService,
    private zonesSectionsAnalyticsService: ZonesSectionElementsAnalyticsService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const target = event.target as Window;
    this.isDesktop = target.innerWidth >= WindowHelper.SIZE_M;
  }

  async ngOnInit(): Promise<void> {
    this.isDesktop = window.innerWidth >= WindowHelper.SIZE_M;

    this.zoneElement = await this.zonesService.getZoneElementByQueryParam('homepage', this.activatedRoute.snapshot.queryParams);

    if (this.zoneElement) {
      this.backgroundColor = this.getGradientForZone(this.zoneElement);

      if (this.zoneElement.double_wide && (this.zoneElement.type === ZoneElementTypes.CardsScroll
        || this.zoneElement.type === ZoneElementTypes.CardsFixed)) {
        this.zoneElement = { ...this.zoneElement, double_wide: false };
      }
    }
  }

  getGradientForZone(zoneElement: ZoneSectionElement): string {

    const brandColors = this.configService.getOption('colors', null);
    let pinnedColor = DEFAULT_PINNED_COLOR;

    if (zoneElement.pinned_color_override) {
      const brandColor = brandColors[zoneElement.pinned_color_override.toLowerCase()];

      pinnedColor = brandColor
        ? brandColor
        : zoneElement.pinned_color_override;
    }

    return this.getCustomLinearGradiend(pinnedColor);
  }

  getCustomLinearGradiend(color: string): string {

    return `linear-gradient(0deg, ${this.colorConverterService.getRGBAColor(color, 0.1)} 0%, #fff 100%)`;
  }

  onInitElement(zoneSectionElement: ZoneSectionElement): void {
    this.analyticsService.eventsTrack(
      this.zonesSectionsAnalyticsService.getAnalyticsEvents('items-selected', zoneSectionElement)
    );
  }

  onDisplayedElement(zoneSectionElement: ZoneSectionElement): void {
    this.analyticsService.eventsTrack(
      this.zonesSectionsAnalyticsService.getAnalyticsEvents('view-ads', zoneSectionElement)
    );
  }

}
