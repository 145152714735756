/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import { CtaAd, CtaOptions, Zone } from '@zones/services/cta-element.service';

export function getAnalyticsActionByZoneSectionElement(item: ZoneSectionElement, action: string) {
  return `zone-${item.type.replace('_', '-')}-${action}`;
}

export function getAnalyticsGACategoryByZoneSectionElement(item: ZoneSectionElement): string {
  return `zone-${item.type.replace('_', '-')}`;
}

export function getAnalyticsGALabelFromObject(data) {
  return Object.values(data).join(' | ');
}

export function getAnalyticsAdByCtaOptions(item: CtaOptions): CtaAd {
  return {
    guid: item.guid,
    type: item.type,
    name: item.campaign || ''
  };
}

export function getAnalyticsZoneByZoneSectionElement(item: ZoneSectionElement): Zone {
  return {
    guid: item.guid,
    type: item.type,
    name: item.name,
    category: item.cat_code_tags || []
  };
}
