<div class="modal-header">
  <div class="modal-header-container">
    <h3 class="modal-title">
      {{ getMarketplaceName() }} Disclaimer
    </h3>
    <p class="modal-subtitle" #disclaimerContent
      [innerHTML]="getDisclaimerContent()"></p>
  </div>

  <div class="modal-action" #disclaimerBottom>
    <button (click)="closeModal()"
            class="btn btn-primary modal-action-btn">
      accept
    </button>
  </div>
</div>
