/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { EnrollmentInfo, EnrollmentResponse } from '@app/shared/services/config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionService } from '@core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';

@Injectable({
  providedIn: 'root'
})
export class OpenEnrollmentService {

  public enrollmentData: EnrollmentInfo;
  private enrollmentSource = new BehaviorSubject<EnrollmentInfo | null>(undefined);

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
  ) { }

  public async fetchEnrollment(isAdminMode = false, country: string): Promise<void> {
    const data = await this.getEnrollmentData(isAdminMode);
    if (!data || data.countries.indexOf(country) === -1) {
      this.enrollmentData = null;
      this.enrollmentSource.next(this.enrollmentData);
      return;
    }

    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    const now = new Date();
    const isEnrollmentAvailable = startDate <= now && endDate >= now;

    this.enrollmentData = {
      ...data,
      start_date: startDate,
      end_date: endDate,
      isEnrollmentAvailable
    };


    this.enrollmentSource.next(this.enrollmentData);
  }

  public async getEnrollmentData(isAdminMode = false): Promise<EnrollmentResponse> {
    const params: Params = {};

    if (this.permissionService.hasDefined('known:access')) {
      params['known_user_email'] = this.knownUserService.knowUserEmail;
      if (this.knownUserService.knownUserCountry) {
        params['country'] = this.knownUserService.knownUserCountry;
      }
    }

    if (isAdminMode) {
      params['is_admin_mode'] = true;
    }

    return this.http.get<EnrollmentResponse>(`${environment.apiUrl}/api/marketplace/enrollment-info`, { params }).toPromise();
  }

  public updateEnrollmentAd(guid: string, data: any): void {
    this.enrollmentData.products = this.enrollmentData.products.map(offer => {
      if (offer.contentGuid === guid) {
        Object.keys(data).forEach(field => {
          if (offer.hasOwnProperty(field)) {
            offer[field] = data[field];
          }
        });
      }
      return offer;
    });
  }

  public getEnrollmentInfo(): Observable<EnrollmentInfo> {
    return this.enrollmentSource.asObservable();
  }
}
