<div class="modal-header">
  <div class="modal-edit-notification-icon">
    <img alt="Check mark icon"
         role="presentation"
         aria-hidden="true"
         loading="lazy"
         src="assets/icons/ic-check-mark.svg">
  </div>
  <div class="title">{{ headline }}</div>

  <button type="button"
          role="button"
          class="close"
          aria-label="Close"
          ngbAutofocus
          (click)="modalService.closeModal()">
    <span aria-hidden="true"
          class="close-icon">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="info-text">{{ description }}</div>
  <img class="modal-body__image"
       [src]="sampleUrl"
       loading="lazy"
       alt="edit mode preview" />
</div>

<div class="modal-footer">
  <button type="button"
          role="button"
          class="btn btn-primary btn-close"
          (click)="modalService.closeModal()">Ok, Got It!</button>
  <a class="btn btn-text btn-underline"
     href="{{ destinationUrl }}">
    <span>Manage Chosen Offers</span>
  </a>
</div>
