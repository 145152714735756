// Mini Widgets
export const TAW_HOTELS_MINI = 'taw_hotels_mini';
export const TRUECAR_MINI = 'truecar_mini';
export const INSURANCE_MINI = 'insurance_mini';
export const RENTAL_CAR_MINI = 'rental_car_mini';
export const FILLER_MINI = 'filler_mini_widget';
export const TICKETS_MINI = 'tickets_mini';

// Large Widgets
export const TAW_HOTELS_LARGE = 'taw_hotels_lg';
export const TRUECAR_LARGE = 'truecar_lg';

// Search Widgets
export const TAW_HOTELS_SEARCH = 'taw_hotels_search';
export const MULTI_SEARCH = 'multi';
export const RENTAL_CAR_SEARCH = 'rental_car_search';
export const TICKETS_SEARCH = 'tickets_search';

export const CustomHotelWidgetIds = [TAW_HOTELS_LARGE, TAW_HOTELS_MINI, TAW_HOTELS_SEARCH] as const;
export const CustomTrueCarWidgetIds = [TRUECAR_LARGE, TRUECAR_MINI] as const;
export const CustomInsuranceWidgetIds = [INSURANCE_MINI] as const;
export const CustomRentalCarWidgetIds = [RENTAL_CAR_MINI, RENTAL_CAR_SEARCH] as const;
export const CustomTicketsWidgetIds = [TICKETS_MINI, TICKETS_SEARCH] as const;
export const CustomOtherWidgetIds = [FILLER_MINI, MULTI_SEARCH] as const;

export const ValidCustomWidgetIds = [
  ...CustomHotelWidgetIds,
  ...CustomTrueCarWidgetIds,
  ...CustomInsuranceWidgetIds,
  ...CustomRentalCarWidgetIds,
  ...CustomTicketsWidgetIds,
  ...CustomOtherWidgetIds
] as const;
export type ValidCustomWidgetIdTypes = (typeof ValidCustomWidgetIds)[number];

export const isValidWidgetId = <T extends ValidCustomWidgetIdTypes>(target: string | T): target is T => ValidCustomWidgetIds.some(id => (id as string) === target);
