/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import * as io from 'socket.io-client';
import { AuthService } from '@app/core/auth/services/auth.service';
import { environment } from '@environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { OneTrustService, OneTrustConsentService } from 'g3-common-ui';
import { take } from 'rxjs/operators';
import { PermissionService } from '@app/core/auth/services/permission.service';

@Injectable()
export class G3analyticsService {
  private socket: SocketIOClient.Socket;
  private trackingAllowed = false;
  private isSessionSet = false;
  private countryCode: string;

  constructor(
    private ngZone: NgZone,
    private oneTrustService: OneTrustService,
    private oneTrustConsentService: OneTrustConsentService,
    private permissionService: PermissionService,
    private auth: AuthService
  ) { }

  public connect(): void {
    this.createConnection();

    this.oneTrustService.inited$.pipe(take(1)).subscribe((isTrackingBlockedByDefault: boolean) => {
      if (!isTrackingBlockedByDefault) {
        this.trackingAllowed = true;
      }

      const isUserConsentGiven = this.oneTrustConsentService.isUserConsentGiven();
      // user did not select any option yet. use default behavior
      if (!isUserConsentGiven) {
        this.onConnect();
      }

      this.oneTrustService.getConsentChange().subscribe(() => {
        this.trackingAllowed = this.oneTrustService.isG3AnalyticsActive();

        // if it's not the first session, close previous and start new
        if (this.isSessionSet) {
          this.disconnect();
          this.createConnection();

          // pass previous session's country to the new session
          if (this.countryCode) {
            this.emitCountry();
          }
        }

        this.onConnect();
      });
    });
  }

  private createConnection(): void {
    this.ngZone.runOutsideAngular(() => {
      this.socket = io.connect(`${environment.wsUrl}/g3analytics`, {
        transports: ['websocket']
      });
    });
  }

  private onConnect(): void {
    this.isSessionSet = true;
    this.ngZone.runOutsideAngular(() => {
      if (this.trackingAllowed) {
        // Send access token in authorize message, if we have one
        const accessToken = this.auth.getAccessToken();
        const shouldUseBearerToken = !!accessToken;
        if (shouldUseBearerToken) {
          this.socket.emit('authorize', {
            authorization: `Bearer ${accessToken}`
          });
        }
      } else {
        const isGuest = this.permissionService.hasDefined('guest:access');
        // for now anonymized users and anonymized guests both go to known
        this.socket.emit('session_type', {
          type: 'known'
        });
      }
    });
  }

  public getSocket(): SocketIOClient.Socket {
    return this.socket;
  }

  public eventTrack(name: string, payload: any, url = ''): void {
    this.ngZone.runOutsideAngular(async () => {
      for (let i = 0; i < 10; i++) {
        if (this.socket && this.socket.connected) {
          this.socket.emit('event', { type: name, data: payload, url });
          break;
        } else {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    });
  }

  public updateCountry(countryCode: string): void {
    this.countryCode = countryCode;
    this.emitCountry();
  }

  private emitCountry(): void {
    if (this.socket) {
      this.socket.emit('update_country', { country_code: this.countryCode });
    }
  }

  public disconnect(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.socket) {
        this.socket.disconnect();
      }
    });
  }
}
