<section *ngFor="let option of unsubscribeOptions; let i = index"
         class="unsubscribe-option">
  <div class="unsubscribe-option-title">
    <h3 class="unsubscribe-option-title__primary">
      {{ option.header }}
    </h3>
    <h5 [innerHTML]="option.subheader"
        class="unsubscribe-option-title__secondary"></h5>
  </div>

  <div *ngIf="option.content"
       class="unsubscribe-option-form">
    <app-unsubscribe-option-form [buttonId]="option.buttonId"
                                 [submitFormState]="submitFormState"
                                 [formType]="option.formType"
                                 [unsubscribeOptionContent]="option.content"
                                 (successHeaders)="onSuccessHeaders($event)"
                                 (isSelectedValuesExist)="onSelectedValuesExist($event)"
                                 (selectedUnsubscribes)="onSelectedUnsubscribes($event)">
    </app-unsubscribe-option-form>
  </div>

  <div [class.update-button]="option.buttonType === 'update'"
       class="unsubscribe-option-action">
    <button tabindex="200 + i"
            (click)="submitForm(option.buttonId)"
            class="btn btn-primary unsubscribe-option-action__button"
            [class.secondary-button]="option.buttonType === 'secondary'">
      {{ option.buttonTitle }}
    </button>
  </div>
</section>