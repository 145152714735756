/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Subscription } from 'rxjs';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter, Input, OnDestroy,
  OnInit, Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-slide-carousel',
  templateUrl: './slide-carousel.component.html',
  styleUrls: ['./slide-carousel.component.less']
})
export class SlideCarouselComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() containerOffsetLeft = 0;
  @Input() containerTranslateX = 8;
  @Input() wrapperHeight = 270;
  @Input() buttonOffsetTop = 0;
  @Input() buttonOffsetBottom;
  @Input() elementWidth = 0;
  @Input() animationDuration = 250;
  @Input() itemSpacing = 8;
  @Input() itemsPerSlide = 2;
  @Input() showNav = true;
  @Input() autoplayTimer = 0;
  @Input() overflowVisible = false;
  @Input() carouselClass = '';
  @Input() updateOnResize = true;
  @Input() autoplaySlideSampleClass = '';
  @Input() slidesPerInfiniteUpdate = 0;
  @Input() arrowCenterElement;
  @Input() enableIndicator = false;
  @Input() indicatorSize = 0;
  @Input() indicatorPosition = 0;
  @Input() carouselElements: any;

  @Output() infiniteStart = new EventEmitter<{ isSwipe: boolean }>();
  @Output() infiniteEnd = new EventEmitter<{ isSwipe: boolean }>();

  //  @ViewChildren('carouselElements') carouselElements: QueryList<any>;
  @ViewChild('carouselContainer') carouselContainer: ElementRef;

  isShowLoadMore = true;
  carouselSub: Subscription;
  counter = 0;
  autoplayFn: any;
  transitionState = false;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (this.carouselElements && this.carouselElements.changes) {
      this.carouselSub = this.carouselElements.changes.subscribe(() => {
        this.getCarouselDimensions();
      });
    }

    // auto play magic here:
    if (this.autoplayTimer > 0) {
      this.autoplayFn = setInterval(() => {
        this.transitionState = true;
        // auto play sample is the gauge element to get slide width from
        if (this.autoplaySlideSampleClass) {
          const slideSample = document.getElementsByClassName(this.autoplaySlideSampleClass)[0];
          this.containerTranslateX -= slideSample.clientWidth;
          if (this.elementWidth) {
            this.slidesPerInfiniteUpdate = Math.round(this.elementWidth / slideSample.clientWidth);
          }
        } else {
          this.containerTranslateX = -this.elementWidth;
        }
        this.counter++;
        setTimeout(() => {
          this.transitionState = false;
          // if whole screen is being slided - reset all counters and offsets, rebuild screens for infinite animation
          if (this.elementWidth && this.slidesPerInfiniteUpdate && this.counter === this.slidesPerInfiniteUpdate) {
            this.infiniteEnd.emit(); // normally push -> shift
            this.containerTranslateX = 0;
            this.counter = 0;
          }
        }, this.animationDuration);
      }, this.autoplayTimer);
    }
  }

  ngAfterViewChecked(): void {
    this.getCarouselDimensions();
  }

  prev(userAction = false, isSwipe = false): void {
    if (userAction && this.autoplayTimer) {
      clearInterval(this.autoplayFn);
    }
    this.transitionState = true;
    this.containerTranslateX = this.elementWidth;
    setTimeout(() => {
      this.infiniteStart.emit({ isSwipe }); // normally unshift -> pop
      this.transitionState = false;
      this.containerTranslateX = 0;
    }, this.animationDuration);
  }

  next(userAction = false, isSwipe = false): void {
    if (userAction && this.autoplayTimer) {
      clearInterval(this.autoplayFn);
    }
    this.transitionState = true;
    this.containerTranslateX = -this.elementWidth;
    setTimeout(() => {
      this.infiniteEnd.emit({ isSwipe }); // normally push -> shift
      this.transitionState = false;
      this.containerTranslateX = 0;
    }, this.animationDuration);
  }

  getCarouselDimensions(e?: any): void {
    const button: HTMLElement = document.querySelector('.btn-square');
    const buttonHeight = button.offsetHeight;
    this.wrapperHeight = this.carouselContainer.nativeElement.offsetHeight;
    const arrowContainer = document.querySelector(this.arrowCenterElement);
    if (arrowContainer) {
      this.buttonOffsetTop = (arrowContainer.offsetHeight - buttonHeight) / 2 + 32;
    }
    this.carouselElements = document.getElementsByClassName('carousel-item');
    if (this.carouselElements && this.carouselElements[0]) {
      this.elementWidth = this.carouselElements[0].offsetWidth;
    }
    if (this.autoplaySlideSampleClass) {
      const slideSample = document.getElementsByClassName(this.autoplaySlideSampleClass)[0];
      this.containerOffsetLeft = -slideSample.clientWidth;
    } else if (this.itemsPerSlide === 1) {
      this.containerOffsetLeft = - this.elementWidth;
    } else if (!this.containerOffsetLeft) {
      this.containerOffsetLeft = - (this.itemSpacing * (this.itemsPerSlide + 1) + this.elementWidth);
    }
    this.cdr.detectChanges();
  }

  checkWidth() {
    const windowWidth = window.innerWidth;
    return windowWidth >= 1024;
  }

  arrayOf(size: number) {
    return Array.from(Array(size).keys());
  }

  ngOnDestroy(): void {
    if (this.carouselSub) {
      this.carouselSub.unsubscribe();
    }
  }

}
