<button class="btn btn-icon btn-share"
        [placement]="placement"
        [ngbTooltip]="tooltipText"
        triggers="click"
        container="body"
        [appAutoTabIndex]="-1"
        [tooltipClass]="customClass"
        #tooltip="ngbTooltip"
        (click)="copyText($event)">
  <svg class="share-ico"
       width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <circle fill="currentColor"
            stroke="currentColor"
            cx="12.5"
            cy="2.5"
            r="2.5" />
    <circle fill="currentColor"
            stroke="currentColor"
            cx="12.5"
            cy="13.5"
            r="2.5" />
    <circle fill="currentColor"
            stroke="currentColor"
            cx="3.4"
            cy="8.4"
            r="2.4" />
    <path d="M12.6778 10.2791C11.7575 10.2791 10.9369 10.7176 10.412 11.3953L6.01664 8.9402C6.11963 8.64452 6.1761 8.32558 6.1761 7.99668C6.1761 7.66445 6.11963 7.34884 6.01331 7.04983L10.4053 4.59801C10.9269 5.27907 11.7509 5.72093 12.6744 5.72093C14.2492 5.72093 15.5349 4.43854 15.5349 2.86046C15.5349 1.28239 14.2525 0 12.6744 0C11.0964 0 9.81398 1.28239 9.81398 2.86046C9.81398 3.19269 9.87046 3.51163 9.97677 3.80731L5.58806 6.25914C5.06647 5.57475 4.24255 5.13621 3.31896 5.13621C1.74421 5.13621 0.458496 6.4186 0.458496 7.99668C0.458496 9.57475 1.74421 10.8571 3.32228 10.8571C4.24587 10.8571 5.06979 10.4153 5.59471 9.7309L9.98674 12.186C9.88042 12.485 9.82062 12.8073 9.82062 13.1395C9.82062 14.7143 11.103 16 12.6811 16C14.2592 16 15.5416 14.7176 15.5416 13.1395C15.5416 11.5615 14.2558 10.2791 12.6778 10.2791ZM12.6778 0.900332C13.7608 0.900332 14.6412 1.78073 14.6412 2.86379C14.6412 3.94684 13.7608 4.82724 12.6778 4.82724C11.5947 4.82724 10.7143 3.94684 10.7143 2.86379C10.7143 1.78073 11.598 0.900332 12.6778 0.900332ZM3.32228 9.96013C2.23923 9.96013 1.35883 9.07973 1.35883 7.99668C1.35883 6.91362 2.23923 6.03322 3.32228 6.03322C4.40534 6.03322 5.28574 6.91362 5.28574 7.99668C5.28574 9.07973 4.40202 9.96013 3.32228 9.96013ZM12.6778 15.0997C11.5947 15.0997 10.7143 14.2193 10.7143 13.1362C10.7143 12.0532 11.5947 11.1728 12.6778 11.1728C13.7608 11.1728 14.6412 12.0532 14.6412 13.1362C14.6412 14.2193 13.7608 15.0997 12.6778 15.0997Z"
          fill="currentColor" />
    <path d="M12.6778 10.2791C11.7575 10.2791 10.9369 10.7176 10.412 11.3953L6.01664 8.9402C6.11963 8.64452 6.1761 8.32558 6.1761 7.99668C6.1761 7.66445 6.11963 7.34884 6.01331 7.04983L10.4053 4.59801C10.9269 5.27907 11.7509 5.72093 12.6744 5.72093C14.2492 5.72093 15.5349 4.43854 15.5349 2.86046C15.5349 1.28239 14.2525 0 12.6744 0C11.0964 0 9.81398 1.28239 9.81398 2.86046C9.81398 3.19269 9.87046 3.51163 9.97677 3.80731L5.58806 6.25914C5.06647 5.57475 4.24255 5.13621 3.31896 5.13621C1.74421 5.13621 0.458496 6.4186 0.458496 7.99668C0.458496 9.57475 1.74421 10.8571 3.32228 10.8571C4.24587 10.8571 5.06979 10.4153 5.59471 9.7309L9.98674 12.186C9.88042 12.485 9.82062 12.8073 9.82062 13.1395C9.82062 14.7143 11.103 16 12.6811 16C14.2592 16 15.5416 14.7176 15.5416 13.1395C15.5416 11.5615 14.2558 10.2791 12.6778 10.2791ZM12.6778 0.900332C13.7608 0.900332 14.6412 1.78073 14.6412 2.86379C14.6412 3.94684 13.7608 4.82724 12.6778 4.82724C11.5947 4.82724 10.7143 3.94684 10.7143 2.86379C10.7143 1.78073 11.598 0.900332 12.6778 0.900332ZM3.32228 9.96013C2.23923 9.96013 1.35883 9.07973 1.35883 7.99668C1.35883 6.91362 2.23923 6.03322 3.32228 6.03322C4.40534 6.03322 5.28574 6.91362 5.28574 7.99668C5.28574 9.07973 4.40202 9.96013 3.32228 9.96013ZM12.6778 15.0997C11.5947 15.0997 10.7143 14.2193 10.7143 13.1362C10.7143 12.0532 11.5947 11.1728 12.6778 11.1728C13.7608 11.1728 14.6412 12.0532 14.6412 13.1362C14.6412 14.2193 13.7608 15.0997 12.6778 15.0997Z"
          fill="currentColor"
          fill-opacity="0.25" />
  </svg>
</button>