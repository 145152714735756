import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  changeFeedbackButtonVisibility(visible: boolean): void {
    const styleId = 'QSIFeedbackButton-style';
    const style = this.document.querySelector(`style#${styleId}`) || this.document.createElement('style');
    style.id = styleId;

    style.innerHTML = `
      #QSIFeedbackButton-btn {
        display: ${visible ? 'block' : 'none'};
      }
    `;
    if (!this.document.querySelector(`style#${styleId}`)) {
      this.document.head.appendChild(style); 
    }
  }
}
