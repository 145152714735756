/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router
} from '@angular/router';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { CHANGE_WIDTH_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ComponentHtmlIdRegistryService,
  FocusEmitterService,
  focusEvents,
  ImageUrlPipe,
  KnownUserType,
  MarketplaceFeaturesResponse,
  Notification,
  PlatformCountry,
  SystemMessageService,
  UrlHelperService,
  WINDOW
} from 'g3-common-ui';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import { ConfigService, CorporateLinks, CustomLinkResponse } from '@app/shared/services/config.service';
import { map, take } from 'rxjs/operators';
import { NavDynamicItem, NavDynamicResponse } from '@core/layouts/logged-in/interfaces/nav-dynamic-section.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { OffersListAdminLayoutService } from '@shared/components/offers-list/offers-list-admin/offers-list-admin-layout.service';
import { PermissionService } from '@core/auth/services/permission.service';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { SearchService } from '@app/shared/services/search/search.service';
import { SessionStorageService } from '@widgets/services/session-storage.service';
import { Observable, Subscription } from 'rxjs';
import { supportOptions } from './header.constants';
import { TOGGLE_MENU_ANIMATION } from '@shared/animations/toggle-menu.animation';
import { UserModel } from '@app/core/auth/services/user.model';
import { ModalService } from '@app/shared/services/modal.service';
import { ModalNotificationComponent } from '@app/shared/components/modal-notification/modal-notification.component';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { KnownUserPasswordService } from '@shared/services/known-user-password.service';
import { MregService } from '@app/shared/services/mreg/mreg.service';
import { CorporateRedirectService } from '@app/core/auth/services/corporate-redirect.service';
import { COUNTRY_LAST_SESSION_KEY } from '@app/shared/constants/profile.constants';
import { IHeaderMenuItem } from '@shared/interfaces/header-menu-item.interface';
import { EmployerPortalAccessResponse } from '@app/employer-portal/interfaces/employer-portal-access.interface';
import { EmployerPortalService } from '@app/employer-portal/services/employer-portal.service';
import { AccountConfirmationStep } from '@app/shared/services/account-confirmation.interface';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';

const attributeKeys = {
  inline_admin: 'inlineEditStatus',
  homepage_takeover: 'takeover',
};

interface CustomLinks {
  myProfile: CustomLinkResponse[];
  myOffers: CustomLinkResponse[];
}

export interface NavLinkEventData {
  offsetLeft: number;
  offsetWidth: number;
}

export interface CollapseMenu {
  title: string;
  itemClass: string;
  itemStyle: string;
}

export interface SupportOptions {
  name: string;
  icon: string;
  href: string;
}

const ADMIN_EDIT_MODE_NAME = 'admin_edit_mode';
const NOT_ALLOWED_ACCESS_ERROR_MESSAGE = 'You do not have permission to access this mode';
const ERROR_MESSAGE_TIME = 10000;
const SIGN_IN_OR_REGISTER = 'Sign in / Register';
const DEFAULT_COUNTRY = 'US';
const refreshOptions = ['this', 'bp', 'wag3', 'all'];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  animations: [TOGGLE_MENU_ANIMATION, CHANGE_WIDTH_ANIMATION],
  providers: [
    ComponentHtmlIdRegistryService
  ]
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Output() showContactUs: EventEmitter<string> = new EventEmitter<string>();

  @Input() wideContainerClass = '';
  @Input() isFullScreenMap = '';
  @Input() customLinksMyProfile = [] as CustomLinkResponse[];
  @Input() customLinksMyOffers = [] as CustomLinkResponse[];
  @Input() notificationBanner = {} as Notification;

  userName: string;
  avatarLetter: string;
  isLoggedIn = false;
  showMultiCountryOptions: boolean;
  showSubMenus = {
    profile: false,
    offers: false,
    countries: false,
    support: false,
    currency: false
  };

  customLinks: CustomLinks = {
    myProfile: [],
    myOffers: []
  };

  countries: PlatformCountry[] = [];
  countryOptions: Partial<PlatformCountry>[] = [];
  otherCountries: PlatformCountry[] = [];
  currentUserCountry: PlatformCountry = {} as any;
  supportOptions = supportOptions as SupportOptions[];

  navigationConfig: NavDynamicResponse = {} as NavDynamicResponse;
  primaryNavItems: NavDynamicItem[] = [] as NavDynamicItem[];

  isMenuShown = false;
  marketplaceName: string;
  hasSecurityTab: boolean;
  employerPortalAccess: EmployerPortalAccessResponse;
  hasControlPanelAccess: boolean;
  hasSubscriptionCenterAccess = false;
  subscriptions: Subscription[] = [];

  hasInlineEditAccess = false;
  adminAccess = {
    admin_edit_mode: ''
  };

  mobileMenu: any[] = [];
  features: MarketplaceFeaturesResponse;
  clientOfferLink: string;

  offersSearchOptions: {
    title: string;
    link: string;
    linkClass?: string;
    iconType?: string;
    queryParams?: object;
    icon?: string;
  }[] = [];

  animationState: string;
  MOBILE_VIEWPORT_WIDTH = 767;
  DESKTOP_VIEWPORT_WIDTH = 1024;
  WIDE_DESKTOP = 1280;
  isDesktop: boolean;
  isTablet: boolean;
  isTableOrMobile: boolean;
  navGuid: string;
  isProfileFeature = '';
  productQuantity: number;
  public corporateQueryParams = '';

  public currentUrl = '';

  public hasTakeoverAccess = false;
  public isGuestOrKnownMode = true;
  public profileTitle = SIGN_IN_OR_REGISTER;
  public openAccessTitle = '';
  public showConfirm = false;
  public showPassword = false;
  public showCurrency = false;

  public isLoyaltyEnabled = false;
  public isGuestAutologin = false;
  public isNameHidden = false;

  public accountConfirmationOpen$: Observable<boolean>;

  public orderHistoryUrl = '';
  public funlifeHistoryUrl = '';
  public isShoppingCartAvailable: boolean;
  public DEFAULT_COUNTRY = DEFAULT_COUNTRY;

  private isHomepageModalOpened = false;

  constructor(
    private permissionService: PermissionService,
    private profileService: ProfileService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private searchService: SearchService,
    private focusEmitterService: FocusEmitterService,
    public idRegistryService: ComponentHtmlIdRegistryService,
    @Inject(WINDOW) private window: WINDOW,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private offersListAdminLayoutService: OffersListAdminLayoutService,
    private imageUrlPipe: ImageUrlPipe,
    private urlHelper: UrlHelperService,
    private sessionStorageService: SessionStorageService,
    private systemMessageService: SystemMessageService,
    private modalService: ModalService,
    private knownUserService: KnownUserService,
    private accountConfirmationService: AccountConfirmationService,
    private knownUserPasswordService: KnownUserPasswordService,
    private mregService: MregService,
    private corporateRedirectService: CorporateRedirectService,
    private employerPortalService: EmployerPortalService,
    private element: ElementRef,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) {

    this.features = this.configService.getOption<any>('features', {});

    this.marketplaceName = this.configService.getOption<string>('name', '');
    this.hasSecurityTab = this.configService.getOption<boolean>('is_require_password', false);
    this.hasControlPanelAccess = this.permissionService.hasDefined('controlpanel:view');
    this.hasInlineEditAccess = this.permissionService.hasDefined('offers:edit:platform');
    this.hasTakeoverAccess = this.permissionService.hasDefined('homepage:takeover');

    this.countries = this.configService.getOption<PlatformCountry[]>('allowed_countries', []);
    this.countryOptions = this.configService.getOption<PlatformCountry[]>('country_options', []);
    this.showMultiCountryOptions = this.countries.length > 1;

    this.isLoyaltyEnabled = this.configService.getOption<boolean>('loyalty');
    this.isGuestAutologin = this.configService.getOption<string>('sign_in_mode', '') === 'unseeded_pass_autoguest';

    this.mobileMenu = this.getInitialMobileMenu();

    this.subscriptions.push(
      this.router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.isMenuShown = false;
          this.currentUrl = e.urlAfterRedirects ? e.urlAfterRedirects.toString().split('?')[0] : '';

          // resets tab navigation to begin tab navigation from skip to controls
          setTimeout(() => document.getElementById(this.idRegistryService.get('reset-focus')).focus());

          this.mobileMenu.forEach(item => item.isCollapsed = true);
          if (e.url.includes('my-offers')) {
            this.mobileMenu[1].isCollapsed = false;
          } else if (e.url.includes('my-profile')) {
            this.mobileMenu[0].isCollapsed = false;
          }
        }
      })
    );

    this.accountConfirmationOpen$ = accountConfirmationService.accountConfirmationOpen$;

    // NB: It is an example. Can be used along with the async pipe to expand the user password dropdown.
    this.knownUserPasswordService.isPasswordDialogOpen.subscribe((value) => {
      if (value) {
        this.accountConfirmationService.checkAndOpenPasswordDialog();
      } else {
        this.onOutsideOfAccountConfirmationClick(AccountConfirmationStep.SetPassword);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(e: any): void {
    this.isDesktop = e.target.innerWidth > this.MOBILE_VIEWPORT_WIDTH;
    this.isTablet = e.target.innerWidth > this.MOBILE_VIEWPORT_WIDTH && e.target.innerWidth < this.DESKTOP_VIEWPORT_WIDTH;
    this.isTableOrMobile = e.target.innerWidth < this.DESKTOP_VIEWPORT_WIDTH;
  }

  public async ngOnInit(): Promise<void | string> {
    const params: Params = this.activatedRoute.snapshot.queryParams;
    this.isShoppingCartAvailable = this.urlHelper.isWorkingAdvantage();

    this.subscriptions.push(this.knownUserService._knowUserType$
      .subscribe((type: KnownUserType) => {
        const isGuest = this.permissionService.hasDefined('guest:access');
        if (isGuest) {
          this.showConfirm = false;
          return;
        }

        if (!type) {
          type = this.knownUserService.knowUserTypeFromStorage || '' as KnownUserType;
        }

        const blockedByAuthFlow = this.knownUserService.isKnownSkipConfirm || this.knownUserService.isKnownSkipPassword;
        const isNotSetType = this.knownUserService.getIsNotSetUnconfirmed();
        const loginWithoutPassword = this.configService.getOption('is_allow_login_without_password', false);
        const showSignIn = isNotSetType && loginWithoutPassword;

        this.showConfirm = this.knownUserService.getIsConfirmInfoCheck(type) && !showSignIn;
        this.showPassword = type.includes('not_set') && !blockedByAuthFlow;

        if (this.showConfirm) {
          this.profileTitle = 'Confirm Account';
          this.userName = 'Confirm Account';
        } else if (this.showPassword) {
          this.profileTitle = 'Set Password';
          this.userName = 'Set Password';
        }
      }));

    if (this.modalService.hasOpenModal()) {
      this.isHomepageModalOpened = true;
    }

    if (params.admin_edit) {
      this.handleAdminEditParams(params.admin_edit);
    }

    if (params.selectedCountry) {
      const getSelectedCountry = this.countries.find(i => i.country_code === params.selectedCountry);
      if (!isEmpty(getSelectedCountry)) {
        await this.profileService.updateCurrentCountry(params.selectedCountry, false);
      }
    }

    this.isDesktop = window.innerWidth > this.MOBILE_VIEWPORT_WIDTH;
    this.isTablet = window.innerWidth > this.MOBILE_VIEWPORT_WIDTH && window.innerWidth < this.DESKTOP_VIEWPORT_WIDTH;
    this.isTableOrMobile = window.innerWidth < this.DESKTOP_VIEWPORT_WIDTH;
    if (this.isFullScreenMap) {
      this.animationState = 'in';
    } else {
      this.animationState = 'out';
    }
    let country = '';
    const isAuthenticated = await this.authService.isAuthenticated();

    if (window.location.search) {
      const searchString = window.location.search;
      const queryString = searchString.substring(1, searchString.length);
      const queryParams = this.urlHelper.parseQueryString(queryString);
      if (queryParams['country'] && !queryParams['entry_redirect']) {
        try {
          await this.profileService.updateCurrentCountry(queryParams['country']);
          country = queryParams['country'];
        } catch (e) {
          console.error(e);
        }
      }
    }

    if (!country) {
      country = localStorage.getItem(COUNTRY_LAST_SESSION_KEY);
    }

    if (isAuthenticated) {

      try {
        const data = await this.profileService.getProfile().toPromise();
        const user = new UserModel(data);

        // if user doesn't have selected country
        // show a page for selection
        if (!country && !user.countryLast && this.router.url !== 'select-country') {
          void this.router.navigate(['/select-country']);
          return '';
        } else {
          if (user && user.countryLast) {
            country = user.countryLast;
          }
        }

        this.hasSubscriptionCenterAccess = this.configService.isSubscriptionCenterEnabled(country);

        if (user.guid) {
          this.profileService.setData(user);
          this.isLoggedIn = true;
          this.setMyProfileSubscription();
        }

        if (user.countryLast !== DEFAULT_COUNTRY) {
          this.isNameHidden = true;
        }

        // if there is an external url for this country
        const countryUrl = this.profileService.getCountryUrl(country);
        const isExternalUrl = this.navigationService.isExternalUrl(countryUrl);
        const isEntryRedirect = this.activatedRoute.snapshot.queryParams['entry_redirect'];
        if (isExternalUrl && !isEntryRedirect) {
          await this.profileService.clearUserCountryLast();
          // redirect to external url
          window.location.href = countryUrl;
          return;
        }

      } catch (e) {
        console.error(e);
      }

      this.getCartQuantity();
    } else {
      this.openAccessTitle = SIGN_IN_OR_REGISTER;
    }

    const subdomain = this.configService.getOption<string>('subdomain', '');
    const forceRefreshNavCache = this.activatedRoute.snapshot.queryParams['navrefresh']
      ? refreshOptions.includes(this.activatedRoute.snapshot.queryParams['navrefresh'].toLowerCase())
      : false;
    const routeUrl = this.router.url.split('?')[0];
    const refreshCache = routeUrl === '/home' && forceRefreshNavCache && this.permissionService.hasDefined('offers:edit');
    const refreshQuery = this.activatedRoute.snapshot.queryParams['navrefresh'];

    if (this.authService.hasAccessToken()) {
      await this.initDynamicNavItems(subdomain, country, refreshCache, 'auth', refreshQuery);
    }

    this.adminAccess.admin_edit_mode = this.sessionStorageHandler(ADMIN_EDIT_MODE_NAME) as string;

    this.employerPortalAccess = await this.employerPortalService.checkEmployerPortalAccess();

    this.clientOfferLink = '/offers';
    this.isProfileFeature = this.profileService.getOption('isProfileFeature');
    this.initMenuItems();

    await this.initCorporateLinks();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isFullScreenMap) {
      this.animationState = 'in';
    } else {
      this.animationState = 'out';
    }
    this.configService.getHeaderAnimationState().pipe(take(1)).subscribe(data => this.animationState = data);
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public clickLogo(): void {
    this.sessionStorageService.removeGroupOfKeys('_mini');
    this.animationState = 'out';
  }

  public homeRedirect(): void {
    this.clickLogo();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    void this.router.navigate(['/home']);
  }

  public triggerNavLink(navOption: NavDynamicItem, nav: { isOpen: () => any; close: () => void; open: () => void }): void {
    if (navOption.children) {
      this.showDynamicNavSubMenu(navOption);
      if (nav.isOpen()) {
        nav.close();
      } else {
        nav.close();
        nav.open();
        setTimeout(() => this.calculateNavMenuOffset(), 50);
      }
    } else {
      this.onDynamicNavClick(navOption);
    }
  }

  public clickSearch(event: any): void {
    this.triggerAnimation(event);
  }

  public triggerAnimation(link: string | string[]): void {
    if (link.includes('listing')) {
      this.animationState = 'in';
    } else {
      this.animationState = 'out';
    }
  }

  getTotalColumnsCount(secondLevelNavItems: NavDynamicItem[]): number {
    const expandedColumnsCount = secondLevelNavItems
      .filter(item => item.children && item.children.length > 6)
      .length;

    // if there is more than 6 children (thirdLevelNavItems), add expanded column to count correct submenu width
    return secondLevelNavItems.length + expandedColumnsCount;
  }

  setMyProfileSubscription(): void {
    this.subscriptions.push(
      this.profileService.profileData$.subscribe(profile => {
        // note: this technique is not right use of scopes, but leaving it in for now.
        // better if we got the avatarLetter from the users profile seperate from the name.
        const hasProfilePermission = this.permissionService.hasDefined('profile:view');
        if (hasProfilePermission) {
          this.isGuestOrKnownMode = false;
          if (profile.isSignedInAsKnownUser) {
            this.userName = SIGN_IN_OR_REGISTER;
            this.avatarLetter = '';
            this.isGuestOrKnownMode = true;

            if (this.showConfirm) {
              this.profileTitle = 'Confirm Account';
              this.userName = 'Confirm Account';
            } else if (this.showPassword) {
              this.profileTitle = 'Set Password';
              this.userName = 'Set Password';
            }
            if (!this.showConfirm && !this.showPassword) {
              this.profileTitle = SIGN_IN_OR_REGISTER;
            }

          } else if ('firstName' in profile) {
            this.userName = profile.firstName;
            this.avatarLetter = profile.firstName.substring(0, 1);
          } else {
            this.userName = '';
            this.avatarLetter = '?';
          }
        } else {
          this.avatarLetter = '?';
          this.userName = SIGN_IN_OR_REGISTER;
          this.isGuestOrKnownMode = true;
        }

        this.currentUserCountry = this.countries.find(c => c.country_code === profile.countryLast ? profile.countryLast : c.country_code === 'US') || this.countries[0];
        if (this.currentUserCountry.country_code !== profile.countryLast) {
          this.emitCountryChange(this.currentUserCountry);
        }
        if (this.countryOptions && this.countryOptions.length && this.countryOptions.length > 0) {
          this.countryOptions.forEach(value => {
            const country = this.countries.find(i => i.country_code === value.country_code);
            if (country) {
              const index = this.countries.indexOf(country);
              if (value.country_url) {
                this.countries[index]['country_url'] = value.country_url;
              }
              if (value.home_redirect_url) {
                this.countries[index]['home_redirect_url'] = value.home_redirect_url;
              }
            }
          });
        }
        this.otherCountries = this.countries.filter(i => i.country_code !== this.currentUserCountry.country_code);
      })
    );
  }

  showSubMenu(name: string): void {
    if (this.showSubMenus) {
      this.showSubMenus[name] = this.showSubMenus[name] ? false : true;
    }
  }

  showDynamicNavSubMenu(nav: NavDynamicItem): void {
    setTimeout(() => this.showSubMenu(nav.id));

    const data = {
      guid: nav.guid,
      id: nav.id,
      name: nav.name
    };
    const action = 'nav-secondary-show';
    this.navigationService.trackAnalyticsEvents(action, data);
  }

  hideSubMenu(name: string | number): void {
    if (this.showSubMenus) {
      this.showSubMenus[name] = false;
    }
  }

  async emitCountryChange(country: PlatformCountry, item?: string): Promise<void> {
    if (!country) {
      this.toggleCollapse(item);
      this.toggleMenu();
      return;
    }

    await this.profileService.updateCurrentCountry(country.country_code, false);

    if (!country.home_redirect_url && !country.country_url) {
      const a = this.addPreventIERedirectParamIfExists('/home');
      this.window.location.href = this.addPreventIERedirectParamIfExists('/home');
    } else {
      if (country.home_redirect_url && !country.country_url) {
        window.location.href = this.addPreventIERedirectParamIfExists(country.home_redirect_url);
        if (this.navigationService.isExternalUrl(country.home_redirect_url)) {
          await this.profileService.clearUserCountryLast();
        }
      } else if (country.country_url) {
        window.location.href = this.addPreventIERedirectParamIfExists(country.country_url);
        if (this.navigationService.isExternalUrl(country.country_url)) {
          await this.profileService.clearUserCountryLast();
        }
      }
    }
  }

  toggleCollapse(target: string): void {
    // Collapse all menu-items and toggle the targeted
    this.mobileMenu.forEach(item => {
      if (item === target) {
        item.isCollapsed = !item.isCollapsed;
      } else {
        item.isCollapsed = true;
      }
    });
  }

  toggleMenu(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.isMenuShown = !this.isMenuShown;
  }

  hasPermission(permissionName: string): boolean {
    return permissionName
      ? this.permissionService.hasDefined(permissionName)
      : true;
  }

  isViewNewMenuItem(item: { type: string }): boolean {
    return item.type === 'view_new';
  }

  openContactUsModal(selected?: string): void {
    this.showContactUs.emit(selected);
  }

  openCart(): void {
    window.location.href = `https://store.workingadvantage.com/packages.php?sub=packages&action=viewcart&${this.corporateQueryParams}`;
  }

  navigateTo(item: { link: any; handler: () => void }): void {

    if (item.link) {
      void this.router.navigate([item.link]);
    } else if (item.handler) {
      item.handler();
    }
  }

  initMenuItems(): void {
    this.mobileMenu = this.getMobileMenuItems();
  }

  onDynamicNavClick(nav: NavDynamicItem | any): void {
    if (nav) {
      if (nav.routerLink) {
        this.triggerAnimation(nav.routerLink);
      } else if (nav.url) {
        this.triggerAnimation(nav.url);
      }
    }

    if (this.notificationBanner) {
      setTimeout(() => this.configService.updateNotificationBannerState(true));
    }

    // nav data || mobile menu data
    const data = {
      guid: nav.guid || nav.info.guid,
      id: nav.id || nav.info.id,
      name: nav.name || nav.title,
      ...(nav.parent && { parent: nav.parent }),
      url: nav.url || nav.routerLink
    };

    const isExternalUrl = this.navigationService.isExternalUrl(data.url);
    const action = isExternalUrl ? 'nav-click-out' : 'nav-click-internal';

    if (isExternalUrl) {
      data['click_out'] = nav.is_ebg ? 'internal' : 'external';
    }

    this.adobeAnalyticsEventsService.sendNavigationClick(nav);
    this.navigationService.trackAnalyticsEvents(action, data);
    this.navigationService.handleClick(data.url, nav.has_token);
  }

  onSecondaryHeaderClick(menuItem: IHeaderMenuItem, event: Event): void {
    event.stopPropagation();
    const data = {
      clickUrl: menuItem.routerLink,
      queryParams: menuItem.queryParams,
    };

    if (menuItem.queryParams && menuItem.queryParams.customCategoryGuid) {
      const actionName = 'custom-category-click';
      this.trackAnalyticsEvents(actionName, data);
    }
    const action = 'secondary-header-item-click';

    this.trackAnalyticsEvents(action, data);

    this.searchService.clearHiddenTerms();
    this.searchService.clearQuery();

    if (menuItem.openExternally) {
      this.window.open(menuItem.routerLink, '_blank');
    }
    void this.router.navigate([menuItem.routerLink], { queryParams: menuItem.queryParams });
  }

  trackAnalyticsEvents(action: any, data: any, gaLabelField?: string): void {
    const label = gaLabelField ? data[gaLabelField] : data;

    try {
      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel(action, {
          data
        }),
        new AnalyticsGAEventModel(action, {
          category: action,
          label
        })
      ]);
    } catch {
      console.error('err');
    }

  }

  onCustomLinkClick(link: string, guid: any): void {
    this.trackAnalyticsEvents('custom-link-click', { link, guid }, 'link');
  }

  setFocusOnElement(name: focusEvents, event: Event): void {
    event.stopPropagation();
    this.focusEmitterService.emitFocusOn(name);
  }

  adminFeaturesToggles(toggleName: string, state?: boolean, fromParam = false, isActive?: boolean): void {
    if (
      (fromParam && this.adminAccess.admin_edit_mode !== toggleName
        && this.sessionStorageHandler(ADMIN_EDIT_MODE_NAME) !== toggleName)
      || !fromParam
    ) {
      switch (toggleName) {
        case 'inline_admin':
          if (!this.hasInlineEditAccess) {
            this.setAdminFeaturesNotAllowedAccessMessage();

            return;
          }

          this.toggleAdminMode('inline_admin');
          this.offersListAdminLayoutService.handleTakeoverState(false);
          this.offersListAdminLayoutService.triggerInlineAdminLayout(true);

          break;

        case 'homepage_takeover':
          if (!this.hasTakeoverAccess) {
            this.setAdminFeaturesNotAllowedAccessMessage();

            return;
          }

          this.toggleAdminMode('homepage_takeover');
          this.offersListAdminLayoutService.triggerInlineAdminLayout(false);
          const takeoverSessionStorageState = sessionStorage.getItem('admin_edit_mode');
          this.offersListAdminLayoutService.handleTakeoverState(takeoverSessionStorageState === 'homepage_takeover');
          if (!isActive) {
            this.openEditModeModal('takeover');
          }

          break;
      }
      setTimeout(() => this.showSubMenus.profile = false, 350); // timeout for animation
    }
  }

  sessionStorageHandler(key: string, value?: boolean | string): void | string {
    if (value === undefined) {
      return sessionStorage.getItem(key);
    } else {
      sessionStorage.setItem(key, value.toString());
    }
  }

  public async signUp(event: Event): Promise<void> {
    event.stopPropagation();
    this.hideSubMenu('countries');
    this.hideSubMenu('support');
    this.isMenuShown = false;

    if (this.showConfirm) {
      this.accountConfirmationService.checkAndOpenConfirmationDialog();
    } else if (this.showPassword) {
      this.accountConfirmationService.checkAndOpenPasswordDialog();
    } else {
      this.authService.setUnauthenticated();
      localStorage.removeItem(COUNTRY_LAST_SESSION_KEY);
      this.authService.redirectToSignInDirectly('', true);
    }
  }

  public onOutsideOfAccountConfirmationClick(stepToClose?: AccountConfirmationStep): void {
    // Used to prevent closing confirmation dialog after clicked on homepage modals
    if (this.isHomepageModalOpened) {
      this.isHomepageModalOpened = false;
      return;
    }
    // We should override outside click logic for Mreg notifications
    const isMregEntryMode = this.mregService.isEntryMode;
    this.accountConfirmationService.closeConfirmationDialog(false, true, stepToClose, isMregEntryMode, true);
  }

  private toggleAdminMode(mode: string): void {
    if (this.sessionStorageHandler(ADMIN_EDIT_MODE_NAME) === mode) {
      this.sessionStorageHandler(ADMIN_EDIT_MODE_NAME, '');
    } else {
      this.sessionStorageHandler(ADMIN_EDIT_MODE_NAME, mode);
    }

    this.adminAccess.admin_edit_mode = this.adminAccess.admin_edit_mode === mode ? '' : mode;
  }

  private calculateNavMenuOffset(): void {
    const wideDesktop = window.innerWidth >= this.WIDE_DESKTOP;
    const defaultMargin = wideDesktop ? 48 : 16;
    const dynamicSubmenuElement: HTMLElement = this.element.nativeElement.querySelector('.dynamic-submenu');
    const bounding = dynamicSubmenuElement.getBoundingClientRect();
    const offsetLeft = bounding.left - defaultMargin;
    const offsetRight = this.window.innerWidth - bounding.right - defaultMargin;
    const popoverBodyElement: HTMLElement = this.element.nativeElement.querySelector('.popover-body');

    if (offsetLeft <= 0 && offsetRight > 0) {
      const leftBias = (Math.abs(bounding.left) + defaultMargin) * 2;
      popoverBodyElement.style.marginLeft = `${leftBias}px`;
      popoverBodyElement.style.marginRight = '0';
    }

    if (offsetRight <= 0 && offsetLeft > 0) {
      const additionalOffset = wideDesktop ? 18 : 16;
      const rightBias = (Math.abs(this.window.innerWidth - bounding.right) + defaultMargin + additionalOffset) * 2;
      popoverBodyElement.style.marginRight = `${rightBias}px`;
      popoverBodyElement.style.marginLeft = '0';
    }
  }

  private async initDynamicNavItems(subdomain: string, country: string, refreshCache: boolean, authStatus: string, refreshQuery?: string) {
    if (!this.corporateQueryParams) {
      try {
        this.corporateQueryParams = await this.corporateRedirectService.getRedirectUrlWithParams();
      } catch (error) {
        this.corporateQueryParams = await this.corporateRedirectService.getRedirectUrlDefaultParams();
      }
    }

    this.navigationConfig = await this.navigationService.getNavigationItems(subdomain, 'header', country, refreshCache, authStatus, refreshQuery).toPromise();

    if ((this.navigationConfig.items || []).some(nav => nav.is_ebg)) {
      await this.appendCorporateQueryParams();
    }

    this.primaryNavItems = (this.navigationConfig.items || [])
      .filter(item => item.parent === '')
      .sort((a, b) => b.priority - a.priority);

    // add to showSubMenus array to trigger submenus
    this.primaryNavItems.forEach(primary => this.showSubMenus[primary.id] = false);

    // set second and third levels of nav items
    const secondLevelNavItems = (this.navigationConfig.items || [])
      .filter(item => this.primaryNavItems.some(parent => item.parent === parent.id))
      .sort((a, b) => b.priority - a.priority);

    const thirdLevelNavItems = (this.navigationConfig.items || [])
      .filter(item => secondLevelNavItems.some(parent => item.parent === parent.id))
      .sort((a, b) => b.priority - a.priority);

    // set children to primary and second level of nav items
    this.navigationService.setChildren(secondLevelNavItems, this.primaryNavItems);
    this.navigationService.setChildren(thirdLevelNavItems, secondLevelNavItems);

    // do not show primary nav items which do not have neither url or children
    this.primaryNavItems = this.primaryNavItems.filter(item => item.url || item.children);

    this.trackLoadedPrimaryNavItems(this.primaryNavItems);
  }

  private trackLoadedPrimaryNavItems(navItems: NavDynamicItem[]) {
    const gaLoadedPrimaryNavItems = navItems
      .map(primary => `${primary.id}|${primary.name}`);

    const internalLoadedPrimaryNavItems = navItems
      .map(primary => ({
        guid: primary.guid,
        id: primary.id,
        name: primary.name
      }));

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('nav-primary-load', {
        primaryNavigation: internalLoadedPrimaryNavItems
      }),
      new AnalyticsGAEventModel('nav-primary-load', {
        category: 'nav-primary-load',
        label: JSON.stringify(gaLoadedPrimaryNavItems)
      })
    ]);
  }

  private getInitialMobileMenu(): IHeaderMenuItem[] {
    const initialMenu: IHeaderMenuItem[] = [
      {
        iconClass: 'menu-icon',
        title: 'Support',
        isDynamicNav: false,
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        collapseMenu: null,
        routerLink: '/common/help/contact',
        queryParams: null,
        noBorder: this.isGuestAutologin ? true : false
      },
      {
        title: 'My Account',
        menuItemClass: 'menu-item menu-item-profile',
        iconClass: 'menu-icon profile-icon',
        iconStyle: {
          backgroundImage: `url(${this.imageUrlPipe.transform('/assets/icons/ic-profile-mobile.svg', { w: 26, h: 26 }, false)})`,
        },
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        permissionRequired: 'profile:view',
        collapseMenu: null,
        routerLink: '/my-account/dashboard',
        noBorder: false,
        isProfileFeature: this.isProfileFeature
      },
      {
        title: 'Near Me',
        menuItemClass: 'menu-item menu-item-profile',
        iconClass: 'menu-icon',
        iconStyle: {
          backgroundImage: `url(${this.imageUrlPipe.transform('/assets/icons/ic-near_me.svg', { w: 26, h: 26 }, false)})`,
        },
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        permissionRequired: 'profile:view',
        collapseMenu: null,
        routerLink: '/listing',
        noBorder: false,
        blockDisplay: !this.features.locations_map_new || this.currentUserCountry.country_code !== DEFAULT_COUNTRY
      },
      {
        title: 'Control Panel',
        iconClass: 'menu-icon',
        iconStyle: {
          backgroundImage: `url(${this.imageUrlPipe.transform('/assets/icons/ic-control-panel.svg', { w: 26, h: 26 }, false)})`,
        },
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        permissionRequired: 'controlpanel:view',
        collapseMenu: null,
        routerLink: '/controlpanel',
        openExternally: true
      }
    ];

    if (this.employerPortalAccess?.has_access_to_ep) {
      initialMenu.push({
        title: 'Employer Portal',
        iconClass: 'menu-icon employer-portal-icon',
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        collapseMenu: null,
        routerLink: this.employerPortalAccess.ep_link,
        openExternally: true
      });
    }


    // Remove dashboard option for Known/Guest
    if (this.isGuestOrKnownMode) {
      const knownExcludedNavs = ['My Account', 'Employer Portal'];
      return initialMenu.filter(item => !knownExcludedNavs.includes(item.title));
    }

    return initialMenu;
  }

  private getCurrentCountryCheck() {
    const currentlyCountry = this.currentUserCountry.country_code;
    const currencies = [{
      collapseMenu: [{
        itemClass: 'currency-item-mobile currency-content',
        itemStyle: '',
        title: 'All prices referenced in this site and the partner sites to which we link are USD unless otherwise noted.'
      }],
      expandable: true,
      iconClass: 'menu-icon sign-out-icon',
      iconStyle: '',
      isCollapsed: true,
      menuItemClass: 'menu-item menu-item-currency',
      title: 'USD',
      name: 'currency'
    }];

    if (currentlyCountry === DEFAULT_COUNTRY) {
      currencies.pop();
    }

    return currencies;
  }

  private getMobileMenuItems() {
    const primaryNavMobileItems = this.primaryNavItems
      .filter(primary => primary.url)
      .map(primary => ({
        info: {
          guid: primary.guid,
          id: primary.id
        },
        iconStyle: {
          backgroundImage: primary.icon_bw ? `url(${this.imageUrlPipe.transform(primary.icon_bw, { w: 26, h: 26 }, false)})` : 'none',
        },
        iconClass: 'menu-icon sign-out-icon',
        title: primary.name,
        icon: primary.icon_bw || '',
        isDynamicNav: true,
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        collapseMenu: null,
        routerLink: primary.url,
        queryParams: null,
        noBorder: false
      }));

    const marketplaceName = [
      {
        title: this.marketplaceName,
        menuItemClass: 'menu-item menu-item-marketplace',
        iconClass: 'menu-icon marketplace-icon',
        iconStyle: {
          backgroundImage: `url(${this.imageUrlPipe.transform('/assets/icons/ic-home-mobile.svg', { w: 26, h: 26 }, false)})`,
        },
        expandable: false,
        expandableId: null,
        isCollapsed: false,
        collapseMenu: null,
        routerLink: '/',
        noBorder: false
      },
    ];

    const signOutMobileItems = [{
      title: 'Sign Out',
      menuItemClass: 'menu-item menu-item-sign-out',
      iconClass: 'menu-icon sign-out-icon',
      expandable: false,
      expandableId: null,
      isCollapsed: false,
      collapseMenu: null,
      routerLink: '/sign-out',
      noBorder: true,
      isProfileFeature: this.isProfileFeature
    }];

    // Add sign-out image only if user logged in through Auth mode
    if (!this.isGuestOrKnownMode) {
      signOutMobileItems[0]['iconStyle'] = {
        backgroundImage: `url(${this.imageUrlPipe.transform('/assets/icons/ic-sign-out-mobile.svg', { w: 26, h: 26 }, false)})`,
      };
    }

    if (this.isGuestAutologin) {
      signOutMobileItems.pop();
    }

    const initialMobileItems = this.getInitialMobileMenu();
    const countriesMobileItems = this.getCountriesMobileItems();
    const currencyMobileItem = this.getCurrentCountryCheck();

    return [
      ...countriesMobileItems,
      ...currencyMobileItem,
      ...marketplaceName,
      ...primaryNavMobileItems,
      ...initialMobileItems,
      ...signOutMobileItems
    ];
  }

  private getCountriesMobileItems() {
    if (!this.showMultiCountryOptions || !this.otherCountries.length) {
      return [];
    }

    const getItemStyle = countryUrl => ({
      backgroundImage: `url(${this.imageUrlPipe.transform(countryUrl, { w: 26, h: 26 }, false)})`,
      paddingLeft: '42px',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: '4px',
      backgroundSize: '26px 26px'
    });

    const getNoItemStyle = () => ({
      height: 0,
    });

    let collapseMenu = [{
      title: '',
      itemStyle: getNoItemStyle() as any,
      itemClass: 'country-subitem-mobile country-subitem-active-mobile',
    }];

    collapseMenu = collapseMenu.concat(this.otherCountries.map(c => ({
      title: c.country_name_en,
      itemStyle: getItemStyle(c.country_icon_url),
      itemClass: 'country-subitem-mobile',
      handler: async (item) => this.emitCountryChange(c, item)
    })));

    return [{
      title: this.currentUserCountry.country_name_en,
      expandable: true,
      iconClass: 'menu-icon sign-out-icon',
      menuItemClass: 'menu-item',
      isCollapsed: true,
      iconStyle: {
        backgroundImage: `url(${this.imageUrlPipe.transform(this.currentUserCountry.country_icon_url, { w: 26, h: 26 }, false)})`,
        backgroundSize: 'contain'
      },
      collapseMenu
    }];
  }

  private getCartQuantity(): void {
    this.subscriptions.push(
      this.profileService.getCartCount()
        .pipe(
          map(cartQuantity => cartQuantity.user_cart),
          map(userCart => userCart.map(cart => cart.product_quantities)),
          map(productQuantities => productQuantities.reduce((acc, productQuantity) => {
            const quantity = sum(productQuantity);

            if (quantity) {
              acc += quantity;
            } else {
              acc += 1;
            }

            return acc;
          }, 0))
        )
        .subscribe(quantity => {
          this.productQuantity = quantity;
        })
    );
  }

  private handleAdminEditParams(value: any): void {
    const mode: string = first(value.split(','));
    if (mode) {
      this.adminFeaturesToggles(mode, !this[attributeKeys[mode]], true);
    }
  }

  private setAdminFeaturesNotAllowedAccessMessage(): void {
    this.systemMessageService.setMessage({
      type: 'error',
      text: NOT_ALLOWED_ACCESS_ERROR_MESSAGE,
      autoCloseTimeout: ERROR_MESSAGE_TIME
    });
  }

  private openEditModeModal(editMode: string): void {
    this.modalService.createModal({
      component: ModalNotificationComponent,
      attributes: [
        { key: 'headline', value: `${editMode} Admin Mode Is On!` },
        {
          key: 'description',
          value: 'To add offers, use the search box or navigation (above). When you find desired offers in search results, select offers using the checkbox on the right side of the result.'
        },
        {
          key: 'sampleUrl',
          value: 'assets/images/edit-mode-notification.png'
        },
        {
          key: 'destinationUrl',
          value: editMode
        }
      ],
      options: { windowClass: 'edit-notification-modal', backdrop: 'static' }
    });
  }

  private async appendCorporateQueryParams(): Promise<void> {
    const corporateQueryParams = new URLSearchParams(this.corporateQueryParams);
    this.navigationConfig.items = this.navigationConfig.items.map(nav => {
      if (nav.is_ebg && this.corporateQueryParams) {
        const [pathUrl, urlQueryParamsString] = nav.url.split('?');
        const urlQueryParams = new URLSearchParams(urlQueryParamsString);

        const mergedQueryParams = new URLSearchParams({
          ...Object.fromEntries(urlQueryParams),
          ...Object.fromEntries(corporateQueryParams),
        });

        nav.url = `${pathUrl}?${mergedQueryParams.toString()}`;
      }
      return nav;
    });
  }

  // SHOULD be removed when we will not support IE at all
  private addPreventIERedirectParamIfExists(url: string): string {
    if (this.activatedRoute.snapshot.queryParams['skipEdgeRedirection']) {
      return url.includes('?') ? `${url}&skipEdgeRedirection=true` : `${url}?skipEdgeRedirection=true`;
    }

    return url;
  }

  private async initCorporateLinks(): Promise<void> {
    const corpLinks: CorporateLinks = this.configService.getOption('corporate_links', {});

    this.orderHistoryUrl = await this.getFullCorporateUrl(corpLinks.order_history_url);
    this.funlifeHistoryUrl = await this.getFullCorporateUrl(corpLinks.funlife_history_url);
  }

  private async getFullCorporateUrl(url): Promise<string> {
    const baseUrl = await this.corporateRedirectService.generateCorporateRedirectUrl(url);
    return this.corporateRedirectService.isValidRedirectUrl(baseUrl) ? baseUrl : '#';
  }

  public trackByCountryCode(index: number, item: PlatformCountry): string {
    return item.country_code;
  }

  public trackBySupportIndex(index: number, item: SupportOptions): number {
    return index;
  }

  public trackByCustomLinkGuid(index: number, item: CustomLinkResponse): string {
    return item.guid;
  }

  public trackByMobileMenuTitle(index: number, item: IHeaderMenuItem): string {
    return item.title;
  }

  public trackByCollapseMenuIndex(index: number, item: CollapseMenu): number {
    return index;
  }

  public trackByPrimaryNavGuid(index: number, item: NavDynamicItem): string {
    return item.guid;
  }

}
