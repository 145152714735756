import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unsubscribe-confirmation-modal',
  templateUrl: './unsubscribe-confirmation-modal.component.html',
  styleUrls: ['./unsubscribe-confirmation-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubscribeConfirmationModalComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() confirmButtonTitle = 'Confirm';

  @Output() unsubscribeAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onCancel(): void {
    this.activeModal.dismiss('Cross click');
  }

  onUnsubscribeAction(): void {
    this.activeModal.dismiss('Cross click');
    this.unsubscribeAction.emit(true);
  }

}
