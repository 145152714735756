/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AutocompleteData, AutocompleteDropdownSettings, AutocompleteSection } from './autocomplete-data.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION]
})

export class AutocompleteDropdownComponent implements OnInit, OnChanges, OnDestroy {

  // Read more: ./autocomplete-dropdown.md
  @Input() autocompleteDropdownSettings: AutocompleteDropdownSettings;
  @Input() autocompleteLabel: string;
  @Input() sections: AutocompleteSection[] = [];
  @Input() closeAutocompleteDropdown: boolean;

  @Output() autocompleteValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedItemTitle: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedValue: EventEmitter<string | number> = new EventEmitter<string | number>();

  @ViewChild('input') input: ElementRef;

  autocompleteForm: UntypedFormGroup;
  autocompleteThreshold: number;
  autocompletePlaceholder: string;
  showDropdown = false;
  isResultsFound = false;
  isCloseDropdownButtonVisible = false;

  private destroyStream = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  @HostListener('document:click', ['$event']) onClick(event: any): void {
    if (this.autocompleteDropdownSettings && this.autocompleteDropdownSettings.backdropClick && event) {
      this.showDropdown = false;
    }
  }

  ngOnInit(): void {
    this.getDefaultAutocompleteSettings();
    this.initForm();
    this.checkValidValue();
  }

  ngOnChanges(): void {
    this.getIntialButtonState();
  }

  closeDropdown(): void {
    this.showDropdown = false;
    this.autocompleteForm.controls['autocompleteInput'].setValue('');
    this.isCloseDropdownButtonVisible = false;
  }

  inputSelect(event: MouseEvent): void {
    this.showDropdown = !this.closeAutocompleteDropdown;
    const value = this.autocompleteForm.get('autocompleteInput').value;
    if (value && this.showDropdown === false) {
      event.stopPropagation();
      event.preventDefault();
      this.showDropdown = false;
    }
  }

  selectItem(event: MouseEvent, item: AutocompleteData): void {
    event.stopPropagation();
    event.preventDefault();

    this.selectedValue.emit(item.value);
    this.selectedItemTitle.emit(item.title);
    this.input.nativeElement.value = item.title;
    this.showDropdown = false;
    this.isCloseDropdownButtonVisible = false;
  }

  getBackdropClick(event: { stopPropagation: () => void; preventDefault: () => void }): void {
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * @param section {Object} based on header settings for each section and list of data
   * @returns sliced array based on @param ItemsToShow OR default list {Array}
   */
  getSectionData(section: AutocompleteSection): AutocompleteData[] {
    if (!this.autocompleteDropdownSettings.itemsToShow) {
      return section.data;
    }
    return section.buttonState === true && section.data.length > this.autocompleteDropdownSettings.itemsToShow
      ? section.data.slice(0, this.autocompleteDropdownSettings.itemsToShow)
      : section.data;
  }

  onShowMore(event: MouseEvent, section: AutocompleteSection): void {
    event.stopPropagation();
    event.preventDefault();

    const index: number = this.sections.findIndex((i => i.title === section.title));
    this.sections[index].buttonState = !section.buttonState;
    this.sections[index].buttonTitle = this.getButtonTitle(this.sections[index].buttonState, section);
  }

  ngOnDestroy(): void {
    this.destroyStream.next();
    this.destroyStream.complete();
  }

  private getButtonTitle(state: boolean, section: AutocompleteSection): string {
    return state === true ? `Show More +${section.data.length - this.autocompleteDropdownSettings.itemsToShow}` : 'Show Less';
  }

  private checkValidValue(): void {
    this.autocompleteForm.get('autocompleteInput').valueChanges
      .pipe(takeUntil(this.destroyStream))
      .subscribe(value => {
        if (value && value.length >= this.autocompleteThreshold) {
          this.autocompleteValue.emit(value);
          this.showDropdown = true;
        } else {
          this.showDropdown = false;
        }

        this.isCloseDropdownButtonVisible = true;
      });
  }

  private initForm(): void {
    this.autocompleteForm = this.formBuilder.group({ autocompleteInput: this.autocompleteDropdownSettings.inputString || '' });
  }

  private getDefaultAutocompleteSettings(): void {
    this.autocompleteThreshold = this.autocompleteDropdownSettings.threshold ? this.autocompleteDropdownSettings.threshold : 3;
    this.autocompletePlaceholder = this.autocompleteDropdownSettings.placeholder ? this.autocompleteDropdownSettings.placeholder : '';
  }

  // Setting up additional params which handle "Show More" behavior
  private getIntialButtonState(): void {
    this.isResultsFound = !!this.sections.filter(i => i.data.length).length;
    this.sections.forEach(i => {
      i['buttonTitle'] = `Show More +${i.data.length - this.autocompleteDropdownSettings.itemsToShow}`;
      i['buttonState'] = true;
    });
  }

}
