import { ConfirmationHeaderContent } from '../interfaces/confirmation.interface';

const defaultButtonTitle = 'Ok, Great!';
export const confirmEmail = (userEmail: string): ConfirmationHeaderContent => ({
  title: 'All set! Email confirmed.',
  content: `Please note that ${userEmail.length > 25 ? userEmail.substring(0, 25) + '...' : userEmail} is your primary account email and should be used to sign in.`,
  buttonTitle: defaultButtonTitle
});

export const setPassword = (marketPlaceName: string): ConfirmationHeaderContent => ({
  title: 'Thank You!',
  content: `Password Set! Thank you for completing your password and enjoy your experience on <strong>${marketPlaceName}</strong>`,
  buttonTitle: defaultButtonTitle
});

export const zipFinalContent: ConfirmationHeaderContent = {
  title: 'Thank You!',
  content: `You can update your ZIP/Postal Code at any time in  
  <span>
    <a style="color: #000; text-decoration: underline; text-underline-offset: 3px;" 
       href="/my-account/dashboard">My Account</a>
     > 
    <a style="color: #000; text-decoration: underline; text-underline-offset: 3px;"
       href="/my-account/details">Details.</a>
  </span>`,
  buttonTitle: defaultButtonTitle
};

export const defaultFinalContent: ConfirmationHeaderContent = {
  title: 'Thank You!',
  content: 'You can update your mobile phone at any time in  <span style="border-bottom: 1px solid #000">My Account > Details.</span>',
  buttonTitle: defaultButtonTitle
};
