import { Component, HostListener, Inject, HostBinding, Output, EventEmitter } from '@angular/core';
import { WINDOW, WindowHelper } from 'g3-common-ui';

@Component({
  selector: 'app-card-overlay',
  templateUrl: './card-overlay.component.html',
  styleUrls: ['./card-overlay.component.less']
})
export class CardOverlayComponent {

  @Output() visibilityChange = new EventEmitter<boolean>();
  @Output() overlayClick = new EventEmitter<void>();

  @HostBinding('class.overlay_shown')
  public isOverlayShown = false;

  constructor(@Inject(WINDOW) private window: Window) { }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (WindowHelper.isTablet(this.window)) {
      return;
    }
    this.isOverlayShown = true;
    this.visibilityChange.emit(this.isOverlayShown);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (WindowHelper.isTablet(this.window)) {
      return;
    }
    this.isOverlayShown = false;
    this.visibilityChange.emit(this.isOverlayShown);
  }

  @HostListener('window:resize')
  onResize(): void {
    if (!WindowHelper.isTablet(this.window) && this.isOverlayShown) {
      this.isOverlayShown = false;
      this.visibilityChange.emit(this.isOverlayShown);
    }
  }

  @HostListener('click', ['$event'])
  onOverlayClick(event: Event): void {
    event.stopPropagation();
    if (this.isOverlayShown) {
      this.overlayClick.next();
    }

    this.isOverlayShown = true;
    this.visibilityChange.emit(this.isOverlayShown);
  }

  onCloseClick(event: Event): void {
    event.stopPropagation();
    this.isOverlayShown = false;
    this.visibilityChange.emit(this.isOverlayShown);
  }
}
