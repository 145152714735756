import { ActivatedRoute } from '@angular/router';
import { Ad } from '@app/shared/models/ad.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScrollOffset } from '@app/core/layouts/home/anchors.enum';
import { ViewportScroller } from '@angular/common';
import { ConfigService } from './config.service';
import moment from 'moment';
import { BrandTagsDictionaryResponse, ITopBrands, ITopCategories } from '../interfaces/brands.interface';
import { ProfileService } from './profile/profile.service';

interface FeaturedBrands {
  guid: string;
  name: string;
  tag_id: string;
  square_logo: string;
}

@Injectable()
export class HomepageAdsService {

  private brandsSource = new BehaviorSubject<Ad[]>([]);
  brands$ = this.brandsSource.asObservable();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private readonly configService: ConfigService,
    private readonly profileService: ProfileService
  ) {
  }

  async getInterests(): Promise<string[]> {
    return await this.http.get(`${environment.apiUrl}/api/my/interests`).toPromise() as string[];
  }

  setNextBrands(brands: Ad[]): void {
    this.brandsSource.next(brands);
  }

  async getFeaturedBrands(): Promise<Ad[]> {
    const result = await this.http.get(`${environment.apiUrl}/api/brands/featured`).toPromise() as FeaturedBrands[];
    return result.map(item => new Ad(
      {
        guid: item.guid,
        company: item.tag_id,
        vendorImage: item.square_logo,
        titleShort: item.name,
        adUrl: `/offers?b=${item.tag_id}`
      }));
  }

  public async getTopBrands(): Promise<Ad[]> {
    const date = moment().utc();
    const dateFrom = date.clone().subtract(1, 'month').startOf('month').toDate();
    const dateTo = date.clone().subtract(1, 'month').endOf('month').toDate();

    const country = this.profileService.getData().countryLast || 'US';

    const subdomain = this.configService.getSubdomain();
    const params = new HttpParams()
      .set('limit', 15)
      .append('country', 'all')
      .append('metricName', 'top_brands')
      .append('optionalFields', true)
      .append('dateFrom', dateFrom.toISOString())
      .append('dateTo', dateTo.toISOString());

    const topMetrics = await this.http.get<ITopBrands>(`${environment.apiUrl}/api/analytics/marketplaces/${subdomain}/top/open`, { params }).toPromise();
    const topBrands = topMetrics.top_brands?.data?.filter(brand => brand.logo);
    const brandIds = topBrands.map(el => el.guid);

    const activeBrands = await this.filterBrandsByActiveOffers(brandIds, country);
    const result = topBrands
      .filter(brand => activeBrands.find(el => el.brand_id === brand.guid))
      .map(brand => ({ ...brand, associatedOffers: activeBrands.find(el => el.brand_id === brand.guid)?.associatedOffers }));

    return result.map(item => new Ad({
      content: item?.associatedOffers?.length === 1 ? item.associatedOffers[0] : '', // * Change navigation behavior only for singe-offer brands
      guid: item.guid,
      company: item.tag_id,
      logo: item.logo,
      titleShort: item.name,
      adUrl: `/offers?b=${item.guid}`
    }));
  }

  async getBrandTags(ids: string[]): Promise<BrandTagsDictionaryResponse[]> {
    if (!ids.length) {
      return [];
    }
    return this.http.get<BrandTagsDictionaryResponse[]>(`${environment.apiUrl}/api/dictionaries/brand-tags/${ids.join(',')}`).toPromise();
  }

  async filterBrandsByActiveOffers(brandTagIds: string[], country: string): Promise<{ brand_id: string; associatedOffers: string[] }[]> {
    const body = { tagIds: brandTagIds, metricName: 'brands', country };
    return this.http.patch<{ brand_id: string; associatedOffers: string[] }[]>(`${environment.apiUrl}/api/brands/offer-compare`, body).toPromise();
  }

  public async filterCategoriesByActiveOffers(categoryIds: string[], country: string): Promise<{ category_id: string; associatedOffers: { guid: string; hasDetailsPage: boolean }[] }[]> {
    const body = { tagIds: categoryIds, metricName: 'categories', country };
    return this.http.patch<{ category_id: string; associatedOffers: { guid: string; hasDetailsPage: boolean }[] }[]>(`${environment.apiUrl}/api/brands/offer-compare`, body).toPromise();
  }

  public scrollToAnchor(anchor: string): void {
    const routeAnchor = this.route.snapshot.fragment;

    if (anchor === routeAnchor) {
      this.viewportScroller.scrollToAnchor(routeAnchor);
      this.viewportScroller.setOffset(ScrollOffset);
    }
  }

  public getAnchorLink(value: string): string {
    const anchorLink = value ? value.replace('\'', '') : '';
    return anchorLink ? anchorLink.split(' ').join('_').toLowerCase() : '';
  }

  public async getTopCategories(): Promise<Ad[]> {
    const date = moment().utc();
    const dateFrom = date.clone().subtract(1, 'month').startOf('month').toDate();
    const dateTo = date.clone().subtract(1, 'month').endOf('month').toDate();

    const country = this.profileService.getData().countryLast || 'US';

    const subdomain = this.configService.getSubdomain();
    const params = new HttpParams()
      .set('limit', 15)
      .append('country', 'all')
      .append('metricName', 'top_categories')
      .append('optionalFields', true)
      .append('dateFrom', dateFrom.toISOString())
      .append('dateTo', dateTo.toISOString());

    const topMetrics = await this.http.get<ITopCategories>(`${environment.apiUrl}/api/analytics/marketplaces/${subdomain}/top/open`, { params }).toPromise();
    const topCategories = topMetrics.top_categories?.data?.filter(brand => brand.image);

    const categoryIds = topCategories.filter(el => el.total).map(el => el.guid);
    const activeCategories = await this.filterCategoriesByActiveOffers(categoryIds, country);

    const result = topCategories
      .filter(brand => activeCategories.find(el => el.category_id === brand.guid))
      .map(brand => ({ ...brand, associatedOffers: activeCategories.find(el => el.category_id === brand.guid)?.associatedOffers }));

    return result
      .map(item => {
        const activeCategory = activeCategories.find(category => category.category_id === item.guid);
        if (!activeCategory) {
          return;
        }

        return new Ad({
          guid: item.guid,
          imageSquare: item.image,
          titleShort: item.name,
          adUrl: `/offers?c=${item.guid}`,
          content: item?.associatedOffers?.length === 1 ? item.associatedOffers[0] : null
        });
      }).filter(Boolean);
  }
}
