import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { pairwise } from 'rxjs/operators';

@Injectable()
export class ViewSettingsService {

  private routeScrollPositions: { [url: string]: number }[] = [];
  private routeShowMoreLimit: { [url: string]: number }[] = [];

  constructor(
    private router: Router
  ) {
    // Listen router events to save or restore scroll position on route change
    this.router.events.pipe(
      pairwise()
    ).subscribe(([prevRouteEvent, currRouteEvent]) => {
      if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
        this.routeScrollPositions[prevRouteEvent.urlAfterRedirects || prevRouteEvent.url] = window.pageYOffset;
      }
    });
  }

  getPageOffset(url: string): number {
    return this.routeScrollPositions[url] || 0;
  }

  getShowMoreLimit(url: string, defaultValue: number = 10): number {
    return this.routeShowMoreLimit[url] || defaultValue;
  }

  setShowMoreLimit(url: string, offset: number): void {
    this.routeShowMoreLimit[url] = offset;
  }
}
