import { Injectable } from '@angular/core';
import {
  SPLIT_TEST_EVENTS,
  SplitTestEventsType,
  SplitTestAnalyticsService
} from 'g3-common-ui';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UrlService } from '../url.service';

@Injectable({
  providedIn: 'root'
})
export class SplitTestEventsService {

  constructor(
    private readonly authService: AuthService,
    private readonly profileService: ProfileService,
    private readonly spliTestsService: SplitTestAnalyticsService,
    private readonly urlService: UrlService
  ) { }

  public emitPageViewEvents(data: { url?: string }): void {
    if (!data) {
      return;
    }

    if (data.url?.startsWith('/home')) {
      void this.emitEvent(SPLIT_TEST_EVENTS.viewHomepage, null, data.url);
    }
  }

  public emitSessionStartEvent(): void {
    const splitTestSession = this.getSessionStartEvent();
    void this.emitEvent(splitTestSession);
  }

  public emitSearchResultViewEvent(): void {
    void this.emitEvent(SPLIT_TEST_EVENTS.viewSearchResult, null);
  }

  public emitInternalClickoutEvent(): void {
    void this.emitEvent(SPLIT_TEST_EVENTS.clickoutInternal, null);
  }

  public emitExternalClickoutEvent(): void {
    void this.emitEvent(SPLIT_TEST_EVENTS.clickoutExternal, null);
  }

  /**
   * Define whether the clickout is external or internal and emit clickout event
   *
   * @param url - Clickout URL
   */
  public emitClickoutEventByUrl(url: string): void {
    if (!url) {
      console.error('Url was not provided. cannot define the clickout split test event.');
      return;
    }
    if (this.urlService.isCorporateSite(url)) {
      this.emitInternalClickoutEvent();
    } else {
      this.emitExternalClickoutEvent();
    }
  }

  private emitEvent(eventName: SplitTestEventsType, payload?: Record<string, unknown>, url?: string): void {
    void this.spliTestsService.sttsEventTrack(eventName, payload || { source: 'main-application' }, url);
  }

  private getSessionStartEvent(): SplitTestEventsType {
    if (this.profileService.isGuestUser()) {
      return SPLIT_TEST_EVENTS.sessionStartGuest;
    } else if (this.profileService.isKnownUser()) {
      return SPLIT_TEST_EVENTS.sessionStartKnown;
    } else if (this.authService.hasAccessToken() && !this.profileService.isGuestOrKnownUser()) {
      return SPLIT_TEST_EVENTS.sessionStartAuth;
    }
  }

}
