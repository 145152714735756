import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EmailValidatorService } from 'g3-common-ui';

import { OffersService } from '@shared/services/offers.service';
import { KnownUserService } from '@shared/services/known-user.service';
import { ModalService } from '@app/shared/services/modal.service';
import { OfferModel } from '@app/shared/models/offer.model';

@Component({
  selector: 'app-offer-otp-modal',
  templateUrl: './offer-otp-modal.component.html',
  styleUrls: ['./offer-otp-modal.component.less']
})
export class OfferOtpModalComponent implements OnInit {

  @Input()
  public offerGuid: string;

  @Output() public otpCompleted: EventEmitter<boolean> = new EventEmitter();

  public email: string;
  public offer: OfferModel;

  constructor(
    private offersService: OffersService,
    private knownUserService: KnownUserService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.email = EmailValidatorService.validateEmail(this.knownUserService.knowUserEmail)
      ? this.knownUserService.knowUserEmail
      : this.knownUserService.knowUserEmailContact;
    void (this.offersService.getOffer(this.offerGuid) as Promise<unknown>).then((offerResponse) => {
      this.offer = new OfferModel(offerResponse);
    });
  }

  public async onValidCode(): Promise<void> {
    this.modalService.closeModal('close');
    const { off_guid } = this.route.snapshot.queryParams;

    if (!off_guid) {
      const isChild = this.knownUserService.knowUserType?.includes('child') || false;

      if (!isChild) {
        const redirectInfo = {} as { url: string; queryParams: Params };

        if (this.offer.descriptionDetails) {
          redirectInfo.url = `/offers/${this.offerGuid}`;
        } else {
          redirectInfo.url = '/home';
          redirectInfo.queryParams = { off_guid: this.offerGuid };
        }

        localStorage.setItem('local-redirect-url', JSON.stringify(redirectInfo));

        await this.router.navigate(['/home']);
      } else {
        this.otpCompleted.emit(true);
        return;
      }

    }
    window.location.reload();
  }

  public closeModal(): void {
    this.modalService.closeModal('close');
  }
}
