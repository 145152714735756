<div class="modal-header">
  <div class="modal-expired-icon">
    <img alt="Expired icon"
         role="presentation"
         aria-hidden="true"
         loading="lazy"
         src="/assets/icons/ic-ghost.svg">
  </div>
  <div class="title" tabindex="-1" ngbAutofocus>Offer Unavailable</div>

  <button type="button"
          role="button"
          class="close"
          aria-label="Close"
          (click)="dismiss()">
    <span aria-hidden="true"
          class="close-icon">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="info-text" [innerText]="modalBodyText"></div>
</div>

<div class="modal-footer">
  <div *ngIf="availableCountries.length > 1" class="input-control">
    <label for="select-country" class="input-label">Location</label>
    <app-select-input
      name="select-country"
      [options]="countriesOptions"
      [selectedValue]="selectedCountryCode"
      (selected)="selectOption($event)"
      [placeholder]="'Select location'"
    ></app-select-input>
    <span *ngIf="errored" class="error-text">
      Please select location from the list
    </span>
  </div>
  <button type="button"
          role="button"
          class="btn btn-primary"
          (click)="switchCountry()"
    >
    SWITCH LOCATION
  </button>
</div>
