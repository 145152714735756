/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { INSURANCE_MINI } from '@app/widgets/constants/widget-ids.constant';
import { SessionStorageService } from '@widgets/services/session-storage.service';
import { UrlService } from '@app/shared/services/url.service';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';

@Component({
  selector: 'app-insurance-form',
  templateUrl: './insurance-form.component.html',
  styleUrls: ['./insurance-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InsuranceFormComponent implements OnInit {

  @Input() widgetClass = '';
  @Input() model: CustomWidgetModel;

  @Output() triggerSearch: EventEmitter<string> = new EventEmitter<string>();

  groupTabIndex: number;
  zipCode: string;
  isExternalUrl: boolean;

  constructor(private sessionStorageService: SessionStorageService,
    private widgetSuggestionService: WidgetSuggestionService,
    private urlService: UrlService) { }

  ngOnInit(): void {
    this.isExternalUrl = this.urlService.isExternalUrl(this.model.destinationUrl);
    const parsedInsuranceWidgetData = this.sessionStorageService.getItem<any>(INSURANCE_MINI);

    if (parsedInsuranceWidgetData && parsedInsuranceWidgetData.zipCode) {
      this.zipCode = parsedInsuranceWidgetData.zipCode;
    }
  }

  onTriggerSearch(event: Event): void {
    event.preventDefault();

    this.triggerSearch.emit(this.zipCode);
    this.sessionStorageService.removeGroupOfKeys('_mini');
    this.sessionStorageService.setItem(INSURANCE_MINI, { zipCode: this.zipCode });
    void this.widgetSuggestionService.addSuggestedWidgets(this.getWidgetDataToSuggestionSave());
  }

  private getWidgetDataToSuggestionSave(): any {
    return {
      data: {
        zipCode: this.zipCode,
      },
      destination_url: this.model.destinationUrl.replace('%zipcode%', this.zipCode),
      page_url: window.location.href,
      type: INSURANCE_MINI
    };
  }

}
