<div class="modal-header">
  <div class="modal-header-container">
    <h3 class="modal-title notranslate"
        [innerHTML]="brandTitle">
    </h3>
    <p class="modal-subtitle"
       [innerHTML]="ad.descriptionDisclaimer"></p>
  </div>

  <div class="modal-action">
    <button (click)="closeModal()"
            class="btn btn-primary modal-action-btn">
      ok, got it
    </button>
  </div>

  <button type="button"
          class="g3-modal-close modal-header-close"
          aria-label="Close"
          (click)="closeModal()">
  </button>
</div>
