import * as moment from 'moment/moment';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Transform date to spec format
 * Takes an configure argument with date format that defaults to 'MM/DD/YYYY'.
 * Usage:
 *   value | formatDate:dateFormat
 * Example:
 *   {{ "2018-08-16T06:33:59.086Z" | formatDate:'MM/DD/YYYY' }}
 *   formats to: 06/08/2018
 */

export const DEFAULT_FORMAT_DATE = 'MM/DD/YYYY';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  ISO_8601 = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

  transform(value: string, outputFormat?: string, inputFormat?: string, returnUtcTimezone: boolean = true): string {
    if (!value) {
      return '';
    }

    const config = outputFormat ? outputFormat : DEFAULT_FORMAT_DATE;
    if (inputFormat) {
      return returnUtcTimezone
        ? moment(value, inputFormat).utc().format(config)
        : moment(value, inputFormat).format(config);
    }
    return returnUtcTimezone ? moment(value).utc().format(config) : moment(value).format(config);
  }
}
