import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { ImageUrlPipeOptions } from 'g3-common-ui';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.less']
})
export class LogoComponent implements OnInit {

  @Input() public route = '';
  @Input() public navigateRoute = true;
  @Input() public imgClass = 'big-logo';
  @Input() public logoContainerClass = '';

  @Output() public clickLogo: EventEmitter<void> = new EventEmitter<void>();

  public path: string;
  public title: string;
  public logoOptions: ImageUrlPipeOptions;

  constructor(
    private config: ConfigService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.path = this.config.getOption<string>('logo', '');
    this.title = this.config.getOption<string>('name', '');
    const defaultOptions = { w: 146, h: 31, fit: 'clip' };

    if (this.logoContainerClass === 'header-logo') {
      this.logoOptions = { w: 440, h: 40, fit: 'clip', trim: 'color', 'trim-color': 'FFFFFF' };
    } else {
      this.logoOptions = defaultOptions;
    }
  }

  public onClickLogo(): void {
    if (window.location.href.includes('/home')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (this.navigateRoute) {
      void this.router.navigate([this.route]);
    }
    this.clickLogo.emit();
  }

}
