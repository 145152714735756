/* eslint-disable typescriptESlintPlugin/no-misused-promises */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-unused-expressions */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { Category } from '@app/shared/interfaces/category.interface';
import {
  ComponentHtmlIdRegistryService,
  DataScrollerComponent,
  DataScrollerEvent,
  DataScrollerService,
  WINDOW
} from 'g3-common-ui';
import { ComponentViewVariant, ProviderLocationType } from '../../interfaces/provider-location.type';
import { ConfigService, HeaderAnimationState } from '@shared/services/config.service';
import { CtaClickData } from '@app/shared/components/provider-locations-panel/provider-locations-panel.component';
import { DirectClickOutService } from '@shared/services/direct-click-out.service';
import { environment } from '@environments/environment';
import {
  filter,
  map,
  take,
  tap
} from 'rxjs/operators';
import { GeographyService } from '@app/shared/services/geography.service';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { Location } from '@angular/common';
import { Map } from 'mapbox-gl';
import { MapConfig } from '@app/shared/interfaces/map-config.interface';
import { MapMarkerPreviewService } from '@app/shared/services/map-marker-preview.service';
import { MapsGeometryService } from '@app/shared/services/maps-geometry.service';
import { Marker } from '@app/shared/interfaces/marker.interface';
import { ModalAlertComponent } from '@shared/components/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotiBlockService } from '@zones/services/noti-block.service';
import { Observable } from 'rxjs';
import { OPACITY_FADE_IN_OUT_ANIMATION, SLIDE_IN_OUT_PANEL_ANIMATION, SLIDE_UP_DOWN_PANEL_ANIMATION } from '@shared/animations/slide-in-out-panel.animation';
import { ProfileService } from '@shared/services/profile/profile.service';
import { ProviderLocationsService } from '../../services/provider-locations.service';
import { ZOOM_IN_SVG, ZOOM_OUT_SVG } from './svg.constants';

export enum MarkerSizes {
  initialLarge = '38px',
  initialSmall = '10px',
  hoverLarge = '64px',
  hoverSmall = '24px'
}

const ASK_LOCATION_DELAY = 500;

@Component({
  selector: 'app-provider-locations',
  templateUrl: './provider-locations.component.html',
  styleUrls: ['./provider-locations.component.less'],
  animations: [SLIDE_IN_OUT_PANEL_ANIMATION, SLIDE_UP_DOWN_PANEL_ANIMATION, OPACITY_FADE_IN_OUT_ANIMATION],
  providers: [ComponentHtmlIdRegistryService]
})

export class ProviderLocationsComponent extends DataScrollerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() wideContainerClass = '' as string;
  @Input() groupTabIndex: number;
  @Input() type = ComponentViewVariant.widget;

  public title = 'Offers Near Me' as string;
  public anchorLink: string;
  public styledMapType: any[] = [];
  public mapConfig: MapConfig;
  public mapLoading = true;
  public colorPrimary = '' as string;
  public isPanelVisible = false;
  public zipCode = '78701' as string;
  public country = 'US' as string;
  public markers$: Observable<Marker[]>;
  public category = '';
  public categories$: Observable<Category[]>;
  public locationType: ProviderLocationType = '';
  public zipSuggestions: string[];
  public cachedDistance = 0;
  public centerLat = 30.307182;
  public centerLng = -97.755996;
  public mapInstance: any = {} as any;
  public searchButtonLeftBinding = '';
  public shouldShowSearchButton = false;
  public zipCodeInvalid = false;
  public isDesktop = false;
  public isResponsive = false;
  public MOBILE_VIEWPORT_WIDTH = 767;
  public DESKTOP_VIEWPORT = 1023;
  public customClass: string;
  public selectedLocation: Marker = {} as Marker;
  public locationDisclaimer = '' as string;
  public markers: Marker[] = [];
  public expandCard = false;
  public markerGuid = '' as string;
  public circleLayerSourceGeometry: any;
  public circleLayerStyles: any;
  public centerBoundLat: number;
  public centerBoundLng: number;
  public notificationBannerData: boolean;

  protected scrollEvent = DataScrollerEvent.Home;

  private cachedCenter: any;
  private defaultZoom = 11;
  private activatedMarker = {} as Marker | any;
  private hoveredMarkers: Marker[] = [];

  constructor(
    public idRegistry: ComponentHtmlIdRegistryService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private providerLocationsService: ProviderLocationsService,
    private profileService: ProfileService,
    private modalService: NgbModal,
    @Inject(WINDOW) private window: WINDOW,
    public changeDetector: ChangeDetectorRef,
    private mapsGeometryService: MapsGeometryService,
    private geographyService: GeographyService,
    public dataScrollerService: DataScrollerService,
    private el: ElementRef,
    private _location: Location,
    private homepageAdsService: HomepageAdsService,
    private mapMarkerPreviewService: MapMarkerPreviewService,
    private directClickOutService: DirectClickOutService,
    private notiBlockService: NotiBlockService
  ) {
    super(dataScrollerService);
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any): void {
    if (this.mapInstance && this.mapInstance.resize) {
      this.mapInstance.resize();
    }

    if (e.target.innerWidth > this.DESKTOP_VIEWPORT) {
      this.customClass = 'provider-filters';
    } else {
      this.customClass = 'mobile-provider';
    }

    this.isResponsive = e.target.innerWidth <= this.DESKTOP_VIEWPORT;
    this.isDesktop = e.target.innerWidth > this.DESKTOP_VIEWPORT;
  }

  async ngOnInit(): Promise<void> {
    if (this.window.innerWidth > this.DESKTOP_VIEWPORT) {
      this.customClass = 'provider-filters';
    } else {
      this.customClass = 'mobile-provider';
    }

    this.isResponsive = this.window.innerWidth <= this.DESKTOP_VIEWPORT;
    this.isDesktop = this.window.innerWidth > this.DESKTOP_VIEWPORT;
    this.markers$ = this.providerLocationsService.markers$.pipe(
      tap(res => {
        this.locationDisclaimer = res.meta.map_disclaimer;
      }),
      map(res => res.data)
    );
    this.componentSubscriptions.add(this.markers$.subscribe(markers => this.markers = markers));

    this.colorPrimary = this.configService.getOption('colors', { primary: '' }).primary;

    const profile = await this.profileService.profileData$.pipe(
      filter(data => Object.keys(data).length > 0),
      take(1)
    ).toPromise();

    if (profile && profile.countryLast) {
      this.country = profile.countryLast;
    }

    const zips = await this.providerLocationsService.getLastZipLocations();
    if (zips) {
      this.zipSuggestions = zips;
      this.zipCode = this.zipSuggestions[0] || '78701';
    }
    await this.initMap();

    this.anchorLink = this.homepageAdsService.getAnchorLink(`s_${this.title}`);
    setTimeout(() => this.homepageAdsService.scrollToAnchor(this.anchorLink));

    this.restartMapOnBannerClose();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const headerLogo: HTMLElement = document.querySelector('.skip-control-container');
      const divider: HTMLElement = document.querySelector('.logo-title-divider');
      if (headerLogo) {
        headerLogo.style.display = 'none';
      }
      if (divider) {
        divider.style.border = 'none';
      }
    });
    const currentLocation = JSON.parse(localStorage.getItem('location'));

    if (!currentLocation || currentLocation && !currentLocation.zipCode) {
      setTimeout(() => {
        this.setMapUserCurrentPosition();
      }, ASK_LOCATION_DELAY);
    }
  }

  async onZipSelected(zip = ''): Promise<void> {
    this.zipCodeInvalid = false;
    if (zip) {
      this.zipCode = zip;
    }

    if (this.zipCode) {

      try {
        const foundCachedByZipCoordinates = await this.geographyService.getLocationByZip(this.zipCode, this.country);

        if (foundCachedByZipCoordinates) {
          const coordinates = foundCachedByZipCoordinates;

          this.centerLat = coordinates.lat;
          this.centerLng = coordinates.lng;
        }

        this.mapInstance.setZoom(this.defaultZoom);
        this.mapInstance.setCenter({ lat: this.centerLat, lng: this.centerLng });
        this.cachedCenter = this.mapInstance.getCenter();
        await this.searchThisLocation(this.zipCode);

      } catch (err) {
        this.zipCodeInvalid = true;
        console.error(err);
      }
    }
  }

  public setMarkerGuid(markerGuid: string): void {
    this.markerGuid = markerGuid;
  }

  handleMarkerClick(event: any, marker: Marker): void {
    if (this.isResponsive) {
      this.closePanelAndResetData();
      setTimeout(() => this.openOffer(marker), 300);
    } else {
      this.openOffer(marker);
      this.mapMarkerPreviewService.removeHighlightedState(marker.guid);
      this.mapMarkerPreviewService.highlightCard(marker.guid, true);
    }
  }

  public showPanel(): void {
    this.isPanelVisible = true;
  }

  public closePanelAndResetData(): void {
    this.isPanelVisible = false;
    this.selectedLocation = null;
  }

  async ctaClick(data: CtaClickData): Promise<void> {
    this.selectedLocation = this.markers.find(i => i.guid === data.guid);
    this.trackAnalyticsEvent('provider-location-cta-click', { markerGuid: data.guid }, this.selectedLocation);
  }

  viewDetailsClick(data: Marker): void {
    this.trackAnalyticsEvent('provider-location-view-details', { markerGuid: data.guid }, data);
  }

  handleMapReady(mapInstance: Map): void {
    this.mapInstance = mapInstance;

    if (this.mapInstance.resize) {
      this.mapInstance.resize();
    }

    void this.searchThisLocation();
  }

  // computeDistance({ mapBounds, cachedCenter = null }: { mapBounds: LatLngBounds, cachedCenter?: { lat: number, lng: number } }): number {
  computeDistance({ mapBounds, cachedCenter = null }: { mapBounds; cachedCenter?: { lat: number; lng: number } }): number {
    const mapCenter = mapBounds.getCenter().toArray();
    const northEast = mapBounds.getNorthEast().toArray();

    const centerLat = mapCenter[1];
    const centerLong = mapCenter[0];
    this.centerBoundLat = centerLat;
    this.centerBoundLng = centerLong;

    const firstPoint = {
      lat: centerLat,
      lng: centerLong,
    };

    const secondPoint = cachedCenter
      ? cachedCenter
      : { lat: northEast[1], lng: centerLong };

    return this.mapsGeometryService.distanceBetweenEarthCoordinates(firstPoint, secondPoint);
  }

  async searchThisLocation(storedZip = ''): Promise<void> {
    this.category = '';
    this.locationType = '';

    const mapBounds = this.mapInstance.getBounds();
    const mapCenter = mapBounds.getCenter().toArray();

    const centerLat = mapCenter[1];
    const centerLong = mapCenter[0];

    const distance = this.computeDistance({ mapBounds });

    this.cachedDistance = distance;

    const zipCode = await this.getZipCodeBy(centerLat, centerLong);
    if (zipCode) {
      this.zipCode = zipCode;
      await this.profileService.saveZip(zipCode);
    }

    if (storedZip || zipCode) {
      this.shouldShowSearchButton = false;

      const offersPanel = document.querySelector('.list-view-panel.show-panel');
      const lng = offersPanel ? 0.07 : 0; // 0.07 - Approximate bias of center cirle
      await this.loadMapData({
        lat: centerLat,
        long: centerLong + lng,
        distance,
        zip: storedZip || zipCode,
        cat: this.category
      });

      await this.initMap(true);

      this.changeDetector.detectChanges(); // due to agm, do not detect changes right after we change map data
    }

    this.trackAnalyticsEvent('provider-location-search-this-location-click', {}, this.selectedLocation, true);
  }

  async getZipCodeBy(lat: number, long: number): Promise<string> {
    try {
      const location = await this.geographyService.getLocationByLatLong(lat, long);

      if (location) {
        return location.zip;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleBoundsChange($event): Promise<void> {
    const mapNewBounds = $event.target.getBounds();
    const mapCenter = $event.target.getCenter().toArray();

    const distance = this.computeDistance({ mapBounds: $event.target.getBounds() });
    const distanceToCachedCenter = this.cachedCenter
      ? this.computeDistance({ mapBounds: mapNewBounds, cachedCenter: this.cachedCenter })
      : 0;
    if (!this.cachedCenter || distanceToCachedCenter > 0.5 * distance) {
      this.cachedCenter = { lat: mapCenter[1], lng: mapCenter[0] + this.mapCircleBias() };
      this.cachedDistance = distance;

      this.searchButtonLeftBinding = 'calc(50% - 107px)';
      this.shouldShowSearchButton = true;
    }

    this.updateMapCircleCoordinates(mapCenter[0] + this.mapCircleBias(), mapCenter[1]);

  }

  async loadMapData({ lat = '', long = '', distance, zip = '', cat = '', locationType = null }): Promise<void> {
    const defaultLimit = 200;
    const defaultType = 'Point';
    const res = await this.providerLocationsService.getLocations({
      lat,
      long,
      distance: distance.toFixed(2),
      limit: defaultLimit,
      coordinatesType: defaultType,
      zip,
      cat,
      locationType
    });
    if (this.type) {
      this.providerLocationsService.paginate();
    }

    this.zipSuggestions = res.meta.zips;
  }

  filterMarkers(): void {
    this.providerLocationsService.filterBy(this.category, this.locationType);
    if (this.type) {
      this.providerLocationsService.paginate();
    }
  }

  trackAnalyticsEvent(eventType: string, eventData: any, locationItem: Marker, passLocation = false): void {
    if (passLocation) {
      const mapCenter = this.mapInstance.getBounds().getCenter().toArray();
      eventData = {
        lat: mapCenter[1],
        lng: mapCenter[0]
      };
    }
    this.directClickOutService.providerLocations.trackAnalyticsEvents(eventType, locationItem, eventData);
  }

  async initMap(onlyCircle = false): Promise<void> {
    let foundCachedByZipCoordinates = null;
    try {
      foundCachedByZipCoordinates = await this.geographyService.getLocationByZip(this.zipCode, this.country);
    } catch (err) {
      console.error(err);
    }

    if (foundCachedByZipCoordinates) {
      const coordinates = foundCachedByZipCoordinates;

      this.centerLat = coordinates.lat;
      this.centerLng = coordinates.lng;
    }

    this.initMapConfig(onlyCircle);
    this.mapLoading = false;
    this.initMapTabIndexes();

    this.dataScrollerService.triggerScroll(this.scrollEvent);
  }

  public panelState(event): void {
    const lng = event === false ? 0.07 : 0; // 0.07 - Approximate bias of center cirle
    this.updateMapCircleCoordinates(this.centerBoundLng + lng, this.centerBoundLat);
  }

  initMapConfig(onlyCircle = false): void {
    if (onlyCircle) {
      // set center of the map
      if (this.circleLayerSourceGeometry) {
        this.updateMapCircleCoordinates(this.centerLng, this.centerLat);
        return;
      }

      this.setMapCircles();
      return;
    }

    this.styledMapType = environment.providerLocationMapStyles;
    this.mapConfig = {
      latitude: this.centerLat,
      longitude: this.centerLng,
      zoom: this.defaultZoom,
      minZoom: 5,
      maxZoom: 15,
      mapTypeControl: false,
      streetViewControl: false,
      panControl: true
    };

    // set center of the map
    this.setMapCircles();
  }

  metersToPixelsAtMaxZoom(meters, latitude): number {
    // got this formula on https://stackoverflow.com/a/37794326
    return meters / 0.075 / Math.cos(latitude * Math.PI / 180);
  }

  async onMapReset(category = '', locationType: ProviderLocationType = ''): Promise<void> {
    const lat = this.centerLat;
    const long = this.centerLng;
    this.closePanelAndResetData();
    this.category = category;
    this.locationType = locationType;
    this.mapInstance.setCenter([long, lat]);

    await this.loadMapData({
      lat: lat.toString(),
      long: long.toString(),
      distance: this.cachedDistance,
      zip: this.zipCode,
      cat: this.category
    });

    /*
    Wait until all map data load,
    then hiding "Search this location" button and changing zoom level
    to avoid collision with handling map bounds changes
     */
    this.window.requestAnimationFrame(() => {
      this.shouldShowSearchButton = false;
      this.mapInstance.setZoom(11);
    });
  }

  setMapUserCurrentPosition(): void {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        async position => {
          const currentLat = position.coords.latitude;
          const currentLong = position.coords.longitude;
          const zipCode = await this.getZipCodeBy(currentLat, currentLong);
          this.saveLocationData(currentLat, currentLong, zipCode);
          void this.onZipSelected(zipCode);
          void this.onMapReset();
          localStorage.setItem('location', JSON.stringify({ lat: currentLat, lng: currentLong, zipCode }));
        },
        error => {
          console.error(error);
          this.alertMessage('Sorry, we\'re unable to detect your current location. Please try again later or use a different device.');
        }
      );
    } else {
      this.alertMessage('Sorry, we\'re unable to detect your current location. Please try again later or use a different device.');
    }
  }

  public activateMarker(event: Marker): void {
    this.markers.find(item => item === event).activated = true;
    this.activatedMarker = event;
    this.hideMarkerPreviewOnClick(event);
    setTimeout(() => this.highlightActivatedMarker(event));
  }

  public showPreviewMarker(event, marker: Marker): void {
    const offersPanel: HTMLElement = document.querySelector('.list-view-panel.show-panel');
    if (offersPanel) {
      offersPanel.style.zIndex = '1000';
    }

    const isExistTouchEvents = event.sourceCapabilities
      ? !event.sourceCapabilities.firesTouchEvents
      : true;

    if (window.innerWidth > this.DESKTOP_VIEWPORT && isExistTouchEvents) {

      // Drop activated state when another marker has been hovered
      if (this.activatedMarker === marker && this.activatedMarker.activated === false) {
        this.activatedMarker = {};
      }

      // Keep activated state on clicked marker while another marker will be hovered
      if (this.activatedMarker !== marker) {
        const activatedMarker = this.markers.find(item => item.activated);
        this.mapMarkerPreviewService.deactivateMarker(activatedMarker, this.markers);
        this.handlePreviewPosition(event, marker);
        marker.showPreviewMarker = true;
        this.hoveredMarkers.push(marker);
        /**
         * Current z-index provides for overriding other markers
         * When user focus in some marker, this marker gets z-index: 9000000
         * Other markers get z-index auto.
         * Reason: other markers overlaps preview container
         */
        event.target.offsetParent.style.boxShadow = '1px 11px 24px rgba(0, 0, 0, 0.44)';
        event.target.offsetParent.style.zIndex = '999';
      }
    } else {
      const largeMarkerSize = this.window.innerWidth > this.MOBILE_VIEWPORT_WIDTH ? MarkerSizes.hoverLarge : '56px';
      this.mapMarkerPreviewService.handleMarkerSize(
        marker,
        largeMarkerSize,
        largeMarkerSize,
        MarkerSizes.hoverSmall,
        MarkerSizes.hoverSmall);
    }
  }

  closeMap(): void {
    this._location.back();
    this.configService.updateHeaderAnimationState(HeaderAnimationState.out);
  }

  public hidePreviewMarker(marker: Marker): void {
    marker.showPreviewMarker = false;
    setTimeout(() => this.dropMarkersZIndex(), 100);
    this.mapMarkerPreviewService.handleMarkerSize(
      marker,
      MarkerSizes.initialLarge,
      MarkerSizes.initialLarge,
      MarkerSizes.initialSmall,
      MarkerSizes.initialSmall);
  }

  public dropMarkerSize(marker: Marker): void {
    if (window.innerWidth <= this.DESKTOP_VIEWPORT) {
      this.mapMarkerPreviewService.handleMarkerSize(
        marker,
        MarkerSizes.initialLarge,
        MarkerSizes.initialLarge,
        MarkerSizes.initialSmall,
        MarkerSizes.initialSmall);
    }
    if (this.hoveredMarkers) {
      this.hoveredMarkers.forEach(item => {
        if (item && item.showPreviewMarker === false && !item.activated) {
          this.mapMarkerPreviewService.handleMarkerSize(
            item,
            MarkerSizes.initialLarge,
            MarkerSizes.initialLarge,
            MarkerSizes.initialSmall,
            MarkerSizes.initialSmall);
        }
      });
    }
  }

  public addHoverClass(e: any): void {
    e.target.classList.add('hover');
  }

  public removeHoverClass(e: any): void {
    e.target.classList.remove('hover');
  }

  public addClassForZoomIn(e: any): void {
    e.target.classList.add('zoom-in-tooltip');
  }

  public addClassForZoomOut(e: any): void {
    e.target.classList.add('zoom-out-tooltip');
  }

  public removeClassForZoomIn(e: any): void {
    e.target.classList.remove('zoom-in-tooltip');
  }

  public removeClassForZoomOut(e: any): void {
    e.target.classList.remove('zoom-out-tooltip');
  }

  ngOnDestroy(): void {
    document.removeEventListener('mouseenter', e => this.addClassForZoomIn(e));
    document.removeEventListener('mouseenter', e => this.removeClassForZoomIn(e));
    document.removeEventListener('mouseleave', e => this.addClassForZoomOut(e));
    document.removeEventListener('mouseleave', e => this.removeClassForZoomOut(e));
  }

  private openOffer(marker): void {
    if (this.selectedLocation?.guid === marker.guid) {
      this.selectedLocation = null;
    } {
      this.selectedLocation = marker;
    }

    this.showPanel();
    this.expandCard = true;
    this.trackAnalyticsEvent('provider-location-marker-click', { markerGuid: marker.guid }, marker);
  }

  private mapCircleBias(): number {
    const offersPanel = document.querySelector('.list-view-panel.show-panel');
    return offersPanel ? 0.07 : 0; // 0.07 - Approximate bias of center cirle
  }
  private initMapTabIndexes(): void {
    this.window.requestAnimationFrame(() => {
      const compEl = this.el.nativeElement;

      // enable map focusing in the appropriate order
      const mapEl = compEl.querySelector('mgl-map canvas');
      this.updateMapCircleCoordinates(this.centerLng + this.mapCircleBias(), this.centerLat);
      if (mapEl) {
        mapEl.removeAttribute('tabIndex');
      }

      // tab order for zoom buttons
      const { tabIndex: zoomTabIndex } = (compEl.querySelector('#app-ngl-map-setup-current-position-button') || { tabIndex: 0 });
      let zoomElements = compEl.querySelectorAll(['.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in', '.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out']);

      if (zoomElements && zoomElements.length) {
        this.customTooltips(zoomElements[0], zoomElements[1]);

        zoomElements = [].slice.call(zoomElements); // Need for IE11 working; IE does not support NodeList foreach
        zoomElements.forEach(el => el.tabIndex = zoomTabIndex);
      }

      // exclude logo from tabbing sequence
      const logoEl = compEl.querySelector('.mapboxgl-ctrl-logo');
      if (logoEl) {
        logoEl.tabIndex = -1;
      }
    });
  }

  private setMapCircles(): void {
    this.circleLayerSourceGeometry = {
      type: 'Point',
      coordinates: [this.centerLng, this.centerLat]
    };

    this.circleLayerStyles = {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, this.metersToPixelsAtMaxZoom(10000, this.centerLat)]
        ],
        base: 2
      },
      'circle-color': this.colorPrimary,
      'circle-opacity': 0.1,
      'circle-stroke-width': 2,
      'circle-stroke-color': this.colorPrimary,
      'circle-stroke-opacity': 0.8,
    };
  }

  private updateMapCircleCoordinates(long: number, lat: number): void {
    if (this.mapInstance && this.mapInstance.getSource) {
      this.mapInstance.getSource('mapcircle').setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [long, lat]
        }
      });
    }
  }

  private alertMessage(message): void {
    const modal = this.modalService.open(ModalAlertComponent, { size: 'sm', centered: true });
    modal.componentInstance.content = {
      text: message
    };
  }

  private customTooltips(zoomIn, zoomOut): void {
    zoomIn.addEventListener('mouseenter', e => this.addClassForZoomIn(e));
    zoomIn.addEventListener('mouseleave', e => this.removeClassForZoomIn(e));
    zoomIn.removeAttribute('title');
    zoomIn.innerHTML = ZOOM_IN_SVG;

    zoomOut.addEventListener('mouseenter', e => this.addClassForZoomOut(e));
    zoomOut.addEventListener('mouseleave', e => this.removeClassForZoomOut(e));
    zoomOut.removeAttribute('title');
    zoomOut.innerHTML = ZOOM_OUT_SVG;
  }

  // Describes when Pin is clicked, pin border should be thicker and should stay until another pin is selected
  private hideMarkerPreviewOnClick(marker: Marker): void {
    this.activatedMarker.showPreviewMarker = false;
    setTimeout(() => {
      const activatedMarker = document.querySelector(`.preview.c${marker.guid}`);
      if (activatedMarker) {
        activatedMarker.classList.remove('preview');
        activatedMarker.classList.remove('preview-area');
      }
      this.dropMarkersZIndex();
    }, 400);
    this.mapMarkerPreviewService.handleMarkerSize(
      marker,
      MarkerSizes.hoverLarge,
      MarkerSizes.hoverLarge,
      MarkerSizes.hoverSmall,
      MarkerSizes.hoverSmall);
  }

  // Add highlight for activated markers
  private highlightActivatedMarker(marker: Marker): void {
    if (marker.label) {
      const activateMarker = document.querySelector(`.location-marker.c${marker.guid}`);
      activateMarker.classList.add('highlight-marker');
    }
    if (!marker.label) {
      const activateDefaultMarker = document.querySelector(`.default-marker.c${marker.guid}`);
      activateDefaultMarker.classList.add('preview-default-icon');
    }
  }

  // Needs to avoid overlaps
  private dropMarkersZIndex(): void {
    const mapMarkers = document.querySelectorAll('.mapboxgl-marker');
    mapMarkers.forEach(item => {
      const pin = item as HTMLElement;
      pin.style.zIndex = 'auto';
      pin.style.boxShadow = 'none';
    });
  }

  /**
   * @param container : change position relative to appropriate position for preview container
   * @param hoverArea : change position relative to appropriate position for hover area of preview container
   * @param clickArea : handle click area to appropriate marker
   * @param defaultClass : @default 'preview-area' remove default hover area of preview container
   */
  private handleRelativePosition(
    marker: Marker,
    container: string,
    hoverArea: string,
    clickArea: string,
    defaultClass = 'preview-area'
  ): void {

    const selector = document.querySelector('.preview');
    const previews = document.querySelectorAll('.preview');
    const clickSmallAreaSelector = document.querySelector('.small-click-area');
    const clickLargeAreaSelector = document.querySelector('.large-click-area');
    if (previews.length > 1) {
      marker.showPreviewMarker = false;
    } else {
      selector.classList.remove(defaultClass);
      selector.classList.add(container);
      selector.classList.add(hoverArea);
      if (clickSmallAreaSelector) {
        clickSmallAreaSelector.classList.add(clickArea);
      }
      if (clickLargeAreaSelector) {
        clickLargeAreaSelector.classList.add(clickArea);
      }
    }
  }

  /**
   * @param container : reset preview container to default preview position (top)
   * @param hoverArea : reset hover area to default position (top)
   */
  private clearRelativeHandling(container: string, hoverArea: string, clickArea: string): void {
    const selector = document.querySelector('.preview');
    const clickAreaSelector = document.querySelector('.small-click-area');
    selector.classList.remove(container);
    selector.classList.remove(hoverArea);
    if (clickAreaSelector) {
      clickAreaSelector.classList.remove(clickArea);
    }
  }

  private handlePreviewPosition(event, marker: Marker): void {

    setTimeout(() => {
      const listViewPanel: HTMLElement = document.querySelector('.list-view-panel');
      const headerPrimary: HTMLElement = document.querySelector('.header-primary');
      const notiBlock: HTMLElement = document.querySelector('.noti-block');
      const previewContainer: HTMLElement = document.querySelector('.preview');

      const offsetX = event.clientX - (previewContainer.offsetWidth / 2);
      const offsetY = event.clientY - (previewContainer.offsetHeight + 100);

      const previews = document.querySelectorAll('.preview');

      this.mapMarkerPreviewService.handleMarkerSize(
        marker,
        MarkerSizes.hoverLarge,
        MarkerSizes.hoverLarge,
        MarkerSizes.hoverSmall,
        MarkerSizes.hoverSmall);

      if (previews.length > 1) {
        marker.showPreviewMarker = false;
      }
      /*
        Calculates X/Y diff. Each variable returns positive/negative value.
        Then this value indicates the need for positioning: if @param < 0 - Need to change position
      */
      const positionDiffX = offsetX;
      const positionDiffWithPanelX = offsetX - listViewPanel.offsetWidth;
      const positionDiffRightX = window.innerWidth - (previewContainer.offsetWidth / 2) - event.clientX;

      let headerHeight = headerPrimary.offsetHeight;
      if (notiBlock) {
        headerHeight += notiBlock.offsetHeight;
      }
      const positionDiffPanelTopY = offsetY - headerHeight;
      const positionDiffPanelBottomY = window.innerHeight - (previewContainer.offsetHeight / 2) - event.clientY;

      /**  ---------------------Handle preview position if @param marker.label = true && @param marker.info_label = true ------------------------ */
      if (marker.label && marker.info_label) {
        /**
         * -----------------------lEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to left edge of the screen if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to left edge of the screen if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelTopY >= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'right-position', 'wrapper-right-position', 'right-area')
            : this.clearRelativeHandling('right-position', 'wrapper-right-position', 'right-area');
        } else {
          positionDiffPanelTopY >= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'right-position', 'wrapper-right-position', 'right-area')
            : this.clearRelativeHandling('right-position', 'wrapper-right-position', 'right-area');
        }

        /**
         * -----------------------TOP ALIGN--------------------------
         * if @param < 0, need to change the position of preview
         * Condition 1/2: Indicates the position of preview relative to top edge of the screen
         * Two conditions provide behavior with and without panel
         */
        if (this.showPanel) {
          positionDiffPanelTopY <= 0 && positionDiffWithPanelX >= 0
            ? this.handleRelativePosition(marker, 'bottom-position', 'wrapper-bottom-position', 'bottom-area')
            : this.clearRelativeHandling('bottom-position', 'wrapper-bottom-position', 'bottom-area');
        } else {
          positionDiffPanelTopY <= 0 && positionDiffX >= 0
            ? this.handleRelativePosition(marker, 'bottom-position', 'wrapper-bottom-position', 'bottom-area')
            : this.clearRelativeHandling('bottom-position', 'wrapper-bottom-position', 'bottom-area');
        }

        /**
         * -----------------------TOP LEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to the top-left corner if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to the top-left corner if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelTopY <= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'bottom-right-position', 'wrapper-bottom-right-position', 'bottom-right-area')
            : this.clearRelativeHandling('bottom-right-position', 'wrapper-bottom-right-position', 'bottom-right-area');
        } else {
          positionDiffPanelTopY <= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'bottom-right-position', 'wrapper-bottom-right-position', 'bottom-right-area')
            : this.clearRelativeHandling('bottom-right-position', 'wrapper-bottom-right-position', 'bottom-right-area');
        }

        /**
         * -----------------------RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to right edge of the screen
         * if @param < 0, need to change position of preview
         */
        positionDiffPanelTopY >= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'left-position', 'wrapper-left-position', 'left-area')
          : this.clearRelativeHandling('left-position', 'wrapper-left-position', 'left-area');

        /**
         * -----------------------TOP RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to the top-right corner
         */
        positionDiffPanelTopY <= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'bottom-left-position', 'wrapper-bottom-left-position', 'bottom-left-area')
          : this.clearRelativeHandling('bottom-left-position', 'wrapper-bottom-left-position', 'bottom-left-area');

        /**
         * -----------------------BOTTOM LEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to the bottom-left corner if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to the bottom-left corner if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelBottomY <= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'top-right-position', 'wrapper-top-right-position', 'top-right-area')
            : this.clearRelativeHandling('top-right-position', 'wrapper-top-right-position', 'top-right-area');
        } else {
          positionDiffPanelBottomY <= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'top-right-position', 'wrapper-top-right-position', 'top-right-area')
            : this.clearRelativeHandling('top-right-position', 'wrapper-top-right-position', 'top-right-area');
        }

        /**
         * -----------------------BOTTOM RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to the bottom-right corner
         */
        positionDiffPanelBottomY <= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'top-left-position', 'wrapper-top-left-position', 'top-left-area')
          : this.clearRelativeHandling('top-left-position', 'wrapper-top-left-position', 'top-left-area');

        // ----------------------------------------------------------------------------------------------------
        /**  ---------------------Handle preview position if @param marker.label = false && if @param marker.info_label = true ------------------------ */
      } else if (marker.info_label && !marker.label) {
        /**
         * -----------------------lEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to left edge of the screen if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to left edge of the screen if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelTopY >= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'right-small-logo-position', 'wrapper-right-small-logo-position', 'right-small-logo-area')
            : this.clearRelativeHandling('right-small-logo-position', 'wrapper-right-small-logo-position', 'right-small-logo-area');
        } else {
          positionDiffPanelTopY >= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'right-small-logo-position', 'wrapper-right-small-logo-position', 'right-small-logo-area')
            : this.clearRelativeHandling('right-small-logo-position', 'wrapper-right-small-logo-position', 'right-small-logo-area');
        }

        /**
         * -----------------------TOP ALIGN--------------------------
         * if @param < 0, need to change the position of preview
         * Condition 1/2: Indicates the position of preview relative to top edge of the screen
         * Two conditions provide behavior with and without panel
         */
        if (this.showPanel) {
          positionDiffPanelTopY <= 0 && positionDiffWithPanelX >= 0
            ? this.handleRelativePosition(marker, 'bottom-small-logo-position', 'wrapper-bottom-small-logo-position', 'bottom-small-logo-area')
            : this.clearRelativeHandling('bottom-small-logo-position', 'wrapper-bottom-small-logo-position', 'bottom-small-logo-area');
        } else {
          positionDiffPanelTopY <= 0 && positionDiffX >= 0
            ? this.handleRelativePosition(marker, 'bottom-small-logo-position', 'wrapper-bottom-small-logo-position', 'bottom-small-logo-area')
            : this.clearRelativeHandling('bottom-small-logo-position', 'wrapper-bottom-small-logo-position', 'bottom-small-logo-area');
        }

        /**
         * -----------------------TOP LEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to the top-left corner if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to the top-left corner if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelTopY <= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'bottom-right-small-logo-position', 'wrapper-bottom-right-small-logo-position', 'bottom-right-small-logo-area')
            : this.clearRelativeHandling('bottom-right-small-logo-position', 'wrapper-bottom-right-small-logo-position', 'bottom-right-small-logo-area');
        } else {
          positionDiffPanelTopY <= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'bottom-right-small-logo-position', 'wrapper-bottom-right-small-logo-position', 'bottom-right-small-logo-area')
            : this.clearRelativeHandling('bottom-right-small-logo-position', 'wrapper-bottom-right-small-logo-position', 'bottom-right-small-logo-area');
        }

        /**
         * -----------------------RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to right edge of the screen
         * if @param < 0, need to change position of preview
         */
        positionDiffPanelTopY >= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'left-small-logo-position', 'wrapper-left-small-logo-position', 'left-small-logo-area')
          : this.clearRelativeHandling('left-small-logo-position', 'wrapper-left-small-logo-position', 'left-small-logo-area');

        /**
         * -----------------------TOP RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to the top-right corner
         */
        positionDiffPanelTopY <= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'bottom-left-small-logo-position', 'wrapper-bottom-left-small-logo-position', 'bottom-left-small-logo-area')
          : this.clearRelativeHandling('bottom-left-small-logo-position', 'wrapper-bottom-left-small-logo-position', 'bottom-left-small-logo-area');

        /**
         * -----------------------BOTTOM LEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to the bottom-left corner if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to the bottom-left corner if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelBottomY <= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'top-right-small-logo-position', 'wrapper-top-right-small-logo-position', 'top-right-small-logo-area')
            : this.clearRelativeHandling('top-right-small-logo-position', 'wrapper-top-right-small-logo-position', 'top-right-small-logo-area');
        } else {
          positionDiffPanelBottomY <= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'top-right-small-logo-position', 'wrapper-top-right-small-logo-position', 'top-right-small-logo-area')
            : this.clearRelativeHandling('top-right-small-logo-position', 'wrapper-top-right-small-logo-position', 'top-right-small-logo-area');
        }

        /**
         * -----------------------BOTTOM RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to the bottom-right corner
         */
        positionDiffPanelBottomY <= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'top-left-small-logo-position', 'wrapper-top-left-small-logo-position', 'top-left-small-logo-area')
          : this.clearRelativeHandling('top-left-small-logo-position', 'wrapper-top-left-small-logo-position', 'top-left-small-logo-area');
        // ----------------------------------------------------------------------------------------------------
      } else {
        // ----------------------------------------------------------------------------------------------------
        /**
         * -----------------------lEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to left edge of the screen if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to left edge of the screen if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelTopY >= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'right-small-position', 'wrapper-right-small-position', 'right-small-area')
            : this.clearRelativeHandling('right-small-position', 'wrapper-right-small-position', 'right-small-area');
        } else {
          positionDiffPanelTopY >= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'right-small-position', 'wrapper-right-small-position', 'right-small-area')
            : this.clearRelativeHandling('right-small-position', 'wrapper-right-small-position', 'right-small-area');
        }

        /**
         * -----------------------TOP ALIGN--------------------------
         * if @param < 0, need to change the position of preview
         * Condition 1/2: Indicates the position of preview relative to top edge of the screen
         * Two conditions provide behavior with and without panel
         */
        if (this.showPanel) {
          positionDiffPanelTopY <= 0 && positionDiffWithPanelX >= 0
            ? this.handleRelativePosition(marker, 'bottom-small-position', 'wrapper-bottom-small-position', 'bottom-small-area')
            : this.clearRelativeHandling('bottom-small-position', 'wrapper-bottom-small-position', 'bottom-small-area');
        } else {
          positionDiffPanelTopY <= 0 && positionDiffX >= 0
            ? this.handleRelativePosition(marker, 'bottom-small-position', 'wrapper-bottom-small-position', 'bottom-small-area')
            : this.clearRelativeHandling('bottom-small-position', 'wrapper-bottom-small-position', 'bottom-small-area');
        }

        /**
         * -----------------------TOP LEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to the top-left corner if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to the top-left corner if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelTopY <= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'bottom-right-small-position', 'wrapper-bottom-right-small-position', 'bottom-right-small-area')
            : this.clearRelativeHandling('bottom-right-small-position', 'wrapper-bottom-right-small-position', 'bottom-right-small-area');
        } else {
          positionDiffPanelTopY <= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'bottom-right-small-position', 'wrapper-bottom-right-small-position', 'bottom-right-small-area')
            : this.clearRelativeHandling('bottom-right-small-position', 'wrapper-bottom-right-small-position', 'bottom-right-small-area');
        }

        /**
         * -----------------------RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to right edge of the screen
         * if @param < 0, need to change position of preview
         */
        positionDiffPanelTopY >= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'left-small-position', 'wrapper-left-small-position', 'left-small-area')
          : this.clearRelativeHandling('left-small-position', 'wrapper-left-small-position', 'left-small-area');

        /**
         * -----------------------TOP RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to the top-right corner
         */
        positionDiffPanelTopY <= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'bottom-left-small-position', 'wrapper-bottom-left-small-position', 'bottom-left-small-area')
          : this.clearRelativeHandling('bottom-left-small-position', 'wrapper-bottom-left-small-position', 'bottom-left-small-area');

        /**
         * -----------------------BOTTOM LEFT ALIGN--------------------------
         * Condition 1: Indicates the position of preview relative to the bottom-left corner if @param showPanel = true
         * Condition 2: Indicates the position of preview relative to the bottom-left corner if @param showPanel = false
         */
        if (this.showPanel) {
          positionDiffPanelBottomY <= 0 && positionDiffWithPanelX <= 0
            ? this.handleRelativePosition(marker, 'top-right-small-position', 'wrapper-top-right-small-position', 'top-right-small-area')
            : this.clearRelativeHandling('top-right-small-position', 'wrapper-top-right-small-position', 'top-right-small-area');
        } else {
          positionDiffPanelBottomY <= 0 && positionDiffX <= 0
            ? this.handleRelativePosition(marker, 'top-right-small-position', 'wrapper-top-right-small-position', 'top-right-small-area')
            : this.clearRelativeHandling('top-right-small-position', 'wrapper-top-right-small-position', 'top-right-small-area');
        }

        /**
         * -----------------------BOTTOM RIGHT ALIGN--------------------------
         * Condition: Indicates the position of preview relative to the bottom-right corner
         */
        positionDiffPanelBottomY <= 0 && positionDiffRightX <= 0
          ? this.handleRelativePosition(marker, 'top-left-small-position', 'wrapper-top-left-small-position', 'top-left-small-area')
          : this.clearRelativeHandling('top-left-small-position', 'wrapper-top-left-small-position', 'top-left-small-area');
      }
    });
  }

  private saveLocationData(lat: number, lng: number, zip: string): void {
    void this.profileService.saveLocation(lat, lng);
    void this.profileService.saveZip(zip);
  }

  private restartMapOnBannerClose(): void {
    this.componentSubscriptions.add(this.configService.getNotificationBannerState().subscribe(data => {
      this.mapLoading = true;
      this.notificationBannerData = data;
      setTimeout(() => this.mapLoading = false, 500);
    }));

    this.componentSubscriptions.add(this.notiBlockService.notiBlockState$.subscribe(() => {
      this.mapLoading = true;
      setTimeout(() => this.mapLoading = false);
    }));
  }
}
