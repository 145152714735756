/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import flatten from 'lodash/flatten';
import { dropdownTitlesData } from '@widgets/forms/rental-car-form/rental-car-options';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AutocompleteData,
  AutocompleteSection,
} from '@shared/components/autocomplete-dropdown/autocomplete-data.interface';

@Injectable()
export class RentalCarService {

  constructor(
    private http: HttpClient,
  ) { }

  async getRentalCarAutocompleteData(keyword: string, hostUrl = environment.apiUrl): Promise<any> {
    return this.http
      .get<any>(`${hostUrl}/api/priceline/${keyword}`)
      .toPromise();
  }

  getAutocompleteSections(airports = [], cities = []): AutocompleteSection[] {
    if (airports.length && cities.length) {
      return [
        this.setSectionData(dropdownTitlesData.airportsIcon, dropdownTitlesData.airportsTitle,
          this.mapSectionDataItems(airports, 'airport_name', 'airport_code')),
        this.setSectionData(dropdownTitlesData.citiesIcon, dropdownTitlesData.citiesTitle,
          this.mapSectionDataItems(cities, 'city', 'ppn_car_cityid')),
      ];
    } else if (airports.length && !cities.length) {
      return [
        this.setSectionData(dropdownTitlesData.airportsIcon, dropdownTitlesData.airportsTitle,
          this.mapSectionDataItems(airports, 'airport_name', 'airport_code'))
      ];
    } else if (airports.length && cities.length) {
      return [
        this.setSectionData(dropdownTitlesData.citiesIcon, dropdownTitlesData.citiesTitle,
          this.mapSectionDataItems(cities, 'city', 'ppn_car_cityid'))
      ];
    } else {
      return [];
    }
  }

  mapResponseData(response): any {
    Object.keys(response.getCarAutoComplete.results)
      .filter(key => key.endsWith('_data') && typeof response.getCarAutoComplete.results[key] === 'object')
      .forEach((key) => response.getCarAutoComplete.results[key] = Object.values(response.getCarAutoComplete.results[key]));

    return response.getCarAutoComplete.results;
  }

  getDestinationPlace(value: string, airports, cities): string {
    const airport = airports.find(i => i.airport_code === value);
    const city = cities.find(i => i.ppn_car_cityid === value);
    if (airport) {
      return airport.airport_name;
    }

    if (city) {
      return city.city;
    }
  }

  getCitiesAirports(cities): any {
    return flatten(cities
      .filter(city => city.airport_data)
      .map(city => Object.values(city.airport_data)));
  }

  mapSectionDataItems(array, nameKey: string, valueKey: string): any {
    return array.map(i => ({ title: this.formatAutocompleteTitle(i, nameKey), value: i[valueKey] }));
  }

  setSectionData(iconUrl: string, title: string, data: AutocompleteData[]): AutocompleteSection {
    return { iconUrl, title, data };
  }

  private formatAutocompleteTitle(obj, nameKey: string): string {
    if (nameKey === 'city' && obj.state_code) {
      return this.replaceNumericHtmlChars(`${obj[nameKey]}, ${obj.state_code}`);
    } else if (nameKey === 'airport_name' && obj.airport_code) {
      return this.replaceNumericHtmlChars(`${obj[nameKey]} (${obj.airport_code})`);
    } else {
      return this.replaceNumericHtmlChars(obj[nameKey]);
    }
  }

  private replaceNumericHtmlChars(input: string): string {
    return input.replace(/&#([^\s]*);/g, (x, y) => String.fromCharCode(y));
  }

}
