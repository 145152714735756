/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Injectable } from '@angular/core';

function _document(): any {
  return document;
}

@Injectable()
export class DocumentService {

  get document(): any {
    return _document();
  }

}
