/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AuthService } from '../services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { ContactFormService } from '@shared/services/contact-form.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MarketplaceInfoModel } from '@shared/models/marketplace-info.model';
import { Observable } from 'rxjs';

const CONTACT_US_URL = '?contactUs=true';
const MAX_NAVS_COUNT = 4;

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.less']
})
export class AuthFooterComponent implements OnInit {

  @Input() classListContainer: string;
  @Input() classListLeft: string;
  @Input() classListRight: string;
  @Input() displayLink = false;
  @Input() policyPageUrl = '/';
  @Input() showFooterLinks = true;
  @Input() footerText = '';
  @Input() hideDescriptionLink = false;
  @Input() marketplaceInfo: MarketplaceInfoModel;

  navigation = [];

  constructor(
    public contactFormService: ContactFormService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.marketplaceInfo = new MarketplaceInfoModel({});
  }

  get isWorkingAdvantage(): boolean {
    return window.location.origin.includes('workingadvantage.com');
  }

  ngOnInit(): void {
    this.getNavigationItems(
      this.authService.getSubdomain(),
      'US'
    )
      .pipe(
        map(res => res.items),
        map(navs => navs.sort((a, b) => b.priority - a.priority)),
        map(navs => navs.filter((nav, i) => i < MAX_NAVS_COUNT))
      )
      .subscribe(navs => {
        this.navigation = navs;
      });
  }

  openContactUsModal(): void {
    this.contactFormService.openContactFormModal();
  }

  onClick(url: string): void {
    if (url === CONTACT_US_URL) {
      this.openContactUsModal();
    } else {
      const newTabUrl = (url.includes('http:') || url.includes('https:')) ? url : `${this.policyPageUrl}${url}`;

      window.open(newTabUrl, '_blank');
    }
  }

  private getNavigationItems(subdomain: string, country: string, navrefresh: string = 'all', authStatus = 'auth'): Observable<any> {
    const params = {};

    if (navrefresh) {
      params['navrefresh'] = navrefresh;
    }

    return this.http.get<any>(`${environment.apiUrl}/api/navigation/${subdomain}/auth_footer/${country}/${authStatus}`, { params });
  }
}
