<section class="zone_header"
         *ngIf="data">
  <h4>
    <img [src]="zoneHeader.icon | imageUrl : { w: 20, h: 20, crop: 'entropy' , fit: 'crop' }"
         alt="title"
         loading="lazy"
         *ngIf="zoneHeader.icon"
         class="zone_header_logo">
    <div class="zone_header_name">{{zoneHeader.name}}</div>
  </h4>
  <a class="zone_header_link btn btn-text btn-underline"
     *ngIf="zoneHeader.url"
     (click)="handleShowAllClick($event)"
     [appAutoTabIndex]="groupTabIndex + 11">
    <span>{{getUrlText()}}</span>
    <svg xmlns="http://www.w3.org/2000/svg"
         width="9"
         height="9"
         viewBox="0 0 9 9">
      <path fill="currentColor"
            fill-rule="nonzero"
            d="M2.147.854l.706-.708L7.223 4.5l-4.37 4.354-.706-.708L5.806 4.5z" />
    </svg>
  </a>
</section>
