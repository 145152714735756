import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.less']
})
export class ShareButtonComponent {

  @Input() tooltipText: string;
  @Input() placement: string;
  @Input() customClass = 'share-tooltip';

  @Output() shareClick = new EventEmitter<boolean>();

  @ViewChild('tooltip', { read: NgbTooltip }) public tooltip: NgbTooltip;

  public copyText(event: { preventDefault: () => void; stopPropagation: () => void }): void {
    event.preventDefault();
    event.stopPropagation();
    this.shareClick.emit(true);
    setTimeout(() => this.tooltip.close(), 2000);
  }
}
