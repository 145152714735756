import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-prompt-phone-step',
  templateUrl: './profile-prompt-phone-step.component.html',
  styleUrls: ['./profile-prompt-phone-step.component.less']
})
export class ProfilePromptPhoneStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
