import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfiniteScrollService {

  private infiniteScrollState = new BehaviorSubject<boolean>(true);

  getInfiniteScrollState(): Observable<boolean> {
    return this.infiniteScrollState.asObservable();
  }

  updateInfiniteScrollState(data: boolean): void {
    this.infiniteScrollState.next(data);
  }
}
