/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { Marker } from '@app/listing/interfaces/marker.interface';
import { OfferModel } from '@shared/models/offer.model';
import { SearchResultsAdsModel } from '@app/shared/models/search-results-ads.model';
import { TodayDeal } from '@app/shared/models/today-deal.model';
import { WINDOW } from 'g3-common-ui';
import { SUCHelper } from '@app/shared/helpers/suc.helper';

const isValidUrl = (url: string): boolean => !!url && url !== 'NOURL';
const isEmptyObject = (obj: any): boolean => !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
const isInputSet = (input: any): boolean => !isEmptyObject(input);

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.less']
})
export class CouponComponent {

  @Input() code: string;
  @Input() buttonText = 'copy';
  @Input() offer: OfferModel;
  @Input() todayDeal: TodayDeal;
  @Input() ad: SearchResultsAdsModel;
  @Input() providerLocation: Marker;
  @Input() groupTabIndex: number;

  @Output() events = new EventEmitter<{ type: string }>();
  @Output() phoneAction = new EventEmitter<string>();
  @Output() phoneClick = new EventEmitter<string>();

  constructor(
    @Inject(WINDOW) private window: WINDOW
  ) { }

  get hasDestinationUrl(): boolean {
    if (isInputSet(this.offer)) {
      return this.offer.hasDestinationUrl() && ((this.offer.urlType === 'static' && isValidUrl(this.offer.url)) || this.offer.urlType === 'dynamic');
    }
    if (isInputSet(this.todayDeal)) {
      return isValidUrl(this.todayDeal.url);
    }
    if (isInputSet(this.ad)) {
      return isValidUrl(this.ad.destinationUrl);
    }
    if (isInputSet(this.providerLocation)) {
      return (this.providerLocation.url_type === 'static' && isValidUrl(this.providerLocation.url) || this.providerLocation.url_type === 'dynamic');
    }
    return false;
  }

  get destinationUrlTarget(): string {
    if (this.todayDeal) {
      return this.todayDeal.new_tab ? '_blank' : '_self';
    }

    return '_blank';
  }

  get isShowOfferCouponIntro(): boolean {
    return (!this.ad && this.offer && !this.offer.instruction_text)
      || (!this.ad && this.offer && this.offer.phone && this.hasDestinationUrl);
  }

  isPhoneLinkInternal(phoneAction: string): boolean {
    return phoneAction.startsWith('/');
  }

  isShownPhoneAction(item: OfferModel | SearchResultsAdsModel): boolean {
    return item.phone_action
      && (item.phone_action.startsWith('/')
        || item.phone_action.startsWith('http://')
        || item.phone_action.startsWith('https://'));
  }

  getPhone(item: OfferModel | SearchResultsAdsModel): string {
    return item.phone_action || item.phone;
  }

  itemHasProperties(itemToCheck: OfferModel | SearchResultsAdsModel, properties: string[]): boolean {
    return itemToCheck && properties.every(prop => itemToCheck[prop]);
  }

  isShowNotification(): boolean {
    return this.buttonText.toLowerCase() === 'copy' && !!this.code && !this.offer.instruction_text;
  }

  isShowCouponIntro(itemToCheck: OfferModel | SearchResultsAdsModel): boolean {
    return this.itemHasProperties(itemToCheck, ['instruction_text', 'phone']) && this.hasDestinationUrl && !!this.code;
  }

  async ctaClick($event: MouseEvent): Promise<void> {
    $event.preventDefault();
    $event.stopPropagation();

    this.events.emit({ type: 'cta-button-click' });
  }

  onCodeCopied(): void {
    this.events.emit({
      type: 'click-copy-code'
    });
  }

  onPhoneAction(url: string): void {
    this.phoneAction.emit(url);
  }

  onPhoneClick(phone: string): void {
    this.phoneClick.emit(phone);
  }

  hasUrlWithSUCToken(): boolean {
    const url = this.offer?.url || this.ad?.destinationUrl;
    const code = this.offer?.code || this.ad?.code;

    if (!url || !code) {
      return false;
    }

    return SUCHelper.isExternalUrlWithSucToken(url, code);
  }

}
