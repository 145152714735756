<div class="modal-header">
  <h4 *ngIf="content && content.title">{{content?.title}}</h4>
  <button type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHtml]="content?.text"></p>
</div>
<div class="modal-footer"
     *ngIf="content.isButton">
  <button type="button"
          class="btn btn-primary"
          (click)="activeModal.close()">{{content.buttonText ? content.buttonText : 'OK'}}</button>
</div>