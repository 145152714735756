<div class="sign-out info-page"
     *ngIf="!loading">
  <app-logo></app-logo>
  <div>
    <h1>{{message}}
      <br /> We hope you come back soon.
    </h1>
    <a class="btn btn-primary btn-l"
       (click)="signIn()">Sign back in</a>
  </div>
</div>
<iframe *ngIf="signOutUrl"
        width="1"
        height="1"
        [src]="signOutUrl">
</iframe>