/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export interface AnalyticsEventModel {
  internalType: string;
  internalData: any;

  gaAction: string;
  gaProperties: any;
}

export interface AnalyticsEvent {
  type: string;
  data: any;
}

export interface AnalyticsGAOffer {
  guid: string;
  brand: string;
  title: string;
}

export interface AnalyticsGAOfferList {
  listGuid: string;
  listName: string;
  listHeader: string;
  clickUrl: string;
}

export interface AnalyticsGANavigation {
  id: string;
  name: string;
  parent?: string;
  url?: string;
  viewAllLink?: boolean;
  click_out?: string;
}

export interface AnalyticsInternalSubscription {
  name: string;
  mkt: string;
  usr: string;
  sid?: string;
  btag?: string;
  ctag?: string;
}

export class AnalyticsInternalEventModel implements AnalyticsEvent {

  constructor(public type: string, public data: any) {
  }
}

export class AnalyticsGAEventModel implements AnalyticsEvent {

  constructor(public type: string, public data: any) {
  }
}

export class AnalyticsAdEventModel implements AnalyticsEvent {

  constructor(public type: string, public data: any) {
  }
}

export class AnalyticsGAOfferModel implements AnalyticsGAOffer {

  constructor(
    public guid: string,
    public brand: string,
    public title: string,
    public offerPhone?: boolean,
    public brandOverride?: string) {
  }

  generateOfferLabel(): string {
    const label = [];

    if (this.guid) {
      label.push(this.guid);
    }
    if (this.brand) {
      if (this.brandOverride) {
        label.push(`${this.brandOverride} - ${this.brand}`);
      } else {
        label.push(this.brand);
      }
    }
    if (this.title) {
      label.push(this.title);
    }
    if (this.offerPhone) {
      label.push('offerPhone');
    }

    return label.join(' | ');
  }

}

export class AnalyticsGANavigationModel implements AnalyticsGANavigation {

  constructor(
    public id: string,
    public name: string,
    public parent?: string,
    public url?: string,
    public viewAllLink?: boolean,
    public click_out?: string
  ) {
  }

  generateNavigationLabel(): string {
    const fields = this.viewAllLink
      ? ['View All', this.name, this.url]
      : [this.id, this.name].concat([this.parent, this.url].filter(val => val));

    if (this.click_out) {
      fields.push(this.click_out);
    }

    return fields.join('|');
  }
}
