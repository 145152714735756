<div class="modal-header">
  <div class="brand-logo"
       [class.no-box-shadow]="!brandLogo"
       [ngStyle]="{'background-image': 'url(' + brandLogo + ')'}"></div>
  <button type="button"
          class="close"
          role="button"
          ngbAutofocus
          [appAutoTabIndex]="groupTabIndex"
          aria-label="Close pop-up"
          (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="notranslate company-name"
       *ngIf="todayDeal">{{ todayDeal.company_name }}</div>
  <app-coupon *ngIf="!errorMessage; else errorMessageContainer"
              [groupTabIndex]="groupTabIndex"
              [code]="code"
              [buttonText]="buttonLabel"
              [todayDeal]="todayDeal"
              [offer]="offer"
              [ad]="ad"
              [providerLocation]="providerLocation"
              (events)="handleEvents($event)"
              (phoneAction)="onPhoneAction($event)"
              (phoneClick)="onPhoneClick($event)"></app-coupon>
  <ng-template #errorMessageContainer>
    <h4 class="suc-error-message">{{errorMessage}}</h4>
  </ng-template>
</div>
