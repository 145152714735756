/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { KnownUserTypes } from 'g3-common-ui';
import { ProviderLocationsResponse } from '@app/shared/interfaces/provider-location-response.interface';
import { ProviderLocationType } from '@app/shared/interfaces/provider-location.type';
import { ProviderLocationUrlResponse } from '@app/shared/interfaces/provider-location-url-response.interface';
import { PermissionService } from '@core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';

const locationTypesResponse = () => ({ count: 0, name: 'network' as ProviderLocationType });
const emptyResponse = (): ProviderLocationsResponse => ({
  data: [],
  meta: {
    categories: [],
    locationTypes: [locationTypesResponse()],
    zips: [],
    marketplaceHasClientLocations: false
  }
});

@Injectable({
  providedIn: 'root'
})
export class ProviderLocationsService {
  private markersCache: ProviderLocationsResponse = emptyResponse();

  private markersSource = new BehaviorSubject<ProviderLocationsResponse>(emptyResponse());
  markers$ = this.markersSource.asObservable();
  category: string;
  locationType: ProviderLocationType;
  offset = 0;
  lastIndex = 0;
  constructor(
    private http: HttpClient,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
  ) { }

  async getLocations({ lat = '', long = '', distance, limit, coordinatesType, zip = '', cat = '', locationType = null }): Promise<ProviderLocationsResponse> {
    const query = this.generateQuery({ lat, long, distance, limit, coordinatesType, zip, cat, locationType });

    const res = await this.http
      .get<any>(`${environment.apiUrl}/api/provider-locations${query}`)
      .pipe(
        map(providerLocationsResponse => {
          providerLocationsResponse.data.forEach((item, index) => {
            this[index] = Object.assign(item, {
              ctaButtonTitle: item.cta_button_title,
              cta_button_title: undefined
            });
          });
          return providerLocationsResponse;
        }))
      .toPromise();

    this.updateCache(res);

    return res;
  }

  async getLastZipLocations(): Promise<string[]> {
    const res = await this.http
      .get<any>(`${environment.apiUrl}/api/provider-locations?onlyMeta=true`)
      .toPromise();
    return res.meta.zips;
  }

  async getLatAndLngFromZip(zip: string): Promise<any> {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${environment.mapApiKey}`).toPromise();
  }

  applyFilters() {
    if (!this.category && !this.locationType) {
      return Object.assign({}, this.markersCache);
    }

    const res = emptyResponse();
    res.data = this.markersCache.data.slice(0);
    res.meta = Object.assign({}, this.markersCache.meta);

    if (this.category) {
      res.data = res.data.filter(marker => marker.category === this.category);
      res.meta.locationTypes = res.data.reduce((total, marker) => {
        const currIndex = total.findIndex(i => i.name === marker.locationType);

        if (currIndex < 0) {
          total.push({ name: marker.locationType, count: 1 });
        } else {
          total[currIndex].count++;
        }

        return total;
      }, []);
    }

    if (this.locationType) {
      res.data = res.data.filter(marker => marker.locationType === this.locationType);
      res.meta.categories = res.data.reduce((total, marker) => {
        const currIndex = total.findIndex(i => i.name === marker.category);

        if (currIndex < 0) {
          total.push({ name: marker.category, count: 1 });
        } else {
          total[currIndex].count++;
        }

        return total;
      }, []);
    }
    return res;
  }

  filterBy(category: string, locationType: ProviderLocationType) {
    this.category = category;
    this.locationType = locationType;
    const res = this.applyFilters();
    res.total = res.data.length;
    res.offset = 0;
    res.lastIndex = this.lastIndex > res.total ? res.total : this.lastIndex;
    if (this.offset || this.lastIndex) {
      res.data = res.data.slice(this.offset, this.lastIndex);
    }
    this.markersSource.next(res);
  }

  paginate(offset = 0, lastIndex = 20) {
    this.offset = offset;
    this.lastIndex = lastIndex;
    const res = this.applyFilters();
    res.total = res.data.length;
    res.offset = this.offset;
    res.lastIndex = this.lastIndex > res.total ? res.total : this.lastIndex;
    res.data = res.data.slice(this.offset, this.lastIndex);
    this.markersSource.next(res);
  }

  private updateCache(res: ProviderLocationsResponse) {
    if (res.meta && res.meta.locationTypes && !res.meta.locationTypes.length) {
      res.meta.locationTypes = [locationTypesResponse()];
    }

    this.markersCache = res;
    this.markersSource.next(res);
  }

  private generateQuery({ lat, long, distance, limit, coordinatesType, zip = '', cat = '', locationType = null }) {
    let query = '?';

    if (lat) {
      query += `&lat=${lat}`;
    }

    if (long) {
      query += `&long=${long}`;
    }

    if (distance) {
      query += `&distance=${distance}`;
    }

    if (coordinatesType) {
      query += `&coordinatesType=${coordinatesType}`;
    }

    if (limit) {
      query += `&limit=${limit}`;
    }

    if (zip) {
      query += `&zip=${zip}`;
    }

    if (cat) {
      query += `&cat=${encodeURIComponent(cat)}`;
    }

    if (locationType) {
      query += `&locationType=${locationType}`;
    }

    return query;
  }

  async getLocationUrl(guid: string): Promise<ProviderLocationUrlResponse> {
    let query = '';

    if (this.permissionService.hasDefined('known:access')) {
      let parentGuid = '';
      query = `?known_user_email=${this.knownUserService.knowUserEmail}`;

      if (this.knownUserService.knowUserType === KnownUserTypes.CHILD) {
        parentGuid = this.knownUserService.knowUserChildDlk.split('~')[0];
      } else {
        parentGuid = this.knownUserService.knowUserGuid;
        query += '&is_parent=true';
      }
      query += `&known_user_guid=${parentGuid}`;
    }

    return this.http
      .get<ProviderLocationUrlResponse>(`${environment.apiUrl}/api/provider-locations/${guid}/url${query}`)
      .toPromise();
  }
}
