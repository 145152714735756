<ng-container *ngIf="topCategories?.length && zoneElement">
  <section class="top-categories scrollable_container container main-container wide-container"
    [attr.id]="anchorLink"
    [class.wide-endpoint]="isWideContainer()"
    (mouseenter)="hovered = true"
    (mouseleave)="hovered = false">
    <app-zone-header
      [data]="zoneElement"
      [groupTabIndex]="groupTabIndex"
      (showAllClick)="showAllClickHandler($event)">
    </app-zone-header>

    <app-homepage-horizontal-scroll [hideGhostEffect]="hideGhostEffect"
      [hideArrows]="hideArrows"
      [isDesktop]="isDesktop"
      [isBrandSection]="true"
      [hovered]="hovered">
      <app-category-item [appAutoTabIndex]="2000"
        class="row-mode"
        *ngFor="let category of topCategories; trackBy: trackByCategoryId; index as i;"
        [category]="category"
        [class.first]="i === 0"
        (click)="onCategoryClick(category, $event)"
        (brokenImageUrl)="handleBrokenImageUrl($event, category.titleShort)">
      </app-category-item>
    </app-homepage-horizontal-scroll>
  </section>
</ng-container>
