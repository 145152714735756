import { Injectable } from '@angular/core';

export interface PointLatLng {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root'
})
export class MapsGeometryService {

  private EARTH_RADIUS = 6371; // in km

  constructor() { }

  distanceBetweenEarthCoordinates(firstPoint: PointLatLng, secondPoint: PointLatLng): number {
    const dLat = this.degreesToRadians(secondPoint.lat - firstPoint.lat);
    const dLng = this.degreesToRadians(secondPoint.lng - firstPoint.lng);
    const dLat1 = this.degreesToRadians(firstPoint.lat);
    const dLat2 = this.degreesToRadians(secondPoint.lat);
    const a = (Math.sin(dLat / 2) ** 2) + Math.cos(dLat1) * Math.cos(dLat2) * Math.sin(dLng / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return this.EARTH_RADIUS * c * 1000; // return value in meters
  }

  private degreesToRadians(degrees: number): number {
    return degrees * Math.PI / 180;
  }
}
