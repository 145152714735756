import {
  AnalyticsGAEventModel,
  AnalyticsGANavigation,
  AnalyticsGANavigationModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavDynamicItem, NavDynamicResponse } from '@core/layouts/logged-in/interfaces/nav-dynamic-section.interface';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UrlService } from '@shared/services/url.service';
import { WINDOW, PlatformService, QualtricsService } from 'g3-common-ui';
import { PermissionService } from '@app/core/auth/services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private urlService: UrlService,
    private router: Router,
    private permissionService: PermissionService,
    private platformService: PlatformService,
    private qualtricsService: QualtricsService,
    @Inject(WINDOW) private window: WINDOW
  ) { }

  getNavigationItems(subdomain: string, location: string, country: string, refreshCache: boolean = false, authStatus = 'auth', refreshQuery?: string): Observable<NavDynamicResponse> {
    const params = {};
    params['siteTag'] = this.platformService.currentPlatform.SITE_TAG;

    if (refreshCache && refreshQuery) {
      params['navrefresh'] = refreshQuery;
    }

    if (this.permissionService.hasDefined('known:access')) {
      authStatus = 'known';
    }

    if (this.permissionService.hasDefined('guest:access')) {
      authStatus = 'guest';
    }

    return this.http.get<NavDynamicResponse>(`${environment.apiUrl}/api/navigation/${subdomain}/${location}/${country}/${authStatus}`, { params });
  }

  setChildren(children: NavDynamicItem[], parents: NavDynamicItem[]): void {
    children.forEach(item => {
      if (!item.url) {
        return;
      }
      const parent = parents.find(i => i.id === item.parent);
      if (parent) {
        (parent['children'] = parent['children'] || []).push(item);
      }
    });
  }

  handleClick(url: string, isInternal = false): void {
    const isExternalUrl = this.isExternalUrl(url);
    if (isExternalUrl) {
      if (this.urlService.isCorporateSite(url)) {
        const npsFlag = this.qualtricsService.getNpsFlag();
        url = `${url}&G3NPS=${npsFlag}`;
      }
      if (isInternal) {
        this.window.location.href = url;
      } else {
        window.open(url, '_blank');
      }
    } else {
      void this.router.navigateByUrl(url);
    }
  }

  trackAnalyticsEvents(action: string, data: AnalyticsGANavigation, viewAllLink = false): void {
    try {
      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel(action, {
          data
        }),
        new AnalyticsGAEventModel(action, {
          category: action,
          label: new AnalyticsGANavigationModel(data.id, data.name, data.parent, data.url, viewAllLink).generateNavigationLabel()
        })
      ]);
    } catch {
      console.error('error posting analytics event');
    }
  }

  trackGAAnalytic(action: string, data: AnalyticsGANavigation, viewAllLink = false): void {
    try {
      void this.analyticsService.eventTrack(
        new AnalyticsGAEventModel(action, {
          category: action,
          label: new AnalyticsGANavigationModel(data.id, data.name, data.parent, data.url, viewAllLink).generateNavigationLabel()
        })
      );
    } catch {
      console.error('error posting analytics event');
    }
  }

  trackInternalAnalytic(action: string, data: AnalyticsGANavigation, viewAllLink = false): void {
    try {
      void this.analyticsService.eventTrack(
        new AnalyticsInternalEventModel(action, {
          data
        })
      );
    } catch {
      console.error('error posting analytics event');
    }
  }

  isExternalUrl(url: string): boolean {
    return this.urlService.isExternalUrl(url);
  }
}
