import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { MAX_ZIP_CODE_LENGTH } from '@app/shared/constants/form-submit-keys.constants';
import { ZipValidator } from '@app/shared/validators/zip.validator';
import { UserModel } from '@app/core/auth/services/user.model';
import { AccountConfirmationService } from '@app/shared/services/account-confirmation.service';
import { ConfigService } from '@app/shared/services/config.service';

const ZIP_CONTROL_KEY = 'zip';

@Component({
  selector: 'app-profile-prompt-zip-step',
  templateUrl: './profile-prompt-zip-step.component.html',
  styleUrls: ['./profile-prompt-zip-step.component.less']
})
export class ProfilePromptZipStepComponent implements OnInit {

  public marketplaceName = '';

  public zipForm = new UntypedFormGroup({
    [ZIP_CONTROL_KEY]: new UntypedFormControl('', [Validators.required, Validators.minLength(3), ZipValidator]),
  });

  public get zip(): AbstractControl {
    return this.zipForm.get(ZIP_CONTROL_KEY);
  }

  public get maxZipCodeLength(): number {
    return MAX_ZIP_CODE_LENGTH;
  }

  public constructor(
    private accountConfirmationService: AccountConfirmationService,
    private configService: ConfigService,
    private profileService: ProfileService
  ) {}

  public ngOnInit(): void {
    this.marketplaceName = this.configService.getConfig()?.name;
  }

  public async onSubmit(event: Event): Promise<void> {
    event.stopPropagation();

    if (this.zipForm.valid) {
      const data = await this.profileService.updateProfile({
        zip_code: this.zipForm.get(ZIP_CONTROL_KEY).value
      });

      this.profileService.setData(new UserModel(data));
      this.accountConfirmationService.openProfilePromptZipFinalModal();
    }
  }
}
