import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { ContactFormService } from '@app/shared/services/contact-form.service';


@Component({
  selector: 'app-unsubscribe-success-page',
  templateUrl: './unsubscribe-success-page.component.html',
  styleUrls: ['./unsubscribe-success-page.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubscribeSuccessPageComponent implements AfterViewInit {

  @Input() successPrimaryHeader: string;
  @Input() successSecondaryHeader: string;

  constructor(
    private contactFormService: ContactFormService
  ) { }

  ngAfterViewInit(): void {
    this.addClickEventOnCustomerServiceAction();
  }

  private addClickEventOnCustomerServiceAction(): void {
    const customerServiceActionElement = document.querySelector<HTMLElement>('.customer-service');
    if (customerServiceActionElement) {
      const onCustomerServiceAction = (): void => this.contactFormService.openContactFormModal({ reason: 'Other' });
      customerServiceActionElement.addEventListener('click', onCustomerServiceAction);
    }
  }

}
