type ValueOf<T> = T[keyof T];

export const AdobeEventNameEnum = {
  CARD: 'card',
  EMAIL_MODAL: 'email modal',
  FLY_IN: 'fly in',
  SEARCH_LISTING: 'search listing',
  OFFER_PAGE: 'offer_page',
  WIDGET: 'widget',
  NAV: 'nav',
  NOTI: 'noti',
} as const;
export type AdobeEventName = ValueOf<typeof AdobeEventNameEnum>;
export const AdobeEventTypeEnum = {
  CLICK: 'click',
  INTERNAL_CLICKOUT: 'internal_clickout',
  EXTERNAL_CLICKOUT: 'external_clickout',
  REVEAL_CODE: 'reveal_code',
  COPY_CODE: 'copy_code',
  FAVORITE: 'favorite',
  UNFAVORITE: 'unfavorite',
  INFORMATION: 'information',
  IMPRESSION: 'impression'
};
export type AdobeEventType = ValueOf<typeof AdobeEventTypeEnum>;
export const AdobeOfferWidgetEventTypeEnum = {
  CLICK: 'get quote',
  OPEN: 'open',
  CLOSE: 'close',
} as const;
export type AdobeOfferWidgetEventName = ValueOf<typeof AdobeOfferWidgetEventTypeEnum>;
export const AdobeCardSizeEnum = {
  DOUBLE: 'double',
  STANDARD: 'standard',
  HYBRID: 'hybrid',
};
export type AdobeCardSize = ValueOf<typeof AdobeCardSizeEnum>;
export type AdobeWidgetSize = 'mini' | 'large' | 'search';

export interface AdobeClickEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: AdobeOfferData;
}

export interface AdobeTddClickEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: AdobeTodayDealData;
}

export interface AdobeWidgetClickEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: AdobeWidgetData;
}

export interface AdobeNotiClickEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: {
    offer: AdobeNotiDataItem;
  };
}

export interface AdobeOfferWidgetEvent {
  event_name: AdobeEventName;
  event_type: AdobeOfferWidgetEventName;
  properties: AdobeOfferData;
}

export interface AdobeImpresssionsEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: {
    impressions?: AdobeOfferData[];
    offer?: AdobeOfferData;
  };
}

export interface AdobeTddImpresssionsEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: {
    impressions?: AdobeTodayDealData[];
    offer?: AdobeTodayDealData;
  };
}

export interface AdobeWidgetImpresssionsEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: {
    impressions: AdobeWidgetData[];
  };
}

export interface AdobeNotiImpressionsEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: {
    ad_info: AdobeNotiDataItem;
  };
}

export interface AdobeOfferData {
  [key: string]: {
    guid: string;
    type: string;
    name: string;
    size?: AdobeCardSize;
    shopping_category: string;
    product_main_category: string;
    display_brand: string;
    parent_brand: string;
    internal_brand: string;
    zone?: string;
    isTdd?: boolean;
    isEbg?: boolean;
  };
}

export interface AdobeWidgetDataItem {
  guid: string;
  name: string;
  display_brand: string;
  size: AdobeWidgetSize;
  shopping_category: string;
  zone: string;
}

export interface AdobeNotiDataItem {
  guid: string;
  name: string;
  display_brand: string;
  size: string;
  zone: string;
}

export interface AdobeTodayDealDataItem {
  guid: string;
  type: string;
  name: string;
  size?: AdobeCardSize;
  shopping_category: string;
  zone?: string;
  display_brand: string;
  isTdd?: boolean;
  isEbg?: boolean;
}

export interface AdobeTodayDealData {
  [key: string]: AdobeTodayDealDataItem;
}

export interface AdobeWidgetData {
  [key: string]: AdobeWidgetDataItem;
}

export enum AdobeEventLocation {
  Homepage,
}

export interface AdobeImpressionEvent {
  event_name: AdobeEventName;
  event_type: AdobeEventType;
  properties: {
    impressions: {
      [key: string]: unknown;
    }[];
  };
}
