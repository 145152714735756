/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import { Marker } from '@app/shared/interfaces/marker.interface';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';

export interface CtaClickData {
  guid: string;
  url: string;
  url_type: string;
}

@Component({
  selector: 'app-provider-locations-panel',
  templateUrl: './provider-locations-panel.component.html',
  styleUrls: ['./provider-locations-panel.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION],
  encapsulation: ViewEncapsulation.None,
})
export class ProviderLocationsPanelComponent implements OnInit, OnChanges {

  @Input() data: Marker = {} as Marker;
  @Input() locationDisclaimer: string = null;
  @Input() groupTabIndex: number;

  @Output() panelClose = new EventEmitter<boolean>();

  @ViewChild('ctaBtn') ctaBtn: ElementRef;

  public markerData: Marker;

  constructor(
    private directClickOutService: DirectClickOutService
  ) { }

  public ngOnInit(): void {
    this.markerData = this.data;
  }

  public ngOnChanges(changes: { data: any }): void {
    const { data } = changes;

    if (!data.firstChange && data.previousValue.guid !== data.currentValue.guid) {
      this.data = data.currentValue;
      this.markerData = null;
    }

    setTimeout(() => {
      this.markerData = data.currentValue;
    }, 600);

  }

  public closePanel(): void {
    this.panelClose.emit();
  }

  public getOffer(): void {
    this.directClickOutService.providerLocations.handleCtaClick(this.data);
    this.ctaBtn.nativeElement.blur();
  }

  public getType(markerData: { code: any; url: any; url_type: string }): string | boolean {
    if (markerData.code) {
      return 'code';
    } else if (markerData.url || markerData.url_type === 'dynamic') {
      return 'external';
    }
    return false;
  }

  public getButtonTitle(): string {
    return this.markerData.ctaButtonTitle || 'GET OFFER';
  }

  public hasUrl(): boolean {
    return (!!this.data.url || this.data.url_type === 'dynamic');
  }

  get cityStateString(): string {
    return [this.markerData.city, this.markerData.state].filter(x => x).join(', ');
  }

}
