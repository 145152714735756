<ng-container>
  <p class="paylogix-profile__title">{{ headerText }}</p>
  <p class="paylogix-profile__sub-title">{{ subheaderText }}</p>
  <form [formGroup]="paylogixProfileForm"
        #form="ngForm"
        (ngSubmit)="onConfirmClick($event)">

    <div *ngFor="let control of controls; let last = last;"
         class="paylogix-profile__field"
         [class.last]="last">
      <label class="paylogix-profile__field__input-lable"
             for="control.key">
        {{ control.label }}
      </label>
      <input class='paylogix-profile__field__input'
             [name]="control.key"
             [type]="control.type"
             [placeholder]="control.placeholder"
             [formControlName]="control.key"
             [maxlength]="control.maxlength">

      <ng-container *ngFor="let error of control.errors">
        <div *ngIf="paylogixProfileForm.controls[control.key].errors && paylogixProfileForm.controls[control.key].errors[error.type] && form.submitted"
             class="paylogix-profile__field__error">
          {{ error.text }}
        </div>
      </ng-container>

    </div>

    <button *ngIf="!isLoading"
            class="btn btn-primary paylogix-profile__submit-button"
            type="submit">
      <span>Submit</span>
      <div class="paylogix-profile__submit-button__image"></div>
    </button>
    <button *ngIf="isLoading"
            class="btn btn-spinner common-primary-button disabled"
            type="submit">
      <app-spinner [size]="20"></app-spinner>
    </button>
  </form>
</ng-container>
