import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less']
})
export class ErrorPageComponent {

  @Input() text: string;
  @Input() moreInfoUrl: string;
  @Input() loading = false;

}
