import { AdzerkBrandItemComponent } from './components/brand-item/adzerk-brand-item.component';
import { CommonModule } from '@angular/common';
import { FeaturedBrandsListComponent } from './components/brands-list/featured-brands-list.component';
import { FormsModule } from '@angular/forms';
import { G3CommonUiModule } from 'g3-common-ui';
import { HomeComponent } from './components/home/home.component';
import { HomepageTakeoverComponent } from './components/homepage-takeover/homepage-takeover.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { OpenEnrollmentComponent } from './components/open-enrollment/open-enrollment.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { TodayDealsService } from '@app/shared/services/today-deals.services';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { ZonesModule } from '@app//zones/zones.module';
import { HomepageDisclaimerComponent } from './components/homepage-disclaimer/homepage-disclaimer.component';
import { OfferBadgeService } from '@shared/services/offer-badge.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    G3CommonUiModule,
    WidgetsModule,
    NgbModule,
    ZonesModule
  ],
  declarations: [
    HomeComponent,
    AdzerkBrandItemComponent,
    FeaturedBrandsListComponent,
    OpenEnrollmentComponent,
    HomepageTakeoverComponent,
    HomepageDisclaimerComponent
  ],
  exports: [],
  providers: [
    TodayDealsService,
    OfferBadgeService
  ]
})
export class HomepageModule { }
