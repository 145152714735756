/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Inject, Injectable } from '@angular/core';
import { KeyValue } from '@angular/common';
import get from 'lodash/get';
import { WINDOW, QualtricsService } from 'g3-common-ui';

import { AnalyticsEvent } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { OffersService } from '@shared/services/offers.service';
import { ProviderLocationsService } from '@shared/services/provider-locations.service';
import { UrlService } from './url.service';
import { appendUrlParams } from '@shared/helpers/query-string';
import { OfferExternalData, OfferExternalSources, OfferModel } from '../models/offer.model';
import { SplitTestEventsService } from './split-tests/split-test-events.service';
import { SingleUseCodeService } from '../components/coupon-modal/single-use-code.service';
import { TokensService } from './tokens.service';
import { LocalStorageService } from './local-storage.service';


interface ClickOutAdditionalData {
  logo: string;
  brand: string;
  guid?: string;
  urlParams?: string;
  externalData?: OfferExternalData;
}

interface ClickOutOptions {
  [key: string]: any;
  analyticEvents?: AnalyticsEvent[];
  data?: ClickOutAdditionalData;
}

const ADMIN_EDIT_MODE = 'admin_edit_mode';

@Injectable({
  providedIn: 'root'
})
export class ClickOutService {

  constructor(
    @Inject(WINDOW) private window: WINDOW,
    private offersService: OffersService,
    private providerLocationsService: ProviderLocationsService,
    private analyticsService: AnalyticsService,
    private urlService: UrlService,
    private qualtricsService: QualtricsService,
    private splitTestEventsService: SplitTestEventsService,
    private singleUseCodeService: SingleUseCodeService,
    private tokensService: TokensService,
    private localStorageService: LocalStorageService
  ) { }

  public async clickOut(url: string, type = '', options: ClickOutOptions = {}, isNewTab: boolean = true, sourceId?: string, sourceBasedParams?: KeyValue<string, string>[]): Promise<any> {
    // important! we must open a blank new tab to defeat popup blockers. then we can load dynamic url.
    // do not remove this functionality! it's important!
    let newTab = this.window;
    if (isNewTab) {
      newTab = this.window.open('about:blank');
    }

    let offerData: OfferModel = null;
    try {
      const guid = get(options, 'guid') || get(options, 'data.guid');
      if (guid) {
        const offerResponse = await this.offersService.getOffer(guid, 0);
        offerData = new OfferModel(offerResponse);
      }
    } catch (e) {
      // ignore errors from get offer
    }

    // important! we must open a blank new tab to defeat popup blockers. then we can load dynamic url.
    // do not remove this functionality! it's important!
    let windowUrl = url;
    let isPaylogixRedirect = false;
    let paylogixDeeplink;
    const paylogixHomepageRedirect = get(options, 'isPaylogixHomepageRedirect');

    switch (type) {
      case 'offer_dynamic':
        if (options['guid']) {
          const data = await this.offersService.getOfferUrl(options['guid'], options['withParams'] || {});
          windowUrl = data.url;
          isPaylogixRedirect = data.paylogix;
          paylogixDeeplink = offerData?.external_data && !paylogixHomepageRedirect ? offerData?.external_data?.deeplink : undefined;

          if (options.analyticEvents) {
            options.analyticEvents.forEach((event) => {
              // for internal analytics for all *-click-out events we need to save this url
              if (event.type.includes('click-out') && event.data.url === null) {
                event.data.url = windowUrl;
              }
            });
          }
        }
        break;

      case 'provider_location_dynamic':
        if (options['guid']) {
          const data = await this.providerLocationsService.getLocationUrl(options['guid']);
          windowUrl = data.url;
          if (options.analyticEvents) {
            options.analyticEvents.forEach((event) => {
              // for internal analytics for all *-click-out events we need to save this url
              if (event.type.includes('click-out') && event.data.url === null) {
                event.data.url = windowUrl;
              }
            });
          }
        }
        break;

      default:
        if (!offerData) {
          break;
        }

        // check to see if the passed in offer is a paylogix offer
        isPaylogixRedirect = offerData.external_source === OfferExternalSources.Paylogix;
        paylogixDeeplink = offerData.external_data && !paylogixHomepageRedirect ? offerData.external_data?.deeplink : undefined;
        break;
    }

    if (Array.isArray(options.analyticEvents) && options.analyticEvents.length > 0) {
      this.analyticsService.eventsTrack(options.analyticEvents);
    }

    if (windowUrl) {
      const sourceParam = sourceBasedParams && sourceBasedParams.find(el => el.key === sourceId && el.value);
      const sourceDefaultParam = sourceBasedParams && sourceBasedParams.find(el => el.key === 'default');
      const sourceParamValue = sourceParam && sourceParam.value
        ? sourceParam.value
        : sourceDefaultParam && sourceDefaultParam.value || '';

      let urlParams = this.getUrlParamsFromOptions(options);

      if (urlParams) {
        urlParams = `${urlParams}${sourceParamValue && `&${sourceParamValue}` || ''}`;
      } else {
        if (sourceParamValue) {
          urlParams = sourceParamValue;
        }
      }

      if (this.urlService.isCorporateSite(windowUrl)) {
        const npsFlag = this.qualtricsService.getNpsFlag().toLowerCase();
        urlParams = `${urlParams}&G3NPS=${npsFlag}`;
      }
      if (offerData && this.singleUseCodeService.isExternalUrlWithSucToken(windowUrl, offerData?.code)) {
        windowUrl = await this.replaceSucToken(offerData.guid, windowUrl);
        options.data = Object.assign({}, options.data, {
          logo: offerData.vendorBrand?.logoSquare,
          brand: offerData.vendorBrand?.title,
          guid: offerData.guid,
        });
      }

      windowUrl = appendUrlParams(windowUrl, urlParams);

      if (!this.isAnyAdminModeEnabled()) {
        this.splitTestEventsService.emitClickoutEventByUrl(windowUrl);
      }

      if (!isNewTab) {
        this.window.location.href = windowUrl;
      } else {
        const isInterstitialRedirect = (this.urlService.isExternalUrl(windowUrl) && options && options.data && options.data.brand) || isPaylogixRedirect;

        if (isInterstitialRedirect) {
          paylogixDeeplink = offerData?.external_data?.deeplink;
          this.proceedInterstitialClickOut(windowUrl, options, newTab, isPaylogixRedirect, paylogixDeeplink);
        } else {
          newTab.location.href = windowUrl;
        }
      }
    }

    if (this.window.sessionStorage.getItem('clickout_test')) {
      console.info('CommonClickOut Confirmed', windowUrl);
    }

    return windowUrl;
  }

  public handleHomepageTakeoverCtaClickOut(): void {
    const newTab = this.window.open('about:blank');
    const isPaylogixRedirect = true;
    return this.proceedInterstitialClickOut('', null, newTab, isPaylogixRedirect);
  }

  public isInternalClickOut(destinationUrl: string): boolean {
    return this.tokensService.isTokenPresent(destinationUrl, 'ebghost');
  }

  private proceedInterstitialClickOut(windowUrl: string, options?: ClickOutOptions, newTab: WINDOW = this.window, isPaylogixRedirect?: boolean, paylogixDeeplink?: string): void {
    const redirectId = btoa(Math.random().toString()).substring(0, 12);
    const redirectObject = {
      redirectUrl: windowUrl ? encodeURIComponent(windowUrl) : null,
      logo: get(options, 'data.logo', ''),
      brand: get(options, 'data.brand', ''),
      guid: get(options, 'data.guid')
    };
    if (isPaylogixRedirect) {
      redirectObject['paylogix'] = true;
      redirectObject['carrier'] = get(options, 'data.externalData.carrier', '');
      redirectObject['name'] = get(options, 'data.externalData.name', '');
      if (paylogixDeeplink) {
        redirectObject['paylogixDeeplink'] = paylogixDeeplink;
      }
    }

    const currentDate = new Date();
    const twoHours = new Date(currentDate.getTime() + 7200000).toUTCString();
    this.localStorageService.setItem(`redirectId_${redirectId}`, { redirectOptions: redirectObject, expire: twoHours });
    newTab.location.href = `${this.urlService.getHostURL(this.window.location.href)}/redirect?redirectId=${redirectId}`;
  }

  private getUrlParamsFromOptions(options: ClickOutOptions = {}): string {
    return options?.data?.urlParams || '';
  }

  private isAnyAdminModeEnabled(): boolean {
    return !!this.window.sessionStorage.getItem(ADMIN_EDIT_MODE);
  }

  private async replaceSucToken(guid: string, url: string): Promise<string> {
    try {
      const result = await this.singleUseCodeService.getCode(guid).toPromise();
      if (!result || !result.code) {
        return url;
      }

      return this.singleUseCodeService.replaceSucToken(url, result.code);
    } catch (err) {
      return url;
    }
  }
}
