
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface SelectedOffer {
  guid: string;
  pinned: boolean;
  position: number;
}

@Injectable()
export class SelectOffersService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getTakeoverOffers(): Observable<SelectedOffer[]> {
    return this.http.get<SelectedOffer[]>('/api/marketplace-offers/takeover')
      .pipe(
        shareReplay()
      );
  }

}
