import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EmployerPortalAccessResponse } from '../interfaces/employer-portal-access.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployerPortalService {

  constructor(
    private readonly http: HttpClient
  ) { }

  async checkEmployerPortalAccess(): Promise<EmployerPortalAccessResponse> {
    return this.http
      .get<EmployerPortalAccessResponse>(`${environment.apiUrl}/api/ep/access`)
      .toPromise();
  }
}
