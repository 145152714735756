<form [formGroup]="form">
  <div *ngIf="isErrorShown()"
       class="message"
       [class.error]="isErrorType()"
       [class.warning]="isWarningType()">
    <div class="message-block">
      <svg *ngIf="isErrorType()"
           width="16"
           height="16"
           viewBox="0 0 16 16"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172
          0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9 4V9H7V4H9ZM9 12V10H7V12H9Z"
              fill="white" />
      </svg>
      <svg *ngIf="isWarningType()"
           width="14"
           height="14"
           viewBox="0 0 14 14"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.90712 14H13.0376C13.8078 14 14.1929 13.1016 13.9041 12.4029L7.83885
          0.524064C7.45375 -0.174688 6.49101 -0.174688 6.20219 0.524064L0.136928
          12.4029C-0.248167 13.1016 0.233202 14 0.90712 14ZM7.93329
          9.00867H6.00781V4.01758H7.93329V9.00867ZM6.00781 12.0042H7.93329V10.0078H6.00781V12.0042Z"
              fill="white" />
      </svg>

    </div>
    <p class="message-text">{{ error.messageContent }}</p>
  </div>

  <div class="contact">
    <div class="contact-head">
      <span class="contact-head-label form-label">{{ type === 'phone' ? 'Mobile Phone': type }}</span>
      <button class="btn btn-text contact-head-action"
              [class.hidden-btn]="isEditModeOn"
              (click)="toogleEditMode(true)">
        <span class="link">change</span>
      </button>
    </div>
    <input *ngIf="type === 'email'"
           class="form contact-form"
           type="text"
           placeholder="Enter {{ type }}"
           name="contact"
           formControlName="contact">
    <div *ngIf="type === 'phone'"
         class="phone-container">
      <div class="phone-code__wrapper">
        <span class="phone-code__prefix" [class.disabled-btn]="!isEditModeOn">+</span>
        <input class="form phone-code"
               type="text"
               placeholder="0"
               name="phone_code"
               formControlName="phone_code">
      </div>
      <input class="form"
             type="text"
             placeholder="00000000"
             name="phone_number"
             formControlName="phone_number">
    </div>
  </div>

  <div class="password">
    <div class="password-label form-label">One Time Passcode</div>
    <input class="form password-form"
           type="text"
           placeholder="Enter one time passcode"
           name="password"
           formControlName="password"
           maxlength="6"
           (keyup.enter)="checkUserCodeOrAuthenticate()">

    <ng-container *ngIf="passcodeErrors && formSubmitted">
      <div *ngFor="let error of passcodeErrors"
           class="form-error">
        {{ error }}
      </div>
    </ng-container>
  </div>

  <p class="send-action">
    <span class="send-action__lable">
      {{ canOtpCodeBeSent ? "Didn't Receive Passcode?" : 'You can request again in:' }}
      <span *ngIf="!canOtpCodeBeSent"
            class="send-action__timer">
        {{ timer * 1000 | date: 'mm:ss' }}
      </span>
    </span>
    <a *ngIf="canOtpCodeBeSent"
       class="send-action__button"
       (click)="this.sendCode(otpReasonType, true)">RESEND</a>
  </p>

  <button class="btn btn-primary submit-password-btn"
          [disabled]="disableIfEmpty && !form?.controls?.password?.value"
          (click)="checkUserCodeOrAuthenticate()">
    {{ confirmLabel }}
  </button>
</form>
