/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { Subscription } from 'rxjs';
import { ModalService } from '@app/shared/services/modal.service';
import { getAllFormErrorsObject } from '@app/shared/helpers/forms';
import { errorMessages, surveyContactFields } from './nps-survey-modal.data';
import { ControlInputType } from '@app/shared/enums/control-input-type.enum';
import { ContactUsFormData } from '@app/policies/policies-forms/contact-us.interface';
import { ContactUsService, PHONE_REGEX_PATTERN } from '@app/policies/services/contact-us.service';

enum NpsModalState {
  'started',
  'follow_up',
  'complete'
}

@Component({
  selector: 'app-nps-survey-modal',
  templateUrl: './nps-survey-modal.component.html',
  styleUrls: ['./nps-survey-modal.component.less'],
  providers: [ContactUsService]
})

export class NpsSurveyModalComponent implements OnInit, OnDestroy {

  public ControlInputType = ControlInputType;

  public score: number;
  public marks = [];
  public closeResult: string;
  public marksForm: UntypedFormGroup;
  public followUpForm: UntypedFormGroup;
  public modalState: NpsModalState = NpsModalState.started;
  public isError = false;
  public question: string;
  public name: string;
  public matchMedia: MediaQueryList;
  public npsModalState = NpsModalState;

  public surveyContactFields = surveyContactFields;
  public surveyErrors = {};
  public surveyValues = {};
  public selectedMark: number;

  private representativeContactEnabled = false;
  private subscriptions: Subscription[] = [];
  private FOLLOW_UP_VALUES = [7, 8];

  constructor(
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private contactUsService: ContactUsService,
    private profileService: ProfileService,
  ) { }

  public ngOnInit(): void {
    this.initData();
  }

  public submitForm(): void {
    const scoreValue = this.marksForm.get('score').value;
    const contactAllowValue = this.followUpForm.get('contact_allow').value;
    this.isError = true;

    if (scoreValue !== null && scoreValue !== undefined) {
      this.modalState = this.FOLLOW_UP_VALUES.includes(scoreValue) ? NpsModalState.follow_up : NpsModalState.complete;
      this.updateSurvey(this.name, scoreValue, contactAllowValue);
    }
  }

  public submitFollowUpForm(): void {
    if (this.followUpForm.valid) {
      this.modalState = NpsModalState.complete;
      void this.updateFeedback()
        .then(_ => {
          const isContactAllowed = this.followUpForm.get('contact_allow').value;

          if (isContactAllowed) {
            this.updateContactData();
          }
          this.surveyErrors = {};
        })
        .catch(_ => {
          this.surveyErrors = {};
        });
      return;
    }

    this.surveyErrors = getAllFormErrorsObject(this.followUpForm, errorMessages);
  }

  public closeModal(): void {
    this.modalService.closeModal('dismiss');
  }

  public dismissSurvey(): void {
    this.updateSurvey(this.name, null, false);
  }

  public setSelectedMark(score: number): void {
    this.selectedMark = score;
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  private updateSurvey(name: string, score: number, contactAllow: boolean): void {
    void this.profileService.updateSurvey(name, { score, contact_allow: contactAllow });
  }

  private async updateFeedback(): Promise<any> {
    const contactAllow = this.followUpForm.get('contact_allow').value;
    const feedback = this.followUpForm.get('feedback').value;
    const score = this.marksForm.get('score').value;
    return this.profileService.updateSurvey(this.name, { score, feedback, contact_allow: contactAllow });
  }

  private initData(): void {
    this.matchMedia = window.matchMedia('(min-width: 768px)');
    this.subscriptions.push(
      this.profileService.profileData$.subscribe(profileData => {
        this.question = profileData.surveys[0].question || '';
        this.name = profileData.surveys[0].name || '';
      }));

    for (let score = 0; score <= 10; score++) {
      this.marks.push(score);
    }

    this.marksForm = this.formBuilder.group({
      score: [null, Validators.required]
    });

    this.followUpForm = this.formBuilder.group({
      feedback: ['', Validators.required],
      contact_allow: [this.representativeContactEnabled]
    });

    this.toggleSurveyContactFields(this.representativeContactEnabled);
  }

  private toggleSurveyContactFields(representativeContactEnabled: boolean): void {
    if (representativeContactEnabled) {
      surveyContactFields.forEach(field => {
        const surveyControlValue = this.surveyValues && this.surveyValues[field.prop]
          ? this.surveyValues[field.prop]
          : '';
        const surveyValidators = [];

        if (field.required) {
          surveyValidators.push(Validators.required);
        }

        if (field.type === ControlInputType.Email) {
          surveyValidators.push(Validators.email);
        }

        if (field.type === ControlInputType.Phone) {
          surveyValidators.push(Validators.pattern(PHONE_REGEX_PATTERN));
        }

        const surveyControl = new UntypedFormControl(surveyControlValue, surveyValidators);
        this.followUpForm.addControl(field.prop, surveyControl);
      });
      return;
    }

    surveyContactFields.forEach(field => {
      this.surveyValues[field.prop] = this.followUpForm.get(field.prop) ? this.followUpForm.get(field.prop).value : '';
      this.followUpForm.removeControl(field.prop);
    });
  }

  private updateContactData(): void {
    const { contact_allow, feedback, ...formValues } = this.followUpForm.value;
    const contactUsData: ContactUsFormData = {
      ...formValues,
      description: feedback,
      order_id: 'N/A',
      reason: 'NPS Feedback'
    };

    this.contactUsService.onContactUsFormSubmit(contactUsData);
  }
}
