import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';

@Injectable()
export class AdobeBrandspageDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService,
  ) { }

  async setBrandsPageData(): Promise<void> {
    await this.adobeDataCollectorService.setPageData({
      name: 'offer category: all brands',
      section: 'offer category',
      type: 'g3 brands',
      hierarchy: this.adobeDataCollectorService.setHierarchy('Brands Page'),
    });
  }
}
