<ng-container>
  <div class="{{ widgetClass }}">
    <form class="widget-form"
          ngNoForm
          novalidate
          id="searchForm"
          method="POST"
          #rentalCarForm
          [action]="url">
      <div class="{{ widgetClass }}-autocomplete">
        <app-autocomplete-dropdown (click)="handlePickUpAutocomplete()"
                                   [closeAutocompleteDropdown]="closePickUpDropdown"
                                   [sections]="autocompleteSections"
                                   (autocompleteValue)="getAutocompleteValue($event)"
                                   (selectedValue)="getPickUpValue($event)"
                                   [autocompleteLabel]="'Pick-up location'"
                                   [autocompleteDropdownSettings]="autocompleteDropdownSettings">
        </app-autocomplete-dropdown>
      </div>

      <label class="{{ widgetClass }}-checkbox">
        <input class="{{ widgetClass }}-checkbox__input"
               [class.checked]="destination_not_origin"
               type="checkbox"
               name="destination_not_origin"
               (ngModelChange)="dropOffChange($event)"
               [(ngModel)]="isDroppedOff" />
        Drop-off at a different location
      </label>

      <div [@opacityFadeInOut]
           *ngIf="destination_not_origin"
           class="{{ widgetClass }}-autocomplete drop-off">
        <app-autocomplete-dropdown (click)="handleDropOffAutocomplete()"
                                   [closeAutocompleteDropdown]="closeDropOffDropdown"
                                   [sections]="autocompleteSections"
                                   (autocompleteValue)="getAutocompleteValue($event)"
                                   (selectedValue)="getDropOffValue($event)"
                                   [autocompleteLabel]="'Drop-off location'"
                                   [autocompleteDropdownSettings]="autocompleteDropdownSettingsDropOff">
        </app-autocomplete-dropdown>
      </div>

      <div class="{{ widgetClass }}-pick-up">
        <div class="{{ widgetClass }}-pick-up__date">
          <label class="widget-label {{ widgetClass }}-pick-up__date--label">
            Pick-up date
          </label>
          <input class="widget-input {{ widgetClass }}-pick-up__date--input  widget-date-input ngb-datepicker"
                 [appAutoTabIndex]="groupTabIndex"
                 placeholder="Pick-up date"
                 aria-label="Pick-up"
                 autocomplete="off"
                 [(ngModel)]="pickUp"
                 [minDate]="minDate"
                 (dateSelect)="onDateSelection($event, 'pickUp')"
                 required="required"
                 ngbDatepicker
                 (click)="datepickerParser.toggleDatepicker($event, dpPickUp)"
                 #dpPickUp="ngbDatepicker">
        </div>
        <div class="{{ widgetClass }}-pick-up__time">
          <label class="widget-label {{ widgetClass }}-pick-up__time--label">
            Time
          </label>
          <app-select [groupTabIndex]="groupTabIndex"
                      [selectValue]="pickUpTimeId"
                      className="pick-up-time"
                      (selectValueChange)="selectChange($event)"
                      [options]="times"
                      name="pickUpTimeId"
                      placeholder="Time">
          </app-select>
        </div>
      </div>

      <div class="{{ widgetClass }}-drop-off">
        <div class="{{ widgetClass }}-drop-off__date">
          <label class="widget-label {{ widgetClass }}-drop-off__date--label">
            Drop-off date
          </label>
          <input class="widget-input {{ widgetClass }}-pick-up__date--input  widget-date-input ngb-datepicker"
                 [appAutoTabIndex]="groupTabIndex"
                 placeholder="Drop-off date"
                 aria-label="Drop-off date"
                 autocomplete="off"
                 [(ngModel)]="dropOff"
                 [minDate]="minDate"
                 (dateSelect)="onDateSelection($event, 'dropOff')"
                 required="required"
                 ngbDatepicker
                 (click)="datepickerParser.toggleDatepicker($event, dpDropOff)"
                 #dpDropOff="ngbDatepicker">
        </div>
        <div class="{{ widgetClass }}-drop-off__time">
          <label class="widget-label {{ widgetClass }}-drop-off__time--label">
            Time
          </label>
          <app-select [groupTabIndex]="groupTabIndex"
                      [selectValue]="dropOffTimeId"
                      className="drop-off-time"
                      (selectValueChange)="selectChange($event)"
                      [options]="times"
                      name="dropOffTimeId"
                      placeholder="Time">
          </app-select>
        </div>
      </div>

      <div class="{{ widgetClass }}-age">
        <label class="widget-label {{ widgetClass }}-age__label">
          Please select your age
        </label>
        <app-select [groupTabIndex]="groupTabIndex"
                    [selectValue]="ageId"
                    className="age"
                    (selectValueChange)="selectChange($event)"
                    [options]="ages"
                    name="age"
                    placeholder="Age">
        </app-select>
      </div>

      <div class="{{ widgetClass }}-action">
        <button [appAutoTabIndex]="groupTabIndex"
                class="btn btn-primary {{ widgetClass }}-action__search"
                type="submit"
                (click)="search()"
                value="Search">
          Search&nbsp;
          <svg *ngIf="!model.isEbg"
               xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               viewBox="0 0 16 16">
            <path fill="#FFF"
                  fill-rule="nonzero"
                  d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
          </svg>
        </button>
      </div>

      <!-- Reason: inputs hasn't value accessor -->
      <div class="hidden-inputs">
        <input type="text"
               name="origin"
               [value]="pickUpValue" />
        <input type="text"
               name="origin_name"
               [value]="originName" />
        <input type="text"
               name="destination"
               [value]="dropOffValue" />
        <input type="text"
               name="destination_name"
               [value]="destinationName" />
        <input type="text"
               name="pick_up_time"
               [value]="pickUpTimeId" />
        <input type="text"
               name="drop_off_time"
               [value]="dropOffTimeId" />
        <input type="text"
               name="age"
               [value]="ageId" />
        <input type="text"
               name="pick_up_date"
               [value]="pickUpDate" />
        <input type="text"
               name="drop_off_date"
               [value]="dropOffDate" />
        <input type="text"
               name="destination_not_origin"
               [value]="destination_not_origin" />
      </div>
    </form>
  </div>
</ng-container>