import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() letter: string;
  @Input() width = 32;
  @Input() fontSize?: number;

  get avatarSizeClass(): string {
    return `avatar-${this.width}`;
  }

  get avatarFontClass(): string {
    return this.fontSize ? `font-size-${this.fontSize}` : '';
  }
}
