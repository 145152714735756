/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import {
  MAX_ADULTS,
  MAX_CHILD_AGE,
  MAX_KIDS,
  MAX_ROOMS,
  MIN_ADULTS,
  MIN_CHILD_AGE,
  MIN_KIDS,
  MIN_ROOMS
} from './room.constants';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { RoomData, RoomsEvent } from './room-data.interface';
import { ValueText } from '../../forms/rental-car-form/rental-car-options';

@Component({
  selector: 'app-rooms-and-guests',
  templateUrl: './rooms-and-guests.component.html',
  styleUrls: ['./rooms-and-guests.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RoomsAndGuestsComponent implements OnInit {

  @Input() widgetId: string;
  @Input() roomsData: RoomData[];
  @Input() widgetClass = '';

  @Output() roomsAndGuests: EventEmitter<RoomsEvent> = new EventEmitter<RoomsEvent>();
  @Output() dismissAction: EventEmitter<void> = new EventEmitter<void>();

  childAges: ValueText[] = [];
  roomsDataClone: RoomData[];
  showAddButton = true;
  showRemoveButton = false;

  private insideClickOut = false;

  @HostListener('click') clickInside(): void {
    this.insideClickOut = true;
  }

  @HostListener('document:click') clickOutAction(): void {
    if (!this.insideClickOut) {
      this.dismissAction.emit();
    }
    this.insideClickOut = false;
  }

  ngOnInit(): void {
    this.initChildData();
    this.roomsDataClone = cloneDeep(this.roomsData);

    this.handleActionButtonState();
  }

  dismiss(event: MouseEvent): void {
    event.preventDefault();
    this.dismissAction.emit();
  }

  selectChange({ name, value }, room: RoomData, childId: string, index: number): void {
    const specificRoom = this.roomsDataClone.find(r => r.roomId === room.roomId);
    if (name === childId + index && specificRoom) {
      specificRoom.children[index].age = value;
    }

    this.submitRoomsData();
  }

  handleRoom(event: MouseEvent, add: boolean): void {
    event.preventDefault();
    if (add && this.roomsDataClone.length < MAX_ROOMS) {
      this.roomsDataClone = [
        ...this.roomsDataClone,
        {
          roomId: this.roomsDataClone[this.roomsDataClone.length - 1].roomId + 1,
          adults: 2,
          kids: 0,
          children: []
        }
      ];
    }

    if (!add && this.roomsDataClone.length > MIN_ROOMS) {
      this.roomsDataClone = [...this.roomsDataClone.slice(0, -1)];
    }

    this.handleActionButtonState();
    this.submitRoomsData();
  }

  handleCount(event: MouseEvent, room: RoomData, increase: boolean, isAdults: boolean): void {
    event.preventDefault();
    const specificRoom = this.roomsDataClone.find(r => r.roomId === room.roomId);
    if (specificRoom) {
      if (increase) {
        this.handleIncreaseAction(isAdults, room, specificRoom);
      } else {
        this.handleDecreaseAction(isAdults, room, specificRoom);
      }
    }

    this.submitRoomsData();
  }

  private handleIncreaseAction(isAdults: boolean, room: RoomData, specificRoom: RoomData): void {
    if (isAdults && room.adults !== MAX_ADULTS) {
      specificRoom.adults++;
    }

    if (!isAdults && room.kids !== MAX_KIDS) {
      specificRoom.kids++;
      specificRoom.children = [
        ...specificRoom.children,
        {
          fieldName: `Child ${specificRoom.kids} Age`,
          id: `child${specificRoom.kids}${specificRoom.roomId}`,
          age: MIN_CHILD_AGE.toString()
        }
      ];
    }
  }

  private handleDecreaseAction(isAdults: boolean, room: RoomData, specificRoom: RoomData): void {
    if (isAdults && room.adults !== MIN_ADULTS) {
      specificRoom.adults--;
    }

    if (!isAdults && room.kids !== MIN_KIDS) {
      specificRoom.children = specificRoom.children.filter(c => c.id !== `child${specificRoom.kids}${specificRoom.roomId}`);
      specificRoom.kids--;
    }
  }

  private initChildData(): void {
    for (let i = MIN_CHILD_AGE; i <= MAX_CHILD_AGE; i++) {
      this.childAges = [...this.childAges, { value: i.toString(), text: i.toString() }];
    }
  }

  private submitRoomsData(): void {
    this.roomsData = this.roomsDataClone;
    this.roomsAndGuests.emit({ roomsData: this.roomsData, widgetId: this.widgetId });
  }

  private handleActionButtonState(): void {
    this.showAddButton = this.roomsDataClone.length < MAX_ROOMS;
    this.showRemoveButton = this.roomsDataClone.length > MIN_ROOMS;
  }
}
