/* eslint-disable typescriptESlintPlugin/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import {
  G3_SUBDOMAINS,
  normalizeAdobeStrings,
  UrlHelperService,
  WINDOW,
} from 'g3-common-ui';
import { ConfigService, cdnHostTypes } from '../../config.service';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Ad, AdSource } from '@app/shared/models/ad.model';
import {
  AdobeCardSize,
  AdobeClickEvent,
  AdobeEventLocation,
  AdobeEventName,
  AdobeEventNameEnum,
  AdobeEventType,
  AdobeImpresssionsEvent,
  AdobeOfferData,
  AdobeOfferWidgetEvent,
  AdobeTddImpresssionsEvent,
  AdobeTodayDealData,
  AdobeTodayDealDataItem,
  AdobeWidgetClickEvent,
  AdobeWidgetData,
  AdobeWidgetDataItem,
  AdobeWidgetImpresssionsEvent,
  AdobeImpressionEvent,
  AdobeNotiClickEvent,
  AdobeEventTypeEnum,
  AdobeNotiImpressionsEvent
} from '../adobe-analytics.interfaces';
import { OfferModel } from '@app/shared/models/offer.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { TodayDeal } from '@app/shared/models/today-deal.model';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { CategoryTagsService } from '../../category-tags.service';
import { TRUECAR_LARGE, TRUECAR_MINI, TICKETS_SEARCH } from '@app/widgets/constants/widget-ids.constant';
import { SearchResultsAdsModel } from '@shared/models/search-results-ads.model';
import { NavDynamicItem } from '@app/core/layouts/logged-in/interfaces/nav-dynamic-section.interface';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

const INTERVAL = 1000;
const EGB_HOST_TOKEN = '%ebghost%';
const aggregatableLocations = [
  AdobeEventLocation.Homepage,
];

interface CategoryTagsDictionaryResponse {
  code: string;
  title: string;
  cat_code_parent: string[];
  sensitive: boolean;
  categories: boolean;
}

interface OfferExtraInfo {
  guid: string;
  prod_main_category?: string;
  brand_tag_display?: string;
  brand_tag_internal?: string;
  parent_brand_name?: string;
  shopping_category?: string;
}

type AggregatableEvent = AdobeImpresssionsEvent | AdobeWidgetImpresssionsEvent | AdobeTddImpresssionsEvent;

@Injectable({
  providedIn: 'root'
})
export class AdobeAnalyticsEventsService {

  private stopTimer$ = new Subject<void>();
  private pendingEvents: { name: string; payload?: unknown }[] = [];
  private pendingPageEvents: Map<AdobeEventLocation, AggregatableEvent[]> = new Map();
  private cachedOfferExtraInfo: Map<string, OfferExtraInfo> = new Map();
  private cachedWidgetExtraInfo: Map<string, AdobeWidgetDataItem> = new Map();
  private cdnUrl: string;

  public constructor(
    private readonly configService: ConfigService,
    private urlHelper: UrlHelperService,
    private http: HttpClient,
    protected categoryTagsService: CategoryTagsService,
    private readonly window: WINDOW
  ) {
    this.cdnUrl = configService.getCdn(cdnHostTypes.apiHost) || environment.apiUrl;
    this.window.addEventListener('beforeunload', () => this.dispatchOnClose());
  }

  public emitInteractionEvent(event: { data: unknown }, forceSend?: boolean): void {
    this.emitEvent('g3_interaction_event', event.data, forceSend);
  }

  public emitPageviewEvent(): void {
    this.emitEvent('g3_pageview');
  }

  public emitGeneralEvent(data: unknown): void {
    try {
      this.emitEvent('g3_event', data);
    } catch (error) {
      console.error('Error sending Adobe analytics');
      console.error(error);
    }
  }

  public emitUserAccountDeletionRequest(guid: string, encryptedEmail: string): void {
    if (!guid || !encryptedEmail) {
      return console.error('Cannot emit user account deletion request event. "guid" and "encryptedEmail" params are required.');
    }
    this.emitInteractionEvent({
      data: {
        category: 'account-enrolls',
        g3_category: 'requested-delete',
        g3_action: 'delete-account.interaction',
        properties: {
          event_name: 'account_deletion_request',
          user: {
            guid,
            email: encryptedEmail
          }
        }
      }
    });
  }

  public emitUserAccountDeletionSuccess(guid: string, encryptedEmail: string): void {
    if (!guid || !encryptedEmail) {
      return console.error('Cannot emit user account deletion success event. "guid" and "encryptedEmail" params are required.');
    }
    this.emitInteractionEvent({
      data: {
        category: 'account-enrolls',
        g3_category: 'success-deleted',
        g3_action: 'delete-account.interaction',
        properties: {
          event_name: 'account_deletion',
          user: {
            guid,
            email: encryptedEmail
          }
        }
      }
    });
  }

  public emitSignInSuccessEvent(loginType: string): void {
    //* This event fires instantly on refresh when _satelite is not loaded yet
    // TODO Move to emitEvent subscription
    setTimeout(() => {
      this.emitInteractionEvent({
        data: {
          category: 'account-logins',
          g3_category: 'sign-in-success',
          g3_action: 'sign-in.interaction',
          properties: {
            event_name: 'login',
            login: {
              type: loginType
            }
          }
        }
      });
    }, 2000);
  }

  public emitSignInFailureEvent(loginType: string, error: string, forceSend?: boolean): void {
    //* This event fires instantly on refresh when _satelite is not loaded yet
    // TODO Move to emitEvent subscription
    this.emitInteractionEvent({
      data: {
        category: 'account-logins',
        g3_category: 'sign-in-failure',
        g3_action: 'sign-in-failure.interaction',
        properties: {
          event_name: 'login_error',
          login: {
            type: loginType,
            errors: [error.replace(/[^\w\s]/g, '')]
          }
        }
      }
    }, forceSend);
  }

  public emitConfirmEmailEvent(enrollType: string, userGuid: string, encryptedEmail: string): void {
    this.emitInteractionEvent({
      data: {
        category: 'account-enrolls',
        g3_category: 'sign-up-confirmation',
        g3_action: 'sign-up-confirmation.interaction',
        properties: {
          event_name: 'enroll_email_confirm',
          enroll_type: enrollType,
          user: {
            guid: userGuid,
            email: encryptedEmail,
          }
        }
      }
    });
  }

  public emitNewAccountEnrollEvent(enrollType: string, userGuid: string, encryptedEmail: string): void {
    this.emitInteractionEvent({
      data: {
        category: 'account-enrolls',
        g3_category: 'sign-up-confirm-info',
        g3_action: 'sign-up-confirm-info.interaction',
        properties: {
          event_name: 'new_account_enroll',
          enroll_type: enrollType,
          user: {
            guid: userGuid,
            email: encryptedEmail,
          }
        }
      }
    });
  }

  private emitEvent<T>(name: string, payload?: T, forceSend?: boolean): void {
    if (
      // this is to require all pageview events to always firing
      (payload && !this.configService.sendViaAdobeSatelite()) && !forceSend
    ) {
      return;
    }

    if (!this.window._satellite) {
      this.pendingEvents.push({ name, payload });
      this.initSatelliteTrack();

      return;
    }

    payload = normalizeAdobeStrings(payload);

    // * Temporary suppressed in G3PROJ-937
    // if (this.configService.isAdobeQAModeEnabled) {
    // eslint-disable-next-line no-console
    console.log(`Calling satellite track - ${name}`, JSON.stringify(payload || '', null, 2));
    // }

    this.window._satellite.track(name, payload);
  }

  private initSatelliteTrack(): void {
    timer(0, INTERVAL)
      .pipe(
        switchMap(async () => this.window._satellite ? true : false),
        takeUntil(this.stopTimer$)
      )
      .subscribe({
        next: (res) => this.dispatchMissedEvents(res),
        error: (error: unknown) => { }
      });
  }

  private dispatchMissedEvents(satelliteLoaded: boolean): void {
    if (!satelliteLoaded) {
      return;
    }

    this.pendingEvents.forEach(event => this.emitEvent(event.name, event.payload));
    this.pendingEvents = [];

    this.stopTimer$.next();
  }

  private dispatchOnClose(): void {
    for (const key of this.pendingPageEvents.keys()) {
      this.emitPageEvents(key);
    }
  }

  emitPageEvents(page: AdobeEventLocation): void {
    const pageEvents = this.pendingPageEvents.get(page);
    if (!pageEvents) {
      return;
    }

    const pageImpressions: AdobeImpressionEvent[] = [];

    for (const event of pageEvents) {
      let aggregatedEvent: AdobeImpressionEvent = pageImpressions.find(e => e.event_name === event.event_name && e.event_type === event.event_type);
      if (!aggregatedEvent) {
        aggregatedEvent = {
          event_name: event.event_name,
          event_type: event.event_type,
          properties: { impressions: [] }
        };
        pageImpressions.push(aggregatedEvent);
      }

      event.properties.impressions.forEach(impression => {
        aggregatedEvent.properties.impressions.push({
          ['offer' + (aggregatedEvent.properties.impressions.length + 1)]: impression[Object.keys(impression)[0]]
        });
      });
    }

    pageImpressions.forEach(aggregatedEvent => {
      this.emitGeneralEvent({
        event_name: aggregatedEvent.event_name,
        event_type: aggregatedEvent.event_type,
        properties: aggregatedEvent.properties,
      });
    });

    this.pendingPageEvents.delete(page);
  }

  sendWidgetImpressions(event: AdobeWidgetImpresssionsEvent, page?: AdobeEventLocation): void {
    if (aggregatableLocations.includes(page)) {
      this.storePageEvent(event, page);
      return;
    }

    this.emitGeneralEvent(event);
  }

  async sendOfferImpressions(event: AdobeImpresssionsEvent, page?: AdobeEventLocation): Promise<void> {
    const guids = event.properties.impressions.filter(i => !i[Object.keys(i)[0]].isTdd).map(i => i[Object.keys(i)[0]].guid);
    const extraInfo = await this.getOffersExtraInfo(guids);

    event.properties.impressions.forEach(i => {
      const offerImpression = i[Object.keys(i)[0]];
      const offerInfo = extraInfo.find(e => e.guid === offerImpression.guid);

      if (offerInfo) {
        this.cachedOfferExtraInfo.set(offerImpression.guid, offerInfo);
        offerImpression.display_brand = offerInfo.brand_tag_display || '';
        offerImpression.parent_brand = offerInfo.parent_brand_name || '';
        offerImpression.internal_brand = offerInfo?.brand_tag_internal || '';
      }

      if (offerImpression.isEbg) {
        offerImpression.product_main_category = offerInfo?.prod_main_category || '';
        delete offerImpression.shopping_category;
      } else {
        offerImpression.shopping_category = offerInfo?.shopping_category || (offerImpression.isTdd
          ? offerImpression.shopping_category
          : '');
        delete offerImpression.product_main_category;
      }
      if (offerImpression.isTdd) {
        delete offerImpression.internal_brand;
        delete offerImpression.parent_brand;
        delete offerImpression.product_main_category;
      }
      delete offerImpression.isTdd;
      delete offerImpression.isEbg;
    });

    if (event.event_name === AdobeEventNameEnum.FLY_IN
      || event.event_name === AdobeEventNameEnum.OFFER_PAGE
      || event.event_name === AdobeEventNameEnum.EMAIL_MODAL) {
      event.properties.offer = event.properties.impressions[0].offer as unknown as AdobeOfferData;
      delete event.properties.impressions;
      delete event.properties.offer.size;
    }

    if (aggregatableLocations.includes(page)) {
      this.storePageEvent(event, page);
      return;
    }

    this.emitGeneralEvent(event);
  }

  storePageEvent(event: AggregatableEvent, page: AdobeEventLocation): void {
    if (!this.pendingPageEvents.has(page)) {
      this.pendingPageEvents.set(page, []);
    }

    this.pendingPageEvents.get(page).push(event);
  }

  sendNavigationClick(nav: NavDynamicItem): void {
    const { ebghost } = this.configService.getConfig();
    const href = nav.url?.toLowerCase().replace(EGB_HOST_TOKEN.toLowerCase(), ebghost);

    const eventDetails = {
      event_name: AdobeEventNameEnum.NAV,
      event_type: this.getClickOutValueForNav(nav),
      properties: {
        link: {
          name: nav.name,
          slug: nav.id,
          href,
        }
      }
    };

    this.emitGeneralEvent(eventDetails);
  }

  sendWidgetClick(event: AdobeWidgetClickEvent): void {
    this.emitGeneralEvent(event);
  }

  sendOfferWidgetEvent(event: AdobeOfferWidgetEvent): void {
    const offerEvent = event.properties[Object.keys(event.properties)[0]];
    const cachedInfo = this.cachedOfferExtraInfo.get(offerEvent.guid);
    if (cachedInfo) {
      offerEvent.shopping_category = cachedInfo.shopping_category || '';
      offerEvent.product_main_category = cachedInfo.prod_main_category || '';
      offerEvent.display_brand = cachedInfo.brand_tag_display || '';
      offerEvent.parent_brand = cachedInfo.parent_brand_name || '';
      offerEvent.internal_brand = cachedInfo.brand_tag_internal || '';
    }
    this.emitGeneralEvent(event);
  }

  async sendOfferClick(event: AdobeClickEvent): Promise<void> {
    const offerImpression = event.properties[Object.keys(event.properties)[0]];
    const cachedInfo = this.cachedOfferExtraInfo.get(offerImpression.guid);
    let offerInfo;
    if (cachedInfo) {
      offerImpression.display_brand = cachedInfo.brand_tag_display || '';
      offerImpression.parent_brand = cachedInfo.parent_brand_name || '';
      offerImpression.internal_brand = cachedInfo.brand_tag_internal || '';
    } else {
      const extraInfo = await this.getOffersExtraInfo([offerImpression.guid]);
      offerInfo = extraInfo[0];

      if (offerInfo) {
        this.cachedOfferExtraInfo.set(offerImpression.guid, offerInfo);
        offerImpression.display_brand = offerInfo.brand_tag_display || '';
        offerImpression.parent_brand = offerInfo.parent_brand_name || '';
        offerImpression.internal_brand = offerInfo.brand_tag_internal || '';
      }
    }
    if (offerImpression.isEbg) {
      offerImpression.product_main_category = cachedInfo?.prod_main_category || offerInfo?.prod_main_category || '';
      delete offerImpression.shopping_category;
    } else {
      offerImpression.shopping_category = cachedInfo?.shopping_category || offerInfo?.shopping_category || '';
      delete offerImpression.product_main_category;
      delete offerImpression.size;
    }
    delete offerImpression.isEbg;
    this.emitGeneralEvent(event);
  }

  sendNotiClick(data: ZoneSectionElement): void {
    const ad = data.ads[0];
    const destinationUrl = ad.content.find(c => c.key === 'button_url')?.value;

    const eventType = this.getClickOutValueForNoti(destinationUrl, ad);
    const event = this.getNotiEventFromNoti(data, eventType);

    this.emitGeneralEvent(event);
  }

  sendNotiImpression(data: ZoneSectionElement): void {
    const event = this.getNotiEventFromNoti(data, AdobeEventTypeEnum.IMPRESSION);

    this.emitGeneralEvent(event);
  }

  async getOffersExtraInfo(guids: string[]): Promise<OfferExtraInfo[]> {
    const extraInfo = await this.http.get<OfferExtraInfo[]>(`${environment.apiUrl}/api/offers/extra-info?guids=${guids.join(',')}`).toPromise();

    for (const info of extraInfo) {
      this.cachedOfferExtraInfo.set(info.guid, info);
    }

    return extraInfo;
  }

  async getWidgetExtraInfo(ad: Ad): Promise<Partial<AdobeWidgetDataItem>> {
    if (!ad.catCodeTagIds.length) {
      return { shopping_category: '' };
    }

    const catCodeIds = await this.getCategoryTags(ad.catCodeTagIds) || [];
    const shoppingCategory = catCodeIds
      .filter(({ categories, title }) => categories && title)
      .map(({ title }) => title.replace(/[,\s]+/g, ' '))
      .join(',');

    return { shopping_category: shoppingCategory };
  }

  async getCategoryTags(catCodeIds: number[] = []): Promise<CategoryTagsDictionaryResponse[]> {
    const tagIds = catCodeIds.join(',') || 'null';
    return this.http.get<CategoryTagsDictionaryResponse[]>(`${this.cdnUrl}/api/dictionaries/category-tags/${tagIds}`)
      .toPromise()
      .catch(() => []);
  }

  getAdobeClickInfo(ad: Ad, size: AdobeCardSize, zoneName?: string, isTakeover = false, eventName: AdobeEventName = 'card'): AdobeClickEvent {
    return {
      event_name: eventName,
      event_type: this.getClickOutValue(ad),
      properties: this.getOfferAdobeData(ad, size, 'offer', zoneName, isTakeover)
    };
  }

  async getWidgetAdobeData(ad: Ad, key: string = 'offer', { size, zone }: Partial<AdobeWidgetDataItem> = {}): Promise<AdobeWidgetData> {
    if (this.cachedWidgetExtraInfo.get(ad.guid)) {
      const adobeEventData = this.cachedWidgetExtraInfo.get(ad.guid);

      return { [key]: adobeEventData };
    }

    const extraInfo = await this.getWidgetExtraInfo(ad);

    const adobeEventData = {
      guid: ad.guid,
      display_brand: ad.brand_name,
      name: ad.getContent().header,
      shopping_category: extraInfo.shopping_category,
      size,
      zone,
    };
    if (this.getIsEbg(ad)) {
      delete adobeEventData.shopping_category;
    }
    this.cachedWidgetExtraInfo.set(ad.guid, adobeEventData);

    return { [key]: adobeEventData };
  }

  getOfferAdobeData(ad: Ad, size: AdobeCardSize, key: string, zoneName?: string, isTakeover = false): AdobeOfferData {
    let offerType = 'natural';
    if (ad.class.toLowerCase() === 'client') {
      offerType = 'client';
    } else if (ad.source === AdSource.BSS) {
      offerType = 'ad';
    } else if (isTakeover) {
      offerType = 'takeover';
    }

    const adobeData: AdobeOfferData = {
      [key]: {
        guid: ad.contentGuid,
        type: offerType,
        name: ad.headerShort || ad.titleShort || '',
        size,
        shopping_category: '',
        product_main_category: '',
        display_brand: '',
        parent_brand: '',
        internal_brand: '',
        isEbg: this.getIsEbg(ad)
      }
    };

    if (zoneName) {
      adobeData[key].zone = zoneName;
    }

    return adobeData;
  }

  getAdobeClickInfoForOffer(offer: OfferModel, zoneName?: string, navigatedFromData?: Breadcrumb, size?: AdobeCardSize): AdobeClickEvent {
    const isSearchResults = navigatedFromData.url === '/offers';
    const eventName = isSearchResults ? AdobeEventNameEnum.SEARCH_LISTING : AdobeEventNameEnum.CARD;
    return {
      event_name: eventName,
      event_type: this.getClickOutValueForOffer(offer),
      properties: this.getOfferAdobeDataFromOffer(offer, eventName, 'offer', zoneName, size)
    };
  }

  getOfferAdobeDataFromTodayDeal(
    todayDeal: TodayDeal,
    eventType: AdobeEventName,
    key: string,
    { zone, type }: Partial<AdobeTodayDealDataItem> = {},
    size: AdobeCardSize = 'standard'
  ): AdobeTodayDealData {
    let adobeData: AdobeTodayDealData;
    if (eventType === AdobeEventNameEnum.CARD || eventType === AdobeEventNameEnum.SEARCH_LISTING) {
      adobeData = {
        [key]: {
          display_brand: todayDeal.company_name,
          guid: todayDeal.guid,
          name: todayDeal.headline || '',
          shopping_category: todayDeal.shopping_category,
          size,
          type: 'natural',
          isEbg: this.getIsEbg(todayDeal)
        }
      };

      if (zone) {
        adobeData[key].zone = zone;
      }
    } else if (eventType === AdobeEventNameEnum.EMAIL_MODAL) {
      adobeData = {
        [key]: {
          display_brand: todayDeal.company_name,
          guid: todayDeal.guid,
          name: todayDeal.headline || '',
          shopping_category: todayDeal.shopping_category,
          type: type || 'pass-thru',
          isEbg: this.getIsEbg(todayDeal)
        }
      };
    }
    return adobeData;
  }

  getOfferAdobeDataFromOffer(offer: OfferModel, eventType: AdobeEventName, key: string, zoneName?: string, size: AdobeCardSize = 'standard'): AdobeOfferData {
    const adobeData: AdobeOfferData = {
      [key]: {
        guid: offer.guid,
        type: '',
        name: offer.header_short || '',
        size,
        shopping_category: '',
        product_main_category: '',
        display_brand: '',
        parent_brand: '',
        internal_brand: '',
        isEbg: this.getIsEbg(offer)
      }
    };

    if (eventType === AdobeEventNameEnum.CARD) {
      let offerType = 'natural';
      if (offer.class.toLowerCase() === 'client') {
        offerType = 'client';
      }

      adobeData[key].type = offerType;
      adobeData[key].size = 'standard';

      if (zoneName) {
        adobeData[key].zone = zoneName;
      }
    } else if (eventType === AdobeEventNameEnum.WIDGET && zoneName === 'offer page') {
      const offerType = offer.class.toLowerCase() === 'client' ? 'client' : '';
      adobeData[key].type = offerType;
      adobeData[key].size = 'iframe_med';
      delete adobeData[key].isEbg;
    } else if (eventType === AdobeEventNameEnum.EMAIL_MODAL) {
      const offerType = offer.class.toLowerCase() === 'client' ? 'client' : 'pass-thru';

      adobeData[key].type = offerType;
    } else if (eventType === AdobeEventNameEnum.OFFER_PAGE) {
      const offerType = offer.class.toLowerCase() === 'client' ? 'client' : '';
      adobeData[key].type = offerType;
    } else if (eventType === AdobeEventNameEnum.SEARCH_LISTING) {
      const offerType = offer.class.toLowerCase() === 'client' ? 'client' : 'natural';
      adobeData[key].type = offerType;
    }

    return adobeData;
  }

  getNotiEventFromNoti(data: ZoneSectionElement, eventType: AdobeEventType): AdobeNotiClickEvent | AdobeNotiImpressionsEvent {
    const ad = data.ads[0];
    const header = ad.content.find(c => c.key === 'header')?.value;
    const event: AdobeNotiClickEvent | AdobeNotiImpressionsEvent = {
      event_name: AdobeEventNameEnum.NOTI,
      event_type: eventType,
      properties: {
        offer: {
          guid: ad.guid,
          name: header || '',
          display_brand: ad.brand_name || 'internal',
          size: data.ad_type,
          zone: data.name || ''
        }
      }
    };

    return event;
  }

  getClickOutValue(ad: Ad): AdobeEventType {
    if (ad.isPaylogixAd) {
      return 'external_clickout';
    }
    if (this.isClickEventType(ad.destinationUrl)) {
      return 'click';
    }

    const isEbg = ad.isEbg === undefined
      ? this.isTokenPresent(ad.destinationUrl, 'ebghost')
      : ad.isEbg;

    return isEbg ? 'internal_clickout' : 'external_clickout';
  }

  getClickOutValueForNav(nav: NavDynamicItem): AdobeEventType {
    if (this.isClickEventType(nav.url)) {
      return 'click';
    }

    const isEbg = nav.is_ebg === undefined
      ? this.isTokenPresent(nav.url, 'ebghost')
      : nav.is_ebg;

    return isEbg ? 'internal_clickout' : 'external_clickout';
  }

  getClickOutValueForWidget(ad: Ad, widgetId: string = ''): AdobeEventType {
    if ([TRUECAR_LARGE, TRUECAR_MINI].includes(widgetId)) {
      return 'external_clickout';
    }

    if ([TICKETS_SEARCH].includes(widgetId) || this.isClickEventType(ad.destinationUrl)) {
      return 'click';
    }

    const isEbg = ad.isEbg === undefined
      ? this.isTokenPresent(ad.destinationUrl, 'ebghost')
      : ad.isEbg;

    return isEbg ? 'internal_clickout' : 'external_clickout';
  }

  getClickOutValueForOffer(offer: OfferModel): AdobeEventType {
    if (offer.external_source === 'paylogix') {
      return 'external_clickout';
    }

    if (this.isClickEventType(offer.url)) {
      return 'click';
    }

    const isEbg = offer.isEbg === undefined
      ? this.isTokenPresent(offer.url, 'ebghost')
      : offer.isEbg;

    return isEbg ? 'internal_clickout' : 'external_clickout';
  }

  getClickOutValueForTdd(tdd: TodayDeal): AdobeEventType {
    if (this.isClickEventType(tdd.url)) {
      return 'click';
    }

    const isEbg = tdd.is_ebg === undefined
      ? this.isTokenPresent(tdd.url, 'ebghost')
      : tdd.is_ebg;

    return isEbg ? 'internal_clickout' : 'external_clickout';
  }

  getClickOutValueForNoti(url: string, ad: Ad): AdobeEventType {
    if (this.isClickEventType(url)) {
      return 'click';
    }

    const isEbg = ad.isEbg === undefined
      ? this.isTokenPresent(url, 'ebghost')
      : ad.isEbg;

    return isEbg ? 'internal_clickout' : 'external_clickout';
  }

  getIsEbg(item: Ad | OfferModel | TodayDeal | SearchResultsAdsModel): boolean {
    if (item instanceof Ad || item instanceof SearchResultsAdsModel) {
      return item.isEbg === undefined
        ? this.isTokenPresent(item.destinationUrl, 'ebghost')
        : item.isEbg;
    }
    if (item instanceof OfferModel) {
      return item.isEbg === undefined
        ? this.isTokenPresent(item.url, 'ebghost')
        : item.isEbg;
    }
    if (item instanceof TodayDeal) {
      return item.is_ebg === undefined
        ? this.isTokenPresent(item.url, 'ebghost')
        : item.is_ebg;
    }
  }

  isClickEventType(url: string): boolean {
    if (!url) {
      return false;
    }

    const isAbsoluteUrl = this.urlHelper.isExternalUrl(url);
    if (!isAbsoluteUrl) {
      return true;
    }

    const domains = ['beneplace', 'workingadvantage'];
    const host = url.split('/')[2];
    const hostArr = host.split('.').reverse();
    const isG3Url = domains.includes(hostArr[1]) && G3_SUBDOMAINS.includes(hostArr[2]);
    if (isG3Url) {
      return true;
    }

    return this.isTokenPresent(host, 'subdomain')
      || this.isTokenPresent(host, 'domain');
  }

  private isTokenPresent(text: string = '', token: string): boolean {
    return text?.toLowerCase().includes(`%${token.toLowerCase()}%`);
  }
}
