<div class="widget car-widget widget-b">
  <div class="widget-image-container"
       [style.backgroundImage]="makeBackgroundImageUrl(backgroundImage | imageUrl : getBackgroundOptions() : false)">
    <div class="logo-wrapper-b">
      <img [src]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           loading="lazy"
           alt="taw"
           class="logo" />
    </div>
  </div>

  <div class="relative widget-form-container">
    <div class="logo-wrapper-b-mobile">
      <img [src]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           alt="taw"
           loading="lazy"
           class="logo" />
    </div>

    <div class="flex-container correct-margin flex-row">
      <div class="image">
        <img src="/assets/icons/ic-car.svg"
             loading="lazy"
             class="ic_car"
             alt="">
      </div>
      <div class="header-text">
        <h3>{{ header }}</h3>
      </div>
    </div>
    <app-truecar-form [model]="model"
                      widgetClass="large-widget"
                      (buttonClick)="onButtonClick($event)">
    </app-truecar-form>
    <div class="logo-wrapper">
      <img src="/assets/truecar.png"
           loading="lazy"
           alt="true-car"
           class="logo" />
    </div>
  </div>
</div>
