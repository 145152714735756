/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  // This Pipe is created for sorting arrays inside ngFor directives in the template
  // it takes actual array, field to sort by and order as an arguments and return sorted array

  // Arguments:
  // array will be passed automatically when used inside ngFor inside the template
  // field is a string value to sort by array of objects
  // order can be 1 or -1 for ascending or descending sort (1 by default)

  transform(array: any[], field: string, order: number = 1): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    return array.sort((a: any, b: any) => a[field] < b[field] ? order : -order);
  }

}
