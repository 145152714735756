<app-card-overlay [ngClass]="{'card-access-lock__half-view-mode': !isTier1}"
                  (visibilityChange)="visibilityChange.next($event)"
                  (overlayClick)='onSignInClick()'>
  <div class='card-access-lock__content'
       [ngClass]="{ 'card-access-lock__content__wide': wideMode }">
    <img class='card-access-lock__content__image'
         src="/assets/icons/ic-sign-in.svg">
    <p class='card-access-lock__content__label'>{{ getCardContent() }}</p>
    <button class="btn btn-primary card-access-lock__content__sign-in-button">{{ getCardButtonTitle() }}</button>
  </div>
</app-card-overlay>
