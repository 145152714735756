import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchWidgetsData } from '@app/offers/interfaces/search-widgets.interface';
import { UrlHelperService } from 'g3-common-ui';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { Ad } from '@app/shared/models/ad.model';
import { AdobeEventNameEnum } from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { TICKETS_SEARCH } from '../constants/widget-ids.constant';

@Injectable()
export class SearchWidgetService {

  constructor(
    private analyticsService: AnalyticsService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private urlHelper: UrlHelperService,
    private http: HttpClient
  ) { }

  onDisplayed(widget: Partial<SearchWidgetsData>): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-search-displayed', {
        'widget-type': widget.widget_id,
        ad_id: widget.adId
      }),
      new AnalyticsGAEventModel('widget-search-displayed', {
        category: 'widget-search',
        label: `${widget.widget_id} | ${widget.adId}`.toString()
      }),
    ]);

    this.http.post(`${environment.apiUrl}/decisions/impressions`,
      { impressions: [{ response_id: widget.responseId }] }).toPromise().catch(() => { });
  }

  onClicked(widget: Partial<SearchWidgetsData>): void {
    const clickOut = widget.isEbg || !this.urlHelper.isExternalUrl(widget.destination_url) ? 'internal' : 'external';

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-search-click', {
        'widget-type': widget.widget_id,
        ad_id: widget.adId,
        url: widget.destination_url,
        click_out: clickOut
      }),
      new AnalyticsGAEventModel('widget-search-click', {
        category: 'widget-search',
        label: `${widget.widget_id}|${widget.adId}|${clickOut}`.toString()
      }),
    ]);

    this.http.post(`${environment.apiUrl}/decisions/clicks`,
      { response_id: widget.responseId }).toPromise().catch(() => { });

    void this.sendAdobeAnalytics(widget);
  }

  async sendAdobeAnalytics(widget: Partial<SearchWidgetsData>): Promise<void> {
    const ad = new Ad({
      guid: widget.adId,
      brand_name: widget.brand_name,
      content: [{ key: 'header', value: widget.header }],
      catCodeTagIds: widget.cat_code_tag_ids,
      isEbg: widget.isEbg,
      destinationUrl: widget.destination_url,
    });
    this.adobeAnalyticsEventsService.sendWidgetClick({
      event_name: AdobeEventNameEnum.WIDGET,
      event_type: this.adobeAnalyticsEventsService.getClickOutValueForWidget(ad, widget.widget_id),
      properties: await this.adobeAnalyticsEventsService.getWidgetAdobeData(ad, 'offer', { size: 'search', zone: 'search page' })
    });
  }

}
