<app-offer-badge *ngIf="widgetOffer"
                 [badgeIndex]="sectionIndex + 1"
                 [offerData]="widgetOffer"
                 [offerClass]="widgetOffer.class"
                 [withRightCorners]="true"
                 class="position-right">
</app-offer-badge>

<section class="widget-container widget-container-variation"
         [@widgetToggle]="isExpandedWidget ? 'expanded' : 'closed'"
         [class.filler]="isFiller"
         [class.expanded]="isExpandedWidget"
         [style.z-index]="sectionIndex">

  <div class="widget-mini widget-mini-variation"
       (click)="onClick()">

    <div class="widget-mini-img-rounded">
      <img [class.img-expanded]="isExpandedWidget"
           loading="lazy"
           [src]="widget.iconUrl | imageUrl: imageOption" />
    </div>

    <div class="widget-mini-content wide-content">
      <div class="widget-mini-content__name">
        <h5 class="widget-mini-content__name--title variation-truncate-title"
            [ngClass]="{'widget-mini-content__name--title--filler': isFiller}">
          <div class="titles-container variation-titles-container">
            <span class="widget-mini-content__name--title-text variation-truncate-header">
              {{ widget.header }}
            </span>
            <div class="sub-header-container">
              <span class="widget-mini-content__name--title-text sub-header variation-truncate-sub-header"
                    [ngClass]="{'widget-mini-content__name--title-text--filler': isFiller}">
                {{ widget.subHeader }}
              </span>
              <ng-container *ngIf="isFiller">
                <ng-container *ngTemplateOutlet="widgetArrow"></ng-container>
              </ng-container>
            </div>
          </div>
        </h5>
        <ng-container *ngIf="!isFiller">
          <button class="widget-mini-content__name--button">
            <ng-container *ngTemplateOutlet="widgetArrow"></ng-container>
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="widget-data"
       [ngClass]="isExpandedWidget
      ? 'widget-data-expand'
      : 'widget-data-collapse'"
       [class.widget-data-expand-visible]="isExpandedWidget"
       [class.widget-data-collapse-visible]="!isExpandedWidget">
    <app-custom-widget-host [widget]="widget"
                            [groupTabIndex]="groupTabIndex"></app-custom-widget-host>
  </div>
</section>

<ng-template #widgetArrow>
  <svg [class.rotated-arrow]="isExpandedWidget"
       [class.standart-arrow]="!isExpandedWidget"
       [class.filler-arrow]="isFiller"
       width="12"
       height="8"
       viewBox="0 0 12 8"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6"
          d="M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z"
          fill="#353535" />
  </svg>
</ng-template>
