/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/no-misused-promises */
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { Angulartics2 } from 'angulartics2';
import { Breadcrumb } from './breadcrumbs.service';
import { DocumentService } from '@app/shared/services/document.service';
import { environment } from '@environments/environment';
import { G3analyticsService } from '@app/shared/services/g3analytics.service';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel,
  AnalyticsAdEventModel,
} from '@app/shared/models/analytics.event.model';
import { WINDOW, SplitTestAnalyticsService, SplitTestEventsType, OneTrustService } from 'g3-common-ui';
import { G3InteractionEvents } from '@shared/services/adobe/adobe-analytics-interaction-arrays';
import { ConfigService } from '@shared/services/config.service';
import { AdobeAnalyticsEventsService } from './adobe/units/adobe-analytics-events.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    public angulartics2: Angulartics2,
    private readonly oneTrustService: OneTrustService,
    private g3AnalyticsService: G3analyticsService,
    private documentService: DocumentService,
    public router: Router,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private splitTestAnalyticsService: SplitTestAnalyticsService,
    @Inject(WINDOW) private window: Window,
    private readonly adobeAnalyticsEventsService: AdobeAnalyticsEventsService
  ) {
  }

  // it's default analytic event handlers, should be overwritten in child classes
  // todo: investigate and add default handlers
  public determineClickType(item: any, source: string) { }
  public runClickHandler(clickType: string, item: any, onClick?: () => void, navigatedFromData?: Breadcrumb) { }
  public increaseViewCounter(eventItem: any) { }

  public eventsTrack(events: AnalyticsEvent[]) {
    events.forEach(async i => this.eventTrack(i));
  }

  public async eventTrack(event: AnalyticsEvent) {
    if (event instanceof AnalyticsGAEventModel) {
      try {
        this.angulartics2.eventTrack.next({
          action: event.type, properties: event.data
        });
      } catch {
        console.error('error in GA event tracking');
      }
    }

    if (event instanceof AnalyticsInternalEventModel) {

      const document = this.documentService.document;
      const url = document.location.origin + this.router.url;

      this.g3AnalyticsService.eventTrack(event.type, event.data, url);

      if (event.type === 'click-out' && event.data?.offer) {
        this.httpClient.post(`${environment.apiUrl}/api/offers/decisions/click`, { offer_guid: event.data.offer }).toPromise().catch(() => { });
      }
    }

    if (event instanceof AnalyticsAdEventModel) {
      if (event.type === 'g3-ad-click' && event.data) {
        this.httpClient.post(`${environment.apiUrl}/decisions/clicks`, { response_id: event.data.response_id }).toPromise().catch(() => { });
      }

      if (event.type === 'g3-ad-results' && event.data && Array.isArray(event.data.ads)) {
        this.httpClient.post(`${environment.apiUrl}/decisions/impressions`,
          { impressions: event.data.ads.map(imp => ({ response_id: imp.response_id })) }).toPromise().catch(() => { });
      }

      if (G3InteractionEvents.includes(event.type) && event.data) {
        this.adobeAnalyticsEventsService.emitInteractionEvent(event);
      }

      // * Temporary suppressed in G3PROJ-931
      // if (this.configService.isAdobeQAModeEnabled) {
      console.info('Adobe Analytics Data: ', event);
      // }
    }
  }

  public setUsername(value: string) {
    this.angulartics2.setUsername.next(value);
  }

  public async assignSplitTests(): Promise<void> {
    try {
      await this.splitTestAnalyticsService.assignSplitTests(this.g3AnalyticsService.getSocket());
    } catch (err) {
      console.error(err);
    }
  }

  public async sttsEventTrack(eventName: SplitTestEventsType, payload?: Record<string, unknown>, url?: string): Promise<void> {
    void this.splitTestAnalyticsService.sttsEventTrack(eventName, payload || { source: 'main-application' }, url);
  }

  public connectG3Analytics() {
    if (this.g3AnalyticsService && this.g3AnalyticsService.connect) {
      this.g3AnalyticsService.connect();
    }
  }

  public disconnectG3Analytics() {
    if (this.g3AnalyticsService && this.g3AnalyticsService.disconnect) {
      this.g3AnalyticsService.disconnect();
    }
  }

}
