export interface AutocompleteSettings {
  inputPlaceholderText: string;
  showSearchButton: boolean;
  currentLocIconUrl: string;
  locationIconUrl: string;
  showRecentSearch: boolean;
  inputString?: string;
  geoTypes?: string[];
  geoLocation?: number[];
  geoRadius?: number; // km
}

// AUSTIN_COORDINATES & SEARCH_RADIUS used only to prioritize search result
// SEARCH_RADIUS doesn't mean that we try to found only inside this circle it mean, that it's a priority,
// and then we get result the closet to this circle first
export const AUSTIN_COORDINATES = [30, -97];
export const SEARCH_RADIUS = 500;
