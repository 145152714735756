/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PermissionService } from '@core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';

export interface SearchTodayDealsItemResponse {
  guid: string;
  company_name: string;
  company_brand_tag_logo: string;
  headline: string;
  image_url: string;
  tag_text: string;
  views: number;
  code: string;
  destination_url: string;
  site_tags: string[];
  is_ebg: boolean;
  orig_price: number;
  curr_price: number;
  offer_group: boolean;
  new_tab: boolean;
  shopping_category: string;
}

export interface SearchTodayDealsResponse {
  items: SearchTodayDealsItemResponse[];
}

@Injectable({
  providedIn: 'root'
})
export class SearchTodayDealsService {

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
  ) { }

  searchTodayDeals(query: string, queryParams: any, limit: number, brandTagIds?: string, categoryCodeTagIds?: string): Observable<SearchTodayDealsResponse> {

    if (limit <= 0) {
      limit = 10;
    }

    const queryStringAsParams = [];

    queryStringAsParams.push(`q=${encodeURIComponent(query)}`);
    queryStringAsParams.push(`limit=${limit}`);

    if (brandTagIds !== '') {
      queryStringAsParams.push(`b=${brandTagIds}`);
    }
    if (categoryCodeTagIds !== '') {
      queryStringAsParams.push(`c=${categoryCodeTagIds}`);
    }
    if (queryParams['f_cat']) {
      queryStringAsParams.push(`f_cat=${queryParams['f_cat']}`);
    }
    if (queryParams['f_brand']) {
      queryStringAsParams.push(`f_brand=${queryParams['f_brand']}`);
    }
    if (queryParams['tdg']) {
      queryStringAsParams.push(`tdg=${queryParams['tdg']}`);
    }
    if (queryParams['nb']) {
      queryStringAsParams.push(`nb=${queryParams['nb']}`);
    }
    if (queryParams['nc']) {
      queryStringAsParams.push(`nc=${queryParams['nc']}`);
    }
    if (queryParams['nq']) {
      queryStringAsParams.push(`nq=${encodeURIComponent(queryParams['nq'])}`);
    }

    // TDD has no class/type/special therefore if such filter is present return nothing
    if (queryParams['f_class'] || queryParams['f_type'] || queryParams['f_special']) {
      return of({ items: [] });
    }

    if (this.permissionService.hasDefined('known:access')) {
      queryStringAsParams.push(`known_user_email=${this.knownUserService.knowUserEmail}`);
    }

    return this.http.get<any>(`${environment.apiUrl}/api/search/today-deals?${queryStringAsParams.join('&')}`);
  }
}
