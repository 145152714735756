/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { ActivatedRoute } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  Ages,
  RentalCarDropdownSettings,
  RentalCarDropOffDropdownSettings,
  Times,
  ValueText
} from '@widgets/forms/rental-car-form/rental-car-options';
import { AutocompleteDropdownSettings, AutocompleteSection } from '@shared/components/autocomplete-dropdown/autocomplete-data.interface';
import uniqBy from 'lodash/uniqBy';
import { NgbDateCustomParserFormatter } from '@app/widgets/services/datepicker-parser.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { RentalCarService } from '@widgets/services/rental-car.service';
import { RentalCarState } from '@app/widgets/interfaces/widgets-state.interface';
import { SearchWidgetsData } from '@offers/interfaces/search-widgets.interface';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { WidgetSearchAbstract } from '../widget-search.abstract';
import { WidgetsService } from '@widgets/services/widgets.service';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';

interface Airport {
  airport_code: string;
  airport_name: string;
}

@Component({
  selector: 'app-rental-car-search-widget',
  templateUrl: './rental-car-search.component.html',
  styleUrls: ['./rental-car-search.component.less', '../common-widget.style.less'],
})
export class RentalCarSearchComponent extends WidgetSearchAbstract implements OnInit, AfterViewInit, OnDestroy {

  @Input() customClass = '';
  @Input() widget: SearchWidgetsData;
  @Input() topIndent: boolean;

  @Output() clicked = new EventEmitter<SearchWidgetsData>();
  @Output() displayed = new EventEmitter<SearchWidgetsData>();

  pickUpAutocompleteDropdownSettings: AutocompleteDropdownSettings = RentalCarDropdownSettings;
  dropOffAutocompleteDropdownSettings: AutocompleteDropdownSettings = RentalCarDropOffDropdownSettings;
  autocompleteSections: AutocompleteSection[] = [];
  ages: ValueText[] = Ages;
  times: ValueText[] = Times;
  ageId = 25;
  pickUpTimeId = '12:00';
  dropOffTimeId = '12:00';
  groupTabIndex: number;
  pickUpValue: string;
  originName: string;
  pickUpSelectedOptionTitle: string;
  dropOffSelectedOptionTitle: string;
  dropOffValue = '';
  destinationName = '';
  airports: Airport[];
  cities;
  currentDate = new Date();
  minValueDropOff = new Date().toISOString().split('T')[0];
  minValuePickUp = new Date().toISOString().split('T')[0];
  url = '';
  isDroppedOff = false;
  destination_not_origin = '';
  closePickUpDropdown: boolean;
  closeDropOffDropdown: boolean;
  cachedAirports: Airport[] = [];
  stateKey: string;
  widgetState: RentalCarState;
  minDate: NgbDateStruct;
  pickUp: NgbDateStruct;
  dropOff: NgbDateStruct;
  pickUpDate: string;
  dropOffDate: string;

  constructor(
    public datepickerParser: NgbDateCustomParserFormatter,
    private rentalCarService: RentalCarService,
    private widgetsService: WidgetsService,
    private cdr: ChangeDetectorRef,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private widgetSuggestionService: WidgetSuggestionService
  ) {
    super();
    this.minDate = datepickerParser.toNgbDate(new Date());
    this.pickUp = datepickerParser.toNgbDate(new Date());
    this.dropOff = datepickerParser.toNgbDate(new Date(), 2);
  }

  ngOnInit(): void {
    this.intialValues();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  getPickUpAutocompleteValue(value: string): void {
    this.pickUpSelectedOptionTitle = value;
    void this.getAutocompleteValue(value);
  }

  getDropOffAutocompleteValue(value: string): void {
    this.dropOffSelectedOptionTitle = value;
    void this.getAutocompleteValue(value);
  }

  handlePickUpAutocomplete(): void {
    this.closePickUpDropdown = false;
    this.closeDropOffDropdown = true;
  }

  handleDropOffAutocomplete(): void {
    this.closeDropOffDropdown = false;
    this.closePickUpDropdown = true;
  }

  getPickUpValue(value: string): void {
    this.pickUpValue = value;
    this.originName = this.getDestinationPlace(value);
  }

  getDropOffValue(value: string): void {
    this.dropOffValue = value;
    this.destinationName = this.getDestinationPlace(value);
  }

  getDestinationPlace(value: string): string {
    return this.rentalCarService.getDestinationPlace(value, this.airports, this.cities);
  }

  dropOffChange(value: boolean): void {
    if (!value) {
      this.dropOffValue = '';
      this.dropOffAutocompleteDropdownSettings.inputString = '';
    }

    this.isDroppedOff = value;
    this.destination_not_origin = value ? 'on' : '';
  }

  selectChange({ name, value }): void {
    if (name === 'pickUpTimeId') {
      this.pickUpTimeId = value;
    }

    if (name === 'dropOffTimeId') {
      this.dropOffTimeId = value;
    }

    if (name === 'age') {
      this.ageId = value;
    }
  }

  search(): void {
    this.pickUpDate = this.datepickerParser.convertDateToFormat(this.datepickerParser.format(this.pickUp));
    this.dropOffDate = this.datepickerParser.convertDateToFormat(this.datepickerParser.format(this.dropOff));
    this.clicked.emit(this.widget);

    this.setWidgetState();
    this.sessionStorageService.setItem(this.stateKey, this.widgetState);
    void this.widgetSuggestionService.addSuggestedWidgets({
      data: this.widgetState,
      type: this.widget.widget_id,
      page_url: window.location.href,
      destination_url: this.widget.destination_url
    });
  }

  pickUpValueChange(e): void {
    this.pickUpValue = e.target.value;
  }

  // Only Edge hack.
  pickUpClick(e): void {
    if (!e.target.value) {
      this.pickUpValue = '';
      this.cdr.detectChanges();
    }
  }

  dropOffValueChange(e): void {
    this.dropOffValue = e.target.value;
  }

  // Only Edge hack.
  dropOffClick(e): void {
    if (!e.target.value) {
      this.dropOffValue = '';
      this.cdr.detectChanges();
    }
  }

  getPickUpSelectedItemTitle(selectedOption: string): void {
    this.pickUpSelectedOptionTitle = selectedOption;
  }

  getDropOffSelectedItemTitle(selectedOption: string): void {
    this.dropOffSelectedOptionTitle = selectedOption;
  }

  onDateSelection(date: NgbDateStruct, target: string): void {
    if (target === 'pickUp') {
      this.dropOff = this.datepickerParser.toNgbDate(new Date(this.datepickerParser.format(date)), 3);
      this.pickUp = date;
    } else {
      this.dropOff = date;
    }
  }

  ngOnDestroy(): void {
    this.setWidgetState();
    this.sessionStorageService.setItem<RentalCarState>(this.stateKey, this.widgetState);
  }

  private intialValues(): void {
    this.stateKey = `${this.widget.widget_id}_${this.getOrderedParams()}`;
    this.widgetState = this.sessionStorageService.getItem<RentalCarState>(this.stateKey);

    if (this.widgetState) {
      this.getWidgetState();
    } else {
      this.pickUpAutocompleteDropdownSettings.inputString = '';
      this.dropOffAutocompleteDropdownSettings.inputString = '';
    }
  }

  private async getAutocompleteValue(value: string): Promise<void> {
    const responseData = await this.rentalCarService.getRentalCarAutocompleteData(value);
    const mappedResponse = responseData && responseData.getCarAutoComplete && responseData.getCarAutoComplete.results
      ? this.rentalCarService.mapResponseData(responseData)
      : {};

    this.airports = mappedResponse && mappedResponse.airport_data ? mappedResponse.airport_data : [];
    this.cities = mappedResponse && mappedResponse.city_data ? mappedResponse.city_data : [];
    this.airports = [
      ...this.rentalCarService.getCitiesAirports(this.cities) as Airport[],
      ...this.airports
    ];
    this.cachedAirports = uniqBy([...this.airports, ...this.cachedAirports], 'airport_code');
    this.cachedAirports
      .filter(airport => airport.airport_name.toLowerCase().includes(value.toLowerCase()))
      .forEach(airport => {
        this.airports.push(airport);
      });
    this.airports = uniqBy([...this.airports], 'airport_code');
    this.autocompleteSections = this.rentalCarService.getAutocompleteSections(this.airports, this.cities);

    this.cdr.detectChanges();
  }

  private getWidgetState(): void {
    this.pickUpAutocompleteDropdownSettings.inputString = this.widgetState.placeName;
    this.pickUpSelectedOptionTitle = this.widgetState.placeName;
    this.isDroppedOff = this.widgetState.isDroppedOff;
    this.pickUpTimeId = this.widgetState.pickUpTimeId;
    this.pickUp = this.datepickerParser.toNgbDate(this.datepickerParser.handleTimezoneDiff(this.widgetState.pickUp.toString()));
    this.dropOff = this.datepickerParser.toNgbDate(this.datepickerParser.handleTimezoneDiff(this.widgetState.dropOff.toString()));
    this.dropOffTimeId = this.widgetState.dropOffTimeId;
    this.ageId = this.widgetState.age;
    this.pickUpValue = this.widgetState.pickUpValue;
    this.originName = this.widgetState.originName;
    this.destinationName = this.widgetState.destinationName;

    if (this.isDroppedOff) {
      this.dropOffChange(this.isDroppedOff);
      this.dropOffValue = this.widgetState.dropOffValue;
      this.dropOffSelectedOptionTitle = this.widgetState.dropOffTitle;
      this.dropOffAutocompleteDropdownSettings.inputString = this.widgetState.dropOffTitle;
    }
  }

  private setWidgetState(): void {
    this.widgetState = {
      placeName: this.pickUpSelectedOptionTitle,
      pickUpValue: this.pickUpValue,
      originName: this.originName,
      isDroppedOff: this.isDroppedOff,
      dropOffTitle: this.dropOffSelectedOptionTitle,
      dropOffValue: this.dropOffValue,
      pickUpTimeId: this.pickUpTimeId,
      pickUp: new Date(this.datepickerParser.format(this.pickUp)),
      dropOff: new Date(this.datepickerParser.format(this.dropOff)),
      dropOffTimeId: this.dropOffTimeId,
      age: this.ageId,
      destinationName: this.destinationName
    };
  }

  private getOrderedParams(): string {
    const orderedParams = Object.keys(this.activatedRoute.snapshot.queryParams)
      .filter(el => el !== 'tw_city_zip' && el !== 'tw_q')
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = this.activatedRoute.snapshot.queryParams[key];
          return obj;
        }, {}
      );

    return JSON.stringify(orderedParams);
  }

}
