/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import { HEIGHT_ANIMATION, OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { MapMarkerPreviewService } from '@app/shared/services/map-marker-preview.service';
import { Marker } from '@app/shared/interfaces/marker.interface';
import { MarkerSizes } from '../provider-locations/provider-locations.component';

@Component({
  selector: 'app-list-view-item',
  templateUrl: './list-view-item.component.html',
  styleUrls: ['./list-view-item.component.less'],
  animations: [HEIGHT_ANIMATION, OPACITY_FADE_IN_OUT_ANIMATION]
})

export class ListViewItemComponent {

  @Input() item: Marker;
  @Input() groupTabIndex: number;
  @Input() expandCard: boolean;
  @Input() markerGuid: string;
  @Input() activatedMarker: Marker;
  @Input() markers: Marker[];

  @ViewChild('ctaBtn') ctaBtn: ElementRef;

  public isCardExpanded: boolean;

  constructor(
    private directClickOutService: DirectClickOutService,
    private mapMarkerPreviewService: MapMarkerPreviewService
  ) { }

  get cityStateString(): string {
    return [this.item.city, this.item.state].join(', ');
  }

  public getType(): string {
    if (this.item.code) {
      return 'code';
    } else if (this.item.url || this.item.url_type === 'dynamic') {
      return 'external';
    }
    return 'none';
  }

  public onCtaClick(event: MouseEvent): void {
    event.cancelBubble = true;
    void this.directClickOutService.providerLocations.handleCtaClick(this.item);
    this.ctaBtn.nativeElement.blur();
    this.ctaBtn.nativeElement.closest('.item-container').focus();
  }

  // If the user focuses on offer cards, the current method should realize a logic of markers highlighting
  public handleMarkerHighlighting(event: MouseEvent, marker: Marker): void {
    const markerElement: HTMLElement = document.querySelector(`.location-marker.c${marker.guid}`);
    const findMarker = markerElement.className.split(' ').find(i => (i === `c${marker.guid}`)) === `c${marker.guid}`;

    const activatedMarker = this.markers.find(item => item.activated);
    if (activatedMarker && activatedMarker !== marker) {
      this.mapMarkerPreviewService.deactivateMarker(activatedMarker, this.markers);
    }
    if (findMarker) {
      this.handleMarkerSize(event, marker, markerElement);
    }
  }

  handleHighlightState(marker: Marker): void {
    marker.showMore = !marker.showMore;
    this.mapMarkerPreviewService.removeHighlightedState(marker.guid);
  }

  public getButtonTitle(): string {
    return this.item.ctaButtonTitle || 'GET OFFER';
  }

  public onLocationViewDetails(event: any): void {
    this.item.showMore = !this.item.showMore;
    if (event && this.item.showMore) {
      this.item.showMore = false;
    } else if (event && !this.item.showMore) {
      this.item.showMore = true;
    } else if (this.expandCard && (this.markerGuid === this.item.guid) && !this.item.showMore) {
      this.item.showMore = true;
    } else if (this.expandCard && this.item.showMore) {
      this.item.showMore = true;
    } else {
      this.item.showMore = false;
    }

    if (this.item.showMore) {
      this.directClickOutService.providerLocations.trackAnalyticsEvents('provider-location-view-details', this.item);
    }
  }

  /**
   * Increase/decrease size of the appropriate marker if the user hovered on offer card
   *
   * @param event : needs for identifying the event type. @event (mouseenter) - increase marker size, @event (mouseleave) - decrease marker size
   * @param marker
   * @param markerElement
   */
  private handleMarkerSize(event: MouseEvent, marker: Marker, markerElement: HTMLElement): void {
    const defaultMarker: HTMLElement = document.querySelector(`.default-marker.c${marker.guid}`);

    if (event.type === 'mouseenter') {
      this.mapMarkerPreviewService.handleMarkerSize(
        marker,
        MarkerSizes.hoverLarge,
        MarkerSizes.hoverLarge,
        MarkerSizes.hoverSmall,
        MarkerSizes.hoverSmall
      );

      if (defaultMarker) {
        defaultMarker.classList.add('preview-default-icon');
      } else {
        markerElement.classList.add('highlight-marker');
      }
    }

    if (event.type === 'mouseleave' && marker.activated === false) {
      this.unhighlightMarker(marker, defaultMarker, markerElement);
    } else if (event.type === 'mouseleave' && !marker.hasOwnProperty('activated')) {
      this.unhighlightMarker(marker, defaultMarker, markerElement);
    }
  }

  private unhighlightMarker(marker: Marker, defaultMarker: HTMLElement, markerElement: HTMLElement): void {
    this.mapMarkerPreviewService.handleMarkerSize(
      marker,
      MarkerSizes.initialLarge,
      MarkerSizes.initialLarge,
      MarkerSizes.initialSmall,
      MarkerSizes.initialSmall
    );

    if (defaultMarker) {
      defaultMarker.classList.remove('preview-default-icon');
    } else {
      markerElement.classList.remove('highlight-marker');
    }
  }
}
