/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import * as moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Inject,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from 'g3-common-ui';

import { Ad } from '@app/shared/models/ad.model';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ClickOutService } from '@shared/services/click-out.service';
import { ConfigService, EnrollmentInfo, MarketplaceColors } from '@shared/services/config.service';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { ModalAlertComponent } from '@app/shared/components/modal-alert/modal-alert.component';
import { SpecialOffersAsAds } from '@app/shared/interfaces/special-offers-as-ads.interface';
import { illustrationsBg, shapesBg } from './backgrounds';
import { ButtonSize } from '@app/shared/enums/button-size.enum';
import { PaylogixService } from '@shared/services/paylogix/paylogix.service';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';

@Component({
  selector: 'app-open-enrollment',
  templateUrl: './open-enrollment.component.html',
  styleUrls: ['./open-enrollment.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenEnrollmentComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() public openEnrollment: EnrollmentInfo;
  @Input() public groupTabIndex: number;
  @Input() public wideContainerClass = '';
  @Input() public wideContainerClassName = '';
  @Input() public isNonPaylogixCountry = false;

  public title = 'Open Enrollment';
  public anchorLink: string;
  public extendedByOECards = false;
  public specialOffersAsAds: SpecialOffersAsAds[] = [];
  public paylogixButtonOptions = {
    title: 'Complete Info',
    size: ButtonSize.Large
  };

  private readonly FULL_BACKGROUND_SIZES = {
    XS: 576,
    S: 768,
    M: 992,
    L: 1200
  };

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private homepageAdsService: HomepageAdsService,
    private clickOutService: ClickOutService,
    private configService: ConfigService,
    private readonly paylogixService: PaylogixService,
    private readonly accountConfirmationService: AccountConfirmationService,
    @Inject(WINDOW) private readonly window: WINDOW
  ) { }

  public get isPaylogixButton(): boolean {
    return this.paylogixService.marketplaceHasPaylogixId()
      && !this.paylogixService.userHasPaylogixId();
  }

  public get paylogixSubscriptionSumDeductions(): string {
    return sumBy(this.openEnrollment.paylogixSubscriptions || [], (item) => item.MonthlyPremium).toFixed(2);
  }

  public get isEnrollmentContent(): boolean {
    const contentFields = [
      'cta_primary_link',
      'headline',
      'cta_secondary_link',
      'headline',
      'help_info',
      'section_name',
      'date_visibility'
    ];

    return contentFields.some(field => this.openEnrollment[field]);
  }

  public get headlineFontClass(): string {
    const headlineLength = this.openEnrollment?.headline?.length ?? 0;

    if (headlineLength > 44) {
      return 'header-font-small';
    }

    if (headlineLength > 24) {
      return 'header-font-medium';
    }

    return '';
  }

  public get hasProducts(): number {
    return this.openEnrollment
      && this.openEnrollment.products
      && this.openEnrollment.products.length;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    setTimeout(() => this.scanOECards(), 400);
  }

  public ngOnInit(): void {
    this.anchorLink = this.homepageAdsService.getAnchorLink(`s_${this.title}`);
    this.filterSpecialOffers();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.openEnrollment) {
      this.filterSpecialOffers();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.scanOECards(), 400);
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  public showInfoModal(): void {
    this.trackAnalyticsEvent('enrollment-click-open-info-button', {});
    const modal = this.modalService.open(ModalAlertComponent,
      {
        centered: true,
        windowClass: 'alert-modal'
      });

    modal.componentInstance.content = {
      title: this.openEnrollment.section_name,
      text: this.openEnrollment.help_info,
      isButton: false,
    };
  }

  public getFormattedDateRange(start: Date, end: Date): any {
    const startDate = moment(moment.tz(start, 'America/Chicago'));
    const endDate = moment(moment.tz(end, 'America/Chicago'));

    if (moment(startDate).month() === moment(endDate).month() && moment(startDate).year() === moment(endDate).year()) {
      return moment(startDate).format('MMMM DD') + '-' + moment(endDate).format('DD, YYYY');
    } else {
      return moment(startDate).format('MMMM DD, YYYY') + '-' + moment(endDate).format('MMMM DD, YYYY');
    }
  }

  public trackAnalyticsEvent(action: string, data: any): void {
    /**
     * All actions:
     * - enrollment-click-primary-enroll +
     * - enrollment-click-secondary-enroll +
     * - enrollment-click-all-benefits -> tracks inside app-special-offers component
     * - enrollment-click-open-info-button +
     */

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel(action, data),
      new AnalyticsGAEventModel(action, {
        category: action,
        label: data,
      })
    ]);
  }

  public async clickOnPrimaryLink(event: MouseEvent): Promise<void> {
    this.trackAnalyticsEvent('enrollment-click-primary-enroll', { link: this.openEnrollment.cta_primary_link });
    if (this.openEnrollment.cta_primary_link) {
      void this.ctaLinkClicked(event, this.openEnrollment.cta_primary_link);
    }
  }

  public async clickOnSecondaryLink(event: MouseEvent): Promise<void> {
    this.trackAnalyticsEvent('enrollment-click-secondary-enroll', { link: this.openEnrollment.cta_secondary_link });
    if (this.openEnrollment.cta_secondary_link) {
      void this.ctaLinkClicked(event, this.openEnrollment.cta_secondary_link);
    }
  }

  public async clickOnPaylogixButton(event: Event): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    if (this.accountConfirmationService.knownUserType) {
      this.accountConfirmationService.checkAndOpenDialog();
      return;
    }

    await this.paylogixService.activateCompleteInfoModal();
    this.accountConfirmationService.openPaylogixProfileModal('Homepage');
  }

  public getSpecialOffersAsAds(products: Ad[]): SpecialOffersAsAds[] {
    return [{
      name: this.openEnrollment.enrollment_off_cat,
      title: this.openEnrollment.section_name,
      items: sortBy(products.map(i => {
        const r = new Ad(i);
        r.adUrl = '';
        r.id = i.guid;
        return r;
      }), ['paylogixSubscription']),
      linkTitle: this.openEnrollment.cta_view_all_text,
      linkUrl: this.openEnrollment.cta_view_all_link,
      ctaIsEbgLink: this.openEnrollment.cta_is_ebg,
      icon_outline: '/assets/icons/ic-enrollment.svg',
      category: this.openEnrollment.enrollment_off_cat,
      style: this.openEnrollment.style
    }];
  }

  public getTakeoverBackground(): string | SafeStyle {
    const colors: MarketplaceColors = this.configService.getOption('colors');

    switch (this.openEnrollment.full_background) {
      case 'shapes': {
        return this.getBase64SvgImage(shapesBg(colors.primary));
      }
      case 'illustrations': {
        return this.getBase64SvgImage(illustrationsBg(colors.primary));
      }
      case 'custom': {
        const innerWidth = this.window.innerWidth;
        let backgroundUrl = null;

        if (innerWidth <= this.FULL_BACKGROUND_SIZES.XS) {
          backgroundUrl = this.openEnrollment.full_background_url_xs;
        }

        if (!backgroundUrl && innerWidth <= this.FULL_BACKGROUND_SIZES.S) {
          backgroundUrl = this.openEnrollment.full_background_url_s;
        }
        if (!backgroundUrl && innerWidth <= this.FULL_BACKGROUND_SIZES.M) {
          backgroundUrl = this.openEnrollment.full_background_url_m;
        }
        if (!backgroundUrl && innerWidth <= this.FULL_BACKGROUND_SIZES.L) {
          backgroundUrl = this.openEnrollment.full_background_url_l;
        }
        return `url(${backgroundUrl || this.openEnrollment.full_background_url})`;
      }

      default: {
        return 'url(/assets/takeover_bgr.jpg)';
      }
    }
  }

  public getBase64SvgImage(svg: string): SafeStyle {
    const base64Svg = window.btoa(svg);
    return this.sanitizer.bypassSecurityTrustStyle(`url('data:image/svg+xml;base64, ${base64Svg}')`);
  }

  public isIllustrations(): boolean {
    return this.openEnrollment.full_background === 'illustrations';
  }

  public getSectionIconImageSrc(): string {
    if (this.openEnrollment.section_icon === 'custom') {
      return this.openEnrollment.section_icon_url;
    } else {
      return `/assets/icons/ic-${this.openEnrollment.section_icon || 'banner'}.svg`;
    }
  }

  private filterSpecialOffers(): void {
    if (!this.hasProducts) {
      return;
    }

    this.openEnrollment.products = this.openEnrollment.products
      .filter(product => !(product.isPaylogixAd && this.isNonPaylogixCountry));

    this.specialOffersAsAds = this.getSpecialOffersAsAds(this.openEnrollment.products);
  }

  private scanOECards(): void {
    const oeCards = document.querySelectorAll('.special-offers-item-body > app-ad-item');
    this.extendedByOECards = !!(oeCards?.length && oeCards.length > 2);
  }

  private async ctaLinkClicked(event: MouseEvent, link: string): Promise<void> {
    const isPaylogixTokenPresent = link.includes(this.paylogixService.paylogixUrlToken);
    if (this.paylogixService.marketplaceHasPaylogixId() && isPaylogixTokenPresent) {
      if (this.isNonPaylogixCountry) {
        return;
      }

      if (this.paylogixService.userHasPaylogixId()) {
        this.clickOutService.handleHomepageTakeoverCtaClickOut();
      } else {
        if (this.accountConfirmationService.knownUserType) {
          event.stopPropagation();
          event.preventDefault();
          this.accountConfirmationService.checkAndOpenDialog();
          return;
        }

        void this.clickOnPaylogixButton(event);
      }
    } else {
      await this.clickOutService.clickOut(link, '', {}, true);
    }
  }

}
