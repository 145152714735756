import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';

@Injectable()
export class AdobeNearMeMapsPageDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService
  ) { }

  async setNearMeMapsPageData(): Promise<void> {
    await this.adobeDataCollectorService.setPageData({
      name: 'maps view',
      section: 'maps view',
      type: 'g3 maps view',
      hierarchy: this.adobeDataCollectorService.setHierarchy('Near Me Page'),
    });
  }
}
