import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeExceptJavascript'
})
export class SafeExceptJavascriptPipe implements PipeTransform {

  constructor(
    protected sanitizer: DomSanitizer
  ) { }

  public transform(value: string = ''): SafeHtml {
    const newHtml = value.replace(/(<script)[^]+(script>)/mgi, '');
    return this.sanitizer.bypassSecurityTrustHtml(newHtml);
  }

}
