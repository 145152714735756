/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { AnalyticsService } from '@shared/services/analytics.service';
import { ClickOutService } from '@shared/services/click-out.service';
import { CouponModalComponent } from '@shared/components/coupon-modal/coupon-modal.component';
import { Injectable } from '@angular/core';
import { Marker } from '@app/listing/interfaces/marker.interface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhoneCtaService } from '@shared/services/phoneCta.service';
import { ProviderLocationsAnalyticsService } from '@shared/services/analytics/provider-locations-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class DirectClickOutProviderLocationsService {

  constructor(
    private modalService: NgbModal,
    private clickOutService: ClickOutService,
    private analyticsService: AnalyticsService,
    private providerLocationsAnalytics: ProviderLocationsAnalyticsService,
    private phoneCtaService: PhoneCtaService,
  ) { }

  getCouponModal(): NgbModalRef {
    return this.modalService.open(CouponModalComponent, {
      size: 'sm',
      centered: true,
      windowClass: 'custom-modal coupon-modal'
    });
  }

  async handleCtaClick(item: Marker) {

    const analyticEvents = this.providerLocationsAnalytics.getAnalyticsEvents('provider-location-cta-click', item) || [];
    if (this.isDisplayModal(item)) {
      // ------ Need for handling marker preview
      item.showPreviewMarker = false;

      const mapMarkers = document.querySelectorAll('.mapboxgl-marker');
      if (mapMarkers) {
        mapMarkers.forEach(el => {
          const pin = el as HTMLElement;
          pin.style.zIndex = 'auto';
        });
      }
      // ------

      const modal = this.getCouponModal();
      modal.componentInstance.brandLogo = item.info_label;
      modal.componentInstance.code = item.code;
      modal.componentInstance.providerLocation = item;
      modal.componentInstance.modalEvents.subscribe(async event => this.handleModalEvents(event, item));
      modal.componentInstance.phoneAction.subscribe(url => this.phoneCtaService.phoneCtaClick(url, item));
      modal.componentInstance.phoneClick.subscribe(phone => this.phoneCtaService.phoneClick(phone, item));
      this.analyticsService.eventsTrack(this.providerLocationsAnalytics.getAnalyticsEvents('provider-location-reveal-code', item));
    } else if (item.url_type === 'dynamic') {
      analyticEvents.push(
        ...this.providerLocationsAnalytics.getAnalyticsEvents('provider-location-cta-click-out', item)
      );
      await this.clickOutService.clickOut('', 'provider_location_dynamic', {
        analyticEvents, guid: item.guid,
        data: { logo: item.info_label, brand: item.name, guid: item.guid }
      }, !item.is_ebg);
    } else {
      analyticEvents.push(
        ...this.providerLocationsAnalytics.getAnalyticsEvents('provider-location-cta-click-out', item)
      );
      await this.clickOutService.clickOut(item.url, null, {
        analyticEvents,
        data: { logo: item.info_label, brand: item.name, guid: item.guid }
      }, !item.is_ebg);
    }
  }

  async handleModalEvents(event, item: Marker) {
    if (event && event.type) {
      switch (event.type) {
        case 'click-copy-code':
          this.analyticsService.eventsTrack(this.providerLocationsAnalytics.getAnalyticsEvents('provider-location-copy-code', item));
          break;
        case 'cta-button-click':
          const analyticEvents = this.providerLocationsAnalytics.getAnalyticsEvents('provider-location-cta-click-out', item);
          if (item.url_type === 'dynamic') {
            await this.clickOutService.clickOut('', 'provider_location_dynamic', {
              analyticEvents, guid: item.guid,
              data: { logo: item.info_label, brand: item.name, guid: item.guid }
            }, !item.is_ebg);
          } else {
            await this.clickOutService.clickOut(item.url, null, {
              analyticEvents,
              data: { logo: item.info_label, brand: item.name, guid: item.guid }
            }, !item.is_ebg);
          }
          break;
      }
    }
  }

  isDisplayModal(item: Marker): boolean {
    return !!item.code;
  }

  trackAnalyticsEvents(action, item: Marker = null, eventData?: any): any {
    this.analyticsService.eventsTrack(this.providerLocationsAnalytics.getAnalyticsEvents(action, item, eventData));
  }
}
