import { Component, Input } from '@angular/core';
import { BrandTagsDictionaryResponse } from '@app/shared/interfaces/brands.interface';

@Component({
  selector: 'app-brand-item-name',
  templateUrl: './brand-item-name.component.html',
  styleUrls: ['./brand-item-name.component.less']
})
export class BrandItemNameComponent {
  @Input() brand: BrandTagsDictionaryResponse;
}
