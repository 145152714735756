<a class="item"
   (click)="emitClick($event)"
   (keyup.enter)="emitClick($event)"
   role="presentation">
  <div class="ico {{iconClass}}"
       role="presentation">
    <img src="{{ iconImage }}"
         *ngIf="iconImage"
         alt=""
         loading="lazy"
         role="presentation" />
  </div>
  <div class="text"
       role="presentation">
    <ng-content></ng-content>
  </div>
</a>
