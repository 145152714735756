/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ConfigService } from './config.service';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'g3-common-ui';
import { STORE_WA, OFFERS_BP } from '../constants/url.constants';

const _isExternalUrl = (url: string): boolean => url && (url.includes('http:') || url.includes('https:'));

const gotoUrlDomains = ['amazon.com', 'amazon.to', 'amzn.to'];

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(private configService: ConfigService, @Inject(WINDOW) private window: WINDOW) {}

  public isExternalUrl(url: string): boolean {
    return _isExternalUrl(url);
  }

  public isRequiredGotoUrl(inputUrl: string): boolean {
    if (!inputUrl) {
      return false;
    }
    if (!gotoUrlDomains) {
      return false;
    }
    return gotoUrlDomains
      .map(domain => new RegExp(`^http(s)?\:\/\/(www.)?${domain.replace('.', '\.')}`))
      .some(regex => regex.test(inputUrl));
  }

  public getHostName(url: string): string {
    const getURLObject = new URL(url, this.window.location.href);
    const urlRegex = /^(?:https?:\/\/)?(?:www\.)?/i;
    return getURLObject.hostname.replace(urlRegex, '');
  }

  public getRefererHopUrl(url: any): string {
    if (!_isExternalUrl(url)) {
      return url;
    }
    if (this.isRequiredGotoUrl(url)) {
      const gotoUrlPath = this.configService.getGotoUrlPath();
      return url ? gotoUrlPath + '?goto-url=' + encodeURIComponent(url) : '';
    }
    return url;
  }

  public isWorkingAdvantage(url: string): boolean {
    return url.includes('workingadvantage');
  }

  public isCorporateSite(url: string): boolean {
    return url.includes(STORE_WA) || url.includes(OFFERS_BP);
  }

  public getHostURL(url: string): string {
    return url.split('/').slice(0, 3).join('/');
  }
}
