import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  private storage: Storage = window.localStorage;

  public getItem<T>(key: string): T {
    return JSON.parse(this.storage.getItem(key));
  }

  public setItem<T>(key: string, data: T): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public getStorage(): Storage {
    return this.storage;
  }
}
