/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AdsAnalyticsService } from '@shared/services/analytics/ads-analytics.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ClickOutService } from '@shared/services/click-out.service';
import { Injectable } from '@angular/core';
import { OffersAnalyticsService } from '@shared/services/analytics/offers-analytics.service';
import { Router } from '@angular/router';

@Injectable()
export class PhoneCtaService {

  constructor(
    private router: Router,
    private clickOutService: ClickOutService,
    private offersAnalytics: OffersAnalyticsService,
    private adsAnalyticsService: AdsAnalyticsService,
    private analyticsService: AnalyticsService,
  ) {
  }

  phoneClick(phone: string, data: any): void {
    this.analyticsService.eventsTrack(this.offersAnalytics.getAnalyticsEvents('click-out-phone', data, null, true));
  }

  phoneCtaClick(url: string, data: any, isAds = false): void {
    if (!this.isExternalUrl(url)) {
      void this.router.navigateByUrl(url);

      return;
    }

    if (isAds) {
      void this.clickOutService.clickOut(url, 'offer_static', {
        guid: data.guid,
        analyticEvents: this.adsAnalyticsService.getAnalyticsEvents('click-out-phone-cta', data)
      }, true);
    } else {
      void this.clickOutService.clickOut(url, 'offer_static', {
        guid: data.guid,
        analyticEvents: this.offersAnalytics.getAnalyticsEvents('click-out-phone-cta', data)
      }, true);
    }
  }

  private isExternalUrl(url: string): boolean {
    return url.includes('http:') || url.includes('https:');
  }
}
