import { Injectable } from '@angular/core';

@Injectable()
export class ShareInfoService {

  public copyInfo(info: string): void {
    let textarea = null;
    textarea = document.createElement('textarea');
    textarea.style.height = '0px';
    textarea.style.left = '-100px';
    textarea.style.opacity = '0';
    textarea.style.position = 'fixed';
    textarea.style.top = '-100px';
    textarea.style.width = '0px';
    document.body.appendChild(textarea);
    textarea.value = info;
    textarea.select();
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNodeContents(textarea);
    range.selectNode(textarea);
    selection.addRange(range);
    document.execCommand('copy');
    if (textarea && textarea.parentNode) {
      textarea.parentNode.removeChild(textarea);
    }
    selection.removeAllRanges();
  }
}
