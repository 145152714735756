import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KnownUserTypes } from 'g3-common-ui';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { ProfileResponse } from '@shared/services/profile/profile.interfaces';

interface IDynamicUrlsBody {
  urls: string[];
  known_user_email?: string;
  known_user_guid?: string;
  is_parent?: string;
  user_guid?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokensService {
  private profileData: ProfileResponse;

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
    private profileService: ProfileService,
  ) {
    void this.getProfile();
  }

  isTokenPresent(text: string = '', token: string): boolean {
    return text?.toLowerCase().includes(`%${token.toLowerCase()}%`);
  }

  async getPopulatedDynamicURLs(urls: string[]): Promise<string[]> {
    const body: IDynamicUrlsBody = { urls };

    if (!urls || !urls.length) {
      return [];
    }

    if (this.permissionService.hasDefined('known:access')) {
      let parentGuid = '';
      body.known_user_email = this.knownUserService.knowUserEmail;

      if (this.knownUserService.knowUserType === KnownUserTypes.CHILD) {
        parentGuid = this.knownUserService.knowUserChildDlk.split('~')[0];
      } else {
        parentGuid = this.knownUserService.knowUserGuid;
        body.is_parent = 'true';
      }
      body.known_user_guid = parentGuid;
    }

    body.user_guid = this.profileData.guid || '';

    return this.http.post<string[]>(`${environment.apiUrl}/api/dynamic-urls`, body).toPromise();
  }

  private async getProfile(): Promise<void> {
    this.profileData = await this.profileService.getProfile().toPromise();
  }

}
