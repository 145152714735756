export const supportOptions = [
  /* {
    name: 'FAQs',
    icon: '/assets/icons/ic-help_faq-small.svg',
    href: '/common/help/faq'
  }, HTB removed because faq pages not built yet*/
  {
    name: 'Contact Us',
    icon: '/assets/icons/ic-help_contact-small.svg',
    href: '/common/help/contact'
  },
  {
    name: 'Nominate a Brand',
    icon: '/assets/icons/icn-nominate.svg',
    href: ' /common/help/contact?tab=nominations'
  }
];
