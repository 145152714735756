import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import every from 'lodash/every';
import { WINDOW } from 'g3-common-ui';
import { ConfigService, VendorSettings } from '@shared/services/config.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { Ad } from '@shared/models/ad.model';
import { VERIFICATION_PROFILE_FIELDS_MAPPING } from './paylogix-fields-mappings.constant';
import { HttpClient } from '@angular/common/http';
import { PaylogixAmount } from '@shared/enums/paylogix-amount.enum';
import { Breakpoints } from '@shared/enums/breakpoints.enum';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { PaylogixPipe } from '@shared/pipes/paylogix.pipe';
import { OfferExternalData, OfferModel } from '@shared/models/offer.model';

@Injectable({
  providedIn: 'root'
})
export class PaylogixService {

  public paylogixUrlToken = '%paylogix%';

  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly configService: ConfigService,
    private readonly formatDatePipe: FormatDatePipe,
    private readonly paylogixPipe: PaylogixPipe,
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    @Inject(WINDOW) protected window: WINDOW,
  ) { }

  /**
   * Makes an HTTP request to the matcher API
   *
   * @param userGuids - User guids to match
   * @param censusDate - Date of census file sync
   * @returns - Promise with a match result
   */
  public async matchUsers(userGuids: string[], censusDate?: Date): Promise<{ successful: number; unsuccessful: number }> {
    return this.httpClient.post<{ successful: number; unsuccessful: number }>(
      '/api/paylogix-user/paylogix-match-by-user-guid',
      {
        user_guids: userGuids,
        census_date: censusDate
      }
    ).toPromise();
  }

  public async activateCompleteInfoModal(): Promise<void> {
    const scrollPosition = this.window.scrollY;
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { prompt: 'pylgx' },
      queryParamsHandling: 'merge',
      state: {
        triggeredByPaylogix: true,
      }
    });

    if (this.window.innerWidth >= Breakpoints.Medium) {
      this.window.scrollTo(0, scrollPosition);
    }
  }

  public isOfferCardPaylogix(item: Ad): boolean {
    return item.external_source === 'paylogix';
  }

  public isPaylogixPromptAvailable(): boolean {
    const vendorSettings = this.configService.getOption('vendor_settings', { paylogix_data: [], paylogix_verification_fields: [] });
    const profile = this.profileService.profile.getValue();

    const skipPrompt = profile.countryLast !== 'US'
      || this.profileService.isGuestOrKnownUser()
      || !this.marketplaceHasPaylogixId()
      || isEmpty(vendorSettings.paylogix_verification_fields);

    if (skipPrompt) {
      return false;
    }

    const areAllVerificationFieldsFilled = every(
      vendorSettings.paylogix_verification_fields,
      field => !!get(profile, VERIFICATION_PROFILE_FIELDS_MAPPING[field])
    );

    return !areAllVerificationFieldsFilled;
  }

  public isPaylogixAvailable(): boolean {
    return !this.profileService.isGuestOrKnownUser();
  }

  public marketplaceHasPaylogixId(): boolean {
    const vendorSettings = this.configService.getOption('vendor_settings', { paylogix_data: [] });
    return vendorSettings?.paylogix_data?.length > 0;
  }

  public userHasPaylogixId(): boolean {
    const profile = this.profileService.profile.getValue();
    return profile.vendorSettings
      ? !!profile.vendorSettings.paylogixParticipantId
      : false;
  }

  public getDefaultPaylogixOrgId(vendorSettings: VendorSettings): string {
    return vendorSettings?.paylogix_data?.find(i => i.default === true)?.org_id?.trim()
      || vendorSettings?.paylogix_data[0]?.org_id?.trim()
      || '';
  }

  public isOfferMatchMarketplaceOrgId(offer: OfferModel): boolean {
    const vendorSettings = this.configService.getOption<VendorSettings>('vendor_settings');
    const defaultMarketplaceOrgId = this.getDefaultPaylogixOrgId(vendorSettings);
    const offerOrgId = offer?.external_data?.org_id?.trim();
    return defaultMarketplaceOrgId === offerOrgId;
  }

  public getFormattedPaylogixProductData(ad: Ad): string[] {
    const products = [];
    if (isEmpty(ad.paylogixSubscription)) {
      if (this.isProductValueProvided(ad.external_data, PaylogixAmount.Benefit)) {
        const benefitAmount = this.paylogixPipe.transform([ad.external_data.benefit_min, ad.external_data.benefit_max], PaylogixAmount.Benefit);

        if (benefitAmount) {
          products.push(`Benefit Amount: ${benefitAmount}`);
        }
      }
      if (this.isProductValueProvided(ad.external_data, PaylogixAmount.Premium)) {
        const premiumAmount = this.paylogixPipe.transform(
          [ad.external_data.premium_min, ad.external_data.premium_max],
          PaylogixAmount.Premium
        );

        if (premiumAmount) {
          products.push(`Cost Amount: ${premiumAmount}`);
        }
      }
    } else {
      const monthlyCost = ad.paylogixSubscription && ad.paylogixSubscription.AnticipatedModalPremium
        ? this.paylogixPipe.transform([String(ad.paylogixSubscription.MonthlyPremium.toFixed(2))])
        : null;
      const effectiveDate = ad.paylogixSubscription && ad.paylogixSubscription.PolicyEffectiveDate
        ? this.formatDatePipe.transform(ad.paylogixSubscription.PolicyEffectiveDate, 'MM/DD/YY', 'YYYY-MM-DDThh:mm:ss', false)
        : null;
      const endDate = ad.paylogixSubscription && ad.paylogixSubscription.LastPeriodEnd
        ? this.formatDatePipe.transform(ad.paylogixSubscription.LastPeriodEnd, 'MM/DD/YY', 'YYYY-MM-DDThh:mm:ss', false)
        : null;

      if (monthlyCost) {
        products.push(`Cost Monthly: ${monthlyCost}`);
      }

      if (effectiveDate) {
        products.push(`Effective Date: ${effectiveDate}`);
      }

      if (endDate) {
        products.push(`End Date: ${endDate}`);
      }
    }

    return products;
  }

  private isProductValueProvided(externalData: OfferExternalData, amountType: PaylogixAmount): boolean {
    return externalData
      && (!!externalData[`${amountType}_min`] || !!externalData[`${amountType}_max`]);
  }

  private convertNumberFormat(value: string): string {
    const asNumber = Number(value);

    return asNumber && asNumber.toFixed(2) || value;
  }
}
