import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BrandTagsDictionaryResponse } from '@app/shared/interfaces/brands.interface';
import { Ad } from '@app/shared/models/ad.model';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { FilterStateService } from '@app/shared/services/search/filter-state.service';
import { Brand, SearchService } from '@app/shared/services/search/search.service';
import { ZoneSectionElement } from '@app/zones/interfaces/zones-search-response.interface';
import { ZoneHeaderService } from '@app/zones/services/zone-header.service';
import { WindowHelper } from 'g3-common-ui';

@Component({
  selector: 'app-top-brands-names',
  templateUrl: './top-brands-names.component.html',
  styleUrls: ['./top-brands-names.component.less']
})
export class TopBrandsNamesComponent implements OnInit, AfterViewInit {

  @Input() public zoneElement: ZoneSectionElement;
  @Input() public wideContainerClass = '';
  @Input() public wideContainerClassName = '';
  @Input() public groupTabIndex: number;
  @Input() isPinnedZone = false;

  @Output() public displayed = new EventEmitter<ZoneSectionElement>();
  @Output() public initialized = new EventEmitter<ZoneSectionElement>();
  @Output() public clickedItem = new EventEmitter<Ad>();

  public topBrands: BrandTagsDictionaryResponse[];
  public hideArrows = false;

  public hovered = true;
  public showSection = true;
  public anchorLink = '';
  public isDesktop = false;
  public hideGhostEffect = false;

  private brands: Brand[] = [];

  public constructor(
    private homepageAdsService: HomepageAdsService,
    private readonly filterStateService: FilterStateService,
    private readonly router: Router,
    private readonly searchService: SearchService,
    private readonly zoneHeaderService: ZoneHeaderService,
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    const target = event.target as Window;
    const adsLength = this.topBrands.length;

    this.hideArrows = target.innerWidth >= WindowHelper.SIZE_M
      && target.innerWidth < WindowHelper.SIZE_L && adsLength === 4
      || target.innerWidth >= WindowHelper.SIZE_L && adsLength === 5;
  }

  public async ngOnInit(): Promise<void> {
    await this.getTopBrands();

    if (this.zoneElement) {
      this.initZoneContent();
    }
  }

  public ngAfterViewInit(): void {
    if (this.zoneElement) {
      this.displayed.emit(this.zoneElement);
    }

    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }


  public onBrandClick(brandTag: BrandTagsDictionaryResponse, event: Event): void {
    if (event) {
      event.preventDefault();
    }

    const brand = this.getBrandById(brandTag.tag_id);
    if (brand?.count === 1 && brand.offer_guid) {
      void this.router.navigate(['/offers', brand.offer_guid]);
      return;
    }

    const queryParams = {
      b: encodeURIComponent(brandTag.tag_id),
      orderBy: 'trending'
    };

    this.searchService.filter = {};
    this.searchService.search('', { vendor: [brandTag.tag_id] }, false);
    void this.router.navigate(['/offers'], { queryParams });

    this.filterStateService.emitSearchEvent();

  }

  public isWideContainer(): boolean {
    return this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  public showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.zoneElement.is_ebg);
  }

  private getBrandById(id: string): Brand | undefined {
    return this.brands.find(brand => brand.tag_id === id);
  }

  private async getTopBrands(): Promise<void> {
    const idsFromAds = this.zoneElement.ads?.flatMap(({ brandTagDisplay }) => brandTagDisplay).filter(t => !!t);
    const uniqIds = Array.from(new Set(idsFromAds));

    const brandTags = await this.homepageAdsService.getBrandTags(uniqIds);
    const brands = await this.searchService.getBrands(uniqIds);

    this.topBrands = brandTags.filter(item => item.status === 'active');
    this.brands.push(...brands.items);
  }

  private initZoneContent(): void {
    const adsLength = this.zoneElement?.ads?.length || 0;
    this.hideArrows = this.isPinnedZone && (window.innerWidth >= WindowHelper.SIZE_M && window.innerWidth < WindowHelper.SIZE_L && adsLength === 4)
      || (window.innerWidth >= WindowHelper.SIZE_L && adsLength === 5);
    this.initialized.emit(this.zoneElement);

    if (this.zoneElement.name) {
      const sectionName = `s_${this.zoneElement.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_cards_scroll';
    }
  }
}
