import { Injectable } from '@angular/core';
import { WINDOW } from 'g3-common-ui';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CjLinksService {
  scriptInjected = false;

  private cjLinks: string[] = [];
  private initialized: Promise<void>;

  constructor(
    private readonly configService: ConfigService,
    private readonly window: WINDOW
  ) {
    this._init();
  }

  isCJLink(url: string): boolean {
    return this.cjLinks.some(link => url.startsWith(link));
  }

  async handleCJLink(url: string): Promise<void> {
    if (!this.scriptInjected) {
      this.initialized = this.injectCJScript();
      this.scriptInjected = true;
    }
    return this.initialized.then(() => {
      try {
        this.window.cj.navigate(url);
      } catch (err) {
        console.error(err);
      }
    });
  }

  private _init(): void {
    this.cjLinks = this.configService.getOption<string[]>('cj_links', []);
  }

  private async injectCJScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.p.zjptg.com/tag/2712644/100001';
      script.onload = (): void => resolve();
      script.onerror = (): void => reject();
      document.body.appendChild(script);
    });
  }
}
