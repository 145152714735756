export const ZOOM_IN_SVG =
  `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="9.69238" width="20" height="2.49231" rx="1.24615" fill="currentColor"/>
    <rect x="8.75" y="0.969238" width="2.5" height="19.9385" rx="1.25" fill="currentColor"/>
  </svg>`;

export const ZOOM_OUT_SVG =
  `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="8.81543" width="20" height="2.49231" rx="1.24615" fill="currentColor"/>
  </svg>`;
