import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KnownUserService } from '@app/shared/services/known-user.service';
import { SUCHelper } from '@app/shared/helpers/suc.helper';

export interface SingleUseCodeResponse {
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class SingleUseCodeService {

  constructor(
    private http: HttpClient,
    private knownUserService: KnownUserService
  ) { }

  getCode(offerId: string): Observable<SingleUseCodeResponse> {
    let query = '';
    if (this.knownUserService.knowUserGuid) {
      query += `user_guid=${this.knownUserService.knowUserGuid}`;
    } else if (this.knownUserService.knowUserChildDlk) {
      query += `user_guid=${this.knownUserService.knowUserChildDlk}`;
    }

    return this.http.get<SingleUseCodeResponse>(`${environment.apiUrl}/api/offers/${offerId}/suc${query ? '?' + query : ''}`);
  }

  replaceSucToken(text: string, coupon: string): string {
    return text.replace(/%suc-.+%/gi, coupon);
  }

  isExternalUrlWithSucToken(url: string, code: string): boolean {
    return SUCHelper.isExternalUrlWithSucToken(url, code);
  }

  containsSucCode(code: string): boolean {
    return this.containsSucOffer(code) || SUCHelper.containsSucToken(code);
  }

  containsSucOffer(code: string): boolean {
    if (!code) {
      return false;
    }

    const sucPart = code.split('^')[0];
    const sucPattern = '^SUC$';
    return new RegExp(sucPattern).test(sucPart);
  }

}
