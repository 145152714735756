<div [appAutoTabIndex]="groupTabIndex"
     class="open-enrollment-wrapper card-wrapper"
     (mouseleave)="onMouseLeave()"
     [class.hover-out]="mouseJustLeft"
     role="presentation"
     [attr.id]="anchorLink">
  <p class="dates">{{getEnrollmentDates()}}</p>
  <p class="section-name"
     [class.section-name-small]="hasLargeSectionName()">
    {{sectionName}}:
    <br />
    Get Ready!
  </p>
  <div class="buttons-container"
       (appClickOutside)="hideRemindMeMenu()">
    <button class="btn btn-primary more-info"
            aria-label="More info"
            [appAutoTabIndex]="groupTabIndex"
            (click)="openMoreInfoModal()">
      More info
    </button>
    <button class="btn btn-primary remind-me"
            aria-label="Remind me"
            [appAutoTabIndex]="groupTabIndex"
            (click)="remindMe()">
      Remind me
    </button>
    <ul class="submenu"
        [class.submenu-expanded]="showScheduleSubmenu"
        role="menu">
      <span class="submenu-title">Add to calendar</span>

      <li *ngFor="let item of scheduleSubmenu">
        <a role="link"
           (click)="item.click()">
          <img [src]="item.icon"
               loading="lazy"
               class="submenu-icon"
               alt=""
               role="presentation" />
          <span>{{item.title}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
