export const imageOptions = {
  cardsFixed: {
    simple: {
      XS: { w: 242, h: 96 },
      S: { w: 340, h: 160 },
      M: { w: 222, h: 160 },
      OTHER: { w: 270, h: 160 }
    },
    doubled: {
      XS: { w: 500, h: 160 },
      S: { w: 698, h: 310 },
      M: { w: 462, h: 310 },
      OTHER: { w: 558, h: 310 },
    },
  },
  cardsScroll: {
    simple: {
      XS: { w: 222, h: 96 },
      S: { w: 287, h: 160 },
      M: { w: 222, h: 160 },
      OTHER: { w: 266, h: 160 },
    },
    doubled: {
      XS: { w: 460, h: 216 },
      S: { w: 592, h: 310 },
      M: { w: 462, h: 310 },
      OTHER: { w: 545, h: 310 },
    }
  }
};
export const imageOptionsWithInput = {
  cardsFixed: {
    simple: {
      XS: { w: 242, h: 96 },
      S: { w: 340, h: 310 },
      M: { w: 222, h: 160 },
      OTHER: { w: 222, h: 310 }
    },
    doubled: {
      XS: { w: 500, h: 310 },
      S: { w: 698, h: 310 },
      M: { w: 461, h: 310 },
      OTHER: { w: 551, h: 310 },
    },
  },
  cardsScroll: {
    simple: {
      XS: { w: 222, h: 96 },
      S: { w: 218, h: 310 },
      M: { w: 266, h: 310 },
      OTHER: { w: 263, h: 310 },
    },
    doubled: {
      XS: { w: 460, h: 216 },
      S: { w: 455, h: 310, },
      M: { w: 500, h: 310 },
      OTHER: { w: 545, h: 310 },
    }
  }
};
export const logoOptionsSmall = { w: 46, h: 46, alternate: true };
export const logoOptionsMedium = { w: 70, h: 70, alternate: true };
export const logoOptionsLarge = { w: 100, h: 100, alternate: true };
