import { AdditionalOption, DefaultOptions } from '../constants/unsubscribe-options.constant';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RouterData } from '../interfaces/router-data.interface';
import { SubscriptionResponse } from '../interfaces/subscriptions-response';
import { UnsubscribeFormType } from '../enums/form-type.enum';
import { UnsubscribeOption, UnsubscribeOptionContent } from '../interfaces/subscription-options.interface';

@Injectable()
export class UnsubscribeHandlerService {

  constructor() { }

  getRouterData(params: Params, queryParams: Params): RouterData {
    return {
      path: {
        subdomain: params && params.subdomain ? params.subdomain : '',
        userdata: params && params.userdata ? params.userdata : ''
      },
      query: {
        sid: queryParams && queryParams.sid ? queryParams.sid : '',
        btag: queryParams && queryParams.btag ? queryParams.btag : '',
        ctag: queryParams && queryParams.ctag ? queryParams.ctag : ''
      }
    };
  }

  mapResponseData(response: SubscriptionResponse[]): UnsubscribeOption[] {
    const defaultUnsubscribeOptions: UnsubscribeOption[] = DefaultOptions;

    if (response && response.length && defaultUnsubscribeOptions.length === 2) {
      const additionalUnsubscribeOption: UnsubscribeOption = AdditionalOption;
      additionalUnsubscribeOption['formType'] = this.getFormType(response);

      const subscriptionName = response && response[0].subscription && response[0].subscription.length ? response[0].subscription[0].name : '';
      additionalUnsubscribeOption['subheader'] = `The email you received was from the email series,
        <strong>${subscriptionName}</strong>. Please indicate if you would like to update this subscription.`;
      additionalUnsubscribeOption.content = this.getAdditionalOptionContent(response, additionalUnsubscribeOption.formType);
      defaultUnsubscribeOptions.unshift(additionalUnsubscribeOption);
    }

    return defaultUnsubscribeOptions;
  }

  getFormType(response: SubscriptionResponse[]): string {

    const subscriptionObject = response.find(i => i.subscription);
    const subNamesObject = response.find(i => i.sub_names);
    const brandsObject = response.find(i => i.brands);
    const categoryObject = response.find(i => i.categories);
    const isFamily = subNamesObject && subNamesObject.sub_names && subNamesObject.sub_names.length
      && subscriptionObject && subscriptionObject.subscription && subscriptionObject.subscription.length;
    const isExclusions = brandsObject && brandsObject.brands && brandsObject.brands.length
      || categoryObject && categoryObject.categories && categoryObject.categories.length;

    if (isFamily && isExclusions) {
      return UnsubscribeFormType.Complex;
    } else if (isFamily && !isExclusions) {
      return UnsubscribeFormType.Family;
    } else {
      return UnsubscribeFormType.Standard;
    }
  }

  getAdditionalOptionContent(response: SubscriptionResponse[], formType: string): UnsubscribeOptionContent {

    const content: UnsubscribeOptionContent = { subscription: [] };
    const isComplex = formType === UnsubscribeFormType.Complex;
    if (isComplex) {
      content['exclusions'] = [];
    }

    const subNamesObject = response.find(i => i.sub_names);
    if (subNamesObject && subNamesObject.sub_names && subNamesObject.sub_names.length) {
      subNamesObject.sub_names.forEach(sub_name => {
        content.subscription.push({
          name: sub_name.name,
          value: sub_name.key,
          key: 'sub_name',
          id: sub_name.key
        });
      });
    }

    const unsubscribeObject = response.find(i => i.subscription);
    if (unsubscribeObject && unsubscribeObject.subscription && unsubscribeObject.subscription.length) {
      unsubscribeObject.subscription.forEach(subscription => {
        if (subscription) {
          content.subscription.push({
            name: subscription.name,
            value: subscription.sid,
            key: 'sid',
            id: subscription.sid
          });
        }
      });
    }

    const categoriesObject = response.find(i => i.categories);
    if (categoriesObject && categoriesObject.categories && categoriesObject.categories.length) {
      categoriesObject.categories.forEach(category => {
        if (category && isComplex) {
          content['exclusions'].push({
            name: category.name,
            value: false,
            key: 'ctag',
            id: category.ctag
          });
        } else {
          content.subscription.unshift({
            name: category.name,
            value: category.ctag,
            key: 'ctag',
            id: category.ctag
          });
        }
      });
    }

    const brandsObject = response.find(i => i.brands);
    if (brandsObject && brandsObject.brands && brandsObject.brands.length) {
      brandsObject.brands.forEach(brand => {
        if (brand && isComplex) {
          content['exclusions'].unshift({
            name: brand.name,
            value: false,
            key: 'btag',
            id: brand.btag
          });
        } else {
          content.subscription.unshift({
            name: brand.name,
            value: brand.btag,
            key: 'btag',
            id: brand.btag
          });
        }
      });
    }

    return content;
  }

}
