export interface CarMake {
  value: string;
  text: string;
}

// aston-martin, bentley, ferrari, lamborghini, lotus, rolls-royce, tesla
// are removed per temporarily per story 2270
export const newCarMake: CarMake[] = [
  { value: 'acura', text: 'Acura' },
  { value: 'alfa-romeo', text: 'Alfa Romeo' },
  // { value: 'aston-martin', text: 'Aston Martin' },
  { value: 'audi', text: 'Audi' },
  // { value: 'bentley', text: 'Bentley' },
  { value: 'bmw', text: 'BMW' },
  { value: 'buick', text: 'Buick' },
  { value: 'cadillac', text: 'Cadillac' },
  { value: 'chevrolet', text: 'Chevrolet' },
  { value: 'chrysler', text: 'Chrysler' },
  { value: 'dodge', text: 'Dodge' },
  // { value: 'ferrari', text: 'Ferrari' },
  { value: 'fiat', text: 'FIAT' },
  { value: 'ford', text: 'Ford' },
  { value: 'gmc', text: 'GMC' },
  { value: 'honda', text: 'Honda' },
  { value: 'hyundai', text: 'Hyundai' },
  { value: 'infiniti', text: 'Infiniti' },
  { value: 'jaguar', text: 'Jaguar' },
  { value: 'jeep', text: 'Jeep' },
  { value: 'kia', text: 'Kia' },
  // { value: 'lamborghini', text: 'Lamborghini' },
  { value: 'land-rover', text: 'Land Rover' },
  { value: 'lexus', text: 'Lexus' },
  { value: 'lincoln', text: 'Lincoln' },
  // { value: 'lotus', text: 'Lotus' },
  { value: 'maserati', text: 'Maserati' },
  { value: 'mazda', text: 'Mazda' },
  { value: 'mercedes-benz', text: 'Mercedes-Benz' },
  { value: 'mini', text: 'MINI' },
  { value: 'mitsubishi', text: 'Mitsubishi' },
  { value: 'nissan', text: 'Nissan' },
  { value: 'porsche', text: 'Porsche' },
  { value: 'ram', text: 'Ram' },
  // { value: 'rolls-royce', text: 'Rolls-Royce' },
  { value: 'scion', text: 'Scion' },
  { value: 'smart', text: 'Smart' },
  { value: 'subaru', text: 'Subaru' },
  // { value: 'tesla', text: 'Tesla' },
  { value: 'toyota', text: 'Toyota' },
  { value: 'volkswagen', text: 'Volkswagen' },
  { value: 'volvo', text: 'Volvo' }
];

export const usedCarMake: CarMake[] = [
  { value: 'acura', text: 'Acura' },
  { value: 'alfa-romeo', text: 'Alfa Romeo' },
  { value: 'aston-martin', text: 'Aston Martin' },
  { value: 'audi', text: 'Audi' },
  { value: 'bentley', text: 'Bentley' },
  { value: 'bmw', text: 'BMW' },
  { value: 'bugatti', text: 'Bugatti' },
  { value: 'buick', text: 'Buick' },
  { value: 'cadillac', text: 'Cadillac' },
  { value: 'chevrolet', text: 'Chevrolet' },
  { value: 'chrysler', text: 'Chrysler' },
  { value: 'daewoo', text: 'Daewoo' },
  { value: 'dodge', text: 'Dodge' },
  { value: 'eagle', text: 'Eagle' },
  { value: 'ferrari', text: 'Ferrari' },
  { value: 'fiat', text: 'FIAT' },
  { value: 'ford', text: 'Ford' },
  { value: 'geo', text: 'Geo' },
  { value: 'gmc', text: 'GMC' },
  { value: 'honda', text: 'Honda' },
  { value: 'hummer', text: 'HUMMER' },
  { value: 'hyundai', text: 'Hyundai' },
  { value: 'lincoln', text: 'Lincoln' },
  { value: 'infiniti', text: 'Infiniti' },
  { value: 'isuzu', text: 'Isuzu' },
  { value: 'jaguar', text: 'Jaguar' },
  { value: 'jeep', text: 'Jeep' },
  { value: 'kia', text: 'Kia' },
  { value: 'lamborghini', text: 'Lamborghini' },
  { value: 'land-rover', text: 'Land Rover' },
  { value: 'lexus', text: 'Lexus' },
  { value: 'lotus', text: 'Lotus' },
  { value: 'maserati', text: 'Maserati' },
  { value: 'maybach', text: 'Maybach' },
  { value: 'mazda', text: 'Mazda' },
  { value: 'mclaren', text: 'McLaren' },
  { value: 'mercedes-benz', text: 'Mercedes-Benz' },
  { value: 'mercury', text: 'Mercury' },
  { value: 'mini', text: 'MINI' },
  { value: 'mitsubishi', text: 'Mitsubishi' },
  { value: 'nissan', text: 'Nissan' },
  { value: 'oldsmobile', text: 'Oldsmobile' },
  { value: 'plymouth', text: 'Plymouth' },
  { value: 'porsche', text: 'Porsche' },
  { value: 'ram', text: 'Ram' },
  { value: 'rolls-royce', text: 'Rolls-Royce' },
  { value: 'rover', text: 'Rover' },
  { value: 'saab', text: 'Saab' },
  { value: 'saturn', text: 'Saturn' },
  { value: 'scion', text: 'Scion' },
  { value: 'smart', text: 'Smart' },
  { value: 'subaru', text: 'Subaru' },
  { value: 'suzuki', text: 'Suzuki' },
  { value: 'tesla', text: 'Tesla' },
  { value: 'toyota', text: 'Toyota' },
  { value: 'volkswagen', text: 'Volkswagen' },
  { value: 'volvo', text: 'Volvo' }
];
