/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ProfileService } from './profile/profile.service';
import { TodayDeal } from '@app/shared/models/today-deal.model';
import { PermissionService } from '@core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';

@Injectable()
export class TodayDealsService {

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
  ) { }

  async getTodayDealByGuid(guid: string): Promise<TodayDeal> {
    return this.http.get<TodayDeal>(`${environment.apiUrl}/api/today-deals/${guid}`).toPromise();
  }

  getTodayDeals(limit: number = 30, zoneUrlSearchParams?: URLSearchParams): Observable<TodayDeal[]> {
    const urlSearchParams = new URLSearchParams({ limit: `${limit}`, onlyActive: 'true' });

    if (this.permissionService.hasDefined('known:access')) {
      urlSearchParams.append('known_user_email', this.knownUserService.knowUserEmail);
    }

    if (zoneUrlSearchParams) {
      for (const [key, value] of zoneUrlSearchParams) {
        urlSearchParams.append(key, value);
      }
    }

    return this.http.get<TodayDeal[]>(`${environment.apiUrl}/api/today-deals/cards?${urlSearchParams.toString()}`);
  }

  getTodayDealsByIds(ids: string[], onlyActive = true): Observable<TodayDeal[]> {
    let query = `?ids=${ids.join(',')}`;

    if (onlyActive) {
      query += '&onlyActive=true';
    }

    if (this.permissionService.hasDefined('known:access')) {
      query += `&known_user_email=${this.knownUserService.knowUserEmail}`;
    }

    return this.http.get<TodayDeal[]>(`${environment.apiUrl}/api/today-deals/cards${query}`);
  }

  increaseViewCounter(todayDeal: TodayDeal): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/today-deals/cards/${todayDeal.guid}/view`, null);
  }

  increaseClickOutCounter(todayDeal: TodayDeal): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/today-deals/cards/${todayDeal.guid}/click-out`, null);
  }
}
