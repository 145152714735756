/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Ad } from '@app/shared/models/ad.model';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ComponentHtmlIdRegistryService } from 'g3-common-ui';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import { HomepageAdsService } from '@shared/services/homepage-ads.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-brands-list',
  templateUrl: './featured-brands-list.component.html',
  styleUrls: ['./featured-brands-list.component.less']
})
export class FeaturedBrandsListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() wideContainerClass = '';
  @Input() wideContainerClassName = '';
  @Input() groupTabIndex: number;

  public title = 'Featured Brands';
  public anchorLink: string;
  public brandsTier1: Ad[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public idRegistryService: ComponentHtmlIdRegistryService,
    private homepageAdsService: HomepageAdsService,
    private directClickOutService: DirectClickOutService,
  ) { }

  ngOnInit(): void {
    this.subscribeOnBrands();
    this.anchorLink = this.homepageAdsService.getAnchorLink(`s_${this.title}`);
  }

  ngAfterViewInit(): void {
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  subscribeOnBrands(): void {
    this.subscriptions.push(
      this.homepageAdsService.brands$.subscribe(brands => {
        this.brandsTier1 = brands ? brands.slice(0, 7) : [];
        const action = 'brand-ads-result';
        const data = (brands || []).map(ad => ad.id);

        this.directClickOutService.trackAnalyticsEvents(action, data);
      })
    );
  }

  async clickHandler($event: { preventDefault: () => void }, brand: Ad): Promise<void> {
    $event.preventDefault();
    void this.directClickOutService.adCards.nonClickOutHandler(brand, 'brand-ads-click');
  }

  getBrands(): any {
    const windowWidth = window.innerWidth;
    const brands = this.brandsTier1.filter(brand => !!brand.vendorImage);

    if (this.isWideContainer() && windowWidth >= 1472) {
      return brands;
    } else {
      return brands.slice(0, 6); // remove last element
    }
  }

  isWideContainer(): boolean {
    return !!this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  brokenImageUrl($event: any, guid: string): any {
    this.brandsTier1 = this.brandsTier1.filter(brand => brand.guid !== guid);
  }
}
