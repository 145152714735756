<section class="unsubscribe">
  <div class="unsubscribe-img">
    <img class="unsubscribe-img__item"
         src="/assets/images/unsubscribe-success.png"
         loading="lazy"
         alt="" />
  </div>
  <div class="unsubscribe-title">
    <h1 class="unsubscribe-title__primary">{{ successPrimaryHeader }}</h1>
    <h4 class="unsubscribe-title__secondary"
        [innerHTML]="successSecondaryHeader"></h4>
  </div>
</section>
