import { Injectable } from '@angular/core';
import isEqual from 'lodash/isEqual';

const ADMIN_EDIT_MODE = 'admin_edit_mode';
const SYSTEM_EXCLUSION_STATUS = 'system-wide-exclusions-status';

// TODO move this service to shared. It not related to widget
@Injectable()
export class SessionStorageService {
  private storage: Storage = window.sessionStorage;

  public getItem<T>(key: string): T {
    return JSON.parse(this.storage.getItem(key));
  }

  public setItem<T>(key: string, data: T): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    // this does NOT clear admin edit mode OR system exclusion status
    const staticItems = {
      admin_edit_mode: this.storage.getItem(ADMIN_EDIT_MODE),
      'system-wide-exclusions-status': this.storage.getItem(SYSTEM_EXCLUSION_STATUS)
    };

    this.storage.clear();

    for (const [key, value] of Object.entries(staticItems)) {
      this.storage.setItem(key, value);
    }
  }

  public hasKey(key: string): boolean {
    return !!this.storage.getItem(key);
  }

  public removeGroupOfKeys(groupKey: string): void {
    Object.keys(sessionStorage)
      .filter(item => item.includes(groupKey))
      .forEach(key => sessionStorage.removeItem(key));
  }

  public addValueToArrayByKey<T>(key: string, value: T): void {
    const values = sessionStorage.getItem(key);

    if (!values) {
      sessionStorage.setItem(key, JSON.stringify([value]));
    } else {
      const valuesArray = JSON.parse(values);

      if (!valuesArray.some((el: T) => isEqual(el, value))) {
        sessionStorage.setItem(key, JSON.stringify([...valuesArray, value]));
      }
    }
  }

}
