<div class="brand-item card-wrapper"
     (click)="click($event)"
     *ngIf="!(brand?.isHtmlTemplate)"
     (mouseleave)="onMouseLeave()"
     [class.hover-out]="mouseJustLeft">
  <img [src]="brand && brand.vendorImage ? brand.vendorImage : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='"
       [attr.aria-label]="brand.company"
       loading="lazy"
       role="link"
       alt=""
       (error)="emitBrokenImageUrl()">
</div>

<div class="ad-wrapper card-wrapper"
     role="link"
     [attr.aria-label]="brand.company"
     (click)="click($event)"
     *ngIf="brand?.isHtmlTemplate"
     (mouseleave)="onMouseLeave()"
     [class.hover-out]="mouseJustLeft">
  <div role="presentation"
       class="custom-adzerk-template"
       [innerHtml]="(brand?.template || '')"></div>
</div>
