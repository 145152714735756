/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AnalyticsEvent, AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { getAnalyticsGALabelFromObject } from '@zones/services/analytics/helpers';
import { Injectable } from '@angular/core';


type ActionType = 'scroll'
| 'swipe';

type CategoryType = 'cards-scroll'
| 'tdd';

@Injectable({
  providedIn: 'root'
})
export class HorizontalScrollAnalyticService {

  getAnalyticsEvents(action: ActionType, category: CategoryType, data: any): AnalyticsEvent[] {
    const analyticsAction = `${category.replace('_', '-')}-${action}`;

    switch (action) {
      case 'scroll':
      case 'swipe':
        return [
          new AnalyticsInternalEventModel(analyticsAction, data),
          new AnalyticsGAEventModel(analyticsAction, {
            category,
            label: getAnalyticsGALabelFromObject(data)
          })
        ];
    }
    return [];
  }
}
