<div class="content-wrap">
  <img class="logo big-logo"
       [src]="logoPath"
       [alt]="marketplaceName">

  <div class="content">
    <h3 class="info-head">Your account is deleted</h3>

    <p class="info-description">We're sorry to see you go! Please check your email for confirmation. Please note:</p>

    <ul class="info-list">
      <li class="info-item">
        <img src='/assets/icons/ic-deleted.svg' class="deleted-icon">
        Your account has been permanently deleted.</li>
      <li class="info-item">
        <img src='/assets/icons/ic-edit.svg' class="edit-icon">
        If you want to access the site again you will need to create a new account.</li>
    </ul>

    <button type="button"
            class="btn btn-primary info-control"
            (click)="onCancelDelete()">CREATE A NEW ACCOUNT</button>
  </div>
  <iframe width="1"
          height="1"
          [src]="signOutUrl"></iframe>
</div>
