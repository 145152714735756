import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '../services/permission.service';
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit {

  @Input('appHasPermission') public requestedPermission: string;
  @Input() public allowUndefinedPermission = false;
  @Input() public navigateTo: string[];

  @Output() public permissionConfirmed: EventEmitter<any> = new EventEmitter();
  @Output() public permissionDenied: EventEmitter<boolean> = new EventEmitter();

  public hasPermission = false;

  constructor(
    public permissionService: PermissionService,
    private router: Router
  ) { }

  @HostListener('click', ['$event'])
  public onClick(e: any): void {
    if (this.requestedPermission !== null) {
      this.handlePermission(e);
    }
  }

  public ngOnInit(): void {
    this.applyPermission();
  }

  public applyPermission(): void {

    if (this.allowUndefinedPermission && this.requestedPermission === undefined) {
      this.hasPermission = true;
    } else {
      this.hasPermission = this.permissionService.hasDefined(this.requestedPermission);
    }
  }

  public handlePermission(e: any): void {
    if (this.hasPermission) {
      this.permissionConfirmed.emit(e);
      if (this.navigateTo) {
        void this.router.navigate(this.navigateTo);
      }
    } else {
      this.permissionDenied.emit(true);
    }
  }

}
