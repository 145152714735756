import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { IAdCTAButtonOptions } from '@app/shared/interfaces/ad-cta-button-options.interface';

@Component({
  selector: 'app-ad-cta-button',
  templateUrl: './ad-cta-button.component.html',
  styleUrls: ['./ad-cta-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdCtaButtonComponent {

  @Input() options: IAdCTAButtonOptions = {
    ad: null,
    customClass: '',
    isEnrollmentAvailable: false,
    marketplaceHasPaylogixId: false,
    userHasPaylogixId: false
  };

  get isGetButtonExternal(): boolean {
    const { ad } = this.options;

    if (!ad) {
      return false;
    }

    return ad.isGetButtonExternal();
  }

  get isDisplayCtaIcon(): boolean {
    // for external links we always show icon
    // for cards with modal - only if there is a code
    const { ad } = this.options;

    if (ad && ad.phone && !ad.code) {
      return false;
    }

    return true;
  }

  get externalTxtClass(): string {
    return this.ctaButtonTitle().toUpperCase() === 'LEARN MORE' ? 'condensed' : '';
  }

  get ctaButtonType(): string {
    const { ad } = this.options;

    if (!ad) {
      return '';
    }

    return ad.getCtaButtonType();
  }

  get titleForCtaButton(): string {
    return this.ctaButtonTitle();
  }

  get xsTitleForCtaButton(): string {
    return this.ctaButtonTitle(true);
  }

  public ctaButtonTitle(isMobile: boolean = false): string {
    const { ad, marketplaceHasPaylogixId, userHasPaylogixId, isEnrollmentAvailable } = this.options;

    if (!ad) {
      return '';
    }

    return ad.getCtaButtonTitle(false, marketplaceHasPaylogixId, userHasPaylogixId, isEnrollmentAvailable, isMobile);
  }

}
