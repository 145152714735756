import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.less']
})
export class CloseButtonComponent {

  @Output() public closed: EventEmitter<Event> = new EventEmitter();

  constructor() { }

  public close(event: Event): void {
    this.closed.emit(event);
  }

}
