/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Ad } from '@shared/models/ad.model';
import { AdCtaClick } from '@shared/components/ad-item/ad-item.component';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { WINDOW } from 'g3-common-ui';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ConfigService, EnrollmentBuildUpInfo } from '@shared/services/config.service';
import { DirectClickOutService } from '@shared/services/direct-click-out.service';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { PRODUCT_CARD } from '@app/zones/services/zones.service';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@app/zones/interfaces/zones-search-response.interface';
import { ZonesSectionElementsAnalyticsService } from '@zones/services/analytics/zones-section-elements-analytics.service';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventLocation,
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeImpresssionsEvent
} from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { ModalTodayDealService } from '@app/shared/components/modal-pass-thru/modal-today-deal.service';

const ROW_COUNT = 5;
const DOUBLE_WIDE_ON_REMNANT = 3;
const DOUBLE_WIDE_OFF_REMNANT = 4;

@Component({
  selector: 'app-cards-fixed',
  templateUrl: './cards-fixed.component.html',
  styleUrls: ['./cards-fixed.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsFixedComponent implements OnInit, AfterViewInit {

  @Input() data: ZoneSectionElement;
  @Input() wideContainerClass = '';
  @Input() wideContainerClassName = '';
  @Input() groupTabIndex: number;
  @Input() userCountry: string;

  @Output() initialized = new EventEmitter<ZoneSectionElement>();
  @Output() displayed = new EventEmitter<ZoneSectionElement>();
  @Output() clickedItem = new EventEmitter<Ad>();

  anchorLink = '';

  constructor(
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private directClickOutService: DirectClickOutService,
    private zonesSectionsAnalyticsService: ZonesSectionElementsAnalyticsService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private homepageAdsService: HomepageAdsService,
    private zoneHeaderService: ZoneHeaderService,
    private modalTodayDealService: ModalTodayDealService,
    @Inject(WINDOW) private window: WINDOW,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.initialized.emit(this.data);

      if (this.data.name) {
        const sectionName = `s_${this.data.name}`;
        this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
      } else {
        this.anchorLink = 's_cards_fixed';
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.data) {
      // * Disabled by G3PROJ-2529
      // if (this.isEnoughAds) {
      //   this.sendAdobeImpressions();
      // }
      this.displayed.emit(this.data);
    }

    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  sendAdobeImpressions(): void {
    const adobeEvent: AdobeImpresssionsEvent = {
      event_name: AdobeEventNameEnum.CARD,
      event_type: AdobeEventTypeEnum.IMPRESSION,
      properties: {
        impressions: this.ads
          .filter((_ad, index) => !(this.isOnlyXL(index) && this.window.innerWidth < 1472 || this.hideLastForOE(index)))
          .map((ad, index) => {
            const size = this.isDoubleWideCard(index) ? 'double' : 'standard';
            return this.adobeAnalyticsEventsService.getOfferAdobeData(ad, size, `offer${index + 1}`, this.data.name);
          })
      }
    };
    void this.adobeAnalyticsEventsService.sendOfferImpressions(adobeEvent, AdobeEventLocation.Homepage);
  }

  get ads(): Ad[] {
    return (this.data && this.data.ads ? this.data.ads : []).filter(i => i.dbImageUrl);
  }

  get isEnoughAds(): boolean {
    return this.data && this.ads.length >= this.data.min_units;
  }

  get isEnrollment(): boolean {
    const enrollmentInfo = this.getEnrollmentInfo();

    return this.data.open_enrollment && enrollmentInfo && !!enrollmentInfo.section_name && enrollmentInfo.countries.includes(this.userCountry);
  }

  isOnlyXL(index: number): boolean {
    return this.data.double_wide
      ? index === DOUBLE_WIDE_ON_REMNANT || index % ROW_COUNT === DOUBLE_WIDE_ON_REMNANT
      : index === DOUBLE_WIDE_OFF_REMNANT || index % ROW_COUNT === DOUBLE_WIDE_OFF_REMNANT;
  }

  isProductCard(ad: Ad): boolean {
    return ad.class && ad.class === PRODUCT_CARD;
  }

  hideLastForOE(index: number): boolean {
    const lastIndex = this.data.ads.length - 1;
    const nextToLastIndex = this.data.ads.length - 2;
    if (this.data.ads.length < 5) {
      return false;
    }
    if (this.isEnrollment && ((index === lastIndex) || (index === nextToLastIndex))) {
      return true;
    } else {
      return false;
    }
  }

  addItemTrackBy(index, item): any {
    return item.id;
  }

  isWideContainer(): boolean {
    return !!this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  onClickedAd(ad: Ad, index: number): void {
    const allowedForChild = this.modalTodayDealService.isAllowedToViewOffer(ad.contentGuid);
    if (ad.isAuthRequired && !allowedForChild) {
      return;
    }

    this.clickedItem.emit(ad);
    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(ad, this.isDoubleWideCard(index) ? 'double' : 'standard', this.data.name);
    void this.directClickOutService.adCards.handleAdClick(ad, 'card', this.data, null, null, null, false, adobeInfo);
  }

  onClickedAdCta(data: AdCtaClick, index: number): void {
    if (data.ad.isAuthRequired) {
      return;
    }

    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(data.ad, this.isDoubleWideCard(index) ? 'double' : 'standard', this.data.name);

    switch (data.source) {
      case 'cta':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'cta', this.data, '', undefined, undefined, undefined, adobeInfo);
        break;
      case 'cta-zipcode':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'cta-zipcode', this.data, 'offer-card-with-zipcode-input', '', data.params, undefined, adobeInfo);
        break;
      case 'paylogix-enrollment':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'paylogix-enrollment', null, '', null, null, true, adobeInfo);
        break;
    }
  }

  onDisplayedAd(ad: Ad): void {
    if (ad.isAuthRequired) {
      return;
    }

    this.analyticsService.eventsTrack(
      this.zonesSectionsAnalyticsService.items.getAnalyticsEvents('view', this.data, ad)
    );
  }

  showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.data.is_ebg);
  }

  isDoubleWideCard(index: number): boolean {
    return index === 0 && this.data.double_wide && !this.isEnrollment;
  }

  getEnrollmentInfo(): EnrollmentBuildUpInfo {
    return this.configService.controls.enrollment_buildup;
  }
}
