import { Ad } from '@shared/models/ad.model';
import { Injectable } from '@angular/core';
import { WidgetsAdContent, WidgetsAdContentResponse } from '@zones/interfaces/widgets.interface';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

@Injectable()
export class ZoneWidgetsService {

  getWidgetsAdContent(zoneSectionElement: ZoneSectionElement, allowedWidgetIds: string[]): WidgetsAdContent[] {
    const ads = zoneSectionElement && zoneSectionElement.ads ? zoneSectionElement.ads : [];
    return ads.map((ad: Ad) => {
      const data = ad.content.reduce((obj, item) => {
        obj[item['key']] = item['value'];
        return obj;
      }, {} as WidgetsAdContentResponse);

      return {
        id: ad.id,
        responseId: ad.responseId,
        widgetId: data.widget_id,
        header: data.header,
        imageUrl: data.image_url,
        logoUrl: data.logo_url,
        destinationUrl: data.destination_url || '',
        iconUrl: data.icon_url || '',
        subHeader: data.sub_header || '',
        isEbg: ad.isEbg,
        guid: ad.guid,
        catCodeTagIds: ad.catCodeTagIds,
        extraStatus: ad.extraStatus,
        endData: ad.endDate,
        class: ad.class,
        brandName: ad.brand_name
      };
    })
      .filter((item: WidgetsAdContent) => allowedWidgetIds.includes(item.widgetId)); // check for valid widget_id
  }
}
