<form method="POST"
      class="rental-car search-widget {{ customClass }}"
      [class.search-widget-top-indent]="topIndent"
      ngNoForm
      [action]="url">
  <div class="search-widget_header">
    <img class="search-widget_header__logo"
         loading="lazy"
         [src]="widget.icon_url | imageUrl: imageOption">
    <div class="search-widget_header__container">
      <div class="search-widget_header__container__title">{{widget.header}}</div>
    </div>
  </div>
  <div class="rental-car_place">
    <div class="rental-car_place__pick">
      <span class="rental-car_place__pick_mask">Pick-up: </span>
      <app-autocomplete-dropdown (click)="handlePickUpAutocomplete()"
                                 [closeAutocompleteDropdown]="closePickUpDropdown"
                                 [sections]="autocompleteSections"
                                 (autocompleteValue)="getPickUpAutocompleteValue($event)"
                                 (selectedItemTitle)="getPickUpSelectedItemTitle($event)"
                                 (selectedValue)="getPickUpValue($event)"
                                 (input)="pickUpValueChange($event)"
                                 (mouseup)="pickUpClick($event)"
                                 [autocompleteDropdownSettings]="pickUpAutocompleteDropdownSettings">
      </app-autocomplete-dropdown>
    </div>
    <div class="rental-car_place__checkbox">
      <input class="rental-car_place__checkbox_input search-widget-checkbox__input"
             [class.checked]="destination_not_origin"
             type="checkbox"
             name="destination_not_origin"
             (ngModelChange)="dropOffChange($event)"
             [(ngModel)]="isDroppedOff" />
      Different drop-off
    </div>
    <div *ngIf="destination_not_origin"
         class="rental-car_place__drop">
      <span class="rental-car_place__drop_mask">Drop-off: </span>
      <app-autocomplete-dropdown (click)="handleDropOffAutocomplete()"
                                 [closeAutocompleteDropdown]="closeDropOffDropdown"
                                 [sections]="autocompleteSections"
                                 (autocompleteValue)="getDropOffAutocompleteValue($event)"
                                 (selectedItemTitle)="getDropOffSelectedItemTitle($event)"
                                 (selectedValue)="getDropOffValue($event)"
                                 (input)="dropOffValueChange($event)"
                                 (mouseup)="dropOffClick($event)"
                                 [autocompleteDropdownSettings]="dropOffAutocompleteDropdownSettings">
      </app-autocomplete-dropdown>
    </div>
  </div>
  <div class="rental-car_time">
    <div class="rental-car_time_from">
      <span class="rental-car_time_from_mask">From:</span>
      <div class="rental-car_time_from_separator"></div>
      <input class="widget-input widget-date-input ngb-datepicker"
             placeholder="Pick-up date"
             aria-label="Pick-up"
             autocomplete="off"
             [(ngModel)]="pickUp"
             [minDate]="minDate"
             (dateSelect)="onDateSelection($event, 'pickUp')"
             required="required"
             ngbDatepicker
             (click)="datepickerParser.toggleDatepicker($event, dpPickUp)"
             #dpPickUp="ngbDatepicker">
      <app-select [groupTabIndex]="groupTabIndex"
                  [selectValue]="pickUpTimeId"
                  className="pick-up-time"
                  (selectValueChange)="selectChange($event)"
                  [options]="times"
                  name="pickUpTimeId"
                  placeholder="Time">
      </app-select>
    </div>
    <div class="rental-car_time_to">
      <span class="rental-car_time_to_mask">To: </span>
      <div class="rental-car_time_to_separator"></div>
      <input class="widget-input widget-date-input ngb-datepicker"
             placeholder="Drop-off date"
             aria-label="Drop-off date"
             autocomplete="off"
             [(ngModel)]="dropOff"
             [minDate]="minDate"
             (dateSelect)="onDateSelection($event, 'dropOff')"
             required="required"
             ngbDatepicker
             (click)="datepickerParser.toggleDatepicker($event, dpDropOff)"
             #dpDropOff="ngbDatepicker">
      <app-select [groupTabIndex]="groupTabIndex"
                  [selectValue]="dropOffTimeId"
                  className="drop-off-time"
                  (selectValueChange)="selectChange($event)"
                  [options]="times"
                  name="dropOffTimeId"
                  placeholder="Time">
      </app-select>
    </div>
  </div>
  <div class="rental-car_age">
    <div class="rental-car_age_label">I am:</div>
    <app-select [groupTabIndex]="groupTabIndex"
                [selectValue]="ageId"
                className="age"
                (selectValueChange)="selectChange($event)"
                [options]="ages"
                name="age"
                placeholder="Age"></app-select>
  </div>
  <button class="rental-car_button btn btn-primary mini-widget-action__search"
          type="submit"
          (click)="search()">
    <span class="rental-car_button_text-xs">SEARCH</span>
    <svg class="rental-car_button_text"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6667 14.6667H15.6133L15.24 14.3067C16.5467 12.7867 17.3333 10.8133 17.3333 8.66667C17.3333 3.88 13.4533 0 8.66667 0C3.88 0 0 3.88 0 8.66667C0 13.4533 3.88 17.3333 8.66667 17.3333C10.8133 17.3333 12.7867 16.5467 14.3067 15.24L14.6667 15.6133V16.6667L21.3333 23.32L23.32 21.3333L16.6667 14.6667ZM8.66667 14.6667C5.34667 14.6667 2.66667 11.9867 2.66667 8.66667C2.66667 5.34667 5.34667 2.66667 8.66667 2.66667C11.9867 2.66667 14.6667 5.34667 14.6667 8.66667C14.6667 11.9867 11.9867 14.6667 8.66667 14.6667Z"
            fill="white" />
      <path d="M16.6667 14.6667H15.6133L15.24 14.3067C16.5467 12.7867 17.3333 10.8133 17.3333 8.66667C17.3333 3.88 13.4533 0 8.66667 0C3.88 0 0 3.88 0 8.66667C0 13.4533 3.88 17.3333 8.66667 17.3333C10.8133 17.3333 12.7867 16.5467 14.3067 15.24L14.6667 15.6133V16.6667L21.3333 23.32L23.32 21.3333L16.6667 14.6667ZM8.66667 14.6667C5.34667 14.6667 2.66667 11.9867 2.66667 8.66667C2.66667 5.34667 5.34667 2.66667 8.66667 2.66667C11.9867 2.66667 14.6667 5.34667 14.6667 8.66667C14.6667 11.9867 11.9867 14.6667 8.66667 14.6667Z"
            fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear"
                        x1="11.66"
                        y1="0"
                        x2="11.66"
                        y2="23.32"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1"
                stop-color="white"
                stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </button>

  <!-- Reason: inputs hasn't value accessor -->
  <div class="hidden-inputs">
    <input type="text"
           name="origin"
           [value]="pickUpValue" />
    <input type="text"
           name="origin_name"
           [value]="originName" />
    <input type="text"
           name="destination"
           [value]="dropOffValue" />
    <input type="text"
           name="destination_name"
           [value]="destinationName" />
    <input type="text"
           name="pick_up_time"
           [value]="pickUpTimeId" />
    <input type="text"
           name="drop_off_time"
           [value]="dropOffTimeId" />
    <input type="text"
           name="age"
           [value]="ageId" />
    <input type="text"
           name="pick_up_date"
           [value]="pickUpDate" />
    <input type="text"
           name="drop_off_date"
           [value]="dropOffDate" />
    <input type="text"
           name="destination_not_origin"
           [value]="destination_not_origin" />
  </div>
</form>
