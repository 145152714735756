/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Marker } from '@app/listing/interfaces/marker.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferModel } from '@shared/models/offer.model';
import { SearchResultsAdsModel } from '@app/shared/models/search-results-ads.model';
import { SingleUseCodeService } from '@shared/components/coupon-modal/single-use-code.service';
import { TodayDeal } from '@app/shared/models/today-deal.model';
import {
  AutoTabIndexService,
} from 'g3-common-ui';

@Component({
  selector: 'app-modal-coupon',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.less'],
  providers: [AutoTabIndexService]
})
export class CouponModalComponent implements OnInit {

  @Input() brandLogo = '';
  @Input() code = '';
  @Input() offer = {} as OfferModel;
  @Input() todayDeal: TodayDeal;
  @Input() providerLocation: Marker;
  @Input() ad: SearchResultsAdsModel;

  @Output() modalEvents = new EventEmitter<any>();
  @Output() phoneAction = new EventEmitter<string>();
  @Output() phoneClick = new EventEmitter<string>();

  public buttonLabel = 'copy';
  public errorMessage: string = null;
  public groupTabIndex = 1000;

  constructor(
    public activeModal: NgbActiveModal,
    private singleUseCodeService: SingleUseCodeService
  ) { }

  getOfferGuid(): string {
    if (this.offer && this.offer.guid) {
      return this.offer.guid;
    }
    if (this.ad && this.ad.contentGuid) {
      return this.ad.contentGuid;
    }
    return '';
  }

  isSingleUseCode(): boolean {
    return this.singleUseCodeService.containsSucCode(this.code) || this.singleUseCodeService.containsSucCode(this.offer.code);
  }

  async ngOnInit(): Promise<void> {
    if (!this.todayDeal && this.isSingleUseCode()) {
      this.errorMessage = ' ';
      try {
        this.code = (await this.singleUseCodeService.getCode(this.getOfferGuid()).toPromise()).code;
        this.errorMessage = null;
      } catch (err) {
        this.errorMessage = (err.error && err.error.errors[0] && err.error.errors[0].message) ? err.error.errors[0].message : 'Request error';
      }
    }
  }

  handleEvents($event: any): void {
    this.modalEvents.emit($event);
  }

  onPhoneAction(url: string): void {
    this.phoneAction.emit(url);
    this.activeModal.close();
  }

  onPhoneClick(phone: string): void {
    this.phoneClick.emit(phone);
  }
}
