import { ControlInputType } from '@app/shared/enums/control-input-type.enum';
import { IFormControlInput } from '@app/shared/interfaces/form-control-input.interface';

export const surveyContactFields: IFormControlInput[] = [
  { prop: 'first_name', type: ControlInputType.Text, name: 'First Name', dataQA: 'first-name-input', required: true, placeholder: 'Add first name' },
  { prop: 'last_name', type: ControlInputType.Text, name: 'Last Name', dataQA: 'last-name-input', required: true, placeholder: 'Add last name' },
  { prop: 'email', type: ControlInputType.Email, name: 'Email', dataQA: 'email-input', required: true, placeholder: 'Add email' },
  { prop: 'phone_number', type: ControlInputType.Phone, name: 'Phone Number', dataQA: 'phone-number-input', required: true, placeholder: 'Add number' }
];

export const errorMessages = {
  feedback: {
    required: 'Feedback is required'
  },
  first_name: {
    required: 'First name is required'
  },
  last_name: {
    required: 'Last name is required'
  },
  email: {
    email: 'Please enter a valid email',
    required: 'A valid email is required'
  },
  phone_number: {
    pattern: 'Please enter a valid phone number',
    required: 'A valid phone number is required'
  }
};
