
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW, WindowHelper } from 'g3-common-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeEventsService implements OnDestroy {

  private ResizeListener: EventListener;
  private prevBadgeResize: string;
  private badgeResize = new Subject<string>();
  public badgeResize$ = this.badgeResize.asObservable();

  constructor(
    @Inject(WINDOW) private window: WINDOW,
  ) {
    this.prevBadgeResize = this.getWindowSize(this.window.innerWidth);

    this.ResizeListener = (): void => {
      const newSize = this.getWindowSize(this.window.innerWidth);

      if (newSize !== this.prevBadgeResize) {
        this.prevBadgeResize = newSize;
        this.badgeResize.next(newSize);
      }
    };
    this.window.addEventListener('resize', this.ResizeListener);
  }

  ngOnDestroy(): void {
    if (this.ResizeListener) {
      this.window.removeEventListener('resize', this.ResizeListener);
    }
  }

  getWindowSize(width: number): string {
    if (width < WindowHelper.SIZE_S) {
      return 'mobile';
    } else if (width < WindowHelper.SIZE_M) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

}
