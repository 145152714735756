/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  filter,
  map,
  scan,
  throttleTime
} from 'rxjs/operators';
import { fromEvent } from 'rxjs';

interface ScrollEvent {
  currYOffset: number;
  canProceed: boolean;
  originalEvent: Event;
}

@Directive({
  selector: '[appInfinite]',
})
export class InfiniteDirective implements OnInit {

  @Input() thresholdFromBottom = 200;
  @Input() debounceTime = 200; // In milliseconds
  @Input() refractoryPeriod = 300; // In milliseconds

  @Output() loadMore: EventEmitter<Event> = new EventEmitter<Event>();

  ngOnInit(): void {
    const seed: ScrollEvent = { currYOffset: 0, canProceed: true, originalEvent: null };

    fromEvent(window, 'scroll')
      .pipe(
        map(event => ({ currYOffset: window.pageYOffset, canProceed: null, originalEvent: event })),
        scan((acc: ScrollEvent, event: ScrollEvent) => Object.assign(
          event,
          { canProceed: event.currYOffset > acc.currYOffset }),
        seed),
        filter(e => this.shouldTrigger(e)),
        throttleTime(1000),
      ).subscribe(e => this.loadMore.emit(e.originalEvent));
  }

  private shouldTrigger(event: ScrollEvent): any {
    const { scrollHeight } = window.document.body;
    const { pageYOffset, innerHeight } = window;
    return event.canProceed && (pageYOffset + innerHeight >= scrollHeight - this.thresholdFromBottom);
  }
}
