<app-offer-badge *ngIf="model"
                 [offerData]="model"
                 [offerClass]="model.class"
                 [withRightCorners]="true"
                 class="position-right">
</app-offer-badge>

<div class="widget hotel-widget widget-b">

  <div class="widget-image-container"
       [style.backgroundImage]="makeBackgroundImageUrl(backgroundImage | imageUrl : getBackgroundOptions() : false)">
    <div class="logo-wrapper-b">
      <img [src]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           loading="lazy"
           alt="taw"
           class="logo" />
    </div>
  </div>

  <div class="relative widget-form-container">
    <div class="logo-wrapper-b-mobile">
      <img [src]="logoUrl | imageUrl: { w: 70, h: 70, alternate: true }"
           loading="lazy"
           alt="taw"
           class="logo" />
    </div>

    <div class="flex-container flex-row correct-margin">
      <div class="image">
        <img src="/assets/icons/ic-hotel.svg"
             loading="lazy"
             class="ic-hotel"
             alt="">
      </div>
      <div class="header-text">
        <h3>{{ header }}</h3>
      </div>
    </div>

    <div class="content widget-form-wrapper">
      <app-hotels-form (clickOut)="hotelClickOut($event)"
                       [model]="model"
                       widgetClass="large-widget">
      </app-hotels-form>
    </div>

    <div class="logo-wrapper">
      <img src="/assets/tickets-at-work.png"
           loading="lazy"
           alt="taw"
           class="logo" />
    </div>
  </div>
</div>
