import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AccountConfirmationService, TRANSITION_TIME } from '@shared/services/account-confirmation.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfirmationHeaderContent } from '@app/shared/interfaces/confirmation.interface';
import { AccountConfirmationStep } from '@app/shared/services/account-confirmation.interface';
import { ScrollToElementService } from '@app/shared/services/scroll-to-element.service';
import { ACCOUNT_CONFIRMATION_DROPDOWN } from '@app/shared/constants/scroll-id.constants';

@Component({
  selector: 'app-account-confirmation-dropdown',
  templateUrl: './account-confirmation-dropdown.component.html',
  styleUrls: ['./account-confirmation-dropdown.component.less'],
  animations: [
    trigger('wrapper', [
      state('*', style({
        opacity: '1',
      })),
      state('void', style({
        opacity: '0',
      })),
      transition('void => *', [style({ opacity: '0' }), animate(`${TRANSITION_TIME}ms ease-out`)]),
      transition('* => void', [style({ opacity: '1' }), animate(`${TRANSITION_TIME}ms ease-in`)])
    ])
  ]
})
export class AccountConfirmationDropdownComponent implements OnInit, OnDestroy {

  @Input() public userName: string;
  @Input() public avatarLetter: string;

  @ViewChild('scrollToElement') scrollToElement: ElementRef;

  public wrapperState$: Observable<string>;
  public AccountConfirmationStep = AccountConfirmationStep;
  public step$: Observable<AccountConfirmationStep>;
  public mregPromptContent$: Observable<ConfirmationHeaderContent>;
  public destroyStream: Subject<void> = new Subject<void>();

  constructor(
    private readonly accountConfirmationService: AccountConfirmationService,
    private readonly scrollToElementService: ScrollToElementService,
  ) {

    this.step$ = accountConfirmationService.step$
      .pipe(tap(() => {
        this.accountConfirmationService.setConfirmationWrapperState('void');
        setTimeout(() => {
          this.accountConfirmationService.setConfirmationWrapperState('*');
        }, TRANSITION_TIME);
      }));

    this.wrapperState$ = this.accountConfirmationService.confirmationWrapperState;
    this.mregPromptContent$ = this.accountConfirmationService.promptContent$.pipe(takeUntil(this.destroyStream));

  }

  ngOnInit(): void {
    this.scrollToElementService.getScrollObservable().subscribe(elementId => {
      if (elementId === ACCOUNT_CONFIRMATION_DROPDOWN) {
        this.scrollToElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  public onCloseClicked(): void {
    this.accountConfirmationService.closeConfirmationDialog(false, true);
  }

  public ngOnDestroy(): void {
    this.destroyStream.next();
    this.destroyStream.complete();
  }

}
