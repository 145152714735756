import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { SpecialOffersAsAds } from '@app/shared/interfaces/special-offers-as-ads.interface';

@Component({
  selector: 'app-special-offers',
  templateUrl: './special-offers.component.html',
  styleUrls: ['./special-offers.component.less']
})
export class SpecialOffersComponent implements OnInit, OnDestroy {

  @Input() specialOffers: SpecialOffersAsAds[];
  @Input() wideContainerClass = '';
  @Input() wideContainerClassName = '';
  @Input() groupTabIndex: number;
  @Input() isEnrollment: boolean;
  @Input() disableShowAll = false;
  @Input() shouldAlignItems = false;
  @Input() isAllowDisableHover = true;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  getSectionIconPath(item: SpecialOffersAsAds): string {
    return item.icon_outline;
  }

  getSectionTitle(item: SpecialOffersAsAds): string {
    return item.title;
  }

  getSectionLinkTitle(item: SpecialOffersAsAds): string {
    return item.linkTitle;
  }

  getLinkUrl(item: SpecialOffersAsAds): string {
    if (item.linkUrl && item.linkUrl !== '') {
      return item.linkUrl;
    } else {
      return '';
    }
  }

  isOffersStaggered(item: SpecialOffersAsAds): boolean {
    return item.style === 'staggered';
  }

  SpecialOffersTrackBy(index: number, item: SpecialOffersAsAds): string {
    return item ? item.name : null;
  }
}
