import { RoomData } from './room-data.interface';

export const defaultRoom: RoomData = {
  roomId: 1,
  adults: 2,
  kids: 0,
  children: []
};

export const DEFAULT_ADULTS = 2;

export const MAX_CHILD_AGE = 17;
export const MIN_CHILD_AGE = 1;

export const MAX_ROOMS = 4;
export const MIN_ROOMS = 1;
export const MAX_ADULTS = 4;
export const MIN_ADULTS = 1;
export const MAX_KIDS = 3;
export const MIN_KIDS = 0;
