<ng-container>
  <p class="change-email-confirmation__title">Please Confirm Your Email</p>
  <p class="change-email-confirmation__sub-title">Enter the code that we just sent to:</p>
  <form [formGroup]="emailChangeForm"
        #form="ngForm"
        (ngSubmit)="onSendPassCodeClick($event)">
    <div class="change-email-confirmation__field">
      <label class="change-email-confirmation__field__input-lable"
             for="email">
        Email
      </label>
      <input name="email"
             class="change-email-confirmation__field__input"
             formControlName="email"
             placeholder="Enter email address">
      <div *ngIf="email.errors?.required && form.submitted"
           class="email-confirmation__field__error">
        Please enter your email
      </div>
      <div *ngIf="email.errors?.invalid && form.submitted"
           class="email-confirmation__field__error">
        <span class="email-confirmation__field__error-invalid">
          {{ email.errors?.invalid }}
        </span>
        <a *ngIf="email.errors?.invalid && form.submitted && email.errors?.invalid === 'Email already exists'"
           class="email-confirmation__sign-in-button"
           (click)="onSignIn()">
          SIGN IN
        </a>
      </div>
      <div *ngIf="email.errors?.email && form.submitted"
           class="email-confirmation__field__error">
        The email is not valid
      </div>
    </div>
    <button class="btn change-email-confirmation__submit-button"
            type="submit">
      Send passcode
    </button>
  </form>
</ng-container>
