import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { CtaElementService, CtaOptions } from '@app/zones/services/cta-element.service';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@app/zones/interfaces/zones-search-response.interface';
import { NgbCarousel, NgbCarouselConfig, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import first from 'lodash/first';

const MAX_CTA_ELEMENTS = 5;

@Component({
  selector: 'app-cta-element',
  templateUrl: './cta-element.component.html',
  styleUrls: ['./cta-element.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbCarouselConfig],
})

export class CtaElementComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel') public carousel: NgbCarousel;
  @Input() zoneElement: ZoneSectionElement;
  @Input() groupTabIndex: number;
  @Input() wideContainerClass = '';

  public ctaElements: CtaOptions[];
  public isCtaElement: boolean;
  public anchorLink: string;
  public position: number;

  constructor(
    private ctaElementService: CtaElementService,
    private analyticsService: AnalyticsService,
    private homepageAdsService: HomepageAdsService,
    private zoneHeaderService: ZoneHeaderService
  ) { }

  public ngOnInit(): void {
    this.isCtaElement = !!(this.zoneElement && this.zoneElement.ads && this.zoneElement.ads.length);
    if (this.isCtaElement) {
      this.ctaElements = this.getCtaElements(this.zoneElement);
    }

    if (this.zoneElement && this.zoneElement.name) {
      const sectionName = `s_${this.zoneElement.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_cta';
    }
  }

  public ngAfterViewInit(): void {
    if (this.isCtaElement) {
      this.sendCtaDisplayedEvent(first(this.ctaElements));
    }

    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  public showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.zoneElement.is_ebg);
  }

  public move(value: number): void {
    const offset = this.position - value;

    if (offset < -100) {
      this.carousel.prev();
    }

    if (offset > 100) {
      this.carousel.next();
    }
  }

  public onSlide(event: NgbSlideEvent): void {
    const ctaIndex = parseInt(event.current.split('').reverse().join(''), 10);
    const ctaElement = this.ctaElements[ctaIndex];
    if (ctaElement) {
      this.sendCtaDisplayedEvent(ctaElement);
    }
  }

  public async handleCtaClick(ctaElement: CtaOptions): Promise<void> {
    this.analyticsService.eventsTrack(
      this.ctaElementService.getAnalyticsEvents('click', this.zoneElement, ctaElement)
    );
    await this.ctaElementService.handleClick(ctaElement, this.zoneElement);
  }

  public trackByGuid(item: CtaOptions): string {
    return item.guid;
  }

  private getCtaElements(zoneElement: ZoneSectionElement): CtaOptions[] {
    return zoneElement.ads.slice(0, MAX_CTA_ELEMENTS).map(ad => {
      const ctaElement: CtaOptions = {
        ad,
        guid: ad.guid,
        ad_id: ad.id,
        ad_source: ad.source,
        isEbg: ad.isEbg,
        campaign: ad.campaign,
        category: ad.catCodeTagIds,
        type: this.zoneElement.ad_type,
        brand_name: ad.brand_name,
        offer_guid: ad.contentGuid,
      };
      ad.content.forEach(i => ctaElement[i.key] = i.value);

      if (!ctaElement.header && ad.titleShort) {
        ctaElement.header = ad.titleShort;
      }

      if (!ctaElement.button_txt && ad.ctaButtonTitle) {
        ctaElement.button_txt = ad.ctaButtonTitle;
      }

      if (!ctaElement.button_url && ad.destinationUrl) {
        ctaElement.button_url = ad.destinationUrl;
      }

      if (ctaElement.brand_name) {
        ad.company = ctaElement.brand_name;
      }

      if (ctaElement.logo_url) {
        ad.vendorImage = ctaElement.logo_url;
      }

      return ctaElement;
    });
  }

  private sendCtaDisplayedEvent(ctaElements: CtaOptions): void {
    this.analyticsService.eventsTrack(
      this.ctaElementService.getAnalyticsEvents('displayed', this.zoneElement, ctaElements)
    );
  }
}
