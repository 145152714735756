/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
  Scroll,
  Params
} from '@angular/router';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ViewportScroller, DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import * as moment from 'moment';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import includes from 'lodash/includes';
import first from 'lodash/first';
import {
  GoogleAnalyticsService,
  MouseflowService,
  QueryParamService,
  UrlHelperService,
  UrlQueryParamItem,
  OneTrustService,
  QualtricsService,
  ACCOUNT_ENROLL_TYPES
} from 'g3-common-ui';

import { environment } from '@environments/environment';
import { AdobeAnalyticsEventsService } from './shared/services/adobe/units/adobe-analytics-events.service';
import { AdobeAnalyticsLaunchService } from './shared/services/adobe/adobe-analytics-launch.service';
import { AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AuthService, CorporateRedirectToken } from '@app/core/auth/services/auth.service';
import { ConfigService } from '@app/shared/services/config.service';
import { ContactFormService } from '@shared/services/contact-form.service';
import { HeaderOffset } from '@core/layouts/home/anchors.enum';
import { ModalService } from '@shared/services/modal.service';
import { NpsSurveyModalComponent } from './nps-survey/components/nps-survey-modal/nps-survey-modal.component';
import { OAuthEvent } from '@core/auth/services/auth-events';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { ThemeStylesService } from '@shared/services/theme-styles.service';
import { HomepageDisclaimerComponent } from './homepage/components/homepage-disclaimer/homepage-disclaimer.component';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { VaultOtpModalComponent } from '@shared/components/vault-otp-modal/vault-otp-modal.component';
import { ContactTypes } from '@app/shared/services/one-time-password.service';
import { KnownUserService } from '@shared/services/known-user.service';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { CorporateRedirectService } from '@core/auth/services/corporate-redirect.service';
import { FinalStepTypes } from './shared/enums/confirmation-dropdown.enum';
import { MregService } from '@shared/services/mreg/mreg.service';
import { UserContactsService } from './shared/services/user-contacts/user-contacts.service';
import { MREG_ENTRY_DATE } from './shared/constants/mreg.constants';
import { SOURCE_ID } from './shared/constants/source-id.constants';
import { PaylogixService } from './shared/services/paylogix/paylogix.service';
import { SplitTestEventsService } from './shared/services/split-tests/split-test-events.service';

const skipQualtricsPaths = [
  '/sign-out',
  '/sign-in',
  '/account-deleted',
  '/select-country',
  '/redirect',
  '/test-g3-header',
  '/test-wasp',
  '/test-web-components',
  '/no-site',
  '/pdf',
  '/unsubscribe',
  '/relay'
];

const skipDisclaimerPage = [
  '/relay'
];
const defaultPhoneCode = '1';
const SESSION_DISCLAIMER_DISMISS_KEY = 'session-disclaimer-shown-time';
const SHOW_DROPDOWN_DELAY = 3000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  providers: [QueryParamService]
})

export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  public title = 'app';
  private routerSubscription: Subscription;
  private fragmentRouteSubscription: Subscription;
  private destroyStream = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private router: Router,
    private _ngZone: NgZone,
    private meta: Meta,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private contactFormService: ContactFormService,
    private analyticsService: AnalyticsService,
    private themeStylesService: ThemeStylesService,
    private viewportScroller: ViewportScroller,
    private urlHelper: UrlHelperService,
    private modalService: ModalService,
    private profileService: ProfileService,
    private permissionService: PermissionService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private adobeAnalyticsLaunchService: AdobeAnalyticsLaunchService,
    private localStorageService: LocalStorageService,
    private knownUserService: KnownUserService,
    private accountConfirmationService: AccountConfirmationService,
    private corporateRedirectService: CorporateRedirectService,
    private mregService: MregService,
    private readonly userContactsService: UserContactsService,
    private readonly oneTrustService: OneTrustService,
    private readonly queryParamService: QueryParamService,
    private readonly mouseflowService: MouseflowService,
    private readonly paylogixService: PaylogixService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly splitTestEventsService: SplitTestEventsService,
    @Inject(DOCUMENT) private document: Document,
    private readonly qualtricsService: QualtricsService,
  ) { }

  @HostListener('document:keyup', ['$event'])
  public keyEvent(ev: KeyboardEvent): void {
    if (ev.key === 'Tab') {
      this.document.body.classList.add('user-is-tabbing');
    }
  }

  @HostListener('document:mousedown', ['$event'])
  public onMouseDown(ev: MouseEvent): void {
    this.document.body.classList.remove('user-is-tabbing');
  }

  public async ngOnInit(): Promise<void> {
    this.adobeAnalyticsLaunchService.startTracking();
    const searchString = window.location.search;

    const isNotGuest = this.permissionService.hasDefined('profile:view');
    const forceNpsModal = this.profileService.setForceNpsModal(searchString);

    if (isNotGuest || forceNpsModal) {
      this.invokeSurveyModal();
    }

    // set title
    this.document.title = this.configService.getOption<string>('name', '');
    const description = this.configService.getOption<string>('description', '');
    this.meta.addTag({ name: 'description', content: description });

    // set favicon
    const favicon = this.configService.getOption<string>('favicon', '');
    if (favicon) {
      this.setFavicon(favicon);
    } else {
      this.setEmptyFavicon();
    }

    this.googleAnalyticsService.gtm('dataLayer');

    const gtagId = this.configService.getGtagId();
    if (gtagId) {
      this.googleAnalyticsService.gtagInit('dataLayer', gtagId);
    }

    const subdomain = this.configService.getOption<string>('subdomain', '');
    this._ngZone.runOutsideAngular(() => void this.mouseflowService.init(subdomain, window, this.document));

    this.themeStylesService.setCustomStyles();

    this.analyticsService.connectG3Analytics();

    void this.analyticsService.assignSplitTests().then(() => {
      this.profileService.profileData$.pipe(
        filter(data => !!data.guid),
        take(1)
      ).subscribe(() => this.splitTestEventsService.emitSessionStartEvent());
    });

    this.routerSubscription = this.router.events
      .subscribe(event => {

        // in future this list should be moved into separate logic
        const excludeRoutes = new Set(['/offers']);

        if (event instanceof NavigationEnd) {
          this.handleQualtrics(event);

          if (this.authService.isAuthenticated_BypassTokenHarvest() && !skipDisclaimerPage.some(path => event.urlAfterRedirects.includes(path))) {
            this.openDisclaimer();
          }

          // reset tab order
          requestAnimationFrame(() => this.document.getElementsByTagName('body')[0].focus());

          // for this list of
          if (this.activatedRoute.snapshot.fragment === 'sf-success') {
            this.contactFormService.openConfirmAlertModal();
          }
          if (this.activatedRoute.snapshot.fragment === 'sf-success-nominate') {
            this.contactFormService.openConfirmAlertModal({
              title: 'Thanks, we\'ve received your nomination.',
              text: 'Nominations are prioritized based on a number of factors, and worked by the team in priority order. ' +
                'Because of high nomination volume, the team does not personally reply to nomination requests.',
              buttonText: 'Close',
              isButton: true
            });
          }
          if (!excludeRoutes.has(event.urlAfterRedirects)) {
            void this.analyticsService.eventTrack(new AnalyticsInternalEventModel('page-load', { source: 'main-application' }));
          }

          this.splitTestEventsService.emitPageViewEvents({ url: event.urlAfterRedirects || event.url });
          this.queryParamService.updateUrlQueryParams();
        }
      });

    await this.handleCountryParam(searchString);

    this.authService.setupAutomaticSilentRefresh();
    // verify cookie for redirect domain
    if (!sessionStorage.getItem('clickout_test')) {
      this.document.cookie = `clickout_test=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${window.location.hostname.slice(window.location.hostname.indexOf('.'))}`;
    }
    // when user loads an app
    // we need to track specific query params and save them into internal analytics
    if (searchString) {
      const queryString = searchString.substring(1, searchString.length);
      if (queryString.indexOf('clickout_test=true') !== -1) {
        this.document.cookie = `clickout_test=true;path=/;domain=${window.location.hostname.slice(window.location.hostname.indexOf('.'))}`;
        sessionStorage.setItem('clickout_test', 'true');
      }
      // this setTimeout is required
      // to solve the problem with emitting exact the same amount of events per query params
      setTimeout(() => {
        this.firstVisitTrackQueryParams(queryString);
      });
    }
  }

  public ngAfterViewInit(): void {
    // note: this is code to cause scroll to jump to correct location after data load.
    // it is a general fix of the issue of angular6.1 reported here: https://github.com/angular/angular/issues/24547
    // it comes from this sample code, but I added 2s timeout to wait for data to load
    // --> https://stackblitz.com/edit/angular-router-scroller?file=src%2Fapp%2Fapp.component.ts
    //  I have a better fix for the homepage see src/app/core/layouts/home
    // that fix is also to workaround the angular 6.1 bug 24547


    // TODO
    // We are using this query params for testing and demo Vault OTP modal, probably this will be removed
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      takeUntil(this.destroyStream)
    ).subscribe(async (data: RoutesRecognized) => {
      const queryIndex = data.url.indexOf('?');
      const queryString = queryIndex > -1 ? data.url.slice(queryIndex + 1) : '';
      const queryParams = { ...this.urlHelper.parseQueryString(queryString), ...data.state.root.queryParams };
      const currentState = this.router.getCurrentNavigation();
      const currentUrl = decodeURIComponent(window.location.href);

      const sourceId = queryParams[SOURCE_ID];
      if (sourceId) {
        sessionStorage.setItem(SOURCE_ID, sourceId);
      }

      this.queryParamService.init(data.url);
      this.handleKnownParameters(queryParams);
      this.handleAdobeParameters(queryParams);
      this.profileService.handleCountryLastByUserRole();
      this.accountConfirmationService.initialize();

      if (currentUrl.includes(CorporateRedirectToken.PREFIX_TOKEN)) {
        const corporateRedirectUri = currentUrl.split(CorporateRedirectToken.PREFIX_TOKEN)
          .filter(value => value.includes(CorporateRedirectToken.SUFFIX_TOKEN))
          .map(value => first(value.split(CorporateRedirectToken.SUFFIX_TOKEN)))
          .pop();

        if (this.corporateRedirectService.isValidRedirectUrl(corporateRedirectUri)) {
          void this.corporateRedirectService.corporateRedirect(corporateRedirectUri);

          return;
        }
      } else if (this.corporateRedirectService.isAllowedCorporateRedirect(queryParams)) {
        void this.corporateRedirectService.corporateRedirect(queryParams['corpRedirectUri']);

        return;
      }

      if (includes(queryParams, 'vault_email' && queryParams.vault_email)) {
        this.openVaultOtpModal('email', queryParams.vault_email);
      }

      if (queryParams['final_step']) {
        this.accountConfirmationService.navigateToFinalStepAfterNextReload(FinalStepTypes.confirmEmail);
      }

      const DLKValue = queryParams['DLK'] || queryParams['dlk'];
      const knownOrGuest = this.permissionService.hasAnyDefined(['guest:access', 'known:access']);
      const userBypassDlkCheck = this.permissionService.hasDefined('user:dlk:bypass');
      let isBlockedDLK = false;

      if (DLKValue && !knownOrGuest && !userBypassDlkCheck) {
        isBlockedDLK = await this.knownUserService.getBlockedDLKValue(DLKValue);
      }

      if (DLKValue && knownOrGuest && !queryParams['known_user'] || isBlockedDLK) {
        let redirectUri = '';

        if (!queryParams['redirect_uri'] && !queryParams['k_redirect_uri']) {
          const currentUrlObject = new URL(currentUrl);
          currentUrlObject.searchParams.delete('dlk');
          currentUrlObject.searchParams.delete('DLK');
          redirectUri = encodeURIComponent(currentUrlObject.toString());
        }

        const authDomain = this.authService.getAuthDomain();
        const subdomain = this.authService.getSubdomain();
        let resultParams = queryParams;

        if (isBlockedDLK && DLKValue) {
          const { dlk, DLK, ...params } = queryParams;
          resultParams = params;

          // * Sign out active user
          await this.signOutAndRedirectToAuth();
          return;
        }

        const hasClientId = !!resultParams.client_id;
        const params = this.stringifyQueryParams(resultParams);
        const clientId = hasClientId
          ? ''
          : `&client_id=${environment.oauth.clientId}`;

        const resultUrl = `${authDomain}/${subdomain}/sign-in?${params}${redirectUri ? '&redirect_uri=' + redirectUri + clientId : ''}`;
        window.location.href = resultUrl;

        return;
      } else if (DLKValue && userBypassDlkCheck) {
        if (queryParams['DLK']) {
          this.queryParamService.removeParamFromUrl('DLK');
        }
        if (queryParams['dlk']) {
          this.queryParamService.removeParamFromUrl('dlk');
        }
      }

      if (queryParams['navrefresh'] && this.authService.hasAccessToken()) {
        this.queryParamService.removeParamFromUrl('navrefresh');
      }

      this.handleOneTrust();

      const isPhoneMode = includes(queryParams, 'vault_phone' && queryParams.vault_phone);
      if (isPhoneMode) {
        this.openVaultOtpModal('phone', `${queryParams.vault_phone_code || defaultPhoneCode}|${queryParams.vault_phone}`);
      }

      const knownUserType = this.knownUserService.knowUserType || '';
      const isKnownNotSetFlow = knownUserType.includes('not_set') && !this.knownUserService.isKnownSkipConfirm && !this.knownUserService.isKnownSkipPassword;

      if (!isKnownNotSetFlow && !queryParams['tdd_guid'] && !queryParams['off_guid'] && !queryParams['vault_email']) {

        setTimeout(() => {
          // * Check for mreg
          void this.handleMreg(queryParams, knownUserType);

          const isPaylogixAllowed = this.paylogixService.isPaylogixPromptAvailable();
          if (
            queryParams['prompt'] === 'pylgx' &&
            isPaylogixAllowed &&
            this.authService.hasAccessToken() &&
            !currentState.extras.state.triggeredByPaylogix &&
            !this.profileService.isGuestOrKnownUser()
          ) {
            this.accountConfirmationService.openPaylogixProfileModal('Parameter');
            this.queryParamService.removeParamFromUrl('prompt');
          }

          this.accountConfirmationService.checkAndOpenProfilePromptModal();
        }, SHOW_DROPDOWN_DELAY);
      }
    });

    this.router.events.pipe(
      filter(e => e instanceof Scroll),
      takeUntil(this.destroyStream)
    ).subscribe((e: any) => {
      // this is fix for dynamic generated(loaded..?) content
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
          this.viewportScroller.setOffset(HeaderOffset);
        }
      });
    });
  }

  private handleAdobeParameters(queryParams: Params): void {
    if (queryParams['adobeEnrollType']) {
      const adobeEnrollType = queryParams['adobeEnrollType'] || '';
      this.queryParamService.removeParamFromUrl('adobeEnrollType');
      this.queryParamService.updateUrlQueryParams();
      // if the event wasn't sent on the first try, we'll try to resend it on the next load
      localStorage.setItem('adobeEnrollType', adobeEnrollType);
    }
    this.checkAndSendAccountEnrollEvents();
  }

  private checkAndSendAccountEnrollEvents(): void {
    const adobeEnrollType = localStorage.getItem('adobeEnrollType');
    if (!adobeEnrollType) {
      return;
    }

    this.profileService.getUserEncryptedEmail(true)
      .subscribe(encryptedLogin => {
        let userGuidObservable: Observable<string>;

        if (this.knownUserService.knowUserGuid) {
          userGuidObservable = of(this.knownUserService.knowUserGuid);
        } else {
          userGuidObservable = this.profileService.profileData$.pipe(
            filter(data => !!data.guid),
            map(user => user.guid)
          );
        }

        userGuidObservable.pipe(take(1)).subscribe(userGuid => {
          if (!localStorage.getItem('adobeEnrollType')) {
            return;
          }

          localStorage.removeItem('adobeEnrollType');
          this.adobeAnalyticsEventsService.emitNewAccountEnrollEvent(adobeEnrollType, userGuid, encryptedLogin);

          // We trigger 'enroll_email_confirm' event after OTP completing.
          // Since SSO and Google users do not confirm those accounts by OTP - we fire this event here for now
          if (adobeEnrollType === ACCOUNT_ENROLL_TYPES.SSO || adobeEnrollType === ACCOUNT_ENROLL_TYPES.OAuthGoogle) {
            this.adobeAnalyticsEventsService.emitConfirmEmailEvent(adobeEnrollType, userGuid, encryptedLogin);
          }
        });
      });

  }

  public handleKnownParameters(queryParams: Params): void {
    const knownEmail = queryParams['known_email'];

    if (knownEmail) {
      this.knownUserService.clearAll();
      this.knownUserService.setKnownUserEmail(knownEmail);
      this.queryParamService.removeParamFromUrl('known_email');
      this.queryParamService.removeParamFromUrl('known_user');

      const skipConfirm = queryParams['skip_confirm'];
      if (skipConfirm) {
        this.knownUserService.setKnownSkipConfirm(skipConfirm);
        this.queryParamService.removeParamFromUrl('skip_confirm');
      }

      const skipPassword = queryParams['skip_password'];
      if (skipPassword) {
        this.knownUserService.setKnownSkipPassword(skipPassword);
        this.queryParamService.removeParamFromUrl('skip_password');
      }

      const rememberMe = queryParams['remember_me'];
      if (rememberMe === 'false') {
        this.knownUserService.setKnownRememberMe(false);
        this.queryParamService.removeParamFromUrl('remember_me');
      }

      const knownUserType = queryParams['known_user_type'];
      if (knownUserType) {
        this.knownUserService.setKnownUserType(knownUserType);
        this.queryParamService.removeParamFromUrl('known_user_type');
      }

      const knownGuid = queryParams['known_user_guid'];
      if (knownGuid) {
        this.knownUserService.setKnownUserGuid(knownGuid);
        this.queryParamService.removeParamFromUrl('known_user_guid');
      }

      const childGuid = queryParams['known_child_guid'];
      if (childGuid) {
        this.knownUserService.setKnownChildGuid(childGuid);
        this.queryParamService.removeParamFromUrl('known_child_guid');
      }

      const childDLK = queryParams['known_child_dlk'];
      if (childDLK) {
        this.knownUserService.setKnownChildDlk(childDLK);
        this.queryParamService.removeParamFromUrl('known_child_dlk');
      }

      const knownEmailContact = queryParams['known_email_contact'];
      if (knownEmailContact) {
        this.knownUserService.setKnownUserEmailContact(knownEmailContact);
        this.queryParamService.removeParamFromUrl('known_email_contact');
      }

      this.queryParamService.updateUrlQueryParams();
    }
  }

  public ngAfterViewChecked(): void {
    const feedbackButton = document.querySelector<HTMLElement>('#QSIFeedbackButton-btn');
    if (feedbackButton) {
      const ariaExpanded = feedbackButton.getAttribute('aria-expanded');
      if (ariaExpanded === 'true') {
        feedbackButton.classList.remove('feedback-button');
      } else {
        feedbackButton.classList.add('feedback-button');
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.fragmentRouteSubscription) {
      this.fragmentRouteSubscription.unsubscribe();
    }
    if (this.analyticsService) {
      this.analyticsService.disconnectG3Analytics();
    }

    this.destroyStream.next();
    this.destroyStream.complete();
  }

  private firstVisitTrackQueryParams(queryParamsAsString: string): void {
    const queryParams = this.urlHelper.getTrackingQueryParams(queryParamsAsString);
    if (queryParams.length) {
      queryParams.forEach((queryParam: UrlQueryParamItem) => {
        const eventData = {};
        eventData[queryParam.key] = queryParam.value;
        void this.analyticsService.eventTrack(new AnalyticsInternalEventModel('first-visit-qs', eventData));
      });
    }
  }

  async handleCountryParam(searchString: string): Promise<void> {
    if (searchString) {
      const queryString = searchString.substring(1, searchString.length);
      const queryParams = this.urlHelper.parseQueryString(queryString);
      if (queryParams['country'] && !queryParams['entry_redirect']) {

        try {
          await this.profileService.updateCurrentCountry(queryParams['country']);

          const countryUrl = this.profileService.getCountryUrl(queryParams['country']);
          const isExternalUrl = this.urlHelper.isExternalUrl(countryUrl);
          if (isExternalUrl) {
            await this.profileService.clearUserCountryLast();
          }

          if (countryUrl) {
            window.location.href = countryUrl;
          }

        } catch (e) {
          console.error(e);
        }
        return;
      }
    }
  }

  private handleOneTrust(): void {
    if (this.oneTrustService.isOneTrustInited) {
      return;
    }

    void this.oneTrustService.initOneTrust().then(async () => {
      const blockDefaultTracking = await this.oneTrustService.inited$.pipe(take(1)).toPromise();

      // TODO Remove log line when OneTrust is fully roll-out
      console.info(`OneTrust: Tracking is ${blockDefaultTracking ? '' : 'not '}blocked`);
    });
  }

  private setFavicon(favicon: string): void {
    const faviconExt = favicon.split('.').pop();

    const link = this.document.createElement('link');
    link.type = faviconExt === 'png' ? 'image/png' : 'image/x-icon';
    link.rel = 'icon';
    link.href = favicon;
    link.setAttribute('data-qa', 'main-favicon');
    this.document.head.appendChild(link);
  }

  /**
   * Sets empty value in case favicon is deleted.
   * Helps to prevent Chrome from loading previously loaded favicon
   */
  private setEmptyFavicon(): void {
    const link = this.document.createElement('link');

    link.rel = 'icon';
    link.href = 'data:;base64,=';
    this.document.head.appendChild(link);
  }

  private openVaultOtpModal(type: ContactTypes, contact: string): void {
    this.modalService.createModal({
      component: VaultOtpModalComponent,
      attributes: [
        {
          key: 'type',
          value: type
        },
        {
          key: 'contact',
          value: contact
        },
        {
          key: 'sendImmediately',
          value: true
        }
      ],
      options: {
        windowClass: 'vault-otp-modal',
        backdrop: 'static',
        animation: false
      }
    });
  }

  private handleQualtrics(event: NavigationEnd): void {
    if (skipQualtricsPaths.some(path => event.urlAfterRedirects.includes(path))) {
      this.qualtricsService.removePreviousQualtrics();
    } else {
      if (!this.qualtricsService.isQualtricsElementPresent()) {
        this.qualtricsService.initialize({
          isActive: this.configService.getFeature('feedback_tab', false),
          companyId: this.configService.getOption('wag3_admin_guid', ''),
          companyName: this.configService.getOption('salesforce_account_name', '')
        });
      }
    }
  }

  private openDisclaimer(): void {
    const disclaimer = this.configService.getOption('session_disclaimer');
    const marketplace = this.configService.getOption('name');

    const allowDisclaimer = disclaimer
      && !this.modalService.hasOpenModal()
      && !this.hasSessionDisclaimerBeenShown();

    if (allowDisclaimer) {
      this.modalService.createModal({
        component: HomepageDisclaimerComponent,
        attributes: [{
          key: 'disclaimer',
          value: { marketplace, content: disclaimer }
        }],
        options: { windowClass: 'session-disclaimer-modal', backdrop: 'static', animation: false }
      });
    }
  }

  private hasSessionDisclaimerBeenShown(): boolean {
    const disclaimerShownTime = this.localStorageService.getItem<number>(SESSION_DISCLAIMER_DISMISS_KEY);
    const currentTime = Date.now();

    return disclaimerShownTime ? (currentTime - disclaimerShownTime) / 1000 / 60 / 60 < this.configService.getOption<number>('session_disclaimer_hours') : false;
  }

  private invokeSurveyModal(): void {
    let isQualtricsDisplaySet = false;
    this.profileService.profileData$
      .pipe(
        filter(el => !!Object.keys(el).length),
        take(2)
      ).subscribe(profileData => {
        if (!isQualtricsDisplaySet) {
          this.qualtricsService.updateDisplayValue(profileData.surveys);
          isQualtricsDisplaySet = true;
        }

        if (profileData.surveys.length) {
          this.modalService.createModal({
            component: NpsSurveyModalComponent,
            options: {
              windowClass: 'nps-modal'
            }
          });
        }
      });
  }

  private stringifyQueryParams(params: Params): string {
    const str = [];

    for (const p in params) {
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }

    return str.join('&');
  }

  private async handleMreg(queryParams: Params, knownUserType: string): Promise<void> {
    // Relates to registration with MREG prompt
    // Prevent mreg modal from displaying if user registration not finished
    const mregFromStorage = knownUserType && !knownUserType.includes('child') || !this.permissionService.hasDefined('guest:access')
      ? localStorage.getItem('mreg')
      : '';

    if (mregFromStorage) {
      this.localStorageService.removeItem('mreg');

      // route mreg interceptor
      if (!queryParams['mreg']) {
        await this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { mreg: mregFromStorage },
          queryParamsHandling: 'merge',
        });
      }

      // This part will fire after user complete registration using MREG prompt
      // Update user contact Zeta sign up event (only for new user)
      await this.userContactsService.setUserContactSignUpEvent(mregFromStorage, this.knownUserService.knowUserGuid, this.knownUserService.knowUserEmail);
    }

    const isDailyEntry = this.getIsDailyEntry();
    const mreg = queryParams['mreg'];

    if (mreg) {
      await this.mregService.handleMregParam(mreg, this.knownUserService.knowUserGuid, isDailyEntry);
    }

    await this.handleUserDailyEntry(isDailyEntry);
    if (this.modalService.hasOpenModal()) {
      this.modalService.activeModalSubject.pipe(
        filter(modal => !modal)
      ).subscribe(() => {
        this.accountConfirmationService.showAdditionalNotifications().then(
          () => setTimeout(() => this.accountConfirmationService.checkAndOpenConfirmationDialog(), 10)
        );
      });
    } else {
      await this.accountConfirmationService.showAdditionalNotifications();
      this.accountConfirmationService.checkAndOpenConfirmationDialog();
    }
  }

  private async handleUserDailyEntry(isDailyEntry: boolean): Promise<void> {
    const isGuest = this.permissionService.hasDefined('guest:access');
    if (isGuest || !isDailyEntry) {
      return;
    }

    const isMregActive = this.configService.controls.mreg_active;
    if (!isMregActive) {
      return;
    }

    await this.mregService.addDailyEntry(this.knownUserService.knowUserGuid);
  }

  private getIsDailyEntry(): boolean {
    const entryDate = localStorage.getItem(MREG_ENTRY_DATE);

    let isSaveDay = false;
    if (entryDate) {
      isSaveDay = moment().isSame(entryDate, 'day');
    }

    return !entryDate || !isSaveDay;
  }

  private async signOutAndRedirectToAuth(): Promise<void> {
    this.authService.setUnauthenticated();
    await this.authService.signOut();

    sessionStorage.clear();
    this.authService.redirectToSignInDirectly();
  }
}
