import { AdSource } from '@shared/models/ad.model';
import {
  AnalyticsAdEventModel,
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { getAnalyticsActionByZoneSectionElement, getAnalyticsGACategoryByZoneSectionElement } from '@zones/services/analytics/helpers';
import { Injectable } from '@angular/core';
import { ZonesSectionElementItemsAnalyticsService } from '@zones/services/analytics/zones-section-element-items-analytics.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

type ActionType = 'items-selected'
| 'view-ads'
| 'view-all-click';


@Injectable({
  providedIn: 'root'
})
export class ZonesSectionElementsAnalyticsService {

  constructor(
    public items: ZonesSectionElementItemsAnalyticsService
  ) { }

  getAnalyticsEvents(action: ActionType, zoneSectionElement: ZoneSectionElement): AnalyticsEvent[] {
    const analyticsAction = getAnalyticsActionByZoneSectionElement(zoneSectionElement, action);
    switch (action) {
      case 'items-selected':
        if (!['cards_fixed', 'cards_scroll'].includes(zoneSectionElement.type)) {
          return [];
        }
        const internalAnalyticsData = zoneSectionElement.ads.map(i => this.items.getZoneElementItemAnalyticsData(i));
        return [
          new AnalyticsInternalEventModel(analyticsAction, {
            items: internalAnalyticsData
          })
          /** * REM per story #9788 **
            new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: this.items.getZoneElementsItemsGALabel(internalAnalyticsData)}) ****/
        ];
      case 'view-ads':
        if (!['cards_fixed', 'cards_scroll'].includes(zoneSectionElement.type)) {
          return [];
        }
        const ads = zoneSectionElement.ads.filter(i => i.source === AdSource.BSS).map(i => ({ response_id: i.id }));
        if (ads.length === 0) {
          return [];
        }
        return [
          new AnalyticsAdEventModel('g3-ad-results', {
            date_time: new Date(),
            ads
          }),
          new AnalyticsInternalEventModel('g3-ad-results', {
            date_time: new Date(),
            ad_type: zoneSectionElement.type,
            ads
          }),
          new AnalyticsGAEventModel('g3-ad-results', {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: ads.map(i => i.response_id).join(' | ')
          })
        ];
      case 'view-all-click':
        return [
          new AnalyticsInternalEventModel(analyticsAction, {
            guid: zoneSectionElement.guid
          }),
          new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: zoneSectionElement.guid
          })
        ];
    }
    return [];
  }
}
