/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Notification, NotificationDismissTypes } from 'g3-common-ui';
import { SystemNotificationModel } from '@shared/services/notifications/system-notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private viewedNotifications = new Set<number>();

  constructor(
    private http: HttpClient
  ) {
  }

  public updateViewedNotifications(items: number[]): number[] {

    items.forEach(i => {
      if (!this.viewedNotifications.has(i)) {
        this.viewedNotifications.add(i);
      }
    });

    return Array.from(this.viewedNotifications.values());

  }

  async addToViewedNotifications(item: Notification, actionType: string, location: string): Promise<number[]> {
    if (item.dismissType === NotificationDismissTypes.Session
      || item.dismissType === NotificationDismissTypes.Persistent) {
      return this.updateViewedNotifications([item.guid as number]);
    }

    const items = await this.http.patch(`${environment.apiUrl}/api/my/notifications`, {
      action_type: actionType,
      location,
      dismiss_type: item.dismissType
    }).toPromise() as number[];

    return this.updateViewedNotifications(items || []);
  }

  async getViewedNotifications(location: string): Promise<number[]> {

    const items = await this.http.get(`${environment.apiUrl}/api/my/notifications/${location}`)
      .toPromise() as number[];

    return this.updateViewedNotifications(items);
  }

  getSystemWideNotifications(filteredGuids: string[]) {
    filteredGuids = filteredGuids || [];

    return this.http.get<SystemNotificationModel[]>(`${environment.apiUrl}/api/notifications/system-wide`).pipe(
      map(items => (items || []).filter(i => !filteredGuids.includes(i.guid)))
    );
  }

}
