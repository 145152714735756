<div class="logo {{ logoContainerClass }}"
     *ngIf="path">

  <div *ngIf="route; else noLink">
    <a *ngIf="route"
       tabindex="-1"
       (click)="onClickLogo()">
      <img [src]="path | imageUrl : logoOptions"
           loading="lazy"
           alt="{{ title }}" />
    </a>
  </div>

  <ng-template #noLink>
    <img [src]="path | imageUrl : { w: 146, h: 31, fit: 'clip' }"
         alt="{{ title }}"
         class="{{ imgClass }}" />
  </ng-template>

</div>
