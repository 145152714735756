/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import { MapMarkerPreviewService } from '@app/shared/services/map-marker-preview.service';
import { Marker } from '@app/shared/interfaces/marker.interface';
import { OPACITY_FADE_IN_OUT_ANIMATION, WIDTH_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { ProviderLocationsService } from '../../services/provider-locations.service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.less'],
  animations: [WIDTH_ANIMATION, OPACITY_FADE_IN_OUT_ANIMATION]
})

export class ListViewComponent implements OnInit {

  @Input() markerGuid: string;
  @Input() expandCard: boolean;
  @Input() groupTabIndex: number;
  @Input() isDesktop: boolean;
  @Input() zipSuggestions: string[];
  @Input() zipCodeInvalid: boolean;
  @Input() zipCode: string;
  @Input() activatedMarker = {} as Marker;
  @Input() set selectedLocation(item: Marker) {
    if (item && this.items && this.items.length && this.isDesktop) {
      this.activateMarker.emit(item);
      this.showPanel = true;
      this.items.find(i => i.guid === item.guid).showMore = true;
      setTimeout(() => this.setFooterWidth(), 100);  // Timeout provides to wait while offers panel will be opened
      setTimeout(() => { // Timeout provides to wait while offers panel will be opened and then scroll into offer card
        const scrollableZone = document.querySelector<HTMLElement>('.scrollable');
        const offerCard = document.querySelector<HTMLElement>(`.c${item.guid}`);
        const offsetTop = offerCard.getBoundingClientRect().top;
        this.expandCard = true;
        const topPosition = this.getTopPosition();
        scrollableZone.scrollBy(0, offsetTop - topPosition);
        this.mapMarkerPreviewService.highlightCard(item.guid, true);
      }, 500);
    }
  }

  @Output() activateMarker = new EventEmitter<Marker>();
  @Output() zipSelected = new EventEmitter<string>();
  @Output() panelState = new EventEmitter<boolean>();

  items: Marker[];
  showPanel = false;
  showPanelTooltipContent = 'Show List View';
  itemsPerPage = 20;
  total: number;
  offset: number;
  lastIndex: number;
  DESKTOP_VIEWPORT = 1280;
  isFiltersSelected = false;
  hideListView = 'Hide List View';
  showListView = 'Show List View';

  constructor(
    private providerLocationsService: ProviderLocationsService,
    private directClickOutService: DirectClickOutService,
    private mapMarkerPreviewService: MapMarkerPreviewService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(e: any): void {
    if (e.target.innerWidth >= this.DESKTOP_VIEWPORT && this.showPanel === true) {
      const itemContainer: HTMLElement = document.querySelector('.item-container');
      const contentContainer: HTMLElement = document.querySelector('.content-container');
      if (itemContainer && itemContainer.offsetWidth > 0) {
        contentContainer.style.width = `${itemContainer.offsetWidth}px`;
      }
    }

    this.showPanel = e.target.innerWidth >= this.DESKTOP_VIEWPORT;
  }

  ngOnInit(): void {
    if (window.matchMedia('(min-width: 1280px)').matches) {
      this.showPanel = true;
      this.showPanelTooltipContent = this.hideListView;
    } else {
      this.showPanel = false;
      this.showPanelTooltipContent = this.showListView;
    }
    this.providerLocationsService.markers$.subscribe(items => {
      this.items = items.data;
      this.total = items.total;
      this.lastIndex = items.lastIndex;
      this.offset = items.offset;
    });
  }
  trackAnalyticsEvent($e: any): void {
    //
  }


  setFooterWidth(): void {
    if (this.showPanel === true) {
      const itemContainer: HTMLElement = document.querySelector('.item-container');
      const contentContainer: HTMLElement = document.querySelector('.content-container');
      if (itemContainer && itemContainer.offsetWidth > 0) {
        contentContainer.style.width = `${itemContainer.offsetWidth}px`;
      }
    }
  }

  // Timeout provides to wait while offers panel will be opened (Mobile)
  handleFooterWidth(): void {
    setTimeout(() => this.setFooterWidth(), 400);
  }

  next(): void {
    if (this.offset + this.itemsPerPage <= this.total) {
      this.offset += this.itemsPerPage;
      this.lastIndex = this.offset + this.itemsPerPage;
      this.paginate();
      this.directClickOutService.providerLocations.trackAnalyticsEvents('provider-location-next-page', null, Math.floor(this.offset / this.itemsPerPage) + 1);
    }
  }

  prev(): void {
    if (this.offset - this.itemsPerPage >= 0) {
      this.lastIndex = this.offset;
      this.offset -= this.itemsPerPage;
      this.paginate();
    }
  }

  paginate(): void {
    this.providerLocationsService.paginate(this.offset, this.lastIndex);
  }

  onZipSelected(zip: string): void {
    this.zipSelected.emit(zip);
    if (this.zipCode !== zip) {
      this.isFiltersSelected = true;
    }
  }

  onFilterSelected(event: boolean): void {
    this.isFiltersSelected = event;
  }

  showPanelStatus(showPanel: boolean): void {
    this.panelState.emit(showPanel);
    this.showPanelTooltipContent = showPanel ? this.showListView : this.hideListView;
  }

  showFilterPanel(): void {
    document.getElementById('filter-control-panel').classList.add('panel-visible');
  }

  closeFilterPanel(): void {
    document.getElementById('filter-control-panel').classList.remove('panel-visible');
  }

  public addHoverClass(e: any): void {
    e.target.classList.add('hover');
  }

  public removeHoverClass(e: any): void {
    e.target.classList.remove('hover');
  }

  getTopPosition(): number {
    const notificationBar = document.querySelector('app-notification');
    let topPosition = !!notificationBar ? 200 : 120;
    const notiBlockEl: HTMLElement = document.querySelector('.noti-block');
    if (notiBlockEl) {
      topPosition += notiBlockEl.offsetHeight;
    }
    return topPosition;
  }
}
