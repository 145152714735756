import { AdobeBrandspageDataService } from './adobe-brandspage-data.service';
import { AdobeCategoriespageDataService } from './adobe-categoriespage-data.service';
import { AdobeOfferDetailspageDataService } from './adobe-offerdetailspage-data.service';
import { AdobeHomepageDataService } from './adobe-homepage-data.service';
import { AdobeNearMeMapsPageDataService } from './adobe-nearmepage-data.service';
import { AdobeSearchPageDataService } from './adobe-searchpage-data.service';
import { AdobePoliciesDataService } from './adobe-policies-data.service';
import { AdobeUserProfileDataService } from './adobe-user-profile-data.service';

export const AdobeUnitsServices = [
  AdobeHomepageDataService,
  AdobeSearchPageDataService,
  AdobeNearMeMapsPageDataService,
  AdobePoliciesDataService,
  AdobeUserProfileDataService,
  AdobeOfferDetailspageDataService,
  AdobeBrandspageDataService,
  AdobeCategoriespageDataService
];

export * from './adobe-homepage-data.service';
export * from './adobe-searchpage-data.service';
export * from './adobe-nearmepage-data.service';
export * from './adobe-policies-data.service';
export * from './adobe-user-profile-data.service';
export * from './adobe-offerdetailspage-data.service';
export * from './adobe-categoriespage-data.service';
export * from './adobe-brandspage-data.service';
