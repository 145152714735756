<p class="profile-prompt-zip__title">Complete Your Account</p>
<p class="profile-prompt-zip__sub-title">Improve your {{marketplaceName}} Marketplace experience and gain access to better deals by providing your home location.</p>
<form [formGroup]="zipForm"
        #form="ngForm"
        (ngSubmit)="onSubmit($event)">
    <div class="profile-prompt-zip__field">
        <label class="profile-prompt-zip__field__input-lable"
            for="zip">
            ZIP/Postal Code
        </label>
        <input name="zip"
            class='profile-prompt-zip__field__input'
            formControlName="zip"
            placeholder="ZIP/Postal Code"
            [maxlength]="maxZipCodeLength"
            type="text">
        <div *ngIf="zip.errors?.required && form.submitted"
            class="profile-prompt-zip__field__error">
            Please enter zip
        </div>
        <div *ngIf="zip.errors?.minlength && form.submitted"
             class="profile-prompt-zip__field__error">
            ZIP code field requires at least 3 characters
        </div>
        <div *ngIf="zip.errors?.pattern && !zip.errors?.minlength && form.submitted"
             class="profile-prompt-zip__field__error">
            Please enter valid ZIP/Postal Code
        </div>
    </div>
    <button class="btn btn-primary profile-prompt-zip__submit-button" type="submit">
        Confirm
    </button>
</form>