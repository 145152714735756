import { Ad, AdSource } from '@shared/models/ad.model';
import {
  AnalyticsAdEventModel,
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { getAnalyticsActionByZoneSectionElement, getAnalyticsGACategoryByZoneSectionElement, getAnalyticsGALabelFromObject } from '@zones/services/analytics/helpers';
import { Injectable } from '@angular/core';
import { UrlHelperService } from 'g3-common-ui';
import { ZoneElementTypes } from '@zones/services/zones.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

type ActionType = 'view'
| 'click-internal'
| 'click'
| 'click-out'
| 'click-copy-code'
| 'click-reveal-code';

export interface ZoneElementItemAnalyticsData {
  ad_guid?: string;
  offer_guid?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ZonesSectionElementItemsAnalyticsService {

  constructor(
    private urlHelper: UrlHelperService,
  ) { }

  getZoneElementItemAnalyticsData(item: Ad): ZoneElementItemAnalyticsData {
    if (item.source === AdSource.BSS) {
      return item.contentGuid ? { offer_guid: item.contentGuid, ad_guid: item.guid } : { ad_guid: item.guid };
    }
    if (item.source === AdSource.Natural) {
      const eventData = { offer_guid: item.guid };

      if (item.brandOverride) {
        eventData['brand_override'] = item.brandOverride;
      }

      return eventData;
    }
  }

  getZoneElementsItemsGALabel(data: ZoneElementItemAnalyticsData[]): string {
    return data.map(i => this.getZoneElementsItemGALabel(i)).join(',');
  }

  getZoneElementsItemGALabel(data: ZoneElementItemAnalyticsData): string {
    return getAnalyticsGALabelFromObject(data);
  }

  getGALabel(item: Ad): string {
    const result = [];
    if (item.source === AdSource.BSS && item.contentGuid) {
      result.push(item.contentGuid);
    }
    if (item.source === AdSource.Natural && item.guid) {
      result.push(item.guid);
    }
    if (item.company) {
      if (item.brandOverride) {
        result.push(`${item.brandOverride} - ${item.company}`);
      } else {
        result.push(item.company);
      }
    }
    if (item.titleShort) {
      result.push(item.titleShort);
    }
    if (item.source === AdSource.BSS && item.guid) {
      result.push(item.guid);
    }
    return result.join(' | ');
  }

  getAnalyticsEvents(action: ActionType, zoneSectionElement: ZoneSectionElement, ad: Ad): AnalyticsEvent[] {
    const gaCategory = getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement);
    let actionName;

    switch (action) {
      case 'view':
      case 'click':
      case 'click-internal':
        if (action === 'view' && ['cards_scroll', 'cards_fixed'].includes(zoneSectionElement.type)) {
          return [
            // note: intentionally returns empty array. cards_scroll and cards_fixed will call this
            //       just like other elemetns. but for these 2 types, we dont want to track each individual
            //       impression because it would be too much (5MM+impressions /day).
            //       AND we already track array with items-selected event.
          ];
        } else {
          actionName = getAnalyticsActionByZoneSectionElement(zoneSectionElement, action);
          const events = [
            new AnalyticsInternalEventModel(actionName, this.getZoneElementItemAnalyticsData(ad)),
          ];

          if (zoneSectionElement.type !== ZoneElementTypes.CardsProduct) {
            events.push(
              new AnalyticsGAEventModel(actionName, {
                category: gaCategory,
                label: this.getGALabel(ad)
              })
            );
          }
          return events;
        }
      case 'click-copy-code':
        actionName = getAnalyticsActionByZoneSectionElement(zoneSectionElement, 'copy-code');
        return [
          new AnalyticsInternalEventModel(actionName, this.getZoneElementItemAnalyticsData(ad)),
          new AnalyticsGAEventModel(actionName, {
            category: gaCategory,
            label: this.getGALabel(ad)
          })
        ];
      case 'click-reveal-code':
        actionName = getAnalyticsActionByZoneSectionElement(zoneSectionElement, 'reveal-code');
        return [
          new AnalyticsInternalEventModel(actionName, this.getZoneElementItemAnalyticsData(ad)),
          new AnalyticsGAEventModel(actionName, {
            category: gaCategory,
            label: this.getGALabel(ad)
          })
        ];
      case 'click-out':
        actionName = getAnalyticsActionByZoneSectionElement(zoneSectionElement, 'click-out');
        const clickOut = ad.isEbg || !this.urlHelper.isExternalUrl(ad.destinationUrl) ? 'internal' : 'external';
        const data = this.getZoneElementItemAnalyticsData(ad);

        data['click_out'] = clickOut;

        const result = [
          new AnalyticsInternalEventModel(actionName, data),
          new AnalyticsGAEventModel(actionName, {
            category: gaCategory,
            label: this.getGALabel(ad) + ` | ${clickOut}`
          })
        ];
        if (ad.source === AdSource.BSS) {
          result.push(new AnalyticsAdEventModel('g3-ad-click', { date_time: new Date(), response_id: ad.id }));
        }
        return result;
    }
    return [];
  }
}
