/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { AnalyticsService } from '@shared/services/analytics.service';
import { ClickOutService } from '@shared/services/click-out.service';
import { CouponModalComponent } from '@shared/components/coupon-modal/coupon-modal.component';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhoneCtaService } from '@shared/services/phoneCta.service';
import { TodayDeal } from '@shared/models/today-deal.model';
import { TodayDealsAnalyticsService } from '@shared/services/analytics/today-deals-analytics.service';
import { TodayDealsService } from '@shared/services/today-deals.services';
import { AdobeEventTypeEnum, AdobeTddClickEvent } from '@shared/services/adobe/adobe-analytics.interfaces';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';

@Injectable({
  providedIn: 'root'
})
export class DirectClickOutTodayDealsService {

  constructor(
    protected adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private modalService: NgbModal,
    private clickOutService: ClickOutService,
    private todayDealsAnalytics: TodayDealsAnalyticsService,
    private todayDealsService: TodayDealsService,
    private analyticsService: AnalyticsService,
    private phoneCtaService: PhoneCtaService,
  ) { }

  public getCouponModal(): NgbModalRef {
    return this.modalService.open(CouponModalComponent, {
      size: 'sm',
      centered: true,
      windowClass: 'custom-modal coupon-modal'
    });
  }

  public async handleClick(todayDeal: TodayDeal, onClick?: () => void, adobeInfo?: AdobeTddClickEvent) {

    this.trackClick(todayDeal, onClick);
    if (adobeInfo && adobeInfo.properties.offer.isEbg) {
      delete adobeInfo.properties.offer.shopping_category;
    }
    delete adobeInfo.properties.offer.isEbg;

    if (this.isDisplayModal(todayDeal)) {
      if (adobeInfo) {
        void this.adobeAnalyticsEventsService.emitGeneralEvent({
          event_name: adobeInfo.event_name,
          event_type: AdobeEventTypeEnum.REVEAL_CODE,
          properties: adobeInfo.properties
        });
      }
      this.trackRevealCode(todayDeal);
      const modal = this.getCouponModal();
      modal.componentInstance.brandLogo = todayDeal.image_url;
      modal.componentInstance.todayDeal = todayDeal;
      modal.componentInstance.code = todayDeal.coupon_code;
      modal.componentInstance.modalEvents.subscribe(async event => this.handleModalEvents(event, todayDeal, adobeInfo));
      modal.componentInstance.phoneAction.subscribe(url => {
        if (adobeInfo) {
          void this.adobeAnalyticsEventsService.emitGeneralEvent(adobeInfo);
        }
        this.phoneCtaService.phoneCtaClick(url, todayDeal);
      });
      modal.componentInstance.phoneClick.subscribe(phone => this.phoneCtaService.phoneClick(phone, todayDeal));
    } else {
      const type = todayDeal.url_type === 'dynamic' || todayDeal.url?.toLowerCase()?.includes('%ebghost%')
        ? 'offer_dynamic'
        : '';
      await this.clickOut(todayDeal, true, type);
      await this.todayDealsService.increaseClickOutCounter(todayDeal).toPromise();
      if (adobeInfo && todayDeal.url) {
        void this.adobeAnalyticsEventsService.emitGeneralEvent(adobeInfo);
      }
    }
  }

  public async clickOut(todayDeal: TodayDeal, useRedirect = true, type: string | null = null) {
    if (!todayDeal.url) {
      return;
    }

    const options = {
      analyticEvents: this.todayDealsAnalytics.getAnalyticsEvents('tdd-click-out', todayDeal),
    };

    if (useRedirect) {
      options['data'] = { logo: todayDeal.company_brand_tag_logo, brand: todayDeal.company_name, guid: todayDeal.guid };
    }

    if (type) {
      options['guid'] = todayDeal.guid;
    }
    await this.clickOutService.clickOut(todayDeal.url, type, options, todayDeal.new_tab);
  }

  public trackClick(todayDeal: TodayDeal, onClick?: () => void) {
    void this.todayDealsService
      .increaseViewCounter(todayDeal)
      .toPromise()
      .then(() => {
        if (onClick) {
          onClick();
        }
        this.analyticsService.eventsTrack(this.todayDealsAnalytics.getAnalyticsEvents('tdd-click', todayDeal));
      });
  }

  public trackView(todayDeal: TodayDeal): void {
    this.analyticsService.eventsTrack(this.todayDealsAnalytics.getAnalyticsEvents('tdd-view', todayDeal));
  }

  public trackRevealCode(todayDeal: TodayDeal) {
    this.analyticsService.eventsTrack(this.todayDealsAnalytics.getAnalyticsEvents('tdd-reveal-code', todayDeal));
  }

  public isDisplayModal(item: TodayDeal): boolean {
    return item.hasCouponCode();
  }

  private async handleModalEvents(event, todayDeal: TodayDeal, adobeInfo?: AdobeTddClickEvent) {
    if (event && event.type) {
      switch (event.type) {
        case 'click-copy-code':
          if (adobeInfo) {
            void this.adobeAnalyticsEventsService.emitGeneralEvent({
              event_name: adobeInfo.event_name,
              event_type: AdobeEventTypeEnum.COPY_CODE,
              properties: adobeInfo.properties
            });
          }
          this.analyticsService.eventsTrack(this.todayDealsAnalytics.getAnalyticsEvents('tdd-copy-code', todayDeal));
          break;
        case 'cta-button-click':
          if (adobeInfo) {
            void this.adobeAnalyticsEventsService.emitGeneralEvent(adobeInfo);
          }
          await this.clickOutService.clickOut(todayDeal.url, null, {
            analyticEvents: this.todayDealsAnalytics.getAnalyticsEvents('tdd-click-out', todayDeal),
            data: { logo: todayDeal.company_brand_tag_logo, brand: todayDeal.company_name, guid: todayDeal.guid }
          }, todayDeal.new_tab);
          await this.todayDealsService.increaseClickOutCounter(todayDeal).toPromise();
          break;
      }
    }
  }

}
