<div class="otp-modal">
  <div class="otp-modal__close">
    <button type="button"
            class="tp-modal__close-btn"
            aria-label="Close"
            (click)="closeModal()">
      <svg width="14.47"
           height="14"
           viewBox="0 0 14.47 14"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M15.174 1.41L13.7193 0L7.95221 5.59L2.18513 0L0.730469 1.41L6.49754 7L0.730469 12.59L2.18513 14L7.95221 8.41L13.7193 14L15.174 12.59L9.40687 7L15.174 1.41Z"
              fill="#353535" />
      </svg>
    </button>
  </div>
  <p class="otp-modal__header">
    Beneplace Rewards
  </p>
  <p class="otp-modal__sub-header">
    Please confirm your email<br>to unlock this offer
  </p>
  <div class="offer-card">
    <img  class="offer-card__image"
          [src]="offer?.vendorBrand?.logoSquare"
          loading="lazy">
    <p class="offer-card__title">
      <span class="notranslate">{{offer?.vendorBrand?.title}}: </span>
      <span>{{offer?.subtitle}}</span>
    </p>
  </div>
  <p class="otp-modal__form-label">
    Enter the code that we just sent to:
  </p>
  <app-otp-form type="email"
                [contact]="email"
                [isContactEditable]="false"
                sendImmediately="true"
                authenticate="true"
                [offerGuid]="offerGuid"
                (onValidCode)="onValidCode()">
  </app-otp-form>
</div>
