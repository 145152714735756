import { Directive, ElementRef, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile/profile.service';

@Directive({
  selector: '[appProfileFeatures]',
})
export class ProfileFeaturesDirective implements OnInit {

  constructor(
    private profileService: ProfileService,
    private elementRef: ElementRef
  ) { }

  public ngOnInit(): void {
    this.applyProfileFeatures();
  }

  public applyProfileFeatures(): void {
    const isProfileFeature = this.profileService.getOption('isProfileFeature');
    const element: HTMLElement = this.elementRef.nativeElement;

    if (isProfileFeature) {
      element.parentNode.removeChild(element);
    }
  }

}
