<section #ticketsResultContainer
         class="tickets-result {{ customClass }}">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div [@reverseHeightInOut]
       *ngFor="let item of ticketsData">
    <div [@opacityFadeInOut]
         *ngIf="!isLoading"
         class="tickets-result-item-separator"></div>
    <div *ngIf="!isLoading"
         (click)="onGetOffer(item)"
         class="tickets-result-item">
      <div class="tickets-result-item__img">
        <img *ngIf="item.listing_image_1x1"
             class="tickets-result-item__img--item"
             loading="lazy"
             [src]="item.listing_image_1x1">
        <img *ngIf="!item.listing_image_1x1"
             loading="lazy"
             class="tickets-result-item__img--item"
             src="/assets/images/image-template.png">
      </div>
      <div class="tickets-result-item__content">
        <div class="tickets-result-item__content__badge">
          <div *ngIf="item.promo"
               class="tickets-result-item__content__badge--item tickets-item-badge"
               [innerHTML]="item.promo"></div>
        </div>
        <div class="tickets-result-item__content__title"
             [innerHTML]="item.short_name"></div>
        <div class="tickets-result-item__content__description"
             [innerHTML]="item.short_description"></div>
        <div class="tickets-result__actions">
          <div class="tickets-result__actions__share">
            <app-share-button tooltipText="Offer info copied to clipboard"
                              placement="top"
                              customClass="share-tooltip tickets-widget-tooltip"
                              (shareClick)="shareClick(item)"
                              class="btn-save-small btn-share btn-share-tdd">
            </app-share-button>
          </div>
          <button class="btn btn-text btn-underline tickets-result__actions__get">
            <span>Get Offer</span>
          </button>
        </div>
      </div>
      <div class="tickets-result-item__actions">
        <div class="tickets-result-item__actions__share">
          <app-share-button tooltipText="Offer info copied to clipboard"
                            placement="top"
                            customClass="share-tooltip tickets-widget-tooltip"
                            (shareClick)="shareClick(item)"
                            class="btn-save-small btn-share btn-share-tdd">
          </app-share-button>
        </div>
        <button class="btn btn-text btn-underline tickets-result-item__actions__get">
          <span>Get Offer</span>
        </button>
        <button class="btn btn-primary btn-action-wide-desktop">
          <span class="text">Get Offer</span>
        </button>
      </div>
    </div>
  </div>

  <div [@opacityFadeInOut]
       *ngIf="!isLoading"
       class="last-separator">
  </div>

  <button *ngIf="isShowMore && !isLoading"
          class="btn btn-secondary tickets-result-show-more"
          (click)="onShowMore()">Show More
  </button>

  <div [@opacityFadeInOut]
       *ngIf="isNoResult"
       class="tickets-result-empty-state">
    <h2 class="tickets-result-empty-state__title">No results are available</h2>
  </div>
</section>
