<section class="container main-container wide-container noti-header"
         [class.noti-header-alone]="!isShowNotiBlock"
         *ngIf="data && !!data.name && !!notiBlockItem">
  <app-zone-header [data]="data"
                   [groupTabIndex]="groupTabIndex"
                   (showAllClick)="showAllClickHandler($event)"></app-zone-header>
</section>
<ng-container *ngIf="isShowNotiBlock">
  <div class="noti-block"
       [class.full-style]="notiBlockItem.style === 'noti_strip'"
       [style.background-image]="'url(' + notiBlockItem.background_url + ')'">
    <div
        class="noti-block-content"
        [class.no-cta-button]="!isShowButton"
        [class.no-content]="!hasContent && !isShowButton"
        [ngStyle]="!hasContent && !isShowButton && notiBlockItem.formatting_height && { 'height': notiBlockItem.formatting_height }">
      <div class="noti-block-title">{{ notiBlockItem.header }}</div>
      <div class="noti-block-body" [innerHTML]="notiBlockItem.body"></div>
      <button *ngIf="isShowButton"
              class="btn btn-primary noti-block-button"
              (click)="onButtonClick()"
              [appAutoTabIndex]="groupTabIndex">{{ notiBlockItem.button_txt }}</button>
      <button *ngIf="isShowCloseButton"
              type="button"
              class="noti-block-close"
              aria-label="Close"
              (click)="close($event)"
              [appAutoTabIndex]="groupTabIndex + 1">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</ng-container>
