<div class="card-wrapper "
     role="presentation">
  <app-today-deal-tag *ngIf="todayDeal.tag_text"
                      [title]="todayDeal?.tag_text"></app-today-deal-tag>

  <div class="card-img"
       [style.backgroundImage]="makeBackgroundImageUrl(todayDeal?.image_url | imageUrl : getBackgroundOptions() : false)">
  </div>
  <div class="info-wrapper">
    <div class="text-wrapper">
      <div class="company">
        <div class="notranslate company-name">{{ todayDeal?.company_name }}</div>
        <app-share-button tooltipText="Offer info copied to clipboard"
                          placement="top"
                          class="btn-save-small btn-share btn-share-tdd"
                          (shareClick)="shareClick()">
        </app-share-button>
      </div>
      <h5 role="presentation"
          class="headline"> {{todayDeal?.headline | truncateCompleteWords : getTrimCount() : true : '...' }}
      </h5>
      <div class="prices">
        <div class="old-price"
             *ngIf="todayDeal?.orig_price && !todayDeal?.offer_group">${{ todayDeal?.orig_price | toFixed: 2 }}</div>
        <span class="offer-group"
              *ngIf="todayDeal?.offer_group">Starting at:</span>
        <div class="new-price"
             *ngIf="todayDeal?.curr_price">${{ todayDeal?.curr_price | toFixed: 2 }}</div>
      </div>
    </div>
    <div class="cta-wrapper-mobile">
      <a class="btn btn-text">
        <span *ngIf="!hasCouponCode(todayDeal)" class="cta-content-wrapper">
          <span class="text">{{ getCtaButtonTitle(todayDeal) }}</span>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="14"
               height="14"
               viewBox="0 0 14 14">
            <path fill-rule="nonzero"
                  d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
          </svg>
        </span>
        <span *ngIf="hasCouponCode(todayDeal)" class="cta-content-wrapper">
          <span class="text"
                [class.text-truncated]="usedFromSearch">{{ getCtaButtonTitle(todayDeal) }}</span>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               fill="none"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M4.246 6.626L5.59 7.99 4.246 9.353A2.38 2.38 0 0 0 1 11.428v.14a2.382 2.382 0 1 0 4.685-.606l1.59-1.27.695.705a.303.303 0 0 0 .217.091h4.025a.112.112 0 0 0 .07-.2L9.403 7.99l4.138-3.31a.367.367 0 0 0 .035-.535 2.679 2.679 0 0 0-2.385-.793A3.815 3.815 0 0 0 9.114 4.43L7.279 6.282l-1.59-1.271a2.384 2.384 0 0 0 .055-.933A2.382 2.382 0 0 0 1 4.41v.14a2.38 2.38 0 0 0 3.246 2.075zm5.845 5.92v-.549a.594.594 0 0 1 .594-.595h1.224a.594.594 0 0 1 .594.595v.55a.594.594 0 0 1-.594.594h-1.226a.594.594 0 0 1-.592-.595zm-1.529-1.143H7.34l-.002-.001a.594.594 0 0 0-.594.595v.55a.594.594 0 0 0 .594.594h1.224a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.594zm-5.182 1.1a.94.94 0 0 0 .94-.939l.001.005a.94.94 0 1 0-1.88-.005c0 .519.42.94.94.94zm.94-8.095a.94.94 0 1 1-.274-.663.94.94 0 0 1 .275.666l-.001-.003zm10.935 6.994H14.03a.594.594 0 0 0-.593.595v.55a.594.594 0 0 0 .593.594h1.225a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.595z"
                  clip-rule="evenodd" />
          </svg>
        </span>
      </a>
    </div>
    <div class="cta-wrapper">
      <a class="btn btn-primary">
        <span *ngIf="!hasCouponCode(todayDeal)" class="cta-content-wrapper">
          <span class="text">{{ getCtaButtonTitle(todayDeal) }}</span>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               viewBox="0 0 16 16">
            <path fill='#FFF'
                  fill-rule="nonzero"
                  d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
          </svg>
        </span>
        <span *ngIf="hasCouponCode(todayDeal)" class="cta-content-wrapper">
          <span class="text">{{ getCtaButtonTitle(todayDeal) }}</span>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               fill="none"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M4.246 6.626L5.59 7.99 4.246 9.353A2.38 2.38 0 0 0 1 11.428v.14a2.382 2.382 0 1 0 4.685-.606l1.59-1.27.695.705a.303.303 0 0 0 .217.091h4.025a.112.112 0 0 0 .07-.2L9.403 7.99l4.138-3.31a.367.367 0 0 0 .035-.535 2.679 2.679 0 0 0-2.385-.793A3.815 3.815 0 0 0 9.114 4.43L7.279 6.282l-1.59-1.271a2.384 2.384 0 0 0 .055-.933A2.382 2.382 0 0 0 1 4.41v.14a2.38 2.38 0 0 0 3.246 2.075zm5.845 5.92v-.549a.594.594 0 0 1 .594-.595h1.224a.594.594 0 0 1 .594.595v.55a.594.594 0 0 1-.594.594h-1.226a.594.594 0 0 1-.592-.595zm-1.529-1.143H7.34l-.002-.001a.594.594 0 0 0-.594.595v.55a.594.594 0 0 0 .594.594h1.224a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.594zm-5.182 1.1a.94.94 0 0 0 .94-.939l.001.005a.94.94 0 1 0-1.88-.005c0 .519.42.94.94.94zm.94-8.095a.94.94 0 1 1-.274-.663.94.94 0 0 1 .275.666l-.001-.003zm10.935 6.994H14.03a.594.594 0 0 0-.593.595v.55a.594.594 0 0 0 .593.594h1.225a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.595z"
                  clip-rule="evenodd" />
          </svg>
        </span>
      </a>
    </div>
  </div>
</div>
