import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { confirmEmail, setPassword, defaultFinalContent, zipFinalContent } from '@app/shared/constants/confirmation-header-content.constants';
import { FinalStepTypes } from '@app/shared/enums/confirmation-dropdown.enum';
import { ConfirmationHeaderContent } from '@app/shared/interfaces/confirmation.interface';
import { ConfigService } from '@app/shared/services/config.service';
import { MregService } from '@app/shared/services/mreg/mreg.service';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { COUNTRY_LAST_SESSION_KEY } from '@shared/constants/profile.constants';

@Component({
  selector: 'app-final-confirmation-step',
  templateUrl: './final-confirmation-step.component.html',
  styleUrls: ['./final-confirmation-step.component.less']
})
export class FinalConfirmationStepComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public promptContent: ConfirmationHeaderContent;

  public email: string;
  public finalStepType: string;
  public subscriptions: Subscription[] = [];
  public modalContent: ConfirmationHeaderContent;

  constructor(
    private readonly accountConfirmationService: AccountConfirmationService,
    private readonly configService: ConfigService,
    private readonly mregService: MregService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.subscriptions.push(this.accountConfirmationService.email$.subscribe(email => this.email = email));
    this.subscriptions.push(this.accountConfirmationService.finalStepType$.subscribe(type => this.finalStepType = type));
  }

  public get isShowLink(): boolean {
    return Boolean(this.modalContent && this.modalContent.link_txt && this.modalContent.link_url);
  }

  public ngOnInit(): void {
    this.setStepContent();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.promptContent && changes.promptContent.currentValue && this.mregService.isEntryMode) {
      this.modalContent = this.promptContent;
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  public onCloseClick(event: Event): void {
    event.stopPropagation();

    if (this.mregService.isMreg) {
      // remove guest session
      this.accountConfirmationService.closeConfirmationDialog(true);
      this.authService.setUnauthenticated();
      localStorage.removeItem(COUNTRY_LAST_SESSION_KEY);

      localStorage.setItem('mreg', this.mregService.mregId);
      this.authService.redirectToSignInDirectly();

      return;
    }

    this.accountConfirmationService.closeConfirmationDialog(true);
  }

  public onLinkClick(): void {
    const isRelative = this.modalContent.link_url.startsWith('/');

    if (isRelative) {
      void this.router.navigateByUrl(this.modalContent.link_url);
    } else {
      window.location.href = this.modalContent.link_url;
    }
  }

  private setStepContent(): void {
    if ((this.mregService.isMreg || this.mregService.isEntryMode) && !this.finalStepType) {
      this.modalContent = this.promptContent;
      return;
    }

    switch (this.finalStepType) {
      case FinalStepTypes.setPassword:
        this.modalContent = setPassword(this.configService.getOption('name'));
        break;

      case FinalStepTypes.confirmEmail:
        this.modalContent = confirmEmail(this.email);
        break;

      case FinalStepTypes.setZip:
        this.modalContent = zipFinalContent;
        break;

      default:
        this.modalContent = defaultFinalContent;
    }

  }

}
