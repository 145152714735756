import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {

  @Input() size = '36';
  @Input() isHomePage = false;

  protected get spinnerWrapHeight(): number {
    return Number(this.size) * Math.SQRT2;
  }

  constructor() { }

  ngOnInit(): void { }

}
