<ng-container *ngIf="topBrands?.length && zoneElement">
  <section class="top-brands scrollable_container container main-container wide-container"
    [attr.id]="anchorLink"
    [class.wide-endpoint]="isWideContainer()"
    (mouseenter)="hovered = true"
    (mouseleave)="hovered = false">
    <app-zone-header
      [data]="zoneElement"
      [groupTabIndex]="groupTabIndex"
      (showAllClick)="showAllClickHandler($event)">
    </app-zone-header>

    <app-homepage-horizontal-scroll [hideGhostEffect]="hideGhostEffect"
      [hideArrows]="hideArrows"
      [isDesktop]="isDesktop"
      [isBrandSection]="true"
      [hovered]="hovered">
      <app-brand-item [appAutoTabIndex]="2000"
        class="row-mode"
        *ngFor="let brand of topBrands; trackBy: trackByBrandId; index as i;"
        [brand]="brand"
        [class.first]="i === 0"
        (click)="onBrandClick(brand, $event)"
        (focusout)="i % 10 === 0 ? loadMore() : ''"
        (brokenImageUrl)="handleBrokenImageUrl($event, brand.title)"
        [rowMode]="true">
      </app-brand-item>
    </app-homepage-horizontal-scroll>
  </section>
</ng-container>
