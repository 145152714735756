import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';

export interface SamlResponse {
  id: string;
  context: string;
  entityEndpoint: string;
  type: string;
}

@Injectable()
export class SamlService {

  constructor(private http: HttpClient) {}

  async getSamlResponse(deeplink = ''): Promise<SamlResponse> {
    return this.http.post<SamlResponse>(`${environment.apiUrl}/api/saml/paylogix`, { deeplink }).toPromise();
  }
}
