import {
  Component,
  Input,
  NgZone,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  @Input() public country: string;
  @Input() public siteTag: string;
  @Input() public hostName: string;
  @Input() public apiHost: string;
  @Input() public cdn: string;
  @Input() public canShowFooter: boolean;

  private loaded = false;

  constructor(
    private _ngZone: NgZone
  ) {}

  public get wrapperOpacity(): string {
    return this.loaded ? '1' : '0';
  }

  public ngOnInit(): void {
    this._ngZone.runOutsideAngular(() => {
      const link = window.document.createElement('link');
      link.href = `${this.apiHost}/assets/footer/style.css`;
      link.rel = 'stylesheet';
      link.type = 'text/css';

      const dataDiv = window.document.createElement('div');
      dataDiv.id = 'bundleId';
      dataDiv.dataset.hostname = this.hostName;
      dataDiv.dataset.country = this.country;
      dataDiv.dataset.cdn = this.cdn;
      dataDiv.dataset.siteTag = this.siteTag;

      const script = window.document.createElement('script');
      script.src = `${this.apiHost}/assets/footer/bundle.js`;
      window.document.body.appendChild(dataDiv);
      window.document.body.appendChild(link);
      window.document.body.appendChild(script);
      script.onload = (): void => {
        this.loaded = true;
      };
    });
  }
}
