/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import {
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsGAOfferModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { Injectable } from '@angular/core';
import { Marker } from '@shared/interfaces/marker.interface';
import { TokensService } from '@shared/services/tokens.service';
import { UrlHelperService } from 'g3-common-ui';

type ActionType = 'provider-location-cta-click'
| 'provider-location-reveal-code'
| 'provider-location-copy-code'
| 'provider-location-cta-click-out'
| 'provider-location-view-details'
| 'provider-location-next-page'
| 'provider-location-zip-enter'
| 'provider-location-zip-select'
| 'provider-location-category-select'
| 'provider-location-map-refresh-click'
| 'provider-location-current-location-click'
| 'provider-location-marker-click'
| 'provider-location-search-this-location-click';


@Injectable({
  providedIn: 'root'
})
export class ProviderLocationsAnalyticsService {

  constructor(
    private tokensService: TokensService,
    private urlHelper: UrlHelperService,
  ) { }

  getAnalyticsGALabel(item: Marker) {
    return new AnalyticsGAOfferModel(item.guid, item.name, item.title).generateOfferLabel();
  }

  getAnalyticsEvents(action: ActionType, item: Marker, eventData?: any): AnalyticsEvent[] {
    const gaCategory = 'listing-map';
    const gaLabel = item && item.guid ? this.getAnalyticsGALabel(item) : JSON.stringify(eventData);
    const isEbg = item && (item.is_ebg || item.url && this.tokensService.isTokenPresent(item.url, 'ebghost'));

    switch (action) {
      case 'provider-location-cta-click':
      case 'provider-location-reveal-code':
      case 'provider-location-copy-code':
        return [
          new AnalyticsInternalEventModel(action, { guid: item.guid }),
          new AnalyticsGAEventModel(action, {
            category: gaCategory,
            label: gaLabel
          })
        ];
      case 'provider-location-view-details':
      case 'provider-location-marker-click':
        return [
          new AnalyticsInternalEventModel(action, { markerGuid: item.guid }),
          new AnalyticsGAEventModel(action, {
            category: gaCategory,
            label: gaLabel
          })
        ];
      case 'provider-location-cta-click-out':
        const clickOut = !this.urlHelper.isExternalUrl(item.url) || isEbg ? 'internal' : 'external';
        return [
          new AnalyticsInternalEventModel(action, {
            guid: item.guid,
            url: item.url,
            url_type: item.url_type,
            click_out: clickOut
          }),
          new AnalyticsGAEventModel(action, {
            category: gaCategory,
            label: gaLabel + ` | ${clickOut}`
          })
        ];
      case 'provider-location-next-page':
      case 'provider-location-zip-enter':
      case 'provider-location-zip-select':
      case 'provider-location-category-select':
      case 'provider-location-map-refresh-click':
      case 'provider-location-current-location-click':
      case 'provider-location-search-this-location-click':
        return [
          new AnalyticsInternalEventModel(action, eventData),
          new AnalyticsGAEventModel(action, {
            category: gaCategory,
            label: gaLabel
          })
        ];
    }
  }
}
