<div *ngIf="category"
     class="category-item card-wrapper"
     (mouseleave)="onMouseLeave()"
     [class.hover-out]="mouseJustLeft">
  <img class="category-item__logo"
       [src]="category.imageSquare"
       [alt]="category.titleShort"
       loading="lazy"
       (error)="emitBrokenImageUrl()">
    <h5 class="category-item__title">{{ category.titleShort }}</h5>
</div>
