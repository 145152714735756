/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { VendorModel } from './vendor.model';
import { AccessSettingOption } from '@shared/interfaces/search-offer-item.interface';
import { KeyValue } from '@angular/common';
import { SUCHelper } from '../helpers/suc.helper';
import isNil from 'lodash/isNil';
import { ImgixSettings } from 'g3-common-ui';

const PHONE_DEFAULT_NOTE = 'Call to redeem.';
const DEFAULT_CTA_TITLE = 'GET OFFER';
export const CODE_INSTRUCTIONS_DEFAULT = `To redeem the offer, copy code then click ‘Go to site’.
      <strong>Don’t forget to paste the code at checkout!</strong>`;

export enum OfferStatus {
  Active = 'active',
  Expired = 'expired',
  Deleted = 'deleted',
  Archived = 'archived',
  Draft = 'draft',
  Pending = 'pending',
  Demo = 'demo'
}

export enum OfferClass {
  Exclusive = 'Exclusive',
  Coupon = 'Coupon',
  Editorial = 'Editorial',
  Client = 'Client',
  Product = 'Product',
  PreferredAccess = 'preferred-access'
}

export interface OfferImageModel {
  url: string;
  is_search_image?: boolean;
}

export interface OfferCategory {
  guid: string;
  title: string;
}

export enum OfferExternalSources {
  None = '',
  Paylogix = 'paylogix'
}

export interface OfferExternalData {
  id: string;
  group_id?: string;
  name: string;
  carrier?: string;
  benefit_min?: string;
  benefit_max?: string;
  premium_min?: string;
  premium_max?: string;
  deeplink?: string;
  org_id?: string;
}

export interface PaylogixSubscription {
  AnticipatedModalPremium: {
    EEContribution: number;
    ERContribution: number;
    Period_Ending: Date;
    Total: number;
  };
  EnrollmentDate: Date;
  FirstPeriodEnd: Date;
  LastPeriodEnd?: string;
  MonthlyPremium: number;
  OrgBasedProductCode: string;
  ParticipantID: number;
  PlanID?: number | null;
  PlanName: string;
  PolicyEffectiveDate: string;
  ProductCode: string;
  ProductID: number | null;
  ProductName: string;
  ProviderID: number;
  ProviderName: string;
  Status: string;
}

export class OfferModel {

  public access_setting: AccessSettingOption;
  public animate = '';
  public brand_tag_ids_string: string;
  public brand_tag_display: string;
  public brandOverride: string;
  public cat_code_tag_ids_string: string;
  public catCodeTagIds: number[];
  public categories: OfferCategory[];
  public class: OfferClass.Exclusive | OfferClass.Coupon | OfferClass.Editorial | OfferClass.Client | OfferClass.Product | OfferClass.PreferredAccess;
  public code_instructions: string;
  public code: string;
  public ctaButtonTitle: string;
  public custom_categories_string: string;
  public customProductSubHeadings: string;
  public descriptionDetails: string;
  public descriptionDisclaimer: string;
  public descriptionHighlights: string;
  public descriptionSearch: string;
  public destinationUrl?: string;
  public destinationUrlNewTab: boolean;
  public endDate: Date;
  public external_data: OfferExternalData;
  public external_source: OfferExternalSources.None | OfferExternalSources.Paylogix;
  public extraStatus: string;
  public guid: string;
  public images: Array<OfferImageModel>;
  public instruction_text: string;
  public is_allowed_url = true;
  public is_available: boolean;
  public is_in_dynamic_category: boolean;
  public is_saved: boolean;
  public isAllowedCta = true;
  public isCouponHidden = false;
  public isEbg?: boolean;
  public isEditorialHero = false;
  public keywords_string: string;
  public locations: string;
  public paylogixSubscription?: PaylogixSubscription;
  public phone_action: string;
  public phone_note: string;
  public phone: string;
  public position: number;
  public productSubHeading: string;
  public promo: string;
  public questionsAndAnswers: string;
  public rateAverage: string;
  public rateCount: string;
  public sourceBasedParams: KeyValue<string, string>[];
  public status: string;
  public stop_keywords: string[];
  public subtitle: string;
  public paylogixEnrolledHeaderShort: string;
  public title: string;
  public type: string;
  public url: string;
  public urlType: string;
  public userRating: string;
  public userRatingDate: Date;
  public vendor: VendorModel;
  public vendorBrand: VendorModel;
  public vendorDescription: string;
  public views: number;
  public is_widget_iframe: boolean;
  public is_widget_disclaimer: boolean;
  public widget_code: string;
  public widget_disclaimer: string;

  public header_short?: string;
  public search_image_imgix_settings?: ImgixSettings;
  public search_image_url?: string;
  public vendor_other_brand_name?: string;
  public pinned_position?: number;
  public isFirstPinned?: boolean;
  public isLastPinned?: boolean;

  private _hasDestinationUrl: boolean;

  constructor(data: any) {

    if (data.guid) {
      this.guid = data.guid;
    }
    if (data.header_long) {
      this.title = data.header_long;
    }

    if (data.header_short) {
      this.subtitle = data.header_short;
    }

    this.descriptionSearch = data.description_search || '';
    this.descriptionDetails = data.description || '';
    this.extraStatus = data.extra_status;
    this.endDate = data.end_date ? new Date(data.end_date) : null;
    this.views = data.views ? data.views : 0;
    this.class = data.class || OfferClass.Exclusive;
    this._hasDestinationUrl = data.has_destination_url;
    if (data.code) {
      this.code = data.code;
    }

    if (data.instruction_text) {
      this.instruction_text = data.instruction_text;
    }

    this.code_instructions = data.code_instructions ? data.code_instructions : CODE_INSTRUCTIONS_DEFAULT;

    if (data.destination_url) {
      this.url = data.destination_url;
    }

    if (!isNil(data.destination_url_new_tab)) {
      this.destinationUrlNewTab = data.destination_url_new_tab;
    } else {
      this.destinationUrlNewTab = true;
    }

    if (data.destination_url_type) {
      this.urlType = data.destination_url_type;
    }

    if (data.is_allowed_url !== undefined && !data.is_allowed_url) {
      this.is_allowed_url = false;
    }

    if (data.is_allowed_cta !== undefined && !data.is_allowed_cta) {
      this.isAllowedCta = false;
    }

    if (data.is_saved) {
      this.is_saved = data.is_saved;
    }

    this.images = data.images && data.images.length > 0 ? data.images.map(
      image => {
        if (image && image.url) {
          return ({ url: image.url, is_search_image: image.is_search_image });
        }
        return null;
        // return only items with data
      }).filter(i => i) : [];

    this.vendorBrand = new VendorModel(data.vendor_brand || {});
    this.vendor_other_brand_name = data.vendor_other_brand_name;

    if (data.description_highlights) {
      this.descriptionHighlights = data.description_highlights;
    }

    if (data.vendor_description) {
      this.vendorDescription = data.vendor_description;
    }

    if (data.description_disclaimer) {
      this.descriptionDisclaimer = data.description_disclaimer;
    }

    if (data.promo) {
      this.promo = data.promo;
    }

    if (data.rate_average) {
      this.rateAverage = data.rate_average;
    }

    if (data.rate_count) {
      this.rateCount = data.rate_count;
    }

    if (data.user_rating) {
      this.userRating = data.user_rating || null;
    }

    if (data.user_rating_date) {
      this.userRatingDate = data.user_rating_date;
    }

    this.vendor = new VendorModel(data.vendor || {});

    if (data.status) {
      this.status = data.status;
    }

    if (data.type) {
      this.type = data.type;
    }

    if (data.categories) {
      this.categories = data.categories;
    }

    if (data.questions_answers) {
      this.questionsAndAnswers = data.questions_answers;
    }

    if (data.cta_button_title) {
      this.ctaButtonTitle = data.cta_button_title;
    }

    if (data.phone) {
      this.phone = data.phone;
      this.phone_note = data.phone_note || PHONE_DEFAULT_NOTE;
      this.phone_action = data.phone_action || '';
    }

    if (data.isEbg !== undefined) {
      this.isEbg = data.isEbg;
    }

    if (data.paylogixSubscription) {
      this.paylogixSubscription = data.paylogixSubscription;
    }

    if (data.external_source) {
      this.external_source = data.external_source;
    }

    if (data.external_data) {
      this.external_data = data.external_data;
    }

    this.isCouponHidden = data.is_coupon_hidden;
    this.is_available = data.is_available;
    this.is_in_dynamic_category = data.is_in_dynamic_category;
    this.locations = data.locations;

    this.brand_tag_ids_string = data.brand_tag_ids_string;
    this.brand_tag_display = data.brand_tag_display;
    this.cat_code_tag_ids_string = data.cat_code_tag_ids_string;
    this.keywords_string = data.keywords_string;
    this.custom_categories_string = data.custom_categories_string;
    this.stop_keywords = data.stop_keywords || [];
    this.catCodeTagIds = data.cat_code_tag_ids || [];

    this.productSubHeading = data.product_sub_heading || '';
    this.customProductSubHeadings = data.custom_product_sub_headings || null;
    this.position = data.position || null;
    this.access_setting = data.access_setting || null;

    this.brandOverride = data.brand_override || '';
    this.sourceBasedParams = data.source_based_params || [];

    this.external_source = data.external_source || OfferExternalSources.None;
    this.paylogixEnrolledHeaderShort = data.paylogix_enrolled_header_short || '';
    this.isEditorialHero = typeof data.isEditorialHero === 'boolean' ? data.isEditorialHero : false;
    this.is_widget_iframe = typeof data.is_widget_iframe === 'boolean' ? data.is_widget_iframe : false;
    this.is_widget_disclaimer = typeof data.is_widget_disclaimer === 'boolean' ? data.is_widget_disclaimer : false;
    this.widget_code = data.widget_code || '';
    this.widget_disclaimer = data.widget_disclaimer || '';
    this.header_short = data.header_short || '';
    this.search_image_url = data.search_image_url;
    this.pinned_position = data.pinned_position;

    if (data.search_image_imgix_settings) {
      this.search_image_imgix_settings = data.search_image_imgix_settings;
    }
  }

  hasDescriptionDetails(): boolean {
    return (this.descriptionDetails && this.descriptionDetails.length) > 0;
  }

  hasCouponCode(): boolean {
    return !!this.code;
  }

  hasPhone(): any {
    return !!this.phone;
  }

  isExternalUrlWithSucToken(url: string, code: string): boolean {
    return SUCHelper.isExternalUrlWithSucToken(url, code);
  }

  hasDestinationUrl(): boolean {
    // if it's offer with static link type
    // check if there
    if (typeof this._hasDestinationUrl !== 'undefined') {
      return this._hasDestinationUrl;
    }
    if (this.urlType === 'static') {
      return this.url && this.url.length > 0;
    }
    if (this.urlType === 'dynamic') {
      return true;
    }
    return false;
  }

  isExpired(): boolean {
    return this.status === 'expired';
  }

  getCtaButtonTitle(): string {
    if (this.isExternalUrlWithSucToken(this.destinationUrl, this.code)) {
      return DEFAULT_CTA_TITLE;
    }

    return this.ctaButtonTitle || DEFAULT_CTA_TITLE;
  }

  isShowCountViews(): boolean {
    const minCountViewsToShow = 25;
    return this.views >= minCountViewsToShow;
  }

  get isPaylogixOffer(): boolean {
    return this.external_source === OfferExternalSources.Paylogix;
  }

  set isPaylogixOffer(isPaylgxOffer: boolean) {
    this.external_source = isPaylgxOffer ? OfferExternalSources.Paylogix : OfferExternalSources.None;
  }

}
