import { AnalyticsGAEventModel, AnalyticsInternalEventModel, AnalyticsInternalSubscription } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { Injectable } from '@angular/core';
import { RouterData } from '../interfaces/router-data.interface';

@Injectable()
export class UnsubscribeAnalyticsService {

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  trackAnalyticsEvents(action: string, category: string, data: AnalyticsInternalSubscription): void {
    try {
      this.analyticsService.eventsTrack([
        new AnalyticsInternalEventModel(action, {
          subscription: data
        }),
        new AnalyticsGAEventModel(action, {
          category,
          action,
          label: data.name
        })
      ]);
    } catch {
      console.error('error posting analytics event');
    }
  }

  getDataForAnalytics(routerData: RouterData, name: string, getParams = true): AnalyticsInternalSubscription {
    return {
      name,
      mkt: routerData.path.subdomain,
      usr: routerData.path.userdata.split('~')[0],
      ...(routerData.query.sid && getParams) && { sid: routerData.query.sid },
      ...(routerData.query.btag && getParams) && { btag: routerData.query.btag },
      ...(routerData.query.ctag && getParams) && { ctag: routerData.query.ctag }
    };
  }

}
