import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const SLIDE_IN_OUT_PANEL_ANIMATION =
  trigger('slideInOutPanel', [
    transition(':enter', [
      style({ transform: 'translateX(-290px)', width: '0', opacity: '.7' }),
      animate('250ms ease-out', style({ transform: 'translateX(0)', width: '*', opacity: '1' }))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({ transform: 'translateX(-290px)', width: '0', opacity: '.7' }))
    ])
  ]);

export const SLIDE_UP_DOWN_PANEL_ANIMATION =
  trigger('slideUpDownPanel', [
    transition(':enter', [
      style({ height: '0', opacity: '.7' }),
      animate('250ms ease-out', style({ height: '*', opacity: '1' }))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({ height: '0', opacity: '.7' }))
    ])
  ]);

export const OPACITY_FADE_IN_OUT_ANIMATION =
  trigger('opacityFadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate('250ms ease-in-out')),
  ]);

export const FADE_ANIMATION =
  trigger('fadeAnimation', [
    state('void', style({
      opacity: 0,
      'z-index': 99999999
    })),
    transition('void <=> *', animate('200ms ease-in-out')),
  ]);

export const WIDGET_TOGGLE_ANIMATION =
  trigger('widgetToggle', [
    state('closed', style({
      overflow: 'hidden',
      height: '88px',
    })),
    transition('expanded => closed', [
      style({
        boxShadow: '0px 6px 25px rgba(3, 8, 33, .12)'
      }),
      animate('0.15s 0.15s ease-in-out')
    ]),
    transition('closed => expanded', animate('0.25s ease-out'))
  ]);

export const WIDTH_ANIMATION =
  trigger('widthInOut', [
    transition('void => *', [
      style({
        width: 0,
        opacity: 0
      }),
      animate('250ms ease-in-out', style({
        width: '*',
        opacity: 1
      }))
    ]),
    transition('* => void', [
      style({
        width: '*',
        opacity: 1
      }),
      animate('250ms ease-in-out', style({
        width: 0,
        opacity: 0
      }))
    ])
  ]);

export const HEIGHT_ANIMATION =
  trigger('heightInOut', [
    transition('void => *', [
      style({
        height: 0,
        opacity: 0
      }),
      animate('250ms ease-in', style({
        height: '*',
        opacity: 1
      }))
    ]),
    transition('* => void', [
      style({
        height: '*',
        opacity: 0.5
      }),
      animate('250ms ease-in', style({
        height: 0,
        opacity: 0
      }))
    ])
  ]);

export const CHANGE_WIDTH_ANIMATION =
  trigger('changeWidthAnimation', [
    state('in', style({
      'max-width': '100%',
      width: 'auto',
    })),
    state('out', style({
      'max-width': '*',
      width: '*',
    })),
    transition('in => out', animate('400ms ease-in-out')),
    transition('out => in', animate('400ms ease-in-out'))
  ]);

export const REVERSE_HEIGHT_ANIMATION =
  trigger('reverseHeightInOut', [
    transition('void => *', [
      style({
        height: 0,
        opacity: 0.25
      }),
      animate('150ms ease-in', style({
        height: '*',
        opacity: 1
      }))
    ]),
    transition('* => void', [
      style({
        height: '*',
        opacity: 0.05
      }),
      animate('150ms ease-in', style({
        height: 0,
        opacity: 0
      }))
    ])
  ]);
