import {
  Component,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-comment',
  template: ' {{contents}} ',
  styleUrls: ['./comment-node.component.less']
})
export class CommentNodeComponent implements OnInit {

  @Input() contents: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.createComment(), 0);
  }

  private createComment(): void {
    const htmlElement = this.elementRef.nativeElement as HTMLElement;
    htmlElement.outerHTML = `<!-- ${htmlElement.textContent} -->`;
  }
}
