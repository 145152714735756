/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Ad, AdSource } from '@app/shared/models/ad.model';
import {
  AnalyticsAdEventModel,
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel
} from '@app/shared/models/analytics.event.model';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { ClickOutService } from '@app/shared/services/click-out.service';
import { ContactFormService } from '@app/shared/services/contact-form.service';
import {
  getAnalyticsActionByZoneSectionElement,
  getAnalyticsAdByCtaOptions,
  getAnalyticsGACategoryByZoneSectionElement,
  getAnalyticsGALabelFromObject, getAnalyticsZoneByZoneSectionElement
} from './analytics/helpers';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { UrlHelperService } from 'g3-common-ui';
import { ZoneSectionElement } from '../interfaces/zones-search-response.interface';
import { DirectClickOutAdCardsService } from '@app/shared/services/direct-click-out/direct-click-out-ad-cards.service';

type ActionType = 'displayed' | 'click';
export interface CtaOptions {
  ad?: Ad;
  guid: string;
  ad_id: string;
  ad_source: string;
  header?: string;
  body?: string;
  button_txt?: string;
  button_action?: string;
  button_url?: string;
  isEbg: boolean;
  background_url_xl?: string;
  background_url_l?: string;
  background_url_m?: string;
  background_url_s?: string;
  background_url_xs?: string;
  image_url?: string;
  logo_url?: string;
  campaign?: string;
  category?: number[];
  type?: string;
  brand_name?: string;
  offer_guid?: string;
}

export interface CtaAd {
  guid: string;
  type: string;
  name: string;
}

export interface Zone {
  guid: string;
  type: string;
  name: string;
  category: string[];
}

@Injectable()
export class CtaElementService {

  private _isMSOrFirefoxBrowser: boolean;

  constructor(
    private directClickOutAdCardsService: DirectClickOutAdCardsService,
    private contactFormService: ContactFormService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private clickOutService: ClickOutService,
    private urlHelper: UrlHelperService
  ) { }

  public async handleClick(ctaBlock: CtaOptions, zoneElement: ZoneSectionElement): Promise<void> {
    if (!ctaBlock.button_url) {
      return;
    }

    const isExternalUrl = this.urlHelper.isExternalUrl(ctaBlock.button_url);
    const isCtaUrlDifferFromOffer = ctaBlock.button_url !== ctaBlock.ad?.destinationUrl;

    if (ctaBlock.button_url === '#nomination') {
      this.openContactUsModal('Nomination');
      return;
    }

    if (ctaBlock.offer_guid && !isCtaUrlDifferFromOffer) {
      await this.directClickOutAdCardsService.handleAdClick(ctaBlock.ad, 'cta', zoneElement);
      return;
    }

    if (isExternalUrl) {
      await this.clickOutService.clickOut(
        ctaBlock.button_url,
        null,
        {
          data: { logo: ctaBlock.logo_url,
            brand: ctaBlock.brand_name, guid: ''
          }
        },
        !ctaBlock.isEbg
      );
    } else if (ctaBlock.button_url[0] === '/') {
      this.breadcrumbsService.setUrl(ctaBlock.button_url);
      void this.router.navigateByUrl(ctaBlock.button_url);
    }
  }

  public openContactUsModal(selected?: string): void {
    this.contactFormService.openContactFormModal({
      reason: selected || ''
    });
  }

  public isMSOrFirefoxBrowser(): boolean {
    if (this._isMSOrFirefoxBrowser !== undefined) {
      return this._isMSOrFirefoxBrowser;
    }

    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMS = userAgent.indexOf('msie ') > -1 || userAgent.indexOf('trident/') > -1 || userAgent.indexOf('edge/') > -1;

    // added here firefox due to issues with masking for it even the fact that it should show it correctly
    const isFirefox = userAgent.indexOf('firefox') > -1;
    this._isMSOrFirefoxBrowser = isMS || isFirefox;

    return this._isMSOrFirefoxBrowser;
  }

  public isImageSvg(imgUrl: string): boolean {
    const isImageSvg = imgUrl && imgUrl.split('.').pop() === 'svg';

    return isImageSvg;
  }

  public imageMask(imgUrl: string | SafeUrl, size: string): any {
    return {
      '-webkit-mask-image': `url(${imgUrl})`,
      'mask-image': `url(${imgUrl})`,
      '-webkit-mask-repeat': 'no-repeat',
      'mask-repeat': 'no-repeat',
      '-webkit-mask-size': size,
      'mask-size': size,
      '-webkit-mask-position': 'center',
      'mask-position': 'center',
    };
  }

  public backgroundMask(imgUrl: string | SafeUrl, size: string): any {
    return {
      'background-image': `url(${imgUrl})`,
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'background-size': size,
    };
  }

  getAnalyticsEvents(action: ActionType, zoneSectionElement: ZoneSectionElement, ctaBlock: CtaOptions): AnalyticsEvent[] {
    let result;
    let data;
    const analyticsAction = getAnalyticsActionByZoneSectionElement(zoneSectionElement, action);

    switch (action) {
      case 'displayed':
        data = {
          ad_guid: ctaBlock.guid,
        };
        if (zoneSectionElement.name) {
          data['name'] = zoneSectionElement.name;
        }
        result = [
          new AnalyticsInternalEventModel(analyticsAction, data),
          new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: getAnalyticsGALabelFromObject(data)
          }),
          new AnalyticsAdEventModel(analyticsAction, {
            category: 'zones',
            g3_category: 'zone-cta-block',
            g3_action: 'zone-cta-block-displayed.interaction',
            properties: {
              event_name: 'impression',
              ad: getAnalyticsAdByCtaOptions(ctaBlock),
              zone: getAnalyticsZoneByZoneSectionElement(zoneSectionElement)
            }
          })
        ];
        if (ctaBlock.ad_source === AdSource.BSS) {
          result.push(new AnalyticsAdEventModel('g3-ad-results', {
            date_time: new Date(),
            ads: [{ response_id: ctaBlock.ad_id }]
          }));
        }
        return result;
      case 'click':
        data = {
          ad_guid: ctaBlock.guid,
          type: ctaBlock.button_action
        };
        if (ctaBlock.button_url) {
          data['url'] = ctaBlock.button_url;
        }
        data.click_out = ctaBlock.isEbg || !this.urlHelper.isExternalUrl(ctaBlock.button_url) ? 'internal' : 'external';

        result = [
          new AnalyticsInternalEventModel(analyticsAction, data),
          new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: getAnalyticsGALabelFromObject(data)
          })
        ];
        // if this ad is from BSS and it's a click
        if (ctaBlock.ad_source === AdSource.BSS) {
          result.push(new AnalyticsAdEventModel('g3-ad-click', {
            date_time: new Date(),
            response_id: ctaBlock.ad_id
          }));
        }
        return result;
    }
  }
}
