/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
import { AuthGuardService } from './core/auth/services/auth-guard.service';
import { HomeComponent } from './homepage/components/home/home.component';
import { LoggedInLayoutComponent } from './core/layouts/logged-in/logged-in-layout.component';
import { NgModule } from '@angular/core';
import { NoSiteErrorComponent } from './core/auth/no-site-error/no-site-error.component';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProfileDeletedComponent } from './core/auth/profile-deleted/profile-deleted.component';
import { RouterExtService } from './ext-router.service';
import { SignOutComponent } from './core/auth/sign-out/sign-out.component';
import { UpgradeVersionComponent } from './core/auth/upgrade-version/upgrade-version.component';
import { RedirectGuard } from './shared/guards/redirect.guard';

const routes: Routes = [
  // sign-in route redirects to home
  // if no access_token, user will be redirected to auth.beneplace.com/sign-in
  {
    path: 'sign-in',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'account-deleted',
    component: ProfileDeletedComponent,
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
  },
  {
    path: 'no-site',
    component: NoSiteErrorComponent,
  },
  {
    path: 'upgrade-version',
    component: UpgradeVersionComponent,
  },
  {
    path: 'instant-upgrade',
    component: UpgradeVersionComponent,
  },
  {
    path: 'unsubscribe',
    loadChildren: async () => (await import('./unsubscribe/unsubscribe.module')).UnsubscribeModule,
  },
  {
    path: 'start-saving',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'common',
    loadChildren: async () => (await import('./policies/policies.module')).PoliciesModule,
  },
  {
    path: 'policies',
    redirectTo: 'common'
  },
  {
    path: 'test-g3-header',
    loadChildren: async () => (await import('./test-g3-header/test-g3-header.module')).TestG3HeaderModule,
  },
  {
    path: 'test-wasp',
    loadChildren: async () => (await import('./test-wasp/test-wasp.module')).TestWaspModule,
  },
  {
    path: 'test-web-components',
    loadChildren: async () => (await import('./test-web-components/test-web-components.module')).TestWebComponentsModule,
  },
  {
    path: 'select-country',
    loadChildren: async () => (await import('./select-country/select-country.module')).SelectCountryModule,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pdf',
    loadChildren: async () => (await import('./pdf/pdf.module')).PdfModule,
  },
  {
    path: 'redirect',
    loadChildren: async () => (await import('./redirect/redirect.module')).RedirectModule,
    canActivate: [AuthGuardService]
  },
  {
    path: 'relay',
    loadChildren: async () => (await import('./relay/relay.module')).RelayModule,
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    component: LoggedInLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'browse-offers',
        loadChildren: async () => (await import('./browse-offers/browse-offers.module')).BrowseOffersModule,
        canActivate: [AuthGuardService]
      },
      {
        path: 'browse-offers-v2',
        redirectTo: 'browse-offers'
      },
      {
        path: 'listing',
        loadChildren: async () => (await import('./listing/listing.module')).ListingModule,
        canActivate: [AuthGuardService]
      },
      {
        path: 'my-profile',
        canActivate: [RedirectGuard],
        data: {
          redirectToPath: 'my-account'
        },
        // Route config requires component so we need this line even if this module will never be rendered under this path
        loadChildren: async () => (await import('./my-profile/my-profile.module')).MyProfileModule,
      },
      {
        path: 'my-account',
        loadChildren: async () => (await import('./my-profile/my-profile.module')).MyProfileModule,
        // canActivate: [ProfileFeaturesGuardService, AuthGuardService]
        // Note: ProfileFeaturesGuardService throw an error during redirect_url from auth to my-account
        canActivate: [AuthGuardService]
      },
      {
        path: 'offers',
        loadChildren: async () => (await import('./offers/offers.module')).OffersModule,
        canActivate: [AuthGuardService]
      },
      {
        path: 'shopping',
        loadChildren: async () => (await import('./channel-page/channel-page.module')).ChannelPageModule,
        canActivate: [AuthGuardService],
        data: {
          page: 'shopping'
        }
      },
      {
        path: 'travel',
        loadChildren: async () => (await import('./channel-page/channel-page.module')).ChannelPageModule,
        canActivate: [AuthGuardService],
        data: {
          page: 'travel'
        }
      },
      {
        path: 'tickets',
        loadChildren: async () => (await import('./channel-page/channel-page.module')).ChannelPageModule,
        canActivate: [AuthGuardService],
        data: {
          page: 'tickets'
        }
      },
    ]
  },
  // Leave this route last. Catch-all for unrecognized paths to go to either the homepage or a 404 page
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules
      } // enableTracing for debugging purposes only
    )
  ],
  providers: [RouterExtService],
  exports: [RouterModule],
})
export class AppRoutingModule { }
