import { AuthService } from 'app/core/auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ConfigService, UpgradePageImagesResponse, UpgradePagesImage } from 'app/shared/services/config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MarketplaceInfoModel } from '@shared/models/marketplace-info.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-version',
  templateUrl: './upgrade-version.component.html',
  styleUrls: ['./upgrade-version.component.less']
})
export class UpgradeVersionComponent implements OnInit {

  accountInfoLoading = true;
  loading = false;
  marketplaceInfo: MarketplaceInfoModel;
  images: Array<string> = [];
  pageTitle = '';
  mainSectionContent = '';
  secondarySectionContent = '';
  updateVersionButtonText = '';
  footerContent = '';
  isInstantUpdate = false;

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.marketplaceInfo = new MarketplaceInfoModel({
      name: this.configService.getOption('name'),
      owner: this.configService.getOption('owner'),
      term_manager: this.configService.getOption('term_manager'),
      term_members: this.configService.getOption('term_members')
    });

    const isAuthenticated = await this.authService.isAuthenticated();
    if (isAuthenticated) {
      this.checkAuthenticatedInfo();
    } else {
      this.accountInfoLoading = false;
    }

    if (this.router.url === '/instant-upgrade') {
      await this.loadImages('instant-upgrade');
      this.pageTitle = 'It\'s time for an upgrade!';
      this.mainSectionContent = 'On behalf of our partners at ' + this.marketplaceInfo.owner +
        ', we are pleased to announce the immediate availability of your new, reimagined ' + this.marketplaceInfo.name + '.';
      this.secondarySectionContent = 'Enjoy more useful offer descriptions;' +
        ' better browsing of brands, categories, new offers, and limited time offers;' +
        ' better search; new personalization options (like favorite offers);' +
        ' and many other features designed to help you save more money and more time.';
      this.updateVersionButtonText = 'Upgrade Today';
      this.footerContent = `This site is maintained by Beneplace, LLC in partnership with ${this.marketplaceInfo.owner}.`;
      this.isInstantUpdate = true;
    } else {
      await this.loadImages('upgrade-version');
      this.pageTitle = 'Are you ready for an upgrade?';
      this.mainSectionContent = 'A preview of your new, reimagined ' + this.marketplaceInfo.name +
        ' site is now available. Enjoy better search, more useful offer descriptions, new personalization options (like favorite offers), ' +
        'and many other features designed to help you save more money and more time.';
      this.secondarySectionContent = 'Not ready for a change? You can still visit the old site until we get the new site fully up and running.' +
        ' Just sign up once, then use whichever version you prefer.';
      this.updateVersionButtonText = 'Sign Up';
    }
  }

  async loadImages(type: string): Promise<void> {
    const upgradeVersionImages: UpgradePageImagesResponse = await this.configService.getUpgradePagesImages(type);
    if (upgradeVersionImages.images && upgradeVersionImages.images.length) {
      this.images = upgradeVersionImages.images.map(imageDescription => imageDescription.image_path);
    }
  }

  checkAuthenticatedInfo(): void {
    this.loading = true;
    // try to check info info about current account
    this.authService.getAccountInfo()
      .then(() => {
        // if user is still have active session
        this.configService.getAuthInfo()
          .then(authInfo => {
            // if is defined redirect_to link after successfully sign-in
            if (authInfo.redirect_to && authInfo.redirect_to !== '') {
              window.location.href = authInfo.redirect_to;
            } else if (authInfo.redirect_to === '') {
              void this.router.navigateByUrl('/');
            } else {
              this.accountInfoLoading = false;
              this.loading = false;
            }
          }).catch(() => {
            this.accountInfoLoading = false;
            this.loading = false;
            void this.router.navigateByUrl('/');
          });
      }).catch(err => {
        this.accountInfoLoading = false;
        this.loading = false;

        // if we get 401 error
        // it means that current session is invalid
        // clear local info about authorization
        if (err instanceof HttpErrorResponse) {

          if (err.status === 401) {
            this.authService.setUnauthenticated();
            void this.router.navigateByUrl('/');
          }
        }
      });
  }

  private getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

}
