/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Ad } from '@app/shared/models/ad.model';
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-adzerk-brand-item',
  templateUrl: './adzerk-brand-item.component.html',
  styleUrls: ['./adzerk-brand-item.component.less']
})
export class AdzerkBrandItemComponent {

  @Input() brand: Ad;

  @Output() brandClick: EventEmitter<any> = new EventEmitter();
  @Output() brokenImageUrl: EventEmitter<string> = new EventEmitter();

  mouseJustLeft = false;

  click(event: any): void {
    this.brandClick.emit(event);
  }

  onMouseLeave(): void {
    this.mouseJustLeft = true;
  }

  emitBrokenImageUrl(): void {
    this.brokenImageUrl.emit(this.brand.guid);
  }

}
