/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { Ad } from '@app/shared/models/ad.model';
import {
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsGAOfferModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { Injectable } from '@angular/core';
import { OfferModel } from '@shared/models/offer.model';
import { TokensService } from '@shared/services/tokens.service';
import { UrlHelperService } from 'g3-common-ui';

export type ActionType =
  'click'
  | 'click-out'
  | 'click-copy-code'
  | 'click-reveal-code'
  | 'click-out-phone'
  | 'click-out-phone-cta'
  | 'cta-button-click'
  | 'offer-page-view'
  | 'special-listings-clicks'
  | 'offer-view'
  | 'offer-bad-view';

export function calculateDaysLeft(endDate: Date) {
  return Math.ceil((+(new Date(endDate)) - Date.now()) / 1000 / 60 / 60 / 24);
}

@Injectable({
  providedIn: 'root'
})
export class OffersAnalyticsService {

  constructor(
    protected tokensService: TokensService,
    private urlHelper: UrlHelperService,
  ) {
  }

  getOfferEventData(offer: OfferModel | Ad): any {
    let result;
    if (offer instanceof OfferModel) {
      result = { url: offer.url, offer: offer.guid };

      if (offer.brandOverride) {
        result['brandOverride'] = offer.brandOverride;
      }
    }
    if (offer instanceof Ad) {
      result = {
        url: offer.destinationUrl,
        offer: offer.contentGuid || offer.guid
      };

      if (offer.brandOverride) {
        result['brandOverride'] = offer.brandOverride;
      }
    }
    if (offer.extraStatus) {
      result['extraStatus'] = offer.extraStatus;
    }
    if (offer.endDate) {
      result['daysLeft'] = calculateDaysLeft(offer.endDate);
    }

    return result;
  }

  getAnalyticsGALabel(item: OfferModel | Ad, isOfferPhone = false): string {
    if (item instanceof OfferModel) {
      return new AnalyticsGAOfferModel(item.guid, item.vendorBrand.title, item.subtitle, isOfferPhone, item.brandOverride)
        .generateOfferLabel();
    } else if (item instanceof Ad) {
      return new AnalyticsGAOfferModel(item.contentGuid || item.guid, item.company, item.titleShort, isOfferPhone, item.brandOverride)
        .generateOfferLabel();
    }

    return '';
  }

  getAnalyticsEvents(action: ActionType, offer: OfferModel | Ad = null, eventData?: any, isOfferPhone = false): AnalyticsEvent[] {
    const label = this.getAnalyticsGALabel(offer, isOfferPhone);
    if (!eventData) {
      eventData = this.getOfferEventData(offer);
    }
    const clickOutValue = this.getEBGClickOutValue(offer);
    const clickOutData = { ...eventData, click_out: clickOutValue };
    const clickOutLabel = label + ` | ${clickOutValue}`;

    switch (action) {
      case 'click':
        return [
          new AnalyticsInternalEventModel('click', eventData),
          new AnalyticsGAEventModel('ListOfferClick', { category: 'OfferClick', label })
        ];
      case 'special-listings-clicks':
      case 'cta-button-click':
      case 'click-out':
        return [
          new AnalyticsInternalEventModel('click-out', clickOutData),
          new AnalyticsGAEventModel('OfferLinkOutClick', { category: 'OfferInteraction', label: clickOutLabel })
        ];
      case 'click-copy-code':
        return [
          new AnalyticsInternalEventModel('click-copy-code', eventData),
          new AnalyticsGAEventModel('OfferCopyCodeClick', { category: 'OfferInteraction', label })
        ];
      case 'click-reveal-code':
        return [
          new AnalyticsInternalEventModel('click-reveal-code', eventData),
          new AnalyticsGAEventModel('OfferRevealCodeClick', { category: 'OfferInteraction', label })
        ];
      case 'click-out-phone':
        const phoneLabel = `${label} | offerPhone | ${clickOutValue}`;
        return [
          new AnalyticsInternalEventModel('click-out', { offer_phone: offer.guid, url: offer.destinationUrl, click_out: clickOutValue }),
          new AnalyticsGAEventModel('click-out', { category: 'OfferInteraction', label: phoneLabel })
        ];
      case 'click-out-phone-cta':
        return [
          new AnalyticsInternalEventModel('click-out', clickOutData),
          new AnalyticsGAEventModel('click-out', { category: 'OfferInteraction', label: clickOutLabel })
        ];
      case 'offer-page-view':
        return [
          new AnalyticsGAEventModel('OfferPageView', { category: 'OfferInteraction', label }),
          new AnalyticsInternalEventModel('offer-view', eventData),
          new AnalyticsGAEventModel('OfferView', { category: 'OfferInteraction', label })
        ];
      case 'offer-view':
        return [
          new AnalyticsInternalEventModel('offer-view', eventData),
          new AnalyticsGAEventModel('OfferView', { category: 'OfferInteraction', label })
        ];
      case 'offer-bad-view':
        return [
          new AnalyticsGAEventModel('OfferPageView', {
            category: 'OfferInteraction',
            label: 'bad_country_view'
          }),
          new AnalyticsInternalEventModel('page-load', {
            action: 'bad_country_view',
            offer: offer.guid,
            source: 'main-application'
          }),
        ];
    }
  }

  getEBGClickOutValue(offer: OfferModel | Ad): string {
    const urlKey = offer instanceof OfferModel ? 'url' : 'destinationUrl';

    if (!this.urlHelper.isExternalUrl(offer[urlKey])) {
      return 'internal';
    }

    const isEbg = offer.isEbg === undefined
      ? this.tokensService.isTokenPresent(offer[urlKey], 'ebghost')
      : offer.isEbg;

    return isEbg ? 'internal' : 'external';
  }
}
