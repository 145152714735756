<div *ngIf="!!widgetClass && !!model"
     class="truecar {{ widgetClass }} widget-b-form">
  <div>
    <form method="get"
          name="trueCarSearch"
          target="_blank">
      <div class="flex-container form-title-wrapper">
        <h5 class="New-Car-Copy-5">New Car</h5>
      </div>
      <fieldset aria-label="new car">
        <div class="flex-column">
          <div class="flex-container label-input-container">
            <label class="select-car-label">New Car Make</label>
            <app-select [groupTabIndex]="groupTabIndex"
                        [selectValue]="newMakeId"
                        (selectValueChange)="selectChange($event)"
                        [options]="newCarMake"
                        name="new-make"
                        placeholder="New Car Make">
            </app-select>
          </div>
          <div class="flex-container wrapper">
            <div class="zip-code-wrapper">
              <label class="zip-code-label">Zip Code</label>
              <input [appAutoTabIndex]="groupTabIndex + 100"
                     id="postalCode"
                     maxlength="5"
                     name="postalCode"
                     type="text"
                     [placeholder]="getZipCodePlaceholder()"
                     aria-label="zip code"
                     class="text-field"
                     [(ngModel)]="zipCodeNew" />
            </div>
            <div class="button-wrapper">
              <button [appAutoTabIndex]="groupTabIndex + 100"
                      class="btn btn-primary truecar-btn btn-left Field"
                      (click)="findNewCar()">Shop <span class="option-word">New</span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     viewBox="0 0 16 16">
                  <path fill='#FFF'
                        fill-rule="nonzero"
                        d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>

  <div class="divider divider-sm-vertical">
    <div class="flexible-rule"></div>
    <span class="divider-text">or</span>
    <div class="flexible-rule"></div>
  </div>

  <div>
    <form class="carSearch"
          name="trueCarUsedSearch">
      <div class="flex-container form-title-wrapper">
        <h5 class="Used-Car-Copy-4">Used Car</h5>
      </div>
      <fieldset aria-label="used car">
        <div class="flex-column">
          <div class="flex-container label-input-container">
            <label class="select-car-label">Used Car Make</label>
            <app-select [groupTabIndex]="groupTabIndex + 100"
                        [selectValue]="usedMakeId"
                        (selectValueChange)="selectChange($event)"
                        [options]="usedCarMake"
                        name="used-make"
                        placeholder="Used Car Make">
            </app-select>
          </div>
          <div class="flex-container wrapper">
            <div class="zip-code-wrapper">
              <label class="zip-code-label">Zip Code</label>
              <input [appAutoTabIndex]="groupTabIndex + 150"
                     id="zipCode"
                     maxlength="5"
                     name="zipCode"
                     aria-label="zip code"
                     type="text"
                     [placeholder]="getZipCodePlaceholder()"
                     class="text-field"
                     [(ngModel)]="zipCodeUsed" />
            </div>
            <div class="button-wrapper">
              <button [appAutoTabIndex]="groupTabIndex + 150"
                      class="btn btn-primary truecar-btn btn-right Field"
                      (click)="findUsedCar()">Shop <span class="option-word">Used</span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     viewBox="0 0 16 16">
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
