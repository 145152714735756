import { ActivatedRoute } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { WINDOW, UrlHelperService } from 'g3-common-ui';
import { MultiSearchState } from '@app/widgets/interfaces/widgets-state.interface';
import { MultiWidgetService } from '@app/widgets/services/multi-widget.service';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { SearchWidgetsData } from '@app/offers/interfaces/search-widgets.interface';
import { SearchWidgetService } from '@app/widgets/services/search-widget.service';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { Tabset } from '@app/shared/components/tabset/tabset.interface';
import { OfferBadgeService } from '@shared/services/offer-badge.service';
import { OfferBadgeSettingsModel } from '@shared/models/offer-badge-settings.model';
import { OfferBadgeData } from '@shared/interfaces/offer-badge-data.interface';
import { ConfigService } from '@shared/services/config.service';
import { SplitTestEventsService } from '@app/shared/services/split-tests/split-test-events.service';
import { RENTAL_CAR_SEARCH, TAW_HOTELS_SEARCH } from '@app/widgets/constants/widget-ids.constant';

const ANIMATION_TIMEOUT = window.navigator.userAgent.indexOf('MSIE ') > 0
  || !!navigator.userAgent.match(/Trident.*rv\:11\./) ? 500 : 350;

const ADMIN_EDIT_MODE = 'admin_edit_mode';


@Component({
  selector: 'app-multi-search',
  templateUrl: './multi-search.component.html',
  styleUrls: ['./multi-search.component.less', '../common-widget.style.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION],
  providers: [OfferBadgeService]
})
export class MultiSearchWidgetComponent implements OnInit {

  @Input() widget: SearchWidgetsData;
  @Input() topIndent: boolean;

  @Output() clicked = new EventEmitter<SearchWidgetsData>();
  @Output() displayed = new EventEmitter<SearchWidgetsData>();

  multiWidgetUnits: Partial<SearchWidgetsData>[] = [];
  selectedWidget: Partial<SearchWidgetsData>;
  widgetUnit: Partial<SearchWidgetsData>;
  tabset: Tabset[] = [];
  tabId: string;
  stateKey: string;
  defaultTab: number;
  isLoading = false;
  loyaltyEnabled = false;

  constructor(
    @Inject(WINDOW) private window: WINDOW,
    private configService: ConfigService,
    private multiWidgetService: MultiWidgetService,
    private searchWidgetService: SearchWidgetService,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private offerBadgeService: OfferBadgeService,
    private urlHelper: UrlHelperService,
    private splitTestEventsService: SplitTestEventsService
  ) { }

  get validWidgetTabs(): Tabset[] {
    if (this.tabset) {
      return this.tabset.filter(tab => tab.cat_code_tag_ids && tab.cat_code_tag_ids.length && this.isWidgetTabIdValid(tab.id));
    }

    return [];
  }

  get widgetOffers(): OfferBadgeData[] {
    if (this.validWidgetTabs) {
      return this.validWidgetTabs.map(tab => ({
        catCodeTagIds: tab.cat_code_tag_ids,
        title: this.getWidgetTabTitle(tab)
      }));
    }

    return [];
  }

  get widgetOfferVisible(): boolean {
    return this.loyaltyEnabled
      && this.validWidgetTabs
      && !!this.validWidgetTabs.length
      && this.widget.cat_code_tag_ids
      && this.widget.cat_code_tag_ids.some(catIds => catIds === 300_000);
  }

  ngOnInit(): void {
    this.offerBadgeService.setBadgeSettings(new OfferBadgeSettingsModel({
      lightPillsCount: 1,
      loyaltyPillsOnly: true
    }));

    this.loyaltyEnabled = this.configService.getConfig().loyalty;
    this.stateKey = `${this.widget.widget_id}_${this.getOrderedQueryItems()}`;
    void this.initMultiWidget();
  }

  onDisplayed(widget: SearchWidgetsData): void {
    this.searchWidgetService.onDisplayed(this.getWidgetUnitForAnalytics(widget));
  }

  onClicked(widget: SearchWidgetsData): void {
    this.sessionStorageService.setItem<MultiSearchState>(this.stateKey, { selectedTab: this.selectedWidget.widget_id });
    this.searchWidgetService.onClicked(this.getWidgetUnitForAnalytics(widget));
    this.emitSplitTestClickoutEvent(this.getWidgetUnitForAnalytics(widget));
  }

  getSelectedTabId(tabId: string): void {
    this.isLoading = true;
    this.tabId = tabId;
    this.selectedWidget = this.multiWidgetUnits.find(data => data.widget_id === tabId);
    this.widgetUnit = this.getWidgetUnitForAnalytics(this.selectedWidget);
    setTimeout(() => this.isLoading = false, ANIMATION_TIMEOUT);
  }

  private emitSplitTestClickoutEvent(widget: Partial<SearchWidgetsData>): void {
    if (this.isAnyAdminModeEnabled()) {
      return;
    }

    const isInternalClickout = widget.isEbg || !this.urlHelper.isExternalUrl(widget.destination_url);
    if (isInternalClickout) {
      this.splitTestEventsService.emitInternalClickoutEvent();
    } else {
      this.splitTestEventsService.emitExternalClickoutEvent();
    }
  }

  private getWidgetUnitForAnalytics(widget: Partial<SearchWidgetsData>): Partial<SearchWidgetsData> {
    return { ...widget, adId: this.widget.adId };
  }

  private async initMultiWidget(): Promise<void> {
    this.multiWidgetUnits = cloneDeep(await this.multiWidgetService.getMultiWidgetUnits(this.widget));
    this.tabset = cloneDeep(this.multiWidgetService.getMultiWidgetTabset(this.multiWidgetUnits));
    const lastSelectedTab = this.sessionStorageService.getItem<MultiSearchState>(this.stateKey);

    if (lastSelectedTab) {
      this.defaultTab = this.tabset.findIndex(tab => tab.id === lastSelectedTab.selectedTab);
    } else {
      this.defaultTab = this.widget && this.widget.default_tab ? this.tabset.findIndex(tab => tab.id === this.widget.default_tab) : 0;
    }
  }

  private getOrderedQueryItems(): string {
    const orderedParams = Object.keys(this.activatedRoute.snapshot.queryParams)
      .filter(el => el !== 'tw_city_zip' && el !== 'tw_q')
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = this.activatedRoute.snapshot.queryParams[key];

          return obj;
        }, {}
      );

    return JSON.stringify(orderedParams);
  }

  private getWidgetTabTitle(tab: Tabset): string {
    let title = '';

    switch (tab.id) {
      case 'rental_car_search':
        title = 'prepaid rental cars';
        break;
      default:
        title = tab.title.toLowerCase();
        break;
    }

    return title;
  }

  private isWidgetTabIdValid(tabId: string): boolean {
    return tabId && (tabId ===  TAW_HOTELS_SEARCH || tabId === RENTAL_CAR_SEARCH);
  }

  private isAnyAdminModeEnabled(): boolean {
    return !!this.window.sessionStorage.getItem(ADMIN_EDIT_MODE);
  }
}
