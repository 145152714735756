import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { normalizeURIString } from '@shared/helpers/query-string';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {

  private readonly F_SPECIAL_PLACEHOLDER = 'F_SPECIAL_PLACEHOLDER';

  parse(url: string): UrlTree {
    const defaultUrlSerializer = new DefaultUrlSerializer();

    if (url.includes('f_special=')) {
      const [path, query] = url.split('?');
      const specialParam = query.split('&').find(p => p.startsWith('f_special='));
      const originalSpecialParam = specialParam.split('=')[1];
      const modifiedUrl = path + '?' + query.replace(`f_special=${originalSpecialParam}`, `f_special=${this.F_SPECIAL_PLACEHOLDER}`);

      let urlTree: UrlTree;
      try {
        urlTree = defaultUrlSerializer.parse(modifiedUrl);
      } catch (e) {
        urlTree = defaultUrlSerializer.parse(normalizeURIString(modifiedUrl));
      }

      urlTree.queryParams.f_special = originalSpecialParam;
      return urlTree;

    } else {
      try {
        return defaultUrlSerializer.parse(url);
      } catch (e) {
        return defaultUrlSerializer.parse(normalizeURIString(url));
      }
    }
  }

  serialize(tree: UrlTree): string {
    const defaultUrlSerializer = new DefaultUrlSerializer();

    // Angular doesn't encode ',' in query params. We need to encode it for marketplace's custom filters.
    // So we replace it temporarly with placeholder and then attach it as it is
    if (tree.queryParams?.f_special) {
      const specialFilterValue = tree.queryParams?.f_special;
      tree.queryParams.f_special = this.F_SPECIAL_PLACEHOLDER;
      const serializedUrl = defaultUrlSerializer.serialize(tree).replace(this.F_SPECIAL_PLACEHOLDER, specialFilterValue);
      tree.queryParams.f_special = specialFilterValue;
      return serializedUrl;
    } else {
      return defaultUrlSerializer.serialize(tree);
    }
  }
}
