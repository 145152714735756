import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/auth/services/auth.service';
import { COUNTRY_LAST_SESSION_KEY } from '@app/shared/constants/profile.constants';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { environment } from '@environments/environment';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { EncryptionService, AuthEmailPopulationTypes } from 'g3-common-ui';
import { Subject, Subscription, takeUntil } from 'rxjs';

const EMAIL_CONTROL_KEY = 'email';

@Component({
  selector: 'app-change-email-confirmation-step',
  templateUrl: './change-email-confirmation-step.component.html',
  styleUrls: ['./change-email-confirmation-step.component.less']
})
export class ChangeEmailConfirmationStepComponent implements OnDestroy {
  public emailSubscription: Subscription;
  public formEmailSubscription: Subscription;
  public errorMessageSubscription: Subscription;
  public emailChangeForm = new UntypedFormGroup({
    [EMAIL_CONTROL_KEY]: new UntypedFormControl('', [Validators.required, Validators.email])
  });

  private destroyStream$ = new Subject<void>();

  public get email(): AbstractControl {
    return this.emailChangeForm.get(EMAIL_CONTROL_KEY);
  }

  constructor(
    private readonly accountConfirmationService: AccountConfirmationService,
    private readonly authService: AuthService,
    private readonly knownUserService: KnownUserService,
    private readonly encryptionService: EncryptionService
  ) {
    this.emailSubscription = accountConfirmationService.email$.pipe(takeUntil(this.destroyStream$)).subscribe(value => {
      this.emailChangeForm.get(EMAIL_CONTROL_KEY).setValue(value);
    });

    this.formEmailSubscription = this.emailChangeForm
      .get(EMAIL_CONTROL_KEY)
      .valueChanges.pipe(takeUntil(this.destroyStream$))
      .subscribe(value => {
        this.accountConfirmationService.setEmail(value);
      });

    this.errorMessageSubscription = accountConfirmationService.errorMessage$
      .pipe(takeUntil(this.destroyStream$))
      .subscribe(value => {
        this.emailChangeForm.get(EMAIL_CONTROL_KEY).setErrors({ invalid: value });
      });
  }

  public async onSendPassCodeClick(event: Event): Promise<void> {
    event.stopPropagation();
    if (this.emailChangeForm.valid) {
      await this.accountConfirmationService.sendPascode(true);

      if (!this.accountConfirmationService.state.errorMessage) {
        this.accountConfirmationService.navigateToEmailConfirmation();
      }
    }
  }

  public async onSignIn(): Promise<void> {
    this.authService.setUnauthenticated();
    localStorage.removeItem(COUNTRY_LAST_SESSION_KEY);

    const { cipher } = await this.encryptionService.encrypt(environment.apiUrl, this.email.value);
    const customQueryObject = { email: cipher, population_type: AuthEmailPopulationTypes.ConfirmAccount };
    this.authService.redirectToSignInDirectly('', true, customQueryObject);

    this.knownUserService.clearAll();
    this.knownUserService.clearSkipPassword();
    sessionStorage.clear();
  }

  public ngOnDestroy(): void {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }
}
